import ArrowOutward from '@mui/icons-material/ArrowOutward';
import clsx from 'clsx';
import { ReactNode } from 'react';

import KnowledgeLevelIcon, {
  KnowledgeLevelIconProps,
} from '@/knowledge/components/KnowledgeLevelIcon';

export type MatchTagType = 'exact' | 'fuzzy' | 'indirect';

const MatchTag = ({
  className,
  children,
  type,
  clickable,
  level,
  suppressTooltip,
  parenthesis,
}: {
  className?: string;
  children: ReactNode;
  type?: MatchTagType;
  clickable?: boolean;
  parenthesis?: string;
} & Partial<Pick<KnowledgeLevelIconProps, 'level' | 'suppressTooltip'>>) => {
  return (
    <span
      className={clsx(
        className,
        'flex items-center gap-8 whitespace-nowrap rounded-sm border py-1 subtitle-1',
        {
          'px-8': !level,
          'pl-4 pr-8': !!level,
          'border-[#b2d0c4] bg-[#edf8e9] text-grey-700 shadow-[0_1px_1px_0_rgba(0,87,85,0.15)]':
            type === 'exact',
          'border-deep-purple-200 bg-deep-purple-50 text-deep-purple-600 shadow-[0_1px_1px_0_rgba(59,0,87,0.15)]':
            type === 'fuzzy' || type === 'indirect',
        }
      )}
    >
      {level ? (
        <KnowledgeLevelIcon
          level={level}
          suppressTooltip={suppressTooltip}
          className={clsx({
            'fill-[#005755]': type === 'exact',
            'fill-[#5e35b1]': type === 'fuzzy',
          })}
        />
      ) : null}
      {children}
      {parenthesis ? (
        <>
          {' '}
          <span className="capitalize">({parenthesis})</span>
        </>
      ) : null}
      {clickable ? <ArrowOutward color="inherit" sx={{ fontSize: 14 }} /> : null}
    </span>
  );
};

export default MatchTag;
