import { Drawer } from '@mui/material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useApp } from '@/hooks/useAppContext';
import { white } from '@/theme/colors';
import { SCREEN_MD } from '@/theme/screens';

import MaterialIcon from '../Icon/MaterialIcon';
import IconButton from '../IconButton';
import Logo from '../Logo';
import s from './MobileNavigation.module.scss';

function getWidth(capabilities: any) {
  if (typeof window !== 'undefined') {
    return window.document.body.clientWidth;
  }

  const { screen } = capabilities;
  if (screen && screen.width) {
    return screen.width;
  }

  return SCREEN_MD + 1;
}

function MobileNavigation({
  open,
  theme,
  onRequestClose,
  drawerColor = white,
  children,
  closeButtonColor,
}: any) {
  const { capabilities } = useApp();
  const [width, setWidth] = React.useState(capabilities?.screen?.width || SCREEN_MD + 1);

  const handleResize = () => {
    setWidth(getWidth(capabilities));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Drawer
      open={open}
      PaperProps={{
        style: { background: drawerColor },
      }}
    >
      <div className={s.header}>
        <a href="/" style={{ lineHeight: 0 }}>
          <Logo dark={theme === 'light'} />
        </a>
        <IconButton onClick={onRequestClose}>
          <MaterialIcon icon="close" color={closeButtonColor} size={30} />
        </IconButton>
      </div>

      <div className={s.content} style={{ width: width - 30 }}>
        {children}
      </div>
    </Drawer>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'MobileNavigation' because it is ... Remove this comment to see the full error message
MobileNavigation = connect((state: RootState) => ({
  guessedScreenWidth: state.ui.guessedScreenWidth,
}))(MobileNavigation);

export default MobileNavigation;
