import s from './TypingIndicator.module.scss';

function TypingIndicator() {
  return (
    <div>
      <span className={s.dot} />
      <span className={s.dot} />
      <span className={s.dot} />
    </div>
  );
}

export default TypingIndicator;
