import React, { FC } from 'react';

import { SignupType } from '@/auth';
import Login from '@/auth/components/Login';
import Signup from '@/auth/components/Signup';
import LayoutPage from '@/components/Layout/LayoutPage';

import s from './AuthPage.module.scss';

interface AuthPageProps {
  signup: any;
  children?: React.ReactNode;
  tags: any;
  signupType: SignupType;
  nextUrl: string;
  signupLink: string;
  signinLink: string;
}

const AuthPage: FC<AuthPageProps> = ({ signup, children, tags, ...other }) => {
  return (
    <LayoutPage className={s.root}>
      {signup ? (
        <Signup tags={tags} {...other}>
          {children}
        </Signup>
      ) : (
        <Login {...other}>{children}</Login>
      )}
    </LayoutPage>
  );
};

export default AuthPage;
