import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useAssignmentsByColleague } from '@/assignment/queries/assignments';
import { useSelf } from '@/hooks/store';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import { useProfileQuery } from '@/profilev2/queries/profile';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { useAppSelector } from '@/store';

import ExperienceListDrawer, { ExperienceListOrdering } from '../ExperienceListDrawer';

const ExperiencesByColleagueDrawer = () => {
  const isSelf = useSelf();
  const { colleagueId } = useParams();
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<ExperienceListOrdering>('-dates');

  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);

  const {
    data: colleague,
    isLoading: isColleagueLoading,
    isFetched: isColleagueFetched,
  } = useProfileQuery({
    id: colleagueId ?? '',
  });

  const {
    data: experiences,
    isLoading,
    isRefetching,
  } = useAssignmentsByColleague({
    profileId: profileData?.id,
    colleagueId,
    ordering: sortValue,
    page,
  });

  const titleIsLoading = isColleagueLoading || isProfileLoading;

  const title = [isSelf ? 'You' : profileData?.full_name, colleague?.full_name].join(' + ');

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title,
    },
  ]);

  if (!colleagueId || (isColleagueFetched && !colleague)) {
    return <Navigate to=".." replace />;
  }

  return (
    <ExperienceListDrawer
      breadcrumbs={titleIsLoading ? [] : breadcrumbs}
      title={title}
      titleIsLoading={titleIsLoading}
      description={`Engagements that ${
        isSelf ? 'you' : profileData?.first_name
      } and ${colleague?.first_name} have worked on together`}
      descriptionIsLoading={titleIsLoading}
      experiences={experiences}
      isLoading={isLoading}
      isRefetching={isRefetching}
      sortValue={sortValue}
      handleSortOrderChange={(newValue) => setSortValue(newValue)}
      page={page}
      handlePageChange={(newValue) => setPage(newValue)}
    />
  );
};

export default ExperiencesByColleagueDrawer;
