import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import Box from '@/componentsv2/Box';

import UploadIcon from './UploadIcon.svg';
import styles, { dragAndDropZoneClasses } from './styles';
import { IDragAndDropZoneProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-drag-and-drop-zone';

const DragAndDropZone = ({
  className,
  accept = {},
  isLoading = false,
  onUploaded,
  ...rest
}: IDragAndDropZoneProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const classProps = clsx(className, classes.dragAndDropZone, dragAndDropZoneClasses.root);

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    ...rest,
    accept: accept,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        onUploaded(acceptedFiles[0]);
      }
    },
  });

  const supportedFormats = useMemo(
    () =>
      Object.values(accept).reduce((acc, curr) => {
        return [...acc, ...curr.map((c) => c.toLowerCase())];
      }, []),
    [accept]
  );

  useEffect(() => {
    if (fileRejections.length) {
      enqueueSnackbar(`Wrong file type. Supported formats: ${supportedFormats.join(', ')}`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, fileRejections, supportedFormats]);

  return (
    <Box className={classProps} data-testid={testId}>
      <div
        className={clsx(dragAndDropZoneClasses.dropZone, {
          [dragAndDropZoneClasses.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="h5" component="p">
            Drop the files here...
          </Typography>
        ) : (
          <>
            {isLoading ? (
              <>
                <CircularProgress />
                <Typography variant="h5" component="p">
                  Uploading file...
                </Typography>
              </>
            ) : (
              <>
                <UploadIcon />
                <Typography variant="h5" component="p">
                  Drag & drop files or{' '}
                  <Typography variant="h5" component="span" color="secondary.main">
                    Browse
                  </Typography>
                </Typography>
                <Typography variant="body1" component="p" mt={1.25}>
                  Supported formats: {supportedFormats.join(', ')}
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export { dragAndDropZoneClasses, testId as DragAndDropZoneTestId };
export type { IDragAndDropZoneProps };
export default DragAndDropZone;
