import { JSX } from 'react/jsx-runtime';

import LayoutPage from '@/components/Layout/LayoutPage';
import MediaQuery from '@/components/MediaQuery';
import NotFoundPage from '@/pages/NotFoundPage';
import { fetchUser } from '@/store/user';
import { SCREEN_SM } from '@/theme/screens';

import { LegacyRoute } from '../routesMiddleware';
import LegalAcknowledgement from './LegalAcknowledgement';

const policyConfig: Record<string, any> = {
  privacy: {
    props: {
      aggregateAgreementName: 'Terms of Use and Privacy Policy',
      policyKeys: ['terms-of-use', 'privacy'],
    },
  },
  'expert-participation-agreement': {
    props: {
      aggregateAgreementName: 'Expert Participation Agreement',
      policyKeys: ['expert-participation-agreement'],
    },
  },
};

function ExpertAgreememtInternalNetworkNote({ networks }: { networks: Array<{ name: string }> }) {
  if (!networks || networks.length === 0) return <NotFoundPage />;

  const names = networks.map((n) => (
    <span key={n.name}>
      <b>{n.name}</b>
      {networks.length > 1 ? ',' : ''}
    </span>
  ));

  return (
    <div style={{ lineHeight: 1.25 }}>
      Joining OnFrontiers allows you to be engaged by {names} and other clients for paid phone
      consultations and other types of work. It does not inhibit in any way your current or future
      work with {names} or any other client we introduce with whom you may already have a
      relationship.
    </div>
  );
}

// TODO: broken for now :(
const route: LegacyRoute = {
  path: '/legal_ack/:policy',

  async action({ store, query, params }) {
    const nextUrl = query.get('next');
    const state = store.getState();
    //if (!state.legal.pages) await store.dispatch(fetchLegalPages());

    let note: JSX.Element;
    if (params.policy === 'expert-participation-agreement') {
      const viewer = await store.dispatch(
        fetchUser(state.viewer.username, {
          internalNetworks: true,
        })
      );
      const expertNetworks = viewer.profile.expert_internal_networks;
      const networks = expertNetworks && expertNetworks.map((e: { network: any }) => e.network);
      if (networks) {
        // @ts-ignore
        note = <ExpertAgreememtInternalNetworkNote networks={networks} />;
      }
    }

    const config = policyConfig[params.policy || ''];

    document.title = `Updated ${config.props.aggregateAgreementName}`;
    return (
      <LayoutPage hideSearch showNewRequest={false}>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(isMobileVersion: any) => (
            <LegalAcknowledgement
              userId={state.viewer.id}
              nextUrl={nextUrl || '/dashboard'}
              isMobileVersion={isMobileVersion}
              note={note}
              {...config.props}
            />
          )}
        </MediaQuery>
      </LayoutPage>
    );
  },
};

export default route;
