import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import langs from 'langs';
import { useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Select } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import { red500 } from '@/theme/colors';

const useStyles = makeStyles({
  label: {
    fontSize: 12,
  },

  remove: {
    border: 0,
  },

  add: {
    marginTop: 10,
  },
});

const fluencyOptions = [
  { value: null, label: '' },
  { value: 'elementary', label: 'Elementary proficiency' },
  { value: 'limited_working', label: 'Limited working proficiency' },
  { value: 'professional_working', label: 'Professional working proficiency' },
  { value: 'full_professional', label: 'Full professional proficiency' },
  { value: 'native_or_bilingual', label: 'Native or bilingual proficiency' },
];

function Languages({ onAdd, fields }: any) {
  const required = useCallback((value: any) => (value ? undefined : 'Required'), []);
  const codes = (fields.value || []).map((v: any) => v.code);
  const s = useStyles();

  return (
    <Box mt="16px">
      <FormLabel classes={{ root: s.label }}>Languages</FormLabel>
      <FieldArray name="languages">
        {({ fields }) =>
          fields.map((name, index) => {
            const code = codes[index];
            const languages = langs
              .all()
              .filter((l: any) => code === l['3'] || !codes.includes(l['3']))
              .map((l: any) => ({
                value: l['3'],
                label: l.name,
              }));

            return (
              <Box
                display="flex"
                key={name}
                gap={2}
                alignItems="flex-end"
                justifyContent="space-evenly"
              >
                <Box className="min-w-[150px]">
                  <Field
                    autocomplete
                    validate={required}
                    component={Select}
                    name={`${name}.code`}
                    options={languages}
                    limit={null}
                    placeholder="Language"
                  />
                </Box>
                <Field
                  validate={required}
                  component={Select}
                  name={`${name}.fluency`}
                  options={fluencyOptions}
                  placeholder="Fluency"
                />
                <IconButton color="inherit" onClick={() => fields.remove(index)}>
                  <FAIcon size={16} color={red500} icon="trash" />
                </IconButton>
              </Box>
            );
          })
        }
      </FieldArray>

      <div className={s.add}>
        <Fab type="button" size="small" color="secondary" onClick={onAdd}>
          <AddIcon />
        </Fab>
      </div>
    </Box>
  );
}

export default Languages;
