import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { SECTOR__BATCH_ADD, SECTOR__REMOVE } = ActionTypes;

export function fetchSectors(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const { sectors } = getState();
    if (sectors.all.length > 0) {
      return sectors;
    }

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchSectors {
          sectors {
            id
            name
          }
        }
      `)
    );
    if (result.sectors) {
      dispatch({
        type: SECTOR__BATCH_ADD,
        sectors: result.sectors,
      });
    }
    return result.sectors;
  };
}

export function fetchSector({ id }: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const { sectors } = getState();
    const sector = sectors.all.find((s: any) => s.id === id);
    if (sector) {
      return sector;
    }

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query getSector($id: String!) {
          sector(id: $id) {
            id
            name
          }
        }
      `),
      { id }
    );
    // only add to all if it is already populated
    if (result.sector && sectors.all.length > 0) {
      dispatch({
        type: SECTOR__BATCH_ADD,
        sectors: [...sectors.all, result.sector],
      });
    }

    return result.sector;
  };
}

export function createSector(values: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const { sectors } = getState();
    const { createSector } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionCreateSector($name: String!) {
          createSector(name: $name) {
            id
            name
          }
        }
      `),
      values
    );

    if (createSector) {
      dispatch({
        type: SECTOR__BATCH_ADD,
        sectors: [...sectors.all, createSector],
      });
    }

    return createSector;
  };
}

export function removeSector(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveSector($id: String!) {
          deleteSector(id: $id)
        }
      `),
      { id }
    );

    if (result) {
      dispatch({ type: SECTOR__REMOVE, id });
    }

    return result;
  };
}
