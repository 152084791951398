import _locale from 'yup/lib/locale';
import _string from 'yup/lib/string';

import { EMAIL_REGEX } from '@/utils/regex';

// override for yup validation of email with custom regex
_string.prototype.email = function email(message) {
  return this.matches(EMAIL_REGEX, {
    message: message === void 0 ? _locale.string.email : message,
    excludeEmptyString: true,
  });
};
