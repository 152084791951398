import clsx from 'clsx';
import { ChevronDown } from 'react-feather';

import Button from '@/componentsv2/Button';

import { ICollapseToggleProps } from './types';

const testId = 'of-collapse-toggle';

const CollapseToggle = ({ className, expanded, handleToggle }: ICollapseToggleProps) => {
  return (
    <Button
      variant="tertiary"
      size="small"
      className={className}
      onClick={handleToggle}
      data-testid={testId}
      endIcon={ChevronDown}
      endIconClassName={clsx('w-16 h-16 transition-transform duration-100', {
        'rotate-180': expanded,
      })}
    >
      Show {expanded ? 'less' : 'more'}
    </Button>
  );
};

export { testId as CollapseToggleTestId };
export type { ICollapseToggleProps };
export default CollapseToggle;
