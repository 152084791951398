import { Navigate, useParams } from 'react-router-dom';

import APP_ROUTES from '@/routes/APP_ROUTES';

const ProfileRedirect = () => {
  const { id } = useParams();
  return <Navigate to={APP_ROUTES.profile(id)} replace />;
};

export default ProfileRedirect;
