import { PureComponent } from 'react';

import Message from '@/messaging/components/Message';

import Link from '../Link';
import LongText from '../LongText';
import s from './ActivityLog.module.scss';
import UserRef from './UserRef';

class NetworkAddActivity extends PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
    const { profile } = this.props.activity.context;
    return (
      <div>
        <div>
          <UserRef user={profile} /> was added to {this.renderNetworkLink()}
          {this.renderAddMethod()}.
        </div>
        {this.renderNote()}
      </div>
    );
  }

  renderNetworkLink() {
    // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
    const { network } = this.props.activity.context;
    if (!network) return null;
    return (
      <span>
        <Link className={s.userName} to={`/search?networks[]=${network.id}`}>
          {network.name}&rsquo;s network
        </Link>
      </span>
    );
  }

  renderAddMethod() {
    // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
    const { add_method: addMethod } = this.props.activity.context;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const addMethodTextFn = {
      consultation: () => 'by doing a consultation',
      invitation: () => <span>{this.renderCreatedBy()} by invitation</span>,
      manual: () => null,
      integration: () => null,
      domain_signup: () => 'by approving application to join',
      group_share: () => this.renderGroupShareAddMethod(),
    }[addMethod];
    if (!addMethodTextFn) return null;

    const addMethodText = addMethodTextFn();
    if (!addMethodText) return null;

    return <span>&nbsp;{addMethodText}</span>;
  }

  renderGroupShareAddMethod() {
    // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
    const { from_group: fromGroup } = this.props.activity.context;
    if (!fromGroup) return <span>by sharing from another group</span>;

    return (
      <span>
        {this.renderCreatedBy()} by sharing from&nbsp;
        <Link className={s.requestName} to={fromGroup.html_url}>
          {fromGroup.name}
        </Link>
        &rsquo;s network
      </span>
    );
  }

  renderCreatedBy() {
    // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
    const { created_by: createdBy } = this.props.activity.context;
    if (!createdBy) return null;
    return (
      <span>
        by <UserRef user={createdBy} />
      </span>
    );
  }

  renderNote() {
    const { created_by: noteAuthor, team_note: teamNote } =
      // @ts-expect-error TS(2339): Property 'activity' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.activity.context;
    if (!teamNote) return null;
    return (
      <Message className={s.message} bubbleClassName={s.bubble} user={noteAuthor}>
        <LongText text={teamNote} />
      </Message>
    );
  }
}

export default NetworkAddActivity;
