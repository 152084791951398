import moment from 'moment-timezone';
import { Action } from 'redux';

import { ActionFetchTemplateQuery } from '@/__generated__/graphql';
import ActionTypes from '@/actions/ActionTypes';

const {
  MESSAGE_TEMPLATES__UPDATE,
  MESSAGE_TEMPLATES__LOADING,
  MESSAGE_TEMPLATES__LIST,
  MESSAGE_TEMPLATES__LOADED,
} = ActionTypes;

export type MessageTemplate = NonNullable<ActionFetchTemplateQuery['messageTemplate']>;

interface MessageTemplateAction extends Action {
  renderArgs: string;
  templates: MessageTemplate[];
  templateId: string;
  template: MessageTemplate;
}

type MessageTemplateState = Record<string, MessageTemplateCollectionState>;

export interface MessageTemplateCollectionState {
  templates: MessageTemplate[];
  expire: moment.Moment;
  loading: boolean;
}

const initialCollectionState: MessageTemplateCollectionState = {
  templates: [],
  expire: moment().add(-24, 'hour'),
  loading: false,
};

export default function messageTemplatesReducer(
  state: MessageTemplateState = {},
  action: MessageTemplateAction
): MessageTemplateState {
  const { type } = action;

  switch (type) {
    case MESSAGE_TEMPLATES__LOADING:
    case MESSAGE_TEMPLATES__LOADED:
    case MESSAGE_TEMPLATES__LIST:
    case MESSAGE_TEMPLATES__UPDATE: {
      const { renderArgs } = action;
      if (typeof renderArgs === 'string') {
        return {
          ...state,
          [renderArgs]: collectionReducer(state[renderArgs], action),
        };
      }
      return Object.keys(state).reduce((acc, renderArgs) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[renderArgs] = collectionReducer(state[renderArgs], action);
        return acc;
      }, {});
    }
    default:
      return state;
  }
}

function collectionReducer(
  state = initialCollectionState,
  action: MessageTemplateAction
): MessageTemplateCollectionState {
  const { type } = action;
  const expire = moment().add(20, 'minutes');
  switch (type) {
    // list actions
    case MESSAGE_TEMPLATES__LOADING:
      return { ...state, loading: true };
    case MESSAGE_TEMPLATES__LOADED:
      return { ...state, loading: false };
    case MESSAGE_TEMPLATES__LIST:
      return (() => {
        const { templates } = action;
        return { ...state, templates: sortedTemplates(templates), expire };
      })();

    // single template actions
    case MESSAGE_TEMPLATES__UPDATE:
      return (() => {
        const { templateId, template } = action;
        const id = templateId || template.id;
        const templates = state.templates.filter((x) => x.id !== id);
        return {
          ...state,
          templates: sortedTemplates([...templates, template].filter(Boolean)),
          expire,
        };
      })();

    default:
      return state;
  }
}

function sortedTemplates(templates: MessageTemplate[] | undefined) {
  return [...(templates || [])].sort((a: any, b: any) => {
    const am = moment(a.created_at);
    const bm = moment(b.created_at);
    return am.isBefore(bm) ? -1 : am.isAfter(bm) ? 1 : 0;
  });
}
