import clsx from 'clsx';

import { IProfileContainerProps } from '../types';

const testId = 'of-profile-container';

const ProfileContainer = ({ className, children, ...rest }: IProfileContainerProps) => {
  return (
    <section
      className={clsx(className, 'rounded-md border border-light-primary bg-white shadow-5')}
      data-testid={testId}
      {...rest}
    >
      {children}
    </section>
  );
};

export default ProfileContainer;
export { testId as ProfileContainerTestId };
