import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from 'react';

import RequestConsultationButton from '@/components/Button/RequestConsultationButton';
import Card from '@/components/Card';
import Divider from '@/components/Divider';
import SendMessageButton from '@/components/SendMessageButton/SendMessageButton';
import { darkGray } from '@/theme/colors';

import Actions from './Actions';
import AddToExpertRequestButton from './AddToExpertRequestButton';

const getStyles = makeStyles((theme) => ({
  container: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.up('sm')]: {
      minWidth: 230,
    },
  },
  imageContainer: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginTop: -125,
      marginBottom: 10,
    },
  },
}));

const getPreviewStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  buttonInfo: {
    color: darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 8,
  },
  overlay: {
    opacity: 0.2,
    pointerEvents: 'none',
  },
  buttonConsultation: {
    marginTop: 20,
  },
  buttonProject: {
    marginTop: 20,
    marginBottom: 10,
  },
}));

function ActionsPreview({ profile }: any) {
  const s = getPreviewStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const emptyClick = useCallback(() => {}, []);

  return (
    !mobile && (
      <div className={s.content}>
        <Divider spacing={0} />

        <div className={s.overlay}>
          <RequestConsultationButton
            fullWidth
            onClick={emptyClick}
            className={s.buttonConsultation}
          />

          <div className={s.buttonInfo}>You won&apos;t be charged until confirming</div>

          <AddToExpertRequestButton
            // @ts-expect-error TS(2769): No overload matches this call.
            label="Add to project"
            fullWidth
            profile={profile}
            disabled
            onClick={emptyClick}
            className={s.buttonProject}
          />

          <SendMessageButton onClick={emptyClick} />
        </div>
      </div>
    )
  );
}

export default function ({ profile, user, preview, children, ...other }: any) {
  const s = getStyles();

  return (
    <Card
      className={s.container}
      imageContainerClassname={s.imageContainer}
      profile={profile}
      preview={preview}
      {...other}
    >
      {preview ? (
        <ActionsPreview profile={profile} />
      ) : (
        <Actions user={user} profile={profile} {...other} />
      )}
    </Card>
  );
}
