import React from 'react';

import Filter from './Filter';
import FilterAutocomplete from './FilterAutocomplete';
import FilterChips from './FilterChips';

export default function KeywordFilter({
  title,
  placeholder,
  keywords,
  onSubmit,
  onRemoveValue,
  hideDivider,
}: any) {
  const handleAdd = (e: any, newValue: any) => {
    if (!newValue) return;

    if (!keywords.includes(newValue)) {
      onSubmit([...keywords, newValue]);
    }
  };

  return (
    <Filter title={title} hideDivider={hideDivider}>
      <FilterAutocomplete
        freeSolo
        displayAddButton
        placeholder={placeholder}
        onChange={handleAdd}
        options={[]}
        value={keywords}
        disableUnderline={false}
      />
      <FilterChips
        options={keywords}
        onRemoveValue={onRemoveValue}
        getColor={(o: any) => (o.value ? 'secondary' : undefined)}
      />
    </Filter>
  );
}
