import makeStyles from '@mui/styles/makeStyles';

import Message from '@/messaging/components/Message';
import { lightBlue } from '@/theme/colors';

import QueryResponses from '../QueryResponses/QueryResponses';
import RequestRef from './RequestRef';
import UserRef from './UserRef';

const useStyles = makeStyles(() => ({
  message: {
    marginTop: 10,
    marginBottom: 10,
  },
  bubble: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    border: `2px solid ${lightBlue}`,
    padding: '10px !important',
  },
}));

export default function CandidateAnswersActivity({
  activity: {
    user,
    context: {
      expert,
      group,
      expert_request: expertRequest,
      raw: { can_answer: canAnswer = [], can_qualify: canQualify = [] },
    } = {},
  },
}: any) {
  const s = useStyles();
  const queryType = canQualify.length > 0 ? `qualifications` : `questions`;

  return (
    <div>
      <div>
        <UserRef user={user} link /> responded to {queryType}{' '}
        <RequestRef expertRequest={expertRequest} group={group} />
      </div>
      <Message className={s.message} bubbleClassName={s.bubble} user={expert}>
        <QueryResponses
          values={canQualify.length > 0 ? canQualify : canAnswer}
          queryType={queryType}
        />
      </Message>
    </div>
  );
}
