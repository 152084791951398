import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';

import { safeHtml } from '@/utils';

function LongText({ text = '', linkify = false, component = 'div', ...other }) {
  const items = text.split('\n').map((item, i) => {
    const line = (
      <span
        key={i}
        style={{ wordBreak: 'break-word' }}
        // @ts-expect-error TS(2322) FIXME: Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
        dangerouslySetInnerHTML={{ __html: safeHtml(item.concat('<br />')) }}
      />
    );
    return linkify ? (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Linkify key={i} properties={{ target: '_blank' }}>
        {line}
      </Linkify>
    ) : (
      line
    );
  });
  return React.createElement(component, other, items);
}

LongText.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.string,
};

export default LongText;
