import { TextField } from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { teal500 } from '@/theme/colors';

import Button from '../Button/Button';
import Dialog from '../Dialog';
import FAIcon from '../Icon/FAIcon';
import SelectProfile from '../SelectProfile';
import s from './SelectMergeSourceProfileButton.module.scss';

const SelectMergeSourceProfileButton = (props: any) => {
  const navigate = useNavigate();
  const [srcProfile, setSrcProfile] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleChange = (selected: any) => setSrcProfile(selected.value);

  const handleConfirm = () => {
    const { profile } = props;
    if (!srcProfile) return;

    // @ts-expect-error TS(2339): Property 'url_endpoint' does not exist on type 'ne... Remove this comment to see the full error message
    navigate(`/merge/${profile.url_endpoint}/${srcProfile.url_endpoint}`);
  };

  const { profile, ...other } = props;

  const title = (
    <div className={s.header}>
      <FAIcon icon="code-fork" color={teal500} size={40} />
      <div className={s.title}>Profiles to Merge</div>
    </div>
  );

  return (
    <div>
      <Button
        color="lightTan"
        startIcon="code-fork"
        fullWidth
        {...other}
        style={{ fontSize: 14, padding: '15px 22px' }}
        onClick={() => setOpen(true)}
        size="medium"
      >
        Merge Profile
      </Button>

      <Dialog
        {...other}
        open={open}
        onCancel={() => setOpen(false)}
        title={title}
        onConfirm={handleConfirm}
        disableSubmit={!srcProfile}
        confirmLabel="Compare Profiles"
        cancelLabel="Cancel"
      >
        <SelectProfile
          // @ts-expect-error TS(2769): No overload matches this call.
          autoFocus
          label="Select Source Profile"
          onChange={handleChange}
          value={
            srcProfile
              ? {
                  type: 'profile',
                  value: srcProfile,
                }
              : null
          }
          menuStyle={{ maxHeight: 300 }}
          showCreationTimestamp
          profileType="lead"
        />
        <TextField
          label="Compare with"
          value={`${profile.name} (${moment(profile.created_at).format('YYYY-MM-DD LT')})`}
          fullWidth
        />
      </Dialog>
    </div>
  );
};

export default SelectMergeSourceProfileButton;
