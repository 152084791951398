import { autocompleteClasses } from '@mui/material/Autocomplete';
import { dialogClasses } from '@mui/material/Dialog';
import { dialogContentClasses } from '@mui/material/DialogContent';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { paperClasses } from '@mui/material/Paper';
import { tooltipClasses } from '@mui/material/Tooltip';

import theme from '@/theme';
import { getStyles } from '@/utils/styles.helpers';

const nt = 's-dialog';

export const DialogClasses = {
  dialogTitle: `${nt}-title`,
  dialogHeader: `${nt}-header`,
  content: `${nt}-content`,
  buttonsContainer: `${nt}-btns-container`,
  button: `${nt}-btn`,
  iconContainer: `${nt}-iconContainer`,
  icon: `${nt}-icon`,
  iconSize: `${nt}-iconSize`,
  nameContent: `${nt}-nameContent`,
  fieldContent: `${nt}-fieldContent`,
  colleagueContainer: `${nt}-colleagueContainer`,
  collaboratorsListContainer: `${nt}-collaboratorsListContainer`,
  addNewCollaboratorContainer: `${nt}-addNewCollaboratorContainer`,
  addNewCollaboratorContext: `${nt}-addNewCollaboratorContext`,
  addNewCollaboratorimages: `${nt}-addNewCollaboratorimages`,
  addNewCollaboratorSkeleton: `${nt}-addNewCollaboratorSkeleton`,
  addNewCollaboratorinput: `${nt}-addNewCollaboratorinput`,
  listedCardContainer: `${nt}-listedCardContainer`,
  listedCardContext: `${nt}-listedCardContext`,
  listedCardInfo: `${nt}-listedCardInfo`,
  listedCardimages: `${nt}-listedCardimages`,
  listedCardClose: `${nt}-listedCardClose`,
  email: `${nt}-email`,
  listedCardName: `${nt}-listedCardName`,
  listedCardPosition: `${nt}-listedCardPosition`,
  loadingCircular: `${nt}-loadingCircular`,
  addNewCollaboratorPlus: `${nt}-addNewCollaboratorPlus`,
  addNewCollaboratorClose: `${nt}-addNewCollaboratorClose`,
  disableStatus: `${nt}-disableStatus`,
  errorStatus: `${nt}-errorStatus`,
  selectedStatus: `${nt}-selectedStatus`,
  colleagueTitle: `${nt}-colleagueTitle`,
  onlyCollaborators: `${nt}-only-collaborators`,
  ...dialogClasses,
  ...paperClasses,
  ...outlinedInputClasses,
  ...tooltipClasses,
  ...autocompleteClasses,
};

const styles = {
  Dialog: {
    [`& .${DialogClasses.content}`]: {
      ...getStyles('display-flex-column'),
      gap: 24,
    },
    [`& .${DialogClasses.iconContainer}`]: {
      cursor: 'pointer',
      [`& svg`]: {
        fontSize: 23,
      },
    },
    [`& .${DialogClasses.iconSize}`]: {
      [`&:hover`]: {
        background: 'transparent',
      },
      [`& svg`]: {
        fontSize: 23,
      },
    },
    [`& .${DialogClasses.icon}`]: {
      ...getStyles('display-flex-column', 'align-items-center', 'justify-content-center'),
      width: 45,
    },
    [`& .${DialogClasses.icon}:active`]: {
      [`& svg`]: {
        [`& path`]: {
          fill: theme.palette.success.main,
          fillOpacity: 1,
        },
      },
    },
    [`& .${paperClasses.root}`]: {
      maxWidth: 800,
      width: 800,
    },
    [`& .${DialogClasses.paper}`]: {
      backgroundColor: theme.palette.grey[100],
      width: 800,
      maxWidth: '100%',
    },
    [`& .${DialogClasses.nameContent}`]: {
      ...getStyles('display-flex-row', 'align-items-center'),
      width: '100%',
      [`& .${DialogClasses.fieldContent}`]: {
        ...getStyles('display-flex-row', 'align-items-center'),
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        padding: '0 13px',
        [`& input`]: {
          backgroundColor: theme.palette.common.white,
          border: 0,
          height: 40,
          width: '100%',
          outline: 'none',
          fontWeight: 400,
          fontFamily: 'Inter',
          fontSize: 16,
        },
      },
    },
    [`& .${DialogClasses.multiline}`]: {
      ...getStyles('display-flex-column', 'align-items-end'),
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 11,
      width: '100%',
      [`& textarea`]: {
        backgroundColor: theme.palette.common.white,
        border: 0,
        width: '100%',
        height: 110,
        resize: 'none',
        outline: 'none',
        marginBottom: 10,
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: 16,
      },
    },
    [`& .${DialogClasses.buttonsContainer}`]: {
      ...getStyles('display-flex-row'),
      justifyContent: 'flex-end',
      [`& .${DialogClasses.button}`]: {
        ...getStyles('display-flex-row'),
        margin: 4,
        minWidth: 75,
        height: 37,
      },
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: '22px 22px 40px 22px',
      [`& .${DialogClasses.colleagueContainer}`]: {
        ...getStyles('display-flex-column'),
        gap: 20,
        color: theme.palette.primary.main,
        [`& .${DialogClasses.collaboratorsListContainer}`]: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: 24,
          alignItems: 'start',
        },
        [`& .${DialogClasses.addNewCollaboratorContainer}`]: {
          ...getStyles('display-flex-column', 'align-items-center', 'justify-content-between'),
          backgroundColor: theme.palette.grey[200],
          border: 'transparent solid 1px',
          borderRadius: 2,
          color: theme.palette.grey[700],
          cursor: 'pointer',
          fontFamily: 'Inter',
          fontWeight: 400,
          order: 1,
          [`&.${DialogClasses.selectedStatus}`]: {
            border: `${theme.palette.secondary.light} solid 1px`,
            [`&:focus-within`]: {
              border: `${theme.palette.secondary.light} solid 1px`,
            },
          },
          [`&:focus-within`]: {
            border: `${theme.palette.grey[700]} solid 1px`,
          },
          [`& .${DialogClasses.addNewCollaboratorContext}`]: {
            display: 'block',
            alignContent: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          },
          [`& .${DialogClasses.addNewCollaboratorimages}`]: {
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: '#A2A2A2',
            display: 'inline-block',
          },
          [`& .${DialogClasses.addNewCollaboratorSkeleton}`]: {
            margin: '0 10px',
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            backgroundColor: 'transparent',
            border: 0,
            fontSize: 16,
            padding: 10,
            width: 320,
            color: theme.palette.grey[700],
            [`&:focus`]: {
              outline: 0,
            },
            [`& .MuiInput-root`]: {
              padding: 0,
            },
          },
          [`& .${autocompleteClasses.root}`]: {
            width: 'calc(100% + 16px)',
          },
          [`& .${autocompleteClasses.endAdornment}`]: {
            display: 'none',
          },
          [`& .${outlinedInputClasses.root}`]: {
            padding: 0,
          },
          [`& .${outlinedInputClasses.input}`]: {
            border: 0,
            height: 74,
            fontSize: 16,
            padding: '26px 26px 26px 20px',
            boxSizing: 'border-box',
            color: theme.palette.grey[700],
            [`&:focus`]: {
              outline: 0,
            },
            [`& .MuiInput-root`]: {
              padding: 0,
            },
          },
          [`& .${DialogClasses.loadingCircular}`]: {
            position: 'relative',
            display: 'inline-block',
            right: 27,
          },
          [`& .${DialogClasses.addNewCollaboratorPlus}`]: {
            color: theme.palette.secondary.light,
            width: 16,
            position: 'relative',
            right: 16,
          },
          [`& .${DialogClasses.disableStatus}`]: {
            color: theme.palette.grey[300],
            width: 16,
            cursor: 'default !important',
            position: 'relative',
            right: 16,
          },
        },
        [`& .${DialogClasses.listedCardContainer}`]: {
          ...getStyles('display-flex-row', 'align-items-center'),
          backgroundColor: theme.palette.common.white,
          border: 'transparent solid 1px',
          borderRadius: 2,
          color: theme.palette.grey[700],
          cursor: 'pointer',
          fontFamily: 'Inter',
          height: 74,
          justifyContent: 'space-between',
          order: 2,
          padding: 16,
          [`& .${DialogClasses.listedCardContext}`]: {
            ...getStyles('display-flex-row', 'align-items-center'),
            gap: 12,
            [`& .${DialogClasses.listedCardInfo}`]: {
              ...getStyles('display-flex-column'),
              [`& .${DialogClasses.listedCardName}`]: {
                fontSize: 12,
                fontWeight: 500,
              },
              [`& .${DialogClasses.listedCardPosition}`]: {
                color: theme.palette.grey[600],
                fontSize: 12,
                lineHeight: '1.4',
              },
            },
          },
          [`& .${DialogClasses.listedCardimages}`]: {
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: '#A2A2A2',
            display: 'inline-block',
            overflow: 'hidden',
          },
          [`& .${DialogClasses.listedCardClose}`]: {
            color: theme.palette.grey[500],
          },
          [`& .${DialogClasses.email}`]: {
            color: theme.palette.primary.main,
            fontSize: 24,
          },
        },
        [`& .${DialogClasses.colleagueTitle}`]: {
          padding: 0,
          fontSize: 20,
          color: theme.palette.primary.main,
        },
      },
    },
    [`& .${DialogClasses.dialogTitle}`]: {
      fontFamily: 'Inter',
      fontSize: '20px',
      padding: 0,
    },
    [`& .${DialogClasses.dialogHeader}`]: {
      ...getStyles('display-flex-row', 'justify-content-between', 'align-items-center'),
      color: theme.palette.primary.main,
      padding: '18px 22px 0',
    },
    [`&.${DialogClasses.onlyCollaborators}`]: {
      [`& .${DialogClasses.dialogTitle}`]: {
        fontSize: 14,
      },
      [`& .${DialogClasses.nameContent}`]: {
        display: 'none',
      },
      [`& .${DialogClasses.multiline}`]: {
        display: 'none',
      },
      [`& .${DialogClasses.colleagueTitle}`]: {
        display: 'none',
      },
      [`& .${dialogContentClasses.root}`]: {
        [`& .${DialogClasses.colleagueContainer}`]: {
          [`& .${DialogClasses.addNewCollaboratorContainer}`]: {
            order: 3,
          },
        },
      },
    },
  },
};

export default styles;
