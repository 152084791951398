import Skeleton from '@mui/material/Skeleton';

import { OneLineList } from '@/componentsv2/OneLineList';
import { mq } from '@/theme/screens';

import Accomplishments from './Accomplishment';

interface IOverviewProps {
  isLoading?: boolean;
  title?: string;
  organization?: string;
  detailsMain?: Array<string | null | undefined>;
  detailsSecondary?: Array<string | null | undefined>;
  description?: string;
}

const Overview = ({
  isLoading,
  title,
  organization,
  detailsMain,
  detailsSecondary,
  description,
}: IOverviewProps) => {
  return (
    <div className="space-y-16 rounded-md border border-light-primary bg-white p-20 shadow-5 sm:space-y-6">
      {isLoading ? (
        <>
          <p className="hd-3">
            <Skeleton variant="text" width={660} />
          </p>
          <p className="hd-6">
            <Skeleton variant="text" width={330} />
          </p>
          <p className="mt-[6px]">
            <Skeleton variant="text" width={400} />
          </p>
        </>
      ) : (
        <>
          <p className="hd-4 sm:hd-3">{title}</p>
          <p className="hd-6">{organization}</p>
          {detailsMain ? (
            <OneLineList className="mt-4 body-2" mq={mq.sm}>
              {detailsMain?.map((detail, i) => (
                <OneLineList.Item key={`${i} - ${detail}`}>{detail}</OneLineList.Item>
              ))}
            </OneLineList>
          ) : null}
          {detailsSecondary ? (
            <OneLineList className="mt-4 body-2" mq={mq.sm}>
              {detailsSecondary?.map((detail, j) => (
                <OneLineList.Item key={`${j} - ${detail}`}>{detail}</OneLineList.Item>
              ))}
            </OneLineList>
          ) : null}
        </>
      )}
      {isLoading ? (
        <Skeleton variant="text" width={660} height={100} />
      ) : description ? (
        <div className="mt-8 border-t border-grey-400 pt-8">
          <Accomplishments content={description} />
        </div>
      ) : null}
    </div>
  );
};

export default Overview;
