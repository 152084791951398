const ns = 'tag-group';

export const tagGroupClasses = {
  root: `${ns}__root`,
  tags: `${ns}__tags`,
};

const { tags } = tagGroupClasses;

export const tagMargin = 8;

const styles = {
  tagGroup: {
    [`& .${tags}`]: {
      listStyle: 'none',
      '& li:not(:last-child)': {
        marginBottom: tagMargin,
        marginRight: tagMargin,
      },
    },
  },
};

export default styles;
