import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  OmnibarResponse,
  SearchEnriched,
  SearchHash,
  SearchKnowledgeSimilar,
  SearchService,
} from '@/openapi';

export const DEFAULT_SEARCH_LIMIT = 10;

type SearchProfiles = {
  query: string;
  limit?: number;
  page?: number;
  filterIds?: Array<string>;
  signal?: AbortSignal;
};

type SearchHashOptions = {
  hash: string;
  signal?: AbortSignal;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const searchProfiles = ({
  query,
  limit = DEFAULT_SEARCH_LIMIT,
  page = 1,
  filterIds = [],
  signal,
}: SearchProfiles): CancelablePromise<SearchEnriched> => {
  const offset = (page - 1) * limit;
  const response = SearchService.searchCreate(limit, offset, query, '', {
    filter_ids: filterIds,
  });
  if (signal) {
    signal.addEventListener('abort', () => response.cancel());
  }
  return response;
};

export const useSearchProfilesQuery = (
  { query, page, limit, filterIds }: SearchProfiles,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['search', { query, page, limit, filterIds }],
    queryFn: async ({ signal }) => searchProfiles({ query, page, limit, filterIds, signal }),
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};

const searchHash = ({ hash, signal }: SearchHashOptions): CancelablePromise<SearchHash> => {
  const response = SearchService.searchHashRetrieve(hash);
  if (signal) {
    signal.addEventListener('abort', () => response.cancel());
  }
  return response;
};

export const useSearchHashQuery = ({ hash }: SearchHashOptions, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['search-hash', { hash }],
    queryFn: hash ? async ({ signal }) => searchHash({ hash, signal }) : () => null,
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
    ...options,
  });
};

type FetchKnowledgeSimilar = {
  knowledgeId: string;
  knowledgeType: string;
  limit?: number;
};

const fetchKnowledgeSimilar = ({
  knowledgeId,
  knowledgeType,
  limit = DEFAULT_SEARCH_LIMIT,
}: FetchKnowledgeSimilar): CancelablePromise<Array<SearchKnowledgeSimilar>> => {
  return SearchService.searchKnowledgeSimilarRetrieve(knowledgeId, knowledgeType, limit);
};

export const useSearchKnowledgeSimilarQuery = ({
  knowledgeId,
  knowledgeType,
  limit,
}: FetchKnowledgeSimilar) => {
  return useQuery({
    queryKey: ['search-knowledge-similar', { knowledgeId, knowledgeType }],
    queryFn: () => fetchKnowledgeSimilar({ knowledgeId, knowledgeType, limit }),
  });
};

const searchOmnibar = ({
  signal,
  query,
  limit,
}: {
  signal?: AbortSignal;
  query: string;
  limit?: number;
}): CancelablePromise<OmnibarResponse> => {
  const response = SearchService.searchOmnibarRetrieve(query, limit);
  if (signal) {
    signal.addEventListener('abort', () => response.cancel());
  }
  return response;
};

export const useSearchOmnibarQuery = ({ query, limit }: { query: string; limit?: number }) => {
  return useQuery({
    queryKey: ['search-omnibar', { query, limit }],
    queryFn: query ? async () => searchOmnibar({ query, limit }) : () => null,
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
  });
};
