import { Button as MaterialButton } from '@mui/material';
import { memo } from 'react';

import { formatCreditsText, roundOffCredits } from '@/utils';

import CreditsButton from '../CreditsButton';
import s from './Actions.module.scss';

function Actions({ bookingFee, expertCredits, submitting, onCancel }: any) {
  expertCredits = roundOffCredits(expertCredits);

  return (
    <div className={s.root}>
      <div>
        <MaterialButton onClick={onCancel}>Cancel</MaterialButton>
      </div>
      <CreditsButton
        credits={expertCredits + bookingFee}
        disabled={submitting}
        type="submit"
        creditsTooltip={
          <div>
            {expertCredits > 0 && <div>Expert time: {formatCreditsText(expertCredits)}</div>}
            <div>Booking fee: {formatCreditsText(bookingFee)}</div>
          </div>
        }
      >
        {submitting ? 'Submitting...' : 'Submit Request'}
      </CreditsButton>
    </div>
  );
}

export default memo(Actions);
