import langs from 'langs';
import React from 'react';
import { connect } from 'react-redux';

import ChipFilter from '../ChipFilter';
import EducationFilter from '../EducationFilter';
import ExperienceFilter from '../ExperienceFilter';
import GroupKeywordsFilter from '../GroupKeywordsFilter';
import KeywordFilter from '../KeywordFilter';

interface RegularFiltersProps {
  query: any;
  languagesOptions: { label: string; value: string }[];
  countriesOptions: { label: string; value: string }[];
  search: (filters: any) => void;
  handleFilterAdd: (filterType: string, value: any) => void;
  handleFilterRemove: (filterType: string, value: any) => void;
  handleFilterEvent: (filterType: string, event: any) => void;
  viewer: any; // Replace 'any' with the appropriate type if known
}

function RegularFilters({
  query,
  languagesOptions,
  countriesOptions,
  search,
  handleFilterAdd,
  handleFilterRemove,
  handleFilterEvent,
  viewer,
}: RegularFiltersProps) {
  const handleKeywordFilterRemove = (opt: { id: any }) => {
    if (opt.id) {
      handleFilterRemove('groupKeywordIds', opt.id);
    } else {
      handleFilterRemove('keywords', opt);
    }
  };

  return (
    <>
      <ChipFilter
        title="Language"
        value={query.languages}
        placeholder="Select languages"
        options={languagesOptions}
        onSubmit={(v: any) => search({ languages: v })}
        onRemoveValue={(opt: { value: any }) => handleFilterRemove('languages', opt.value)}
        selectInputProps={{ limit: null }}
      />

      <ChipFilter
        title="Country Experience"
        value={query.countries}
        placeholder="Select countries"
        options={countriesOptions}
        onSubmit={(v: any) => search({ countries: v })}
        onRemoveValue={(opt: { value: any }) => handleFilterRemove('countries', opt.value)}
        selectInputProps={{ limit: null }}
      />

      <GroupKeywordsFilter
        viewer={viewer}
        title="Team Keywords"
        placeholder="Select keywords"
        selected={query.groupKeywordIds}
        onSubmit={(v: any) => search({ groupKeywordIds: v })}
        onRemoveValue={handleKeywordFilterRemove}
      />

      <KeywordFilter
        viewer={viewer}
        title="Keywords"
        placeholder="Select keywords"
        keywords={query.keywords}
        onSubmit={(v: any) => search({ keywords: v })}
        onRemoveValue={handleKeywordFilterRemove}
      />

      <EducationFilter
        degrees={query.educationDegrees}
        fieldsOfStudy={query.educationFos}
        onDegreeChange={(e: any) => handleFilterEvent('educationDegrees', e)}
        onFieldsOfStudyAdd={(v: any) => handleFilterAdd('educationFos', v)}
        onFieldsOfStudyRemove={(v: any) => handleFilterRemove('educationFos', v)}
      />

      <ExperienceFilter
        title="Job title"
        placeholder="Enter job title"
        currentRestriction={query.jobTitlesCurrentRestriction}
        values={query.jobTitles}
        onCurrentRestrictionChange={(e) => handleFilterEvent('jobTitlesCurrentRestriction', e)}
        onAddValue={(v) => handleFilterAdd('jobTitles', v)}
        onRemoveValue={(v) => handleFilterRemove('jobTitles', v)}
      />

      <ExperienceFilter
        title="Work Location"
        placeholder="Enter location"
        currentRestriction={query.locationsCurrentRestriction}
        values={query.locations}
        onCurrentRestrictionChange={(e) => handleFilterEvent('locationsCurrentRestriction', e)}
        onAddValue={(v) => handleFilterAdd('locations', v)}
        onRemoveValue={(v) => handleFilterRemove('locations', v)}
      />
    </>
  );
}

export default connect((state: { countries: { name: any }[] }) => ({
  languagesOptions: langs.all().map((l) => ({ label: l.name, value: l['3'] })),
  countriesOptions: state.countries.map((c: { name: any }) => ({
    label: c.name,
    value: c.name,
  })),
}))(RegularFilters);
