import NotFoundPage from '@/pages/NotFoundPage';
import { fetchConflicts } from '@/profile/store';
import { LegacyRoute } from '@/routes/routesMiddleware';

import ProfileConflicts from './ProfileConflicts';

const route: LegacyRoute = {
  path: '/profile-conflicts',
  element: <ProfileConflicts />,
  async action({ store }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return <NotFoundPage />;

    store.dispatch(fetchConflicts());

    document.title = 'Profile Merge Conflicts';
    return null;
  },
};

export default route;
