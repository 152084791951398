import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import styles, { pillClasses } from './styles';

const useStyles = makeStyles(styles);

type PillColors = 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'error' | 'light';

type PillProps = {
  color?: PillColors;
  className?: string;
  component?: 'div' | 'span';
  label: string | ReactElement;
  variant?: 'filled' | 'outlined';
  shape?: 'rounded' | 'squared';
};

const Pill: React.FC<PillProps> = ({
  className,
  color = 'primary',
  component: C = 'div',
  label,
  shape = 'rounded',
  variant = 'filled',
}) => {
  const classes = useStyles();
  const classProps = clsx(
    pillClasses.root,
    className,
    classes.pill,
    pillClasses[color],
    pillClasses[variant],
    pillClasses[shape]
  );

  return (
    <C data-testid="of-pill" className={classProps}>
      {label}
    </C>
  );
};

export default Pill;
export { pillClasses };
export type { PillColors };
