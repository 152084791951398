import theme from '@/theme';

const ns = 'one-line-list';

export const oneLineListClasses = {
  root: `${ns}__root`,
};

const styles = {
  oneLineList: {
    alignItems: 'center',
    display: 'flex',
    gap: '4px 8px',
    '& > *:not(:first-child)': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px 8px',
      '&::before': {
        background: theme.palette.grey[300],
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: 4,
        width: 4,
        flexShrink: 0,
      },
    },
  },
};

export default styles;
