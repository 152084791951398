import { Action, AnyAction, AsyncThunk } from '@reduxjs/toolkit';

export interface RejectedAction extends Action {
  error: Error;
}

export function isRejectedNotAbortedAction(baseAction: AsyncThunk<any, any, any>) {
  return (action: AnyAction): action is RejectedAction => {
    return (
      action.type === baseAction.rejected.type &&
      action.error &&
      action.error.message !== 'Request aborted'
    );
  };
}

export function removeAttribute(object: any, attribute: any) {
  const newObject = Object.assign({}, object);
  delete newObject[attribute];
  return newObject;
}

export function add<T>(list: T[], item: T, condition?: any) {
  return condition && list.some(condition) ? list : [...list, item];
}

export function filter<T>(list: T[], condition: any) {
  const newList = list.filter(condition);
  return list.length === newList.length ? list : newList;
}

export function removeAt<T>(list: T[], index: number) {
  if (index < 0) return list;

  return [...list.slice(0, index), ...list.slice(index + 1, list.length)];
}

export function updateAt<T>(list: T[], index: number, item: T) {
  if (index < 0) return list;

  return [...list.slice(0, index), item, ...list.slice(index + 1, list.length)];
}

export function mergeAt<T>(list: T[], index: number, item: T) {
  if (index < 0) return list;

  const cur = list[index];
  return updateAt(list, index, { ...cur, ...item });
}

export function isValidURL(rawUrl = '') {
  return rawUrl && (rawUrl.startsWith('http://') || rawUrl.startsWith('https://'));
}

export function isValidLinkedin(linkedinUrl: any) {
  // URL accepts invalid values such as http:/linkedin.com
  if (!isValidURL(linkedinUrl)) {
    return false;
  }

  try {
    const url = new URL(linkedinUrl);
    return url.hostname.endsWith('linkedin.com');
  } catch {
    return false;
  }
}

export function encodeFilestackURL(url: any) {
  const { origin } = window.location;
  const filestack = new URL(url);

  return `${origin}/attachment${filestack.pathname}`;
}

export function getJoinTitle(domain: any) {
  if (domain) {
    return domain.network_join_title || `Join the ${domain.group.name} Knowledge Network`;
  }
  return 'Join the OnFrontiers Expert Network';
}

const onlyLettersRegex = /[A-zÀ-ÖØ-öø-ÿ ]+$/;

export function hasOnlyLetters(s: any) {
  return onlyLettersRegex.test(s);
}
