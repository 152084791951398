import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import Alert from '@/components/Alert';
import AppNotifications from '@/components/AppNotifications';
import Button from '@/components/Button';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link';
import agreements, { policies } from '@/core/agreements';
import RedirectCompleteness from '@/profile/components/ProfileCompleteness/RedirectCompleteness';
import { RootState } from '@/store';
import { darkGreen, red500 } from '@/theme/colors';

import Consultations from './Consultations';
import ExpertApplicationPromo from './ExpertApplicationPromo';
import NoConsultationsInstructions from './NoConsultationsInstructions';
import Projects from './Projects';

const getStyles = makeStyles(() => ({
  title: {
    margin: '0 0 30px',
  },
}));

interface DashboardProps {
  user: RootState['viewer'];
  userContext: RootState['ui']['userContext'];
  awaitingConsultations: RootState['consultations']['dashboardAwaiting'];
  confirmedConsultations: RootState['consultations']['dashboardConfirmed'];
}

function Dashboard({
  user,
  userContext,
  awaitingConsultations,
  confirmedConsultations,
}: DashboardProps) {
  const s = getStyles();
  const [passwordUpdateClosed, setPasswordUpdate] = useState(false);
  const handlePasswordUpdateClose = useCallback(() => setPasswordUpdate(true), []);

  const expertPartPol = policies['expert-participation-agreement'];
  const isExpert = userContext === 'expert';
  const isApplying =
    user.expert_state === 'applying' || (user.signup_type === 'expert' && !user.expert_state);

  const alerts = [];
  // @ts-ignore
  const agreement = agreements(user.agreements);
  if (!agreement.hasAccepted('terms-of-use', 'privacy')) {
    alerts.push(
      <Alert type="error" key="privacy-alert">
        <div style={{ marginBottom: 20 }}>
          Please take a moment to accept our updated Terms of Use and Privacy Policy.
        </div>

        <Link to="/legal_ack/privacy">
          <Button color="white" fontColor={red500}>
            Review &amp; Accept
          </Button>
        </Link>
      </Alert>
    );
  }

  if (isExpert && !agreement.hasAccepted('expert-participation-agreement')) {
    alerts.push(
      <Alert type="error" key="aggrement-alert">
        <div style={{ marginBottom: 20 }}>
          You must accept our {expertPartPol.name} before accepting a consultation request.
        </div>

        <Link to="/legal_ack/expert-participation-agreement">
          <Button color="white" fontColor={red500}>
            Review &amp; Accept
          </Button>
        </Link>
      </Alert>
    );
  }

  if (!passwordUpdateClosed && user.password_expiry && user.password_expiry.expiring) {
    alerts.push(
      <Alert
        icon={<FAIcon color={darkGreen} icon="lock" />}
        preset="notification"
        onRequestClose={handlePasswordUpdateClose}
        key="password-update-alert"
      >
        <div>
          To increase security, we ask users to update their password periodically. Your password
          will expire at {moment(user.password_expiry.expiry).format('MMM DD')}.
          <br />
          <br />
          <Link to="/settings/password">
            <Button variant="contained" size="medium">
              Update Password
            </Button>
          </Link>
        </div>
      </Alert>
    );
  }

  const loading = awaitingConsultations.loading || confirmedConsultations.loading;
  const totalConsultations =
    (awaitingConsultations.edges || []).length + (confirmedConsultations.edges || []).length;

  return (
    <div id="dashboard-root">
      <Grid container spacing={4}>
        <Grid item md xs>
          <ConsultationsStarting />
          {alerts}
          <AppNotifications />
          <RedirectCompleteness profile={user.profile} />
          <h2 className={s.title}>Dashboard</h2>
          <Consultations
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            loading={loading}
            awaiting={awaitingConsultations.edges || []}
            confirmed={confirmedConsultations.edges || []}
          />
          {!isExpert && <Projects isViewerApplying={isApplying} />}
          {isExpert && isApplying && <ExpertApplicationPromo />}
          {isExpert && !isApplying && !loading && totalConsultations === 0 && (
            <NoConsultationsInstructions />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state.viewer,
  userContext: state.ui.userContext,
  awaitingConsultations: state.consultations.dashboardAwaiting || {},
  confirmedConsultations: state.consultations.dashboardConfirmed || {},
}))(Dashboard);
