import EventEmitter from 'events';

export const FeatureSupport = Object.freeze({
  supported: Symbol('supported'),
  unsupported: Symbol('unsupported'),
  unknown: Symbol('unknown'),
});

export const NetworkWarnings = Object.freeze({
  highRtt: Symbol('high-rtt'),
  lowMos: Symbol('low-mos'),
  highJitter: Symbol('high-jitter'),
  highPacketLoss: Symbol('high-packet-loss'),
});

const supportedEvents = [
  'featureSupport',
  'inputDeviceSelected',
  'inputDeviceOptions',
  'outputDeviceSelected',
  'outputDeviceOptions',
  'error',
  'callStateChange',
  'volumeChange',
  'connect',
];

export class VoipCarrier {
  _carrier: any;
  _carriers: any;
  _createToken: any;
  _emitter: any;
  _featureSupport: any;
  _sid: any;
  _state: any;
  constructor() {
    this._emitter = new EventEmitter();
    this._state = {};
    this._carriers = {};
  }

  registerCarrier(name: any, carrier: any) {
    this._carriers[name] = carrier;

    carrier.register({
      onDeviceChange: this._handleDeviceChange,
      onConnected: this._handleConnected,
      onDisconnected: this._handleDisconnected,
      onWarning: this._handleWarning,
      onError: this._handleError,
      onVolume: this._handleVolume,
      onFeatureSupport: this._handleFeatureSupport,
    });
  }

  _dispatchStateChange = (overrides: any) => {
    this._state = { ...this._state, ...overrides }; // merging
    this._emitter.emit('callStateChange', Object.freeze(this._state));
  };

  featureSupport = () => Object.freeze(this._featureSupport);

  // -- TOKEN FACTORY

  setTokenFactory(createToken: any) {
    this._createToken = createToken;
  }

  // -- EVENT EMITTER

  on(eventType: any, listener: any) {
    if (!supportedEvents.includes(eventType))
      throw new Error(`unsupported event type ${eventType}`);
    this._emitter.on(eventType, listener);
    return this;
  }

  removeListener(eventType: any, listener: any) {
    if (!supportedEvents.includes(eventType)) throw new Error(`unknown event type ${eventType}`);
    this._emitter.removeListener(eventType, listener);
    return this;
  }

  // -- CARRIER METHODS

  // async
  inputDeviceOptions = () => this._carrier.inputDeviceOptions();

  // async
  inputDeviceSelect = (deviceId: any) => {
    this._emitter.emit('inputDeviceSelected', deviceId);
    if (this.featureSupport().selectInputDevice === FeatureSupport.supported) {
      return this._carrier.inputDeviceSelect(deviceId);
    }
  };

  inputDeviceSelected = () => this._carrier.inputDeviceSelected();

  // async
  outputDeviceOptions = () => this._carrier.outputDeviceOptions();

  // async
  outputDeviceSelect = (deviceId: any) => {
    this._emitter.emit('outputDeviceSelected', deviceId);
    if (this.featureSupport().selectOutputDevice === FeatureSupport.supported) {
      this._carrier.outputDeviceSelect(deviceId);
    }
  };

  outputDeviceSelected = () => this._carrier.outputDeviceSelected();

  toggleMute() {
    const { mute } = this._state;
    this._carrier.mute(!mute);
    this._dispatchStateChange({ mute: !mute });
  }

  // -- CARRIER EVENTS

  _handleDeviceChange = async () => {
    const input = this.inputDeviceSelected();
    const output = this.outputDeviceSelected();

    const inputOptions = await this.inputDeviceOptions();
    const outputOptions = await this.outputDeviceOptions();

    this._emitter.emit('inputDeviceOptions', inputOptions);
    this._emitter.emit('outputDeviceOptions', outputOptions);

    if (
      inputOptions &&
      inputOptions.length > 0 &&
      !inputOptions.some((o: any) => o.deviceId === input)
    ) {
      this.inputDeviceSelect(inputOptions[0].deviceId);
    }

    if (
      outputOptions &&
      outputOptions.length > 0 &&
      !outputOptions.some((o: any) => o.deviceId === output)
    ) {
      this.outputDeviceSelect(outputOptions[0].deviceId);
    }
  };

  _handleConnected = (sid: any) => {
    this._sid = sid;
    this._dispatchStateChange({
      connecting: false,
      connected: true,
      sid,
    });
    this._emitter.emit('connect', Object.freeze(this._state));
  };

  _handleDisconnected = () => {
    this._dispatchStateChange({ connecting: false, connected: false });
  };

  _handleWarning = (warningName: any, active: any) => {
    this._dispatchStateChange({
      poorConnection: active,
      warningName: active ? warningName : '',
    });
  };

  _handleError = (err: any) => {
    this._dispatchStateChange({
      connecting: false,
      connected: false,
      poorConnection: false,
      warningName: '',
    });
    err.sid = this._sid;
    this._emitter.emit('error', err);
  };

  _handleVolume = (inputVolume: any, outputVolume: any) => {
    this._emitter.emit(
      'volumeChange',
      Object.freeze({
        inputVolume,
        outputVolume,
      })
    );
  };

  _handleFeatureSupport = (support: any) => {
    this._featureSupport = Object.freeze(support);
    this._emitter.emit('featureSupport', this._featureSupport);
  };

  // -- CONNECTION

  async connect(carrierName: any, identifier: any, joinURL: any) {
    const carrier = this._carriers[carrierName];
    if (!carrier) {
      throw new Error(`carrier ${carrierName} not supported`);
    }

    if (!this._createToken) {
      throw new Error('token generation function not defined');
    }

    this._carrier = carrier;

    try {
      this._dispatchStateChange({ connecting: true });

      validateConferenceSupported();

      const token = await this._createToken(identifier);

      this._dispatchStateChange({
        connected: false,
        poorConnection: false,
        warningName: '',
        sid: null,
        mute: false,
      });

      await this._carrier.connect(token, identifier, joinURL);
    } catch (e) {
      this._dispatchStateChange({ connecting: false });

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (e.message.indexOf('browser support') >= 0) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        e.unsupportedBrowser = true;
        this._dispatchStateChange({ unsupportedBrowser: true });
      }
      throw e;
    }
  }

  disconnect() {
    this._carrier.disconnect();
    this._dispatchStateChange({
      connected: false,
      poorConnection: false,
      warningName: '',
      mute: false,
    });
  }
}

export default new VoipCarrier();

function validateConferenceSupported() {
  if (!window.RTCPeerConnection) {
    throw new Error('browser support');
  }
}
