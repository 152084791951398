import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { typeCollectionProfilesSort } from '@/collection/pages/CollectionById';
import {
  CancelablePromise,
  PaginatedSavedCollectionProfileList,
  SavedCollectionService,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<PaginatedSavedCollectionProfileList>;

export const fetchData = createAsyncThunk(
  'collectionProfiles/fetchData',
  async ({
    id,
    limit = 12,
    offset,
    sort,
  }: {
    id: string;
    limit: number;
    offset?: number;
    sort?: typeCollectionProfilesSort;
  }) => {
    request?.cancel();
    request = SavedCollectionService.savedCollectionProfilesList(id, limit, offset, sort);
    return request;
  }
);
export const deleteProfileFromCollection = createAsyncThunk(
  'collectionProfiles/deleteProfileFromCollection',
  ({ id, profileId }: { id: string; profileId: string }) =>
    SavedCollectionService.savedCollectionProfileDeleteDestroy(id, profileId)
);

const collectionProfilesSlice = createSlice({
  name: 'collectionProfiles',
  initialState: initialState.collectionProfiles,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedSavedCollectionProfileList>) {
      state.data = action.payload;
    },
    deleteProfile(state, action: PayloadAction<string>) {
      if (state.data)
        state.data.results = state.data.results?.filter((val) => val.profile.id !== action.payload);
    },
    resetData() {
      return initialState.collectionProfiles;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload as PaginatedSavedCollectionProfileList,
      isLoading: false,
      error: null,
    }));
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
      error: {
        message: 'Rejected',
        status: 404,
      },
    }));
  },
});

export const collectionProfilesSelector = (state: IState) => state.collectionProfiles;
export const { updateData, deleteProfile, resetData } = collectionProfilesSlice.actions;
export default collectionProfilesSlice.reducer;
