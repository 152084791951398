import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

export function fetchCountries(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const { countries } = getState();
    if (countries.length > 0) {
      return countries;
    }

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query fetchCountries {
          countries {
            id
            name
            country_iso2_code
          }
        }
      `)
    );

    dispatch({
      type: ActionTypes.COUNTRIES__FETCH_SUCCESS,
      countries: result.countries,
    });
    return result.countries;
  };
}
