import { gengql } from '@/__generated__';
import { AgreementInput, AuthCreateTokenMutation, SignupType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import config from '@/config';
import { gtm } from '@/core/analytics';
import { GraphQLClient, basicAuth } from '@/core/api';
import { clearInvitationToken, getInvitationToken } from '@/core/invite';
import { clearTracking } from '@/core/tracking';
import { UserContext } from '@/core/user';
import { Viewer } from '@/core/viewer';
import { GroupDomain } from '@/group';
import { useApp } from '@/hooks/useAppContext';
import { AppStore } from '@/store';
import { clearCache, setCache } from '@/utils';

export { SignupType } from '@/__generated__/graphql';
export const defaultNext = '/dashboard';

function formatPath(path: string, domain?: GroupDomain, signupType?: SignupType) {
  const domainPart = domain ? `/${domain.subdomain}` : '';
  const signupTypePart = signupType ? `/${signupType === 'client' ? 'member' : signupType}` : '';
  return `${domainPart}${path}${signupTypePart}`;
}

function addSearchParam(urlStr: string, field: string, value?: unknown) {
  if (!value) return urlStr;
  const querySign = urlStr.indexOf('?') === -1 ? '?' : '&';
  return `${urlStr}${querySign}${field}=${value}`;
}

interface AuthPathOptions {
  to: string;
  domain?: GroupDomain;
  signupType?: SignupType;
  invite?: string;
  next?: string;
}

export function formatAuthPath({ to, domain, signupType, invite, next }: AuthPathOptions): string {
  let path = to;
  path = formatPath(path, domain, signupType);
  path = addSearchParam(path, 'invite', invite);
  path = addSearchParam(path, 'next', encodeURIComponent(next || ''));
  return path;
}

// At least 8 characters long
// At least 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special char
// List of special chart https://owasp.org/www-community/password-special-characters
const regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s"'^`~!#%&,-/:;<=>@_[\]\\$.|?*+(){}])[A-Za-z\d\s"'^`~!#%&,-/:;<=>@_[\]\\$.|?*+(){}]{8,16}/;
export function isValidPassword(password: string): boolean {
  if (!password) return false;
  return regex.test(password);
}

export type TokenMeta = AuthCreateTokenMutation['createToken'];

interface LoginMeta {
  inviteToken?: string;
  [key: string]: unknown;
}

async function createToken(
  client: GraphQLClient,
  userId: string | undefined,
  loginMeta: LoginMeta,
  recaptchaToken?: string
): Promise<TokenMeta> {
  const { inviteToken } = loginMeta || {};
  const data = await client.mutate(
    gengql(/* GraphQL */ `
      mutation authCreateToken(
        $userId: String
        $inviteToken: String
        $loginMeta: Json
        $recaptchaToken: String
      ) {
        createToken(
          user_id: $userId
          invite_token: $inviteToken
          login_meta: $loginMeta
          recaptcha_token: $recaptchaToken
          client_id: "node"
        ) {
          user_id
          invite_token
          login_meta
          token
        }
      }
    `),
    { userId, inviteToken, loginMeta, recaptchaToken }
  );

  return data.createToken;
}

export function logout(): void {
  clearCache('session');
  clearCache('access_token');
  clearCache('user_context');
}

export async function loginAs(
  client: GraphQLClient,
  store: AppStore,
  userIdOrEmail: string
): Promise<void> {
  const data = await client.query(
    gengql(/* GraphQL */ `
      query getUser($userId: String!) {
        user(id: $userId) {
          id
          username
        }
      }
    `),
    { userId: userIdOrEmail }
  );

  if (!data.user) {
    throw new Error('User not found');
  }

  const userId = data.user.id;
  try {
    const token = await createToken(client, userId, {
      method: 'admin',
      ua: window.navigator.userAgent,
    });
    logout();

    setCache('access_token', token);
  } catch {
    await store.dispatch(notify("You don't have permission to perform this action", 'error'));
    throw new Error('Login as user failed');
  }
}

interface CreateUserOptions {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  recaptcha_token?: string;
  landing_url?: string;
  invite?: string;
  tags?: string[];
  signup_type?: SignupType;
  subdomain?: string;
  phone?: string;
  agreements?: AgreementInput[];
}

async function createUser(client: GraphQLClient, options: CreateUserOptions) {
  const res = await client.mutate(
    gengql(/* GraphQL */ `
      mutation createUser(
        $email: String!
        $password: String!
        $first_name: String!
        $last_name: String!
        $recaptcha_token: String
        $landing_url: String
        $invite: String
        $tags: [String!]
        $signup_type: SignupType
        $subdomain: String
        $phone: String
        $agreements: [AgreementInput!]
      ) {
        createUser(
          email: $email
          password: $password
          first_name: $first_name
          last_name: $last_name
          recaptcha_token: $recaptcha_token
          landing_url: $landing_url
          invitation_token: $invite
          tags: $tags
          signup_type: $signup_type
          subdomain: $subdomain
          phone: $phone
          agreements: $agreements
        ) {
          user {
            id
          }
        }
      }
    `),
    options
  );

  return res.createUser;
}

export async function signup(
  client: GraphQLClient,
  options: CreateUserOptions,
  next = defaultNext
): Promise<void> {
  const data = { ...options };
  data.invite = data.invite || getInvitationToken();
  //data.login = data.login || true;

  const resp = await createUser(client, data);

  gtm({
    event: 'signup',
    signupType: data.signup_type || 'unknown',
    userId: resp.user.id,
  });

  // eslint-disable-next-line no-param-reassign
  client = new GraphQLClient(config.apiUrl, basicAuth(data.email, data.password));

  let token;
  try {
    token = await createToken(client, undefined, {
      method: 'password',
      signup: true,
      signupType: data.signup_type || undefined,
      landingUrl: data.landing_url,
      ua: window.navigator.userAgent,
    });
  } catch (err) {
    if (!['otp_enrollment_required', 'otp_required'].includes((err as Error).message)) {
      throw err;
    }
    window.location.assign(next);
    return;
  }

  clearInvitationToken();
  clearTracking();

  setCache('access_token', token);
  window.location.assign(next);
}

interface LoginOptions {
  email: string;
  password: string;
  otp?: string;
  recaptchaToken?: string;
  next?: string;
}

export default async function login({
  email,
  password,
  otp,
  recaptchaToken,
  next = defaultNext,
}: LoginOptions): Promise<void> {
  const inviteToken = getInvitationToken();

  const client = new GraphQLClient(config.apiUrl, basicAuth(email, password), {
    otp,
  });

  const token = await createToken(
    client,
    undefined,
    {
      method: 'password',
      inviteToken,
      ua: window.navigator.userAgent,
    },
    recaptchaToken
  );

  clearInvitationToken();

  setCache('access_token', token);
  window.location.assign(next);
}

export function useAuth(): { viewer: Viewer; userContext: UserContext } {
  const { viewer, store } = useApp();
  return { viewer, userContext: store.getState().ui.userContext };
}
