import { useMemo } from 'react';

import { darkGreen } from '@/theme/colors';

import SettingsMenu from '../EditProjectMembers/SettingsMenu';
import MaterialIcon from '../Icon/MaterialIcon';
import Link from '../Link';
import MemberList from '../MemberList';
import s from './ProjectBar.module.scss';

function ProjectBar({ viewer, project, showSettings, members }: any) {
  const { group } = project;
  const groupName = useMemo(() => (viewer.admin && group ? group.name : ''), [viewer.admin, group]);
  const titleSufix = useMemo(() => {
    const trackingCode = project.tracking_code;
    if (groupName && trackingCode) {
      return ` (${groupName}: ${trackingCode})`;
    } else if (groupName) {
      return ` (${groupName})`;
    } else if (trackingCode) {
      return ` (${trackingCode})`;
    }
  }, [groupName, project.tracking_code]);

  return (
    <div className={s.bar}>
      <div className={s.title}>
        <Link className={s.link} to={project.html_url}>
          <MaterialIcon color={darkGreen} className={s.icon} icon="arrow_back" />
          <span className={s.project}> Project:</span>
          {project.name}
          <span className={s.sufix}>{titleSufix}</span>
        </Link>
      </div>
      {}
      <div className={s.members}>
        {members && <MemberList members={members} />}
        {showSettings && <SettingsMenu project={project} />}
      </div>
    </div>
  );
}

export default ProjectBar;
