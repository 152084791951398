import { dialogClasses } from '@mui/material/Dialog';
import { dialogContentClasses } from '@mui/material/DialogContent';

import theme from '@/theme';

export const DialogClasses = {
  dialogTitle: 'd-dialog-title',
  dialogHeader: 'd-dialog-header',
  headerCloseButton: 'd-dialog-header-closebtn',
  ...dialogClasses,
};

const styles = {
  Dialog: {
    [`& .${dialogContentClasses.root}`]: {
      padding: '16px 24px',
    },
    [`& .${DialogClasses.dialogHeader}`]: {
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    },
  },
};

export default styles;
