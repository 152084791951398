import React from 'react';

import s from './SectionTitle.module.scss';

function SectionTitle({
  preTitle,
  title,
  subTitle,
  preTitleStyle,
  titleStyle,
  subTitleStyle,
  titleMargin = 45,
}: any) {
  const marginBottomStyle = { marginBottom: titleMargin };
  return [
    preTitle && (
      <h3 key="section-preTitle" style={preTitleStyle} className={s.preTitle}>
        {preTitle}
      </h3>
    ),
    title && (
      <h2
        key="section-title"
        style={{
          ...titleStyle,
          ...(subTitle ? {} : marginBottomStyle),
        }}
        className={s.title}
      >
        {title}
      </h2>
    ),
    subTitle && (
      <h4
        key="section-subTitle"
        style={{
          ...subTitleStyle,
          ...marginBottomStyle,
        }}
        className={s.subTitle}
      >
        {subTitle}
      </h4>
    ),
  ];
}

export default SectionTitle;
