import moment from 'moment-timezone';

import { gengql } from '@/__generated__';
import {
  GET_GROUP,
  LIST_GROUPS,
  UPDATE_GROUP,
  UPDATE_GROUP_ABOUT_PAGE,
  UPDATE_GROUP_BRANDING,
} from '@/group/store/queries';
import { AppThunk } from '@/store';
import { shouldResetCollection } from '@/utils';

import ActionTypes from './ActionTypes';

const {
  GROUP__BATCH_ADD,
  GROUP__UPDATE,
  GROUP__REMOVE_MEMBER,
  GROUP__REMOVE_MEMBER_AWAITING,
  GROUP__ADD_MEMBER,
  GROUP__UPDATE_MEMBER,
  GROUP__LIST_LOADING,
  GROUP__LIST_LOADED,
} = ActionTypes;

const NOT_FOUND = 'GraphQL Error: group does not exist';

export function fetchGroup(
  id: any,
  {
    transactions = false,
    members = false,
    awaitingMembers = false,
    aboutPage = false,
    publicData = false,
    profileKeywordsDefinition = false,
    internalNetwork = false,
    domain = false,
    savedSearches = false,
  } = {}
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const queryKey = JSON.stringify({
      transactions,
      members,
      awaitingMembers,
      aboutPage,
      publicData,
      profileKeywordsDefinition,
      internalNetwork,
    });

    for (const gedge of getState().groups.default.edges || []) {
      if (gedge.node.id !== id) continue;
      if (!gedge.expiresAt || moment().isSameOrAfter(gedge.expiresAt)) break;
      if (gedge.queryKey !== queryKey) break;
      return gedge.node; // re-use cache
    }

    try {
      const { data } = await graphql.client.query({
        query: GET_GROUP,
        variables: {
          id,
          members,
          awaitingMembers,
          transactions,
          aboutPage,
          profileKeywordsDefinition,
          internalNetwork,
          domain,
          savedSearches,
          publicData,
        },
      });

      const group = data.group;
      if (group) dispatch({ type: GROUP__UPDATE, group, queryKey });
      return group;
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (err.isPermissionError || err.message === NOT_FOUND) return;
      throw err;
    }
  };
}

export function fetchGroupMembers(
  groupId: any,
  { limit = 10, page, name, states }: any = {}
): AppThunk<Promise<any>> {
  return async (_: any, __: any, { graphql }: any) => {
    try {
      const result = await graphql.query(
        gengql(/* GraphQL */ `
          query actionfetchGroupMembers(
            $groupId: String!
            $limit: Int!
            $page: Int
            $name: String
            $states: [GroupMemberState]
          ) {
            groupMembers(
              group_id: $groupId
              limit: $limit
              page: $page
              name: $name
              states: $states
            ) {
              edges {
                node {
                  id
                  role
                  email
                  user {
                    id
                    html_url
                    picture_url
                    name
                    first_name
                    last_name
                    city
                    country
                  }
                }
              }
              pageInfo {
                total
                lastPage
              }
            }
          }
        `),
        { groupId, limit, page, states, name }
      );

      const { groupMembers } = result;
      return groupMembers;
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (err.isPermissionError || err.message === NOT_FOUND) return;
      throw err;
    }
  };
}

export function fetchGroups({
  // params
  cursor,

  collection = 'default',
  memberLimit = 5,
  pageSize = 10,
  internal = false,
  sharingExperts = false,
  memberOnly = true,
  name = '',

  // cache
  force,

  // fields
  billingAccount = false,

  stats = false,
  internalNetwork = false,
  keywordsConfig = false,
}: any = {}): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const reset = !cursor;
    // @ts-ignore
    const groups = getState().groups[collection];
    if (!force && reset && !shouldResetCollection(groups, pageSize)) return groups;

    dispatch({ type: GROUP__LIST_LOADING, collection });

    const variables = {
      internal,
      memberOnly,
      memberLimit,
      includeMemberLimit: memberLimit > 0,
      cursor,
      pageSize,
      sharingExperts,
      name,
      stats,
      billingAccount,
      internalNetwork,
      keywordsConfig,
    };

    try {
      const { data } = await graphql.client.query({ query: LIST_GROUPS, variables });
      const page = data.groups;

      dispatch({
        type: GROUP__BATCH_ADD,
        reset,
        collection,
        ...page,
      });

      dispatch({ type: GROUP__LIST_LOADED, collection });

      return page;
    } catch (err) {
      dispatch({ type: GROUP__LIST_LOADED, collection });
      throw err;
    }
  };
}

export function fetchAllGroups(opts?: Parameters<typeof fetchGroups>[0]): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    const { viewer } = getState();
    return dispatch(
      fetchGroups({
        collection: 'all',
        pageSize: 1000,
        memberLimit: 0,
        memberOnly: !viewer.admin,
        ...opts,
      })
    );
  };
}

export function fetchGroupKeywordCounts(keywordIDs: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchGroupKeywordCounts($keywordIDs: [ID!]!) {
          groupKeywordCounts(keyword_ids: $keywordIDs) {
            id
            count
            name
          }
        }
      `),
      { keywordIDs }
    );
    return result;
  };
}

export function addGroupMember(groupId: string, { userId, role }: any): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        gengql(/* GraphQL */ `
          mutation actionAddGroupMember($groupId: String!, $userId: String!, $role: GroupRole!) {
            addGroupMember(group_id: $groupId, user_id: $userId, role: $role) {
              id
              email
              role
              user {
                id
                first_name
                last_name
                name
                html_url
                picture_url
                city
                country
              }
            }
          }
        `),
        { groupId, userId, role }
      )
      .then(({ addGroupMember: member }) => {
        dispatch({
          type: GROUP__ADD_MEMBER,
          groupId,
          member,
        });
      });
}

export function updateGroupMember(
  groupId: any,
  groupMemberId: any,
  { role }: any
): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        gengql(/* GraphQL */ `
          mutation actionUpdateGroupMember($id: String!, $role: GroupRole!) {
            updateGroupMember(id: $id, role: $role) {
              id
              email
              user {
                id
              }
            }
          }
        `),
        { id: groupMemberId, role }
      )
      .then(() => {
        dispatch({
          type: GROUP__UPDATE_MEMBER,
          groupId,
          groupMemberId,
          role,
        });
      });
}

export function removeGroupMember(groupId: any, groupMemberId: any): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        gengql(/* GraphQL */ `
          mutation actionRemoveGroupMember($id: String!) {
            removeGroupMember(id: $id) {
              id
              email
              user {
                id
              }
            }
          }
        `),
        { id: groupMemberId }
      )
      .then(() => {
        dispatch({
          type: GROUP__REMOVE_MEMBER,
          groupId,
          groupMemberId,
        });
      });
}

export function approveGroupMember(memberId: string): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionApproveGroupMember($id: String!) {
          updateGroupMember(id: $id, state: active) {
            id
            email
            role
            user {
              id
              first_name
              last_name
              name
              html_url
              picture_url
              city
              country
            }
            group {
              id
            }
          }
        }
      `),
      { id: memberId }
    );
    const member = data!.updateGroupMember!;
    dispatch({
      type: GROUP__ADD_MEMBER,
      groupId: member.group?.id,
      member,
    });
    dispatch({
      type: GROUP__REMOVE_MEMBER_AWAITING,
      groupId: member.group?.id,
      groupMemberId: member.id,
    });
  };
}

export function denyGroupMember(memberId: string): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { removeGroupMember: removed } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDenyGroupMember($id: String!) {
          removeGroupMember(id: $id) {
            id
            email
            user {
              id
            }
            group {
              id
            }
          }
        }
      `),
      { id: memberId }
    );

    dispatch({
      type: GROUP__REMOVE_MEMBER_AWAITING,
      groupId: removed!.group!.id,
      groupMemberId: removed!.id,
    });
  };
}

export function updateGroup(group: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const params = {
      id: group.id,
      about: group.about,
      default_anonymous_messaging: group.default_anonymous_messaging,
      profile_keywords_definition: group.profile_keywords_definition,
      enforce_2fa: group.enforce_2fa,
    };

    const { data } = await graphql.client.mutate({ mutation: UPDATE_GROUP, variables: params });

    if (data?.updateGroup) {
      dispatch({
        type: GROUP__UPDATE,
        group: data.updateGroup,
      });

      return data.updateGroup;
    }
  };
}

export function updateGroupAboutPage(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: UPDATE_GROUP_ABOUT_PAGE,
      variables: options,
    });

    const aboutPage = data?.updateGroupAboutPage;

    if (aboutPage) {
      dispatch({
        type: GROUP__UPDATE,
        group: {
          id: options.group_id,
          about_page: aboutPage,
        },
      });

      return aboutPage;
    }
  };
}

export function updateGroupSavedSearches(
  groupId: string,
  // @ts-expect-error TS(7031): Binding element 'savedSearches' implicitly has an ... Remove this comment to see the full error message
  { saved_searches: savedSearches }
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { updateGroupSavedSearches } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateGroupSavedSearches(
          $group_id: String!
          $saved_searches: [GroupSavedSearchInput]
        ) {
          updateGroupSavedSearches(group_id: $group_id, saved_searches: $saved_searches) {
            id
            name
            url
          }
        }
      `),
      { group_id: groupId, saved_searches: savedSearches }
    );

    if (updateGroupSavedSearches && groupId) {
      dispatch({
        type: GROUP__UPDATE,
        group: { id: groupId, saved_searches: updateGroupSavedSearches },
      });
    }

    return updateGroupSavedSearches;
  };
}

export function updateGroupBranding(groupId: string, values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: UPDATE_GROUP_BRANDING,
      variables: {
        id: groupId,
        branding_logo_url: values.branding_logo_url,
        branding_show_poweredbyof: values.branding_show_poweredbyof,
      },
    });

    const updateGroup = data?.updateGroup;
    if (updateGroup) {
      dispatch({
        type: GROUP__UPDATE,
        group: updateGroup,
      });

      return updateGroup;
    }
  };
}
