import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';

import { AssignmentsOrdering } from '@/assignment/AssignmentsManager';
import { DEFAULT_ASSIGNMENTS_LIMIT } from '@/assignment/queries/assignments';
import { Drawer, IDrawerHeaderProps } from '@/componentsv2/Drawer';
import Dropdown from '@/componentsv2/Dropdown';
import Pagination from '@/componentsv2/Pagination';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { Assignments, PaginatedAssignmentsList } from '@/openapi';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';

import ExperienceRow from '../ExperienceRow';
import { IExperienceListDrawerProps } from './types';

const testId = 'of-experience-list-drawer';

// @TODO this could be based on the experience count we already have when opening the drawer (except on page refresh, can default to current value)
export const ContentSkeleton = ({ count = DEFAULT_ASSIGNMENTS_LIMIT }: { count?: number }) => {
  return (
    <>
      {Array(count)
        .fill('')
        .map((n, idx) => (
          <ExperienceRow label={n} key={idx} isLoading />
        ))}
    </>
  );
};

export type ExperienceListOrdering = 'dates' | '-dates';

export const SORT_OPTIONS: {
  value: ExperienceListOrdering;
  display: string;
}[] = [
  {
    value: '-dates',
    display: 'Most recent',
  },
  {
    value: 'dates',
    display: 'Oldest',
  },
];

export const getDetailPath = (assignment: Assignments) => {
  if (assignment.assignment_type === 'Job') {
    return '../' + PROFILE_ROUTES().job(assignment.assignment_id);
  }

  if (assignment.assignment_type === 'Project') {
    return '../' + PROFILE_ROUTES().project(assignment.assignment_parent_id);
  }

  return '../' + PROFILE_ROUTES().proposal(assignment.assignment_parent_id);
};

const ExperienceListDrawer = ({
  title,
  titleIsLoading,
  description,
  descriptionIsLoading,
  isLoading,
  isRefetching,
  isPreviousData,
  headerSlotActions,
  experiences,
  sortValue,
  handleSortOrderChange,
  limit = DEFAULT_ASSIGNMENTS_LIMIT,
  page,
  handlePageChange,
  resultsSlot,
  details = {},
  breadcrumbs,
}: IDrawerHeaderProps & {
  title: string;
  titleIsLoading?: boolean;
  description?: string;
  descriptionIsLoading?: boolean;
  className?: string;
  isLoading?: boolean;
  isRefetching?: boolean;
  isPreviousData?: boolean;
  headerSlotActions?: ReactNode;
  experiences?: PaginatedAssignmentsList;
  sortValue: AssignmentsOrdering;
  handleSortOrderChange: (newOrdering: ExperienceListOrdering) => void;
  limit?: number;
  page: number;
  handlePageChange: (newPage: number) => void;
  resultsSlot?: ReactNode;
  details?: Record<string, string | number | undefined>;
}) => {
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const { accrualIsStale } = useAppSelector(accrualSummarySelector);
  const handleCloseDrawer = useCloseDrawer();
  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);

  return (
    <>
      <Drawer.Header
        showUpdatingIndicator={isRefetching || accrualIsStale}
        updatingIndicatorText="Updating experiences"
        breadcrumbs={breadcrumbs}
        onClose={() => handleCloseDrawer()}
        slotActions={headerSlotActions}
      />
      <Drawer.Main ref={scrollableListRef} data-testid={testId}>
        <Drawer.Section>
          <div className="border-b border-grey-400 p-20">
            <div className="space-y-8">
              {isProfileLoading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <span className="body-1">{profileData?.full_name}&rsquo;s Experiences</span>
              )}
              <h2 className="hd-4">
                {titleIsLoading ? <Skeleton variant="text" width={300} /> : title}
              </h2>
              {description || descriptionIsLoading ? (
                <p className="text-grey-600 body-2">
                  {descriptionIsLoading ? <Skeleton variant="text" width={300} /> : description}
                </p>
              ) : null}
            </div>
          </div>
          <div className="p-16">
            <div className="flex justify-between">
              <Drawer.DetailsList
                details={{
                  'Total Experiences': !isLoading ? experiences?.count : undefined,
                  ...details,
                }}
              />
              <Dropdown
                id="experience-list-drawer-sort"
                options={SORT_OPTIONS}
                value={sortValue}
                onChange={(newValue) => {
                  handleSortOrderChange(newValue as ExperienceListOrdering);
                  handlePageChange(1);
                }}
              />
            </div>
            <div
              className={clsx('mt-16 flex flex-col gap-10', {
                'animate-pulse opacity-50': isPreviousData,
              })}
            >
              {isLoading ? (
                <ContentSkeleton count={limit} />
              ) : experiences?.results?.length ? (
                <>
                  {resultsSlot}
                  {experiences.results.map((experience) => {
                    return (
                      <ExperienceRow
                        experience={experience}
                        indicateExpandedKnowledge={experience.is_expanded}
                        key={experience.assignment_id}
                        to={getDetailPath(experience)}
                      />
                    );
                  })}
                </>
              ) : null}
            </div>
            {experiences?.count && experiences.count > limit ? (
              <div className="mt-auto">
                <Pagination
                  disabled={isLoading}
                  page={page}
                  count={Math.ceil(experiences.count / limit)}
                  handlePageChange={(_, newPage) => handlePageChange(newPage)}
                />
              </div>
            ) : null}
          </div>
        </Drawer.Section>
      </Drawer.Main>
    </>
  );
};

export { testId as ExperienceListDrawerTestId };
export type { IExperienceListDrawerProps };
export default ExperienceListDrawer;
