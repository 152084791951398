import React from 'react';

import s from './ActivityLog.module.scss';

function UserActivity({
  event,

  activity: {
    context: { user },
  },
}: any) {
  return (
    <div>
      {user ? <span className={s.userName}>{user.name}</span> : null} {event}
    </div>
  );
}

export default UserActivity;
