/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { ManageEmail } from '../models/ManageEmail';
import type { PaginatedAssignmentsList } from '../models/PaginatedAssignmentsList';
import type { PaginatedMissingExperiencesList } from '../models/PaginatedMissingExperiencesList';
import type { PaginatedProfileList } from '../models/PaginatedProfileList';
import type { PaginatedProfileMutualExperienceList } from '../models/PaginatedProfileMutualExperienceList';
import type { PatchedProfileUpdate } from '../models/PatchedProfileUpdate';
import type { PresignedUrlRequest } from '../models/PresignedUrlRequest';
import type { PresignedUrlResponse } from '../models/PresignedUrlResponse';
import type { Profile } from '../models/Profile';
import type { ProfileCreate } from '../models/ProfileCreate';
import type { ProfileUpdate } from '../models/ProfileUpdate';

export class ProfileService {
  /**
   * List all Profiles
   * List all Profiles
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedProfileList
   * @throws ApiError
   */
  public static profileList(
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedProfileList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile/',
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * Create a Profile
   * Create a Profile
   * @param requestBody
   * @returns ProfileCreate
   * @throws ApiError
   */
  public static profileCreate(requestBody: ProfileCreate): CancelablePromise<ProfileCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Profile by UUID
   * Retrieve a Profile by UUID
   * @param id A UUID string identifying this profile.
   * @returns Profile
   * @throws ApiError
   */
  public static profileRetrieve(id: string): CancelablePromise<Profile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Profile by UUID
   * Completely update a Profile by UUID
   * @param id A UUID string identifying this profile.
   * @param requestBody
   * @returns ProfileUpdate
   * @throws ApiError
   */
  public static profileUpdate(
    id: string,
    requestBody: ProfileUpdate
  ): CancelablePromise<ProfileUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Profile by UUID
   * Update specific fields within a Profile by UUID
   * @param id A UUID string identifying this profile.
   * @param requestBody
   * @returns Profile
   * @throws ApiError
   */
  public static profilePartialUpdate(
    id: string,
    requestBody?: PatchedProfileUpdate
  ): CancelablePromise<Profile> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Profile by UUID
   * Delete a Profile by UUID
   * @param id A UUID string identifying this profile.
   * @returns void
   * @throws ApiError
   */
  public static profileDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * List the colleagues by Profile by UUID
   * List the colleagues by Profile by UUID
   * @param id A UUID string identifying this profile.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedProfileMutualExperienceList
   * @throws ApiError
   */
  public static profileColleaguesList(
    id: string,
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedProfileMutualExperienceList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile/{id}/colleagues/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * List the experiences by Profile by UUID
   * List the experiences by Profile by UUID
   * @param id A UUID string identifying this profile.
   * @param scoreName The name of the profile knowledge score.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param scoreType The type of the profile knowledge score.
   * @returns PaginatedAssignmentsList
   * @throws ApiError
   */
  public static profileExperiencesV2List(
    id: string,
    scoreName: string,
    limit?: number,
    offset?: number,
    ordering:
      | ''
      | '-assignment_country'
      | '-dates'
      | '-title'
      | '-title_raw_term_name'
      | 'assignment_country'
      | 'dates'
      | 'title'
      | 'title_raw_term_name' = '',
    scoreType: '' | 'knowledge' | 'location' = ''
  ): CancelablePromise<PaginatedAssignmentsList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile/{id}/experiences_v2/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
        score_name: scoreName,
        score_type: scoreType,
      },
    });
  }

  /**
   * Get the experiences with missing information
   * Get the experiences with missing information
   * @param id A UUID string identifying this profile.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedMissingExperiencesList
   * @throws ApiError
   */
  public static profileMissingExperiencesList(
    id: string,
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedMissingExperiencesList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile/{id}/missing_experiences/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * Generate presigned urls to upload files into S3
   * Generate presigned urls to upload files into S3
   * @param id A UUID string identifying this profile.
   * @param requestBody
   * @returns PresignedUrlResponse
   * @throws ApiError
   */
  public static profilePresignedUrlCreate(
    id: string,
    requestBody: PresignedUrlRequest
  ): CancelablePromise<PresignedUrlResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile/{id}/presigned_url/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Upsert the work and personal emails
   * Upsert the work and personal emails
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static profileManageEmailCreate(requestBody?: ManageEmail): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile/manage_email/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
