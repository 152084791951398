import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import Form from '@/components/Form/Form';

function SaveButton(props: any) {
  return (
    <Button type="submit" size="small" {...props}>
      Save
    </Button>
  );
}

class SaveForm extends PureComponent {
  static defaultProps = {
    saveMessage: 'Settings updated.',
    saveLabel: 'Save',
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onSubmit, notify, saveMessage } = this.props;
    Promise.resolve(onSubmit(values)).then((errors) => {
      if (errors) return;
      notify(saveMessage);
    });
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
    const { children, saveLabel, saveButtonStyle, onReset, style, className } = this.props;

    return (
      <Form {...{ onReset, style, className }} onSubmit={this.handleSubmit}>
        {children}
        <SaveButton style={{ marginTop: 40, ...saveButtonStyle }} label={saveLabel} />
      </Form>
    );
  }
}

export default connect(undefined, {
  notify,
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof SaveForm' is not assignab... Remove this comment to see the full error message
})(SaveForm);
