import TextField from '@mui/material/TextField';
import { memo, useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { fetchGroups } from '@/actions/group';
import LongList from '@/components/LongList';
import TeamPreview from '@/components/TeamPreview';
import { RootState } from '@/store';
import { debounce, normalizeSpace } from '@/utils';

const connector = connect(
  (state: RootState) => {
    const page = state.groups.default;
    return {
      edges: page.edges,
      pageInfo: page.pageInfo,
      loading: page.loading,
      viewer: state.viewer,
    };
  },
  {
    fetchGroups,
  }
);

const TeamList = memo(
  ({ fetchGroups, viewer, pageInfo, edges, loading }: ConnectedProps<typeof connector>) => {
    const [name, setName] = useState('');
    const [enableSearch, setEnableSearch] = useState(false);

    const filterGroups = useCallback(
      ({ force, cursor, name }: any) => {
        fetchGroups({
          force,
          collection: 'default',
          cursor,
          memberOnly: !viewer.admin,
          memberLimit: 5,
          billingAccount: true,
          stats: true,
          name,
        });
      },
      [fetchGroups, viewer.admin]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(
      debounce((name: any) => {
        filterGroups({
          force: true,
          name,
        });
      }, 500),
      [filterGroups]
    );

    useEffect(() => {
      if (!enableSearch) return;
      handleSearch(name);
    }, [enableSearch, handleSearch, name]);

    useEffect(() => {
      if (enableSearch) return;

      setEnableSearch(!!edges.length && edges.length > 5);
    }, [edges.length, enableSearch]);

    const handleMore = () => {
      if (!pageInfo.hasNextPage) return;

      if (!edges.length) throw new Error('last group edge not found');

      filterGroups({
        cursor: edges[edges.length - 1].cursor,
        name,
      });
    };

    return (
      <div>
        <LongList
          loading={loading}
          title="Teams"
          hasNextPage={pageInfo.hasNextPage}
          onMore={handleMore}
          action={
            enableSearch && (
              <TextField
                value={name}
                fullWidth={false}
                onChange={({ target: { value } }) => setName(normalizeSpace(value))}
                placeholder="Filter by name"
              />
            )
          }
        >
          {edges.map((g: any) => (
            <TeamPreview key={g.node.id} team={g.node} />
          ))}
        </LongList>
      </div>
    );
  }
);
TeamList.displayName = 'TeamList';

export default connector(TeamList);
