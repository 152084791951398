import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  PaginatedProfileKnowledgeScoreList,
  ProfileKnowledgeRankScore,
  ProfileKnowledgeScoreService,
} from '@/openapi';

type ValueOf<T> = T[keyof T];

export const DEFAULT_KNOWLEDGES_LIMIT = 10;

export const EXPERT_LEVEL_ENUM = {
  FAMILIAR: 'familiar' as const,
  KNOWLEDGEABLE: 'knowledgeable' as const,
  PROFICIENT: 'proficient' as const,
  EXPERT: 'expert' as const,
};

export type ExpertLevelEnumType = ValueOf<typeof EXPERT_LEVEL_ENUM>;

export type KnowledgesOrdering = '' | '-percentile' | '-value' | 'percentile' | 'value';

export type KnowledgeType = 'location' | 'knowledge';

export const KNOWLEDGE_TYPE_ENUM = {
  TOPICS: 'Area of Expertise' as const,
  ACTIVITIES: 'Specialized Activity' as const,
  TOOLS: 'Tool' as const,
  GENERAL: 'Transversal Skill|Intrinsic Ability' as const,
};

export type KnowledgeEnumType = ValueOf<typeof KNOWLEDGE_TYPE_ENUM>;

const generateOrString = (val?: string | string[]) => (Array.isArray(val) ? val.join('|') : val);

type FetchKnowledges = {
  profileId: string;
  name?: string;
  query?: string;
  expertLevels?: Array<ExpertLevelEnumType> | '';
  knowledgeTypes?: Array<KnowledgeEnumType> | '';
  page?: number;
  limit?: number;
  ordering?: KnowledgesOrdering;
  type?: '' | 'location' | 'knowledge';
  regionType?: '' | 'country';
  includeExpanded?: boolean;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  keepPreviousData?: boolean;
  refetchOnMount?: boolean | 'always';
};

const fetchKnowledges = ({
  profileId,
  name,
  query,
  expertLevels,
  knowledgeTypes,
  page = 1,
  ordering = '-value',
  limit = DEFAULT_KNOWLEDGES_LIMIT,
  regionType = '',
  type = 'knowledge',
  includeExpanded = false,
}: FetchKnowledges): CancelablePromise<PaginatedProfileKnowledgeScoreList> => {
  const offset = (page - 1) * limit;
  return ProfileKnowledgeScoreService.profileKnowledgeScoreList(
    generateOrString(expertLevels),
    includeExpanded,
    generateOrString(knowledgeTypes),
    limit,
    name,
    offset,
    ordering,
    profileId,
    query,
    regionType,
    type
  );
};

type FetchAllKnowledges = Pick<
  FetchKnowledges,
  'profileId' | 'page' | 'limit' | 'ordering' | 'name' | 'query' | 'expertLevels' | 'knowledgeTypes'
>;

export const useAllKnowledgesQuery = (
  {
    profileId,
    ordering,
    page,
    limit,
    name,
    query,
    expertLevels,
    knowledgeTypes,
  }: FetchAllKnowledges,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: [
      'knowledge',
      {
        profileId,
        ordering,
        page,
        limit,
        name,
        query,
        expertLevels,
        knowledgeTypes,
      },
    ],
    queryFn: () =>
      fetchKnowledges({
        profileId,
        ordering,
        page,
        limit,
        name,
        query,
        expertLevels,
        knowledgeTypes,
      }),
    keepPreviousData: true,
    enabled: !!profileId,
    ...options,
  });
};

type FetchKnowledgesByType = Pick<
  FetchKnowledges,
  'profileId' | 'knowledgeTypes' | 'page' | 'limit' | 'ordering'
>;

export const useKnowledgesByTypeQuery = (
  { profileId, knowledgeTypes, ordering, page, limit }: FetchKnowledgesByType,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['knowledge', { profileId, knowledgeTypes, ordering, page, limit }],
    queryFn: () =>
      fetchKnowledges({
        profileId,
        knowledgeTypes,
        ordering,
        page,
        limit,
      }),
    keepPreviousData: true,
    enabled: !!knowledgeTypes?.length,
    ...options,
  });
};

type FetchKnowledgeByName = Pick<FetchKnowledges, 'profileId' | 'name' | 'includeExpanded'>;

export const useKnowledgeByNameQuery = (
  { profileId, name, includeExpanded }: FetchKnowledgeByName,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['knowledge', { profileId, name, includeExpanded }],
    queryFn: () =>
      fetchKnowledges({
        profileId,
        name,
        limit: 1,
        includeExpanded,
      }),
    enabled: !!name,
    ...options,
  });
};

type FetchLocations = Pick<FetchKnowledges, 'profileId' | 'page' | 'limit' | 'ordering'>;

export const useLocationsQuery = (
  { profileId, ordering, page, limit }: FetchLocations,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['locations', { profileId, ordering, page, limit }],
    queryFn: () =>
      fetchKnowledges({
        profileId,
        ordering,
        page,
        limit,
        regionType: 'country',
        type: 'location',
      }),
    keepPreviousData: true,
    enabled: !!profileId,
    ...options,
  });
};

type FetchLocation = Pick<FetchKnowledges, 'profileId' | 'name' | 'includeExpanded'>;

export const useLocationQuery = (
  { profileId, name, includeExpanded }: FetchLocation,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['locations', { profileId, name, includeExpanded }],
    queryFn: () =>
      fetchKnowledges({
        profileId,
        name,
        regionType: 'country',
        type: 'location',
        includeExpanded,
      }),
    keepPreviousData: true,
    ...options,
  });
};

type FetctchKnowledgeRank = {
  profileId: string;
  knowledgeName: string;
  knowledgeType: 'location' | 'knowledge';
};

const fetchKnowledgeRank = ({
  profileId,
  knowledgeName,
  knowledgeType = 'knowledge',
}: FetctchKnowledgeRank): CancelablePromise<Array<ProfileKnowledgeRankScore>> => {
  return ProfileKnowledgeScoreService.profileKnowledgeScoreRankList(
    knowledgeName,
    knowledgeType,
    profileId
  );
};

export const useKnowledgeRankQuery = ({
  profileId,
  knowledgeName,
  knowledgeType,
}: FetctchKnowledgeRank) => {
  return useQuery({
    queryKey: ['knowledge', { profileId, knowledgeName, knowledgeType, queryType: 'rank' }],
    queryFn: () =>
      fetchKnowledgeRank({
        knowledgeName,
        knowledgeType,
        profileId,
      }),
  });
};
