import { useSearchParams } from 'react-router-dom';

import {
  SEGMENTS_KNOWLEDGE_TYPE_DICT_REVERSE,
  displaySegment,
} from '@/dashboardv2/components/KnowledgeTable';
import { useProfileId } from '@/hooks/store';
import { KnowledgeEnumType, useKnowledgeByNameQuery } from '@/knowledge/queries/knowledge';
import { profileSelector } from '@/profilev2/store/profileSlice';
import APP_ROUTES, { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { knowledgeCapitalized } from '@/utils/capitalization';

import DetailsDrawerBase from './Base';

const KnowledgeDetailsDrawer = ({ disableEditing }: { disableEditing?: boolean }) => {
  const profileId = useProfileId();
  const [searchParams] = useSearchParams();
  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);
  const parsedKnowledgeName = decodeURIComponent(searchParams.get('q') ?? '');

  const { data, isFetched, isLoading } = useKnowledgeByNameQuery({
    profileId,
    name: parsedKnowledgeName ?? '',
    includeExpanded: true,
  });

  const knowledge = data?.results?.[0];
  const knowledgeType = knowledge?.knowledge_type;

  return (
    <DetailsDrawerBase
      disableEditing={disableEditing}
      knowledgeData={knowledge}
      isFetched={isFetched}
      isLoading={isLoading}
      type="knowledge"
      breadcrumbs={
        !isProfileLoading && !isLoading
          ? [
              {
                title: profileData?.full_name ?? '',
                to: '../' + APP_ROUTES.profile(profileId),
              },
              {
                title: displaySegment(knowledge?.knowledge_type as KnowledgeEnumType),
                to:
                  knowledgeType !== null && knowledgeType !== undefined
                    ? '../' +
                      PROFILE_ROUTES().knowledgeType(
                        SEGMENTS_KNOWLEDGE_TYPE_DICT_REVERSE[knowledgeType]
                      )
                    : '',
              },
              {
                title: knowledgeCapitalized(parsedKnowledgeName),
              },
            ]
          : []
      }
    />
  );
};

export default KnowledgeDetailsDrawer;
