import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { answerQuestion } from '@/actions/expertComplianceTraining';
import WizardPage from '@/components/WizardPage';

import s from './Questions.module.scss';

function Questions({ questions, subTitle, answers, handleAnswer, onNext }: any) {
  const correctAnswers = questions.filter((question: any) => {
    const selectedAnswer = answers.find((a: any) => a.questionId === question.id);
    return selectedAnswer && selectedAnswer.answerId === question.correct;
  });

  const hasSelectedAllCorrectAnswers = questions.length === correctAnswers.length;

  const questionsTitle = `Questions ${questions[0].id} - ${
    questions.slice(-1)[0].id
  } test your understanding. Please choose the most accurate response.`;

  return (
    <WizardPage
      title="Expert Compliance Training"
      subTitle={<h3>{subTitle}</h3>}
      onNext={hasSelectedAllCorrectAnswers ? onNext : null}
    >
      {questionsTitle}
      {questions.map((question: any) => {
        const selectedAnswer = answers.find((a: any) => a.questionId === question.id);
        return (
          <div className={s.question} key={question.id}>
            <p>
              {question.id}. {question.text}
            </p>
            {question.answers.map((answer: any) => (
              <div
                key={answer.id}
                className={s.answer}
                onClick={() => handleAnswer(question.id, answer.id)}
                onKeyDown={() => handleAnswer(question.id, answer.id)}
              >
                <input
                  type="radio"
                  name={`compliance_question_${question.id}`}
                  id={`compliance_question_${question.id}_${answer.id}`}
                  value={answer.id}
                  checked={selectedAnswer ? selectedAnswer.answerId === answer.id : false}
                />
                &nbsp;{answer.text}
              </div>
            ))}
            <p className={`compliance_question_${question.id}-result`}>
              {selectedAnswer && selectedAnswer.answerId === question.correct && (
                <i className="fa fa-check light-green" aria-hidden="true" />
              )}
            </p>
          </div>
        );
      })}

      <Divider />

      {!hasSelectedAllCorrectAnswers && (
        <p className={s.message}>You must select all the correct answers before continuing.</p>
      )}
    </WizardPage>
  );
}

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.number.isRequired,
      questionId: PropTypes.number.isRequired,
    })
  ),
  subTitle: PropTypes.string.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

const Component = Questions;

export default connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    answers: state.expertComplianceTraining.answers,
  }),
  {
    handleAnswer: answerQuestion,
  }
)(Component);
