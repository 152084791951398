import makeStyles from '@mui/styles/makeStyles';

import EditIcon from '@/components/EditIcon/EditIcon';
import KeywordList from '@/components/KeywordList/KeywordList';
import RegionList from '@/components/RegionList/RegionList';
import SectorList from '@/components/SectorList/SectorList';
import { darkGray } from '@/theme/colors';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
    marginBottom: -10,
  },
  list: {
    marginBottom: 5,
  },
}));

export default ({ profile, editable, onEdit }: any) => {
  const s = getStyles();

  const { regions, sectors } = profile;

  const shouldShowEmpty = editable && !regions.length && !sectors.length;

  return (
    <div className={s.row}>
      <div>
        {regions.length > 0 && (
          <RegionList className={s.list} regions={regions.map((r: any) => r.name)} />
        )}

        {sectors.length > 0 && (
          <SectorList className={s.list} sectors={sectors.map((s: any) => s.name)} />
        )}

        {shouldShowEmpty && (
          /* eslint-disable-next-line */
          <a onClick={onEdit}>
            <KeywordList className={s.list} iconColor={darkGray} emptyText="Add expertise" />
          </a>
        )}
      </div>

      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};
