import { LinearProgress, Slider } from '@mui/material';
import { FC, memo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import AudioPlayer from '../AudioPlayer';
import CircularProgress from '../CircularProgress';
import FAIcon from '../Icon/FAIcon';
import IconButton from '../IconButton';
import s from './MediaPlayer.module.scss';

interface MediaPlayerProps {
  src: string;
  enabled: boolean;
  isAudioOnly?: boolean;
}

const MediaPlayer: FC<MediaPlayerProps> = memo(({ src, enabled, isAudioOnly }) => {
  const playerRef = useRef<ReactPlayer | null>(null);

  const [state, setState] = useState({
    ready: false,
    playing: false,
    muted: false,
    seeking: false,
    currentTime: 0,
    totalTime: 0,
    loaded: 0,
  });

  const handlePlayPause = () => {
    setState((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  };

  const handlePause = () => {
    setState((prevState) => ({ ...prevState, playing: false }));
  };

  const handleProgress = (playerState: any) => {
    // We only want to update time slider if we are not currently seeking
    if (!playerState.seeking) {
      setState((prevState) => ({
        ...prevState,
        currentTime: playerState.playedSeconds,
        loaded: playerState.loaded,
      }));
    }
  };

  const handleDuration = (totalTime: any) => {
    setState((prevState) => ({ ...prevState, totalTime }));
  };

  const handleSeekMouseDown = () => {
    setState((prevState) => ({ ...prevState, seeking: true }));
  };

  const handleSeekChange = (_: any, value: any) => {
    playerRef.current?.seekTo(value);
    setState((prevState) => ({ ...prevState, currentTime: value }));
  };

  const handleSeekMouseUp = () => {
    setState((prevState) => ({ ...prevState, seeking: false }));
  };

  const handleToggleMuted = () => {
    setState((prevState) => ({
      ...prevState,
      muted: !prevState.muted,
    }));
  };

  const handleOnReady = () => {
    setState((prev) => ({ ...prev, ready: true }));
  };

  const { playing, muted, currentTime, totalTime, ready, loaded } = state;
  const validTime = isTimeValid(totalTime);
  if (!enabled) handlePause();

  if (isAudioOnly) {
    return <AudioPlayer recordingUrl={src} />;
  }

  return (
    <div className={s.root}>
      <ReactPlayer
        ref={playerRef}
        width="100%"
        height="100%"
        playing={playing}
        muted={muted}
        onPause={handlePause}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onReady={handleOnReady}
        url={src}
        config={{
          file: {
            //tracks: [{ kind: 'captions', default: true }],
            attributes: {
              onContextMenu: (e: any) => e.preventDefault(),
              preload: 'metadata',
            },
          },
        }}
      />
      <div style={{ position: 'relative', width: '100%', height: 0 }}>
        <Slider
          style={{
            position: 'relative',
            top: isAudioOnly ? -15 : -20,
            cursor: 'pointer',
          }}
          min={0}
          max={validTime ? totalTime : 100}
          value={validTime ? currentTime : 0}
          disabled={!validTime}
          onChange={handleSeekChange}
          onMouseDown={handleSeekMouseDown}
          onChangeCommitted={handleSeekMouseUp}
          classes={{
            thumb: s.thumb,
            track: s.track,
          }}
        />
        <LinearProgress
          style={{
            position: 'absolute',
            width: '100%',
            height: 2,
            zIndex: 1,
            top: isAudioOnly ? -2 : -7,
          }}
          color="secondary"
          variant="determinate"
          value={loaded * 100}
        />
      </div>
      <div className={s.controlContainer}>
        <div className={s.leftControls}>
          <div style={{ width: 50, display: 'inline-block' }}> {formatTime(currentTime)} </div>/
          <div style={{ marginLeft: 5, display: 'inline-block' }}> {formatTime(totalTime)} </div>
        </div>
        <div className={s.centerControls}>
          {ready ? (
            <IconButton onClick={handlePlayPause} disabled={!enabled}>
              <FAIcon icon={playing ? 'pause' : 'play'} size={28} className={s.controlButton} />
            </IconButton>
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </div>
        <div className={s.rightControls}>
          <IconButton
            onClick={handleToggleMuted}
            style={{ width: 21, textAlign: 'left' }}
            disabled={!enabled}
          >
            <FAIcon
              icon={muted ? 'volume-off' : 'volume-up'}
              size={28}
              className={s.controlButton}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
});
MediaPlayer.displayName = 'MediaPlayer';

function formatTime(timeInSeconds: number) {
  if (!isTimeValid(timeInSeconds)) return '--:--';

  const mins = Math.floor(timeInSeconds / 60);
  const secs = Math.floor(timeInSeconds % 60);

  return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

function isTimeValid(time: number) {
  if (time === 0) return true;
  if (!time) return false;
  if (Number.isNaN(time)) return false;
  if (time === Infinity) return false;
  return true;
}

export default MediaPlayer;
