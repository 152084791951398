import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { typeCollectionOrdering } from '@/collection/components/CollectionSelector';
import { SavedCollection, SavedCollectionService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const myCollection = createAsyncThunk(
  'collections/myCollection',
  async ({
    collaboratorCanJoin,
    collaboratorCannotJoin,
    limit,
    offset,
    ordering,
    privacy,
    profileCanJoin,
    profileCannotJoin,
  }: {
    collaboratorCanJoin?: string;
    collaboratorCannotJoin?: string;
    limit: number;
    offset?: number;
    ordering?: typeCollectionOrdering;
    privacy: '' | 'PRIVATE' | 'PUBLIC';
    profileCanJoin?: string;
    profileCannotJoin?: string;
  }) =>
    await SavedCollectionService.savedCollectionMyCollectionsList(
      collaboratorCanJoin,
      collaboratorCannotJoin,
      limit,
      offset,
      ordering,
      privacy,
      profileCanJoin,
      profileCannotJoin
    )
);

const myCollectionSlice = createSlice({
  name: 'myCollections',
  initialState: initialState.myCollection,
  reducers: {
    updateData: (state, action: PayloadAction<SavedCollection[]>) => ({
      ...state,
      data: { results: action.payload },
    }),
    resetData: () => initialState.myCollection,
  },
  extraReducers: (builder) => {
    builder.addCase(myCollection.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(myCollection.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(myCollection.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const myCollectionSelector = (state: IState) => state.myCollection;
export const { updateData, resetData } = myCollectionSlice.actions;
export default myCollectionSlice.reducer;
