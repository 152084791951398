import React from 'react';
import { useLoaderData } from 'react-router-dom';

import Body from '../Body';
import Layout from './Layout';

function LayoutPage({ children, bodyClassName, bodyStyle, ...other }: any) {
  const loaderData = useLoaderData();
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  children = loaderData?.children || children;

  return (
    <Layout {...other}>
      <Body className={bodyClassName} style={{ paddingTop: 20, paddingBottom: 30, ...bodyStyle }}>
        {children}
      </Body>
    </Layout>
  );
}

export default LayoutPage;
