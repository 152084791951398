// @ts-nocheck
import { GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { SetStateAction } from 'react';
import { Edit2, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';

import Button from '@/componentsv2/Button';
import Meter from '@/componentsv2/Meter';
import KnowledgeLevelLabel from '@/knowledge/components/KnowledgeLevelLabel';
import {
  selectKnowledge,
  showDrawer as showAddKnowledgeDrawer,
} from '@/profilev2/store/addKnowledgeSlice';
import { DASHBOARD_ROUTES } from '@/routes/APP_ROUTES';
import { Dispatch } from '@/store';
import { showDialog } from '@/ui/store/uiSlice';
import { knowledgeCapitalized } from '@/utils/capitalization';
import { displayWithInDaysOrDate } from '@/utils/date';
import { displayPercentileStatus } from '@/utils/displayPercentileStatus';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

import { displaySegment } from '.';

export const knowledgeTableColumns = (
  dispatch: Dispatch,
  setKnowledge: React.Dispatch<SetStateAction<Record<string, unknown>>>,
  rowId: string | undefined
) => [
  {
    headerName: 'Skills',
    field: 'name',
    width: 290,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={DASHBOARD_ROUTES.knowledge(params.row.name ?? '')}
        className="text-secondary-main decoration-secondary-main/40 hover:decoration-secondary-main"
      >
        {knowledgeCapitalized(params.row.name)}
      </Link>
    ),
  },
  {
    headerName: 'Type',
    field: 'knowledge_type',
    width: 130,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueFormatter: (params: GridValueFormatterParams) => displaySegment(params.value),
  },
  {
    headerName: 'Percentile',
    field: 'percentile',
    width: 120,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueFormatter: (params: GridValueFormatterParams) => displayPercentileStatus(params.value),
  },
  {
    headerName: 'Level',
    field: 'expert_level',
    width: 150,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => (
      <KnowledgeLevelLabel value={knowledgeScoreToPercent(+(params.row.value || 0))} />
    ),
  },
  {
    headerName: 'Score',
    field: 'value',
    width: 150,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => (
      <Meter className="w-full" value={knowledgeScoreToPercent(+(params.value || 0))} />
    ),
  },
  {
    headerName: 'Last Used',
    field: 'latest_date',
    width: 100,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    valueFormatter: (params: GridValueFormatterParams) =>
      displayWithInDaysOrDate(params.value, 7, 'This week'),
  },
  {
    headerName: '',
    field: 'actions',
    width: 70,
    filterable: false,
    hideable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      return rowId === params.id ? (
        <>
          <Button
            variant="tertiary"
            srText="edit"
            size="small"
            onClick={() => {
              dispatch(
                selectKnowledge({
                  name: params.row.name,
                  type: 'knowledge_raw',
                  knowledge_type: params.row.knowledge_type,
                  knowledge_id: params.row.source.object_id,
                })
              );
              dispatch(showAddKnowledgeDrawer());
            }}
            startIcon={Edit2}
          />
          <Button
            variant="tertiary"
            srText="delete"
            size="small"
            onClick={() => {
              setKnowledge(params.row);
              dispatch(showDialog('DELETE_KNOWLEDGE'));
            }}
            startIcon={Trash2}
          />
        </>
      ) : null;
    },
  },
];
