// Converted from https://github.com/icholy/Duration.js/blob/golang_compatible/duration.js

const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const MICROSECOND = MILLISECOND / 1000;
const NANOSECOND = MICROSECOND / 1000;

const UNIT_MAP = {
  ns: NANOSECOND,
  us: MICROSECOND,
  µs: MICROSECOND,
  μs: MICROSECOND,
  ms: MILLISECOND,
  s: SECOND,
  m: MINUTE,
  h: HOUR,
  d: DAY,
  w: WEEK,
};

class Duration {
  private _milliseconds: number;

  constructor(value?: any) {
    if (value instanceof Duration) {
      this._milliseconds = value._milliseconds;
    } else {
      switch (typeof value) {
        case 'number':
          if (!isFinite(value)) {
            throw new Error(`invalid duration: ${value}`);
          }
          this._milliseconds = value;
          break;
        case 'string':
          this._milliseconds = Duration.parse(value)._milliseconds;
          break;
        case 'undefined':
          this._milliseconds = 0;
          break;
        default:
          throw new Error(`invalid duration: ${value}`);
      }
    }
  }

  // Static properties for predefined durations
  static millisecond = new Duration(MILLISECOND);
  static second = new Duration(SECOND);
  static minute = new Duration(MINUTE);
  static hour = new Duration(HOUR);
  static day = new Duration(DAY);
  static week = new Duration(WEEK);

  // Static methods for creating durations
  static milliseconds(milliseconds: number) {
    return new Duration(milliseconds * MILLISECOND);
  }

  static seconds(seconds: number) {
    return new Duration(seconds * SECOND);
  }

  static minutes(minutes: number) {
    return new Duration(minutes * MINUTE);
  }

  static hours(hours: number) {
    return new Duration(hours * HOUR);
  }

  static days(days: number) {
    return new Duration(days * DAY);
  }

  static weeks(weeks: number) {
    return new Duration(weeks * WEEK);
  }

  // Instance methods
  nanoseconds() {
    return Math.floor(this._milliseconds / NANOSECOND);
  }

  microseconds() {
    return Math.floor(this._milliseconds / MICROSECOND);
  }

  milliseconds() {
    return this._milliseconds;
  }

  seconds() {
    return Math.floor(this._milliseconds / SECOND);
  }

  minutes() {
    return Math.floor(this._milliseconds / MINUTE);
  }

  hours() {
    return Math.floor(this._milliseconds / HOUR);
  }

  days() {
    return Math.floor(this._milliseconds / DAY);
  }

  weeks() {
    return Math.floor(this._milliseconds / WEEK);
  }

  toString() {
    let str = '';
    let milliseconds = Math.abs(this._milliseconds);
    const sign = this._milliseconds < 0 ? '-' : '';

    // No units for 0 duration
    if (milliseconds === 0) {
      return '0';
    }

    // Hours
    const hours = Math.floor(milliseconds / HOUR);
    if (hours !== 0) {
      milliseconds -= HOUR * hours;
      str += `${hours}h`;
    }

    // Minutes
    const minutes = Math.floor(milliseconds / MINUTE);
    if (minutes !== 0) {
      milliseconds -= MINUTE * minutes;
      str += `${minutes}m`;
    }

    // Seconds
    const seconds = Math.floor(milliseconds / SECOND);
    if (seconds !== 0) {
      milliseconds -= SECOND * seconds;
      str += `${seconds}s`;
    }

    // Milliseconds
    if (milliseconds !== 0) {
      str += `${milliseconds}ms`;
    }

    return sign + str;
  }

  valueOf() {
    return this._milliseconds;
  }

  roundTo(duration: number) {
    const ms = new Duration(duration)._milliseconds;
    this._milliseconds = ms * Math.round(this._milliseconds / ms);
  }

  isGreaterThan(duration: number | Duration) {
    const otherMilliseconds =
      duration instanceof Duration ? duration._milliseconds : new Duration(duration)._milliseconds;
    return this._milliseconds > otherMilliseconds;
  }

  isLessThan(duration: number | Duration) {
    const otherMilliseconds =
      duration instanceof Duration ? duration._milliseconds : new Duration(duration)._milliseconds;
    return this._milliseconds < otherMilliseconds;
  }

  isEqualTo(duration: number | Duration) {
    const otherMilliseconds =
      duration instanceof Duration ? duration._milliseconds : new Duration(duration)._milliseconds;
    return this._milliseconds === otherMilliseconds;
  }

  after(date: Date) {
    return new Date(date.valueOf() + this._milliseconds);
  }

  // Static parsing method
  static parse(duration: string) {
    if (!duration || duration === '0' || duration === '+0' || duration === '-0') {
      return new Duration(0);
    }

    const regex = /([-+]?\d*\.?\d+)([a-zµμ]+)/g;
    let total = 0;
    let count = 0;
    let match;

    while ((match = regex.exec(duration))) {
      const unit = match[2] as keyof typeof UNIT_MAP;
      const value = parseFloat(match[1]);
      count++;

      if (isNaN(value)) {
        throw new Error('invalid duration');
      }

      if (UNIT_MAP[unit] === undefined) {
        throw new Error(`invalid unit: ${unit}`);
      }

      total += value * UNIT_MAP[unit];
    }

    if (count === 0) {
      throw new Error('invalid duration');
    }

    return new Duration(total);
  }

  // Additional static methods
  static since(date: Date) {
    return new Duration(Date.now() - date.valueOf());
  }

  static until(date: Date) {
    return new Duration(date.valueOf() - Date.now());
  }

  static fromMicroseconds(us: number) {
    return new Duration(us / 1000);
  }

  static fromNanoseconds(ns: number) {
    return new Duration(ns / 1e6);
  }

  static between(a: Date, b: Date) {
    return new Duration(b.valueOf() - a.valueOf());
  }

  static add(a: number | Duration, b: number | Duration) {
    const aMilliseconds = a instanceof Duration ? a._milliseconds : new Duration(a)._milliseconds;
    const bMilliseconds = b instanceof Duration ? b._milliseconds : new Duration(b)._milliseconds;
    return new Duration(aMilliseconds + bMilliseconds);
  }

  static subtract(a: number | Duration, b: number | Duration) {
    const aMilliseconds = a instanceof Duration ? a._milliseconds : new Duration(a)._milliseconds;
    const bMilliseconds = b instanceof Duration ? b._milliseconds : new Duration(b)._milliseconds;
    return new Duration(aMilliseconds - bMilliseconds);
  }

  static multiply(a: number | Duration, b: number) {
    const aMilliseconds = a instanceof Duration ? a._milliseconds : new Duration(a)._milliseconds;
    return new Duration(aMilliseconds * b);
  }

  static divide(a: number | Duration, b: number) {
    const aMilliseconds = a instanceof Duration ? a._milliseconds : new Duration(a)._milliseconds;
    return aMilliseconds / b;
  }
}

export default Duration;
