import { buttonClasses } from '@mui/material/Button';
import { containerClasses } from '@mui/material/Container';

import { textClasses } from '@/componentsv2/Text';
import theme from '@/theme';

const ns = 'hero';

export const heroClasses = {
  root: `${ns}__root`,
  title: `${ns}__title`,
  tagline: `${ns}__tagline`,
  action: `${ns}__action`,
};

const styles = {
  hero: {
    color: 'white',
    backgroundColor: '#304D60', // Not found in the design system
    padding: '90px',
    paddingBottom: '145px',

    [`& .${containerClasses.root}`]: {
      display: 'grid',
      rowGap: '20px',
      justifyItems: 'center',
      textAlign: 'center',
    },
    [`& .${heroClasses.title}.${textClasses.root}`]: {
      fontSize: '56px',
      lineHeight: '110%',
    },
    [`& .${heroClasses.tagline}.${textClasses.root}`]: {
      fontSize: '20px',
      lineHeight: '180%',
      color: theme.palette.grey['100'],
      maxWidth: '960px',
    },
    [`& .${buttonClasses.root}`]: {
      height: '60px',
      minWidth: 'auto',
      borderRadius: '2px',

      [`& .${textClasses.root}`]: {
        fontSize: '20px',
        fontWeight: 700,
        textTransform: 'none',
      },
    },
  },
};

export default styles;
