import { FC, memo, useCallback } from 'react';

import Dialog, { DialogProps } from '../Dialog';
import Form from './Form';

interface EditMembersDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose?: (c?: boolean) => void;
  onSubmit?: (values: any) => any;
  handleSubmit?: () => void;
  array?: any;
  users?: any;
  members?: any;
  project?: any;
  viewer?: any;
  saveLabel?: string;
  initialValues?: any;
  usersLocked?: boolean;
}

const EditMembersDialog: FC<EditMembersDialogProps> = memo(
  ({
    onClose,
    onSubmit,
    //handleSubmit,
    array,
    users,
    members,
    project,
    viewer,
    saveLabel,
    initialValues,
    usersLocked,
    ...other
  }) => {
    const handleClose = useCallback(() => {
      if (onClose) {
        onClose(true);
      }
    }, [onClose]);

    const handleSubmit = useCallback(
      (values: any) => {
        if (onSubmit) {
          const errors = onSubmit(values);
          if (errors) {
            return;
          }
        }

        if (onClose) {
          onClose(true);
        }
      },
      [onClose, onSubmit]
    );

    return (
      <Dialog
        maxWidth="md"
        title="Project Members"
        subTitle="Members can see this project, add other members, and join any scheduled calls."
        onClose={handleClose}
        {...other}
      >
        <Form
          buttonSize="medium"
          onSubmit={handleSubmit}
          onReset={handleClose}
          resetLabel="Cancel"
          submitLabel="Save"
          searchLabel="Find or invite users"
          searchHint="Start typing the name of a user or an email address"
          project={project}
          initialValues={initialValues}
          usersLocked={usersLocked}
        />
      </Dialog>
    );
  }
);
EditMembersDialog.displayName = 'EditMembersDialog';

export default EditMembersDialog;
