import { black, sand } from '@/theme/colors';

import MarketingSection from '../MarketingSection';
import s from './Customers.module.scss';

const defaultCustomers = ['bose', 'deloitte', 'opic', 'rockefeller', 'dhl', 'worldbank'];

// const logos = new Map(
//   defaultCustomers.map((c) => [
//     c,
//     [require(`./logos/${c}.png`), require(`./logos/${c}@2x.png`)],
//   ])
// );

const logoImages: Record<string, { default: string }> = import.meta.glob('./logos/*.png', {
  eager: true,
});

const logos = new Map();

defaultCustomers.forEach((c) => {
  const img1xPath = `./logos/${c}.png`;
  const img2xPath = `./logos/${c}@2x.png`;

  const img1xModule = logoImages[img1xPath];
  const img2xModule = logoImages[img2xPath];

  if (img1xModule && img2xModule) {
    const img1x = img1xModule.default;
    const img2x = img2xModule.default;
    logos.set(c, [img1x, img2x]);
  } else {
    console.warn(`Images for customer "${c}" not found.`);
  }
});

function Img({ src, ...other }: any) {
  let source;
  let set = [];
  if (typeof src === 'string') {
    source = src;
  } else {
    source = src[0];
    set = src.slice(1).map((s: any, i: any) => `${s} ${i + 2}x`);
  }

  return (
    <img alt="" src={source} srcSet={(set.length > 0 && set.join(', ')) || undefined} {...other} />
  );
}

function Customers({ ...other }) {
  return (
    <MarketingSection
      style={{ backgroundColor: sand }}
      title="Trusted by Government Contractors, NGOs, Consulting Firms, and Investors"
      titleStyle={{
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 'normal',
        color: black,
      }}
      titleMargin={10}
      {...other}
    >
      <div className={s.container}>
        {defaultCustomers.map((c) => (
          <Img key={c} id={c} src={logos.get(c)} draggable="false" alt={c} className={s.img} />
        ))}
      </div>
    </MarketingSection>
  );
}

export default Customers;
