import Tooltip from 'rc-tooltip';
import React, { PureComponent } from 'react';

import { formatCreditsText } from '@/utils';

import Button from '../Button';
import s from './CreditsButton.module.scss';

interface CreditsButtonProps {
  credits: number;
  disabled: boolean;
  type: 'button' | 'reset' | 'submit';
  creditsTooltip:
    | (() => React.ReactElement | number | string)
    | React.ReactElement
    | number
    | string
    | Iterable<React.ReactNode>
    | React.ReactPortal;
  children: React.ReactNode;
}

class CreditsButton extends PureComponent<CreditsButtonProps> {
  render() {
    const { creditsTooltip, credits, ...other } = this.props;

    const creditsComponent = <div className={s.credits}>{formatCreditsText(credits)} →</div>;

    return (
      <div className={s.root}>
        {!!credits &&
          (creditsTooltip ? (
            <Tooltip
              overlay={creditsTooltip}
              trigger={['hover', 'click']}
              overlayStyle={{ zIndex: 2000 }}
              placement="left"
            >
              {creditsComponent}
            </Tooltip>
          ) : (
            creditsComponent
          ))}
        <Button size="medium" {...other} />
      </div>
    );
  }
}

export default CreditsButton;
