import { gengql } from '@/__generated__';
import { GetMetricsConsultationQuery } from '@/__generated__/graphql';

export const GET_CONSULTATION = gengql(/* GraphQL */ `
  query getMetricsConsultation($id: String!) {
    consultation(id: $id) {
      id
      expert_identifier
      requester_identifier
      conference {
        id
        carrier
        participants {
          id
          identifier
          call
          metrics
        }
        attachments {
          file_type
          location
          recording_type
        }
        registrants {
          email
          name
          identifier
          invited_by
        }
      }
    }
  }
`);

export type Conference = NonNullable<
  NonNullable<GetMetricsConsultationQuery['consultation']>['conference']
>;
