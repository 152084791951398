import { Chip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';
import { highlight } from '@/utils';

import ChipList from '../ChipList';
import Link from '../Link';

interface SectorListProps {
  sectors: string[];
  children?: React.ReactNode;
  allSectors?: { id: string; name: string }[];
  lookup?: boolean;
  icon?: string;
  [x: string]: any;
}

class SectorList extends React.Component<SectorListProps> {
  render() {
    const { sectors, children, allSectors, lookup, icon = 'factory', ...other } = this.props;

    return (
      <ChipList {...other} icon={icon}>
        {sectors.map((key: any) => {
          let name = key;
          if (lookup) {
            const sector = allSectors?.find((s: any) => s.id === key);
            if (sector) name = sector.name;
          }

          return (
            <Link key={key} to={`/search?q=${encodeURIComponent(name)}`}>
              <Chip
                style={{
                  cursor: 'pointer',
                  width: 'initial',
                  // @ts-expect-error TS(2769): No overload matches this call.
                  label: { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
                label={<span dangerouslySetInnerHTML={{ __html: highlight(name) }} />}
              />
            </Link>
          );
        })}
      </ChipList>
    );
  }
}

export default connect((state: RootState) => ({
  allSectors: state.sectors.all,
}))(SectorList);
