import makeStyles from '@mui/styles/makeStyles';

import EditIcon from '@/components/EditIcon/EditIcon';
import { darkBrown, darkGray } from '@/theme/colors';

const getStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  headline: {
    fontSize: 30,
    color: darkBrown,
    margin: 0,
    fontWeight: 'bold',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      textAlign: 'center',
      flex: 1,
    },
  },
  headlineEmpty: {
    fontSize: 30,
    margin: 0,
    lineHeight: '24px',
    fontWeight: 'bold',
    color: darkGray,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      textAlign: 'center',
      flex: 1,
    },
  },
}));

export default ({ profile, editable, onEdit }: any) => {
  const s = getStyles();

  return (
    (profile.title || editable) && (
      <div className={s.row}>
        {profile.title ? (
          <h3 className={s.headline}>{profile.title}</h3>
        ) : (
          editable && (
            /* eslint-disable-next-line */
            <a onClick={onEdit}>
              <h3 className={s.headlineEmpty}>Introduce yourself with a headline</h3>
            </a>
          )
        )}
        {editable && <EditIcon onClick={onEdit} />}
      </div>
    )
  );
};
