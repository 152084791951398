import { Chip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';
import { highlight } from '@/utils';

import ChipList from '../ChipList';
import Link from '../Link';

interface RegionListProps {
  regions: string[];
  allRegions?: { id: string; name: string }[];
  lookup?: boolean;
  icon?: string;
  [key: string]: any;
}

class RegionList extends React.PureComponent<RegionListProps> {
  render() {
    const { regions, children, allRegions, lookup, icon = 'globe', ...other } = this.props;

    return (
      <ChipList {...other} icon={icon}>
        {regions.map((key: any) => {
          let name = key;
          if (lookup) {
            const region = allRegions?.find((r: any) => r.id === key);
            if (region) name = region.name;
          }

          return (
            <Link key={key} to={`/search?q=${encodeURIComponent(name)}`}>
              <Chip
                style={{
                  cursor: 'pointer',
                  width: 'initial',
                  // @ts-expect-error TS(2769): No overload matches this call.
                  label: { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
                label={<span dangerouslySetInnerHTML={{ __html: highlight(name) }} />}
              />
            </Link>
          );
        })}
      </ChipList>
    );
  }
}

export default connect((state: RootState) => ({
  allRegions: state.countries,
}))(RegionList);
