import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CancelablePromise, ProjectQuickMatch, ProjectService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<Array<ProjectQuickMatch>>;

export const fetchData = createAsyncThunk(
  'projectList/fetchData',
  async ({
    query,
    offset,
    limit,
    type,
  }: {
    query?: string;
    offset?: number;
    limit?: number;
    type?: 'project' | 'proposal';
  }) => {
    request?.cancel();
    request = ProjectService.projectList(limit, offset, query, type);
    return request;
  }
);

const projectListSlice = createSlice({
  name: 'projectList',
  initialState: initialState.projectList,
  reducers: {
    updateData: (state, action: PayloadAction<Array<ProjectQuickMatch>>) => ({
      ...state,
      data: action.payload,
    }),
    setQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      query: action.payload,
    }),
    resetData: () => initialState.projectList,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const projectListSelector = (state: IState) => state.projectList;
export const { updateData, resetData, setQuery } = projectListSlice.actions;
export default projectListSlice.reducer;
