import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { URL__SHORTENED } = ActionTypes;

export function shortenUrl(url: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const {
      urls: { shortened: shortenedUrls },
    } = getState();
    // @ts-ignore
    if (shortenedUrls[url]) {
      // @ts-ignore
      return shortenedUrls[url];
    }

    const { shortenURL: shorten } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionShortenUrl($url: String!) {
          shortenURL(url: $url)
        }
      `),
      { url }
    );

    dispatch({
      type: URL__SHORTENED,
      url,
      shorten,
    });

    return shorten;
  };
}
