import { FC } from 'react';

import { SignupType } from '@/__generated__/graphql';
import Signup from '@/auth/components/Signup';
import LayoutPage from '@/components/Layout/LayoutPage';
import { GroupDomain } from '@/group';

const clientLongAboutText = `In today's intricate knowledge economy, standing out requires an edge. OnFrontiers understands this intricacy and offers professionals a unique opportunity to connect with a diverse pool of industry leaders and experts from around the globe. Our platform serves as a bridge, eliminating the distance between a question and its expert answer. Whether you're looking for insights on a specific topic or seeking a long-term expert partnership, OnFrontiers has been designed with your needs in mind.

Our commitment isn't just about facilitating connections. At the heart of OnFrontiers is a dedication to privacy, robust compliance checks, and a user-friendly experience that transforms past interactions into a treasure trove of knowledge. This dedication to excellence is reflected in our collaboration with giants like PWC, CACI, and IFC, attesting to our platform's trusted and proven capabilities.

Positioned at the nexus of professional networking and expertise sourcing, OnFrontiers stands ready to amplify your strategies and projects.

<b>Here’s what we’re hearing from our customers:</b>

<i>“OnFrontiers was able to identify Chadian health supply chain experts that are also from Chad, which speaks volumes about their services and ability to find local expertise.”</i>
 - James (Technical Researcher at Chemonics)

<i>“The expert's recent work on justice reform issues in Uzbekistan was really helpful to us as we began our capture efforts. On a 45 minute call he was able to quickly orient us on the key issues and reform actors, which allowed us to move forward efficiently with our planning.”</i>
 - Jason (Senior Director at Tetra Tech)
`;

interface ClientRegisterPageProps {
  marketingLinks: string[];
  domain?: GroupDomain;
  title: string;
  tags: string[];
  invite: string;
  defaultEmail: string;
  nextUrl: string;
}

const ClientRegisterPage: FC<ClientRegisterPageProps> = ({
  marketingLinks,
  domain,
  title,
  tags,
  invite,
  defaultEmail,
  nextUrl,
}) => {
  return (
    <LayoutPage headerBorder={false} marketingLinks={marketingLinks}>
      <Signup
        signupType={SignupType.Client}
        domain={domain}
        nextUrl={nextUrl}
        title={title}
        shortAboutText={domain?.member_signup_about_mobile}
        longAboutText={domain?.member_signup_about || clientLongAboutText}
        tags={tags}
        invite={invite}
        defaultEmail={defaultEmail}
      />
    </LayoutPage>
  );
};

export default ClientRegisterPage;
