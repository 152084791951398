import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { fetchAllGroups } from '@/actions/group';
import { updateProfileKeywords } from '@/actions/internalNetwork';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import EditDialog from '@/components/EditDialog';
import { KeywordsFields } from '@/profile/components/forms/KeywordsForm';
import { updateProfile } from '@/profile/store';
import { clearProfileSelection, selectProfile } from '@/search/store';
import { fetchUser } from '@/store/user';

function ProfileKeywordsDialog({
  viewer,
  profiles,
  updateProfile,
  updateProfileKeywords,
  selectProfile,
  clearProfileSelection,
  notify,
  reset,
  onReset,
  array,
  change,
  handleSubmit: handleSubmitProp,
  ...rest
}: any) {
  const handleSubmit = (values: any) => {
    const keywords = viewer.admin ? values.keywords : values.keywords.map((k: any) => k.id);

    Promise.all(
      profiles.map((p: any) => {
        const groupKeywords = values.group_keywords.reduce(
          (ks: any, g: any) => [...ks, ...g.keywords?.map((k: any) => k.id)],
          []
        );

        const promise = updateProfileKeywords(p.id, groupKeywords);

        return promise
          .then(() => ({ profile: p, success: true }))
          .catch(() => ({ profile: p, success: false }));
      }),
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      profiles
        .map((p: any) => {
          return viewer.admin && updateProfile({ id: p.id, keywords_to_add: keywords });
        })
        .filter(Boolean)
    ).then((responses) => {
      const success = responses.filter((r) => r.success);
      const failure = responses.filter((r) => !r.success);

      if (success.length > 0 && failure.length === 0) {
        notify('Keywords added to the selected experts.', 'success');
        clearProfileSelection();
      } else if (failure.length > 0 && success.length === 0) {
        notify('Error when adding keywords to the selected experts.', 'error');
      } else if (failure.length > 0 && success.length > 0) {
        const failureText =
          failure.length === 1 ? failure[0].profile.name : `${failure.length} experts`;
        notify(`Keywords could not be added to ${failureText}.`, 'warning');
        success.forEach((r) => selectProfile(r.profile, false));
      }
    });
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <KeywordsFields
      {...rest}
      component={EditDialog}
      maxWidth="md"
      title="Add Keywords"
      handleSubmit={handleSubmitProp(handleSubmit)}
      handleReset={handleReset}
      showGeneralKeywords={viewer.admin}
      array={array}
      change={change}
    />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ProfileKeywordsDialog' because i... Remove this comment to see the full error message
ProfileKeywordsDialog = connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    groups: state.groups.all,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    formValues: state.form.profilesKeywords?.values,
    initialValues: {
      group_keywords: [{ keywords: [] }],
      keywords: [],
    },
  }),
  {
    updateProfile,
    updateProfileKeywords,
    selectProfile,
    clearProfileSelection,
    notify,
  }
)(
  reduxForm({
    form: 'profilesKeywords',
    initialValues: {
      keywords: [],
    },
  })(ProfileKeywordsDialog)
);

function AddKeywordsButton({
  viewer,
  fetchUser,
  profiles,
  labelStyle,
  fetchAllGroups,
  groups,
  ...other
}: any) {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchAllGroups({
      keywordsConfig: true,
    });
  }, []);

  const hasTeamKeywords = useMemo(
    () =>
      groups?.edges?.some(
        // @ts-expect-error TS(7031) FIXME: Binding element 'g' implicitly has an 'any' type.
        ({ node: g }) => g.profile_keywords_definition?.length
      ),
    [groups]
  );

  return (
    <div>
      {hasTeamKeywords && (
        <Button
          variant="contained"
          color="teal"
          size="small"
          {...other}
          onClick={() => setDialogOpen(true)}
        >
          Add Keywords
        </Button>
      )}
      <ProfileKeywordsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        profiles={profiles}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    groups: state.groups.all,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
  }),
  {
    fetchAllGroups,
    fetchUser,
  }
)(AddKeywordsButton);
