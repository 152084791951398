import clsx from 'clsx';

import HeadIncrease from './assets/head-increase.svg';
import TailIncrease from './assets/tail-increase.svg';
import { IMeterProps } from './types';

export const meterTestId = 'of-meter';

const Meter = ({ className, diff = 0, value, ariaLabelledBy, animate }: IMeterProps) => {
  const changeBarWidth = Math.abs(diff);
  const mainBarWidth = value - Math.max(diff, 0);

  return (
    <div
      data-testid={meterTestId}
      role="meter"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      aria-labelledby={ariaLabelledBy}
      className={clsx(className, 'flex h-12 rounded-[24px] bg-black bg-opacity-10 p-1')}
    >
      <div
        className={clsx('flex h-full justify-end', {
          'rounded-l-[24px] bg-gray-400': diff !== 0,
          'rounded-[24px] bg-brand-primary': diff === 0,
          'animate-grow-from-left': animate,
        })}
        style={{ width: `${mainBarWidth}%` }}
      />

      {diff !== 0 ? (
        <div
          className={clsx('relative', {
            'bg-brand-primary': diff > 0,
            'bg-destructive-primary': diff < 0,
          })}
          style={{ width: `${changeBarWidth}%` }}
        >
          <TailIncrease
            aria-hidden="true"
            className={clsx('absolute -top-1 h-[calc(100%+2px)] text-grey-200', {
              'right-full fill-brand-primary': diff > 0,
              'left-full rotate-180 fill-destructive-primary': diff < 0,
            })}
          />
          <HeadIncrease
            aria-hidden="true"
            className={clsx('absolute -top-1 h-[calc(100%+2px)] text-grey-200', {
              'left-full fill-brand-primary': diff > 0,
              'right-full rotate-180 fill-destructive-primary': diff < 0,
            })}
          />
        </div>
      ) : null}
    </div>
  );
};

export type { IMeterProps };
export default Meter;
