import PropTypes from 'prop-types';

import { SCREEN_SM } from '@/theme/screens';

import Button from '../Button';
import MediaQuery from '../MediaQuery';
import s from './WizardFooter.module.scss';

function WizardFooter({ nextLabel = 'Next →', nextDisabled, onNext }: any) {
  return (
    <footer className={s.root}>
      <MediaQuery maxWidth={SCREEN_SM}>
        {(smallScreen: any) => (
          <Button
            onClick={onNext}
            disabled={nextDisabled}
            size={smallScreen ? 'normal' : 'large'}
            type="submit"
          >
            {nextLabel}
          </Button>
        )}
      </MediaQuery>
    </footer>
  );
}

WizardFooter.propTypes = {
  nextLabel: PropTypes.string,
  onNext: PropTypes.func,
};

export default WizardFooter;
