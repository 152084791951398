import makeStyles from '@mui/styles/makeStyles';

import logoDark from './logo-black.png';
import logoLight from './logo-white.png';

const useSizes = makeStyles((theme) => ({
  // @ts-expect-error TS(2339): Property 'width' does not exist on type '{}'.
  standard: ({ width, height }) => ({
    width: width || '213px',
    height: height || '20px',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '17px',
    },
  }),
  big: {
    width: '416px',
    height: '40px',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '17px',
    },
  },
  small: {
    width: '180px',
    height: '17px',
  },
}));

function Logo({ user, dark = false, size = 'standard', style, width, height, ...other }: any) {
  let logoUrl = dark ? logoDark : logoLight;
  let logoAlt = 'OnFrontiers';
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const sizeClass = useSizes({ width, height })[size];

  const groupLogo = user && user.groups && user.groups.find((g: any) => !!g.branding_logo_url);

  if (groupLogo) {
    logoUrl = groupLogo.branding_logo_url;
    logoAlt = groupLogo.public_name || 'Powered by OnFrontiers';
  }

  return (
    <img
      src={logoUrl}
      alt={logoAlt}
      className={sizeClass}
      style={{
        ...style,
      }}
      {...other}
    />
  );
}

export default Logo;
