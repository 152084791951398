import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import moment from 'moment';
import { ReactNode } from 'react';

import { editRoles } from '@/assignment/store/manageProjectSlice';
import { Drawer } from '@/componentsv2/Drawer';
import Tooltip from '@/componentsv2/Tooltip';
import { useSelf } from '@/hooks/store';
import { Project, ProjectMember } from '@/openapi';
import { useAppDispatch } from '@/store';
import { jobTitleCapitalized } from '@/utils/capitalization';
import { sortStartDateMostRecent } from '@/utils/date';

interface IProjectRolesTableProps {
  isLoading?: boolean;
  className?: string;
  project?: Project | null;
  projectMemberResults?: ProjectMember[];
}

type TableCellProps = {
  className?: string;
  children: ReactNode;
  first?: boolean;
};

const Th = ({ className, children, first }: TableCellProps) => {
  return (
    <th
      className={clsx(
        className,
        'rounded-t-sm border-b border-grey-400 bg-grey-50 px-[16px] py-12 text-left subtitle-1',
        {
          'w-[15%]': !first,
          'w-[25%]': first,
        }
      )}
    >
      {children}
    </th>
  );
};

const Td = ({ className, children, first }: TableCellProps) => {
  return (
    <td
      className={clsx(className, 'w-[15%] px-[16px] py-[9px]', {
        'w-[15%]': !first,
        'w-[25%]': first,
        'font-medium': first,
      })}
    >
      {children}
    </td>
  );
};

const ProjectRolesTable = ({
  isLoading,
  className,
  project,
  projectMemberResults,
}: IProjectRolesTableProps) => {
  const dispatch = useAppDispatch();
  const isSelf = useSelf();

  const sortedRoles = [...(projectMemberResults || [])].sort(sortStartDateMostRecent);

  const EditButton = ({
    children,
    consumerText,
  }: {
    children: ReactNode;
    consumerText?: string;
  }) => {
    return isSelf ? (
      <Tooltip title="Edit" placement="top" arrow>
        <button
          className="text-warning-main"
          onClick={() => project && dispatch(editRoles(project))}
        >
          {children}
        </button>
      </Tooltip>
    ) : (
      <>{consumerText ?? children}</>
    );
  };

  return (
    <Drawer.Section className={clsx(className, 'mt-20')}>
      <h3 className="sr-only">Individual Roles</h3>
      <table className="w-full body-2">
        <thead className="bg-grey-100">
          <tr>
            <Th first>{project?.project_type || 'Project'} Roles</Th>
            <Th>Start Dates</Th>
            <Th>End Dates</Th>
            <Th>Location(s)</Th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <Td first>
                <Skeleton variant="text" width="100%" height={15} />
              </Td>
              <Td>
                <Skeleton variant="text" width="100%" height={15} />
              </Td>
              <Td>
                <Skeleton variant="text" width="100%" height={15} />
              </Td>
              <Td>
                <Skeleton variant="text" width="100%" height={15} />
              </Td>
            </tr>
          ) : (
            <>
              {sortedRoles.map((role) => {
                return (
                  <tr key={role.id}>
                    <Td first>
                      {role.title_raw_term ? (
                        jobTitleCapitalized(role.title_raw_term.name)
                      ) : (
                        <EditButton consumerText="Participant">Role missing</EditButton>
                      )}
                    </Td>
                    {!role.start_date && !role.end_date ? (
                      <td colSpan={2}>Dates missing</td>
                    ) : (
                      <>
                        <Td className="start-date">
                          {moment(role.start_date).isValid() ? (
                            moment(role.start_date).format('MM/DD/YYYY')
                          ) : (
                            <EditButton>Missing</EditButton>
                          )}
                        </Td>
                        <Td className="end-date">
                          {moment(role.end_date).isValid() ? (
                            moment(role.end_date).format('MM/DD/YYYY')
                          ) : role.title_raw_term ? (
                            'Current'
                          ) : (
                            <EditButton>Missing</EditButton>
                          )}
                        </Td>
                      </>
                    )}

                    <Td>{role.countries[0]?.name ?? <EditButton>Unknown</EditButton>}</Td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </Drawer.Section>
  );
};

export default ProjectRolesTable;
