import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { SavedSearch, SavedSearchService } from '@/openapi';

export const useCreateSavedSearch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      name,
      hash,
      description,
    }: {
      name: string;
      hash: string;
      description?: string;
    }) =>
      SavedSearchService.savedSearchCreate({
        name,
        hash,
        description,
      }).then((res) => res),
    onSuccess: () => {
      queryClient.invalidateQueries(['saved-search']);
    },
    onError: () => {
      enqueueSnackbar('Failed to create saved search, please contact support', {
        variant: 'error',
      });
    },
  });
};

export const useUpdateSavedSearch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      name,
      hash,
      description,
    }: {
      id: string;
      name?: string;
      hash?: string;
      description?: string;
    }) =>
      SavedSearchService.savedSearchPartialUpdate(id, {
        name,
        hash,
        description,
      }).then((res) => res),
    onMutate: async (updatedPayload) => {
      // optimstically update cache
      const prevData: SavedSearch =
        queryClient.getQueryData(['saved-search', { id: updatedPayload.id }]) ??
        ({} as SavedSearch);

      const newData = {
        ...prevData,
        ...updatedPayload,
        search_hash: updatedPayload.hash,
      };
      queryClient.setQueryData(['saved-search', { id: updatedPayload.id }], newData);

      return { prevData };
    },
    onSuccess: () => {
      // invalidate paginated list
      queryClient.invalidateQueries(['saved-searches']);
    },
    onError: (err, newTodo, context) => {
      // rollback optimistic update
      queryClient.setQueryData(['saved-search', { id: context?.prevData.id }], context?.prevData);
      enqueueSnackbar('Failed to Update saved search, please contact support', {
        variant: 'error',
      });
    },
  });
};

export const useDeleteSavedSearch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => SavedSearchService.savedSearchDestroy(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['saved-searches']);
    },
    onError: () => {
      enqueueSnackbar('Failed to Delete saved search, please contact support', {
        variant: 'error',
      });
    },
  });
};
