import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles, withStyles } from '@mui/styles';
import { useMemo } from 'react';

import { gengql } from '@/__generated__';
import Alert from '@/components/Alert';
import LinearProgress from '@/components/LinearProgress';
import { darkBlue, primaryLight, white, whiteTransparent } from '@/theme/colors';
import { isArrayNotEmpty } from '@/utils';

const GET_PROFILE = gengql(/* GraphQL */ `
  query getProfileCompleteness($id: String!) {
    profile(id: $id) {
      id
      first_name
      last_name
      phones {
        verified
      }
      picture_url
      linkedin_url
      cv_url
      languages {
        code
      }
      city
      country
      title
      summary
      experiences {
        id
      }
      education {
        id
      }
      keywords
      sectors {
        id
      }
      regions {
        id
      }
      questions
    }
  }
`);

const getStyles = makeStyles(() => ({
  message: {
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    lineHeight: '23px',
  },
  subtitle: {
    color: primaryLight,
    fontSize: 16,
    fontWeight: 600,
  },
  progressBar: {
    margin: '10px 0 20px',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: white,
    marginBottom: 10,
    fontSize: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  actionText: {
    cursor: 'pointer',
    color: white,
    fontWeight: 500,
  },
}));

const ColorLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    backgroundColor: 'white',
  },
}))(LinearProgress);

interface ProfileCompletnessProps {
  profileId: string;
  isSignup?: boolean;
  style?: any;
  onRequestClose?: any;
  nameAction?: any;
  phoneAction?: any;
  pictureAction?: any;
  resumeAction?: any;
  introductionAction?: any;
  experiencesAction?: any;
  keywordsAction?: any;
  expertiseAction?: any;
  questionsAction?: any;
  locationAction?: any;
  communicationAction?: any;
  educationsAction?: any;
  linkedinAction?: any;
}

interface Action {
  action: any;
  text: string;
  completed: boolean;
}

const ProfileCompleteness = ({
  profileId,
  isSignup,
  style,
  onRequestClose,
  nameAction,
  phoneAction,
  pictureAction,
  resumeAction,
  introductionAction,
  experiencesAction,
  keywordsAction,
  expertiseAction,
  questionsAction,
  locationAction,
  communicationAction,
  educationsAction,
  linkedinAction,
}: ProfileCompletnessProps) => {
  const s = getStyles();

  const { data } = useQuery(GET_PROFILE, { variables: { id: profileId } });
  const profile = data?.profile;

  const actions = useMemo(() => {
    if (!profile) return [];

    const actions: Action[] = [];

    if (nameAction) {
      actions.push({
        action: nameAction,
        text: 'Add first and last name',
        completed: !!(profile.first_name && profile.last_name),
      });
    }

    if (phoneAction) {
      actions.push({
        action: phoneAction,
        text: 'Add a phone number',
        completed: isArrayNotEmpty(profile.phones || []),
      });
    }

    if (pictureAction) {
      actions.push({
        action: pictureAction,
        text: 'Add a picture of you',
        completed: !!profile.picture_url,
      });
    }

    if (linkedinAction) {
      actions.push({
        action: linkedinAction,
        text: 'Have a LinkedIn Profile?',
        completed: !!profile.linkedin_url,
      });
    }

    if (resumeAction) {
      actions.push({
        action: resumeAction,
        text: 'Upload your Resume',
        completed: !!profile.cv_url,
      });
    }

    if (communicationAction) {
      actions.push({
        action: communicationAction,
        text: 'Add one or more languages that you can speak',
        completed: isArrayNotEmpty(profile.languages || []),
      });
    }

    if (locationAction) {
      actions.push({
        action: locationAction,
        text: 'Add your location',
        completed: !!(profile.country || profile.city),
      });
    }

    if (introductionAction) {
      actions.push({
        action: introductionAction,
        text: 'Add a profile headline',
        completed: !!profile.title,
      });

      actions.push({
        action: introductionAction,
        text: 'Add a short bio',
        completed: !!profile.summary,
      });
    }

    if (experiencesAction) {
      actions.push({
        action: experiencesAction,
        text: 'Add one or more work experiences',
        completed: isArrayNotEmpty(profile.experiences || []),
      });
    }

    if (educationsAction) {
      actions.push({
        action: educationsAction,
        text: 'Add one or more educations',
        completed: isArrayNotEmpty(profile.education || []),
      });
    }

    if (keywordsAction) {
      actions.push({
        action: keywordsAction,
        text: 'Add some skills and relevant keywords',
        completed: isArrayNotEmpty(profile.keywords || []),
      });
    }

    if (expertiseAction) {
      actions.push({
        action: expertiseAction,
        text: 'Add one or more industries or sectors where you have expertise',
        completed: isArrayNotEmpty(profile.sectors || []),
      });

      actions.push({
        action: expertiseAction,
        text: 'Add one or more countries or regions where you have expertise',
        completed: isArrayNotEmpty(profile.regions || []),
      });
    }

    if (questionsAction) {
      actions.push({
        action: questionsAction,
        text: 'Add some questions you can answer',
        completed: isArrayNotEmpty(profile.questions || []),
      });
    }

    return actions;
  }, [
    communicationAction,
    educationsAction,
    experiencesAction,
    expertiseAction,
    introductionAction,
    keywordsAction,
    linkedinAction,
    locationAction,
    nameAction,
    phoneAction,
    pictureAction,
    profile,
    questionsAction,
    resumeAction,
  ]);

  if (!profile) {
    return null;
  }

  const total = actions.length;
  const missing = actions.filter((a) => !a.completed);
  const completeness = 100 - (missing.length / total) * 100;

  return (
    completeness < 100 && (
      <Alert
        backgroundColor={darkBlue}
        style={style}
        messageClassName={s.message}
        onRequestClose={onRequestClose}
      >
        <div className={s.title}>
          Your profile is live!
          {isSignup && ' Please scroll to review and finish.'}
        </div>

        <div className={s.subtitle}>You’re {Math.round(completeness)}% of the way there!</div>

        <div className={s.progressBar}>
          <ColorLinearProgress
            style={{
              height: 10,
              backgroundColor: whiteTransparent,
            }}
            variant="determinate"
            value={completeness}
          />
        </div>

        <div>
          {missing.slice(0, 3).map((a) => (
            <div key={a.text} onClick={a.action} onKeyPress={a.action} className={s.action}>
              <AddIcon
                fontSize="small"
                style={{ color: primaryLight, marginRight: 5, fontWeight: 500 }}
              />
              <span className={s.actionText}>{a.text}</span>
            </div>
          ))}
        </div>
      </Alert>
    )
  );
};

export default ProfileCompleteness;
