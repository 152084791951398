import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { ExpertRequestType } from '@/__generated__/graphql';
import FAIcon from '@/components/Icon/FAIcon';
import config from '@/config';
import { black, darkGray, primary as primaryColor, white } from '@/theme/colors';

import ExpertRequestWizardPage from './ExpertRequestWizardPage';

const useTypeStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
  },
  button: ({ primary }: { primary: boolean }) => {
    const backgroundColor = primary ? primaryColor : white;
    return {
      flex: '0 0 auto',
      border: 0,
      borderRadius: 2,
      boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
      cursor: 'pointer',
      width: 70,
      height: 70,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: primary ? primaryColor : backgroundColor,
      '&:hover': {
        backgroundColor: lighten(backgroundColor, 0.4),
      },
    };
  },
  content: {
    flex: 1,
    marginLeft: 10,
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    color: black,
  },
  text: {
    marginTop: 10,
    color: darkGray,
  },
});

const RequestType = ({ icon, onClick, title, text, primary }: any) => {
  const s = useTypeStyles({ primary });

  return (
    <div className={s.root}>
      <button className={s.button} onClick={onClick} type="button">
        <FAIcon iconSet="far" icon={icon} size={35} color={white} />
      </button>

      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.text}>{text}</div>
      </div>
    </div>
  );
};

const SelectType = ({ change, onNext, ...other }: any) => {
  const handleTypeClick = (type: any) => {
    change('er_type', type);

    onNext();
  };

  const subTitle = (
    <div>
      On OnFrontiers, relationships with experts can evolve over time. An initial consultation could
      lead to contract work or even a permanent role on your team.
      <br />
      <br />
      Let us know how you would like to start.
    </div>
  );

  return (
    <ExpertRequestWizardPage
      {...other}
      editName={false}
      nextUrl={null}
      onNext={null}
      title="How would you like to start engaging with an Expert?"
      subTitle={subTitle}
    >
      <RequestType
        primary
        icon="phone"
        title="Consultation"
        text="Connect with an Expert for a phone call, in-person meeting, or written Q&A"
        onClick={() => handleTypeClick(ExpertRequestType.Consultation)}
      />
      <RequestType
        primary
        icon="briefcase"
        title="Consulting Project"
        text="Hire an Expert for a short or long-term consulting project."
        onClick={() => handleTypeClick(ExpertRequestType.ConsultingProject)}
      />
      <RequestType
        primary
        icon="chair-office"
        title="New Hire"
        text="Hire an Expert in a permanent role"
        onClick={() => handleTypeClick(ExpertRequestType.NewHire)}
      />
      {!config.disableWrittenEngagement && (
        <RequestType
          primary
          icon="book"
          title="Written Review"
          text="Hire an Expert to review a provided document"
          onClick={() => handleTypeClick(ExpertRequestType.WrittenReview)}
        />
      )}
    </ExpertRequestWizardPage>
  );
};

SelectType.title = 'Select Request Type';

export default connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
  }),
  {}
)(SelectType);
