import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';

import {
  CancelablePromise,
  PaginatedProfilePublicationList,
  ProfilePublication,
  ProfilePublicationCreate,
  ProfilePublicationService,
  ProfilePublicationUpdate,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<PaginatedProfilePublicationList>;

export const fetchData = createAsyncThunk(
  'profilePublications/fetchData',
  async (profileId: string) => {
    request?.cancel();
    request = ProfilePublicationService.profilePublicationList(1000, 0, profileId);
    return request;
  }
);
export const createPublication = createAsyncThunk(
  'profilePublications/createPublication',
  async (payload: ProfilePublicationCreate) =>
    await ProfilePublicationService.profilePublicationCreate(payload)
);
export const updatePublication = createAsyncThunk(
  'profilePublications/updatePublication',
  async (payload: ProfilePublicationUpdate) =>
    await ProfilePublicationService.profilePublicationUpdate(payload.id, payload)
);
export const deletePublication = createAsyncThunk(
  'profilePublications/deletePublication',
  (id: string) => ProfilePublicationService.profilePublicationDestroy(id).then(() => id)
);

export const resetData = createAsyncThunk(
  'profilePublications/resetData',
  async (_, { dispatch }) => {
    request?.cancel();
    dispatch(profilePublicationsSlice.actions.reset());
  }
);

const profilePublicationsSlice = createSlice({
  name: 'profilePublications',
  initialState: initialState.profilePublications,
  reducers: {
    reset: () => initialState.profilePublications,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(createPublication.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        if (draft.data.results) {
          draft.data.results.push({
            ...(action.payload as ProfilePublication),
            publication: action.payload.organization_by_name ?? '',
            organization: action.payload.organization_by_name || action.payload.organization || '',
          });
        }
      });
    });
    builder.addCase(updatePublication.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;
        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((a) => a.id === action.payload.id);
        if (draft.data.results) {
          draft.data.results[index] = {
            ...(action.payload as ProfilePublication),
            publication: action.payload.organization_by_name ?? '',
            organization: action.payload.organization_by_name || action.payload.organization || '',
          };
        }
      });
    });
    builder.addCase(deletePublication.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;
        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((i) => i.id === action.payload);
        if (draft.data.results) {
          draft.data.results.splice(index, 1);
        }
      });
    });
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const profilePublicationsSelector = (state: IState) => state.profilePublications;
export default profilePublicationsSlice.reducer;
