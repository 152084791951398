import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';
import { fetchUser } from '@/store/user';

import Button from '../Button';
import InternalNetworkShareMultipleProfiles from './InternalNetworkShareMultipleProfiles';

class InternalNetworkShareButton extends PureComponent {
  state = {
    dialogOpen: false,
  };

  handleOpen = async () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer, fetchUser } = this.props;

    if (!viewer.admin) {
      await fetchUser(viewer.username, {
        groups: true,
        sharedInternalNetworks: true,
      });
    }

    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      // @ts-expect-error TS(2339): Property 'internalNetworks' does not exist on type... Remove this comment to see the full error message
      internalNetworks,
      // @ts-expect-error TS(2339): Property 'onExpertShare' does not exist on type 'R... Remove this comment to see the full error message
      onExpertShare,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
      style,
      // @ts-expect-error TS(2339): Property 'fetchUser' does not exist on type 'Reado... Remove this comment to see the full error message
      fetchUser,
      ...other
    } = this.props;

    // @ts-expect-error TS(2339): Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
    const profiles = profile ? [profile] : this.props.profiles || [];
    if (profiles.length === 0) return null;

    return (
      <div>
        <Button
          size="small"
          {...other}
          style={{
            fontWeight: 'bold',
            letterSpacing: '-0.5px',
            ...style,
          }}
          onClick={this.handleOpen}
        >
          Share
        </Button>
        <InternalNetworkShareMultipleProfiles
          // @ts-expect-error TS(2769): No overload matches this call.
          open={this.state.dialogOpen}
          profiles={profiles}
          onClose={this.handleClose}
          onExpertShare={onExpertShare}
        />
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    fetchUser,
  }
)(InternalNetworkShareButton);
