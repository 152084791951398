/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileKnowledgeScoreList } from '../models/PaginatedProfileKnowledgeScoreList';
import type { ProfileKnowledgeRankScore } from '../models/ProfileKnowledgeRankScore';

export class ProfileKnowledgeScoreService {
  /**
   * Get the profile knowledge score
   * List the profile knowledge score items
   * @param expertLevels Filter by expert level
   * @param includeExpanded Include expanded knowledge.
   * @param knowledgeTypes Filter by knowledge type
   * @param limit Number of results to return per page.
   * @param name Filter the scores list by name exact match.
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param profileId
   * @param query Filter the scores list by name icontains
   * @param regionType Filter by region type
   * @param type The type of the profile knowledge score.
   * @returns PaginatedProfileKnowledgeScoreList
   * @throws ApiError
   */
  public static profileKnowledgeScoreList(
    expertLevels: string = '',
    includeExpanded: boolean = false,
    knowledgeTypes: string = '',
    limit?: number,
    name: string = '',
    offset?: number,
    ordering:
      | ''
      | '-expanded'
      | '-knowledge_type'
      | '-latest_date'
      | '-name'
      | '-percentile'
      | '-value'
      | 'expanded'
      | 'knowledge_type'
      | 'latest_date'
      | 'name'
      | 'percentile'
      | 'value' = '',
    profileId?: string,
    query: string = '',
    regionType: '' | 'country' = '',
    type: '' | 'knowledge' | 'location' = ''
  ): CancelablePromise<PaginatedProfileKnowledgeScoreList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_knowledge_score/',
      query: {
        expert_levels: expertLevels,
        include_expanded: includeExpanded,
        knowledge_types: knowledgeTypes,
        limit: limit,
        name: name,
        offset: offset,
        ordering: ordering,
        profile__id: profileId,
        query: query,
        region_type: regionType,
        type: type,
      },
    });
  }

  /**
   * Get the rank for a knowledge given a profile id
   * Get the rank for a knowledge given a profile id
   * @param knowledgeName The name of the knowledge to get the rank for.
   * @param knowledgeType The type of the knowledge to get the rank for.
   * @param profileId The profile id to get the rank for.
   * @returns ProfileKnowledgeRankScore
   * @throws ApiError
   */
  public static profileKnowledgeScoreRankList(
    knowledgeName: string,
    knowledgeType: 'knowledge' | 'location',
    profileId: string
  ): CancelablePromise<Array<ProfileKnowledgeRankScore>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_knowledge_score/rank/{knowledge_type}/{knowledge_name}/',
      path: {
        knowledge_name: knowledgeName,
        knowledge_type: knowledgeType,
      },
      query: {
        profile_id: profileId,
      },
    });
  }
}
