import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';

import styles from './styles';
import { IOneLineListItemProps, IOneLineListRootProps } from './types';

const useStyles = makeStyles(styles);

export const OneLineListTestId = 'of-one-line-list';

const Root = ({ className, children, mq = '(min-width: 0)' }: IOneLineListRootProps) => {
  const classes = useStyles();
  const matches = useMediaQuery(mq);
  return (
    <ul
      data-testid={OneLineListTestId}
      className={clsx(className, {
        [classes.oneLineList]: matches,
      })}
    >
      {children}
    </ul>
  );
};

const Item = ({ className, children }: IOneLineListItemProps) => {
  return children ? <li className={className}>{children}</li> : null;
};

export const OneLineList = Object.assign(Root, {
  Item,
});
