import { FC, memo } from 'react';

import s from './ChannelList.module.scss';

interface ChannelListProps {
  children: React.ReactNode;
}

const ChannelList: FC<ChannelListProps> = memo(({ children }) => {
  return <div className={s.channels}>{children}</div>;
});
ChannelList.displayName = 'ChannelList';

export default ChannelList;
