import makeStyles from '@mui/styles/makeStyles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';

import Box from '@/componentsv2/Box';
import Checkbox from '@/componentsv2/Checkbox';
import Input from '@/componentsv2/Input';

import styles, { datePickerClasses } from './styles';
import { DatePickerValue, IDatePickerProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-date-picker';

const DatePicker = ({
  className,
  enableCurrentCheckbox,
  CheckboxProps = {},
  onChange,
  placeholder,
  defaultValue = null,
  value: initialValue,
  TextFieldProps,
  includeFutureDatePick = false,
  ...props
}: IDatePickerProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.datePicker, datePickerClasses.root);
  const [value, setValue] = useState<DatePickerValue>(initialValue || defaultValue);

  useEffect(() => {
    if (initialValue === undefined) {
      return;
    }

    setValue(initialValue);
  }, [initialValue]);

  const showDatePicker = !enableCurrentCheckbox || moment.isMoment(value) || !value?.isCurrent;

  const disableYearSelection = (year: moment.Moment): boolean => {
    return includeFutureDatePick
      ? year.year() < 1950
      : year.year() < 1950 || year.year() > moment().year();
  };

  return (
    <Box className={classProps} data-testid={testId}>
      {showDatePicker ? (
        <DesktopDatePicker
          format="MM/DD/YYYY"
          label={null}
          onChange={(v) => {
            const newValue: DatePickerValue = enableCurrentCheckbox
              ? { date: v, isCurrent: false }
              : v;
            setValue(newValue);
            onChange?.(newValue);
          }}
          slots={{
            textField: ({ className, inputProps, ...props }) => (
              <Input
                size="small"
                type="tel"
                className={clsx(className, datePickerClasses.input)}
                inputProps={{
                  ...inputProps,
                  placeholder: placeholder ?? 'mm/dd/yyyy',
                  readOnly: !moment.isMoment(value) && value?.isCurrent,
                }}
                fullWidth
                {...props}
                {...TextFieldProps}
                abbreviateRequired
              />
            ),
          }}
          shouldDisableDate={() => Boolean(!moment.isMoment(value) && value?.isCurrent)}
          shouldDisableYear={disableYearSelection}
          value={moment.isMoment(value) ? value : (value?.date ?? null)}
          slotProps={{
            desktopPaper: {
              sx: {
                '.PrivatePickersYear-yearButton.Mui-disabled': {
                  display: 'none',
                },
              },
            },
          }}
          openTo={props.views?.[0] ?? undefined}
          {...props}
        />
      ) : null}

      {enableCurrentCheckbox ? (
        <Checkbox
          formControlLabelClassName={classes.checkbox}
          label="Current"
          name="isCurrent"
          color="primary"
          size="medium"
          {...CheckboxProps}
          checked={(!moment.isMoment(value) && value?.isCurrent) || false}
          onChange={() => {
            const isCurrent = !moment.isMoment(value) && !value?.isCurrent;
            const newValue: DatePickerValue = {
              date: null,
              isCurrent,
            };

            setValue(newValue);
            onChange?.(newValue);
          }}
        />
      ) : null}
    </Box>
  );
};

export { datePickerClasses, testId as DatePickerTestId };
export type { IDatePickerProps };
export default DatePicker;
