import { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button/Button';
import ConsultationList from '@/components/ConsultationList';
import EditExternalConsultation from '@/components/EditExternalConsultation';
import EmptyMessage from '@/components/EmptyMessage';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import { fetchAwaiting, fetchCanceled, fetchCompleted, fetchConfirmed } from '@/consultation/store';
import { fetchExpertRequests } from '@/expertrequest/store';
import { RootState } from '@/store';
import { darkGreen } from '@/theme/colors';

import s from './Consultations.module.scss';

const ConsultationPromo = () => {
  return (
    <EmptyMessage
      style={{ marginTop: 10 }}
      icon={<FAIcon icon="frown-o" className={s.sadIcon} />}
      title="You don't have any upcoming calls."
      body=""
    />
  );
};

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    awaiting: state.consultations.awaiting || {},
    confirmed: state.consultations.confirmed || {},
    canceled: state.consultations.canceled || {},
    completed: state.consultations.completed || {},
  }),
  {
    fetchAwaiting,
    fetchCanceled,
    fetchConfirmed,
    fetchCompleted,
    fetchExpertRequests,
  }
);

const Consultations = ({
  viewer,
  awaiting,
  confirmed,
  canceled,
  completed,
  fetchAwaiting,
  fetchCanceled,
  fetchConfirmed,
  fetchCompleted,
  fetchExpertRequests,
}: ConnectedProps<typeof connector>) => {
  const [externalConsultationOpen, setExternalConsultationOpen] = useState(false);

  const handleConsultations = useCallback((fn: any, collection: any) => {
    const { pageInfo, edges } = collection;
    if (!pageInfo.hasNextPage) return;
    if (!edges.length) throw new Error('last consultation edge not found');
    const { cursor } = edges[edges.length - 1];
    fn(cursor, 10, true);
  }, []);

  const handleAwaiting = useCallback(() => {
    handleConsultations(fetchAwaiting, awaiting);
  }, [awaiting, fetchAwaiting, handleConsultations]);

  const handleConfirmed = useCallback(() => {
    handleConsultations(fetchConfirmed, confirmed);
  }, [confirmed, fetchConfirmed, handleConsultations]);

  const handleCanceled = useCallback(() => {
    handleConsultations(fetchCanceled, canceled);
  }, [canceled, fetchCanceled, handleConsultations]);

  const handleCompleted = useCallback(() => {
    handleConsultations(fetchCompleted, completed);
  }, [completed, fetchCompleted, handleConsultations]);

  const handleExternalConsultationDialog = useCallback(() => {
    fetchExpertRequests({ state: 'open' }).then(() => setExternalConsultationOpen(true));
  }, [fetchExpertRequests]);

  const closeExternalConsultation = useCallback(() => {
    setExternalConsultationOpen(false);
  }, []);

  const awaitingConsultations = awaiting.edges || [];
  const confirmedConsultations = confirmed.edges || [];
  const canceledConsultations = canceled.edges || [];
  const completedConsultations = completed.edges || [];

  const hasConsultations =
    awaitingConsultations.length > 0 ||
    confirmedConsultations.length > 0 ||
    canceledConsultations.length > 0 ||
    completedConsultations.length > 0;

  const loading = awaiting.loading || confirmed.loading || canceled.loading || completed.loading;

  const belongsToEnterpriseGroup =
    (viewer.groups || []).filter((g) => g.account_type === 'enterprise')?.length > 0;

  return (
    <div>
      {belongsToEnterpriseGroup && (
        <div className={s.externalConsultationButton}>
          <Button
            variant="text"
            fontColor={darkGreen}
            startIcon={<MaterialIcon icon="file_upload" />}
            onClick={handleExternalConsultationDialog}
          >
            Add External Consultation
          </Button>
        </div>
      )}

      {!hasConsultations && loading === false && <ConsultationPromo />}

      <ConsultationList
        title="Awaiting"
        consultations={awaitingConsultations.map((c: any) => c.node)}
        loading={awaiting.loading}
        pageInfo={awaiting.pageInfo}
        handleMore={handleAwaiting}
      />

      <ConsultationList
        title="Upcoming"
        consultations={confirmedConsultations.map((c: any) => c.node)}
        loading={confirmed.loading}
        pageInfo={confirmed.pageInfo}
        handleMore={handleConfirmed}
      />

      <ConsultationList
        grid
        title="Completed"
        consultations={completedConsultations.map((c: any) => c.node)}
        loading={completed.loading}
        pageInfo={completed.pageInfo}
        handleMore={handleCompleted}
      />

      <ConsultationList
        grid
        title="Expired/Canceled"
        consultations={canceledConsultations.map((c: any) => c.node)}
        loading={canceled.loading}
        pageInfo={canceled.pageInfo}
        handleMore={handleCanceled}
      />

      <EditExternalConsultation
        open={externalConsultationOpen}
        onClose={closeExternalConsultation}
      />
    </div>
  );
};

export default connector(Consultations);
