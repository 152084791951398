import isEqual from 'lodash.isequal';
import React from 'react';

import { Select } from '../FormAdapters/FormAdapters';

type Country = {
  id: string;
  name: string;
};

interface SelectLocationProps {
  countries?: Country[];
  value?: Country | Country[];
  input?: any;
  meta?: any;
  label?: string;
  placeholder?: string;
  [key: string]: any;
}

const SelectLocation: React.FC<SelectLocationProps> = ({
  countries = [],
  value,
  input,
  meta,
  ...other
}) => {
  const selectedValue = value || input?.value || [];

  return (
    <Select
      autocomplete
      input={input}
      meta={meta}
      // @ts-expect-error
      fullWidth
      openOnFocus
      options={countries}
      getOptionLabel={(option: Country) => option.name}
      getOptionValue={(option: Country) => option}
      isOptionEqualToValue={(option: Country, selected: Country) => isEqual(option, selected)}
      limit={null}
      rawValueOnChange
      value={selectedValue}
      selectOnExactMatchInMultiMode
      {...other}
    />
  );
};

export default SelectLocation;
