import clsx from 'clsx';
import { useParams, useSearchParams } from 'react-router-dom';

import { SearchResponse } from '@/openapi';
import { DEFAULT_SEARCH_LIMIT } from '@/searchv2/queries/search';
import { useSavedSearchShortlistQuery } from '@/searchv2/queries/shortlist';
import { arrayFromRange } from '@/utils/array';

import Result, { ResultSkeleton } from './Result';

const Results = ({
  results,
  isLoading,
  isPreviousData,
  showDebugDialog,
}: {
  results: Array<SearchResponse>;
  isLoading?: boolean;
  isPreviousData?: boolean;
  showDebugDialog: (result: SearchResponse) => void;
}) => {
  const { savedSearchId } = useParams();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page') ?? 1);

  const { data: shortlistProfiles, isLoading: isShortlistLoading } = useSavedSearchShortlistQuery({
    id: savedSearchId ?? '',
  });

  const shortlistProfileIds = (shortlistProfiles?.results ?? []).map(
    (profile) => profile.profile_id
  );

  return (
    <ul
      className={clsx('space-y-16', {
        'animate-pulse opacity-50': isPreviousData,
      })}
    >
      {isLoading
        ? arrayFromRange({ max: 5 }).map((i) => <ResultSkeleton key={i} index={i} />)
        : results.map((result, i) => {
            return (
              <Result
                key={result.profile_id}
                result={result}
                trackingPayload={{
                  subject_profile_id: result.profile_id ?? '',
                  search_hash: searchParams.get('hash') ?? '',
                  search_position: (page - 1) * DEFAULT_SEARCH_LIMIT + i + 1,
                  search_results_page: page,
                  search_results: results.map((result) => result.profile_id),
                }}
                isShortlistLoading={isShortlistLoading}
                isAddedToShortlist={shortlistProfileIds.includes(result.profile_id ?? '')}
                showDebugDialog={() => showDebugDialog(result)}
              />
            );
          })}
    </ul>
  );
};

export default Results;
