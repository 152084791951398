import ActionTypes from '@/actions/ActionTypes';

export type Country = {
  id: string;
  name: string;
  country_iso2_code: string;
};

export default function countriesReducer(
  state = [],
  action: { type: string; countries: Country[] }
) {
  switch (action.type) {
    case ActionTypes.COUNTRIES__FETCH_SUCCESS:
      return action.countries;
    default:
      return state;
  }
}
