import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@/components/Button';
import Image from '@/components/Image';
import SvgUploadButton from '@/components/SvgUploadButton';

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px 0',
    textAlign: 'center',
    width: 220,
  },
  image: {
    width: 220,
    height: 220,
  },
  label: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5,
  },
}));

export default function Logo({
  label,
  src,
  onChange,
  dimensions,
  alternateSvgUpload,
  noStretch,
}: any) {
  const s = useStyles();

  return (
    <div className={s.root}>
      {label && <InputLabel className={s.label}>{label}</InputLabel>}
      <Image
        // @ts-ignore
        editable
        src={src}
        className={s.image}
        onChange={onChange}
        emptyText="Upload a PNG image"
        accept="image/png"
        dimensions={dimensions}
        noStretch={noStretch}
      />
      {src && (
        <Button
          variant="text"
          onClick={() => onChange({ url: '' })}
          style={{ marginTop: 10 }}
          size="small"
        >
          Remove
        </Button>
      )}
      {alternateSvgUpload && <SvgUploadButton onChange={onChange} dimensions={dimensions} />}
    </div>
  );
}
