import cx from 'classnames';
import React, { PureComponent } from 'react';

import s from './SettingsSection.module.scss';

interface SettingsSectionProps {
  title: string;
  text?: React.ReactNode;
  children?: React.ReactNode;
  box?: boolean;
  className?: string;
  boxClassName?: string;
}

class SettingsSection extends PureComponent<SettingsSectionProps> {
  render() {
    const { title, text, children, box, className, boxClassName } = this.props;

    return (
      <div className={cx(s.root, className)}>
        <h4 className={s.title}>{title}</h4>
        <div
          className={cx({
            [s.box]: !!box,
            boxClassName,
          })}
        >
          {text && <div className={s.text}>{text}</div>}
          {children}
        </div>
      </div>
    );
  }
}

export default SettingsSection;
