import { AnyAction } from 'redux';

import { Query } from '@/search';
import { add, removeAt } from '@/utils/reducer';

import {
  SEARCH,
  SEARCH_CLEAR_PROFILE_SELECTION,
  SEARCH_SELECT_PROFILE,
  SearchAction,
  SearchResultPage,
} from '.';

interface ResultsState {
  fetching: boolean;
  edges: SearchResultPage['edges'];
  pageInfo: SearchResultPage['pageInfo'];
}

interface SearchState {
  queries: Record<string, ResultsState>;
  query: Partial<Query>;
  error?: string;
  selectedProfiles: any[];
}

const initialState: SearchState = {
  queries: {},
  query: {},
  error: undefined,
  selectedProfiles: [],
};

const initialResultsState: ResultsState = {
  fetching: false,
  edges: [],
  pageInfo: { hasNextPage: false, total: 0 },
};

function reduceResults(state = initialResultsState, action: AnyAction): ResultsState {
  switch (action.type) {
    case SEARCH: {
      const searchAction = action as SearchAction;
      const prevState = searchAction.reset ? initialResultsState : state;
      const page = searchAction.page;
      return {
        ...prevState,
        edges: [...prevState.edges, ...(page?.edges || [])],
        pageInfo: { ...prevState.pageInfo, ...page?.pageInfo },
        fetching: searchAction.fetching,
      };
    }
    default:
      return state;
  }
}

export default function searches(state = initialState, action: AnyAction): SearchState {
  switch (action.type) {
    case SEARCH: {
      const searchAction = action as SearchAction;
      const { queries } = state;
      return {
        ...state,
        error: action.error,
        query: {
          ...state.query,
          ...action.query,
        },
        queries: {
          ...queries,
          [searchAction.hash]: reduceResults(queries[searchAction.hash], action),
        },
      };
    }
    case SEARCH_SELECT_PROFILE:
      if (action.selected) {
        return {
          ...state,
          selectedProfiles: add(
            state.selectedProfiles,
            action.profile,
            (p: any) => p.id === action.profile.id
          ),
        };
      }

      return {
        ...state,
        selectedProfiles: removeAt(
          state.selectedProfiles,
          state.selectedProfiles.findIndex((p) => p.id === action.profile.id)
        ),
      };
    case SEARCH_CLEAR_PROFILE_SELECTION:
      return {
        ...state,
        selectedProfiles: [],
      };
    default:
      return state;
  }
}
