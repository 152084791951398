import React from 'react';
import { connect } from 'react-redux';

import Button from '@/components/Button';
import FAIcon from '@/components/Icon/FAIcon';
import { fetchExpertRequests } from '@/expertrequest/store';

import AddToExpertRequestDialog from './AddToExpertRequestDialog';

class AddToExpertRequestButton extends React.Component {
  state = {
    dialogOpened: false,
  };

  handleOpen = () => {
    // @ts-expect-error TS(2339): Property 'fetchExpertRequests' does not exist on t... Remove this comment to see the full error message
    const { fetchExpertRequests } = this.props;

    fetchExpertRequests({ state: 'open' }).then(() => this.setState({ dialogOpened: true }));
  };

  handleClose = () => this.setState({ dialogOpened: false });

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      // @ts-expect-error TS(2339): Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label = 'Add to Request',
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'icon' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      icon = true,
      // @ts-expect-error TS(2339): Property 'onExpertAdd' does not exist on type 'Rea... Remove this comment to see the full error message
      onExpertAdd,
      ...other
    } = this.props;

    // @ts-expect-error TS(2339): Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
    const profiles = profile ? [profile] : this.props.profiles || [];
    if (profiles.length === 0) return null;

    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="lightTan"
          size="small"
          {...other}
          startIcon={icon && <FAIcon size={14} icon="plus" />}
          onClick={this.handleOpen}
        >
          {label}
        </Button>
        {!disabled && (
          <AddToExpertRequestDialog
            open={this.state.dialogOpened}
            profiles={profiles}
            onClose={this.handleClose}
            onExpertAdd={onExpertAdd}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(undefined, {
  fetchExpertRequests,
})(AddToExpertRequestButton);
