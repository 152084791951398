import React from 'react';

import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import {
  clearCollection,
  fetchAwaiting,
  fetchCanceled,
  fetchCompleted,
  fetchConfirmed,
} from '@/consultation/store';

import { LegacyRoute } from '../routesMiddleware';
import Consultations from './Consultations';

const route: LegacyRoute = {
  path: '/consultations',

  async action({ store }) {
    const cursor = '';

    store.dispatch(fetchAwaiting(cursor, 10, true));
    store.dispatch(fetchConfirmed(cursor, 10, true));
    store.dispatch(fetchCanceled(cursor, 10, true));
    store.dispatch(fetchCompleted(cursor, 10, true));
    store.dispatch(clearCollection('default'));

    document.title = 'Consultations';
    return (
      <LayoutPage showNav selected="consultations">
        <ConsultationsStarting />
        <Consultations />
      </LayoutPage>
    );
  },
};

export default route;
