import { dialogClasses } from '@mui/material/Dialog';
import { dialogContentClasses } from '@mui/material/DialogContent';
import { paperClasses } from '@mui/material/Paper';

import theme from '@/theme';
import { getStyles } from '@/utils/styles.helpers';

const nt = 'd-dialog';

export const DialogClasses = {
  dialogTitle: `${nt}-title`,
  dialogBtn: `${nt}-Btn`,
  dialogHeader: `${nt}-header`,
  buttonsContainer: `${nt}-btns-container`,
  headerCloseButton: `${nt}-header-closebtn`,
  dialogIconSize: `${nt}-header-closebtn`,
  ...dialogClasses,
  ...paperClasses,
};

const styles = {
  Dialog: {
    [`& .${DialogClasses.paper}`]: {
      width: '800px',
      maxWidth: '800px',
    },
    [`& .${DialogClasses.dialogIconSize}`]: {
      [`&:hover`]: {
        background: 'transparent',
      },
      [`& svg`]: {
        fontSize: '23px',
      },
    },
    [`& .${dialogContentClasses.root}`]: {
      paddingLeft: '39px',
    },
    [`& .${DialogClasses.dialogHeader}`]: {
      minHeight: '72px',
      paddingLeft: '39px',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingBottom: '17px',
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
      ...getStyles('display-flex-row', 'justify-content-between', 'align-items-start'),
    },
    [`& .${DialogClasses.buttonsContainer}`]: {
      ...getStyles('display-flex-row'),
      marginTop: '30px',
      marginBottom: '30px',
      flexDirection: 'row-reverse',
    },
    [`& .${DialogClasses.dialogBtn}`]: {
      borderRadius: '5px',
    },
  },
};

export default styles;
