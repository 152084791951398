import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';
import React from 'react';

const MonthPicker = ({
  onChange,
  value,
  maxDate,
  minDate,
  placeholder,
  error,
  helperText,
  required,
  ...props
}: any) => {
  const pattern = 'MM/YYYY';

  const handleOnChangeMonth = (e: any) => {
    onChange(e?.toDate());
  };

  const textFieldsProps = {
    error,
    placeholder,
    helperText,
    required,
  };

  return (
    <DatePicker
      {...props}
      maxDate={maxDate ? moment(maxDate) : undefined}
      minDate={minDate ? moment(minDate) : undefined}
      value={moment(value)}
      autoOk
      onBlur={null}
      views={['year', 'month']}
      onChange={handleOnChangeMonth}
      format={pattern}
      slotProps={{
        inputAdornment: { position: 'end' },
        textField: textFieldsProps,
      }}
    />
  );
};

export default MonthPicker;
