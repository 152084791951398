import config from '@/config';

export class Policy {
  id: number;
  key: string;
  name: string;
  slug: string;

  constructor({ id, key, name, slug }: { id: number; key: string; name: string; slug: string }) {
    this.id = id;
    this.key = key;
    this.name = name;
    this.slug = slug;
  }

  get url(): string {
    return `${config.mainSiteUrl}/${this.slug}`;
  }

  get apiKey(): string {
    return `${this.key}:${this.id}`;
  }
}

export const policies: Readonly<Record<string, Policy>> = Object.fromEntries(
  [
    new Policy({
      id: 6848,
      key: 'terms-of-use',
      name: 'Terms of Use',
      slug: 'policies/onfrontiers-terms-of-use',
    }),
    new Policy({
      id: 6630,
      key: 'terms-of-service',
      name: 'Terms of Service',
      slug: 'policies/terms-of-service',
    }),
    new Policy({
      id: 6864,
      key: 'privacy',
      name: 'Privacy Policy',
      slug: 'policies/privacy-policy',
    }),
    new Policy({
      id: 6917,
      key: 'expert-participation-agreement',
      name: 'Expert Participation Agreement',
      slug: 'policies/expert-participation-agreement',
    }),
    new Policy({
      id: 6907,
      key: 'marketplace-expert-agreement',
      name: 'Marketplace Expert Agreement',
      slug: 'policies/marketplace-expert-agreement',
    }),
    new Policy({
      id: 6879,
      key: 'customer-charging-policy',
      name: 'Customer Charging Policy',
      slug: 'policies/customer-charging-policy',
    }),
  ].map((p) => [p.key, p])
);

export interface Agreement {
  policy: string;
  updated_at: string;
  accepted: boolean;
}

export function matchAgreement(apiKey: string, policy: Policy): boolean {
  const [key, id] = apiKey.split(':');
  if (id) {
    return policy.id.toString() === id;
  }
  return policy.key === key;
}

function isValidAgreement(agreement: Agreement) {
  const apiKey = agreement.policy;
  const [key, id] = apiKey.split(':');
  return !!id || !policies[key];
}

function hasAcceptedAgreement(key: string, agreements: Agreement[]) {
  const policy = policies[key];
  if (!policy || !agreements) {
    return false;
  }

  const agreement = agreements.find((a) => matchAgreement(a.policy, policy));
  return !!agreement && agreement.accepted;
}

export class Agreements {
  private readonly userAgreements: Agreement[];

  constructor(userAgreements: Agreement[]) {
    this.userAgreements = userAgreements;
  }

  hasAccepted(...policyKeys: string[]): boolean {
    if (!config.requireAgreements) {
      return true;
    }
    const agreements = this.userAgreements.filter(isValidAgreement);
    return policyKeys.reduce((acc, key) => {
      return acc && hasAcceptedAgreement(key, agreements);
    }, true);
  }
}

export default function agreements(userAgreements: Agreement[]): Agreements {
  return new Agreements(userAgreements);
}
