import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ShortlistProfile } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState.harnessSearch,
  reducers: {
    createOrEditSearch: (
      state,
      action: PayloadAction<
        { savedSearchId: string } | { initialShortlist: ShortlistProfile[] } | undefined
      >
    ) => {
      let editId = undefined;
      if (action.payload && 'savedSearchId' in action.payload) {
        editId = action.payload.savedSearchId;
      }
      let initialShortlist = undefined;
      if (action.payload && 'initialShortlist' in action.payload) {
        initialShortlist = action.payload.initialShortlist;
      }
      return {
        ...state,
        createOrEditDialogIsOpen: true,
        editId,
        initialShortlistOnSearchCreation: initialShortlist,
      };
    },
    closeCreateOrEditSearch: (state) => {
      return {
        ...state,
        createOrEditDialogIsOpen: false,
        editId: undefined,
        initialShortlistOnSearchCreation: undefined,
      };
    },
    showSavedSearchesDrawer: (state) => {
      return {
        ...state,
        savedSearchesDrawerIsOpen: true,
      };
    },
    closeSavedSearchesDrawer: (state) => {
      return {
        ...state,
        savedSearchesDrawerIsOpen: false,
      };
    },
    showShortlistDrawer: (state) => {
      return {
        ...state,
        shortlistDrawerIsOpen: true,
      };
    },
    closeShortlistDrawer: (state) => {
      return {
        ...state,
        shortlistDrawerIsOpen: false,
      };
    },
    showCollaboratorsDialog: (state) => {
      return {
        ...state,
        collaboratorsDialogIsOpen: true,
      };
    },
    closeCollaboratorsDialog: (state) => {
      return {
        ...state,
        collaboratorsDialogIsOpen: false,
      };
    },
    resetData: () => initialState.harnessSearch,
  },
});

export const searchSelector = (state: IState) => state.harnessSearch;
export const {
  resetData,
  createOrEditSearch,
  closeCreateOrEditSearch,
  showSavedSearchesDrawer,
  closeSavedSearchesDrawer,
  showShortlistDrawer,
  closeShortlistDrawer,
  showCollaboratorsDialog,
  closeCollaboratorsDialog,
} = searchSlice.actions;
export default searchSlice.reducer;
