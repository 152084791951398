import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { dismissAppNotification, readAppNotifications } from '@/actions/appNotification';
import { approveGroupMember, denyGroupMember } from '@/actions/group';
import { red500 } from '@/theme/colors';

import Alert from '../Alert';
import Button from '../Button';
import Picture from '../Picture';

const useStyles = makeStyles(() => ({
  expertName: {
    fontWeight: 'bold',
  },
  notificationContents: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  notificationTitle: {
    marginBottom: 20,
  },
  notificationPhoto: {
    marginRight: 20,
  },
  approveButton: {
    marginRight: 10,
  },
  notificationBody: {
    flex: 1,
  },
}));

function GroupMemberAwaitingApprovalNotification({
  notification,
  readAppNotifications,
  approveGroupMember,
  denyGroupMember,
  dismissAppNotification,
}: any) {
  const s = useStyles();

  useEffect(() => {
    readAppNotifications(notification.id);
  }, [notification.id, readAppNotifications]);

  const handleApprove = async () => {
    await approveGroupMember(notification.payload.member.id);
    await dismissAppNotification(notification.id);
  };

  const handleDeny = async () => {
    await denyGroupMember(notification.payload.member.id);
    await dismissAppNotification(notification.id);
  };

  const { payload } = notification;
  const { member } = payload;
  const { user, group } = member || {};
  if (!user) return null;

  return (
    <Alert preset="notification">
      <div className={s.notificationContents}>
        <div className={s.notificationPhoto}>
          <Picture user={user} size={60} />
        </div>
        <div className={s.notificationBody}>
          <div className={s.notificationTitle}>
            <span className={s.expertName}>{user.name}</span>
            &nbsp;would like to join {group.name}
          </div>
          <Button onClick={handleApprove} classes={{ root: s.approveButton }} size="medium">
            Approve
          </Button>
          <Button onClick={handleDeny} color="white" fontColor={red500} size="medium">
            Deny
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default connect(undefined, {
  readAppNotifications,
  dismissAppNotification,
  approveGroupMember,
  denyGroupMember,
})(GroupMemberAwaitingApprovalNotification);

GroupMemberAwaitingApprovalNotification.filterNotifications = (notifications: any[]) => {
  // notification must have accessible awaiting member to be accepted
  const accepted = notifications.filter((n: any) => n.payload.member && n.payload.member.user);

  // at most 3 notifications
  return accepted.slice(0, 3);
};
