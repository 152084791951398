import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import DraggableList from '@/components/DraggableList';
import { Checkbox, RadioGroup, TextField } from '@/components/FormAdapters';
import { required } from '@/utils';

function ExpertQuery({
  name,
  label,
  placeholder,
  disabled,
  value,
  isViewerExpert,
  isViewerAdmin,
}: any) {
  const editDisabled = disabled || (!!value?.id && !isViewerAdmin);

  return (
    <div style={{ flexDirection: 'column', margin: '0 10px', width: '100%' }}>
      <Field
        id={`expertRequests-${name}`}
        name={`${name}.query`}
        component={TextField}
        // Required to prevent entered text from being lost during drag and
        // drop if user has not clicked outside of the field
        changeOnBlur={false}
        validate={required}
        required
        disabled={editDisabled}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        multiline
        minRows={2}
        maxRows={20}
        fullWidth
        style={{ marginTop: 0 }}
        // Disable native required validation
        inputProps={{
          required: false,
          maxLength: 2048,
        }}
        // prevents re-rendering of the label on drop
        InputLabelProps={{ shrink: true }}
      />
      {!isViewerExpert && (
        <>
          <Field
            name={`${name}.required`}
            component={Checkbox}
            label="Required"
            type="checkbox"
            FormControlProps={{
              style: { margin: '0' },
              required: true,
              disabled: editDisabled,
            }}
          />
          <Field
            name={`${name}.response_type`}
            component={RadioGroup}
            validate={required}
            label="How do you want the expert to respond?"
            FormControlProps={{
              style: { margin: '10px 0 10px' },
              required: true,
              disabled: editDisabled,
            }}
            style={{ flexDirection: 'row' }}
            options={[
              {
                label: 'Yes/No',
                value: 'yes_no',
                id: `expertRequestsYesNoRadioButton-${name}`,
              },
              {
                label: 'Free-form Text',
                value: 'free_form',
                id: `expertRequestsFreeFormTextRadioButton-${name}`,
              },
            ]}
          />
        </>
      )}
    </div>
  );
}

class ExpertQueries extends React.PureComponent {
  render = () => {
    const {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label,
      // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
      placeholder,
      // @ts-expect-error TS(2339): Property 'isMobileVersion' does not exist on type ... Remove this comment to see the full error message
      isMobileVersion,
      // @ts-expect-error TS(2339): Property 'isViewerExpert' does not exist on type '... Remove this comment to see the full error message
      isViewerExpert,
      // @ts-expect-error TS(2339): Property 'isViewerAdmin' does not exist on type 'R... Remove this comment to see the full error message
      isViewerAdmin,
      ...other
    } = this.props;
    return (
      <FieldArray
        component={DraggableList}
        disabled={disabled}
        name={name}
        defaultValue={[]}
        inputComponent={ExpertQuery}
        inputProps={{
          label,
          placeholder: isMobileVersion ? '' : placeholder,
          disabled,
          isViewerExpert,
          isViewerAdmin,
        }}
        showRemoveForField={(v: any) => !v?.id || isViewerAdmin}
        {...other}
      />
    );
  };
}

export default ExpertQueries;
