import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

import { styles } from '../styles';
import { FlagIconProps } from '../types';

const useStyles = makeStyles(styles);

export const FlagIcon: React.FC<FlagIconProps> = ({ className, country, ...rest }) => {
  const classes = useStyles();

  const classProps = clsx(className, classes.flagIcon, 'flag', `flag-${country.toLowerCase()}`);

  return <span className={classProps} data-testid="of-flag-icon" {...rest} />;
};

export default FlagIcon;
