import { gengql } from '@/__generated__';
import { PROFILE__UPDATE } from '@/profile/store';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { NETWORK__UPDATE_EXPERT, GROUP__UPDATE } = ActionTypes;

const UPDATE_NETWORK = gengql(/* GraphQL */ `
  mutation actionUpdateNetwork(
    $group_id: String!
    $expert_not_available_self_service: Boolean
    $expert_unpaid: Boolean
  ) {
    updateNetwork(
      group_id: $group_id
      expert_not_available_self_service: $expert_not_available_self_service
      expert_unpaid: $expert_unpaid
    ) {
      id
      name
      expert_not_available_self_service
      expert_unpaid
    }
  }
`);

export function fetchNetworkExpert(networkExpertId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { internalNetworkExpert: networkExpert } = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchNetworkExpert($id: String!) {
          internalNetworkExpert(id: $id) {
            id
            network {
              id
              group {
                id
                domain {
                  id
                  logo_url
                  agreements(active_only: true) {
                    id
                    policy_code
                    policy_label
                    policy_url
                  }
                }
              }
            }
          }
        }
      `),
      { id: networkExpertId }
    );

    if (networkExpert) dispatch({ type: NETWORK__UPDATE_EXPERT, networkExpert });

    return networkExpert;
  };
}

export function canJoinNetwork(params: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.query(
      gengql(/* GraphQL */ `
        query canJoinNetwork($email: String, $profileId: String, $networkId: String!) {
          canJoinNetwork(email: $email, profile_id: $profileId, network_id: $networkId)
        }
      `),
      params
    );
}

export function joinNetwork(subdomain: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionJoinNetwork($subdomain: String!) {
          joinNetwork(subdomain: $subdomain) {
            id
          }
        }
      `),
      { subdomain }
    );
}

export function addNetworkExperts(params: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionAddNetworkExperts(
          $profileIds: [ID!]
          $networkIds: [ID!]
          $addMethod: NetworkExpertAddMethod!
          $teamNote: String
        ) {
          addNetworkExperts(
            profile_ids: $profileIds
            network_ids: $networkIds
            add_method: $addMethod
            team_note: $teamNote
          ) {
            id
          }
        }
      `),
      params
    );
}

export function removeNetworkExperts(params: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveNetworkExperts(
          $networkExpertIds: [ID!]
          $message: String
          $unshare: Boolean
        ) {
          removeNetworkExperts(
            network_expert_ids: $networkExpertIds
            message: $message
            unshare: $unshare
          )
        }
      `),
      params
    );
}

export function updateNetworkExpert(networkExpert: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateNetworkExpert(
          $id: String!
          $connection_state: NetworkExpertConnectionState
        ) {
          updateNetworkExpert(id: $id, connection_state: $connection_state) {
            id
          }
        }
      `),
      networkExpert
    );
}

export function updateProfileKeywords(
  profileId: any,
  toAdd = [],
  toDelete = []
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateProfileKeywords(
          $profileId: String!
          $toAdd: [String]!
          $toDelete: [String]!
        ) {
          updateProfileKeywords(profile_id: $profileId, to_add: $toAdd, to_delete: $toDelete) {
            group {
              id
              name
            }
            keywords {
              id
              name
            }
          }
        }
      `),
      {
        profileId,
        toAdd,
        toDelete,
      }
    );

    if (result) {
      dispatch({
        type: PROFILE__UPDATE,
        profile: {
          id: profileId,
          group_keywords: result.updateProfileKeywords,
        },
      });

      return result.updateProfileKeywords;
    }
  };
}

export function updateNetwork(network: any, groupId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { updateNetwork } = await graphql.mutate(UPDATE_NETWORK, network);

    if (updateNetwork && groupId) {
      dispatch({
        type: GROUP__UPDATE,
        group: { id: groupId, internal_network: updateNetwork },
      });
    }

    return updateNetwork;
  };
}
