import arrayMutators from 'final-form-arrays';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Attachments from '@/components/Attachments/Attachments';
import Button from '@/components/Button';
import { addAttachments } from '@/consultation/store';

import s from './SubmitAttachments.module.scss';

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;
const attachmentsAddStyle: React.CSSProperties = {
  textAlign: 'right',
  marginRight: 40,
};

interface SubmitAttachmentsProps {
  addAttachments: (data: any) => Promise<void>;
  consultation: any;
  notify: (message: string, type: string) => void;
  isViewerExpert: boolean;
  isMobileVersion: boolean;
}

const SubmitAttachments: React.FC<SubmitAttachmentsProps> = ({
  addAttachments,
  consultation,
  notify,
  isViewerExpert,
  isMobileVersion,
}) => {
  const [allowSubmit, setAllowSubmit] = useState(false);

  const handleAddFiles = useCallback(() => {
    setAllowSubmit(true);
  }, []);

  const handleSubmitFiles = useCallback(
    async (values: any) => {
      const { attachments } = values;

      try {
        await addAttachments({
          consultation,
          attachments: attachments.filter((a: any) => !a.id),
        });
        notify('Successfully submitted written response files.', 'success');
      } catch (ex) {
        notify('Error submitting written response files.', 'error');
        throw ex;
      }

      setAllowSubmit(false);
    },
    [addAttachments, consultation, notify]
  );

  const canUpload = isViewerExpert;

  const title = isViewerExpert
    ? 'File(s) uploaded for written response to client'
    : consultation.attachments.length
      ? 'File(s) uploaded by expert for written response'
      : 'No files have been uploaded by expert';

  return (
    <Form
      onSubmit={handleSubmitFiles}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={consultation}
    >
      {({ handleSubmit }) => (
        <div className={s.attachmentSection}>
          <div>
            <p className={s.attachmentTitle}>{title}</p>
            {canUpload && (
              <p className={s.attachmentSubtitle}>
                {`Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`}
              </p>
            )}
          </div>
          <Attachments
            name="attachments"
            disabled={!canUpload}
            label="Upload Files"
            addButtonLabel="Files"
            isMobileVersion={isMobileVersion}
            maxAttachmentFiles={maxAttachmentFiles}
            maxAttachmentSize={maxAttachmentSize}
            onAddedFiles={handleAddFiles}
            addActionStyle={attachmentsAddStyle}
          />
          {allowSubmit && (
            <div className={s.submitButton}>
              <Button onClick={handleSubmit} color="teal" size="large">
                Submit Files
              </Button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

export default connect(undefined, {
  notify,
  addAttachments,
})(SubmitAttachments);
