import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Dialog from '@/components/Dialog/Dialog';
import FAIcon from '@/components/Icon/FAIcon';
import { borderColor, darkGray, red500 } from '@/theme/colors';
import { isEmpty, prettyName } from '@/utils';

import ModelField from './ModelField';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    borderTop: `1px solid ${borderColor}`,
    paddingTop: 15,
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  index: {
    paddingRight: 15,
    fontSize: 18,
    color: darkGray,
  },
  row: {
    display: 'flex',
    border: `1px solid ${borderColor}`,
    padding: 15,
    marginBottom: 10,
  },
  delete: {
    paddingLeft: 15,
    color: red500,
    cursor: 'pointer',
  },
  button: {
    fontSize: 12,
  },
  fields: {
    padding: 15,
  },
  grid: {
    alignItems: 'center',
  },
}));

export default function ModelArray({ name, allRequired, form, fields: fieldsProp, validate }: any) {
  const [pendentAction, setPendentAction] = useState(undefined);
  const s = useStyles();

  const { push } = form.mutators;

  const onCreate = () => {
    push(name, {});
  };

  const defaultValidate = useCallback(
    (valuesArray = []) => {
      const errorsArray: any = [];

      valuesArray.forEach((values) => {
        const errors = {};

        fieldsProp.forEach((f: any) => {
          if ((f.required || allRequired) && isEmpty(values[f.name])) {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            errors[f.name] = `Required`;
          }
        });

        errorsArray.push(errors);
      });

      return errorsArray;
    },
    [fieldsProp]
  );

  return (
    <>
      <List className={s.root}>
        <ListSubheader disableSticky className={s.header}>
          {prettyName(name)}
          <Button variant="contained" color="secondary" className={s.button} onClick={onCreate}>
            <FAIcon icon="plus" style={{ marginRight: 10 }} /> Add
          </Button>
        </ListSubheader>
        <div className={s.fields}>
          <FieldArray name={name} validate={validate || defaultValidate}>
            {({ fields }) =>
              fields.map((n, index) => (
                <div className={s.row} key={n}>
                  <span className={s.index}>{index + 1}.</span>

                  <Grid container className={s.grid} spacing={4}>
                    {fieldsProp.map((f: any) => {
                      const path = `${n}.${f.name}`;
                      return (
                        <Grid item md={f.columnWidth || 4} key={f.name}>
                          <ModelField
                            key={path}
                            required={allRequired}
                            {...f}
                            form={form}
                            name={path}
                            index={index}
                            value={fields.value[index][f.name]}
                            margin="dense"
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  <FAIcon
                    className={s.delete}
                    onClick={() =>
                      // @ts-expect-error TS(2345) FIXME: Argument of type '() => () => any' is not assignab... Remove this comment to see the full error message
                      setPendentAction(() => () => fields.remove(index))
                    }
                    icon="trash"
                  />
                </div>
              ))
            }
          </FieldArray>
        </div>
      </List>

      <Dialog
        open={pendentAction !== undefined}
        fullWidth
        maxWidth="sm"
        confirmLabel="Remove"
        onConfirm={() => {
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          pendentAction();
          setPendentAction(undefined);
        }}
        cancelLabel="Cancel"
        onCancel={() => setPendentAction(undefined)}
      >
        Are you sure?
      </Dialog>
    </>
  );
}
