import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { AssignmentTypes } from '@/assignment/AssignmentsManager';
import {
  AssignmentsUseQueryOptions,
  DEFAULT_ASSIGNMENTS_LIMIT,
  useAssignmentsByJobIdQuery,
} from '@/assignment/queries/assignments';
import Pagination from '@/componentsv2/Pagination';

const usePaginatedAssignments = ({
  jobId,
  assignmentTypes,
  limit = DEFAULT_ASSIGNMENTS_LIMIT,
  options,
}: {
  jobId: string;
  assignmentTypes: AssignmentTypes;
  limit?: number;
  options?: AssignmentsUseQueryOptions;
}) => {
  const [page, setPage] = useState(1);

  const { data, isLoading, isRefetching, isPreviousData, isFetching } = useAssignmentsByJobIdQuery(
    {
      jobId,
      page,
      limit,
      assignmentTypes,
    },
    options
  );
  const queryClient = useQueryClient();

  const count = data?.count ?? 0;

  useEffect(() => {
    if (page > 1 && count <= limit && !isFetching) {
      queryClient.removeQueries(['assignments', { jobId, assignmentTypes, page }]);
      setPage(1);
    }
  }, [assignmentTypes, count, isFetching, limit, page, queryClient, jobId]);

  const pagination =
    count > limit ? (
      <Pagination
        page={page}
        count={Math.ceil(count / limit)}
        handlePageChange={(_, page) => setPage(page)}
      />
    ) : null;

  return {
    data,
    count,
    isLoading,
    isRefetching,
    isPreviousData,
    isFetching,
    pagination,
  };
};

export default usePaginatedAssignments;
