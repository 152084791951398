import React, { useEffect } from 'react';

const AbsoluteRedirect: React.FC<{
  to: string;
}> = ({ to }) => {
  useEffect(() => {
    window.location.assign(to);
  });
  return null;
};

export default AbsoluteRedirect;
