import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import SelectLocation from '@/components/SelectLocation';
import SelectSector from '@/components/SelectSector';
import { updateProfile } from '@/profile/store';
import { parseId } from '@/utils';

class Expertise extends PureComponent {
  static propTypes = {
    allCountries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    allSectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    countries: PropTypes.array,
    sectors: PropTypes.array,
    keywords: PropTypes.array,
    handleSubmit: PropTypes.func,
    array: PropTypes.object,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container = Form,
      // @ts-expect-error TS(2339): Property 'allCountries' does not exist on type 'Re... Remove this comment to see the full error message
      allCountries,
      // @ts-expect-error TS(2339): Property 'allSectors' does not exist on type 'Read... Remove this comment to see the full error message
      allSectors,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'array' does not exist on type 'Readonly<... Remove this comment to see the full error message
      array,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      // @ts-expect-error TS(2339): Property 'isMobileVersion' does not exist on type ... Remove this comment to see the full error message
      isMobileVersion,
      ...other
    } = this.props;

    function formatter(meta: any) {
      return function (values: any) {
        return (
          values &&
          values
            .map((value: any) => meta.find((c: any) => c.id.toString() === value))
            .filter(Boolean)
        );
      };
    }

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          component={SelectLocation}
          multiple
          id="regions"
          name="region_ids"
          countries={allCountries}
          label="Select countries and regions"
          format={formatter(allCountries)}
          parse={parseId}
        />
        <Field
          component={SelectSector}
          id="sectors"
          name="sector_ids"
          sectors={allSectors}
          format={formatter(allSectors)}
          parse={parseId}
        />
      </Container>
    );
  }
}

// @ts-expect-error TS(2629): Cannot assign to 'Expertise' because it is a class... Remove this comment to see the full error message
Expertise = reduxForm({
  form: 'expertExpertise',
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'typeof Expertise' is not assigna... Remove this comment to see the full error message
})(Expertise);

// @ts-expect-error TS(2629): Cannot assign to 'Expertise' because it is a class... Remove this comment to see the full error message
Expertise = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        region_ids: profile.regions.map((r: any) => r.id),
        sector_ids: profile.sectors.map((s: any) => s.id),
        keywords: profile.keywords,
      },
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      allCountries: state.countries,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      allSectors: state.sectors.all,
    };
  },
  {
    updateProfile,
  }
  // @ts-expect-error TS(2345): Argument of type 'typeof Expertise' is not assigna... Remove this comment to see the full error message
)(Expertise);

export default Expertise;
