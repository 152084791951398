import cx from 'classnames';

import LogoImg from '@/components/Logo';
import config from '@/config';
import { policies } from '@/core/agreements';

import Body from '../Body';
import Divider from '../Divider/Divider';
import s from './Footer.module.scss';
import Facebook from './icons/footer-icon-facebook.svg';
import LinkedIn from './icons/footer-icon-linkedin.svg';
import Twitter from './icons/footer-icon-twitter.svg';
import Youtube from './icons/footer-icon-youtube.svg';

const { mainSiteUrl } = config;

function MarketingLinks() {
  return (
    <div className={s.socialGrid}>
      <div className={s.gridLogo}>
        <LogoImg dark width={142} height={13} />
        <p className="mt-12 text-10">
          <strong>OnFrontiers, Inc.</strong>
          <br />
          42 W 23rd St, 6th Fl
          <br />
          New York, NY, 10010
        </p>
      </div>
      <div className={cx(s.gridLearnMore, 'flex flex-col gap-5')}>
        <strong className="mb-12">Learn More</strong>
        <a
          href={`${mainSiteUrl}/product`}
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          Product
        </a>
        <a
          href={`${mainSiteUrl}/blog`}
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          Blog
        </a>
        <a
          href={`${mainSiteUrl}/about`}
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          About
        </a>
        <a
          href={`${mainSiteUrl}/careers`}
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          Careers
        </a>
        <a href="/signup/expert" target="_blank" className="font-semibold underline">
          Join our Expert Marketplace
        </a>
      </div>
      <div className={cx(s.gridContact, 'flex flex-col gap-5')}>
        <strong className="mb-12">Contact Us</strong>
        <a
          href="/login?_gl=1*1pdsu6z*_ga*MjE0MjA3MzE0Ny4xNjUyMjE0NTM2*_ga_H81JGPMNJS*MTY5Mzk2MjMzOC41NS4xLjE2OTM5NjIzNTQuNDQuMC4w*_ga_MGPKYQJTYM*MTY5Mzk2MjMzOC4xNi4xLjE2OTM5NjIzNTQuMC4wLjA.*_ga_WHQJGC49XR*MTY5Mzk2MjMzOC4yNi4xLjE2OTM5NjIzNTQuMC4wLjA.&_ga=2.79508882.709995483.1693959266-2142073147.1652214536"
          target="_blank"
          className="font-semibold underline"
        >
          Log In
        </a>
        <a
          href="https://try.onfrontiers.com/knowledge"
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          Help Center
        </a>
        <a
          href={`${mainSiteUrl}/request-demo`}
          target="_blank"
          className="font-semibold underline"
          rel="noreferrer"
        >
          Talk to Sales
        </a>
      </div>
      <div className={cx(s.gridFollow, 'flex flex-col gap-5')}>
        <strong className="mb-12">Follow Us</strong>
        <a
          className="flex items-center gap-2"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/onfrontiers"
        >
          <img src={Facebook} alt="Facebook" />
          Facebook
        </a>
        <a
          className="flex items-center gap-2 whitespace-nowrap"
          rel="noopener noreferrer"
          target="_blank"
          href="https://twitter.com/Onfrontiers"
        >
          <img src={Twitter} alt="Twitter" />X / Twitter
        </a>
        <a
          className="flex items-center gap-2"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/onfrontiers"
        >
          <img src={LinkedIn} alt="LinkedIn" />
          LinkedIn
        </a>
        <a
          className="flex items-center gap-2"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/@OnfrontiersOfficial"
        >
          <img src={Youtube} alt="Youtube" />
          Youtube
        </a>
      </div>
    </div>
  );
}

export default function Footer({ style, is404 = false }: any) {
  const tosPol = policies['terms-of-service'];
  const privacyPol = policies.privacy;

  return (
    <div className={s.root}>
      <footer className={s.footer} style={style}>
        <Body>
          {is404 && <MarketingLinks />}
          <Divider className={s.divider} spacing={35} />
          <div className={s.address}>
            <div className={s.addressColumn}>© OnFrontiers, Inc. All rights reserved.</div>
            <div className={s.linksColumn}>
              {!is404 && (
                <a
                  className={s.link}
                  href="https://try.onfrontiers.com/knowledge"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
              )}
              <a
                className={s.link}
                href={`${mainSiteUrl}/policies/privacy-policy`}
                target="_blank"
                rel="noreferrer"
              >
                {privacyPol.name}
              </a>
              <a
                className={s.link}
                href={`${mainSiteUrl}/policies/terms-of-service`}
                target="_blank"
                rel="noreferrer"
              >
                {tosPol.name}
              </a>
              {!is404 && (
                <a className={s.link} href={`${mainSiteUrl}/blog`} target="_blank" rel="noreferrer">
                  Blog
                </a>
              )}
            </div>
          </div>
        </Body>
      </footer>
    </div>
  );
}
