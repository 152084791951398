import config from '@/config';
import NotFoundPage from '@/pages/NotFoundPage';

import { LegacyRoute } from '../routesMiddleware';

function graphQLFetcher(token: any, graphQLParams: any) {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    // @ts-expect-error TS(2339) FIXME: Property 'Authorization' does not exist on type '{... Remove this comment to see the full error message
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${config.apiUrl}/graphql`, {
    method: 'post',
    headers,
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
}

const route: LegacyRoute = {
  path: '/graphiql',

  async action({ store }) {
    const { viewer } = store.getState();
    if (import.meta.env.MODE !== 'development' && !viewer.admin) return <NotFoundPage />;

    const { token } = store.getState().runtime;

    const GraphIQL = (await import('graphiql')).default;
    document.title = 'GraphiQL';
    return (
      <div style={{ height: '100vh' }}>
        <GraphIQL fetcher={(params) => graphQLFetcher(token, params)} />
      </div>
    );
  },
};

export default route;
