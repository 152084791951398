import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { darken, lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { FC, memo } from 'react';

import { black, darkGray, lightTan, primary, red500, teal900, white } from '@/theme/colors';

import CircularProgress from '../CircularProgress/CircularProgress';
import FAIcon from '../Icon/FAIcon';

const useStyles = makeStyles({
  fontBold: {
    fontWeight: 'bold',
  },
  fontSmoothing: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  circularProgress: {
    color: 'inherit',
  },
});

const useButtonColors = makeStyles({
  // @ts-expect-error TS(2339): Property 'inverted' does not exist on type '{}'.
  primary: ({ inverted }) => ({
    backgroundColor: inverted ? white : primary,
    color: inverted ? primary : white,
    '&:hover': {
      backgroundColor: darken(inverted ? white : primary, 0.1),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(white) : white,
    },
  }),
  // @ts-expect-error TS(2339): Property 'inverted' does not exist on type '{}'.
  teal: ({ inverted }) => ({
    backgroundColor: inverted ? white : teal900,
    // @ts-expect-error TS(2339): Property 'fontColor' does not exist on type '{}'.
    color: ({ fontColor }) => (inverted ? fontColor || teal900 : white),
    '&:hover': {
      backgroundColor: darken(inverted ? white : teal900, 0.1),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(white) : white,
    },
  }),
  // @ts-expect-error TS(2339): Property 'inverted' does not exist on type '{}'.
  red: ({ inverted }) => ({
    backgroundColor: inverted ? white : red500,
    color: inverted ? red500 : white,
    '&:hover': {
      backgroundColor: darken(inverted ? white : red500, 0.1),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(white) : white,
    },
  }),
  // @ts-expect-error TS(2339): Property 'inverted' does not exist on type '{}'.
  lightTan: ({ inverted }) => ({
    backgroundColor: inverted ? darkGray : lightTan,
    color: inverted ? lightTan : darkGray,
    '&:hover': {
      backgroundColor: darken(lightTan, 0.1),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(white) : white,
    },
  }),
  // @ts-expect-error TS(2339): Property 'inverted' does not exist on type '{}'.
  darkGray: ({ inverted }) => ({
    color: inverted ? white : darkGray,
    '&:hover': {
      backgroundColor: darken(inverted ? darkGray : white, 0.1),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(darkGray) : darkGray,
    },
  }),
  // @ts-expect-error TS(2339): Property 'fontColor' does not exist on type '{}'.
  white: ({ fontColor, inverted }) => ({
    color: inverted ? black : (fontColor ?? black),
    backgroundColor: inverted ? (fontColor ?? black) : white,
    '&:hover': {
      backgroundColor: lighten(inverted ? (fontColor ?? white) : black, 0.98),
    },
    '&:disabled': {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      color: inverted ? darken(darkGray) : darkGray,
    },
  }),
});

const useTextColors = makeStyles({
  // @ts-expect-error TS(2339): Property 'fontColor' does not exist on type '{}'.
  root: ({ fontColor = darkGray }) => ({
    color: fontColor,
    '&:hover': {
      color: darken(fontColor, 0.1),
    },
    '&:disabled': {
      color: lighten(fontColor, 0.1),
    },
  }),
});

const useSizes = makeStyles({
  tiny: {
    padding: 0,
    fontSize: 12,
  },
  huge: {
    minHeight: 90,
    padding: '25px 30px',
    fontSize: 20,
  },
  normal: {
    minHeight: 50,
    padding: '12px 20px',
    fontSize: 16,
  },
  large: {
    minHeight: 70,
    padding: '20px 25px',
    fontSize: 16,
  },
});

const useSquared = makeStyles({
  squared: {
    // @ts-expect-error TS(2339): Property 'squareSize' does not exist on type '{}'.
    minWidth: (props) => props.squareSize,
    // @ts-expect-error TS(2339): Property 'squareSize' does not exist on type '{}'.
    width: (props) => props.squareSize,
    // @ts-expect-error TS(2339): Property 'squareSize' does not exist on type '{}'.
    height: (props) => props.squareSize,
    verticalAlign: 'top',
    marginRight: 20,
    padding: 0,
  },
});

interface ButtonProps extends Omit<MuiButtonProps, 'variant' | 'color' | 'size'> {
  variant?: 'inverted' | 'text' | 'contained';
  color?:
    | 'white'
    | 'red'
    | 'lightTan'
    | 'teal'
    | 'darkGray'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'tiny' | 'normal' | 'large' | 'huge' | 'small' | 'medium' | 'large';
  fontColor?: string;
  fontSmoothing?: boolean;
  fontBold?: boolean;
  label?: string;
  backgroundColor?: string;
  square?: boolean;
  squareSize?: number;
  submitting?: boolean;
}

const Button: FC<ButtonProps> = memo(
  ({
    variant = 'contained',
    color = 'primary',
    classes = {},
    size = 'large',
    startIcon,
    fontColor,
    fontSmoothing,
    fontBold,
    label,
    backgroundColor,
    style,
    square,
    squareSize = 140,
    submitting,
    ...other
  }) => {
    const text = useTextColors({ fontColor });
    const colors = useButtonColors({
      fontColor,
      inverted: variant === 'inverted',
    });
    const sizes = useSizes();
    const s = useStyles();
    const squared = useSquared({ squareSize });
    const styles = {
      ...(style || {}),
      ...(backgroundColor ? { backgroundColor } : {}),
    };
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const colorClassName = variant === 'text' ? text.root : colors[color];

    const rootClassname = classes && classes.root;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const sizeClassName = sizes[size];

    const startIconElement =
      typeof startIcon === 'string' ? <FAIcon size={14} icon={startIcon} /> : startIcon;

    const actualFontSmoothing =
      typeof fontSmoothing === 'boolean' ? fontSmoothing : variant !== 'text';

    const actualFontBold = typeof fontBold === 'boolean' ? fontBold : variant !== 'text';

    return (
      <MuiButton
        {...other}
        variant={variant === 'text' ? 'text' : 'contained'}
        color={
          colorClassName
            ? undefined
            : (color as 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'warning')
        }
        size={sizeClassName ? undefined : (size as 'small' | 'medium' | 'large')}
        startIcon={startIconElement}
        style={styles}
        classes={{
          ...classes,
          root: cx({
            [s.fontBold]: actualFontBold,
            [s.fontSmoothing]: actualFontSmoothing,
            [colorClassName]: !!colorClassName,
            [sizeClassName]: !!sizeClassName,
            [squared.squared]: !!square,
            ...(rootClassname && { [rootClassname]: !!rootClassname }),
          }),
        }}
        data-sentry-unmask
      >
        {submitting ? (
          <CircularProgress
            color="inherit"
            size={14}
            thickness={5}
            classes={{
              root: s.circularProgress,
            }}
          />
        ) : (
          label || other.children
        )}
      </MuiButton>
    );
  }
);
Button.displayName = 'Button';

export default Button;
