import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { updateConsultationReview } from '@/consultation/store';

import Dialog from '../Dialog';
import { TextField } from '../FormAdapters';

function EditReviewForm({
  // Props
  open,

  onClose,
  review,

  // Redux Actions
  updateConsultationReview,

  notify,
}: any) {
  const validate = useCallback((values: any) => {
    const errors = {};
    if (!values.review) {
      // @ts-expect-error TS(2339): Property 'review' does not exist on type '{}'.
      errors.review = 'Review can not be blank';
    }
    return errors;
  }, []);

  const handleSubmit = useCallback(async (values: any) => {
    const { id } = values;
    const { review } = values;

    try {
      await updateConsultationReview({
        id,
        review,
      });

      notify('Review updated.');
      onClose();
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      notify(err.message, 'error');
      return err;
    }
  }, []);

  const initialValues = useMemo(
    () => ({
      id: review.id,
      review: review.review,
    }),
    [review]
  );

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      component={EditReviewDialog}
      open={open}
      onClose={onClose}
    />
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'EditReviewForm' because it is a ... Remove this comment to see the full error message
EditReviewForm = connect(undefined, {
  notify,
  updateConsultationReview,
})(EditReviewForm);

function EditReviewDialog({
  // Props
  open,

  onClose,

  // Final Form
  form,

  submitting,
  handleSubmit,
}: any) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirm = (event: any) => {
    handleSubmit(event);
    setConfirmDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        confirmLabel="Save"
        title="Edit Review"
        onConfirm={() => setConfirmDialogOpen(true)}
        onReset={() => form.reset()}
        onClose={onClose}
        onCancel={onClose}
        disableSubmit={submitting}
      >
        <Field name="review" component={TextField} multiline rows={10} label="Review Content" />
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        title="Are you sure?"
        maxWidth="sm"
        onConfirm={handleConfirm}
        onCancel={() => setConfirmDialogOpen(false)}
      >
        This text can NOT be edited once saved.
      </Dialog>
    </React.Fragment>
  );
}

export default EditReviewForm;
