import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, Project, ProjectService } from '@/openapi';

type FetchProject = {
  projectId: string;
  profileId: string;
};

const fetchProject = ({
  projectId,
}: Omit<FetchProject, 'profileId'>): CancelablePromise<Project> => {
  return ProjectService.projectRetrieve(projectId);
};

export const useProjectQuery = ({ projectId, profileId }: FetchProject) => {
  return useQuery({
    queryKey: ['project', { projectId, profileId }],
    queryFn: () => fetchProject({ projectId }),
    enabled: !!projectId,
  });
};
