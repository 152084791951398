import ActionTypes from '@/actions/ActionTypes';

const { SUGGEST, SUGGEST_LOADING } = ActionTypes;

const initialScopeState = {
  cache: {},
  loading: false,
};

const initialState = {
  users: initialScopeState,
  profiles: initialScopeState,
};

export default function suggestions(state = initialState, action: any) {
  switch (action.type) {
    case SUGGEST:
    case SUGGEST_LOADING:
      // scopes allow multiple suggestion use cases to coexist
      return {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [action.scope]: reduceScope(state[action.scope], action),
      };
    default:
      return state;
  }
}

function reduceScope(state = initialScopeState, action: any) {
  switch (action.type) {
    case SUGGEST:
      // each different text suggestion is cached in a separate key in the state
      return {
        loading: action.loading,
        cache: {
          ...state.cache,
          [action.text]: action.suggestions,
        },
      };
    case SUGGEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
