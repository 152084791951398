import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Box from '@/componentsv2/Box';
import Radio from '@/componentsv2/RadioButton/Radio';

import styles, { formRadioButtonClasses } from './styles';
import { IFormRadioButtonProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-form-radio-button';

const FormRadioButton = ({ error, helperText, className, ...rest }: IFormRadioButtonProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.formRadioButton, formRadioButtonClasses.root);

  return (
    <Box className={classProps} data-testid={testId}>
      <Radio {...rest} />
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};

export { formRadioButtonClasses, testId as FormRadioButtonTestId };
export type { IFormRadioButtonProps };
export default FormRadioButton;
