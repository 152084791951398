import TextField from '@mui/material/TextField';
import React, { PureComponent } from 'react';

import s from './EducationFilter.module.scss';
import Filter from './Filter';
import FilterButton from './FilterButton';
import FilterChip from './FilterChip';
import FilterSelect from './FilterSelect';

interface EducationFilterProps {
  degrees: any;
  fieldsOfStudy: any;
  onDegreeChange: (e: any) => void;
  onFieldsOfStudyAdd: (v: any) => void;
  onFieldsOfStudyRemove: (v: any) => void;
}

class EducationFilter extends PureComponent<EducationFilterProps> {
  state = {
    fieldOfStudy: '',
  };

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.handleAdd();
    }
  };

  handleAdd = () => {
    if (this.state.fieldOfStudy) {
      this.props.onFieldsOfStudyAdd(this.state.fieldOfStudy);
      this.setState({ fieldOfStudy: '' });
    }
  };

  render() {
    const { degrees, fieldsOfStudy, onDegreeChange, onFieldsOfStudyRemove } = this.props;

    return (
      <Filter title="Education">
        <FilterSelect
          placeholder="Select degree types"
          multiple
          value={degrees}
          onChange={onDegreeChange}
          options={[
            { value: 'associate', label: "Associate's" },
            { value: 'bachelor', label: "Bachelor's" },
            { value: 'master', label: "Master's" },
            { value: 'doctor', label: 'PhD' },
          ]}
        />

        <div>
          <TextField
            fullWidth
            value={this.state.fieldOfStudy}
            placeholder="Field of study"
            InputProps={{ style: { fontSize: 14 } }}
            onChange={(e) => this.setState({ fieldOfStudy: e.target.value })}
            onKeyPress={this.handleKeyPress}
          />

          <div className={s.educationFosAction}>
            <FilterButton disabled={!this.state.fieldOfStudy} onClick={this.handleAdd}>
              Add
            </FilterButton>
          </div>

          <div>
            {fieldsOfStudy.map((value: any) => (
              <FilterChip key={value} onDelete={() => onFieldsOfStudyRemove(value)} label={value} />
            ))}
          </div>
        </div>
      </Filter>
    );
  }
}

export default EducationFilter;
