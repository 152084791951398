import Badge from '@mui/material/Badge';

import { red500, white } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import Picture from '../Picture';

function ExpertPicture(props: any) {
  const { user } = props;

  return user && user.available_marketplace === false ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <Picture
          size={22}
          style={{
            backgroundColor: red500,
            borderColor: white,
            borderWidth: 2,
          }}
        >
          <FAIcon color={white} icon="user-slash" size={10} />
        </Picture>
      }
    >
      <Picture {...props} />
    </Badge>
  ) : (
    <Picture {...props} />
  );
}

export default ExpertPicture;
