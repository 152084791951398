import ActionTypes from '@/actions/ActionTypes';
import { getCache, setCache } from '@/utils';
import { mergeAt, removeAt } from '@/utils/reducer';

import {
  CONSULTATION__ADD_REGISTRANT,
  CONSULTATION__BATCH_ADD,
  CONSULTATION__CLEAR,
  CONSULTATION__DELETE,
  CONSULTATION__DISMISS_REVIEW,
  CONSULTATION__LIST_LOADED,
  CONSULTATION__LIST_LOADING,
  CONSULTATION__RESET_COLLECTION,
  CONSULTATION__UPDATE,
  CollectionType,
} from '.';

interface ConsultationCollectionState {
  loading: boolean;
  edges: any[];
  pageInfo: any;
  resetAt?: Date;
}

type ConsultationsState = {
  [key in CollectionType]: ConsultationCollectionState;
};

const initialCollectionState: ConsultationCollectionState = {
  loading: false,
  edges: [],
  pageInfo: { hasNextPage: true },
  resetAt: undefined,
};

const initialState: ConsultationsState = {
  completed: initialCollectionState,
  unreviewed: initialCollectionState,
  starting: initialCollectionState,
  awaiting: initialCollectionState,
  canceled: initialCollectionState,
  confirmed: initialCollectionState,
  default: initialCollectionState,
  dashboardAwaiting: initialCollectionState,
  dashboardConfirmed: initialCollectionState,
};

function collectionReducer(
  state = initialCollectionState,
  action: any
): ConsultationCollectionState {
  switch (action.type) {
    case CONSULTATION__LIST_LOADING:
      return { ...state, loading: true };
    case CONSULTATION__LIST_LOADED:
      return { ...state, loading: false };
    case CONSULTATION__BATCH_ADD: {
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { loading: false, edges, pageInfo, resetAt: new Date() };
      }
      return { ...state, edges: [...state.edges, ...edges], pageInfo };
    }
    case CONSULTATION__DELETE:
      return {
        ...state,
        edges: removeAt(
          state.edges,
          state.edges.findIndex((c) => c.node.id === action.id)
        ),
      };
    case CONSULTATION__UPDATE: {
      const index = state.edges.findIndex((c) => c.node.id === action.consultation.id);
      if (index < 0) {
        return action.canAdd
          ? {
              ...state,
              edges: [{ node: action.consultation }, ...state.edges],
            }
          : state;
      }
      const updated = {
        node: {
          ...state.edges[index].node,
          ...action.consultation,
        },
      };
      return {
        ...state,
        edges: mergeAt(state.edges, index, updated),
      };
    }
    case CONSULTATION__ADD_REGISTRANT: {
      const { conference } = action.consultation;
      const registrants = [...(conference?.registrants || [])];
      const { registrant } = action;
      if (!registrants.find((r: any) => r.identifier === registrant.identifier)) {
        registrants.push(registrant);
      }
      const consultation = {
        ...action.consultation,
        conference: {
          ...conference,
          registrants,
        },
      };
      return collectionReducer(state, {
        type: CONSULTATION__UPDATE,
        consultation,
      });
    }
    default:
      return state;
  }
}

export default function consultationReducer(state = initialState, action: any): ConsultationsState {
  switch (action.type) {
    case CONSULTATION__LIST_LOADING:
    case CONSULTATION__LIST_LOADED:
    case CONSULTATION__BATCH_ADD:
      return (() => {
        const collection = action.collection || 'default';
        return {
          ...state,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [collection]: collectionReducer(state[collection], action),
        };
      })();
    case CONSULTATION__UPDATE:
    case CONSULTATION__ADD_REGISTRANT:
      return (() => {
        const newState = { ...state };
        Object.keys(state).forEach((col) => {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newState[col] = collectionReducer(state[col], {
            ...action,
            canAdd: col === action.collection,
          });
        });
        return newState;
      })();
    case CONSULTATION__DELETE:
      return (() => {
        const newState = { ...state };
        if (action.collection) {
          const col = action.collection;
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newState[col] = collectionReducer(state[col], action);
        } else {
          Object.keys(state).forEach((col) => {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            newState[col] = collectionReducer(state[col], action);
          });
        }
        return {
          ...newState,
        };
      })();
    case CONSULTATION__RESET_COLLECTION:
      return (() => {
        const collection = action.collection || 'default';
        return {
          ...state,
          [collection]: initialCollectionState,
        };
      })();
    case CONSULTATION__CLEAR:
    case ActionTypes.UI__SET_USER_CONTEXT:
      return initialState;
    default:
      return state;
  }
}

const reviewsInitialState = {
  dismissed: [],
  dismissedPermanently: [],
};

export function reviewsReducer(state = reviewsInitialState, action: any) {
  switch (action.type) {
    case ActionTypes.LOAD_FROM_LOCAL_STORAGE:
      return {
        ...state,
        dismissed: getCache('dismissedConsultationReviews') || [],
        dismissedPermanently: getCache('permanentlyDismissedConsultationReviews') || [],
      };
    case CONSULTATION__DISMISS_REVIEW: {
      const dismissed = [...state.dismissed, action.id];
      setCache('dismissedConsultationReviews', dismissed);

      let { dismissedPermanently } = state;
      if (action.permanent) {
        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        dismissedPermanently = [...state.dismissedPermanently, action.id];
        setCache('permanentlyDismissedConsultationReviews', dismissedPermanently);
      }

      return {
        ...state,
        dismissed,
        dismissedPermanently,
      };
    }
    default:
      return state;
  }
}
