import { Helmet } from 'react-helmet';
import { redirect, useLoaderData } from 'react-router-dom';

import { CandidateState, IsGoodMatch } from '@/__generated__/graphql';
import { awaitingProjectMembershipApproval } from '@/actions/project';
import { track } from '@/actions/tracking';
import InaccessibleArchived from '@/components/InaccessibleArchived/InaccessibleArchived';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess/RequestProjectAccess';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import {
  fetchExpertRequest,
  fetchExpertRequestCandidates,
  fetchPublicExpertRequest,
  requestAddExpertRequestCandidate,
  updateExpertRequestCandidate,
} from '@/expertrequest/store';
import NotFoundPage from '@/pages/NotFoundPage';
import {
  ActionContext,
  LegacyRoute,
  redirectIfAgreementsNotAccepted,
  redirectIfEmailNotVerified,
} from '@/routes/routesMiddleware';
import { AppStore } from '@/store';
import { parseEnum } from '@/utils/types';

import { Candidate, ExpertRequest } from '..';
import ExpertRequestAddRequest from '../components/ExpertRequestAddRequest';
import ExpertAuthPage from './ExpertAuthPage';
import ReferralAuthPage from './ReferralAuthPage';
import ExpertRequestPrivate, { SectionType } from './expertRequestPrivate/ExpertRequestPrivate';
import ExpertRequestPublic from './expertRequestPublic/ExpertRequestPublic';

//import promoImageURL from './promo.jpg';

const path = '/expert_request/:id';
const ALREADY_ADDED = 'GraphQL Error: expert request candidate already added';

function findExpertRequest(store: AppStore, id: string) {
  return store.dispatch(fetchPublicExpertRequest(id));
}

const RequestAddPage = (): JSX.Element => {
  // @ts-expect-error TS(2339) FIXME: Property 'viewer' does not exist on type 'unknown'... Remove this comment to see the full error message
  const { expertRequestId, viewer, signup, tags } = useLoaderData();

  if (!viewer.id) {
    return <ExpertAuthPage expertRequestId={expertRequestId} signup={signup} tags={tags} />;
  }

  return <ExpertRequestAddRequest expertRequestId={expertRequestId} />;
};

interface RequestProjectAccessPageProps {
  query: any;
  viewer: Viewer;
  id: string;
  accessRequested?: boolean;
}

const RequestProjectAccessPage = ({
  query,
  viewer,
  id,
  accessRequested,
}: RequestProjectAccessPageProps): JSX.Element => {
  return (
    <LayoutPage showNav selected="expert_requests">
      <Helmet>
        <title>Expert Request</title>
      </Helmet>
      <RequestProjectAccess
        viewer={viewer}
        path="expert_request"
        query={query}
        expertRequestId={id}
        accessRequested={accessRequested}
      />
    </LayoutPage>
  );
};

async function privateExpertRequestAction(id: string, { store, location, query }: ActionContext) {
  const selectedSection = query.get('section') || undefined;
  const isGoodMatch = parseEnum(IsGoodMatch, query.get('is_good_match'));
  const candidateId = query.get('candidate_id') || undefined;

  const shouldRedirect =
    redirectIfEmailNotVerified(store) || redirectIfAgreementsNotAccepted(store, location);

  if (shouldRedirect) return shouldRedirect;

  const { viewer } = store.getState();
  if (!viewer.id) return <RequestProjectAccessPage query={query} viewer={viewer} id={id} />;

  let expertRequest: ExpertRequest;
  try {
    expertRequest = await store.dispatch(fetchExpertRequest(id));
    await Promise.all(
      [
        fetchExpertRequestCandidates(id, 'suggested', true),
        fetchExpertRequestCandidates(id, 'matched', true),
      ].map(store.dispatch)
    );
  } catch (e: any) {
    if (hasErrorCode(e, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) return <NotFoundPage />;
    if (!e.isPermissionError) throw e;
    const accessRequested = await store.dispatch(
      awaitingProjectMembershipApproval({ expertRequestId: id })
    );
    return (
      <RequestProjectAccessPage
        query={query}
        viewer={viewer}
        id={id}
        accessRequested={accessRequested}
      />
    );
  }

  if (expertRequest.archived && !expertRequest.permissions.includes('view_archived')) {
    store.dispatch(track('promo.show.request.archived'));
    document.title = expertRequest.name;
    return (
      <InaccessibleArchived
        selectedTab="expert_requests"
        entity="request"
        entities="requests"
        trackingCode="promo.chat.request.archived"
      />
    );
  }

  if (candidateId && isGoodMatch) {
    try {
      const candidateData: Candidate = {
        id: candidateId,
        client_note: { is_good_match: isGoodMatch },
      };
      if (isGoodMatch === IsGoodMatch.Yes) {
        candidateData.state = CandidateState.Matched;
      }
      await store.dispatch(updateExpertRequestCandidate(id, candidateData));
    } catch (err) {
      console.warn(err);
    }
  }

  const targetSection = selectedSection || 'experts';

  const fetchMoreCandidates = async (
    type: 'matched' | 'suggested',
    pageInfo: Record<string, any>
  ) => {
    if (pageInfo?.hasNextPage) {
      await store.dispatch(
        fetchExpertRequestCandidates(id, type, false, pageInfo.cursor as string)
      );
    }
  };

  document.title = expertRequest.name;
  return (
    <ExpertRequestPrivate
      section={targetSection as SectionType}
      expertRequestId={expertRequest.id}
      expertRequest={expertRequest}
      candidateId={candidateId}
      isGoodMatch={isGoodMatch === IsGoodMatch.Yes}
      fetchMoreCandidates={fetchMoreCandidates}
    />
  );
}

async function publicExpertRequestAction(id: string, { store, query }: ActionContext) {
  const action = query.get('action') || undefined;
  const tags = query.getAll('t');

  let expertRequest;
  try {
    expertRequest = await findExpertRequest(store, id);
  } catch (e: unknown) {
    if (hasErrorCode(e, ERROR_CODES.EXPERT_REQUEST_NOT_FOUND)) return <NotFoundPage />;
  }

  document.title = 'Expert Request';
  return (
    <ExpertRequestPublic
      expertRequestId={id}
      expertRequest={expertRequest}
      action={action}
      tags={tags}
    />
  );
}

const route: LegacyRoute = {
  path,
  children: [
    {
      path,
      async action(context: ActionContext) {
        const { id = '' } = context.params;

        if (/^\d+$/.test(id)) {
          return await privateExpertRequestAction(id, context);
        }

        return await publicExpertRequestAction(id, context);
      },
    },
    {
      path: `${path}/request_add/:auth?/:signupType?`,
      element: <RequestAddPage />,
      async action({ store, params, query }: ActionContext) {
        const { auth } = params;
        const id = params.id!;
        const tags = query.getAll('t');
        document.title = 'Expert Request';

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${id}/request_add/signup`);
        }

        if (viewer.id && auth) {
          return redirect(`/expert_request/${id}/request_add`);
        }

        if (!viewer.id && auth) {
          return {
            viewer,
            expertRequestId: id,
            signup: auth === 'signup',
            tags,
          };
        }

        try {
          await store.dispatch(
            requestAddExpertRequestCandidate({
              expert_request_id: id,
            })
          );
        } catch (err) {
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          if (err.message === ALREADY_ADDED) {
            return { viewer, expertRequestId: id };
          }
          throw err;
        }

        return { viewer, expertRequestId: id };
      },
    },
    {
      path: `${path}/refer/:auth?/:signupType?`,
      element: <ReferralAuthPage />,
      async action({ store, params, query }: ActionContext) {
        const id = params.id!;
        const { auth } = params;
        const tags = query.getAll('t');
        document.title = 'Expert Request';

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return <NotFoundPage />;

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${expertRequest.slug}/refer/signup`);
        }

        if (viewer.id) {
          return redirect(`/expert_request/${expertRequest.slug}`);
        }

        return {
          expertRequest,
          signup: auth === 'signup',
          tags,
        };
      },
    },
  ],
};

export default route;
