// @ts-nocheck
import Chevron from '@mui/icons-material/ArrowForwardIos';
import { Skeleton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import { Checkbox, Input } from '@/componentsv2';
import Collapse from '@/componentsv2/Collapse';
import { FilterValue, SearchFilterResponse } from '@/openapi';
import { FilterName, useFiltersQuery } from '@/searchv2/queries/filters';
import { arrayFromRange } from '@/utils/array';
import { knowledgeCapitalized } from '@/utils/capitalization';

type FilterKey =
  | 'title'
  | 'knowledge_expertise'
  | 'knowledge_skills'
  | 'location'
  | 'current_location'
  | 'contract'
  | 'client'
  | 'education_type'
  | 'education_major'
  | 'language';

export const filterTests = {
  title: (filterId: string) => filterId.startsWith('title'),
  expertise: (filterId: string) => filterId.startsWith('expertise:'),
  skill: (filterId: string) => filterId.startsWith('skill:'),
  location: (filterId: string) =>
    filterId.startsWith('region:') ||
    filterId.startsWith('sub_region:') ||
    filterId.startsWith('inter_region:') ||
    filterTests.country(filterId),
  country: (filterId: string) => filterId.startsWith('country:'),
  currentLocation: (filterId: string) =>
    filterId.startsWith('current_region:') ||
    filterId.startsWith('current_sub_region:') ||
    filterId.startsWith('current_inter_region:') ||
    filterTests.currentCountry(filterId),
  currentCountry: (filterId: string) => filterId.startsWith('current_country:'),
  contract: (filterId: string) => filterId.startsWith('contract:'),
  client: (filterId: string) => filterId.startsWith('org:'),
  education: (filterId: string) => filterId.startsWith('degree_type:'),
  major: (filterId: string) => filterId.startsWith('major:'),
  language: (filterId: string) => filterId.startsWith('language:'),
};

export const getFilterType = (filterId: string): FilterKey | null => {
  if (filterTests.title(filterId)) {
    return 'title';
  }

  if (filterTests.expertise(filterId)) {
    return 'knowledge_expertise';
  }

  if (filterTests.skill(filterId)) {
    return 'knowledge_skills';
  }

  if (filterTests.location(filterId)) {
    return 'location';
  }

  if (filterTests.currentLocation(filterId)) {
    return 'current_location';
  }

  if (filterTests.contract(filterId)) {
    return 'contract';
  }

  if (filterTests.client(filterId)) {
    return 'client';
  }

  if (filterTests.education(filterId)) {
    return 'education_type';
  }

  if (filterTests.major(filterId)) {
    return 'education_major';
  }

  if (filterTests.language(filterId)) {
    return 'language';
  }

  return null;
};

const getAppliedFilters = (filterIds: string[], name: FilterKey) => {
  switch (name) {
    case 'title': {
      return filterIds.filter((id) => filterTests.title(id));
    }
    case 'knowledge_expertise': {
      return filterIds.filter((id) => filterTests.expertise(id));
    }
    case 'knowledge_skills': {
      return filterIds.filter((id) => filterTests.skill(id));
    }
    case 'location': {
      return filterIds.filter((id) => filterTests.location(id));
    }
    case 'current_location': {
      return filterIds.filter((id) => filterTests.currentLocation(id));
    }
    case 'contract': {
      return filterIds.filter((id) => filterTests.contract(id));
    }
    case 'client': {
      return filterIds.filter((id) => filterTests.client(id));
    }
    case 'education_type': {
      return filterIds.filter((id) => filterTests.education(id));
    }
    case 'education_major': {
      return filterIds.filter((id) => filterTests.major(id));
    }
    case 'language': {
      return filterIds.filter((id) => filterTests.language(id));
    }
    default: {
      return [];
    }
  }
};

type CheckboxFilter = {
  name?: FilterName;
  title: string;
  filterKey: FilterKey;
  skeletonCount?: number;
};

export const CHECKBOX_FILTERS: CheckboxFilter[] = [
  {
    name: 'title_completion',
    title: 'Title',
    filterKey: 'title',
  },
  {
    name: 'knowledge_expertise_completion',
    title: 'Topics',
    filterKey: 'knowledge_expertise',
  },
  {
    name: 'knowledge_skills_completion',
    title: 'Skills',
    filterKey: 'knowledge_skills',
  },
  {
    title: 'Degree',
    filterKey: 'education_type',
    skeletonCount: 3,
  },
  {
    name: 'education_major_completion',
    title: 'Degree Major',
    filterKey: 'education_major',
  },
  {
    name: 'language_completion',
    title: 'Language',
    filterKey: 'language',
  },
  {
    name: 'location_completion',
    title: 'Location Skills',
    filterKey: 'location',
  },
  {
    name: 'current_location_completion',
    title: 'Current Location',
    filterKey: 'current_location',
  },
  {
    name: 'contract_completion',
    title: 'Contract Type',
    filterKey: 'contract',
  },
  {
    name: 'client_completion',
    title: 'Organization Experience',
    filterKey: 'client',
  },
];

const CheckboxFilter = ({
  title,
  name,
  filterKey,
  searchInputQuery,
  options = [],
  allFilterIds,
  applyFilter,
  removeFilter,
  isLoading,
  isFetching,
  skeletonCount = 10,
}: {
  title: string;
  name?: FilterName;
  filterKey: FilterKey;
  searchInputQuery: string;
  allFilterIds: string[];
  applyFilter: (id: string) => void;
  removeFilter: (id: string) => void;
  options?: FilterValue[];
  isLoading?: boolean;
  isFetching?: boolean;
  skeletonCount?: number;
}) => {
  const queryClient = useQueryClient();
  const selectedOptions = getAppliedFilters(allFilterIds, filterKey);
  const selectedOptionIds = selectedOptions.map((id) => id);
  const [expanded, setExpanded] = useState(selectedOptions.length > 0);
  const [inputValue, setInputValue] = useState('');
  const debouncedQuery = useDebounce(inputValue, 100);
  const [value, setValue] = useState<SearchFilterResponse | null>(null);

  const sortedOptions = [...options].sort((a, b) => {
    const aIsSelected = selectedOptionIds.includes(a.filter_id as string);
    const bIsSelected = selectedOptionIds.includes(b.filter_id as string);
    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
    return 0;
  });

  const id = `filter-${name}`;

  const { data: autocompleteData, isFetching: autocompleteIsFetching } = useFiltersQuery({
    filterName: name,
    query: debouncedQuery,
    searchInputQuery,
    appliedFilters: allFilterIds,
  });

  const debouncedIsFetching = useDebounce(autocompleteIsFetching, 2000);

  return (
    <div className="border-y border-grey-400">
      <button
        className="flex w-full items-center px-16 py-8 subtitle-1"
        onClick={() => setExpanded(!expanded)}
        aria-controls={id}
        aria-expanded={expanded ? 'true' : 'false'}
      >
        <span className="mr-auto">{title}</span>
        {selectedOptionIds.length > 0 ? (
          <span className="mr-12 rounded-[900px] bg-primary-main px-[9px] text-[11px] font-semibold leading-normal text-white">
            {selectedOptionIds.length}
          </span>
        ) : null}
        <Chevron
          className={clsx({
            '-rotate-90': expanded,
            'rotate-90': !expanded,
          })}
          sx={{ fontSize: 12, transition: 'transform 0.1s' }}
        />
      </button>
      <Collapse in={expanded} className="border-t border-grey-400 bg-grey-200" id={id}>
        {name ? (
          <div className="px-16 py-8">
            <Autocomplete
              options={autocompleteData?.results ?? []}
              getOptionLabel={(option: SearchFilterResponse) => option.name ?? ''}
              isOptionEqualToValue={(option: SearchFilterResponse, value: SearchFilterResponse) =>
                option.id === value.id
              }
              size="small"
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              value={value}
              clearOnBlur
              onChange={(_, newValue) => {
                setValue(null);
                setInputValue('');
                if (newValue) {
                  applyFilter(newValue.id as string);
                  queryClient.invalidateQueries(['filters']);
                  queryClient.removeQueries(['filters']);
                }
              }}
              renderInput={(params) => (
                <Input
                  name={`filter-search-${name}`}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: 'Search...',
                    className: 'bg-white',
                    'aria-label': `Search ${title}`,
                    endAdornment:
                      inputValue && debouncedIsFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        params.InputProps?.endAdornment
                      ),
                  }}
                />
              )}
              filterOptions={(x) => x}
              renderOption={(props, option: SearchFilterResponse) => (
                <li key={option.id} {...props}>
                  {knowledgeCapitalized(option.name)}
                </li>
              )}
            />
          </div>
        ) : null}
        <fieldset
          className="max-h-[200px] overflow-auto px-16 py-8 disabled:opacity-50"
          disabled={isFetching}
        >
          {isLoading ? (
            <ul>
              {arrayFromRange({ max: skeletonCount }).map((i) => (
                <li key={i} style={{ opacity: 1 - i * 0.1 }}>
                  <Checkbox disabled />
                  <Skeleton
                    variant="text"
                    width={(1 - (i % 4) * 0.05) * 150}
                    sx={{ display: 'inline-block' }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            sortedOptions.map((option) => (
              <Checkbox
                key={option.filter_id}
                label={`${knowledgeCapitalized(option.name) || option.filter_id} (${option.count})`}
                checked={selectedOptionIds.includes(option.filter_id as string)}
                onChange={(e) => {
                  if (e.target.checked) {
                    applyFilter(option.filter_id as string);
                  } else {
                    removeFilter(option.filter_id as string);
                  }
                }}
                formControlLabelClassName="subtitle-1 !flex [:disabled>&]:!cursor-wait"
                FormControlLabelProps={{
                  disableTypography: true,
                }}
              />
            ))
          )}
        </fieldset>
      </Collapse>
    </div>
  );
};

export default CheckboxFilter;
