import { CircularProgress } from '@mui/material';
import { MetaData } from '@sendbird/chat';
import {
  GroupChannel,
  MessageCollectionInitPolicy,
  MessageEventContext,
  MessageFilter,
} from '@sendbird/chat/groupChannel';
import { BaseMessage, Sender } from '@sendbird/chat/message';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/auth';
import EmptyMessage from '@/components/EmptyMessage/EmptyMessage';
import { darkBlue } from '@/theme/colors';

import { isAnonymousUser } from '../sendbird';
import ChatMessage from './ChatMessage';
import s from './MessageList.module.scss';

interface MessageListProps {
  channel: GroupChannel;
  metadata: MetaData;
}

interface MessageListState {
  loading: boolean;
  messages: BaseMessage[];
}

const MessageList: FC<MessageListProps> = ({ channel, metadata }) => {
  const { viewer } = useAuth();

  const [state, setState] = useState<MessageListState>({
    loading: true,
    messages: [],
  });
  const { loading, messages } = state;

  const handleMessagesAdded = useCallback(
    (_context: MessageEventContext, cbChannel: GroupChannel, addedMessages: BaseMessage[]) => {
      if (cbChannel.url !== channel.url) return;
      console.log('messages added', addedMessages);
      setState(({ loading, messages }) => ({
        loading: loading,
        messages: [...messages, ...addedMessages],
      }));
    },
    [channel.url]
  );

  useEffect(() => {
    const filter = new MessageFilter();
    const col = channel.createMessageCollection({
      filter,
      prevResultLimit: 100,
      nextResultLimit: 100,
    });
    col.initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API);
    col.setMessageCollectionHandler({
      onMessagesAdded: handleMessagesAdded,
    });
    (async function loadMessages() {
      let messages: BaseMessage[] = [];
      if (col.hasPrevious) {
        // Load the previous messages when the scroll
        // reaches the first message in the chat view.
        messages = await col.loadPrevious();
      }

      messages.sort((a, b) => a.createdAt - b.createdAt);

      // if (collection.hasNext) {
      //   // Load the next messages when the scroll
      //   // reaches the last message in the chat view.
      //   await collection.loadNext();
      // }

      await channel.markAsRead();

      setState({ loading: false, messages });
    })();

    return () => {
      col.dispose();
    };
  }, [channel, handleMessagesAdded]);

  if (!loading && messages.length === 0) {
    return (
      <EmptyMessage
        style={{
          padding: '60px 10px 0',
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          alignItems: 'center',
        }}
        bodyStyle={{ margin: 0, fontSize: 18 }}
        border={false}
        iconName="chat"
        iconColor={darkBlue}
        title="Start a conversation."
        body="Use messages to clarify scope, questions, and expertise before your call begins."
      />
    );
  }

  const readStatus = channel.getReadStatus(true) || {};

  return (
    <div className={s.messagesContainer}>
      <div className={s.messages}>
        {loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}
        {/* {channelUrl && messaging.channels.length > 0 && (
          <Waypoint key={channelUrl} onEnter={handlePreviousMessages} />
        )} */}

        <Suspense>
          {messages.map((m) => {
            const sender = (m as any).sender as Sender | undefined;
            if (!sender) {
              throw new Error('Message has no sender');
            }

            return (
              <ChatMessage
                key={m.messageId}
                message={m}
                isRead={!!readStatus[sender.userId]}
                isAnonymous={isAnonymousUser(metadata, sender.userId)}
                isOwnMessage={viewer.id === sender.userId}
              />
            );
          })}
        </Suspense>
        {/* {channel &&
          channel.getTypingMembers().map((m: any) => {
            const user = !isAnonymousUser(channel, m.userId) && users[m.userId];

            return (
              <Message
                id={`typing${user.id}`}
                key={`typing${user.id}`}
                bubbleClassName={s.typingBubble}
                pictureSize={40}
                className={cx(s.message, s.messageLeft)}
                borderColor={lightTan}
                backgroundColor={lightTan}
                position="left"
                align="top"
                user={user}
              >
                <TypingIndicator />
              </Message>
            );
          })} */}
      </div>
    </div>
  );
};
MessageList.displayName = 'MessageList';

export default MessageList;
