import { gengql } from '@/__generated__';
import { AUTH__UPDATE, BASIC_AUTH__UPDATE } from '@/auth/store/reducer';
import { basicAuth } from '@/core/api';
import { AppThunk } from '@/store';
import { updateUserInfo } from '@/store/user';
import { DocumentNodeResult } from '@/utils/gql';

export function setBasicAuth(userId: string, password: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: BASIC_AUTH__UPDATE,
      basicAuthentication: basicAuth(userId, password),
    });
  };
}

const CREATE_OTP_AUTH_CONFIG = gengql(/* GraphQL */ `
  mutation authStoreCreateOtpAuthConfiguration($method: String!, $address: String) {
    createOtpAuthConfiguration(method: $method, address: $address)
  }
`);

export function createOtpAuthConfiguration(
  method: string,
  address: string
): AppThunk<Promise<string>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-expect-error Property 'basicAuthentication' does not exist on type '{}'
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication).client
      : graphql.client;
    const { data } = await client.mutate({
      mutation: CREATE_OTP_AUTH_CONFIG,
      variables: { method, address },
    });
    const { createOtpAuthConfiguration: otpAuthUri } = data!;
    dispatch({ type: AUTH__UPDATE, otpAuthUri });
    return otpAuthUri;
  };
}

export function removeOtpAuth(userId: string, password: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { removeOtpAuth } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveOtpAuth($userId: String, $password: String!) {
          removeOtpAuth(user_id: $userId, password: $password)
        }
      `),
      { userId, password }
    );
    return removeOtpAuth;
  };
}

export function disableOtpAuth(userId: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { disableOtpAuth } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDisableOtpAuth($userId: String) {
          disableOtpAuth(user_id: $userId)
        }
      `),
      { userId }
    );
    return disableOtpAuth;
  };
}

export function authenticateOtp(otp: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, getState, { graphql }) => {
    // @ts-expect-error Property 'basicAuthentication' does not exist on type '{}'
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication ? graphql.fromAuth(basicAuthentication) : graphql;
    const { authenticateOtp } = await client.mutate(
      gengql(/* GraphQL */ `
        mutation actionAuthenticateOtp($otp: String!) {
          authenticateOtp(otp: $otp)
        }
      `),
      { otp }
    );
    return authenticateOtp;
  };
}

export function otpQrCodeBase64(): AppThunk<Promise<string>> {
  return async (_dispatch, getState, { graphql }) => {
    // @ts-expect-error Property 'basicAuthentication' does not exist on type '{}'
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication ? graphql.fromAuth(basicAuthentication) : graphql;
    const { otpQrCodeBase64: qrCodeBase64 } = await client.query(
      gengql(/* GraphQL */ `
        query actionOtpQrCodeBase64 {
          otpQrCodeBase64
        }
      `)
    );
    return qrCodeBase64!;
  };
}

const SET_PASSWORD = gengql(/* GraphQL */ `
  mutation actionSetPassword($userId: String, $currentPass: String, $newPass: String!) {
    setPassword(user_id: $userId, current_password: $currentPass, new_password: $newPass) {
      id
      has_password
      password_expiry {
        expiry
        expired
        expiring
      }
    }
  }
`);

export function setPassword(
  userId: string,
  currentPass: string,
  newPass: string
): AppThunk<Promise<NonNullable<DocumentNodeResult<typeof SET_PASSWORD>['setPassword']>>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: SET_PASSWORD,
      variables: {
        userId,
        currentPass,
        newPass,
      },
    });

    const user = data!.setPassword;
    if (user) {
      dispatch(updateUserInfo(user));
    }
    return user;
  };
}

export function resetPassword(token: string, newPass: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionResetPassword($token: String!, $newPass: String!) {
          resetPassword(token: $token, new_password: $newPass)
        }
      `),
      { token, newPass }
    );
    return data.resetPassword;
  };
}

export function unlockAccount(userId: string): AppThunk<Promise<boolean>> {
  return async (dispatch, _getState, { graphql }) => {
    const { unlockAccount } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUnlockAccount($user_id: String!) {
          unlockAccount(user_id: $user_id)
        }
      `),
      { user_id: userId }
    );

    dispatch(
      updateUserInfo({
        id: userId,
        locked: !unlockAccount,
      })
    );

    return unlockAccount;
  };
}

export function requestPasswordReset(email: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRequestPasswordReset($email: String!) {
          requestPasswordReset(email: $email)
        }
      `),
      { email }
    );
    return data.requestPasswordReset;
  };
}
