import { Viewer } from './viewer';

export function gtm(...args: (string | object)[]) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
}

export function initSegmentIdentifier(viewer: Viewer) {
  const { analytics } = window;
  if (!analytics || !analytics.identify) return;

  const analyticsTraits = {
    name: viewer.full_name,
    email: viewer.email?.address,
    userId: viewer.id,
  };

  // Helpful for GTM triggers/variables
  window._analyticsTraits = analyticsTraits;

  if (viewer.id) {
    analytics.identify(viewer.id, analyticsTraits);
  }
}

export function segmentTracking(eventName: string, properties?: Record<string, unknown>) {
  const { analytics } = window;

  if (!analytics || !analytics.identify) {
    return;
  }

  analytics.track(eventName, properties);
}
