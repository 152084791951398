import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PaginatedProfileEmailList, ProfileEmailService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const fetchData = createAsyncThunk(
  'profilesByEmail/fetchData',
  async ({ email, limit = 50, offset = 0 }: { email: string; limit?: number; offset?: number }) =>
    await ProfileEmailService.profileEmailList(limit, offset, email)
);

const profilesByEmailSlice = createSlice({
  name: 'profilesByEmail',
  initialState: initialState.profilesByEmail,
  reducers: {
    updateData: (state, action: PayloadAction<PaginatedProfileEmailList>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.profilesByEmail,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    });
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const profilesByEmailSelector = (state: IState) => state.profilesByEmail;
export const { updateData, resetData } = profilesByEmailSlice.actions;
export default profilesByEmailSlice.reducer;
