import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { cloneElement, useState } from 'react';
import { X } from 'react-feather';

import Box from '@/componentsv2/Box';
import Button from '@/componentsv2/Button';
import AreYouSureDialog from '@/componentsv2/ConfirmDialog/AreYouSureDialog';
import { useAppDispatch } from '@/store';
import { toggleDialogConfirmClose } from '@/ui/store/uiSlice';

import styles, { DialogClasses } from './styles';

const useStyles = makeStyles(styles);

export interface IDialogProps extends DialogProps {
  className?: string;
  onClose: () => void;
  open: boolean;
  children: React.ReactElement;
  dialogTitle?: string | React.ReactNode | JSX.Element;
  showCloseButton?: boolean;
  disablePortal?: boolean;
  confirmClose?: boolean;
  'data-test-id'?: string;
}
const Dialog: React.FC<IDialogProps> = ({
  className,
  onClose,
  children,
  open,
  dialogTitle,
  showCloseButton,
  disablePortal = true,
  confirmClose,
  'data-test-id': dataTestId,
}) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.Dialog, DialogClasses.root);
  const testId = dataTestId || 'of-dialog';
  const dispatch = useAppDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleClose = () => {
    if (confirmClose) {
      setConfirmDialogOpen(true);
      return;
    }

    onClose();
  };

  return (
    <MUIDialog
      className={classProps}
      data-testid={testId}
      open={open}
      disablePortal={disablePortal}
      onClose={handleClose}
    >
      <Box className={DialogClasses.dialogHeader}>
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        {showCloseButton && (
          <Button
            variant="tertiary"
            size="large"
            startIcon={X}
            srText="Close"
            onClick={handleClose}
          />
        )}
      </Box>
      <DialogContent>
        {cloneElement(children, {
          onClose: (force: boolean) => (force ? onClose() : handleClose()),
        })}
      </DialogContent>
      <AreYouSureDialog
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          onClose();
          setConfirmDialogOpen(false);
          dispatch(toggleDialogConfirmClose(false));
        }}
        open={confirmDialogOpen}
      />
    </MUIDialog>
  );
};

export default Dialog;
