import Chevron from '@mui/icons-material/ArrowForwardIos';
import { Box, Collapse, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

export enum RangeFilterKey {
  YEAR_OF_EXPERIENCE_MIN = 'years_of_experience_min',
  YEAR_OF_EXPERIENCE_MAX = 'years_of_experience_max',
}

interface RangeFilter {
  minFilterKey?: RangeFilterKey;
  maxFilterKey?: RangeFilterKey;
  title: string;
}

export const RANGE_FILTERS: RangeFilter[] = [
  {
    minFilterKey: RangeFilterKey.YEAR_OF_EXPERIENCE_MIN,
    maxFilterKey: RangeFilterKey.YEAR_OF_EXPERIENCE_MAX,
    title: 'Years of Experience',
  },
];

interface RangeFilterProps {
  title: string;
  minFilterKey?: RangeFilterKey;
  maxFilterKey?: RangeFilterKey;
  applyFilter: (id: string) => void;
  removeFilter: (id: string) => void;
  allFilterIds: string[];
  isFetching?: boolean;
}

const RangeFilter: React.FC<RangeFilterProps> = ({
  title,
  minFilterKey,
  maxFilterKey,
  applyFilter,
  removeFilter,
  allFilterIds,
  isFetching,
}) => {
  // a filterId in allFilterIds has this format: FILTER_KEY:VALUE
  const parseInitialValue = (key: string) =>
    allFilterIds.find((filterId) => filterId.startsWith(key as string))?.split(':')[1] ?? '';

  const [minValue, setMinValue] = useState(parseInitialValue(minFilterKey as string));
  const [maxValue, setMaxValue] = useState(parseInitialValue(maxFilterKey as string));
  const debouncedMinValue = useDebounce(minValue, 1000);
  const debouncedMaxValue = useDebounce(maxValue, 1000);
  const prevMinValue = useRef(minValue);
  const prevMaxValue = useRef(maxValue);

  const [isActive, setActive] = useState<boolean>(!!(minValue || maxValue));
  const [expanded, setExpanded] = useState<boolean>(isActive);

  useEffect(() => {
    if (debouncedMinValue !== prevMinValue.current) {
      if (debouncedMinValue) {
        removeFilter(`${minFilterKey}:${prevMinValue.current}`);

        const min = parseInt(debouncedMinValue);
        if (!isNaN(min) && min > 0) {
          applyFilter(`${minFilterKey}:${min}`);
        }
        setActive(!!(min || parseInt(maxValue)));
      }
      prevMinValue.current = debouncedMinValue;
    }
  }, [debouncedMinValue, applyFilter, removeFilter, minFilterKey, setActive, maxValue]);

  useEffect(() => {
    if (debouncedMaxValue !== prevMaxValue.current) {
      if (debouncedMaxValue) {
        removeFilter(`${maxFilterKey}:${prevMaxValue.current}`);

        const max = parseInt(debouncedMaxValue);
        if (!isNaN(max) && max > 0) {
          applyFilter(`${maxFilterKey}:${max}`);
        }
        setActive(!!(parseInt(minValue) || max));
        prevMaxValue.current = debouncedMaxValue;
      }
    }
  }, [debouncedMaxValue, applyFilter, removeFilter, maxFilterKey, setActive, minValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === minFilterKey) {
      setMinValue(value);
    } else {
      setMaxValue(value);
    }
  };

  const id = `filter-${minFilterKey}-${maxFilterKey}`;

  return (
    <div className="border-y border-grey-400">
      <button
        className="flex w-full items-center px-16 py-8 subtitle-1"
        onClick={() => setExpanded(!expanded)}
        aria-controls={id}
        aria-label="Expand"
        aria-expanded={expanded ? 'true' : 'false'}
      >
        <span className="mr-auto">{title}</span>
        {isActive ? (
          <span className="mr-12 rounded-[900px] bg-primary-main px-[9px] text-[11px] font-semibold leading-normal text-white">
            1
          </span>
        ) : null}
        <Chevron
          className={clsx({
            '-rotate-90': expanded,
            'rotate-90': !expanded,
          })}
          sx={{ fontSize: 12, transition: 'transform 0.1s' }}
        />
      </button>
      <Collapse
        in={expanded}
        className="border-t border-grey-400 bg-grey-200"
        id={`filter-${minFilterKey}-${maxFilterKey}`}
      >
        <fieldset
          className="max-h-[200px] overflow-auto px-16 py-8 disabled:opacity-50"
          disabled={isFetching}
        >
          <Box
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <TextField
              label="Min"
              type="number"
              name={minFilterKey}
              value={minValue || ''}
              onChange={handleChange}
              size="small"
              sx={{ marginRight: 1 }}
            />
            <TextField
              label="Max"
              type="number"
              name={maxFilterKey}
              value={maxValue || ''}
              onChange={handleChange}
              size="small"
              sx={{ marginRight: 1 }}
            />
          </Box>
        </fieldset>
      </Collapse>
    </div>
  );
};

export default RangeFilter;
