import pluralize from 'pluralize';
import { Link } from 'react-router-dom';

import ConditionalWrapper from '@/componentsv2/ConditionalWrapper';
import { segmentTracking } from '@/core/analytics';
import { KnowledgeSearchResult } from '@/openapi';
import { PROFILE_ROUTES, SEARCH_ROUTES } from '@/routes/APP_ROUTES';
import { knowledgeCapitalized } from '@/utils/capitalization';
import { calculateExperienceTimeDisplay } from '@/utils/date';
import { KnowledgeLevel } from '@/utils/knowledgeScoreToPercent';

import { filterTests, getFilterType } from '../Filters/CheckboxFilter';
import MatchTag, { MatchTagType } from './MatchTag';

const getMatchUrl = (
  currentRoute: 'search' | 'profile',
  profileId: string,
  filterId: string,
  name: string,
  canonicalName?: string
) => {
  if (!filterId) return null;

  const filterType = getFilterType(filterId);
  const routes = currentRoute === 'search' ? SEARCH_ROUTES(profileId) : PROFILE_ROUTES(profileId);

  switch (filterType) {
    case 'knowledge_expertise':
    case 'knowledge_skills': {
      const knowledgeName = canonicalName ?? name;
      const redirectedFrom =
        canonicalName && canonicalName.toLowerCase() !== name.toLowerCase() ? name : undefined;
      return routes.knowledge(knowledgeName, redirectedFrom);
    }

    case 'location':
    case 'current_location': {
      // we only have a drawer for countries
      if (filterTests.country(filterId) || filterTests.currentCountry(filterId)) {
        return routes.location(name);
      }
    }
  }

  return null;
};

const generateQualifier = (match: KnowledgeSearchResult) => {
  const showLanguageFluency = filterTests.language(match.filter_id);

  if (showLanguageFluency) {
    return match.expert_level;
  }

  const showYearsOfExperience = filterTests.title(match.filter_id);

  if (showYearsOfExperience) {
    const timeDisplay = calculateExperienceTimeDisplay(match.accrued_time.days ?? 0);
    if (!timeDisplay) {
      return undefined; // i.e. less than a week
    }
    return `${timeDisplay.value} ${pluralize(timeDisplay.period, timeDisplay.value)}`;
  }

  return undefined;
};

export type MatchedTagsTrackingPayload = {
  subject_profile_id: string;
  search_hash: string;
  search_position: number;
  search_results: (string | null)[];
  search_results_page: number;
  search_relevance?: KnowledgeSearchResult[];
  triggered_by?: 'search results' | 'profile';
};

const MatchedTags = ({
  profileId,
  matchedFilters,
  currentRoute,
  trackingPayload,
}: {
  profileId: string;
  matchedFilters: KnowledgeSearchResult[];
  currentRoute: 'search' | 'profile';
  trackingPayload: MatchedTagsTrackingPayload;
}) => {
  const sortedMatches = [...matchedFilters].sort((a, b) =>
    a.search_score < b.search_score ? 1 : a.search_score > b.search_score ? -1 : 0
  );

  return (
    <ul className="flex gap-8 p-8">
      {sortedMatches.map((match) => {
        const drawerLink = getMatchUrl(
          currentRoute,
          profileId,
          match.filter_id,
          match.name,
          match.canonical_value
        );

        const showExpertiseLevel =
          filterTests.skill(match.filter_id) ||
          filterTests.expertise(match.filter_id) ||
          filterTests.country(match.filter_id);

        const expertiseLevel = showExpertiseLevel ? match.expert_level : null;
        const matchQualifier = generateQualifier(match);

        return (
          <li key={match.filter_id}>
            <ConditionalWrapper
              condition={!!drawerLink}
              wrapper={(children) => (
                <Link
                  to={drawerLink ?? ''}
                  onClick={() => {
                    segmentTracking('user-clicked-why-chip', {
                      entity_type: getFilterType(match.filter_id),
                      entity_name: match.name,
                      raw_term_id: match.filter_id,
                      canonical_term_id: match.canonical_id,
                      ...trackingPayload,
                    });
                  }}
                >
                  {children}
                </Link>
              )}
            >
              <MatchTag
                type={match.match_type as MatchTagType}
                clickable={!!drawerLink}
                level={expertiseLevel as KnowledgeLevel}
                parenthesis={matchQualifier}
              >
                {knowledgeCapitalized(match.name)}
              </MatchTag>
            </ConditionalWrapper>
          </li>
        );
      })}
    </ul>
  );
};

export default MatchedTags;
