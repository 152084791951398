import { useCallback, useState } from 'react';

import ChangePasswordForm from '@/auth/components/ChangePasswordForm';
import Button from '@/components/Button/Button';
import WizardPage from '@/components/WizardPage';

export interface SetPasswordProps {
  token: string;
  isInvalid?: boolean;
}

const SetPassword = ({ token, isInvalid }: SetPasswordProps): JSX.Element => {
  const [done, setDone] = useState(false);

  const handleSubmit = useCallback(() => {
    setDone(true);
  }, []);

  if (isInvalid) {
    return (
      <div>
        <h3>This link is invalid or expired. Please request a new one.</h3>
        <Button href="/password_reset" size="large">
          Request new password →
        </Button>
      </div>
    );
  }

  if (done) {
    return (
      <div>
        <h3>Hooray! Your password has been reset. You can now login.</h3>
        <Button href="/login" size="large">
          Go to Login →
        </Button>
      </div>
    );
  }

  return (
    <WizardPage title="Reset your password">
      <ChangePasswordForm onSubmit={handleSubmit} successNotification={false} token={token} />
    </WizardPage>
  );
};

export default SetPassword;
