import { FC } from 'react';

import s from './OnlineStatus.module.scss';

type OnlineStatusProps = React.HTMLAttributes<HTMLDivElement>;

const OnlineStatus: FC<OnlineStatusProps> = (props) => {
  return <div className={s.onlineStatus} {...props} />;
};

export default OnlineStatus;
