import React from 'react';

import CommunicationForm from '@/profile/components/forms/CommunicationForm';

import SaveForm from './SaveForm';

function Communication({ profileId }: any) {
  return (
    <CommunicationForm
      editPhone
      phoneRequired
      component={SaveForm}
      profileId={profileId}
      showSuccessMessage
    />
  );
}

export default Communication;
