import { Action, UnknownAction } from 'redux';

import { gengql } from '@/__generated__';
import { Query } from '@/search';
import { AppThunk } from '@/store';
import { DocumentNodeResult } from '@/utils/gql';

export const SEARCH = 'SEARCH' as const;
export const SEARCH_SELECT_PROFILE = 'SEARCH_SELECT_PROFILE' as const;
export const SEARCH_CLEAR_PROFILE_SELECTION = 'SEARCH_CLEAR_PROFILE_SELECTION' as const;

const SEARCH_QUERY = gengql(/* GraphQL */ `
  query actionSearch(
    $cursor: String
    $text: String
    $sort: String
    $ascending: Boolean
    $highlight: Boolean
    $countries: [String!]
    $languages: [String!]
    $industries: [String!]
    $mustHaveEmail: Boolean
    $profileTypes: [ProfileType]
    $expertStates: [ExpertState]
    $expertHistory: [ExpertHistory]
    $agentIds: [String!]
    $networkIds: [String!]
    $sharingGroupIds: [ID!]
    $experienceOrganizations: ExperienceFieldInput
    $experienceLocations: ExperienceFieldInput
    $experienceJobTitles: ExperienceFieldInput
    $consultationsWith: ConsultationsWithInput
    $educationDegrees: [String!]
    $educationFos: [String!]
    $groupKeywordIds: [ID!]
    $keywords: [String!]
    $marketplacePreference: MarketplacePreference
  ) {
    search(
      after: $cursor
      query: $text
      sort: $sort
      ascending: $ascending
      highlight: $highlight
      countries: $countries
      languages: $languages
      industries: $industries
      must_have_email: $mustHaveEmail
      profile_types: $profileTypes
      expert_states: $expertStates
      expert_history: $expertHistory
      agent_ids: $agentIds
      network_ids: $networkIds
      sharing_group_ids: $sharingGroupIds
      experience_organizations: $experienceOrganizations
      experience_locations: $experienceLocations
      experience_job_titles: $experienceJobTitles
      consultations_with: $consultationsWith
      education_degrees: $educationDegrees
      education_fos: $educationFos
      group_keyword_ids: $groupKeywordIds
      keywords: $keywords
      marketplace_preference: $marketplacePreference
    ) {
      edges {
        cursor
        node {
          first_name
          last_name
          name
          title
          city
          country
          languages
          keywords
          group_keywords
          sectors
          regions
          summary_snippets
          cv_snippets
          additional_information_snippets
          experiences {
            title
            organization
            location
            role
            start_date
            end_date
            description_snippets
            current
          }
          education {
            degree
            field_of_study
            school
            start_date
            end_date
            description_snippets
          }
          candidates {
            id
            state
            request {
              id
              name
              html_url
            }
            project {
              id
              name
              html_url
              group {
                id
                name
              }
            }
            request_name_highlight
            answerable_questions {
              query
              query_snippets
              response
              response_snippets
              response_type
            }
            qualification_responses {
              query
              query_snippets
              response
              response_snippets
              response_type
            }
            notes {
              id
              author {
                id
                name
                first_name
                last_name
                picture_url
                html_url
              }
              author_role
              text_snippets
              is_good_match
            }
          }
          network_experts {
            id
            network {
              id
              group {
                id
                name
              }
            }
            created_by {
              id
              name
              first_name
              last_name
              picture_url
              html_url
            }
            add_note_snippets
          }
          profile {
            id
            name
            user {
              id
            }
            sources(only_last: true, has_custom_candidate_invitation_message: true) {
              agent {
                name
                custom_candidate_invitation_message
              }
            }
            html_url
            picture_url
            first_name
            last_name
            credit_rate
            languages {
              code
              fluency
            }
            expert_internal_networks(connection_states: [active]) {
              id
              add_method
              participation_level
              from_group {
                id
                name
              }
              network {
                id
                name
              }
            }
            available_marketplace
            url_endpoint
          }
        }
      }
      pageInfo {
        total
        hasNextPage
      }
    }
  }
`);

export type SearchResultPage = NonNullable<DocumentNodeResult<typeof SEARCH_QUERY>>['search'];

export function search(query: Query, cursor?: any): AppThunk<Promise<SearchResultPage | null>> {
  return async (dispatch, _getState, { graphql }) => {
    dispatch(
      setSearchResults({
        query,
        fetching: true,
      })
    );

    try {
      const { data: result } = await graphql.client.query({
        query: SEARCH_QUERY,
        variables: {
          cursor,
          text: query.text,
          sort: query.sort,
          ascending: query.ascending,
          highlight: query.highlight,
          countries: query.countries,
          languages: query.languages,
          industries: query.industries,
          mustHaveEmail: query.mustHaveEmail,
          profileTypes: query.profileType ? [query.profileType] : [],
          expertStates: query.expertStates,
          expertHistory: query.expertHistory,
          agentIds: query.agentIds,
          networkIds: query.networkIds,
          sharingGroupIds: query.sharingGroupIds,
          experienceOrganizations: query.organizationsCurrentRestriction
            ? {
                current_restriction: query.organizationsCurrentRestriction,
                accepted_values: query.organizations,
              }
            : undefined,
          experienceLocations: query.locationsCurrentRestriction
            ? {
                current_restriction: query.locationsCurrentRestriction,
                accepted_values: query.locations,
              }
            : undefined,
          experienceJobTitles: query.jobTitlesCurrentRestriction
            ? {
                current_restriction: query.jobTitlesCurrentRestriction,
                accepted_values: query.jobTitles,
              }
            : undefined,
          consultationsWith: {
            me: (query.consultationsWith || []).includes('me'),
            group_ids: (query.consultationsWith || []).filter((x: any) => x !== 'me'),
          },
          educationDegrees: query.educationDegrees,
          educationFos: query.educationFos,
          groupKeywordIds: query.groupKeywordIds,
          keywords: query.keywords,
          marketplacePreference: query.marketplacePreference,
        },
      });

      const page = result.search;

      dispatch(
        setSearchResults({
          query,
          fetching: false,
          page,
        })
      );

      return page;
    } catch (err: any) {
      dispatch(
        setSearchResults({
          query,
          fetching: false,
          error: err.message,
        })
      );
    }
    return null;
  };
}

export interface SearchAction extends Action<typeof SEARCH> {
  query: Partial<Query>;
  hash: string;
  fetching: boolean;
  page?: SearchResultPage;
  error?: string;
  reset?: boolean;
}

export function setSearchResults({
  query,
  fetching,
  page,
  error,
  reset,
}: {
  query: Query;
  fetching: boolean;
  page?: SearchResultPage;
  error?: string;
  reset?: boolean;
}): SearchAction {
  const action: SearchAction = {
    type: SEARCH,
    query: query.serialize(),
    hash: query.hash(),
    fetching,
    page,
    error,
    reset,
  };

  return action;
}

export function selectProfile(profile: any, selected: any): UnknownAction {
  return {
    type: SEARCH_SELECT_PROFILE,
    profile,
    selected,
  };
}

export function clearProfileSelection(): UnknownAction {
  return {
    type: SEARCH_CLEAR_PROFILE_SELECTION,
  };
}
