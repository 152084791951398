import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { Fragment, useCallback, useMemo, useState } from 'react';

import FlagIcon from '../FlagIcon';

export default function ({ options, value, renderOption, getCountryCode, onChange, style }: any) {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = useCallback((event: any) => setAnchorEl(event.currentTarget), []);

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleChange = useCallback(
    (value: any) => {
      onChange(value);
      handleClose();
    },
    [onChange]
  );

  const items = useMemo(
    () =>
      options
        .map((option: any) => {
          const text = renderOption(option);
          if (!text) return;

          return (
            <MenuItem
              key={JSON.stringify(option)}
              selected={option === value}
              onClick={() => handleChange(option)}
            >
              <FlagIcon
                style={{ marginRight: 10 }}
                country={getCountryCode ? getCountryCode(option) : option}
              />
              {text}
            </MenuItem>
          );
        })
        .filter(Boolean),
    [value, options]
  );

  return (
    <Fragment>
      <ButtonBase style={style} onClick={handleClick} tabIndex={-1}>
        <FlagIcon country={getCountryCode ? getCountryCode(value) : value} />
        <ArrowDropDown />
      </ButtonBase>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {items}
      </Menu>
    </Fragment>
  );
}
