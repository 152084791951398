import Dialog from '../Dialog';
import FieldContainer from '../FieldContainer';
import s from './MessageTemplateVariablesDialog.module.scss';

const requestVariables = [
  'id',
  'slug',
  'public_url',
  'private_url',
  'short_public_url',
  'name',
  'description',
  'questions',
  'qualifications',
];

const userVariables = [
  'id',
  'private_url',
  'username',
  'first_name',
  'last_name',
  'full_name',
  'city',
  'country',
  'headline',
  'summary',
];

function MessageTemplateVariablesDialog({
  // Props
  open,

  onClose,
  title,
}: any) {
  return (
    <Dialog open={open} title={title} onClose={onClose} onCancel={onClose}>
      <FieldContainer className={s.messageField} label="Request">
        {requestVariables.map((v) => (
          <p>[field: request.{v}]</p>
        ))}
      </FieldContainer>
      <FieldContainer className={s.messageField} label="Expert">
        {userVariables.map((v) => (
          <p>[field: expert.{v}]</p>
        ))}
      </FieldContainer>
      <FieldContainer className={s.messageField} label="Sender">
        {userVariables.map((v) => (
          <p>[field: sender.{v}]</p>
        ))}
      </FieldContainer>
    </Dialog>
  );
}

export default MessageTemplateVariablesDialog;
