import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import EditDialog from '@/components/EditDialog';
import { TextField } from '@/components/FormAdapters/FormAdapters';

function EditMessage({
  messageTitle,
  messageTitleCapitalized,
  onSubmit,
  notify,
  handleSubmit: handleSubmitProp,
  reset,
  field,
  placeholder,
  fieldValue,
  ...props
}: any) {
  const handleSubmit = (values: any) => {
    onSubmit(values)
      .then(() => {
        notify(`${messageTitleCapitalized} updated.`, 'success');
      })
      .catch((err: any) => {
        notify(`Error when updating ${messageTitle}.`, 'error');
        throw err;
      });
  };

  return (
    <EditDialog
      {...props}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      fieldValue={fieldValue}
    >
      <Field
        id={field}
        component={TextField}
        fullWidth
        multiline
        name={field}
        label={messageTitleCapitalized}
        placeholder={placeholder}
        maxRows={15}
      />
    </EditDialog>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'EditMessage' because it is a fun... Remove this comment to see the full error message
EditMessage = reduxForm({
  enableReinitialize: true,
})(EditMessage);

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'field' does not exist on type '{}'.
    const formName = `message-${ownProps.field}-${ownProps.initialValues.id}`;
    return {
      form: formName,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      fieldValue: formValueSelector(formName)(state, ownProps.field),
    };
  },
  {
    notify,
  }
)(EditMessage);
