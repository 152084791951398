import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';

import Button from '@/componentsv2/Button';

import { IDropdownProps } from './types';

const testId = 'of-dropdown';

const Dropdown = ({
  className,
  options,
  value,
  onChange,
  id,
  buttonProps = {
    variant: 'tertiary',
  },
}: IDropdownProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<null | HTMLButtonElement>(null);

  const handleToggle = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
  };

  const selected = options.find((option) => option.value === value);

  return (
    <div className={className} data-testid={testId}>
      <Button
        size="small"
        ref={ref}
        id={`dropdown-button-${id}`}
        aria-controls={open ? `dropdown-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
        data-testid={testId}
        endIcon={ChevronDown}
        endIconClassName={clsx('transition-transform duration-100', {
          'rotate-180': open,
        })}
        {...buttonProps}
      >
        {selected?.display}
      </Button>
      <Menu
        id={`dropdown-menu-${id}`}
        anchorEl={ref.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `dropdown-button-${id}`,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onChange(option.value);
              handleClose();
            }}
            selected={option.value === value}
          >
            {option.display}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export { testId as DropdownTestId };
export type { IDropdownProps };
export default Dropdown;
