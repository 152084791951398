import { UnknownAction } from 'redux';

import ActionTypes from './ActionTypes';

export function answerQuestion(questionId: any, answerId: any): UnknownAction {
  return {
    type: ActionTypes.COMPLIANCE_TRAINING__ANSWER_QUESTION,
    questionId,
    answerId,
  };
}

export function completeStep(step: any, userId: any): UnknownAction {
  return {
    type: ActionTypes.COMPLIANCE_TRAINING__COMPLETE_STEP,
    step,
    userId,
  };
}
