import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SelectOption } from '@/componentsv2/Select/types';
import { FilterRequest } from '@/core/pagination';
import { SearchService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const fetchData = createAsyncThunk(
  'lookups/fetchData',
  async (filters: FilterRequest = {}) => {
    if (!filters.query) {
      return [];
    }

    const knowledgeTypes = ['knowledge_raw'];

    let data = null;

    data = await SearchService.completeKnowledgeCreate({
      query: filters.query ?? '',
      knowledge_types: knowledgeTypes,
      limit: filters.limit ?? 20,
    });

    return (
      data?.map(
        (r) =>
          ({
            id: r.knowledge_id,
            label: r.name,
            name: r.name,
            type: r.type,
          }) as SelectOption
      ) || []
    );
  }
);

const lookupsSlice = createSlice({
  name: 'lookup',
  initialState: initialState.lookups,
  reducers: {
    resetData() {
      return initialState.lookups;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      results: action.payload,
      isLoading: false,
      error: null,
    }));
  },
});

export const lookupsSelector = (state: IState) => state.lookups;
export const { resetData } = lookupsSlice.actions;
export default lookupsSlice.reducer;
