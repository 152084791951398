import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { Checkbox } from '@/components/FormAdapters/FormAdapters';
import { updateProfile } from '@/profile/store';

class AvailableMarketplaceForm extends PureComponent {
  handleSubmit = async (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, updateProfile, onSubmit } = this.props;
    await updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
    const { component: Container = Form, handleSubmit, ...other } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          type="checkbox"
          component={Checkbox}
          name="available_marketplace"
          label="Available for marketplace projects"
        />
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      initialValues: {
        available_marketplace: profile.available_marketplace,
      },
    };
  },
  {
    updateProfile,
  }
)(
  reduxForm({
    form: 'expertAvailableMarketplace',
    enableReinitialize: true,
    // @ts-expect-error TS(2345): Argument of type 'typeof AvailableMarketplaceForm'... Remove this comment to see the full error message
  })(AvailableMarketplaceForm)
);
