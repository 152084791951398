import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import React from 'react';

import Divider from '@/components/Divider';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import SvgIcon from '@/components/Icon/SvgIcon';
import Link from '@/components/Link';
import { getGMTOffset } from '@/core/time';
import { darkGray, teal900 } from '@/theme/colors';
import { formatBillRate } from '@/utils';

import Field from './Field';

const useStyles = makeStyles(() => ({
  signupType: {
    textTransform: 'capitalize',
  },
  empty: {
    color: darkGray,
  },
  teamsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function ProfileInfo({
  user,
  profile,
  onEditRate,
  onEditPhone,
  onEditEmail,
  onEditTimezone,
  onEditSkype,
  onEditMarketplace,
  onEditSelfService,
  onEditBackgroundCheck,
}: any) {
  const s = useStyles();

  const { timezone, skype, phones, emails } = profile;

  const email = emails && (emails.find((e: any) => e.primary) || emails[0]);
  const phone = phones && (phones.find((e: any) => e.primary) || phones[0]);

  let zoneLabel;
  if (timezone) {
    const now = moment().tz(timezone);
    zoneLabel = `${getGMTOffset(timezone)} (${now.format('h:mm a')})`;
  }

  const id = user ? user.id : profile.id;

  return (
    <div>
      <Field clipboard={id} label="ID">
        {id}
      </Field>
      <Field label="Hidden">{profile.hide_profile ? 'Yes' : 'No'}</Field>
      <Field label="Type">
        <span className={s.signupType}>{user ? user.signup_type || 'unknown' : 'lead'}</span>
      </Field>
      {user && user.recruiter && (
        <Field label="Referred by" link={user.recruiter.name}>
          {user.recruiter.name}
        </Field>
      )}

      {user && user.groups && user.groups.length > 0 && (
        <Field label={`Team${user.groups.length > 1 ? 's' : ''}`}>
          <div className={s.teamsContainer}>
            {user.groups.map((g: any, i: any) => (
              <React.Fragment key={g.id}>
                <Link to={g.html_url}>{g.name}</Link>
                {i < user.groups.length - 1 && <span>,&nbsp;</span>}
              </React.Fragment>
            ))}
          </div>
        </Field>
      )}

      <Divider />

      {!user && profile.created_by && (
        <Field
          icon={<FAIcon icon="plus" size={20} />}
          tooltip="Created"
          link={profile.created_by.html_url}
          note={profile.created_at && moment(profile.created_at).format('D MMM YYYY hh:mma')}
        >
          {profile.created_by.name}
        </Field>
      )}
      {!user && profile.updated_by && (
        <Field
          icon={<FAIcon icon="pencil" size={20} />}
          tooltip="Last update"
          link={profile.updated_by.html_url}
          note={profile.updated_at && moment(profile.updated_at).format('D MMM YYYY hh:mma')}
        >
          {profile.updated_by.name}
        </Field>
      )}
      <Field icon={<SvgIcon fill={teal900} icon="onfrontiers" />} onEdit={onEditMarketplace}>
        <b>Marketplace:</b>&nbsp;{profile.available_marketplace ? 'Yes' : 'No'}
      </Field>
      {user && (
        <Field icon={<MaterialIcon icon="search" />} onEdit={onEditSelfService}>
          <b>Self Service:</b>&nbsp;{user.available_self_service ? 'Yes' : 'No'}
        </Field>
      )}
      {user && (
        <Field icon={<MaterialIcon icon="check" />} onEdit={onEditBackgroundCheck}>
          <b>Background Check:</b>&nbsp;{user.background_check ? 'Yes' : 'No'}
        </Field>
      )}
      {user && (
        <Field icon={<MaterialIcon icon="attach_money" />} onEdit={onEditRate}>
          {formatBillRate(profile.bill_rate)}
        </Field>
      )}
      {zoneLabel && (
        <Field icon={<MaterialIcon icon="access_time" />} onEdit={onEditTimezone}>
          {zoneLabel}
        </Field>
      )}
      <Field
        absolute
        icon={<MaterialIcon icon="mail" />}
        link={email && `mailto:${email.address}`}
        onEdit={onEditEmail}
      >
        {email ? email.address : <span className={s.empty}>(Add email)</span>}
      </Field>
      <Field
        absolute
        icon={<MaterialIcon icon="phone" />}
        link={phone && `tel:${phone.address}`}
        onEdit={onEditPhone}
      >
        {phone ? phone.address : <span className={s.empty}>(Add phone)</span>}
      </Field>
      {skype && (
        <Field
          absolute
          icon={<FAIcon icon="skype" size={24} />}
          link={`skype:${skype}?call`}
          onEdit={onEditSkype}
        >
          {skype}
        </Field>
      )}
    </div>
  );
}
