import makeStyles from '@mui/styles/makeStyles';

import Body from '@/components/Body';

const getStyles = makeStyles((theme) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'noMobilePadding' does not exist on type ... Remove this comment to see the full error message
  root: ({ noMobilePadding }) => ({
    paddingTop: 30,
    paddingBottom: 30,
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      paddingRight: noMobilePadding ? 0 : 15,
      paddingLeft: noMobilePadding ? 0 : 15,
    },
  }),
}));

export default ({ children, noMobilePadding, ...other }: any) => {
  const s = getStyles({ noMobilePadding });
  return (
    <Body className={s.root} {...other}>
      {children}
    </Body>
  );
};
