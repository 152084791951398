import moment from 'moment-timezone';
import React from 'react';

import { Select } from '../FormAdapters';
import timezones from './timezones.json';

function SelectTimezone({ input, onChange, ...other }: any) {
  const timezoneOptions = timezones
    .map((tz) => {
      const value = tz[0];
      const zone = moment.tz(value);
      const label = `(GMT${zone.format('Z')}) ${tz[1]}`;
      return { value, label, zone };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return <Select options={timezoneOptions} input={input} {...other} />;
}

export default SelectTimezone;
