import createCache from '@emotion/cache';
import { SimplePaletteColorOptions, createTheme } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

import * as accessColors from '@/theme/colors';
import { colors as tailwindColors } from '@/theme/colors';
import Campton from '@/theme/vendor/campton/38D5CC_5_0.woff2';

declare module '@mui/material/styles' {
  interface CustomPalette {
    tertiary: SimplePaletteColorOptions;
    danger: SimplePaletteColorOptions;
    blue: SimplePaletteColorOptions;
    deepPurple: ColorPartial;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    newExperience: true;
  }
}

const {
  palette: { augmentColor: ac },
  breakpoints,
} = createTheme();

const createColor = (main: string, light?: string, dark?: string) =>
  ac({ color: { main, light, dark } });

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that Material UI styles are loaded first.
// It allows developers to easily override Material UI styles with other styling solutions, like CSS modules.
export const cacheEmotionKey = 'mui-style';
export function createEmotionCache() {
  const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
  const insertionPoint = emotionInsertionPoint ?? undefined;

  return createCache({
    key: cacheEmotionKey,
    insertionPoint: insertionPoint as HTMLElement | undefined,
    prepend: true,
  });
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1200,
      newExperience: 800,
    },
  },
  typography: {
    allVariants: {
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
  },
  palette: {
    primary: tailwindColors.primary,
    secondary: tailwindColors.secondary,
    error: tailwindColors.error,
    warning: tailwindColors.warning,
    info: {
      main: accessColors.darkGray,
    },
    success: tailwindColors.success,

    tertiary: createColor('#00646e'),
    danger: createColor('#fb3544'),
    blue: createColor('#007bff'),
    deepPurple: {
      '50': '#ede7f6',
      '100': '#d1c4e9',
      '200': '#b39ddb',
      '300': '#9575cd',
      '400': '#7e57c2',
      '500': '#673ab7',
      '600': '#5e35b1',
      '700': '#512da8',
      '800': '#4527a0',
      '900': '#311b92',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${accessColors.borderColor}`,
        },
        indicator: {
          backgroundColor: accessColors.darkGreen,
          height: 5,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: 'initial',
          textTransform: 'none',
          [breakpoints.up('md')]: {
            fontSize: '1rem',
            minWidth: 'initial',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large',
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: accessColors.borderColor,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: accessColors.lightTan,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard', // https://mui.com/material-ui/migration/migration-v4/#variant-prop
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: accessColors.darkGray,
          fontSize: 12,
        },
        head: {
          fontWeight: 400,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: accessColors.white,
          backgroundColor: accessColors.teal900,
          '&:hover': {
            backgroundColor: accessColors.teal900,
            opacity: 0.6,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: accessColors.teal900,
          '&$disabled': {
            color: accessColors.darkGray,
          },
        },
        track: {
          color: accessColors.teal900,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: accessColors.darkGreen,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: accessColors.darkBrown,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: accessColors.darkBrown,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    // MuiPickersToolbar: {
    //   styleOverrides: {
    //     toolbar: {
    //       backgroundColor: accessColors.blue,
    //     },
    //   },
    // },
    // MuiPickersYear: {
    //   styleOverrides: {
    //     yearSelected: {
    //       color: accessColors.teal900,
    //     },
    //   },
    // },
    // MuiPickersMonth: {
    //   styleOverrides: {
    //     monthSelected: {
    //       color: accessColors.teal900,
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     daySelected: {
    //       backgroundColor: accessColors.darkGreen,
    //     },
    //   },
    //   defaultProps: {
    //     sx: {
    //       '&.MuiPickersDay-root.Mui-selected': {
    //         backgroundColor: accessColors.darkGreen,
    //         '&:hover': {
    //           backgroundColor: accessColors.darkBlue,
    //         },
    //       },
    //       '&.MuiPickersDay-today': {
    //         border: 'none',
    //       },
    //     },
    //   },
    // },
    // MuiPickersModal: {
    //   styleOverrides: {
    //     dialogAction: {
    //       color: accessColors.teal900,
    //     },
    //   },
    // },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          color: accessColors.teal900,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
});

export const harnessTheme = createTheme({
  palette: {
    primary: createColor(tailwindColors.teal[900], '#33D6C5', '#1E9F92'),
    secondary: createColor('#2389C1', '#2DA1E2', '#1E6D9A'),
    tertiary: createColor('#00646e'),
    error: createColor('#E94F74', '#FF5C83', '#B43A57'),
    warning: createColor('#ED6C02', '#E65100', '#FF9800'),
    success: createColor('#1DC068'),
    danger: createColor('#fb3544'),
    blue: createColor('#007bff'),
    info: createColor('#007BFF', '#3395FF', '#0056B2'),
    common: {
      black: '#151616',
      white: '#FFFFFF',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#2c3533',
    },
    deepPurple: {
      '50': '#ede7f6',
      '100': '#d1c4e9',
      '200': '#b39ddb',
      '300': '#9575cd',
      '400': '#7e57c2',
      '500': '#673ab7',
      '600': '#5e35b1',
      '700': '#512da8',
      '800': '#4527a0',
      '900': '#311b92',
    },
    grey: {
      '50': '#FCFCFC',
      '100': '#F7F9FA',
      '200': '#F2F4F4',
      '300': '#E1EDF1',
      '400': '#C8D4DA',
      '500': '#879499',
      '600': '#545D63',
      '700': '#343E45',
      '800': '#151616',
      '900': '#151616',
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans',
    fontSize: 16,
    h1: {
      fontSize: 64,
      fontWeight: 500,
    },
    h2: {
      fontSize: 48,
      fontWeight: 500,
    },
    h3: {
      fontSize: 36,
      fontWeight: 500,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '120%',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.07em',
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: '0.042em',
      textTransform: 'uppercase',
    },
    button: undefined,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Campton';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Campton'), local('Campton-Regular'), url(${Campton}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          pRegular: 'p',
          pMedium: 'p',
          inputPlaceholder: 'span',
          inputLabel: 'span',
          inputHelpText: 'span',
          button: 'span',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: '14px',
          minWidth: '97px',
          height: '50px',
        },
        contained: {
          color: 'white',
          boxShadow: 'inset 0px -2px 0px rgb(0 0 0 / 20%)',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'medium',
        color: 'success',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        groupedContained: {
          boxShadow: 'inset 0px -2px 0px rgb(0 0 0 / 20%)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          textAlign: 'center',
        },
        tooltip: {
          fontSize: '12px',
          minWidth: '275px',
          textAlign: 'center',
          padding: '13px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.10)',
          borderRadius: '3px',
          position: 'relative',
          fontWeight: 400,
          fontFamily: 'Inter',
          margin: '0px',
          backgroundColor: 'white',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          letterSpacing: '0.02em',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // Disable ripple for jest tests
        disableRipple: !!import.meta.env.JEST_WORKER_ID,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-MenuItem,&.Mui-selected,&.Mui-selected:hover': {
            background: theme.palette.action.selected,
          },
        }),
      },
    },
  },
  breakpoints: {
    values: {
      ...breakpoints.values,
      newExperience: 800,
    },
  },
  zIndex: {
    // In styles/global.ts: Intercom Widget = appBar - 100
  },
});

harnessTheme.palette.action.disabledBackground = harnessTheme.palette.grey[300];

export default theme;
