import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { fetchAllGroups } from '@/actions/group';

import Filter from './Filter';
import FilterButton from './FilterButton';
import FilterChips from './FilterChips';
import FilterSelect from './FilterSelect';
import SelectInput from './SelectInput';

const useStyles = makeStyles({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  keyword: {
    marginTop: 10,
  },
});

function GroupKeywordsFilter({
  title,
  hideDivider,
  groups,
  selected,
  onRemoveValue,
  onSubmit,
  fetchAllGroups,
  placeholder,
}: any) {
  const s = useStyles();

  const [groupSelected, setGroupSelected] = useState('');
  const [keywordsSelected, setKeywordsSelected] = useState([]);

  useEffect(() => {
    fetchAllGroups({
      keywordsConfig: true,
    });
  }, []);

  const handleSubmit = () => {
    onSubmit([...selected, ...keywordsSelected]);
    setKeywordsSelected([]);
    setGroupSelected('');
  };

  const groupKeywords = useMemo(
    () =>
      (groups?.edges || [])
        // @ts-expect-error TS(7031) FIXME: Binding element 'g' implicitly has an 'any' type.
        .map(({ node: g }) => ({
          group: g,
          keywords: g.profile_keywords_definition || [],
        }))
        .filter((gk: any) => gk.keywords.length),
    [groups]
  );

  const selectedOptions = useMemo(
    () =>
      groupKeywords
        .map((gk: any) => ({
          ...gk,
          keywords: gk.keywords.filter((k: any) => selected.includes(k.id)),
        }))
        .filter((gk: any) => gk.keywords.length)
        .reduce(
          (all: any, gk: any) => [
            ...all,
            ...gk.keywords.map((k: any) => ({
              id: k.id,
              name: k.name,
              group: gk.group.name,
            })),
          ],
          []
        ),
    [groupKeywords, selected]
  );

  const selectedGroupKeywords = groupKeywords.find((gk: any) => groupSelected === gk.group.id);

  if (!groupKeywords.length) {
    return null;
  }

  return (
    <Filter title={title} hideDivider={hideDivider}>
      <FilterSelect
        placeholder="Select team"
        options={groupKeywords.map((gk: any) => ({
          value: gk.group.id,
          label: gk.group.name,
        }))}
        onChange={({ target: { value } }: any) => {
          setGroupSelected(value);
          setKeywordsSelected([]);
        }}
        value={groupSelected}
      />

      <SelectInput
        primary
        selected={keywordsSelected}
        setSelected={setKeywordsSelected}
        disabled={!selectedGroupKeywords}
        placeholder={placeholder}
        className={s.keyword}
        options={
          selectedGroupKeywords?.keywords.filter(
            (k: any) => !selectedOptions.some((s: any) => s.id === k.id)
          ) || []
        }
        getOptionValue={(o: any) => o.id}
        getOptionLabel={(o: any) => o.name}
        input={{ value: keywordsSelected }}
        selectInputProps={{ limit: null }}
      />

      <div className={s.action}>
        <FilterButton disabled={!keywordsSelected.length} onClick={handleSubmit} />
      </div>

      <FilterChips
        options={selectedOptions}
        onRemoveValue={onRemoveValue}
        getLabel={(o: any) => (
          <>
            {o.group}: <strong>{o.name}</strong>
          </>
        )}
        getColor={(o: any) => (o.value ? 'secondary' : undefined)}
      />
    </Filter>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'GroupKeywordsFilter' because it ... Remove this comment to see the full error message
GroupKeywordsFilter = connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    groups: state.groups.all,
  }),
  {
    fetchAllGroups,
  }
)(GroupKeywordsFilter);

export default GroupKeywordsFilter;
