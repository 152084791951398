import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addNetworkExperts } from '@/actions/internalNetwork';
import { notify } from '@/actions/ui';
import { clearProfileSelection, selectProfile } from '@/search/store';
import { RootState } from '@/store';
import { unique } from '@/utils';

import EditDialog from '../EditDialog/EditDialog';
import FieldContainer from '../FieldContainer/FieldContainer';
import InternalNetworkSharePromoDialog from '../InternalNetworkSharePromoDialog';
import MemberRow from '../MemberRow/MemberRow';
import s from './InternalNetworkShareMultipleProfiles.module.scss';

class InternalNetworkShareMultipleProfiles extends PureComponent {
  state = {
    selectedInternalNetworks: {},
    addNote: '',
  };

  getSharedInternalNetworks = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer, groups } = this.props;

    if (viewer.admin) {
      return groups.edges.map((e: any) => e.node.internal_network).filter(Boolean);
    }

    const sharedInternalNetworks = viewer.groups
      ? viewer.groups
          .map((g: any) => g.shared_internal_networks || [])
          .filter(Boolean)
          .reduce((acc: any, n: any) => [...acc, ...n])
      : [];

    return unique(sharedInternalNetworks, (n) => n.id);
  };

  handleNetworkSelect = (id: any, checked: any) => {
    this.setState((prevState) => ({
      selectedInternalNetworks: {
        // @ts-expect-error TS(2339): Property 'selectedInternalNetworks' does not exist... Remove this comment to see the full error message
        ...prevState.selectedInternalNetworks,
        [id]: checked,
      },
    }));
  };

  handleAddNoteChange = (e: any) => this.setState({ addNote: e.target.value });

  handleSubmit = async () => {
    // @ts-expect-error TS(2339): Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
    const { profiles, addNetworkExperts, clearProfileSelection, notify } = this.props;

    const { selectedInternalNetworks, addNote } = this.state;

    const networkIds = Object.keys(selectedInternalNetworks).filter(
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (id) => selectedInternalNetworks[id]
    );

    const profileIds = profiles.map((p: any) => p.id);

    try {
      await addNetworkExperts({
        profileIds,
        networkIds,
        addMethod: 'group_share',
        teamNote: addNote,
      });
      clearProfileSelection();
      notify('Experts shared with selected networks.', 'success');
    } catch (err) {
      notify('An error occurred when sharing experts.', 'error');
      throw err;
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { open, profiles, onClose, selectProfile } = this.props;

    const { addNote, selectedInternalNetworks } = this.state;

    const sharedInternalNetworks = this.getSharedInternalNetworks();

    if (sharedInternalNetworks.length === 0) {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <InternalNetworkSharePromoDialog open={open} onClose={onClose} />;
    }

    const messageLabel =
      profiles && profiles.length > 1
        ? 'How do you know these experts?'
        : 'How do you know this expert?';

    return (
      <EditDialog
        open={open}
        title="Share Experts"
        //saveLabel="Share"
        subTitle="Grant other groups access to your network of experts"
        onSubmit={this.handleSubmit}
        onReset={onClose}
        onClose={onClose}
      >
        <FieldContainer
          label="Shared groups"
          className={s.sharedGroups}
          containerClassName={s.sharedGroupsContainer}
        >
          <div className={s.sharedGroupsScroll}>
            {sharedInternalNetworks.map((n: any) => (
              <FormControlLabel
                key={n.id}
                control={
                  <Checkbox
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    checked={selectedInternalNetworks[n.id]}
                    onChange={() => {
                      this.handleNetworkSelect(
                        n.id,
                        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        !selectedInternalNetworks[n.id]
                      );
                    }}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    value={n.name}
                  />
                }
                label={n.name}
                style={{ width: '100%', marginBottom: 5 }}
              />
            ))}
          </div>
        </FieldContainer>
        <FieldContainer
          label="Experts"
          className={s.experts}
          containerClassName={s.expertsContainer}
        >
          <div className={s.expertsScroll}>
            {profiles.map((p: any) => (
              <MemberRow
                key={p.id}
                profile={p}
                displayName={p.name}
                onRemove={() => selectProfile(p, false)}
              />
            ))}
          </div>
        </FieldContainer>
        <FieldContainer label={messageLabel}>
          <TextField
            id="networkShareMessage"
            multiline
            InputProps={{ disableUnderline: true }}
            fullWidth
            value={addNote}
            onChange={this.handleAddNoteChange}
          />
        </FieldContainer>
      </EditDialog>
    );
  }
}

export default connect(
  (state: RootState) => ({
    groups: state.groups.networks,
    viewer: state.viewer,
  }),
  {
    selectProfile,
    addNetworkExperts,
    clearProfileSelection,
    notify,
  }
)(InternalNetworkShareMultipleProfiles);
