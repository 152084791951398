import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { Edit2 } from 'react-feather';

import Button from '@/componentsv2/Button';
import { segmentTracking } from '@/core/analytics';
import { SavedSearch } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import {
  createOrEditSearch,
  showCollaboratorsDialog,
  showSavedSearchesDrawer,
  showShortlistDrawer,
} from '@/searchv2/store/searchSlice';
import { useAppDispatch } from '@/store';

const SavedSearchBar = ({
  className,
  savedSearch,
  isLoading,
}: {
  className?: string;
  savedSearch?: SavedSearch;
  isLoading?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isSaved = !!savedSearch?.id;
  const name = savedSearch?.name ?? 'New Unsaved Search';

  const StartNewSearchButton = ({ className }: { className?: string }) => {
    return (
      <Button
        className={className}
        variant="tertiary"
        to={APP_ROUTES.search}
        size="small"
        onClick={() => {
          segmentTracking('user-started-new-search', {
            event_type: 'Saved Search',
            event_name: 'User started a new search',
            savedsearch_id: savedSearch?.id,
            savedsearch_name: savedSearch?.name,
          });
        }}
      >
        Start New Search
      </Button>
    );
  };

  return (
    <>
      <div
        className={clsx(
          className,
          'border-b border-gray-100 bg-white sm:flex sm:items-center sm:justify-between'
        )}
      >
        <div className="min-w-0 flex items-center justify-between border-b border-gray-100 px-16 py-5 sm:block sm:border-0">
          <div className="min-w-0 flex items-center gap-x-16 text-14 font-bold">
            {isLoading ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <>
                <span className="min-w-0 truncate">{name}</span>
                {isSaved ? (
                  <Button
                    variant="tertiary"
                    srText="edit"
                    size="small"
                    onClick={() => {
                      dispatch(createOrEditSearch({ savedSearchId: savedSearch?.id }));
                    }}
                    startIcon={Edit2}
                  />
                ) : (
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => dispatch(createOrEditSearch())}
                  >
                    Save Search
                  </Button>
                )}
              </>
            )}
          </div>
          {isSaved ? <StartNewSearchButton className="sm:hidden" /> : null}
        </div>
        <div className="flex items-center justify-end gap-8 px-16 py-5 sm:justify-start">
          {isSaved ? (
            <>
              <StartNewSearchButton className="hidden sm:inline" />
              <Button
                variant="secondary"
                size="small"
                onClick={() => dispatch(showCollaboratorsDialog())}
              >
                Share
              </Button>
            </>
          ) : null}
          <Button
            variant="secondary"
            size="small"
            onClick={() => dispatch(showSavedSearchesDrawer())}
          >
            My Saved Searches
          </Button>
          {isSaved ? (
            <Button size="small" onClick={() => dispatch(showShortlistDrawer())}>
              Shortlist
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SavedSearchBar;
