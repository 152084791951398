import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import { TASKS, completeTask, setJob } from '@/dashboardv2/store/onboardingSlice';
import { CancelablePromise, Job, JobCreate, JobService } from '@/openapi';
import { fetchKnowledge } from '@/profilev2/store/profileSlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

import IManageExperience from '../manageExperience';

const history = createBrowserHistory();

let jobRequest: CancelablePromise<Job>;

export const createJob = createAsyncThunk(
  'manageExperience/createJob',
  async (
    { requestBody, skipTasks }: { requestBody: JobCreate; skipTasks?: boolean },
    { dispatch }
  ) =>
    JobService.jobCreate(requestBody).then((data) => {
      if (!skipTasks) {
        dispatch(
          completeTask({
            slug: TASKS.ADD_PROFESSIONAL_HISTORY,
          })
        );
      }
      dispatch(fetchKnowledge(data.profile_id));
      dispatch(setJob(data));
      return data;
    })
);

export const fetchJob = createAsyncThunk('manageExperience/fetchJob', async (jobId: string) => {
  jobRequest?.cancel();
  jobRequest = JobService.jobRetrieve(jobId);
  return jobRequest.then((data) => {
    setJob(data);
    return data;
  });
});

const manageExperienceSlice = createSlice({
  name: 'manageExperience',
  initialState: initialState.manageExperience,
  reducers: {
    resetData: () => initialState.manageExperience,
    updateJob: (state, action: PayloadAction<Job>) => ({
      ...state,
      job: action.payload,
    }),
    addExperience: (
      state,
      action: PayloadAction<Pick<IManageExperience, 'skipAccrualUpdate'> | undefined>
    ) => {
      history.pushState('', '');
      return {
        ...state,
        skipAccrualUpdate: action?.payload?.skipAccrualUpdate ?? false,
        opened: true,
      };
    },
    editExperience: (
      state,
      action: PayloadAction<
        | Pick<IManageExperience, 'job' | 'skipAccrualUpdate'>
        | Pick<IManageExperience, 'jobId' | 'skipAccrualUpdate'>
      >
    ) => {
      history.pushState('', '');
      return {
        ...state,
        ...(action?.payload ?? {}),
        opened: true,
      };
    },
    closeDrawer: (state) => ({
      ...state,
      opened: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJob.pending, (state) => ({
      ...state,
      isJobLoading: true,
      error: null,
    }));
    builder.addCase(fetchJob.fulfilled, (state, action) => ({
      ...state,
      job: action.payload,
      isJobLoading: false,
      error: null,
    }));
    builder.addCase(createJob.pending, (state) => ({
      ...state,
      isJobLoading: true,
    }));
    builder.addCase(createJob.fulfilled, (state, action) => ({
      ...state,
      job: action.payload,
      isJobLoading: false,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchJob), (state) => ({
      ...state,
      isJobLoading: false,
    }));
  },
});

export const manageExperienceSelector = (state: IState) => state.manageExperience;
export const { addExperience, editExperience, closeDrawer, resetData, updateJob } =
  manageExperienceSlice.actions;
export default manageExperienceSlice.reducer;
