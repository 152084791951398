import { useQuery } from '@tanstack/react-query';

import { AssignmentsService, CancelablePromise, PaginatedAssignmentsList } from '@/openapi';

import { AssignmentTypes, AssignmentsOrdering } from '../AssignmentsManager';

export const DEFAULT_ASSIGNMENTS_LIMIT = 5;

type FetchAssignments = {
  profileId?: string;
  assignmentTypes: AssignmentTypes;
  colleagueId?: string;
  jobId?: string;
  name?: string;
  ordering?: AssignmentsOrdering;
  page: number;
  limit?: number;
};

export type AssignmentsUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchAssignments = ({
  assignmentTypes = '',
  colleagueId,
  jobId,
  page = 1,
  limit = DEFAULT_ASSIGNMENTS_LIMIT,
  name,
  ordering = '-dates',
  profileId = '',
}: FetchAssignments): CancelablePromise<PaginatedAssignmentsList> => {
  const offset = (page - 1) * limit;
  return AssignmentsService.assignmentsList(
    assignmentTypes,
    colleagueId,
    jobId,
    limit,
    name,
    offset,
    ordering,
    profileId
  );
};

type FetchAssignmentsByJobId = Pick<
  FetchAssignments,
  'jobId' | 'assignmentTypes' | 'page' | 'limit'
>;

export const useAssignmentsByJobIdQuery = (
  { jobId, page, assignmentTypes = '', limit }: FetchAssignmentsByJobId,
  options?: AssignmentsUseQueryOptions
) => {
  return useQuery({
    queryKey: ['assignments', { jobId, assignmentTypes, page, limit }],
    queryFn: () => fetchAssignments({ jobId, assignmentTypes, limit, page }),
    keepPreviousData: true,
    enabled: !!jobId,
    ...options,
  });
};

type FetchAssignmentsByType = Pick<
  FetchAssignments,
  'profileId' | 'assignmentTypes' | 'page' | 'limit' | 'ordering'
>;

export const useAssignmentsByType = (
  { profileId, page, assignmentTypes = '', limit, ordering }: FetchAssignmentsByType,
  options?: AssignmentsUseQueryOptions
) => {
  return useQuery({
    queryKey: ['assignments', { profileId, assignmentTypes, page, limit, ordering }],
    queryFn: () => fetchAssignments({ profileId, assignmentTypes, limit, page, ordering }),
    keepPreviousData: true,
    enabled: !!profileId,
    ...options,
  });
};

type FetchAssignmentsByColleague = Pick<
  FetchAssignments,
  'profileId' | 'colleagueId' | 'page' | 'limit' | 'ordering'
>;

export const useAssignmentsByColleague = (
  { profileId, page, colleagueId, limit, ordering }: FetchAssignmentsByColleague,
  options?: AssignmentsUseQueryOptions
) => {
  return useQuery({
    queryKey: ['assignments', { profileId, colleagueId, page, limit, ordering }],
    queryFn: () =>
      fetchAssignments({
        profileId,
        colleagueId,
        limit,
        page,
        ordering,
        assignmentTypes: 'Project|Proposal',
      }),
    keepPreviousData: true,
    enabled: !!profileId && !!colleagueId,
    ...options,
  });
};
