import Tag from '@/componentsv2/Tag';
import KnowledgeLevelIcon from '@/knowledge/components/KnowledgeLevelIcon';
import { EXPERT_THRESHOLD } from '@/utils/knowledgeScoreToPercent';

import { IExpertTagProps } from './types';

const ExpertTag = ({ value, children, ...rest }: IExpertTagProps) => {
  return (
    <Tag
      startIcon={
        value && value >= EXPERT_THRESHOLD ? (
          <KnowledgeLevelIcon level="expert" className="fill-grey-900" />
        ) : undefined
      }
      {...rest}
    >
      {children}
    </Tag>
  );
};

export type { IExpertTagProps };
export default ExpertTag;
