import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import Paper, { PaperProps } from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ErrorMessage, Field, FieldArray, useFormik } from 'formik';
import { Map } from 'immutable';
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Lock, Mail, Unlock, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Avatar from '@/auth/components/Avatar';
import { userSelector } from '@/auth/store/userSlice';
import CollectionService from '@/collection/CollectionsService';
import {
  collectionCollaboratorSelector,
  fetchData as fetchCollaboratorsCollection,
} from '@/collection/store/collectionCollaboratorSlice';
import {
  collectionInfoSelector,
  fetchData as fetchCollectionInfoData,
} from '@/collection/store/collectionInfoSlice';
import Box from '@/componentsv2/Box';
import Button from '@/componentsv2/Button';
import FormikForm from '@/componentsv2/FormikForm';
import Input from '@/componentsv2/Input';
import Text from '@/componentsv2/Text';
import Tooltip from '@/componentsv2/Tooltip';
import { segmentTracking } from '@/core/analytics';
import {
  PaginatedProfileEmailList,
  PrivacyEnum,
  ProfileEmail,
  SavedCollectionService,
} from '@/openapi';
import {
  fetchData as fetchDataByEmail,
  profilesByEmailSelector,
  resetData as resetDataByEmail,
} from '@/profilev2/store/profilesEmailSlice';
import { CollaboratorItem } from '@/searchv2/components/saved-search/CollaboratorsList';
import { useAppSelector } from '@/store';
import { useAppDispatch } from '@/store';
import { capitalizer } from '@/utils/texts';

import styles, { DialogClasses } from './styles';

const useStyles = makeStyles(styles);

export interface IDialogProps extends DialogProps {
  className?: string;
  onClose: () => void;
  open: boolean;
  showCloseButton?: boolean;
  'data-test-id'?: string;
  onSave?: () => void;
  id?: string;
  onlyCollaborators?: boolean;
  profileId?: string;
  profileName?: string;
}
export type SelectOption = {
  name: string;
  label: string;
  id: string;
  isNew: boolean;
  picture_url: string;
};

const CollectionFormDialog = (props: IDialogProps) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [toDelete, setToDelete] = useState(Map<string, boolean>());

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { data: userData } = useAppSelector(userSelector);
  const { data: collaboratorsByEmail, isLoading: isLoadingCollaboratorsByEmail } =
    useAppSelector(profilesByEmailSelector);
  const { data: collectionInfo } = useAppSelector(collectionInfoSelector);
  const collectionCollaboratorInfo = useAppSelector(collectionCollaboratorSelector);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const classProps = clsx(props.className, classes.Dialog, DialogClasses.paper, {
    [DialogClasses.onlyCollaborators]: props.onlyCollaborators,
  });
  const testId = props['data-test-id'] || 'of-dialog';
  const NAME_CHARACTER_LIMIT = 50;
  const DES_CHARACTER_LIMIT = 100;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = useCallback(
    debounce((query: string) => {
      dispatch(fetchDataByEmail({ email: query }));
    }, 900),
    [dispatch]
  );

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  const refresh = useCallback(() => {
    props.id && dispatch(fetchCollaboratorsCollection({ id: props.id, limit: 999 }));
  }, [dispatch, props.id]);

  useEffect(() => {
    if (props.id) {
      dispatch(fetchCollectionInfoData({ id: props.id }));
      refresh();
    }
  }, [dispatch, props.id, refresh]);

  const handleOnSubmit = useCallback(
    async (values: {
      name: string;
      description: string;
      collaborators: Array<SelectOption>;
      isPublic: boolean;
      collaboratorMail: SelectOption;
    }) => {
      const { name, description, isPublic, collaborators } = values;
      const privacy = isPublic ? PrivacyEnum.PUBLIC : PrivacyEnum.PRIVATE;
      const collaboratorsIDs: string[] = [];

      collaborators.forEach(
        (collaborator) => collaborator.isNew && collaboratorsIDs.push(collaborator.id)
      );

      if (props.id) {
        if (toDelete.count()) {
          const ids: string[] = [];
          toDelete.forEach((_, id) => ids.push(id));

          await CollectionService.deleteCollaborators(props.id, ids, false, (id: string) =>
            setToDelete(toDelete.delete(id))
          );
          segmentTracking('remove-collaborator-from-collection');
        }

        for (let c = 0; c < collaboratorsIDs.length; c++)
          await CollectionService.addCollaborator(props.id, collaboratorsIDs[c]);
      }

      const request = props.id
        ? CollectionService.updateCollection(props.id, name, description, privacy)
        : CollectionService.createCollection(name, description, privacy, collaboratorsIDs);

      request
        .then((response) => {
          enqueueSnackbar(`Collection ${props.id ? 'updated' : 'created'}`, {
            variant: 'success',
          });

          props.onClose?.();
          if (!props.id && response.id && props.profileId) {
            SavedCollectionService.savedCollectionProfileCreate(response.id, {
              profile: [props.profileId],
            })
              .then(() => {
                enqueueSnackbar(
                  `${props.profileName} was added to the ${response.name} Collection`,
                  { variant: 'success' }
                );
              })
              .catch(() => {
                enqueueSnackbar('Error when creating a collection', {
                  variant: 'error',
                });
              })
              .finally(() => refresh());
          }
          !props.id && navigate(`/collections/${response.id}`);
        })
        .catch(() => {
          enqueueSnackbar('Error creating collection', { variant: 'error' });
        })
        .finally(() => refresh());
    },
    // keep dependecies updated but DO NOT use just 'props'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, props.id, props.onClose, toDelete]
  );

  const validationSchema = yup.object().shape({
    name: yup.string().min(2, 'Too Short!').required('Required'),
    description: yup.string(),
    collaboratorMail: yup.object().shape({
      id: yup.string(),
      label: yup.string().email(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: (props.id && collectionInfo?.name) || '',
      description: (props.id && collectionInfo?.description) || '',
      collaborators:
        (props.id &&
          collectionCollaboratorInfo?.results?.map((info) => ({
            id: info.profile.id,
            label: info.profile.email,
            name: info.profile.full_name,
            isNew: false,
            picture_url: info.profile.picture_url,
          }))) ||
        [],
      isPublic: !(props.id && collectionInfo?.privacy === PrivacyEnum.PRIVATE),
      collaboratorMail: {
        id: '',
        label: '',
        name: '',
        isNew: true,
        picture_url: '',
      },
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
    enableReinitialize: true,
  });

  const convertToSelectOptions = useCallback(
    (data: PaginatedProfileEmailList | null) =>
      data?.results
        ?.filter(
          (data: ProfileEmail) =>
            !formik.values.collaborators.some((aux: { label: string }) => aux.label === data.email)
        )
        .map(
          (data: ProfileEmail) =>
            ({
              id: data.profile_id,
              label: data.email,
              name: data.profile,
              isNew: true,
              picture_url: data.picture_url,
            }) as SelectOption
        ) || [],
    [formik.values.collaborators]
  );

  const emailInputValue = formik.values.collaboratorMail;
  const emailInputValidator = inputValue && inputValue !== userData?.email;

  const CustomPaper = (props: PaperProps) => <Paper {...props} />;

  const AddNewCollaborator = (): JSX.Element => {
    const [emailValidationRef, setEmailValidationRef] = useState<HTMLDivElement | null>(null);

    const handleClick = () => {
      emailValidationRef?.focus();
    };

    const handlerAdder = (push: (value: SelectOption) => void, newValue: SelectOption) => {
      if (!emailInputValidator) {
        return;
      }
      // Add colaborator
      push(newValue);
      // Send analytics
      segmentTracking('add-collaborator-to-collection', {
        event_type: 'Collection',
        event_name: 'Invite User to Collection',
        collaboratorAddedEmail: emailInputValue,
      });
      // Reset values
      setInputValue('');
      dispatch(resetDataByEmail());
      formik.setFieldValue('collaboratorMail', '');
    };

    const renderOption = (optnProps: React.HTMLAttributes<HTMLLIElement>, option: SelectOption) => {
      return (
        <span {...optnProps} style={{}}>
          {isLoadingCollaboratorsByEmail ? (
            <Skeleton variant="text" width="95%" />
          ) : (
            <CollaboratorItem
              fullName={option.name}
              pictureUrl={option.picture_url}
              email={option.label}
            />
          )}
        </span>
      );
    };

    return (
      <FieldArray
        name="collaborators"
        render={({ remove, push }) => (
          <Box className={DialogClasses.collaboratorsListContainer}>
            <Box
              onClick={handleClick}
              className={clsx(
                DialogClasses.addNewCollaboratorContainer,
                'col-span-2',
                formik.values.collaboratorMail.id && DialogClasses.selectedStatus
              )}
            >
              <Box className={DialogClasses.addNewCollaboratorContext}>
                <Autocomplete
                  onChange={(_event, newValue) => {
                    handlerAdder(push, newValue);
                  }}
                  filterOptions={(x) => x}
                  disableClearable
                  PaperComponent={CustomPaper}
                  handleHomeEndKeys={false}
                  value={formik.values.collaboratorMail}
                  options={convertToSelectOptions(collaboratorsByEmail)}
                  noOptionsText={`${
                    collaboratorsByEmail && isLoadingCollaboratorsByEmail
                      ? `Looking for "${formik.values.collaboratorMail?.label}"`
                      : 'No Options for this search'
                  }`}
                  renderOption={renderOption}
                  onInputChange={(_event, value) => {
                    debounceChange(value);
                    setInputValue(value);
                    formik.setFieldValue('collaboratorMail', {
                      label: value,
                      isNew: true,
                    });
                  }}
                  inputValue={inputValue}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      data-testid="collection-collaborator-input"
                      placeholder="Add collaborator by name or email"
                      onClick={() => setInputValue('')}
                      value={''}
                      inputRef={(item) => {
                        setEmailValidationRef(item);
                      }}
                    />
                  )}
                />
              </Box>
              {isLoadingCollaboratorsByEmail && (
                <CircularProgress size={15} className={DialogClasses.loadingCircular} />
              )}
            </Box>
            {formik.values.collaborators.map((collaborator: SelectOption, index: number) => (
              <Box
                key={`${collaborator.id} ${collaborator.label}`}
                className={DialogClasses.listedCardContainer}
              >
                <Box className={DialogClasses.listedCardContext}>
                  <Avatar fullName={String(collaborator?.name)} src={collaborator.picture_url} />
                  <Box className={DialogClasses.listedCardInfo}>
                    <Text className={clsx(DialogClasses.listedCardName, 'text-brand-primary')}>
                      {collaborator?.name}
                    </Text>
                    <Text className={DialogClasses.listedCardPosition}>{collaborator?.label}</Text>
                  </Box>
                </Box>
                {props.onlyCollaborators ? (
                  <a
                    href={`mailto:${collaborator.label}`}
                    onClick={() => {
                      segmentTracking('clicked-collaborator-profile-email');
                    }}
                  >
                    <Mail className="h-20 w-20 text-brand-tertiary" />
                    <span className="sr-only">Email</span>
                  </a>
                ) : (
                  <Button
                    variant="tertiary"
                    startIcon={X}
                    srText="Close"
                    onClick={() => {
                      setToDelete(toDelete.set(collaborator.id, true));
                      remove(index);
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}
      />
    );
  };

  const CreateCollectionForm = () => {
    return (
      <FormikForm value={formik} className={DialogClasses.content}>
        <Box className={DialogClasses.nameContent}>
          <Box className={DialogClasses.fieldContent}>
            <Field name="name">
              {() => (
                <input
                  value={formik.values['name']}
                  type="text"
                  placeholder="e.g. French Speakers"
                  data-testid="collection-name-input"
                  ref={(item) => setInputRef(item)}
                  onChange={(event) => {
                    return formik.setFieldValue('name', capitalizer(event.target.value));
                  }}
                  maxLength={NAME_CHARACTER_LIMIT}
                />
              )}
            </Field>

            <InputAdornment position="end">
              {NAME_CHARACTER_LIMIT - formik.values.name.length}
            </InputAdornment>
            <ErrorMessage name="name" component="div" />
          </Box>
          <Box className={DialogClasses.icon}>
            <Tooltip
              title={`Click to make this collection ${
                formik.values.isPublic ? 'private' : 'public'
              }.`}
              placement="bottom-end"
              className={DialogClasses.tooltip}
            >
              <Button
                variant="tertiary"
                onClick={() => formik.setFieldValue('isPublic', !formik.values.isPublic)}
                srText={formik.values.isPublic ? 'Make private' : 'Make public'}
                startIcon={formik.values.isPublic ? Lock : Unlock}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box className={DialogClasses.multiline}>
          <Field name="description">
            {() => (
              <textarea
                value={formik.values['description']}
                data-testid="collection-description-input"
                placeholder="e.g. Freelance French Speakers around the globe"
                onChange={(event) => {
                  return formik.setFieldValue('description', capitalizer(event.target.value));
                }}
                maxLength={DES_CHARACTER_LIMIT}
              />
            )}
          </Field>
          <InputAdornment position="end">
            {DES_CHARACTER_LIMIT - formik.values.description.length}
          </InputAdornment>
        </Box>
        <Box className={DialogClasses.colleagueContainer}>
          {!props.onlyCollaborators ? (
            <h3 className="text-dark-primary hd-5">
              Collaborators ({formik.values.collaborators.length ?? 0})
            </h3>
          ) : null}
          {AddNewCollaborator()}
        </Box>
        <Box className={DialogClasses.buttonsContainer}>
          <Button type="submit" disabled={!(formik.isValid && formik.dirty)}>
            Save
          </Button>
        </Box>
      </FormikForm>
    );
  };

  return (
    <MUIDialog className={classProps} data-testid={testId} open={props.open}>
      <Box className="flex items-center justify-between p-24">
        <h2 className="hd-4">
          {props.onlyCollaborators
            ? `Collaborators (${formik.values.collaborators.length ?? 0})`
            : props.id
              ? 'Edit Collection'
              : 'Create Collection'}
        </h2>
        {props.showCloseButton && (
          <Button
            variant="tertiary"
            startIcon={X}
            size="large"
            onClick={props.onClose}
            srText="Close"
          />
        )}
      </Box>
      <DialogContent>{CreateCollectionForm()}</DialogContent>
    </MUIDialog>
  );
};

export default CollectionFormDialog;
