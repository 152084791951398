import cx from 'classnames';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { Checkbox, Select, TextField } from '@/components/FormAdapters';
import MonthPicker from '@/components/MonthPicker';
import SelectLocation from '@/components/SelectLocation';
import { RootState } from '@/store';

import s from './Experience.module.scss';
import AddButton from './buttons/AddButton';
import RemoveButton from './buttons/RemoveButton';

const parseFormat = 'YYYY-MM-DD';

function dateToString(d: any) {
  return d && moment(d).format(parseFormat);
}

function stringToDate(d: any) {
  return d && moment(d, parseFormat).toDate();
}

const roles = [
  'Consultant',
  'Cross border trade',
  'Executive Management',
  'Finance and Accounting',
  'HR',
  'Investments, Mergers and Acquisitions',
  'IT',
  'Manufacturing',
  'Marketing',
  'Operations',
  'Research and Development',
  'Sales',
  'Sourcing',
  'Strategy',
];

function ExperienceMonthPicker({ input, meta: { touched, error }, ...other }: any) {
  const hasError = touched && error;

  return (
    <MonthPicker
      {...other}
      {...input}
      maxDate={new Date()}
      error={hasError}
      placeholder="Example: 06/2010"
      helperText={hasError ? error : ''}
    />
  );
}

function Col({ className, children, ...other }: any) {
  return (
    <div className={cx(s.col, { [className]: !!className })} {...other}>
      <div className={s.colContainer}>{children}</div>
    </div>
  );
}

function Experience({
  field,
  allCountries,
  experience,
  onRemove,
  showRemove,
  showAdd,
  onAdd,
  change,
}: any) {
  return (
    <>
      <div className={s.root}>
        <div className={s.removeAction}>{showRemove && <RemoveButton onClick={onRemove} />}</div>

        <div className={s.content}>
          <div className={s.row}>
            <Col>
              <Field component={TextField} fullWidth name={`${field}.title`} label="Title" />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                component={SelectLocation}
                multiple={false}
                name={`${field}.location`}
                label="Location"
                countries={allCountries}
                format={(country: any) => allCountries.find((c: any) => c.name === country)}
                parse={(country: any) => (country && country.name) || ''}
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                component={TextField}
                fullWidth
                name={`${field}.organization`}
                label="Employer Name"
              />
            </Col>

            <Col>
              <Field
                component={Select}
                fullWidth
                name={`${field}.role`}
                options={roles.map((r) => ({ label: r, value: r }))}
                label="Type of Role"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                component={ExperienceMonthPicker}
                fullWidth
                name={`${field}.start_date`}
                label="Start date"
                format={(value: any) => stringToDate(value)}
                parse={(value: any) => dateToString(value)}
              />
            </Col>

            <Col>
              <Field
                component={ExperienceMonthPicker}
                fullWidth
                name={`${field}.end_date`}
                label="End date"
                disabled={experience.current}
                format={(value: any) => stringToDate(value)}
                parse={(value: any) => dateToString(value)}
              />
            </Col>

            <Col className={s.currentCol}>
              <Field
                type="checkbox"
                component={Checkbox}
                name={`${field}.current`}
                label="I still work here"
                onChange={() => {
                  change(`${field}.end_date`, null);
                }}
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                name={`${field}.description`}
                component={TextField}
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                label="Describe what you learned here"
              />
            </Col>
          </div>
        </div>
      </div>

      <div className={s.addAction}>
        {showAdd && <AddButton label="Experience" onClick={onAdd} />}
      </div>
    </>
  );
}

export default connect((state: RootState) => ({
  allCountries: state.countries,
}))(Experience);
