import makeStyles from '@mui/styles/makeStyles';

import ColumnSection from '@/components/ColumnSection/ColumnSection';
import EditIcon from '@/components/EditIcon/EditIcon';
import { formatExperiencePeriod } from '@/core/profile';
import { primary } from '@/theme/colors';

import SectionItem from './SectionItem';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  emptyText: {
    fontSize: 16,
    lineHeight: '24px',
    color: primary,
  },
}));

export default ({ education, editable, onEdit }: any) => {
  const s = getStyles();

  return (
    <div className={s.row}>
      <ColumnSection title="Education">
        {education.length
          ? education.map((e: any) => (
              <SectionItem
                key={e.id}
                subTitle={`${e.degree}${
                  e.degree && e.field_of_study ? ', ' : ''
                }${e.field_of_study}`}
                title={e.school}
                description={e.description}
                period={formatExperiencePeriod(e.start_date, e.end_date, false, {
                  duration: false,
                })}
              />
            ))
          : editable && (
              /* eslint-disable-next-line */
              <a onClick={onEdit}>
                <span className={s.emptyText}>Add education</span>
              </a>
            )}
      </ColumnSection>

      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};
