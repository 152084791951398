import moment from 'moment-timezone';

export interface Event {
  sid: string;
  level: 'ERROR' | 'WARNING' | 'INFO';
  timestamp: string;
  group: string;
  name: string;
  payload?: {
    message?: string;
  };
}

export type CallType = 'dialout' | 'dialin' | 'phone' | 'web';

export interface CallRange {
  duration: number;
  ended_time: string; // ISO date string format
  started_time: string; // ISO date string format
}

export interface Call {
  duration: number;
  ended_time: string; // ISO date string format
  grouper: string;
  origin: CallType;
  ranges: CallRange[];
  started_time: string; // ISO date string format
  username: string;
  uuid: string;
}

const callTypeLabel: Record<CallType, string> = {
  dialout: 'Dial Out',
  dialin: 'Dial In',
  web: 'Web',
  phone: 'Phone',
};

export default class Participant {
  id: string;
  call: any;
  metrics: any;

  constructor(participant: Record<string, any>) {
    this.id = participant.id;
    for (const key in participant) {
      // @ts-ignore
      this[key] = participant[key];
    }
  }

  events(): Event[] {
    return [];
  }

  summary() {
    return null;
  }

  callTypeLabel(): string {
    return callTypeLabel[this.inferCallType()];
  }

  inferCallType(): CallType {
    throw new Error('Not implemented');
  }

  callId(): string {
    throw new Error('Not implemented');
  }

  url(): string | undefined {
    throw new Error('Not implemented');
  }

  callStart(): any {
    throw new Error('Not implemented');
  }

  callEnd(): any {
    throw new Error('Not implemented');
  }

  callDuration(): any {
    throw new Error('Not implemented');
  }

  getCallOriginDescription(): string {
    throw new Error('Not implemented');
  }

  hasMetrics(): boolean {
    throw new Error('Not implemented');
  }
}

export class TwilioParticipant extends Participant {
  hasCall = () => !!this.call;

  hasMetrics = () => !!this.metrics;

  callId = () => this.call?.sid;

  callStart = () => this.call?.start_time;

  callEnd = () => this.call?.end_time;

  callDuration = () => parseInt(this.call?.duration);

  callFrom = () => this.call?.from;

  callTo = () => this.call?.to;

  events = () => this.metrics?.events;

  summary = () => this.metrics?.summary;

  url = () =>
    `https://www.twilio.com/console/voice/calls/logs/${this.callId()}${
      this.inferCallType() === 'web' ? '/metrics' : ''
    }`;

  getGrouper = () => {
    return '';
  };

  getCallOriginDescription = () => {
    const callType = this.inferCallType();

    if (callType === 'web') {
      const summary = this.summary();
      const ipAddress = summary && summary.from && summary.from.ip_address;
      return `IP: ${ipAddress || 'Unknown'}`;
    }

    if (callType === 'dialin') return `Phone: ${this.callFrom() || 'Unknown'}`;

    if (callType === 'dialout') return `Phone: ${this.callTo() || 'Unknown'}`;

    return 'Unknown';
  };

  inferCallType = () => {
    const { call } = this;
    if (call?.direction === 'outbound-api') return 'dialout';
    if (call?.direction === 'inbound') return call?.to ? 'dialin' : 'web';
    return call?.direction;
  };
}

export class PlivoParticipant extends Participant {
  hasCall = () => !!this.call;

  // Plivo doesn't have support for summary/events
  hasMetrics = () => !!this.call;

  callId = () => this.call?.call_uuid;

  // answer_time can be null when `Destination Out Of Service`
  callStart = () => this.call?.answer_time ?? this.call?.initiation_time;

  callEnd = () => this.call?.end_time;

  callDuration = () => this.call?.call_duration;

  callFrom = () => {
    const number = this.call?.from_number || '';
    return number.startsWith('sip') ? '' : number;
  };

  callTo = () => {
    const number = this.call?.to_number || '';
    return number.startsWith('sip') ? '' : number;
  };

  // Plivo console currently has a bug where we have to specify the end date
  // filter as a query param in onder to load the call metrics
  url = () => {
    const endDate = this.callEnd();
    const formattedEndDate = endDate && moment(endDate).add(1, 'd').format('MM/DD/YYYY');
    return `https://console.plivo.com/voice/logs/calls/stats/${this.callId()}?end_date=${formattedEndDate}`;
  };

  getGrouper = () => {
    return '';
  };

  getCallOriginDescription = () => {
    const callType = this.inferCallType();

    if (callType === 'web') return 'Web';

    if (callType === 'dialin') return `Phone: ${this.callFrom() || 'Unknown'}`;

    if (callType === 'dialout') return `Phone: ${this.callTo() || 'Unknown'}`;

    return 'Unknown';
  };

  inferCallType = () => {
    const { call } = this;
    if (call?.call_direction === 'outbound') return 'dialout';
    return this.callTo() ? 'dialin' : 'web';
  };
}

export class ZoomParticipant extends Participant {
  hasCall() {
    return !!this.call;
  }

  // Zoom metrics is out of scope for now
  hasMetrics() {
    return !!this.call;
  }

  callId() {
    return this.call.uuid;
  }

  callStart() {
    return this.call.started_time;
  }

  callEnd() {
    return this.call.ended_time;
  }

  callDuration() {
    return this.call.duration;
  }

  callFrom() {
    return '';
  }

  callTo() {
    return this.call.username;
  }

  // Zoom doesn't support
  url() {
    return '';
  }

  getGrouper() {
    return this.call.grouper;
  }

  // Usually we return Web/Phone, but for Zoom we can identify the user,
  // so we can just show which username is in call
  // (for unknown dial-in it shows the phone number)
  getCallOriginDescription() {
    return `${this.callTo()} (${this.inferCallType()})`;
  }

  inferCallType() {
    return this.call.origin;
  }
}
