import isEqual from 'lodash.isequal';
import { FC } from 'react';

import { Select } from '../FormAdapters/FormAdapters';

interface KeywordInputProps {
  input: any;
  meta: any;
  primary?: boolean;
  label: string;
  placeholder: string;
  ChipProps?: any;
  [key: string]: any;
}

const KeywordInput: FC<KeywordInputProps> = ({
  input,
  meta,
  primary,
  label,
  placeholder,
  ChipProps = {},
  ...other
}) => {
  const chipColor = primary ? 'primary' : 'default';

  return (
    <Select
      autocomplete
      // @ts-expect-error
      multiple
      input={input}
      meta={meta}
      label={label}
      placeholder={placeholder}
      rawValueOnChange
      getOptionValue={(o: any) => o}
      isOptionEqualToValue={isEqual}
      TextFieldProps={{
        inputProps: {
          maxLength: 64,
        },
      }}
      ChipProps={{
        color: chipColor,
        ...ChipProps,
      }}
      {...other}
    />
  );
};

export default KeywordInput;
