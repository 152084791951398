import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { TextField } from '@/components/FormAdapters';
import { updateProfile } from '@/profile/store';

function validate(values = {}) {
  const errors = {};

  // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
  if (!values.title) {
    // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
    errors.title = 'Required';
  }

  // @ts-expect-error TS(2339): Property 'summary' does not exist on type '{}'.
  if (!values.summary) {
    // @ts-expect-error TS(2339): Property 'summary' does not exist on type '{}'.
    errors.summary = 'Required';
  }

  // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
  if (String(values.title).length > 120) {
    // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
    errors.title = 'Headline may be at most 120 characters long';
  }

  // @ts-expect-error TS(2339): Property 'summary' does not exist on type '{}'.
  if (String(values.summary).length > 2000) {
    // @ts-expect-error TS(2339): Property 'summary' does not exist on type '{}'.
    errors.summary = 'Short bio may be at most 2000 characters long';
  }

  return errors;
}

class IntroductionForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container = Form,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      ...other
    } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          id="introductionHeadline"
          component={TextField}
          fullWidth
          name="title"
          label="Headline"
          placeholder="Keep it brief"
          maxLength={120}
        />
        <Field
          id="introductionSummary"
          component={TextField}
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          name="summary"
          label="Short Bio"
          placeholder="Tell us briefly what you know, and how this came to be an area of expertise"
          maxLength={2000}
        />
      </Container>
    );
  }
}

// @ts-expect-error TS(2629): Cannot assign to 'IntroductionForm' because it is ... Remove this comment to see the full error message
IntroductionForm = reduxForm({
  form: 'expertIntroduction',
  validate,
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'typeof IntroductionForm' is not ... Remove this comment to see the full error message
})(IntroductionForm);

// @ts-expect-error TS(2629): Cannot assign to 'IntroductionForm' because it is ... Remove this comment to see the full error message
IntroductionForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        title: profile.title,
        summary: profile.summary,
      },
    };
  },
  {
    updateProfile,
  }
  // @ts-expect-error TS(2345): Argument of type 'typeof IntroductionForm' is not ... Remove this comment to see the full error message
)(IntroductionForm);

export default IntroductionForm;
