import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import keycode from 'keycode';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { canJoinNetwork } from '@/actions/internalNetwork';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';
import { isEmailValid, normalizeSpace } from '@/utils';

import EditDialog from '../EditDialog';
import FieldContainer from '../FieldContainer';
import CustomSelect from '../Select';
import SelectProfile from '../SelectProfile';

const useStyles = makeStyles(() => ({
  networks: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  networksLabel: {
    marginRight: 10,
    flex: '0 0 125px',
  },
  container: {
    padding: '2px 10px 0 !important',
  },
}));

const defaultInvitationMessage =
  'I’d like to invite you to my company’s knowledge ' +
  'network so that we can collaborate more ' +
  'efficiently on upcoming opportunities';

function AddToNetworkDialog({ allowAdd, open, onAdd, onDone, networks, canJoinNetwork }: any) {
  interface Invitee {
    type: 'email' | 'profile';
    value: {
      id?: string;
      email?: string;
      first_name?: string;
      last_name?: string;
    };
  }

  const [invitee, setInvitee] = useState<Invitee | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [invitationMessage, setInvitationMessage] = useState('');
  const [teamNote, setTeamNote] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState<any>({});
  const [canJoin, setCanJoin] = useState(null);

  const s = useStyles();

  const checkCanJoinNetwork = useCallback(() => {
    const { id, email } = invitee?.value || {};
    const identifier = id || email;

    if (!identifier || !selectedNetwork.id) {
      setCanJoin(null);
      return;
    }

    canJoinNetwork({
      profileId: id,
      email,
      networkId: selectedNetwork.id,
    })
      .then((result: any) => {
        setCanJoin(result.canJoinNetwork);
      })
      .catch(() => {
        // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        setCanJoin(false);
      });
  }, [canJoinNetwork, invitee?.value, selectedNetwork.id]);

  const reset = useCallback(async () => {
    setFirstName('');
    setLastName('');
    setInvitationMessage(defaultInvitationMessage);
    setTeamNote('');
    setSelectedNetwork({});
    setInvitee(null);
    setCanJoin(null);
    await onDone();
  }, [onDone]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (networks.length === 1) {
      setSelectedNetwork(networks[0]);
    }
    checkCanJoinNetwork();
  }, [checkCanJoinNetwork, networks, selectedNetwork.id]);

  useEffect(() => {
    checkCanJoinNetwork();
  }, [checkCanJoinNetwork, invitee?.value]);

  const handleSubmit = async () => {
    await onAdd({
      ...invitee,
      value: {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        ...invitee.value,
        firstName,
        lastName,
      },
      invitationMessage,
      teamNote,
      networkId: selectedNetwork.id,
    });
    await reset();
  };

  const validInvitee = () => {
    if (!invitee || !invitee.value) {
      return false;
    }

    const { id, email } = invitee.value;
    const hasIdentifier = id || isEmailValid(email || '');

    return hasIdentifier && firstName.trim().length && lastName.trim().length;
  };

  const acceptsInvitee = (candidate: any) => {
    if (invitee?.type === 'email' && candidate.type === 'email') {
      return invitee.value.email !== candidate.value.email;
    }
    if (invitee?.type === 'profile' && candidate.type === 'profile') {
      return invitee.value.id !== candidate.value.id;
    }
    return true;
  };

  // @ts-expect-error TS(7031): Binding element 'selectedNetworkId' implicitly has... Remove this comment to see the full error message
  const handleSelectNetwork = ({ target: { value: selectedNetworkId } }) => {
    setSelectedNetwork(networks.find((n: any) => n.id === selectedNetworkId) || {});
  };

  const handleChange = (invitee: any) => {
    const { first_name: firstName, last_name: lastName } = invitee?.value || {};

    setInvitee(invitee);
    setFirstName(firstName || '');
    setLastName(lastName || '');
  };

  const notSelectedNetwork = !selectedNetwork.id;
  const networkName = selectedNetwork.name;
  const isValidInvitee = validInvitee();

  //const label = isValidInvitee && invitee.value.id ? 'Add' : 'Invite';

  return (
    <EditDialog
      //modal
      open={open}
      title={`${networkName ? `${networkName}: ` : ''}Invite Expert`}
      subTitle={`Invite a ${allowAdd ? '' : 'new '}expert to your organization’s knowledge network`}
      //saveLabel={label}
      onSubmit={handleSubmit}
      onClose={reset}
      disableSubmit={notSelectedNetwork || !isValidInvitee || !canJoin}
      width={540}
    >
      <Grid container spacing={3}>
        {networks.length > 1 && (
          <Grid item md={12} sm={12} xs={12} className={s.networks}>
            <div className={s.networksLabel}>Invite experts to</div>
            <CustomSelect
              value={selectedNetwork.id}
              autocomplete={networks.length > 10}
              margin="none"
              fullWidth
              onChange={handleSelectNetwork}
              //maxHeight={300}
              placeholder="Select network"
              TextFieldProps={{
                InputLabelProps: { shrink: false },
              }}
              options={networks.map((n: any) => ({
                value: n.id,
                label: n.name,
              }))}
            />
          </Grid>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <InviteeInput
            onChange={handleChange}
            accepts={acceptsInvitee}
            invitee={invitee}
            canJoin={canJoin}
            className={s.container}
            allowedToJoin={canJoin}
          />
        </Grid>
        {!!invitee && !!invitee.value && (
          <>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label="First Name"
                margin="none"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={firstName}
                fullWidth
                onChange={({ target: { value } }) => setFirstName(normalizeSpace(value))}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label="Last Name"
                margin="none"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={lastName}
                fullWidth
                onChange={({ target: { value } }) => setLastName(normalizeSpace(value))}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Message to Expert"
            multiline
            margin="none"
            InputLabelProps={{ shrink: true }}
            minRows={3}
            maxRows={4}
            variant="outlined"
            fullWidth
            value={invitationMessage}
            onChange={({ target: { value } }) => setInvitationMessage(value.slice(0, 2048))}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Message to Team (Optional)"
            multiline
            margin="none"
            InputLabelProps={{ shrink: true }}
            minRows={3}
            maxRows={4}
            variant="outlined"
            fullWidth
            value={teamNote}
            onChange={({ target: { value } }) => setTeamNote(value.slice(0, 2048))}
            placeholder={
              'Let your team know why you are adding this expert ' +
              'and the area(s) where they can help your organization. ' +
              'Your team will be able to search for this.'
            }
          />
        </Grid>
      </Grid>
    </EditDialog>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'AddToNetworkDialog' because it i... Remove this comment to see the full error message
AddToNetworkDialog = connect(
  (state: RootState) => ({
    allowAdd: state.viewer && state.viewer.admin,
    networks: (state.groups.networks?.edges || [])
      .map((e: any) => e.node.internal_network)
      .filter(Boolean),
  }),
  {
    canJoinNetwork,
  }
)(AddToNetworkDialog);

export default AddToNetworkDialog;

function InviteeInput({
  accepts,
  onChange,
  canJoin,
  allowAdd,
  allowedToJoin,
  className,
  invitee,
}: any) {
  const [value, setValue] = useState('');

  const handleAdd = useCallback(() => {
    const invitee = { type: 'email', value: { email: value } };
    const valid = isEmailValid(value) && accepts(invitee);
    if (!valid) return;
    onChange(invitee);
  }, [accepts, onChange, value]);

  useEffect(() => {
    handleAdd();
  }, [handleAdd, value]);

  const interceptEnter = useCallback(
    (e: any) => {
      if (keycode(e) !== 'enter') return;
      e.preventDefault();
      handleAdd();
    },
    [handleAdd]
  );

  const handleChange = useCallback(({ target: { value } }: any) => {
    // sanity max length
    setValue(value.trim().slice(0, 500));
  }, []);

  const errorText =
    allowedToJoin === false
      ? 'A user with this email address already ' +
        'exists in your organization’s knowledge network'
      : '';

  return allowAdd ? (
    <FieldContainer
      label="Work Email"
      containerClassName={className}
      containerStyle={canJoin === false ? { borderColor: red500 } : {}}
      labelStyle={{ zIndex: 10 }}
    >
      <SelectProfile
        // @ts-expect-error TS(2769): No overload matches this call.
        underlineShow={false}
        showExpertState
        showURLEndpoint
        allowEmail
        allowProfile
        onChange={onChange}
        suggestionsFilter={accepts}
        value={invitee}
        openOnFocus
        errorText={
          allowedToJoin !== false
            ? null
            : 'A user with this email address already ' +
              'exists in your organization’s knowledge network'
        }
      />
    </FieldContainer>
  ) : (
    <TextField
      label="Work Email"
      placeholder="Email to invite"
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      value={value}
      onChange={handleChange}
      onKeyPress={interceptEnter}
      helperText={errorText}
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
      error={errorText}
    />
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'InviteeInput' because it is a fu... Remove this comment to see the full error message
InviteeInput = connect((state: RootState) => ({
  allowAdd: state.viewer && state.viewer.admin,
}))(InviteeInput);
