import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@/components/Button/Button';
import EmptyMessage from '@/components/EmptyMessage';
import { removeEducation, saveEducation } from '@/profile/store';

import Education from './Education';

const validate = (values: any) => {
  const errors = {};

  const educationErrors: any = [];
  values?.education.forEach((edu: any, i: any) => {
    if (emptyEducation(edu)) {
      // empty education form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!edu.degree) {
      // @ts-expect-error TS(2339): Property 'degree' does not exist on type '{}'.
      err.degree = 'Required';
    }

    if (!edu.school) {
      // @ts-expect-error TS(2339): Property 'school' does not exist on type '{}'.
      err.school = 'Required';
    }

    if (edu.start_date && edu.end_date && moment(edu.start_date).isAfter(edu.end_date)) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End year before start year';
    }

    if (Object.keys(err).length > 0) {
      educationErrors[i] = err;
    }
  });

  if (educationErrors.length) {
    // @ts-expect-error TS(2339): Property 'education' does not exist on type '{}'.
    errors.education = educationErrors;
  }

  return errors;
};

function emptyEducation(edu: any) {
  if (edu.degree) return false;
  if (edu.field_of_study) return false;
  if (edu.school) return false;
  if (edu.start_date) return false;
  if (edu.end_date) return false;
  if (edu.description) return false;
  return true;
}

function Educations({ fields, onAdd, change }: any) {
  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field: any, index: any) => (
          <Education
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            education={fields.get(index)}
            field={field}
            key={field}
            onRemove={() => fields.remove(index)}
            change={change}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add you first education record"
          action={<Button onClick={onAdd}>Add a Education</Button>}
        />
      )}
    </div>
  );
}

class EducationForm extends PureComponent {
  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { profile, onSubmit } = this.props;
    const education = values?.education.filter((edu: any) => !emptyEducation(edu));

    const itemsToDelete = profile.education.filter(
      (current: any) => !education.find((edited: any) => edited.id === current.id)
    );

    itemsToDelete.forEach((e: any) =>
      // @ts-expect-error TS(2339): Property 'removeEducation' does not exist on type ... Remove this comment to see the full error message
      this.props.removeEducation(profile.id, e.id)
    );

    // @ts-expect-error TS(2339): Property 'saveEducation' does not exist on type 'R... Remove this comment to see the full error message
    education.forEach((e: any) => this.props.saveEducation(profile.id, e));

    if (onSubmit) {
      onSubmit({ education });
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container,
      // @ts-expect-error TS(2339): Property 'array' does not exist on type 'Readonly<... Remove this comment to see the full error message
      array,
      // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
      change,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      ...other
    } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <FieldArray
          name="education"
          component={Educations}
          onAdd={() => array.push('education', {})}
          change={change}
        />
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const initialEducations =
      profile.education && profile.education.length > 0 ? profile.education : [{}];
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const form = state.form.expertEducation;
    const initialValues = { education: initialEducations };
    const formValues = form ? form.values : initialValues;

    return {
      profile,
      formValues,
      initialValues,
    };
  },
  {
    saveEducation,
    removeEducation,
  }
)(
  reduxForm({
    form: 'expertEducation',
    validate,
    enableReinitialize: true,
    // @ts-expect-error TS(2345): Argument of type 'typeof EducationForm' is not ass... Remove this comment to see the full error message
  })(EducationForm)
);
