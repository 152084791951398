import clsx from 'clsx';
import { ReactElement } from 'react';

import ConditionalWrapper from '@/componentsv2/ConditionalWrapper';
import Tooltip from '@/componentsv2/Tooltip';
import { KnowledgeLevel, LEVELS_BY_NAME } from '@/utils/knowledgeScoreToPercent';

const testId = 'of-knowledge-level-icon';

export type KnowledgeLevelIconProps = {
  level: KnowledgeLevel;
  className?: string;
  sizeClassName?: string;
  suppressTooltip?: boolean;
};

const KnowledgeLevelIcon = ({
  level,
  className = 'fill-[#005755]',
  sizeClassName = 'w-16 h-16',
  suppressTooltip,
}: KnowledgeLevelIconProps) => {
  const levelNumber = LEVELS_BY_NAME[level];
  return (
    <ConditionalWrapper
      condition={!suppressTooltip}
      wrapper={(wrapperChildren) => (
        <Tooltip className="capitalize" title={level} arrow placement="top" variant="dark">
          {wrapperChildren as ReactElement}
        </Tooltip>
      )}
    >
      <svg
        className={clsx(className, sizeClassName)}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{level}</title>
        <path d="M2 16C0.895431 16 -3.91405e-08 15.1046 -8.74228e-08 14L-3.49691e-07 8C4.41828 8 8 11.5817 8 16L2 16Z" />
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H8C8 4.41828 4.41828 8 0 8V2Z"
          className={clsx({ 'opacity-25': levelNumber < 2 })}
        />
        <path
          d="M14 -8.74228e-08C15.1046 -3.91405e-08 16 0.895431 16 2L16 8C11.5817 8 8 4.41828 8 -3.49691e-07L14 -8.74228e-08Z"
          className={clsx({ 'opacity-25': levelNumber < 3 })}
        />
        <path
          d="M16 14C16 15.1046 15.1046 16 14 16L8 16C8 11.5817 11.5817 8 16 8L16 14Z"
          className={clsx({ 'opacity-25': levelNumber < 4 })}
        />
      </svg>
    </ConditionalWrapper>
  );
};

export { testId as KnowledgeLevelIconTestId };
export default KnowledgeLevelIcon;
