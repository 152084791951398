import Search from '@/searchv2/pages/index';
import { isTrue } from '@/utils/validation';

import APP_ROUTES from './APP_ROUTES';
import AbsoluteRedirect from './AbsoluteRedirect';

const getStorybookRoute = () => {
  if (isTrue(import.meta.env.VITE_STORYBOOK)) {
    return {
      path: APP_ROUTES.storybook,
      element: <AbsoluteRedirect to={`/storybook-static/index.html`} />,
    };
  }
  return {
    path: APP_ROUTES.storybook,
    element: <Search />,
  };
};

export default getStorybookRoute;
