import cx from 'classnames';
import { FC } from 'react';

import s from './FlagIcon.module.scss';

interface FlagIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  country: string;
}

const FlagIcon: FC<FlagIconProps> = ({ country, ...other }) => {
  // @ts-ignore
  const flagClass = s[`flag-${country.toLowerCase()}`] as string | undefined;
  if (!flagClass) console.warn(`Flag css not found for country: ${country}`);

  return <span className={cx(s.flag, flagClass)} {...other} />;
};

export default FlagIcon;
