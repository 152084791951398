import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SelectOption } from '@/componentsv2/Select/types';
import initialState from '@/store/initialState';
import IState from '@/store/state';

const addedLookupValuesSlice = createSlice({
  name: 'addedLookupValues',
  initialState: initialState.addedLookupValues,
  reducers: {
    resetData: () => initialState.addedLookupValues,
    updateData: (state, action: PayloadAction<{ key: string; values: SelectOption[] }>) => ({
      ...state,
      [action.payload.key]: action.payload.values,
    }),
  },
});

export const addedLookupValuesSelector = (state: IState) => state.addedLookupValues;
export const { resetData, updateData } = addedLookupValuesSlice.actions;
export default addedLookupValuesSlice.reducer;
