import countries from 'country-data/data/countries.json';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useCallback, useMemo, useState } from 'react';

import CountryDropdown from '@/components/CountryDropdown';
import FAIcon from '@/components/Icon/FAIcon';
import { darkGreen } from '@/theme/colors';

function inferLocation(user: any, phoneNumbers: any) {
  const defaultCountry = 'US';

  if (!user) return defaultCountry;
  if (user.country_code) return user.country_code;
  if (!user.phone) return defaultCountry;

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phone = phoneUtil.parse(user.phone, defaultCountry);
    const countryFromPhone = phoneUtil.getRegionCodeForNumber(phone);
    const countryHasPhone = phoneNumbers.some((x: any) => x.country_code === countryFromPhone);
    return countryHasPhone ? countryFromPhone : defaultCountry;
  } catch (err) {
    console.warn(err);
    return defaultCountry;
  }
}

function alpha2name(code: any) {
  const country = countries.find((c) => c.alpha2 === code);
  return country ? country.name : '';
}

function phoneSort(a: any, b: any) {
  const as = alpha2name(a.country_code);
  const bs = alpha2name(b.country_code);

  return bs > as ? -1 : bs < as ? 1 : 0;
  // return as > bs ? 1 : as < bs ? -1 : 0; <- this line is messing with color scheme in vs code
}

const SelectPhoneNumber = ({ numbers, className, viewer, ...other }: any) => {
  // Ideally SelectPhoneNumber shouldn't know about viewer and value should
  // be a prop, but since this component is for display only, we can keep it
  // self contained.
  const countryCode = useMemo(() => inferLocation(viewer, numbers), [numbers, viewer]);
  const initialValue = useMemo(
    () =>
      numbers.find((p: any) => p.country_code === countryCode) ||
      numbers.find((p: any) => p.country_code === 'US') ||
      numbers[0],
    [countryCode, numbers]
  );

  const [value, setValue] = useState(initialValue.display);

  const sortedNumbers = useMemo(() => [...numbers].sort(phoneSort), [numbers]);

  const handleChange = useCallback((option: any) => setValue(option.display), []);

  const renderOption = useCallback((option: any) => alpha2name(option.country_code), []);

  const getCountryCode = useCallback((option: any) => option.country_code, []);

  const selected = numbers.find((p: any) => p.display === value);

  return (
    <div className={className}>
      <CountryDropdown
        value={selected}
        onChange={handleChange}
        options={sortedNumbers}
        renderOption={renderOption}
        getCountryCode={getCountryCode}
        {...other}
      />
      <FAIcon icon="phone" size={20} color={darkGreen} style={{ marginLeft: 5, marginRight: 5 }} />
      {value}
    </div>
  );
};

export default SelectPhoneNumber;
