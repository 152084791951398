import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CancelablePromise, Job, JobService, PaginatedJobList } from '@/openapi';

type FetchJob = {
  jobId: string;
  profileId: string;
};

const fetchJob = ({ jobId }: Omit<FetchJob, 'profileId'>): CancelablePromise<Job> => {
  return JobService.jobRetrieve(jobId);
};

export const useJobQuery = ({ jobId, profileId }: FetchJob) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['job', { jobId, profileId }],
    queryFn: () => fetchJob({ jobId }),
    initialData: () => {
      const jobs: PaginatedJobList | undefined = queryClient.getQueryData(['jobs', { profileId }]);
      return jobs?.results?.find((job) => job.id === jobId);
    },
    enabled: !!jobId,
  });
};
