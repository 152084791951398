import moment from 'moment-timezone';
import queryString from 'query-string';

import { getCache, setCache } from '@/utils';

const key = 'invite';

function isValid(val: any) {
  return val && val.invite && val.expires && moment().isBefore(moment(val.expires));
}

export function saveInvitationToken() {
  const query = queryString.parse(window.location.search);

  if (query.invite) {
    return setCache(key, {
      invite: query.invite,
      expires: moment().add(12, 'hour').toISOString(),
    });
  }

  const val = getCache(key);
  if (!isValid(val)) return clearInvitationToken();
}

export function getInvitationToken() {
  const val = getCache(key);
  if (isValid(val)) return val.invite;
}

export function clearInvitationToken() {
  setCache(key, undefined);
}
