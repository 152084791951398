import MuiBox from '@mui/material/Box';
import { BoxProps } from '@mui/system/Box';
import { forwardRef } from 'react';

export type BoxType = { dataTestId?: string } & BoxProps;

const Box = forwardRef<unknown, BoxType>(({ dataTestId, ...props }, ref) => {
  return (
    <MuiBox ref={ref} data-testid={`of-box${dataTestId ? '-' + dataTestId : ''}`} {...props}>
      {props.children}
    </MuiBox>
  );
});

Box.displayName = 'Box';

export default Box;
