/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CompleteKnowledge } from '../models/CompleteKnowledge';
import type { CompleteKnowledgeRequest } from '../models/CompleteKnowledgeRequest';
import type { OmnibarResponse } from '../models/OmnibarResponse';
import type { PaginatedSearchFilterResponseList } from '../models/PaginatedSearchFilterResponseList';
import type { QuickMatchRequest } from '../models/QuickMatchRequest';
import type { QuickMatchResponse } from '../models/QuickMatchResponse';
import type { Search } from '../models/Search';
import type { SearchEnriched } from '../models/SearchEnriched';
import type { SearchFilters } from '../models/SearchFilters';
import type { SearchHash } from '../models/SearchHash';
import type { SearchKnowledgeSimilar } from '../models/SearchKnowledgeSimilar';

export class SearchService {
  /**
   * Get a list of knowledge recommendations
   * @param requestBody
   * @returns CompleteKnowledge
   * @throws ApiError
   */
  public static completeKnowledgeCreate(
    requestBody: CompleteKnowledgeRequest
  ): CancelablePromise<Array<CompleteKnowledge>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/complete_knowledge/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get quick match from ElasticSearch.
   * Get quick match from ElasticSearch.
   * @param requestBody
   * @returns QuickMatchResponse
   * @throws ApiError
   */
  public static quickMatchCreate(
    requestBody: QuickMatchRequest
  ): CancelablePromise<Array<QuickMatchResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/quick_match/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Search Data from ElasticSearch
   * Using various terms and filters return data from ElasticSearch
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param query Full text query string. Defaults to *, which matches all results in the index.
   * @param sort The sorting direction based on full_name.
   * @param requestBody
   * @returns SearchEnriched | **Response Variable** | **Usage** |
   * |---|---|
   * | count | Total results for the given query |
   * | next | A link to execute to obtain the next set of results |
   * | previous | A link to execute to obtain the previous set of results |
   * | results | A list of all the various profiles found for the given search |
   * | metadata | The metadata from the request |
   *
   * @throws ApiError
   */
  public static searchCreate(
    limit: number = 10,
    offset?: number,
    query: string = '*',
    sort: '' | 'ASC' | 'DESC' = '',
    requestBody?: Search
  ): CancelablePromise<SearchEnriched> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/search/',
      query: {
        limit: limit,
        offset: offset,
        query: query,
        sort: sort,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Search Contextual Filters
   * Using a query and a filter_name obtain the results for a filter
   * @param prefix The term you are completing on
   * @param filterName The name of the filter you are completing on
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param query The full text query the user put in the search input box
   * @param requestBody
   * @returns PaginatedSearchFilterResponseList | **Response Variable** | **Usage** |
   * |---|---|
   * | count | Total results for the given query |
   * | next | A link to execute to obtain the next set of results |
   * | previous | A link to execute to obtain the previous set of results |
   * | results | A list of all the various profiles found for the given search |
   *
   * @throws ApiError
   */
  public static searchFiltersCreate(
    prefix: string,
    filterName:
      | 'client_completion'
      | 'contract_completion'
      | 'current_location_completion'
      | 'department_completion'
      | 'education_major_completion'
      | 'knowledge_expertise_completion'
      | 'knowledge_raw_completion'
      | 'knowledge_skills_completion'
      | 'language_completion'
      | 'location_completion'
      | 'sector_completion'
      | 'skill_completion'
      | 'title_completion' = 'skill_completion',
    limit: number = 10,
    offset?: number,
    query: string = '',
    requestBody?: SearchFilters
  ): CancelablePromise<PaginatedSearchFilterResponseList> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/search/filters/',
      query: {
        filter_name: filterName,
        limit: limit,
        offset: offset,
        prefix: prefix,
        query: query,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get Similar Knowledges
   * Based on a specific knowledge id and knowledge type
   * @param knowledgeId The raw knowledge id
   * @param knowledgeType The knowledge type that relates to the knowledge id
   * @param limit The number of results to return
   * @returns SearchKnowledgeSimilar
   * @throws ApiError
   */
  public static searchKnowledgeSimilarRetrieve(
    knowledgeId: string,
    knowledgeType: string,
    limit: number = 10
  ): CancelablePromise<Array<SearchKnowledgeSimilar>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/search/knowledge/similar',
      query: {
        knowledge_id: knowledgeId,
        knowledge_type: knowledgeType,
        limit: limit,
      },
    });
  }

  /**
   * OmniBar Search
   * Find filter values grouped by types
   * @param query Query
   * @param limit How many results do you want back?
   * @returns OmnibarResponse Omnibar result
   * @throws ApiError
   */
  public static searchOmnibarRetrieve(
    query: string,
    limit: number = 10
  ): CancelablePromise<OmnibarResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/search/omnibar/',
      query: {
        limit: limit,
        query: query,
      },
    });
  }

  /**
   * @param hash
   * @returns SearchHash
   * @throws ApiError
   */
  public static searchHashRetrieve(hash: string): CancelablePromise<SearchHash> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/search_hash/{hash}',
      path: {
        hash: hash,
      },
    });
  }
}
