import { FC, useCallback } from 'react';
import { connect } from 'react-redux';

import { popup } from '@/actions/ui';
import { openSvgDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

import Button from '../Button';

interface SvgUploadButtonProps {
  dimensions: any;
  popup: any;
  onChange: any;
}

const SvgUploadButton: FC<SvgUploadButtonProps> = ({ dimensions, popup, onChange }) => {
  const { graphql } = useApp();
  const handleSvgUpload = useCallback(() => {
    const contents = (
      <div>
        SVG files are uploaded without modification to the OnFrontiers platform. Additional
        formatting to image may be necessary to satisfy client and OnFrontiers site expectations.
        Consult OnFrontiers developers and/or graphic designer to ensure results are satisfactory.
        {dimensions && (
          <p>
            Expecting aspect ratio for dimensions:{dimensions.width} x {dimensions.height} (Width x
            Height)
          </p>
        )}
      </div>
    );
    popup({
      title: 'Warning',
      contents,
      buttonAlignment: 'flex-end',
      buttons: [
        {
          label: 'OK',
          callback: () => openSvgDialog(graphql.client).then((files) => onChange(files[0])),
        },
      ],
    });
  }, [dimensions, graphql.client, onChange, popup]);

  return (
    <Button variant="text" onClick={handleSvgUpload} style={{ marginTop: 0 }} size="small">
      Alternate SVG Upload
    </Button>
  );
};

export default connect(undefined, {
  popup,
})(SvgUploadButton);
