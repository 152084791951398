import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import clsx from 'clsx';
import { FC, useState } from 'react';
import {
  Book,
  Briefcase,
  CheckCircle,
  Edit3,
  FileText,
  HelpCircle,
  MapPin,
  Search,
  Tool,
  User,
  Users,
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import Input from '@/componentsv2/Input';
import { segmentTracking } from '@/core/analytics';
import { OmnibarResultSearch } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useSearchOmnibarQuery } from '@/searchv2/queries/search';
import { safeHtml } from '@/utils/safeHtml';

import { IOmnibarProps } from './types';

const testId = 'of-omnibar';

type CategoryType =
  | 'expertise'
  | 'skill'
  | 'title'
  | 'location'
  | 'org'
  | 'department'
  | 'contract'
  | 'major'
  | 'other';

const OPTIONS_ORDER = [
  'title',
  'expertise',
  'skill',
  'location',
  'org',
  'department',
  'contract',
  'major',
  'other',
  'profile',
];

const sortOptions = (arr: OmnibarResultSearch[]) => {
  return arr.sort((a, b) => {
    const indexA = OPTIONS_ORDER.indexOf(a.category);
    const indexB = OPTIONS_ORDER.indexOf(b.category);
    return indexA !== -1 && indexB !== -1
      ? indexA - indexB
      : indexA !== -1
        ? 1
        : indexB !== -1
          ? -1
          : 0;
  });
};

const CATEGORY_ICON_MAP: Record<CategoryType, FC> = {
  expertise: CheckCircle,
  skill: Edit3,
  title: User,
  location: MapPin,
  org: Briefcase,
  department: Users,
  contract: FileText,
  other: Tool,
  major: Book,
};

const CATEGORY_LABEL_MAP: Record<CategoryType, string> = {
  expertise: 'Topic',
  skill: 'Skill',
  title: 'Title',
  location: 'Location',
  org: 'Client',
  department: 'Department',
  contract: 'Contract',
  other: 'Other',
  major: 'Major',
};

const Omnibar = ({ className }: IOmnibarProps) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const debouncedQuery = useDebounce(inputValue, 200);
  const { data, isLoading } = useSearchOmnibarQuery({
    query: debouncedQuery,
  });

  const options = (data?.results || []).map(({ label, ...option }) => ({
    ...option,
    value: label,
    label: option.value,
  }));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, value: string) => {
    e.preventDefault();
    if (value) {
      navigate(APP_ROUTES.search, {
        state: { keywordSearch: value },
      });
    }
  };

  const handleOnSelection = (_: React.SyntheticEvent, selected: OmnibarResultSearch | string) => {
    if (!selected || typeof selected === 'string') return;

    if (selected?.category === 'profile') {
      const profileId = selected?.filter_id.replace('profile:', '');
      segmentTracking('pressed-click-view-profile', {
        subject_profile_id: profileId,
        triggered_by: 'omnibar',
      });
      navigate(APP_ROUTES.profile(profileId));
    } else {
      navigate(APP_ROUTES.search, {
        state: { filterId: selected?.filter_id },
      });
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e, inputValue)}
      className={clsx(className, 'w-full')}
      data-testid={testId}
    >
      <Autocomplete
        freeSolo
        options={sortOptions(options)}
        renderInput={(params) => (
          <Input
            {...params}
            placeholder="Start a new search"
            size="small"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              ref: params.InputProps.ref,
              inputProps: {
                ...params.inputProps,
              },
              sx: {
                paddingLeft: '4px',
              },
              startAdornment: (
                <button className="my-4 grid place-content-center self-stretch rounded-sm px-6 text-gray-500">
                  <span className="sr-only">Search</span>
                  <Search className="h-16 w-16" />
                </button>
              ),
            }}
          />
        )}
        groupBy={(option) => option.category}
        renderGroup={(params) => (
          <div key={params.key}>
            {params.group === 'profile' ? (
              <div className="flex h-[44px] w-full items-center border-b border-light-secondary bg-light-primary px-16 capitalize">
                {params.group}
              </div>
            ) : null}
            <div>{params.children}</div>
          </div>
        )}
        renderOption={(props, option) => {
          const Icon = (CATEGORY_ICON_MAP[option.category as CategoryType] || HelpCircle) as FC<{
            className?: string;
          }>;
          return (
            <ListItem
              {...props}
              key={option.value}
              secondaryAction={
                option.category !== 'profile' ? (
                  <div className="flex items-center gap-5 rounded-sm bg-light-primary px-8 py-2 text-14 group-hover:bg-light-secondary">
                    <Icon className="h-16 w-16" />
                    {CATEGORY_LABEL_MAP[option.category as CategoryType] || 'Unknown'}
                  </div>
                ) : null
              }
              className={clsx(
                'group flex h-[44px] w-full cursor-pointer px-16 hover:bg-gray-50 active:bg-gray-50 [&_em]:font-bold [&_em]:not-italic',
                {
                  'opacity-40': isLoading,
                }
              )}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: String(safeHtml(option.value, { ALLOWED_TAGS: ['em'] })),
                }}
              />
            </ListItem>
          );
        }}
        onChange={(_, selected) => handleOnSelection(_, selected as OmnibarResultSearch)}
      />
    </form>
  );
};

export { testId as OmnibarTestId };
export type { IOmnibarProps };
export default Omnibar;
