import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ConnectedProps, connect } from 'react-redux';

import { setAddress } from '@/actions/address';
import { notify } from '@/actions/ui';
import { PhoneInput, TextField } from '@/components/FormAdapters';
import SelectTimezone from '@/components/SelectTimezone';
import { ErrAddressAlreadyTaken } from '@/core/address';
import { updateProfile } from '@/profile/store';
import { RootState } from '@/store';
import { isPhoneValid } from '@/utils';

import Languages from './Languages';

const useStyles = makeStyles({
  contact: {
    maxWidth: 400,
  },
});

interface CommunicationFormProps {
  open?: boolean;
  title?: string;
  component: React.ElementType;
  editPhone: boolean;
  useForm?: boolean;
  onClose?: (values?: any) => void;
  phoneRequired?: boolean;
  showSuccessMessage?: boolean;
  profileId: string;
}

const connector = connect(
  (state: RootState, ownProps: CommunicationFormProps) => ({
    allCountries: state.countries,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    updateProfile,
    setAddress,
    notify,
  }
);

function CommunicationForm({
  component: Container,
  profile,
  allCountries,
  editPhone,
  onClose,
  phoneRequired,
  showSuccessMessage,
  profileId,
  notify,
  updateProfile,
  setAddress,
  ...other
}: CommunicationFormProps & ConnectedProps<typeof connector>) {
  const validate = useCallback(
    (values: any) => {
      const errors: { phone?: string } = {};

      if (phoneRequired && !values.phone) {
        errors.phone = 'Required';
      }

      if (values.phone && !isPhoneValid(values.phone)) {
        errors.phone = 'Phone must be valid.';
      }

      return errors;
    },
    [phoneRequired]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const { phone, timezone, skype, languages } = values;

      try {
        if (phone) {
          await setAddress(profileId, 'phone', phone, true);
        }

        await updateProfile({
          id: profileId,
          timezone,
          skype,
          languages: languages && languages.filter((l: any) => l),
        });

        if (showSuccessMessage) notify('Settings updated.');

        if (onClose) onClose(values);
      } catch (err) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        if (err.message === ErrAddressAlreadyTaken.message) {
          return { phone: 'Phone number is already in use.' };
        }
        notify('An error occurred when saving the profile.', 'error');
      }
    },
    [notify, onClose, profileId, setAddress, showSuccessMessage, updateProfile]
  );

  const initialValues = useMemo(() => {
    const phone = profile.phones && profile.phones.find((p: any) => p.primary);
    return {
      phone: phone && phone.display,
      languages: profile.languages || [],
      skype: profile.skype,
      timezone: profile.timezone,
    };
  }, [profile.languages, profile.phones, profile.skype, profile.timezone]);

  const s = useStyles();

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ form, handleSubmit }) => {
        const handleReset = () => {
          form.reset();
          if (onClose) onClose();
        };

        const { push } = form.mutators;

        return (
          <Container {...other} onConfirm={handleSubmit} onCancel={handleReset}>
            <div className={s.contact}>
              <Field component={SelectTimezone} name="timezone" label="Time Zone" />

              <Field component={TextField} name="skype" label="Skype Name" />

              {editPhone && (
                <Field
                  component={PhoneInput}
                  type="tel"
                  name="phone"
                  label="Mobile Phone"
                  allCountries={allCountries}
                  showExampleOnError
                />
              )}
            </div>

            <FieldArray
              component={Languages}
              name="languages"
              label="Languages"
              onAdd={() => push('languages', {})}
            />
          </Container>
        );
      }}
    </Form>
  );
}

export default connector(CommunicationForm);
