import { connect } from 'react-redux';

import { RootState } from '@/store';

import ExpertAcceptedInvitationNotification from './ExpertAcceptedInvitationNotification';
import GroupMemberAwaitingApprovalNotification from './GroupMemberAwaitingApprovalNotification';
import PromoteOtpAuthNotification from './PromoteOtpAuthNotification';

const config = {
  internal_network: {
    expert_accepted_invitation: ExpertAcceptedInvitationNotification,
  },
  group: {
    member_awaiting_approval: GroupMemberAwaitingApprovalNotification,
  },
  auth: {
    promote_otp: PromoteOtpAuthNotification,
  },
};

function AppNotifications({ notifications }: any) {
  return notifications.map((n: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Component = config[n.service][n.code];
    return <Component key={n.id} notification={n} />;
  });
}

// @ts-expect-error TS(2631): Cannot assign to 'AppNotifications' because it is ... Remove this comment to see the full error message
AppNotifications = connect((state: RootState) => ({
  notifications: filterNotifications(state),
}))(AppNotifications);

AppNotifications.prefetch = async (store: any) => {
  const notifications = store.getState().appNotifications.default || [];
  const promises = [];
  // @ts-expect-error TS(2339): Property 'service' does not exist on type 'unknown... Remove this comment to see the full error message
  for (const { service, code, values } of groupNotifications(notifications)) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const groupConfig = config[service] && config[service][code];
    if (!groupConfig) throw new Error(`no app notification configuration for ${service}:${code}`);
    if (!groupConfig.prefetch) continue;
    promises.push(groupConfig.prefetch(store, values));
  }

  await Promise.all(promises);
};

export default AppNotifications;

/**
 * Groups all notifications per (service,code) tuple.
 * Returned list will be in the form:
 * ```
 * [
 *  { service:'service_name', code:'code_name', values: [..notifications..]}
 * ]
 * ```
 * A single entry will be returned for every (service,code) tuple found in the
 * notifications provided.
 */
function groupNotifications(notifications: any) {
  return Object.values(
    notifications.reduce((acc: any, n: any) => {
      const { service, code } = n;
      const key = `${service}:${code}`;
      if (!acc[key])
        acc[key] = {
          service,
          code,
          values: [],
        };
      acc[key].values.push(n);
      return acc;
    }, {})
  );
}

function filterNotifications(state: RootState) {
  const notifications = state.appNotifications.default;
  const filtered = [];

  // @ts-expect-error TS(2339): Property 'service' does not exist on type 'unknown... Remove this comment to see the full error message
  for (const { service, code, values } of groupNotifications(notifications)) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const groupConfig = config[service][code];
    filtered.push(
      ...(groupConfig.filterNotifications ? groupConfig.filterNotifications(state, values) : values)
    );
  }

  return filtered;
}
