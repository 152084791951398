import { FC } from 'react';

import { SignupType } from '@/__generated__/graphql';
import Signup from '@/auth/components/Signup';
import BodyContainer from '@/components/BodyContainer';
import Layout from '@/components/Layout';
import { GroupDomain } from '@/group';

interface ExpertRegisterPageProps {
  marketingLinks: string[];
  domain?: GroupDomain;
  title: string;
  tags: string[];
  invite: string;
  defaultEmail: string;
  nextUrl: string;
}

const ExpertRegisterPage: FC<ExpertRegisterPageProps> = ({
  marketingLinks,
  domain,
  title,
  tags,
  invite,
  defaultEmail,
  nextUrl,
}) => {
  return (
    <Layout hideSearch showNewRequest={false} headerBorder={false} marketingLinks={marketingLinks}>
      <BodyContainer>
        <Signup
          domain={domain}
          nextUrl={nextUrl}
          defaultEmail={defaultEmail}
          signupType={SignupType.Expert}
          title={title}
          shortAboutText={domain?.expert_signup_about_mobile}
          longAboutText={domain?.expert_signup_about}
          tags={tags}
          invite={invite}
          signinNextUrl={domain && `/${domain.subdomain}/signup/expert`}
        />
      </BodyContainer>
    </Layout>
  );
};

export default ExpertRegisterPage;
