import clsx from 'clsx';
import { ReactNode } from 'react';

import SparklesIcon from '@/icons/sparkles.svg';

const ExpandedKnowledgeDisclaimer = ({
  className,
  knowledge,
  additionalText,
}: {
  className?: string;
  knowledge: string;
  additionalText?: string | ReactNode;
}) => {
  return (
    <div
      className={clsx(
        className,
        'rounded-sm bg-deep-purple-50/60 px-8 py-4 text-deep-purple-800 body-2'
      )}
    >
      Experiences marked with{' '}
      <SparklesIcon className="inline w-12 fill-deep-purple-500 align-sub" /> contribute to{' '}
      <span className="font-medium">{knowledge}’s</span> Skills Score using AI-powered inferences.
      {additionalText && ' '}
      {additionalText}
    </div>
  );
};

export default ExpandedKnowledgeDisclaimer;
