import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { removeAddress, setAddress } from '@/actions/address';
import { notify } from '@/actions/ui';
import {
  ErrAddressAlreadyTaken,
  ErrAddressAtLeastOne,
  ErrPhoneAlreadyExists,
} from '@/core/address';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';

import Button from '../Button/Button';
import Form from '../Form';
import FAIcon from '../Icon/FAIcon';
import PhoneInput from '../PhoneInput';
import s from './EditPhones.module.scss';

interface FormData {
  phone: string;
}

function validate(values: FormData) {
  const errors: Partial<Record<keyof FormData, string>> = {};

  if (!values.phone) {
    errors.phone = 'Required';
  }

  return errors;
}

interface PhoneProps {
  address: string;
  showRemove: boolean;
  onRemove: (phone: string) => void;
}

const Phone = ({ address, showRemove, onRemove }: PhoneProps) => {
  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove(address);
    }
  }, [address, onRemove]);

  return (
    <div className={s.phoneRoot}>
      <div className={s.phone}>
        <span className={s.address} title={address}>
          {address}
        </span>
      </div>
      <div className={s.actions}>
        {showRemove && (
          <IconButton onClick={handleRemove}>
            <FAIcon icon="trash-o" size={24} color={red500} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

interface EditPhonesProps {
  profileId: string;
}

const connector = connect(
  (state: RootState, ownProps: EditPhonesProps) => ({
    allCountries: state.countries,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    setAddress,
    removeAddress,
    notify,
  }
);

const EditPhones = ({
  profileId,
  setAddress,
  reset,
  notify,
  profile,
  allCountries,
  handleSubmit,
  removeAddress,
}: EditPhonesProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FormData, EditPhonesProps>) => {
  const { phones = [] } = profile;

  const myHandleSubmit = useCallback(
    async (values: FormData) => {
      try {
        await setAddress(profileId, 'phone', values.phone, !phones.length);
        reset();
      } catch (e: any) {
        switch (e.message) {
          case ErrAddressAlreadyTaken.message:
          case ErrPhoneAlreadyExists.message:
            notify('Phone already in use, please add a different one.', 'error');
            break;
          default:
            notify('An error occurred when adding the phone number.', 'error');
            console.warn(e);
            break;
        }
      }
    },
    [notify, phones.length, profileId, reset, setAddress]
  );

  const handleRemove = useCallback(
    async (phone: any) => {
      try {
        await removeAddress(profileId, 'phone', phone);
      } catch (e: any) {
        switch (e.message) {
          case ErrAddressAtLeastOne.message:
            notify('User must have at least one primary address', 'error');
            break;
          default:
            notify('An error occurred when removing the phone number.', 'error');
            break;
        }
      }
    },
    [notify, profileId, removeAddress]
  );

  return (
    <div>
      <div className={s.root}>
        {phones.map((e: any) => (
          <Phone
            key={e.address}
            profileId={profile.id}
            showRemove={phones.length > 1}
            {...e}
            onRemove={handleRemove}
          />
        ))}
      </div>

      <Form onSubmit={handleSubmit(myHandleSubmit)}>
        <div className={s.add}>
          <Field
            component={PhoneInput}
            type="tel"
            name="phone"
            label="Mobile Phone"
            className={s.phone}
            allCountries={allCountries}
            showExampleOnError
          />
          <Button type="submit" size="small" variant="inverted" className="mb-4">
            Add
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default reduxForm<FormData, EditPhonesProps>({
  form: 'phones',
  validate,
})(connector(EditPhones));
