/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { DeleteKnowledge } from '../models/DeleteKnowledge';
import type { DeleteKnowledgeResponse } from '../models/DeleteKnowledgeResponse';

export class DeleteKnowledgeService {
  /**
   * Delete knowledge for all experiences
   * Delete knowledge for all experiences
   * @param requestBody
   * @returns DeleteKnowledgeResponse
   * @throws ApiError
   */
  public static deleteKnowledgeCreate(
    requestBody: DeleteKnowledge
  ): CancelablePromise<DeleteKnowledgeResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/delete_knowledge/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
