export type KnowledgeLevel = 'familiar' | 'knowledgeable' | 'proficient' | 'expert';

export const LEVELS: Record<number, KnowledgeLevel> = {
  1: 'familiar',
  2: 'knowledgeable',
  3: 'proficient',
  4: 'expert',
};

export const LEVELS_BY_NAME: Record<KnowledgeLevel, number> = {
  familiar: 1,
  knowledgeable: 2,
  proficient: 3,
  expert: 4,
};

const MAX_SCORE = 4;

// LEVEL 1: familiar < 1
const LEVEL_2_THRESHOLD = 1; // knowledgeable
const LEVEL_3_THRESHOLD = 2.2; // proficient
export const EXPERT_THRESHOLD = 2.7;

const LEVEL_2_PERCENTILE_THRESHOLD = (LEVEL_2_THRESHOLD / MAX_SCORE) * 100;
const LEVEL_3_PERCENTILE_THRESHOLD = (LEVEL_3_THRESHOLD / MAX_SCORE) * 100;
export const EXPERT_PERCENTILE_THRESHOLD = (EXPERT_THRESHOLD / MAX_SCORE) * 100;

export const knowledgeScoreToPercent = (value: number) =>
  Math.min(100, Math.ceil((value / MAX_SCORE) * 1000) / 10);

export const getLevelByScore = (score: number) => {
  if (score > EXPERT_PERCENTILE_THRESHOLD) {
    return LEVELS[4];
  }

  if (score > LEVEL_3_PERCENTILE_THRESHOLD) {
    return LEVELS[3];
  }

  if (score > LEVEL_2_PERCENTILE_THRESHOLD) {
    return LEVELS[2];
  }

  return LEVELS[1];
};
