import { PureComponent } from 'react';

import { money } from '@/core/money';

import Link from '../Link';
import TransactionList from '../TransactionList';
import s from './BillingSettings.module.scss';

interface BillingProps {
  account: { transactions: { money: any }[] };
}

class Billing extends PureComponent<BillingProps> {
  render() {
    const { account } = this.props;
    const transactions = (account && account.transactions) || [];

    return (
      <div>
        <div>
          <TransactionList
            transactions={transactions.filter((t) => money(t.money).cents !== 0)}
            amountColumn={{
              header: 'Amount',
              field: 'money',
            }}
          />
        </div>
        <div className={s.questions}>
          Questions about billing? Contact our{' '}
          <Link href="https://try.onfrontiers.com/knowledge">customer care team</Link>.
        </div>
      </div>
    );
  }
}

export default Billing;
