import { fetchProjects } from '@/actions/project';
import LayoutPage from '@/components/Layout/LayoutPage';
import { isGroupContext } from '@/core/user';
import { LegacyRoute } from '@/routes/routesMiddleware';

import ExpertRequestList from './ExpertRequestList';

const route: LegacyRoute = {
  path: '/expert_requests',

  async action({ store }) {
    const { ui } = store.getState();

    const groupId = isGroupContext(ui.userContext) ? ui.userContext : undefined;

    await Promise.all([
      store.dispatch(
        fetchProjects({
          groupId,
          openOnly: false,
          memberOnly: false,
        })
      ),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    document.title = 'Expert Requests';
    return (
      <LayoutPage showNav selected="expert_requests" bodyClassName="meu-teste">
        <ExpertRequestList />
      </LayoutPage>
    );
  },
};

export default route;
