import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import CountBox from '@/components/CountBox/CountBox';
import Divider from '@/components/Divider';

const getStyles = makeStyles(() => ({
  countBox: {
    marginTop: 20,
  },
  historyTab: {
    marginTop: 20,
  },
}));

export default ({ user }: any) => {
  const s = getStyles();
  const [section, setSection] = useState('expert');

  const { consultation_count: expertConsultationCount, candidate_summary: candidateSummary } =
    user.expert_stats || {};

  const {
    vetting_count: vettingCount,
    verified_count: verifiedCount,
    matched_count: matchedCount,
  } = candidateSummary || {};

  const {
    expert_request_count: clientExpertRequestCount,
    consultation_count: clientConsultationCount,
    avg_expert_rating: clientAvgExpertRating,
  } = user.client_stats || {};

  const expertStatsComponent = (expertConsultationCount ||
    vettingCount ||
    verifiedCount ||
    matchedCount) && (
    <div>
      <CountBox grayOutZero className={s.countBox} count={vettingCount || 0} label="Vetting" />
      <CountBox grayOutZero className={s.countBox} count={verifiedCount || 0} label="Verified" />
      <CountBox grayOutZero className={s.countBox} count={matchedCount || 0} label="Matched" />
      <CountBox
        grayOutZero
        className={s.countBox}
        count={expertConsultationCount || 0}
        label="Consultations"
      />
    </div>
  );

  const clientStatsComponent = (clientExpertRequestCount ||
    clientConsultationCount ||
    clientAvgExpertRating) && (
    <div>
      <CountBox
        grayOutZero
        className={s.countBox}
        count={clientExpertRequestCount || 0}
        label="Requests Submitted"
      />
      <CountBox
        grayOutZero
        className={s.countBox}
        count={clientConsultationCount || 0}
        label="Consultations Completed"
      />
      {clientAvgExpertRating && (
        <CountBox
          grayOutZero
          className={s.countBox}
          count={clientAvgExpertRating.toFixed(1)}
          label="Avg Expert Rating"
        />
      )}
    </div>
  );

  const showActivityStats = !!expertStatsComponent || !!clientStatsComponent;

  if (!showActivityStats) return null;

  const sectionSelected = expertStatsComponent ? section : 'client';

  return (
    // @ts-expect-error TS(2339): Property 'stats' does not exist on type 'ClassName... Remove this comment to see the full error message
    <div className={s.stats}>
      <Tabs
        classes={{ root: s.historyTab }}
        value={sectionSelected}
        onChange={(_, s) => setSection(s)}
      >
        {expertStatsComponent && <Tab value="expert" label="History as Expert" />}
        {clientStatsComponent && <Tab value="client" label="History as Client" />}
      </Tabs>

      {sectionSelected === 'expert' && expertStatsComponent}
      {sectionSelected === 'client' && clientStatsComponent}

      <Divider style={{ marginBottom: 25 }} />
    </div>
  );
};
