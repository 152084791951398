import queryString from 'query-string';
import { redirect } from 'react-router-dom';

import {
  awaitingProjectMembershipApproval,
  fetchProject,
  updateProjectMember,
} from '@/actions/project';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { Viewer } from '@/core/viewer';
import NotFoundPage from '@/pages/NotFoundPage';

import { LegacyRoute } from '../routesMiddleware';
import ProjectDetails from './ProjectDetails';

function requestAdd(query: any, viewer: Viewer, id: any, accessRequested: any) {
  document.title = 'Expert Request';
  return (
    <LayoutPage showNav selected="expert_requests">
      <RequestProjectAccess
        viewer={viewer}
        path="project"
        query={query}
        projectId={id}
        accessRequested={accessRequested}
      />
    </LayoutPage>
  );
}

const routes: LegacyRoute[] = [
  {
    // private project page
    path: '/project/:id',

    async action({ store, params, query: queryRaw }) {
      const query = Object.fromEntries(queryRaw.entries());
      const { id } = params;
      const { token, approve_member: approve, deny_member: deny } = query;
      if (/(.+-[0-9]+)$/.test(id!)) {
        return redirect(`/expert_request/${id}?${queryString.stringify(query)}`);
      }

      const { viewer } = store.getState();
      if (!viewer.id) return requestAdd(query, viewer, id, false);

      let project;
      try {
        project = await store.dispatch(fetchProject(id));
      } catch (e: any) {
        if (hasErrorCode(e, ERROR_CODES.PROJECT_NOT_FOUND)) return <NotFoundPage />;

        if (!e.isPermissionError) throw e;
        const accessRequested = await store.dispatch(
          awaitingProjectMembershipApproval({ projectId: id })
        );
        return requestAdd(query, viewer, id, accessRequested);
      }

      if (token && (approve || deny)) {
        try {
          await store.dispatch(
            updateProjectMember(id, {
              id: approve || deny,
              state: approve ? 'active' : 'denied',
            })
          );
        } catch (e) {
          console.warn(e);
        }
        // we don't want to keep the token in the URL since user can share link
        return redirect(`/project/${id}`);
      }

      document.title = project.name;
      // @ts-expect-error
      return <ProjectDetails projectId={id} />;
    },
  },
  {
    // legacy new project wizard flow
    path: '/projects/new',
    async action() {
      return redirect('/request_expert');
    },
  },
];

export default routes;
