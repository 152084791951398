import Item from './Item';
import ItemContainer from './ItemContainer';
import MobileItem from './MobileItem';
import Root from './Root';
import UserMenu from './UserMenu';
import UserMenuItem from './UserMenuItem';

export const NavBar = Object.assign(Root, {
  ItemContainer,
  Item,
  UserMenu,
  UserMenuItem,
  MobileItem,
});
