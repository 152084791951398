import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';

import MaterialIcon from '@/components/Icon/MaterialIcon';
import Menu from '@/components/Menu/Menu';
import { darkBlue, darkGray, darkGreen, red500 } from '@/theme/colors';

import MatchProgress from './MatchProgress';
import MatchStateDescription from './MatchStateDescription';
import PolishRequestForm from './PolishRequestForm';

export const stateConfigurations = Object.freeze({
  suggested_by_platform: {
    label: 'Suggested',
    icon: 'lightbulb_outline',
    color: darkBlue,
    description: 'This expert has been suggested by the platform.',
  },
  suggested_by_research: {
    label: 'Suggested',
    icon: 'lightbulb_outline',
    color: darkBlue,
    description: 'This expert has been suggested by a researcher.',
  },
  contacted: {
    label: 'Contacted',
    icon: 'email',
    color: darkGreen,
    description:
      'The expert has been contacted and his interest in ' + 'the request is being evaluated.',
  },
  interested: {
    label: 'Interested',
    icon: 'sentiment_satisfied_alt',
    color: darkGreen,
    description: 'The expert is interested in this request.',
  },
  polishing: {
    label: 'Polishing',
    icon: 'brush',
    color: darkBlue,
    description: "The expert's profile is being polished.",
  },
  rejected_suggestion: {
    label: 'Rejected Suggestion',
    icon: 'lightbulb_outline',
    color: red500,
    description: 'This expert does not meet your requirements.',
  },
  vetting: {
    label: 'Vetting',
    icon: 'timelapse',
    color: darkGray,
    description: 'A research manager is verifying that this expert meets ' + 'your requirements.',
  },
  verified: {
    label: 'Verified',
    icon: 'check',
    color: darkGreen,
    description: 'A research manager has verified that this expert meets ' + 'your requirements.',
  },
  matched: {
    label: 'Matched',
    icon: 'check_circle',
    color: darkGreen,
    description: 'A member has indicated that this expert is a good match.',
  },
  rejected_by_research: {
    label: 'Rejected',
    icon: 'not_interested',
    color: red500,
    description: 'This expert does not meet your requirements.',
  },
  rejected_by_client: {
    label: 'Rejected',
    icon: 'not_interested',
    color: red500,
    description: 'This expert does not meet your requirements.',
  },
});

const useStyles = makeStyles((theme) => ({
  matchState: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    paddingLeft: 5,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',

    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
}));

export default function MatchState({
  stateConfig,
  editable,
  expertRequest,
  candidate,
  state,
  onUpdate,
}: any) {
  const s = useStyles();

  const [selectMatchStateOpen, setSelectMatchStateOpen] = useState(null);
  const [confirmPolish, setConfirmPolish] = useState(false);

  const getStateChoices = (candidate: any) => {
    const id = candidate && candidate.id;

    return [
      {
        states: ['contacted'],
        action: () => onUpdate({ id, state: 'contacted' }),
        ...stateConfigurations.contacted,
      },
      {
        states: ['interested'],
        action: () => onUpdate({ id, state: 'interested' }),
        ...stateConfigurations.interested,
      },
      {
        states: ['polishing'],
        action: () => setConfirmPolish(true),
        ...stateConfigurations.polishing,
        label: 'Send for Improvement',
      },
      {
        states: ['vetting'],
        action: () => onUpdate({ id, state: 'vetting' }),
        ...stateConfigurations.vetting,
      },
      {
        states: ['verified'],
        action: () => onUpdate({ id, state: 'verified' }),
        ...stateConfigurations.verified,
      },
      {
        states: ['matched'],
        action: () => onUpdate({ id, state: 'matched' }),
        ...stateConfigurations.matched,
      },
      {
        states: ['rejected_suggestion', 'rejected_by_client', 'rejected_by_research'],
        action: () => onUpdate({ id, state: getNextStateOnReject() }),
        ...stateConfigurations.rejected_by_research,
      },
    ]
      .filter((choice) => !choice.states.includes(candidate.state))
      .map((x) => ({
        ...x,
        action: () => {
          closeSelectMatchState();
          return x.action();
        },
      }));
  };

  const closeSelectMatchState = () => {
    setSelectMatchStateOpen(null);
  };

  const getNextStateOnReject = () => {
    const state = (candidate || {}).state || '';

    if (
      [
        'suggested_by_platform',
        'suggested_by_research',
        'contacted',
        'interested',
        'polishing',
      ].includes(state)
    ) {
      return 'rejected_suggestion';
    }

    return 'rejected_by_research';
  };

  const openSelectMatchState = (event: any) => {
    event.preventDefault();
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ anchor: any; }' is not assigna... Remove this comment to see the full error message
    setSelectMatchStateOpen({ anchor: event.currentTarget });
  };

  const suggestedByResearch = state === 'suggested_by_research';

  return (
    <div className={s.matchState} style={{ color: stateConfig.color }}>
      {!!(expertRequest.qualifications?.length || expertRequest.questions?.length) && (
        <MatchProgress
          editable={editable}
          expertRequest={expertRequest}
          candidate={candidate}
          onSubmit={onUpdate}
        />
      )}
      <MatchStateDescription
        stateConfig={stateConfig}
        editable={editable}
        candidate={candidate}
        suggestedByResearch={suggestedByResearch}
        onOpenSelectMatchState={openSelectMatchState}
      />

      {editable && (
        <Menu
          // @ts-expect-error TS(2339) FIXME: Property 'anchor' does not exist on type 'never'.
          anchorEl={selectMatchStateOpen && selectMatchStateOpen.anchor}
          onClose={closeSelectMatchState}
          placement="bottom-start"
        >
          {getStateChoices(candidate).map((config) => (
            <MenuItem key={config.states[0]} onClick={config.action} style={{ marginTop: 5 }}>
              <div className={s.matchState}>
                <MaterialIcon icon={config.icon} size={16} style={{ width: 16, marginRight: 6 }} />{' '}
                {config.label}
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
      <PolishRequestForm
        // @ts-ignore
        open={confirmPolish}
        onClose={() => setConfirmPolish(false)}
        onSubmit={({ note, priority }: any) =>
          onUpdate({ id: candidate.id, state: 'polishing', note, priority })
        }
      />
    </div>
  );
}
