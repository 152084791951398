import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import arrayMutators from 'final-form-arrays';
import { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';

import { updateGroupSavedSearches } from '@/actions/group';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { TextField } from '@/components/FormAdapters/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import { red500 } from '@/theme/colors';
import { interceptEnter, normalizeSpace } from '@/utils';
import { isValidURL } from '@/utils/reducer';

function SavedSearches({ groupId, group, internalNetwork, updateGroupSavedSearches, notify }: any) {
  const initialValues = useMemo(() => {
    return { saved_searches: group.saved_searches };
  }, [group, internalNetwork]);

  const required = useCallback((value: any) => (value ? undefined : 'Required'), []);

  const validUrl = useCallback((url: any) => (!isValidURL(url) ? 'Must be a valid URL' : null), []);

  const handleSubmit = async (values: any) => {
    try {
      await updateGroupSavedSearches(groupId, {
        ...values,
      });
      notify('Saved searches updated.', 'success');
    } catch (err) {
      notify('Error when updating saved searches.', 'error');
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ form, submitting, pristine, handleSubmit }) => {
        const { push } = form.mutators;
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Button
              color="secondary"
              style={{ marginBottom: 20 }}
              onClick={() => push('saved_searches', {})}
              size="medium"
            >
              Add search
            </Button>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="saved_searches">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <TableRow key={name}>
                          <TableCell>
                            <Field
                              component={TextField}
                              inputProps={{ maxLength: 100 }}
                              name={`${name}.name`}
                              onKeyPress={interceptEnter}
                              validate={required}
                              parse={(v) => normalizeSpace(v)}
                              changeOnBlur={false}
                            />

                            <Field
                              component={TextField}
                              style={{ display: 'none' }}
                              name={`${name}.id`}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              component={TextField}
                              fullWidth
                              inputProps={{ maxLength: 800 }}
                              name={`${name}.url`}
                              onKeyPress={interceptEnter}
                              validate={validUrl}
                              parse={(v) => v}
                              changeOnBlur={false}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={() => fields.remove(index)}
                            >
                              <FAIcon color={red500} icon="trash" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              type="submit"
              disabled={submitting || pristine}
              style={{ marginTop: 40 }}
              size="medium"
            >
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'SavedSearches' because it is a f... Remove this comment to see the full error message
SavedSearches = connect(
  // @ts-expect-error TS(2339) FIXME: Property 'groupId' does not exist on type '{}'.
  (state, { groupId }) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const group = state.groups.default.edges.find((e: any) => e.node.id === groupId).node;
    return {
      group,
    };
  },
  {
    updateGroupSavedSearches,
    notify,
  }
)(SavedSearches);

export default SavedSearches;
