import React from 'react';

import ConsultationRef from './ConsultationRef';
import UserRef from './UserRef';

function ConsultationActivity({
  state,

  activity: {
    context: { expert, requester, consultation },
  },
}: any) {
  switch (state) {
    case 'completed':
    case 'confirmed':
      // Special renderer for confirmed and completed states
      return (
        <div>
          <UserRef user={expert} /> {state} a <ConsultationRef consultation={consultation} />{' '}
          requested by <UserRef user={requester} link />
        </div>
      );
    default:
      return (
        <div>
          A <ConsultationRef consultation={consultation} /> requested by{' '}
          <UserRef user={requester} link /> with <UserRef user={expert} /> got its state moved to
          &quot;{state}&quot;
        </div>
      );
  }
}

export default ConsultationActivity;
