import makeStyles from '@mui/styles/makeStyles';
import { FC, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { PasswordText } from '@/auth/components/PasswordTextField';
import { removeOtpAuth } from '@/auth/store';
import Dialog from '@/components/Dialog';

const ERROR_INVALID_PASSWORD = 'GraphQL Error: invalid password';

const useStyles = makeStyles({
  title: {
    marginTop: 0,
  },
  password: {
    marginTop: 10,
  },
});

interface DisableOtpAuthProps {
  open: boolean;
  user: any;
  onClose: () => void;
  onDisable: () => void;
  refetch: () => Promise<any>;
}

const connector = connect(undefined, {
  removeOtpAuth,
  notify,
});

const DisableOtpAuth: FC<DisableOtpAuthProps & ConnectedProps<typeof connector>> = ({
  user,
  removeOtpAuth,
  notify,
  onClose,
  onDisable,
  refetch,
  open,
}) => {
  const s = useStyles();

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await removeOtpAuth(user.id, values.password);
        refetch();
        onClose();
        if (onDisable) onDisable();
      } catch (err) {
        if (err instanceof Error && err.message === ERROR_INVALID_PASSWORD) {
          return { password: 'Password is incorrect' };
        }
        notify('An error occurred when trying to remove two-factor authentication.', 'error');
        throw err;
      }
    },
    [refetch, notify, onClose, onDisable, removeOtpAuth, user.id]
  );

  const validate = useCallback((values: any) => {
    const errors = {};

    if (!(values.password || '').trim()) {
      // @ts-expect-error TS(2339): Property 'password' does not exist on type '{}'.
      errors.password = 'Required';
    }

    return errors;
  }, []);

  return (
    <Form onSubmit={handleSubmit} validate={validate} subscription={{ submitting: true }}>
      {({ handleSubmit, submitting }: any) => {
        return (
          <Dialog
            open={open}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={handleSubmit}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Yes, disable"
            confirmButtonProps={{ color: 'red' }}
            maxWidth="xs"
          >
            <form onSubmit={(e: any) => handleSubmit(e)}>
              <h4 className={s.title}>
                Are you sure you want to disable two-factor authentication?
              </h4>
              <Field
                component={PasswordText}
                name="password"
                label="Re-enter Password"
                variant="outlined"
                classes={{ root: s.password }}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
};

export default connector(DisableOtpAuth);
