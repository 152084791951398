import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { SavedSearchService, ShortlistCollaborator } from '@/openapi';

export const useAddSearchCollaborator = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, profiles }: { id: string; profiles: ShortlistCollaborator[] }) =>
      SavedSearchService.savedSearchCollaboratorsCreate(id, {
        profile_ids: profiles.map((profile) => profile.profile_id),
      }),
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(['saved-search-collaborators', { id }]);
    },
    onError: () => {
      enqueueSnackbar('Failed to add as a collaborator, please contact support', {
        variant: 'error',
      });
    },
  });
};

export const useRemoveSearchCollaborator = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, profileId }: { id: string; profileId: string }) =>
      SavedSearchService.savedSearchCollaboratorsDestroy(id, profileId),
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(['saved-search-collaborators', { id }]);
      queryClient.invalidateQueries(['saved-searches']);
    },
    onError: () => {
      enqueueSnackbar('Failed to remove, please contact support', {
        variant: 'error',
      });
    },
  });
};
