import { circularProgressClasses } from '@mui/material/CircularProgress';

const ns = 'button';

export const buttonClasses = {
  root: `${ns}-root`,
  contained: 'contained',
  shadowed: 'shadowed',
  withIcon: 'withIcon',
  large: 'large',
  medium: 'medium',
  small: 'small',
  label: ns + '-label',
};

export const styles = {
  button: {
    borderRadius: 2,
    [`&.${buttonClasses.contained}`]: {
      '&:disabled': {
        color: '#ffffff',
      },
      [`&.${buttonClasses.shadowed}`]: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px -2px 0px rgba(0, 0, 0, 0.2)',
      },
    },
    [`&.${buttonClasses.large}`]: {
      minWidth: '117px',
      height: '70px',
      [`&.${buttonClasses.withIcon}`]: {
        minWidth: '140px',
      },
    },
    [`&.${buttonClasses.medium}`]: {
      [`&.${buttonClasses.withIcon}`]: {
        minWidth: '120px',
      },
    },
    [`&.${buttonClasses.small}`]: {
      minWidth: '79px',
      height: '36px',
      [`&.${buttonClasses.withIcon}`]: {
        minWidth: '102px',
      },
    },
    [`& .${circularProgressClasses.root}`]: {
      color: 'inherit',
    },
  },
};
