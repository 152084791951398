// Harness colors
export const colors = {
  primary: {
    main: '#005755', //teal900
    light: '#28BEAF', //teal500
    dark: '#003c3d', //teal950
  },
  secondary: {
    main: '#2389C1',
    light: '#2DA1E2',
    dark: '#1E6D9A',
    states: {
      'focus-visible': 'rgba(35, 137, 193, 0.30)',
      selected: 'rgba(35, 137, 193, 0.08)',
    },
  },
  tertiary: {
    main: '#00646e',
  },
  error: {
    main: '#E94F74',
    light: '#FF5C83',
    dark: '#B43A57',
  },
  warning: {
    main: '#ED6C02',
    light: '#E65100',
    dark: '#FF9800',
  },
  success: {
    main: '#1DC068',
  },
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',
  current: 'currentColor',
  green: {
    50: '#f5faf3',
    100: '#edf8e9',
    200: '#d1ebc7',
    300: '#aadb9a',
    400: '#7dc167',
    500: '#5aa641',
    600: '#468831',
    700: '#3a6b2a',
    800: '#305625',
    900: '#284720',
    950: '#12260d',
  },
  yellow: {
    50: '#fcf7ee',
    100: '#f7eace',
    200: '#eed399',
    300: '#e5b864',
    400: '#dea041',
    500: '#d6822a',
    600: '#b15d20',
    700: '#9d4820',
    800: '#803920',
    900: '#6a301d',
    950: '#3c170c',
  },
  purple: {
    50: '#faf5ff',
    100: '#f2e8ff',
    200: '#e7d4ff',
    300: '#d5b3ff',
    400: '#ba82fe',
    500: '#a052fa',
    600: '#8b30ed',
    700: '#761fd1',
    800: '#6920b1',
    900: '#531a89',
    950: '#370566',
  },
  red: {
    50: '#fef2f2',
    100: '#fde3e3',
    200: '#fccccc',
    300: '#f9a8a8',
    400: '#f47575',
    500: '#ea4949',
    600: '#d72b2b',
    700: '#b12020',
    800: '#951f1f',
    900: '#7c2020',
    950: '#430c0c',
  },
  teal: {
    50: '#ecfffd',
    100: '#bdfffd',
    200: '#7bfffc',
    300: '#31fffc',
    400: '#00fff2',
    500: '#00edda',
    600: '#00bfb5',
    700: '#009791',
    800: '#007774',
    900: '#005755',
    950: '#003c3d',
  },
  gray: {
    50: '#f6f7f7',
    100: '#e5ebe9',
    200: '#c0cfca',
    300: '#99afa8',
    400: '#748d87',
    500: '#59736d',
    600: '#465b56',
    700: '#3b4a47',
    800: '#323d3c',
    900: '#2c3533',
    950: '#161d1c',
  },
  grey: {
    50: '#FCFCFC',
    100: '#F7F9FA',
    200: '#F2F4F4',
    300: '#E1EDF1',
    400: '#C8D4DA',
    500: '#879499',
    600: '#545D63',
    700: '#343E45',
    800: '#2E404A',
    900: '#151616',
  },
  'deep-purple': {
    50: '#ede7f6',
    100: '#d1c4e9',
    200: '#b39ddb',
    300: '#9575cd',
    400: '#7e57c2',
    500: '#673ab7',
    600: '#5e35b1',
    700: '#512da8',
    800: '#4527a0',
    900: '#311b92',
  },
};

// Access Colors
// https://projects.invisionapp.com/d/main#/console/11241705/348769977/preview
export const white = '#FFFFFF';
export const black = '#000000';
export const teal500 = '#28BEAF';
export const teal900 = '#005755';
export const orange500 = '#FFA21D';
export const red500 = '#FB3544';
export const darkGray = '#787878';
export const darkBrown = '#2D2D2D';
export const lightTan = '#E6E6D7';
export const lightBlue = '#CCDEEC';
export const mediumBlue = '#6895B8';
export const darkBlue = '#28465A';
export const darkGreen = '#00646E';
export const sand = '#F2F2EB';
export const porpita = '#2389C1';
export const primary = colors.primary.main;
export const primaryLight = colors.primary.light;
export const primaryDark = colors.primary.dark;

// Off palette colors
export const grey50 = '#f6f7f7';
export const grey100 = '#E5EBE9';
export const grey300 = '#ECECEC';
export const skype = '#00AFF0';
export const linkedIn = '#0077B5';
export const borderColor = 'rgba(0, 0, 0, 0.2)';
export const borderColorHex = '#D2D2D2'; // equivalent to borderColor
export const highlightedExpert = '#F2F7F8';
export const aboutEmployeeRole = '#999999';
export const emailContentTitle = '#4A4A4A';
export const whiteTransparent = 'rgba(255, 255, 255, 0.3)';
export const blue = '#00BCD4';

export default {
  primary: colors.primary.main,
  primaryLight: colors.primary.light,
  primaryDark: colors.primary.dark,
  white,
  black,
  teal500,
  teal900,
  orange500,
  red500,
  darkGray,
  darkBrown,
  lightTan,
  lightBlue,
  mediumBlue,
  darkBlue,
  darkGreen,
  sand,
  porpita,
  grey300,
  skype,
  linkedIn,
  borderColor,
  borderColorHex,
  highlightedExpert,
  aboutEmployeeRole,
  emailContentTitle,
  whiteTransparent,
};
