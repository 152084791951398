import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@/componentsv2/Drawer';

const MobileNavDrawer = ({
  children,
  open,
  handleClose,
}: PropsWithChildren<{
  open: boolean;
  handleClose: () => void;
}>) => {
  const location = useLocation();
  useEffect(() => {
    handleClose();
  }, [handleClose, location]);

  return (
    <Drawer open={open} onClose={handleClose} size="xs">
      <Drawer.Header breadcrumbs={[{ title: 'Menu' }]} onClose={handleClose} />
      <Drawer.Main bgClassName="bg-grey-50">
        <nav aria-label="Site Navigation">
          <ul className="space-y-2 !text-20 text-brand-tertiary hd-4">{children}</ul>
        </nav>
      </Drawer.Main>
    </Drawer>
  );
};

export default MobileNavDrawer;
