import React from 'react';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import AddUserDialog from '@/components/AddUserDialog';

function AddTeamMemberDialog({
  groupId,
  addTeamMember,
  inviteUser,
  notify,
  onRequestDone,
  ...rest
}: any) {
  const done = (ref: any) => {
    if (onRequestDone) {
      onRequestDone(ref);
    }
  };

  const handleRequestAdd = (values: any) => {
    const usersAdded = values
      .filter((v: any) => v.type === 'user')
      .map((u: any) => ({
        userId: u.value.id,
        name: u.value.name,
        role: 'member',
      }))
      .map((member: any) => ({
        ref: member.name,
        promise: addTeamMember(groupId, member),
      }));

    const usersInvited = values
      .filter((v: any) => v.type === 'email')
      .map((v: any) => ({
        email: v.value.email,
        collectionType: 'group',
        collectionId: groupId,
        role: 'member',
      }))
      .map((member: any) => ({
        ref: member.email,
        promise: inviteUser(member),
      }));

    [...usersAdded, ...usersInvited].forEach(({ ref, promise }) => {
      promise.then(
        () => {
          done(ref);

          notify(`${ref} added successfully.`, 'success', 3000);
        },
        (e: any) => {
          done(ref);

          if (e.isPermissionError) {
            notify(`You don't have permission to add ${ref}}.`, 'error', 3000);
          } else if (e.message === 'GraphQL Error: member already exists') {
            notify(`${ref} was already a member`, 'warning', 3000);
          } else if (e.message && e.message.startsWith('GraphQL Error: user with email')) {
            notify(`${ref} already a user, no invitation sent`, 'error', 3000);
          } else {
            notify(`An error occurred when adding ${ref}.`, 'error', 3000);
          }
        }
      );
    });
  };

  return (
    <AddUserDialog title="Add team member" allowEmails onRequestAdd={handleRequestAdd} {...rest} />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'AddTeamMemberDialog' because it ... Remove this comment to see the full error message
AddTeamMemberDialog = connect(undefined, {
  notify,
})(AddTeamMemberDialog);

export default AddTeamMemberDialog;
