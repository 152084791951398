import React from 'react';

import CheckboxFilter from '../CheckboxFilter';
import SelectFilter from '../SelectFilter';

interface AdminFiltersProps {
  query: {
    agentIds?: string[];
    mustHaveEmail?: boolean;
    expertStates?: string[];
    marketplacePreference?: string;
    expertHistory?: string[];
  };
  handleFilterEvent: (key: string, value: any) => void;
  search: (filters: { mustHaveEmail?: boolean }) => void;
}

export default function AdminFilters({ query, handleFilterEvent, search }: AdminFiltersProps) {
  return (
    <>
      <SelectFilter
        multiple
        title="Source"
        placeholder="Select sources"
        value={query.agentIds || []}
        onChange={(e: any) => handleFilterEvent('agentIds', e)}
        options={[
          { value: 'tetratech-ftps', label: 'Tetra Tech' },
          { value: 'csv-import', label: 'CSV' },
        ]}
      />

      <CheckboxFilter
        checked={query.mustHaveEmail}
        label="Has Email?"
        onChange={(_: any, v: any) => search({ mustHaveEmail: v })}
      />

      <SelectFilter
        multiple
        title="Expert State"
        placeholder="Select expert states"
        value={query.expertStates || []}
        onChange={(e: any) => handleFilterEvent('expertStates', e)}
        options={[
          { value: 'active', label: 'Active' },
          { value: 'applying', label: 'Applying' },
          { value: 'applied', label: 'Applied' },
          { value: 'denied', label: 'Denied' },
          { value: 'inactive', label: 'Inactive' },
        ]}
      />

      <SelectFilter
        title="Marketplace Status"
        value={query.marketplacePreference}
        onChange={(e: any) => handleFilterEvent('marketplacePreference', e)}
        options={[
          { value: 'none', label: 'No preference' },
          { value: 'opted_in', label: 'Opted In' },
          { value: 'opted_out', label: 'Opted Out' },
        ]}
      />

      <SelectFilter
        multiple
        title="Expert History"
        placeholder="Select expert history"
        value={query.expertHistory || []}
        onChange={(e: any) => handleFilterEvent('expertHistory', e)}
        options={[
          {
            value: 'completed_consultation',
            label: 'Completed Consultation',
          },
          { value: 'matched', label: 'Matched by Client' },
          { value: 'verified', label: 'Verified by RM' },
          { value: 'vetting', label: 'Vetting by RM' },
        ]}
      />
    </>
  );
}
