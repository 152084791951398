import theme from '@/theme';

const ns = 'drag-and-drop-zone';

export const dragAndDropZoneClasses = {
  root: `${ns}__root`,
  dropZone: `${ns}__drop-zone`,
  dragActive: `${ns}__drag-active`,
};

const { dropZone, dragActive } = dragAndDropZoneClasses;

const styles = {
  dragAndDropZone: {
    maxWidth: '100%',
    textAlign: 'center' as const,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column' as const,
    rowGap: '24px',
    cursor: 'pointer',
    fontFamily: 'Inter',

    [`& .${dropZone}`]: {
      backgroundColor: theme.palette.common.white,
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='rgba(35, 137, 193, 0.5)' stroke-width='4' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      borderRadius: 4,
      cursor: 'pointer',
      display: 'grid',
      placeContent: 'center',
      textAlign: 'center',
      aspectRatio: '2/1',
      width: '100%',
      '& svg': {
        margin: '0 auto 10px',
      },
      [`&.${dragActive}`]: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23${theme.palette.primary.main.slice(
          1
        )}' stroke-width='4' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        boxShadow: 'inset 0 0 20px rgba(40,190,175,0.25)',
      },
    },
  },
};

export default styles;
