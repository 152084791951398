import { useSuspenseQuery } from '@apollo/client';
import { Badge } from '@mui/material';
import { MetaData } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import cx from 'classnames';
import moment from 'moment-timezone';
import { FC } from 'react';

import { gengql } from '@/__generated__';
import { useAuth } from '@/auth';
import SvgIcon from '@/components/Icon/SvgIcon';
import Picture from '@/components/Picture';
import { isAnonymousUser } from '@/messaging/sendbird';
import { black, darkGreen, white } from '@/theme/colors';

import { findOtherMember } from '../utils';
import s from './ChannelListItem.module.scss';
import OnlineStatus from './OnlineStatus';
import TypingIndicator from './TypingIndicator';

interface ChannelProps {
  channel: GroupChannel;
  metadata: MetaData;
  isTyping?: boolean;
  selected?: boolean;
  onSelect?: (channel: GroupChannel) => void | Promise<void>;
}

const GET_USER = gengql(/* GraphQL */ `
  query getChannelListItemUser($id: String!) {
    user(id: $id) {
      id
      profile {
        id
        name
        first_name
        last_name
        picture_url
        html_url
      }
    }
  }
`);

const ChannelListItem: FC<ChannelProps> = ({
  channel,
  metadata,
  isTyping = false,
  selected = false,
  onSelect,
}) => {
  const { viewer } = useAuth();
  if (!viewer.id) throw new Error('Viewer must be logged in');

  const m = findOtherMember(channel.members, viewer.id);
  const isOtherAnonymous = isAnonymousUser(metadata, m.userId);
  const isAnonymous = isAnonymousUser(metadata, viewer.id);

  const { data } = useSuspenseQuery(GET_USER, {
    variables: {
      id: m.userId,
    },
    skip: isOtherAnonymous,
  });

  const handleClick = () => {
    if (onSelect) onSelect(channel);
  };

  const user = isOtherAnonymous ? null : data?.user;
  const profile = user?.profile;

  const lastMessage = channel.lastMessage;
  const messageDate = lastMessage ? new Date(lastMessage.createdAt) : new Date();
  const format = moment(messageDate).startOf('day').isSame(moment().startOf('day'))
    ? 'h:mma'
    : 'D MMM';

  const unreadCount = channel.unreadMessageCount;

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleClick}
      className={cx(s.channel, {
        [s.channelSelected]: selected,
        [s.unreadMessage]: unreadCount > 0,
      })}
    >
      <div className={s.picture}>
        <Picture user={profile} />
        {m.connectionStatus === 'online' && <OnlineStatus />}
      </div>

      <div className={s.content}>
        <div>{profile ? profile.name : 'Confidential Client'}</div>
        {lastMessage && <div className={s.date}>{moment(messageDate).format(format)}</div>}
      </div>

      <div className={s.actions}>
        {unreadCount > 0 ? (
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          <Badge
            style={{ padding: 0, verticalAlign: 'middle' }}
            badgeStyle={{
              position: 'initial',
              backgroundColor: selected ? white : darkGreen,
              color: selected ? darkGreen : white,
            }}
            badgeContent={unreadCount}
          />
        ) : isTyping ? (
          <TypingIndicator />
        ) : (
          isAnonymous && (
            <SvgIcon icon="incognito" width={25} height={25} fill={selected ? white : black} />
          )
        )}
      </div>
    </div>
  );
};

export default ChannelListItem;
