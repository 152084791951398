import { gengql } from '@/__generated__';

export const PROFILE_FIELDS = gengql(/* GraphQL */ `
  fragment ProfileFields on Profile {
    id
    expert_state
    conflicted
    first_name
    last_name
    name
    html_url
    url_endpoint
    linkedin_username
    linkedin_url
    title
    summary
    skype
    timezone
    city
    country
    picture_url
    cover_url
    questions
    cv_url
    available_long_term
    available_marketplace
    hide_profile
    bill_rate
    credit_rate
    keywords
    tier
    additional_information
    languages {
      code
      fluency
    }
  }
`);

export const FETCH_PROFILE = gengql(/* GraphQL */ `
  query actionFetchProfile(
    $id: String!
    $connectionStates: [NetworkExpertConnectionState!]
    $rawPictureUrl: Boolean!
    $expertise: Boolean!
    $groupKeywords: Boolean!
    $addresses: Boolean!
    $experiences: Boolean!
    $education: Boolean!
    $expertRequestCandidates: Boolean!
    $internalNetworks: Boolean!
    $internalNetworkConsultations: Boolean!
    $groupAboutPage: Boolean!
    $sources: Boolean!
    $audit: Boolean!
  ) {
    profile(id: $id) {
      ...ProfileFields
      id
      user {
        id
      }
      raw_picture_url @include(if: $rawPictureUrl)
      sectors @include(if: $expertise) {
        id
        name
      }
      regions @include(if: $expertise) {
        id
        name
        country_iso2_code
      }
      group_keywords @include(if: $groupKeywords) {
        group {
          id
          name
        }
        keywords {
          id
          name
        }
      }
      emails @include(if: $addresses) {
        address
        display
        primary
        verified
        accepted
        confirmed
      }
      phones @include(if: $addresses) {
        address
        display
        primary
        verified
        accepted
      }
      experiences @include(if: $experiences) {
        id
        title
        start_date
        end_date
        description
        organization
        linkedin_url
        location
        current
        role
      }
      education @include(if: $education) {
        id
        degree
        field_of_study
        school
        start_date
        end_date
        description
      }
      expert_request_id @include(if: $expertRequestCandidates)
      expert_request_candidates @include(if: $expertRequestCandidates) {
        id
        request_id
        state
      }
      expert_internal_networks(connection_states: $connectionStates)
        @include(if: $internalNetworks) {
        id
        network {
          id
          name
          group {
            id
            city
            country
            about_page @include(if: $groupAboutPage) {
              html_url
            }
            domain {
              id
              agreements(active_only: true) {
                id
                policy_code
                policy_label
                policy_url
              }
            }
          }
        }
        add_method
        participation_level
        connection_state
        from_group {
          id
          name
        }
        consultations @include(if: $internalNetworkConsultations) {
          id
          html_url
          requester {
            id
            name
          }
        }
      }
      sources @include(if: $sources) {
        agent_id
        agent_profile_id
        source_id
        source_profile_id
        created_by {
          id
          name
          html_url
        }
        created_at
        network_id
      }
      created_at @include(if: $audit)
      created_by @include(if: $audit) {
        id
        name
        html_url
      }
      updated_at @include(if: $audit)
      updated_by @include(if: $audit) {
        id
        name
        html_url
      }
    }
  }
`);

export const UPDATE_PROFILE = gengql(/* GraphQL */ `
  mutation actionUpdateProfile(
    $id: String!
    $first_name: String
    $last_name: String
    $linkedin_username: String
    $linkedin_url: String
    $title: String
    $summary: String
    $skype: String
    $timezone: String
    $city: String
    $country: String
    $picture_url: String
    $cover_url: String
    $keywords: [String]
    $keywords_to_add: [String]
    $sector_ids: [ID!]
    $region_ids: [ID!]
    $languages: [LanguageInput]
    $questions: [String]
    $cv_url: String
    $bill_rate: Int
    $available_long_term: Boolean
    $available_marketplace: Boolean
    $hide_profile: Boolean
    $tier: Int
    $additional_information: String
  ) {
    updateProfile(
      id: $id
      first_name: $first_name
      last_name: $last_name
      linkedin_username: $linkedin_username
      linkedin_url: $linkedin_url
      title: $title
      summary: $summary
      skype: $skype
      timezone: $timezone
      city: $city
      country: $country
      picture_url: $picture_url
      cover_url: $cover_url
      keywords: $keywords
      keywords_to_add: $keywords_to_add
      sector_ids: $sector_ids
      region_ids: $region_ids
      languages: $languages
      questions: $questions
      cv_url: $cv_url
      bill_rate: $bill_rate
      available_long_term: $available_long_term
      available_marketplace: $available_marketplace
      hide_profile: $hide_profile
      tier: $tier
      additional_information: $additional_information
    ) {
      ...ProfileFields
      id
      sectors {
        id
        name
      }
      regions {
        id
        name
        country_iso2_code
      }
    }
  }
`);

export const MERGE_PROFILES = gengql(/* GraphQL */ `
  mutation actionMergeProfiles(
    $destination_id: String!
    $source_id: String!
    $first_name: String
    $last_name: String
    $linkedin_username: String
    $linkedin_url: String
    $title: String
    $summary: String
    $skype: String
    $timezone: String
    $city: String
    $country: String
    $picture_url: String
    $emails: [String]
    $phones: [String]
    $keywords: [String]
    $sector_ids: [ID!]
    $region_ids: [ID!]
    $languages: [LanguageInput]
    $experiences: [ExperienceInput]
    $education: [EducationInput]
    $questions: [String]
    $cv_url: String
    $available_marketplace: Boolean
    $internal_networks_ids: [ID!]
    $networks_ids: [String!]
    $expert_request_candidate_ids: [ID!]
    $expert_request_id: String
  ) {
    mergeProfiles(
      destination_id: $destination_id
      source_id: $source_id
      first_name: $first_name
      last_name: $last_name
      linkedin_username: $linkedin_username
      linkedin_url: $linkedin_url
      title: $title
      summary: $summary
      skype: $skype
      timezone: $timezone
      city: $city
      country: $country
      picture_url: $picture_url
      emails: $emails
      phones: $phones
      keywords: $keywords
      sector_ids: $sector_ids
      region_ids: $region_ids
      languages: $languages
      experiences: $experiences
      education: $education
      questions: $questions
      cv_url: $cv_url
      available_marketplace: $available_marketplace
      internal_networks_ids: $internal_networks_ids
      networks_ids: $networks_ids
      expert_request_candidate_ids: $expert_request_candidate_ids
      expert_request_id: $expert_request_id
    ) {
      ...ProfileFields
      id
      html_url
      emails {
        address
        display
        primary
        verified
        accepted
        confirmed
      }
      phones {
        address
        display
        primary
        verified
        accepted
      }
      experiences {
        id
        title
        start_date
        end_date
        description
        organization
        linkedin_url
        location
        current
        role
      }
      education {
        id
        degree
        field_of_study
        school
        start_date
        end_date
        description
      }
      expert_request_id
      expert_request_candidates {
        id
        request_id
        state
      }
      sources {
        agent_id
        agent_profile_id
        source_id
        source_profile_id
        created_by {
          id
          name
          html_url
        }
        created_at
        network_id
      }
      created_at
      created_by {
        id
        name
        html_url
      }
      updated_at
      updated_by {
        id
        name
        html_url
      }
    }
  }
`);

export const FETCH_CONFLICTS = gengql(/* GraphQL */ `
  query actionFetchConflicts($cursor: String, $pageSize: Int!) {
    profiles(after: $cursor, conflicts: true, first: $pageSize) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          user {
            id
          }
          sources(only_last: true) {
            agent_id
            agent_profile_id
            source_id
            source_profile_id
            created_at
            created_by {
              id
              name
            }
          }
          created_at
          name
          html_url
          url_endpoint
          expert_request_id
          conflicting {
            conflicting_ids
            profile {
              id
              user {
                id
              }
              sources(only_last: true) {
                agent_id
                agent_profile_id
                source_id
                source_profile_id
                created_at
                created_by {
                  id
                  name
                }
              }
              created_at
              name
              html_url
              url_endpoint
            }
          }
        }
      }
    }
  }
`);

export const CONFLICTS_COUNT = gengql(/* GraphQL */ `
  query fetchProfilesCount {
    profilesCount(conflicts: true)
  }
`);

export const CSV_IMPORT_PREVIEW = gengql(/* GraphQL */ `
  query actionProfileCsvImportPreview(
    $url: String!
    $networkId: String
    $availableMarketplace: Boolean
  ) {
    profileCsvImportPreview(
      url: $url
      network_id: $networkId
      available_marketplace: $availableMarketplace
    ) {
      url
      profiles {
        row
        name
        email
        linkedin_url
        phone
        resolution
        expert_request_id
        warnings
        errors
      }
    }
  }
`);

export const CSV_IMPORT = gengql(/* GraphQL */ `
  mutation actionCsvImport(
    $filename: String
    $url: String!
    $ignoreRows: [Int]
    $createdBy: String
    $requestId: String
    $networkId: String
    $availableMarketplace: Boolean
  ) {
    profileCsvImport(
      filename: $filename
      url: $url
      ignore_rows: $ignoreRows
      request_id: $requestId
      created_by: $createdBy
      network_id: $networkId
      available_marketplace: $availableMarketplace
    ) {
      url
      profiles {
        row
        name
        email
        linkedin_url
        phone
        resolution
        warnings
        errors
      }
    }
  }
`);

export const DELETE_PROFILE = gengql(/* GraphQL */ `
  mutation actionDeleteProfile($id: String!) {
    deleteProfile(id: $id)
  }
`);

export const AUTOFILL_PROFILE = gengql(/* GraphQL */ `
  mutation actionAutoFillProfile($autofill: Boolean!, $cvUrl: String) {
    autofillProfile(autofill: $autofill, cv_url: $cvUrl) {
      id
    }
  }
`);

export const UPDATE_EXPERIENCE = gengql(/* GraphQL */ `
  mutation actionUpdateExperience(
    $id: String!
    $title: String
    $role: String
    $description: String
    $organization: String
    $location: String
    $start_date: Date
    $end_date: Date
    $current: Boolean
  ) {
    updateExperience(
      id: $id
      title: $title
      role: $role
      description: $description
      organization: $organization
      location: $location
      start_date: $start_date
      end_date: $end_date
      current: $current
    ) {
      id
      title
      role
      description
      organization
      location
      start_date
      end_date
      current
    }
  }
`);

export const ADD_EXPERIENCE = gengql(/* GraphQL */ `
  mutation actionAddExperience(
    $profile_id: String!
    $title: String
    $role: String
    $description: String
    $organization: String
    $location: String
    $start_date: Date
    $end_date: Date
    $current: Boolean
  ) {
    addExperience(
      profile_id: $profile_id
      title: $title
      role: $role
      description: $description
      organization: $organization
      location: $location
      start_date: $start_date
      end_date: $end_date
      current: $current
    ) {
      id
      title
      role
      description
      organization
      location
      start_date
      end_date
      current
    }
  }
`);

export const REMOVE_EXPERIENCE = gengql(/* GraphQL */ `
  mutation actionRemoveExperience($id: String!) {
    removeExperience(id: $id) {
      id
    }
  }
`);

export const UPDATE_EDUCATION = gengql(/* GraphQL */ `
  mutation actionUpdateEducation(
    $id: String!
    $degree: String
    $field_of_study: String
    $school: String
    $start_date: Date
    $end_date: Date
    $description: String
  ) {
    updateEducation(
      id: $id
      degree: $degree
      field_of_study: $field_of_study
      school: $school
      start_date: $start_date
      end_date: $end_date
      description: $description
    ) {
      id
      degree
      field_of_study
      description
      start_date
      end_date
      school
    }
  }
`);

export const ADD_EDUCATION = gengql(/* GraphQL */ `
  mutation actionAddEducation(
    $profile_id: String!
    $degree: String
    $field_of_study: String
    $school: String
    $start_date: Date
    $end_date: Date
    $description: String
  ) {
    addEducation(
      profile_id: $profile_id
      degree: $degree
      field_of_study: $field_of_study
      school: $school
      start_date: $start_date
      end_date: $end_date
      description: $description
    ) {
      id
      degree
      field_of_study
      description
      start_date
      end_date
      school
    }
  }
`);

export const REMOVE_EDUCATION = gengql(/* GraphQL */ `
  mutation actionRemoveEducation($id: String!) {
    removeEducation(id: $id) {
      id
    }
  }
`);
