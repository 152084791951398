import ActionTypes from '@/actions/ActionTypes';

const { LANDING_PAGE__UPDATE, LANDING_PAGE__REMOVE, LANDING_PAGE__BATCH_ADD } = ActionTypes;

const initialState = {
  all: [],
  full: {},
};

function landingPageReducer(state = {}, action: any) {
  switch (action.type) {
    case LANDING_PAGE__UPDATE:
      return {
        ...state,
        [action.path]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.path],
          ...action.page,
        },
      };
    default:
      return state;
  }
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case LANDING_PAGE__UPDATE:
      const newState = {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [action.subdomain]: landingPageReducer(state[action.subdomain], action),
      };

      if (action.page && action.page.id) {
        newState.full = {
          ...state.full,
          [action.page.id]: action.page,
        };

        // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
        newState.all = state.all.map((page) => {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          if (page.id === action.page.id) {
            return {
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              ...page,
              ...action.page,
            };
          }
          return page;
        });
      }

      return newState;
    case LANDING_PAGE__REMOVE:
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        all: state.all.filter((p) => p.id !== action.id),
      };
    case LANDING_PAGE__BATCH_ADD:
      return {
        ...state,
        all: action.pages,
      };
    default:
      return state;
  }
}
