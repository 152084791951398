type structure = {
  id?: string | null;
  name?: string | null;
  label?: string;
};
export const convertToSelectOptions = (data: structure[], ifNoResults?: structure) => {
  const results = data.map((data) => ({
    ...data,
    id: data.id ?? data.name ?? '0',
    label: String(data.label || data.name || data.id),
    name: data.name ?? String(data.id),
  }));

  if (!results.length && ifNoResults) {
    results.push({
      id: String(ifNoResults.id),
      label: String(ifNoResults.label ?? ifNoResults.name ?? ifNoResults.id),
      name: ifNoResults.name ?? String(ifNoResults.label),
    });
  }

  return results;
};

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export function parseEnum<T extends { [key: string]: string | number }>(
  enumObj: T,
  value: any
): T[keyof T] | undefined {
  if (value === undefined || value === null) return undefined;

  for (const key in enumObj) {
    if (enumObj[key] === value || key === value) {
      return enumObj[key];
    }
  }

  return undefined;
}
