import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CancelablePromise, JobService, PaginatedJobList } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<PaginatedJobList>;

export const fetchData = createAsyncThunk('workHistory/fetchData', (profileId: string) => {
  request?.cancel();

  request = JobService.jobList(100, 0, '-dates', undefined, profileId);
  return request;
});

const workHistorySlice = createSlice({
  name: 'workHistory',
  initialState: initialState.workHistory,
  reducers: {
    updateLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    updateData: (state, action: PayloadAction<PaginatedJobList>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.workHistory,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
  },
});
export const workHistorySelector = (state: IState) => state.workHistory;
export const { updateData, updateLoading, resetData } = workHistorySlice.actions;
export default workHistorySlice.reducer;
