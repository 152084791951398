import clsx from 'clsx';
import { forwardRef } from 'react';

import Box from '@/componentsv2/Box';

import { ITagProps } from './types';

const testId = 'of-tag';

const Tag = forwardRef<HTMLDivElement | HTMLButtonElement, ITagProps>(
  (
    {
      className,
      children,
      startIcon,
      endIcon,
      onClick,
      size = 'md',
      disabled,
      isNew,
      textClassName,
      isClickable,
      ...rest
    },
    ref
  ) => {
    isClickable = isClickable || !!onClick;
    return (
      <Box
        ref={ref}
        component={isClickable ? 'button' : 'div'}
        onClick={onClick}
        className={clsx(className, 'inline-flex items-center rounded-sm border shadow-5', {
          'gap-2 p-4 subtitle-2': size === 'sm',
          'gap-8 px-8 py-6 subtitle-1': size === 'md',
          'border-black border-opacity-10 bg-gray-50': !isNew,
          'border-green-300 bg-green-200': isNew,
          'hover:bg-light-primary': !isNew && isClickable,
        })}
        data-testid={testId}
        {...(isClickable && disabled ? { disabled: 'disabled' } : {})}
        {...rest}
      >
        {startIcon ? startIcon : null}
        {children ? (
          <span className={clsx(textClassName, { 'px-4': size === 'sm' })}>{children}</span>
        ) : null}
        {endIcon ? endIcon : null}
      </Box>
    );
  }
);

Tag.displayName = 'Tag';

export { testId as TagTestId };
export type { ITagProps };
export default Tag;
