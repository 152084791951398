/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Message } from '../models/Message';
import type { PaginatedSavedSearchList } from '../models/PaginatedSavedSearchList';
import type { PaginatedShortlistCollaboratorList } from '../models/PaginatedShortlistCollaboratorList';
import type { PaginatedShortlistProfileList } from '../models/PaginatedShortlistProfileList';
import type { PatchedSavedSearchUpdate } from '../models/PatchedSavedSearchUpdate';
import type { SavedSearch } from '../models/SavedSearch';
import type { SavedSearchCreate } from '../models/SavedSearchCreate';
import type { SavedSearchResponse } from '../models/SavedSearchResponse';
import type { SavedSearchShortlistRequest } from '../models/SavedSearchShortlistRequest';
import type { SavedSearchUpdate } from '../models/SavedSearchUpdate';
import type { ShortlistCollaboratorCreate } from '../models/ShortlistCollaboratorCreate';

export class SavedSearchService {
  /**
   * List all saved searches
   * List all saved searches
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @returns PaginatedSavedSearchList
   * @throws ApiError
   */
  public static savedSearchList(
    limit?: number,
    offset?: number,
    ordering:
      | ''
      | '-created_at'
      | '-owner__first_name'
      | '-updated_at'
      | 'created_at'
      | 'owner__first_name'
      | 'updated_at' = '-created_at'
  ): CancelablePromise<PaginatedSavedSearchList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_search/',
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
      },
    });
  }

  /**
   * Create a saved search
   * Create a saved search
   * @param requestBody
   * @returns SavedSearchResponse
   * @throws ApiError
   */
  public static savedSearchCreate(
    requestBody: SavedSearchCreate
  ): CancelablePromise<SavedSearchResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_search/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a saved search by UUID
   * Retrieve a saved search by UUID
   * @param id A UUID string identifying this saved collection.
   * @returns SavedSearch
   * @throws ApiError
   */
  public static savedSearchRetrieve(id: string): CancelablePromise<SavedSearch> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_search/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a saved search by UUID
   * Completely update a saved search by UUID
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns SavedSearchResponse
   * @throws ApiError
   */
  public static savedSearchUpdate(
    id: string,
    requestBody: SavedSearchUpdate
  ): CancelablePromise<SavedSearchResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/saved_search/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a saved search by UUID
   * Update specific fields within a saved search by UUID
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns SavedSearchResponse
   * @throws ApiError
   */
  public static savedSearchPartialUpdate(
    id: string,
    requestBody?: PatchedSavedSearchUpdate
  ): CancelablePromise<SavedSearchResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/saved_search/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a saved Search by UUID
   * Delete a saved Search by UUID
   * @param id A UUID string identifying this saved collection.
   * @returns void
   * @throws ApiError
   */
  public static savedSearchDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_search/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Get saved search collaborators
   * Get saved search collaborators
   * @param id A UUID string identifying this saved collection.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering Which field to use when ordering the results.
   * @returns PaginatedShortlistCollaboratorList
   * @throws ApiError
   */
  public static savedSearchCollaboratorsList(
    id: string,
    limit?: number,
    offset?: number,
    ordering?: string
  ): CancelablePromise<PaginatedShortlistCollaboratorList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_search/{id}/collaborators/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
      },
    });
  }

  /**
   * Add collaborators to saved search
   * Add collaborators to saved search
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns Message
   * @throws ApiError
   */
  public static savedSearchCollaboratorsCreate(
    id: string,
    requestBody: ShortlistCollaboratorCreate
  ): CancelablePromise<Message> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_search/{id}/collaborators/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete collaborators from saved search
   * Remove collaborators from saved search
   * @param id A UUID string identifying this saved collection.
   * @param profileId
   * @returns void
   * @throws ApiError
   */
  public static savedSearchCollaboratorsDestroy(
    id: string,
    profileId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_search/{id}/collaborators/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
    });
  }

  /**
   * Get shortlists
   * Get shortlists
   * @param id A UUID string identifying this saved collection.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering Which field to use when ordering the results.
   * @returns PaginatedShortlistProfileList
   * @throws ApiError
   */
  public static savedSearchShortlistList(
    id: string,
    limit?: number,
    offset?: number,
    ordering?: string
  ): CancelablePromise<PaginatedShortlistProfileList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_search/{id}/shortlist/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
      },
    });
  }

  /**
   * Add profiles to shortlists
   * Add profiles to shortlists
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns Message
   * @throws ApiError
   */
  public static savedSearchShortlistCreate(
    id: string,
    requestBody: SavedSearchShortlistRequest
  ): CancelablePromise<Message> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_search/{id}/shortlist/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove profiles from shortlists
   * Remove profiles from shortlists
   * @param id A UUID string identifying this saved collection.
   * @param profileId
   * @returns void
   * @throws ApiError
   */
  public static savedSearchShortlistDestroy(
    id: string,
    profileId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_search/{id}/shortlist/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
    });
  }
}
