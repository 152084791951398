import cx from 'classnames';

import ConsultationPreview from '../ConsultationPreview';
import LongList from '../LongList';
import s from './ConsultationList.module.scss';

interface ConsultationListProps {
  title: string;
  handleMore?: () => void;
  consultations: any[];
  loading?: boolean;
  pageInfo?: any;
  grid?: boolean;
}

const ConsultationList = ({
  title,
  handleMore,
  consultations,
  loading,
  pageInfo,
  grid,
}: ConsultationListProps) => {
  if (consultations.length === 0 && !loading) return <div />;

  return (
    <LongList
      className={s.consultations}
      containerClassName={cx({ [s.gridList]: grid })}
      title={title}
      loading={loading}
      hasNextPage={!!pageInfo && pageInfo.hasNextPage}
      onMore={handleMore}
    >
      {consultations.map((c: any) => (
        <div key={c.id} className={cx(s.listItem, { [s.gridItem]: grid })}>
          <ConsultationPreview consultation={c} compact={grid} />
        </div>
      ))}
    </LongList>
  );
};

export default ConsultationList;
