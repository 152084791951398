import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchLandingPage, removeLandingPage, updateLandingPage } from '@/actions/landing';
import { notify } from '@/actions/ui';

import ModelForm from '../ModelForm';

function LandingPageModel({
  id,
  model,
  fetchLandingPage,
  updateLandingPage,
  removeLandingPage,
  notify,
  landingPage,
}: any) {
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      fetchLandingPage({ id }).then((page: any) => {
        if (!page) {
          notify('Not found', 'warning');
          navigate(`/admin/${model.path}`);
        }
      });
    }
  }, [id]);

  const editableFields = useMemo(
    () => [
      { name: 'subdomain', type: 'text', required: true },
      { name: 'path', type: 'text', required: true },
      { name: 'page_title', type: 'text' },
      { name: 'title', type: 'text' },
      { name: 'subtitle', type: 'text' },
      { name: 'background_url', type: 'file' },
      { name: 'header_logo_url', type: 'file' },
      { name: 'header_logo_link_url', type: 'url' },
      { name: 'body_logo_url', type: 'file' },
      { name: 'meta_image_url', type: 'file' },
      { name: 'description', type: 'textarea' },
      { name: 'experts_engage_text', type: 'textarea' },
      { name: 'google_tag_manager_id', type: 'text' },
      { name: 'organization_about_logo_url', type: 'url' },
      { name: 'organization_about_logo_link_url', type: 'url' },
      { name: 'organization_about_text', type: 'textarea' },
      { name: 'organization_about_title', type: 'text' },
      { name: 'show_footer', type: 'checkbox' },
      { name: 'onfrontiers_about_text', type: 'textarea' },
      {
        name: 'ctas',
        allRequired: true,
        fields: [
          { name: 'label', type: 'text', maxLength: 40 },
          { name: 'url', type: 'url' },
          { name: 'order', type: 'number' },
        ],
      },
      {
        name: 'engage_ctas',
        allRequired: true,
        fields: [
          { name: 'label', type: 'text', maxLength: 40 },
          { name: 'url', type: 'url' },
          { name: 'order', type: 'number' },
        ],
      },
      {
        name: 'engage_details',
        allRequired: true,
        fields: [
          { name: 'icon', type: 'text' },
          { name: 'description', type: 'text' },
          { name: 'order', type: 'number' },
        ],
      },
      {
        name: 'value_propositions',
        allRequired: true,
        fields: [
          { name: 'icon', type: 'text' },
          { name: 'title', type: 'text' },
          { name: 'description', type: 'textarea' },
        ],
      },
      {
        name: 'blog_posts',
        allRequired: true,
        fields: [
          { name: 'url', type: 'url' },
          { name: 'thumbnail_url', type: 'url' },
          { name: 'author', type: 'text' },
          { name: 'date', type: 'date' },
          { name: 'title', type: 'textarea' },
          { name: 'excerpt', type: 'textarea' },
        ],
      },
    ],
    []
  );

  const handleSubmit = useCallback(async (values: any) => {
    const page = await updateLandingPage(values);
    navigate(`/admin/${model.path}/${page.id}`);
  }, []);

  const handleDelete = useCallback(() => removeLandingPage(id), []);

  return (
    <ModelForm
      title={id ? `Landing Page ${id} - ${landingPage.title}` : 'Create new landing page'}
      id={id}
      model={model}
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      initialModel={landingPage}
      fields={editableFields}
      returnPath={model.path}
    />
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'LandingPageModel' because it is ... Remove this comment to see the full error message
LandingPageModel = connect(
  (state, ownProps) => {
    return {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      landingPage: state.landing.full[ownProps.id] || {},
    };
  },
  {
    fetchLandingPage,
    updateLandingPage,
    removeLandingPage,
    notify,
  }
)(LandingPageModel);

export default LandingPageModel;
