/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileAwardList } from '../models/PaginatedProfileAwardList';
import type { PatchedProfileAwardUpdate } from '../models/PatchedProfileAwardUpdate';
import type { ProfileAward } from '../models/ProfileAward';
import type { ProfileAwardCreate } from '../models/ProfileAwardCreate';
import type { ProfileAwardUpdate } from '../models/ProfileAwardUpdate';

export class ProfileAwardService {
  /**
   * List all Profile Awards
   * List all Profile Awards
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId
   * @returns PaginatedProfileAwardList
   * @throws ApiError
   */
  public static profileAwardList(
    limit?: number,
    offset?: number,
    profileId?: string
  ): CancelablePromise<PaginatedProfileAwardList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_award/',
      query: {
        limit: limit,
        offset: offset,
        profile__id: profileId,
      },
    });
  }

  /**
   * Create a Profile Award
   * Create a Profile Award
   * @param requestBody
   * @returns ProfileAwardCreate
   * @throws ApiError
   */
  public static profileAwardCreate(
    requestBody?: ProfileAwardCreate
  ): CancelablePromise<ProfileAwardCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile_award/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Profile Award by UUID
   * Retrieve a Profile Award by UUID
   * @param id A UUID string identifying this profile award.
   * @returns ProfileAward
   * @throws ApiError
   */
  public static profileAwardRetrieve(id: string): CancelablePromise<ProfileAward> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_award/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Profile Award by UUID
   * Completely update a Profile Award by UUID
   * @param id A UUID string identifying this profile award.
   * @param requestBody
   * @returns ProfileAwardUpdate
   * @throws ApiError
   */
  public static profileAwardUpdate(
    id: string,
    requestBody?: ProfileAwardUpdate
  ): CancelablePromise<ProfileAwardUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile_award/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Profile Award by UUID
   * Update specific fields within a Profile Award by UUID
   * @param id A UUID string identifying this profile award.
   * @param requestBody
   * @returns ProfileAwardUpdate
   * @throws ApiError
   */
  public static profileAwardPartialUpdate(
    id: string,
    requestBody?: PatchedProfileAwardUpdate
  ): CancelablePromise<ProfileAwardUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_award/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Profile Award by UUID
   * Delete a Profile Award by UUID
   * @param id A UUID string identifying this profile award.
   * @returns void
   * @throws ApiError
   */
  public static profileAwardDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile_award/{id}/',
      path: {
        id: id,
      },
    });
  }
}
