import { fetchAllGroups } from '@/actions/group';
import { fetchExpertRequests } from '@/expertrequest/store';
import { LegacyRoute } from '@/routes/routesMiddleware';

import ProfileUploader from './ProfileUploader';

const route: LegacyRoute = {
  path: '/profile-uploader',
  element: <ProfileUploader />,
  async action({ store }) {
    await Promise.all(
      [
        fetchExpertRequests({ state: 'open' }),
        fetchAllGroups({
          collection: 'internal',
          internal: true,
          memberOnly: false,
          memberLimit: 100,
        }),
        fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      ].map(store.dispatch)
    );

    document.title = 'Profile Uploader';
    return null;
  },
};

export default route;
