import clsx from 'clsx';

import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';

import { IDrawerSectionProps } from '../types';

const Section = ({
  className,
  title,
  titleClassName = 'subtitle-1',
  showUpdatingIndicator,
  children,
  childrenContainerClassName,
  action,
  ...rest
}: IDrawerSectionProps) => {
  return (
    <div
      className={clsx(className, 'rounded-md border border-light-primary bg-white shadow-5')}
      {...rest}
    >
      {title || showUpdatingIndicator || action ? (
        <div
          className={clsx('border-b border-light-primary px-16 py-12', {
            'flex items-center gap-16': showUpdatingIndicator || action,
          })}
        >
          {title ? <h3 className={titleClassName}>{title}</h3> : null}
          {showUpdatingIndicator ? <UpdatingIndicator /> : null}
          {action ? <div className="ml-auto">{action}</div> : null}
        </div>
      ) : null}
      <div className={clsx(childrenContainerClassName)}>{children}</div>
    </div>
  );
};

export default Section;
