import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useCallback, useState } from 'react';

import FilterButton from './FilterButton';

const defaultFilterOptions = createFilterOptions({
  limit: 5,
  trim: true,
});

const useStyles = makeStyles({
  small: {
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '13px 0 10px',
  },
});

export default function ({
  placeholder,
  options,
  value,
  onChange,
  freeSolo,
  disabled,
  displayAddButton,
  disableUnderline = true,
}: any) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((e: any, newInputValue: any, reason: any) => {
    setInputValue(reason === 'input' ? newInputValue : '');
  }, []);

  const handleChange = useCallback(
    (e: any, newInputValue: any, reason: any) => {
      onChange(e, newInputValue, reason);
      setInputValue('');
    },
    [onChange]
  );

  const getOptionLabel = useCallback((o: any) => (o && o.label) || o || '', []);

  const availableOpts = options.filter((o: any) => !value.includes(o.value));

  const handleAddClick = useCallback(
    (e: any) => handleChange(e, inputValue, 'add-click'),
    [inputValue, handleChange]
  );

  return (
    <Fragment>
      <Autocomplete
        classes={{ input: classes.autocompleteInput }}
        disableClearable
        openOnFocus
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        freeSolo={freeSolo}
        options={availableOpts}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        filterOptions={defaultFilterOptions}
        // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'HTMLAttri... Remove this comment to see the full error message
        renderOption={(o) => <span className={classes.small}>{o.label}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            margin="none"
            InputProps={{ ...params.InputProps, disableUnderline }}
          />
        )}
      />

      {displayAddButton && (
        <div className={classes.action}>
          <FilterButton disabled={!inputValue} onClick={handleAddClick} />
        </div>
      )}
    </Fragment>
  );
}
