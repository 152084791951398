import { gengql } from '@/__generated__';

export const PROJECT_FIELDS = gengql(/* GraphQL */ `
  fragment ProjectFields on Project {
    id
    name
    html_url
    tracking_code
    group {
      id
      name
    }
    members {
      id
      email
      role
      state
      user {
        id
        name
        first_name
        last_name
        html_url
        picture_url
      }
    }
    permissions
  }
`);

export const CANDIDATE_FIELDS = gengql(/* GraphQL */ `
  fragment CandidateFields on ExpertRequestCandidate {
    id
    email
    state
    last_state_change
    show_engagement_agreement
    match_note {
      author {
        id
        first_name
        last_name
        name
        picture_url
        html_url
      }
      note
      recommended
    }
    client_note {
      author {
        id
        first_name
        last_name
        name
        picture_url
        html_url
      }
      note
      is_good_match
    }
    match_score
    match_experience
    bill_rate
    credit_rate
    qualification_responses {
      id
      query {
        id
        query
        response_type
      }
      can_answer
      text_response
    }
    question_answers {
      id
      query {
        id
        query
        response_type
      }
      can_answer
      text_response
    }
    updated_by {
      id
      name
    }
    created_by {
      id
      name
      picture_url
      html_url
    }
    profile {
      user {
        id
        username
      }
      id
      credit_rate
      expert_state
      first_name
      last_name
      name
      html_url
      linkedin_url
      title
      summary
      timezone
      city
      country
      picture_url
      available_marketplace
      can_request_consultation(expert_request_id: $expertRequestId)
      emails {
        address
      }
      keywords
      group_keywords {
        group {
          id
          name
        }
        keywords {
          id
          name
        }
      }
      experiences {
        id
        title
        organization
      }
      expert_internal_networks {
        id
        network {
          id
          name
          group {
            id
          }
        }
      }
    }
  }
`);

export const SAVE_EXPERT_REQUEST_FIELDS = gengql(/* GraphQL */ `
  fragment SaveExpertRequestFields on ExpertRequest {
    id
    permissions
    public_html_url
    qualifications {
      id
      query
      response_type
      required
    }
    questions {
      id
      query
      response_type
      required
    }
  }
`);

export const GET_EXPERT_REQUEST = gengql(/* GraphQL */ `
  query actionFetchExpertRequest($expertRequestId: String!) {
    expertRequest(id: $expertRequestId) {
      id
      html_url
      created_at
      name
      description
      focus_areas
      qualifications {
        id
        query
        response_type
        required
      }
      questions {
        id
        query
        response_type
        required
      }
      attachments {
        id
        created_at
        author {
          id
          name
          html_url
        }
        expert_request_id
        consultation_id
        name
        description
        file_url
        hide_from_experts
      }
      companies_pursue
      companies_avoid
      disclosure
      state
      archived
      slug
      public_html_url
      creator {
        id
        name
        first_name
        last_name
        html_url
        picture_url
      }
      regions {
        id
        name
      }
      sectors {
        id
        name
      }
      er_type
      group_about
      instructions_research
      job_scope
      opportunity_location
      permissions
      project {
        ...ProjectFields
      }
      consultations {
        id
        html_url
        starts_at
        started_at
        ended_at
        created_at
        state
        proposed_times
        requester_name
        expert_name
        requester {
          id
          name
          html_url
          picture_url
        }
        expert {
          id
          name
          html_url
          picture_url
        }
      }
      tags
      expected_duration
      phone
      stats {
        calls
      }
      time_done_scoping_call
    }
  }
`);

export const UPDATE_EXPERT_REQUEST = gengql(/* GraphQL */ `
  mutation actionUpdateExpertRequest(
    $id: String!
    $includeProject: Boolean!
    $name: String
    $description: String
    $disclosure: DisclosureOption
    $focus_areas: [String]
    $companies_pursue: [String]
    $companies_avoid: [String]
    $qualifications: [ExpertRequestQueryInput]
    $questions: [ExpertRequestQueryInput]
    $regions: [String]
    $sectors: [String]
    $tags: [String]
    $expected_duration: Duration
    $project_id: String
    $phone: String
    $er_type: ExpertRequestType
    $instructions_research: String
    $group_about: String
    $job_scope: String
    $opportunity_location: String
    $time_done_scoping_call: Datetime
  ) {
    updateExpertRequest(
      id: $id
      name: $name
      description: $description
      disclosure: $disclosure
      focus_areas: $focus_areas
      companies_pursue: $companies_pursue
      companies_avoid: $companies_avoid
      qualifications: $qualifications
      questions: $questions
      regions: $regions
      sectors: $sectors
      tags: $tags
      expected_duration: $expected_duration
      project_id: $project_id
      phone: $phone
      er_type: $er_type
      instructions_research: $instructions_research
      group_about: $group_about
      job_scope: $job_scope
      opportunity_location: $opportunity_location
      time_done_scoping_call: $time_done_scoping_call
    ) {
      ...SaveExpertRequestFields
      project @include(if: $includeProject) {
        ...ProjectFields
      }
    }
  }
`);

export const CREATE_EXPERT_REQUEST = gengql(/* GraphQL */ `
  mutation actionCreateExpertRequest(
    $project_id: String!
    $name: String!
    $er_type: ExpertRequestType!
    $includeProject: Boolean!
    $description: String
    $disclosure: DisclosureOption
    $focus_areas: [String]
    $companies_pursue: [String]
    $companies_avoid: [String]
    $qualifications: [ExpertRequestQueryInput]
    $questions: [ExpertRequestQueryInput]
    $regions: [String]
    $sectors: [String]
    $tags: [String]
    $expected_duration: Duration
    $phone: String
    $instructions_research: String
    $group_about: String
    $job_scope: String
    $opportunity_location: String
    $time_done_scoping_call: Datetime
  ) {
    createExpertRequest(
      name: $name
      description: $description
      disclosure: $disclosure
      focus_areas: $focus_areas
      companies_pursue: $companies_pursue
      companies_avoid: $companies_avoid
      qualifications: $qualifications
      questions: $questions
      regions: $regions
      sectors: $sectors
      tags: $tags
      expected_duration: $expected_duration
      project_id: $project_id
      phone: $phone
      er_type: $er_type
      instructions_research: $instructions_research
      group_about: $group_about
      job_scope: $job_scope
      opportunity_location: $opportunity_location
      time_done_scoping_call: $time_done_scoping_call
    ) {
      ...SaveExpertRequestFields
      project @include(if: $includeProject) {
        ...ProjectFields
      }
    }
  }
`);

export const GET_EXPERT_REQUEST_CANDIDATES = gengql(/* GraphQL */ `
  query getExpertRequestCandidates(
    $expertRequestId: String!
    $states: [String]
    $first: Int!
    $after: String
  ) {
    expertRequestCandidates(id: $expertRequestId, states: $states, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        metaData
      }
      edges {
        cursor
        node {
          ...CandidateFields
        }
      }
    }
  }
`);

export const ADD_EXPERT_REQUEST_CANDIDATES = gengql(/* GraphQL */ `
  mutation actionAddExpertRequestCandidates(
    $expertRequestId: String!
    $profileIds: [String!]
    $dryRun: Boolean!
    $sendInvitationEmail: Boolean
    $invitationEmailSubject: String
    $invitationEmailBody: String
    $state: CandidateState!
  ) {
    addExpertRequestCandidates(
      expert_request_id: $expertRequestId
      dry_run: $dryRun
      send_invitation_email: $sendInvitationEmail
      invitation_email_subject: $invitationEmailSubject
      invitation_email_body: $invitationEmailBody
      profile_ids: $profileIds
      state: $state
    ) {
      success
      results {
        profile_id
        error_code
        warning_code
        candidate @skip(if: $dryRun) {
          ...CandidateFields
        }
      }
    }
  }
`);

export const REQUEST_ADD_EXPERT_REQUEST_CANDIDATE = gengql(/* GraphQL */ `
  mutation actionRequestAddExpertRequestCandidate(
    $expert_request_id: String!
    $match_experience: String
    $question_answers: [CandidateQueryResponseInput!]
    $qualification_responses: [CandidateQueryResponseInput!]
  ) {
    requestAddExpertRequestCandidate(
      expert_request_id: $expert_request_id
      match_experience: $match_experience
      question_answers: $question_answers
      qualification_responses: $qualification_responses
    ) {
      id
      email
    }
  }
`);

export const UPDATE_EXPERT_REQUEST_CANDIDATE = gengql(/* GraphQL */ `
  mutation actionUpdateExpertRequestCandidate(
    $id: String!
    $state: CandidateState
    $match_experience: String
    $match_note: CandidateMatchNoteInput
    $client_note: CandidateClientNoteInput
    $qualification_responses: [CandidateQueryResponseInput]
    $question_answers: [CandidateQueryResponseInput]
    $note: String
    $priority: Boolean
    $bill_rate: Int
    $credit_rate: Int
    $show_engagement_agreement: Boolean
  ) {
    updateExpertRequestCandidate(
      id: $id
      state: $state
      match_note: $match_note
      client_note: $client_note
      match_experience: $match_experience
      qualification_responses: $qualification_responses
      question_answers: $question_answers
      update_note: $note
      priority: $priority
      bill_rate: $bill_rate
      credit_rate: $credit_rate
      show_engagement_agreement: $show_engagement_agreement
    ) {
      id
      email
      state
      last_state_change
      match_note {
        author {
          id
          first_name
          last_name
          name
          picture_url
          html_url
        }
        note
        recommended
      }
      client_note {
        author {
          id
          first_name
          last_name
          name
          picture_url
          html_url
        }
        note
        is_good_match
      }
      updated_by {
        id
        name
      }
      qualification_responses {
        id
        can_answer
        text_response
        query {
          id
          query
          response_type
        }
      }
      question_answers {
        id
        can_answer
        text_response
        query {
          id
          query
          response_type
        }
      }
      match_experience
      bill_rate
      credit_rate
      show_engagement_agreement
    }
  }
`);
