import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, ReactNode, useMemo } from 'react';

import Link from '@/components/Link';
import LongText from '@/components/LongText';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      textAlign: 'center',
    },
  },

  body: {
    fontSize: 20,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      textAlign: 'center',
    },
  },

  logo: {
    maxWidth: 300,
    maxHeight: 100,
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: 255,
      maxHeight: 85,
    },
  },

  logoDivider: {
    height: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },

  headerChildrenDivider: {
    marginBottom: 30,
  },

  logoContainer: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}));

interface AuthLayoutProps {
  headerChildren?: ReactNode;
  children: ReactNode;
  domain?: any;
  title?: string;
  shortAboutText?: string;
  longAboutText?: string;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  headerChildren,
  children,
  domain,
  title,
  shortAboutText,
  longAboutText,
}) => {
  const s = useStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const logo = domain && <img alt="logo" src={domain.logo_url} className={s.logo} />;

  const aboutPage = domain && domain.group.about_page;

  const defaultShortAboutText = useMemo(
    () => 'OnFrontiers is unlocking trapped knowledge to help companies solve wicked problems.',
    []
  );

  const defaultLongAboutText = useMemo(
    () => `Do you have the urge to get paid to deliver value at an hourly rate of your choice, advise large or small domestic or international government contracting projects, and continue to build your legacy in your field?

    We’ve created a digital network where specialized experts like yourself can launch new careers as independent consultants for leading government contracting organizations and consultancies (without the commitment of a full-time role!). Your knowledge will help everyone from Fortune 500 companies to upcoming small businesses move with greater agility and precision.

    You’re probably not a massive fan of setting up or optimizing online profiles, but you don’t have to worry. Our easy-to-use onboarding platform will help you get set up for your first round of consultations in less than five minutes!

    Our membership is 100% free, completely compliant with the latest U.S. federal acquisition regulations, and it always will be.

    <b>Here’s what we’re hearing from our Experts:</b>

    <i>“OnFrontiers is the reason I started a consulting firm”</i><br />Jamie (Government Contracting Expert)

    <i>“1st consultation was less than 1hr call but turned into more than 10hr project”</i><br />J. T. (Proposal Management Expert)

    <i>“My first engagement extended to 4 more engagements”</i><br />C. R. ( HR Leader, DE&I Expert)`,
    []
  );

  return (
    <div>
      {headerChildren && (
        <>
          {headerChildren}
          <Divider className={s.headerChildrenDivider} />
        </>
      )}

      {domain && domain.logo_url && (
        <div className={s.logoContainer}>
          {aboutPage && aboutPage.html_url ? <Link to={aboutPage.html_url}>{logo}</Link> : logo}
          <Divider className={s.logoDivider} />
        </div>
      )}

      <Grid container spacing={4}>
        <Grid item md={6} sm={12}>
          {title && <h1 className={s.title}>{title}</h1>}
          <LongText
            className={s.body}
            text={
              mobile
                ? shortAboutText || defaultShortAboutText
                : longAboutText || defaultLongAboutText
            }
          />
        </Grid>

        {!mobile && <Grid item md={1} sm={12} />}

        <Grid item md={5} sm={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthLayout;
