import { useState } from 'react';

import Box from '@/componentsv2/Box';
import Collapse from '@/componentsv2/Collapse';
import CollapseToggle from '@/componentsv2/CollapseToggle';

import { IShowMoreProps } from './types';

const testId = 'of-show-more';

const ShowMore = ({
  className,
  children,
  containerCmp,
  listCmp,
  limit,
  maxLimit,
}: IShowMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {Array.isArray(children) && children.length > 1 && limit ? (
        <Box component={containerCmp} className={className} data-testid={testId}>
          <Box component={listCmp}>
            <ul className="space-y-16">
              {children.slice(0, limit).map((child, i) => (
                <li key={i}>{child}</li>
              ))}
            </ul>
            <Collapse in={isExpanded}>
              <ul className="mt-16 space-y-16">
                {(maxLimit && maxLimit > limit
                  ? children.slice(limit, maxLimit)
                  : children.slice(limit)
                ).map((child, i) => (
                  <li key={i}>{child}</li>
                ))}
              </ul>
            </Collapse>
          </Box>
          {children.length > limit ? (
            <CollapseToggle handleToggle={() => setIsExpanded((v) => !v)} expanded={isExpanded} />
          ) : null}
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export { testId as ShowMoreTestId };
export type { IShowMoreProps };
export default ShowMore;
