import { ReactNode } from 'react';

import ConfirmDialog, { IConfirmDialogProps } from '..';

const testId = 'of-are-you-sure-dialog';

export type IAreYouSureDialogProps = Omit<
  IConfirmDialogProps & { title?: string; subtitle?: string | ReactNode },
  'message'
>;

const AreYouSureDialog = ({ className, title, subtitle, ...rest }: IAreYouSureDialogProps) => {
  return (
    <ConfirmDialog
      className={className}
      data-testid={testId}
      confirmButtonLabel="Discard changes"
      confirmButtonProps={{ color: 'error' }}
      dialogTitle={title || 'Are you sure?'}
      message={subtitle || 'You will lose all the information that you have input thus far'}
      {...rest}
    />
  );
};

export { testId as AreYouSureDialogTestId };
export default AreYouSureDialog;
