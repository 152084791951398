/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Language } from '../models/Language';
import type { PaginatedLanguageList } from '../models/PaginatedLanguageList';
import type { PatchedLanguage } from '../models/PatchedLanguage';

export class LanguageService {
  /**
   * List all languages within our taxonomy
   * List all languages within our taxonomy
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedLanguageList
   * @throws ApiError
   */
  public static languageList(
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedLanguageList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/language/',
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * Create a language
   * Create a language
   * @param requestBody
   * @returns Language
   * @throws ApiError
   */
  public static languageCreate(requestBody?: Language): CancelablePromise<Language> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/language/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a language by UUID
   * Retrieve a language by UUID
   * @param id A UUID string identifying this language.
   * @returns Language
   * @throws ApiError
   */
  public static languageRetrieve(id: string): CancelablePromise<Language> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/language/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a language by UUID
   * Completely update a language by UUID
   * @param id A UUID string identifying this language.
   * @param requestBody
   * @returns Language
   * @throws ApiError
   */
  public static languageUpdate(id: string, requestBody?: Language): CancelablePromise<Language> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/language/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a language by UUID
   * Update specific fields within a language by UUID
   * @param id A UUID string identifying this language.
   * @param requestBody
   * @returns Language
   * @throws ApiError
   */
  public static languagePartialUpdate(
    id: string,
    requestBody?: PatchedLanguage
  ): CancelablePromise<Language> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/language/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a language by UUID
   * Delete a language by UUID
   * @param id A UUID string identifying this language.
   * @returns void
   * @throws ApiError
   */
  public static languageDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/language/{id}/',
      path: {
        id: id,
      },
    });
  }
}
