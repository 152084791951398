import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

import { IDrawerDetailsListProps } from '../types';

const testId = 'of-drawer-details-list';

const DetailsList = ({ className, details = {} }: IDrawerDetailsListProps) => {
  const classProps = clsx('sm:flex sm:gap-16', className);

  return (
    <dl className={classProps} data-testid={testId}>
      {Object.entries(details).map(([label, value]) => (
        <div key={label} className="flex items-center gap-4 sm:flex-row-reverse">
          <dt className="body-2">{label}</dt>
          <dd className="subtitle-1">
            {value !== undefined ? value : <Skeleton variant="text" width={20} />}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default DetailsList;
