import { Navigate, RouteObject, json, redirect } from 'react-router-dom';

import changePassword from '@/auth/pages/ChangePasswordPage';
import loginAs from '@/auth/pages/LoginAsPage';
import LoginPage, { loginPageMiddleware } from '@/auth/pages/LoginPage';
import LogoutPage, { logoutPageLoader } from '@/auth/pages/LogoutPage';
import register from '@/auth/pages/RegisterPage';
import resetPassword from '@/auth/pages/ResetPasswordPage';
import selectDomain from '@/auth/pages/SelectDomainPage';
import validateEmail from '@/auth/pages/ValidateEmailPage';
import consultation from '@/consultation/pages/ConsultationPage';
import expertRequest from '@/expertrequest/pages/ExpertRequestPage';
import expertRequestList from '@/expertrequest/pages/expertRequestList';
import expertRequestNew, {
  addMembers as expertRequestAddMembers,
} from '@/expertrequest/pages/expertRequestNew';
import MessagingPage, { messagingMiddleware } from '@/messaging/pages/MessagingPage';
import NotFoundPage from '@/pages/NotFoundPage';
import profile from '@/profile/pages/profile';
import profileConflicts from '@/profile/pages/profileConflicts';
import profileMerge from '@/profile/pages/profileMerge';
import profileUploader from '@/profile/pages/profileUploader';
import unregisteredProfile from '@/profile/pages/unregisteredProfile';
import search from '@/search/pages/SearchPage';

import admin from './admin';
import application from './application';
import browse from './browse';
import complianceTraining from './complianceTraining';
import consultations from './consultations';
import dashboard from './dashboard';
import expertNetwork from './expertNetwork';
import graphiql from './graphiql';
import harnessRoutes from './harnessRoutes';
import legalAck from './legalAck';
import payoutDashboard from './payoutDashboard';
import projectDetails from './projectDetails';
import {
  LegacyRoute,
  MiddlewareContext,
  ProtectionFunction,
  agreementsRequiredLegacy,
  agreementsWithEmailRequiredLegacy,
  allRequiredLegacy,
  allRequiredMiddleware,
  applyMiddlewares as applyMiddleware,
  legacyRouteAdapter,
  loginRequiredLegacy,
  superAdminRequiredLegacy,
} from './routesMiddleware';
import settings from './settings';
import team from './team';
import teamSettings from './teamSettings';
import teams from './teams';

function createAccessRoutes(context: MiddlewareContext): RouteObject[] {
  const adaptLegacy = (route: LegacyRoute, protectionFn?: ProtectionFunction) =>
    legacyRouteAdapter(context, route, protectionFn);

  return [
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: '/become-an-expert',
      loader: () => redirect('/signup/expert'),
    },
    adaptLegacy(browse),
    adaptLegacy(graphiql),
    {
      path: '/health-check',
      element: <h1>Ok</h1>,
      loader: () => json({ status: 200 }),
    },
    {
      path: '/login/:type?',
      element: <LoginPage />,
      loader: applyMiddleware(context, [loginPageMiddleware]),
    },
    {
      path: '/:subdomain/login/:type?',
      element: <LoginPage />,
      loader: applyMiddleware(context, [loginPageMiddleware]),
    },
    adaptLegacy(resetPassword),
    adaptLegacy(selectDomain),
    {
      path: '/logout',
      element: <LogoutPage />,
      loader: logoutPageLoader,
    },
    adaptLegacy(changePassword),
    adaptLegacy(loginAs, loginRequiredLegacy),
    adaptLegacy(legalAck, loginRequiredLegacy),
    adaptLegacy(application, allRequiredLegacy),
    adaptLegacy(consultation, allRequiredLegacy),
    adaptLegacy(consultations, allRequiredLegacy),
    adaptLegacy(dashboard, allRequiredLegacy),
    adaptLegacy(payoutDashboard, allRequiredLegacy),
    adaptLegacy(expertRequest),
    adaptLegacy(expertRequestNew, allRequiredLegacy),
    adaptLegacy(expertRequestAddMembers, allRequiredLegacy),
    {
      path: '/messaging/:channelId?',
      element: <MessagingPage />,
      loader: applyMiddleware(context, [allRequiredMiddleware, messagingMiddleware]),
    },
    adaptLegacy(profile),
    adaptLegacy(unregisteredProfile, allRequiredLegacy),
    adaptLegacy(profileConflicts, allRequiredLegacy),
    adaptLegacy(profileUploader, allRequiredLegacy),
    adaptLegacy(profileMerge, allRequiredLegacy),
    adaptLegacy(search, allRequiredLegacy),
    adaptLegacy(settings, allRequiredLegacy),
    adaptLegacy(teams, allRequiredLegacy),
    adaptLegacy(teamSettings, allRequiredLegacy),
    adaptLegacy(expertRequestList, agreementsWithEmailRequiredLegacy),
    adaptLegacy(register, agreementsRequiredLegacy),
    adaptLegacy(admin, superAdminRequiredLegacy),
    ...validateEmail.map((route) => adaptLegacy(route, loginRequiredLegacy)),
    ...expertNetwork.map((route) => adaptLegacy(route, loginRequiredLegacy)),
    ...projectDetails.map((route) => adaptLegacy(route, agreementsWithEmailRequiredLegacy)),
    adaptLegacy(team, allRequiredLegacy),
    ...complianceTraining.map((route) => adaptLegacy(route, allRequiredLegacy)),
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ];
}

export default (context: MiddlewareContext): RouteObject[] => {
  const accessRoutes = createAccessRoutes(context);
  return [...accessRoutes, ...harnessRoutes];
};
