import { CompleteKnowledge, PaginatedAssignmentHasKnowledgeList } from '@/openapi';
import { IBaseState } from '@/store/state';

export type CompleteKnowledgeType = 'country' | 'knowledge_raw';

export const KnowledgeTypeToCompleteType = {
  location: 'country' as CompleteKnowledgeType,
  knowledge: 'knowledge_raw' as CompleteKnowledgeType,
};

export type SelectKnowledge = Omit<CompleteKnowledge, 'score'> & {
  type: CompleteKnowledgeType;
};

export type IAddKnowledge = Pick<IBaseState, 'error'> & {
  opened: boolean;
  selectedKnowledge: SelectKnowledge | null;
  lookupKnowledges: Array<SelectKnowledge>;
  isLookupKnowledgeLoading: boolean;
  lookupExperiences: PaginatedAssignmentHasKnowledgeList;
  isLookupExperiencesLoading: boolean;
};

export default IAddKnowledge;
