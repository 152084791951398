import cx from 'classnames';

import Link from '../Link';
import s from './CaseStudyPreview.module.scss';

function CaseStudyPreview({ slug, subtitle, image, dim, titleStyle }: any) {
  const style = {
    background: `url('/images/studies/thumb/${image}')`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  return (
    <div className={cx({ [s.dimmed]: dim, [s.notDimmed]: !dim })}>
      <Link to={`/case/${slug}`} className={s.image} style={style} />
      {dim && <div className={s.dim} />}

      <Link to={`/case/${slug}`}>
        <h3 style={titleStyle} className={s.title}>
          {subtitle}
        </h3>
      </Link>
    </div>
  );
}

export default CaseStudyPreview;
