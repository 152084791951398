import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import Box from '@/componentsv2/Box';
import FormikDatePicker from '@/componentsv2/DatePicker/withFormik';
import FormikField from '@/componentsv2/FormikField';
import { AwardsExtract, ProfileAward } from '@/openapi';

import { AwardFormValues, IAwardFormProps } from './types';

export const validationSchema = yup.object().shape({
  award: yup.string().required(),
  organization: yup.string().required(),
  date: yup.date().required().typeError('Invalid date').nullable(),
});

export const newItem = {
  award: '',
  organization: '',
  date: null,
} as AwardFormValues;

export const mapProfileDataToFormValues = (data: ProfileAward[] | AwardsExtract[]) =>
  data.map((award) => ({
    id: award.id ?? '',
    new: 'new' in award ? award.new : false,
    date:
      'issued_date' in award
        ? moment(award.issued_date).isValid()
          ? moment(award.issued_date)
          : null
        : null,
    description: 'description' in award ? (award.description ?? '') : '',
    award: ('award' in award ? award.award : award.award_by_name) || '',
    organization: ('organization' in award ? award.organization : award.organization_by_name) || '',
  }));

const testId = 'of-award-form';

const AwardForm = ({ className, children, preventAutofocus }: IAwardFormProps) => {
  const formik = useFormikContext<AwardFormValues>();

  return (
    <>
      <Grid
        className={className}
        container
        columnSpacing="10px"
        rowSpacing={2}
        data-testid={testId}
      >
        <Grid item xs={8}>
          <FormikField
            container={Box}
            name="award"
            label="Award name"
            size="small"
            variant="filled"
            fullWidth
            autoFocus={!preventAutofocus}
            required
            inputProps={{ maxLength: 150 }}
          />
        </Grid>
        <Grid item xs={8}>
          <FormikField
            container={Box}
            name="organization"
            label="Organization"
            size="small"
            variant="filled"
            fullWidth
            required
            inputProps={{ maxLength: 1000 }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikDatePicker
            container={Box}
            name="date"
            value={formik.values.date}
            onChange={(date) => formik.setFieldValue('date', date)}
            label="Date"
            placeholder="MM/YYYY"
            inputFormat="MM/YYYY"
            views={['year', 'month']}
            TextFieldProps={{ variant: 'filled', required: true }}
          />
        </Grid>
      </Grid>
      {children}
    </>
  );
};

export default AwardForm;
export { testId as AwardFormTestId };
