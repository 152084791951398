import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import AssignmentsManager from '@/assignment/AssignmentsManager';
import { TASKS, completeTask } from '@/dashboardv2/store/onboardingSlice';
import { BatchCreateOrUpdateService, ExtractorResponse } from '@/openapi';
import { refresh as refreshProfile } from '@/profilev2/store/profileSlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';

import {
  mapProfileDataToFormValues,
  validationSchema,
} from '../components/ExperienceForms/AwardForm';

const history = createBrowserHistory();

export const batchUpdateExperiences = createAsyncThunk(
  'manageExperiences/batchUpdateExperiences',
  async (
    {
      payload,
      profileId,
      skipTasks,
    }: { payload: ExtractorResponse; profileId: string; skipTasks?: boolean },
    { dispatch, rejectWithValue }
  ) =>
    await BatchCreateOrUpdateService.batchCreateOrUpdateCreate(payload)
      .then(async (data) => {
        if (data.jobs.length > 1) {
          dispatch(completeTask({ slug: TASKS.ADD_PROFESSIONAL_HISTORY }));

          if (
            mapProfileDataToFormValues(data.jobs).every((job) => validationSchema.isValidSync(job))
          ) {
            dispatch(completeTask({ slug: TASKS.REVIEW_ALL_PROFESSIONAL_HISTORY }));
          }
        }

        if (payload.projectmembers?.length) {
          const allProjects = await AssignmentsManager.fetchAssignmentsList({
            limit: 100,
            profileId,
            assignmentTypes: 'Project|Proposal',
          });

          if (
            allProjects.results?.every((project) => !!project.title_raw_term_name) &&
            !skipTasks
          ) {
            dispatch(completeTask({ slug: TASKS.REVIEW_ALL_CONTRACTS }));
          }
        }

        dispatch(refreshProfile(profileId));

        return data;
      })
      .catch(() => {
        return rejectWithValue('Unable to save. Please contact support');
      })
);

type ExtractorResponseMinimumValue = {
  id?: string | null;
}[];

export const addDelete = (value: ExtractorResponseMinimumValue | undefined, profileId: string) =>
  value?.map((item) => ({
    deleted: true,
    id: item.id,
    profile: profileId,
  }));

const manageExperiencesSlice = createSlice({
  name: 'manageExperiences',
  initialState: initialState.manageExperiences,
  reducers: {
    resetData: () => initialState.manageExperiences,
    showDrawer: (
      state,
      action: PayloadAction<{ enableUpload: boolean; excludeJobs?: boolean }>
    ) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        enableUpload: action.payload.enableUpload,
        excludeJobs: action.payload.excludeJobs ?? false,
      };
    },
    closeDrawer: (state) => ({
      ...state,
      opened: false,
    }),
    setParsedExperiences: (state, action: PayloadAction<ExtractorResponse>) => ({
      ...state,
      parsedExperiences: action.payload,
    }),
    setActiveEditSection: (state, action: PayloadAction<string>) => ({
      ...state,
      activeEditSection: action.payload,
    }),
  },
});

export const manageExperiencesSelector = (state: IState) => state.manageExperiences;
export const { showDrawer, closeDrawer, setParsedExperiences, setActiveEditSection, resetData } =
  manageExperiencesSlice.actions;
export default manageExperiencesSlice.reducer;
