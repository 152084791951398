import { FC, useCallback, useEffect, useState } from 'react';

import MediaPlayer from '@/components/MediaPlayer';
import { fileTypes, isAudioType, isVideoType, recordingTypes } from '@/consultation';
import { presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

// TODO: Make fallbackURL a recording type {location, file type, recording type, etc }
interface Recording {
  location: string;
  fileType: string;
  recordingType?: string;
}

interface RecordingPlayerProps {
  conference: any;
  enabled: boolean;
  fallbackURL?: string;
}

const RecordingPlayer: FC<RecordingPlayerProps> = ({ conference, enabled, fallbackURL }) => {
  const { graphql } = useApp();

  const [recording, setRecording] = useState<Recording | undefined>(undefined);

  const presignRecording = useCallback(
    async (recording: any) => {
      let newRecording;
      if (recording) {
        const location = await presignAttachmentURL(graphql.client, recording.location);
        newRecording = {
          location,
          fileType: recording.file_type,
          recordingType: recording.recording_type,
        };
      }
      setRecording(newRecording);
    },
    [graphql.client]
  );

  useEffect(() => {
    let internalRecording = conference?.attachments?.find(
      (a: any) => a.recording_type === recordingTypes.sharedScreenWithSpeakerView
    );
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a: any) => isVideoType(a.file_type));
    }
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a: any) => isAudioType(a.file_type));
    }
    if (!internalRecording && fallbackURL) {
      internalRecording = {
        location: fallbackURL,
        file_type: fileTypes.mp3,
      };
    }
    presignRecording(internalRecording);
  }, [conference, fallbackURL, presignRecording]);

  return (
    <div>
      {recording?.location && (
        <MediaPlayer
          src={recording.location}
          isAudioOnly={isAudioType(recording.fileType)}
          enabled={enabled}
        />
      )}
    </div>
  );
};

export default RecordingPlayer;
