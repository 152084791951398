import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';

import AssignmentsManager from '@/assignment/AssignmentsManager';
import {
  CancelablePromise,
  DegreeType,
  Education,
  EducationService,
  JobSerialized,
  PaginatedAssignmentsList,
  PatchedEducationUpdate,
  PatchedProfileUpdate,
  Profile,
  ProfileService,
} from '@/openapi';
import { resetData as resetJobs } from '@/profilev2/store/jobsSlice';
import { fetchData as fetchLanguages } from '@/profilev2/store/languagesSlice';
import {
  fetchData as fetchWorkHistory,
  resetData as resetWorkHistory,
} from '@/profilev2/store/workHistorySlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

import { fetchData as fetchAwards, resetData as resetAwards } from './profileAwardsSlice';
import {
  fetchData as fetchCertifications,
  resetData as resetCertifications,
} from './profileCertificationsSlice';
import {
  fetchData as fetchPublications,
  resetData as resetPublications,
} from './profilePublicationsSlice';

let request: CancelablePromise<Profile>;

export const fetchSimpleData = createAsyncThunk(
  'profile/fetchSimpleData',
  async (profileId: string) => {
    request?.cancel();
    request = ProfileService.profileRetrieve(profileId);
    return await Promise.resolve(request);
  }
);

export const fetchCareer = createAsyncThunk(
  'profile/fetchCareer',
  async (profileId: string, { dispatch }) => {
    dispatch(fetchWorkHistory(profileId));
    dispatch(fetchAwards(profileId));
    dispatch(fetchCertifications(profileId));
    dispatch(fetchPublications(profileId));
  }
);

export const fetchKnowledge = createAsyncThunk(
  'profile/fetchKnowledge',
  async (profileId: string, { dispatch }) => {
    // Summary
    dispatch(proposalCounts(profileId));
    dispatch(projectCounts(profileId));
  }
);

export const fetchData = createAsyncThunk(
  'profile/fetchData',
  async (profileId: string, { dispatch }) => {
    request?.cancel();
    request = ProfileService.profileRetrieve(profileId);
    // Give preference to finish asap and clean previous state
    const [result] = await Promise.all([
      request, // result
      dispatch(resetAll()),
    ]);

    dispatch(fetchLanguages({ limit: 1000 }));
    dispatch(fetchKnowledge(profileId));
    dispatch(fetchCareer(profileId));

    return result;
  }
);

export const refresh = createAsyncThunk(
  'profile/refresh',
  async (profileId: string, { dispatch }) => {
    dispatch(fetchSimpleData(profileId));
    dispatch(fetchKnowledge(profileId));
    dispatch(fetchCareer(profileId));
  }
);

export const resetAll = createAsyncThunk('profile/resetAll', async (_, { dispatch }) => {
  dispatch(profileSlice.actions.resetData());

  // Career Experiences
  dispatch(resetJobs());
  dispatch(resetWorkHistory());
  dispatch(resetAwards());
  dispatch(resetCertifications());
  dispatch(resetPublications());
});

let requestProposal: CancelablePromise<PaginatedAssignmentsList>;
export const proposalCounts = createAsyncThunk(
  'profile/proposalCounts',
  async (profileId: string) => {
    requestProposal?.cancel();
    requestProposal = AssignmentsManager.findByProposals({
      profileId,
    });
    return requestProposal.then((data) => data.count);
  }
);

let requestProjects: CancelablePromise<PaginatedAssignmentsList>;
export const projectCounts = createAsyncThunk(
  'profile/projectCounts',
  async (profileId: string) => {
    requestProjects?.cancel();
    requestProjects = AssignmentsManager.findByProjects({
      profileId,
    });
    return requestProjects.then((data) => data.count);
  }
);

export const saveData = createAsyncThunk(
  'profile/saveData',
  async (patchedProfile: PatchedProfileUpdate, { rejectWithValue }) =>
    await ProfileService.profilePartialUpdate(patchedProfile.id as string, patchedProfile).catch(
      () => {
        return rejectWithValue('Unable to save. Please contact support');
      }
    )
);

export const createEducation = createAsyncThunk(
  'profile/createEducation',
  async (payload: Education) => await EducationService.educationCreate(payload)
);

export const updateEducation = createAsyncThunk(
  'profile/updateEducation',
  async (payload: PatchedEducationUpdate) =>
    await EducationService.educationPartialUpdate(payload.id as string, payload)
);

export const deleteEducation = createAsyncThunk('profile/deleteEducation', (id: string) =>
  EducationService.educationDestroy(id).then(() => id)
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState.profile,
  reducers: {
    updateData: (state, action: PayloadAction<Profile>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.profile,
    updateLatestJob: (state, action: PayloadAction<JobSerialized>) => {
      return produce(state, (draft) => {
        if (draft?.data) {
          draft.data.latest_job = action.payload;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(fetchSimpleData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchSimpleData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(saveData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
    }));
    builder.addCase(proposalCounts.pending, (state) => ({
      ...state,
      counts: {
        ...state.counts,
        proposals: 0,
        proposalsIsLoading: true,
      },
    }));
    builder.addCase(proposalCounts.fulfilled, (state, action) => ({
      ...state,
      counts: {
        ...state.counts,
        proposals: action.payload || 0,
        proposalsIsLoading: false,
      },
    }));
    builder.addCase(projectCounts.pending, (state) => ({
      ...state,
      counts: {
        ...state.counts,
        projects: 0,
        projectsIsLoading: true,
      },
    }));
    builder.addCase(projectCounts.fulfilled, (state, action) => ({
      ...state,
      counts: {
        ...state.counts,
        projects: action.payload || 0,
        projectsIsLoading: false,
      },
    }));
    builder.addCase(createEducation.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;
        if (draft.data) {
          draft.data.educations.push({
            ...action.payload,
            id: action.payload.id as string,
            school: action.payload.school_by_name || action.payload.school,
            description: String(action.payload.description),
            majors: action.payload.majors?.map((major) => String(major.name)) || [],
            start_date: action.payload.start_date || '',
            end_date: action.payload.end_date || '',
            degree_type: action.payload.degree_type as DegreeType,
            user_preference_order: 0, // deprecated
          });
        }
      });
    });
    builder.addCase(updateEducation.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        const newData = draft?.data?.educations ?? [];
        const index = newData.findIndex((e) => e.id === action.payload.id);

        if (draft?.data?.educations[index]) {
          draft.data.educations[index] = {
            ...action.payload,
            id: action.payload.id as string,
            description: String(action.payload.description),
            school: action.payload.school_by_name || action.payload.school,
            majors: action.payload.majors?.map((major) => String(major.name)) || [],
            start_date: action.payload.start_date || '',
            end_date: action.payload.end_date || '',
            degree_type: action.payload.degree_type as DegreeType,
            user_preference_order: 0, // deprecated
          };
        }
      });
    });
    builder.addCase(deleteEducation.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        const newData = draft?.data?.educations ?? [];
        const index = newData.findIndex((e) => e.id === action.payload);

        if (index !== -1 && draft?.data?.educations) {
          newData.splice(index, 1);
          draft.data.educations = newData;
        }
      });
    });
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchSimpleData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const profileSelector = (state: IState) => state.profile;
export const { updateData, resetData, updateLatestJob } = profileSlice.actions;
export default profileSlice.reducer;
