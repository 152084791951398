import { useNavigate } from 'react-router-dom';

const useCloseDrawer = (fallbackRoute = '..') => {
  const navigate = useNavigate();

  return () => {
    if (window.history.state.idx > 0) {
      navigate(-1);
      return;
    }

    navigate(fallbackRoute);
  };
};

export default useCloseDrawer;
