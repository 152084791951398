/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Major } from '../models/Major';
import type { PaginatedMajorList } from '../models/PaginatedMajorList';
import type { PatchedMajor } from '../models/PatchedMajor';

export class MajorService {
  /**
   * List all majors
   * List all majors
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param query The query in which you are searching for.
   * @returns PaginatedMajorList
   * @throws ApiError
   */
  public static majorList(
    limit?: number,
    offset?: number,
    query?: string
  ): CancelablePromise<PaginatedMajorList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/major/',
      query: {
        limit: limit,
        offset: offset,
        query: query,
      },
    });
  }

  /**
   * Create a major
   * Create a major
   * @param requestBody
   * @returns Major
   * @throws ApiError
   */
  public static majorCreate(requestBody: Major): CancelablePromise<Major> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/major/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a major by UUID
   * Retrieve a major by UUID
   * @param id A UUID string identifying this major.
   * @returns Major
   * @throws ApiError
   */
  public static majorRetrieve(id: string): CancelablePromise<Major> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/major/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a major by UUID
   * Completely update a major by UUID
   * @param id A UUID string identifying this major.
   * @param requestBody
   * @returns Major
   * @throws ApiError
   */
  public static majorUpdate(id: string, requestBody: Major): CancelablePromise<Major> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/major/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a major by UUID
   * Update specific fields within a major by UUID
   * @param id A UUID string identifying this major.
   * @param requestBody
   * @returns Major
   * @throws ApiError
   */
  public static majorPartialUpdate(
    id: string,
    requestBody?: PatchedMajor
  ): CancelablePromise<Major> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/major/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a major by UUID
   * Delete a major by UUID
   * @param id A UUID string identifying this major.
   * @returns void
   * @throws ApiError
   */
  public static majorDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/major/{id}/',
      path: {
        id: id,
      },
    });
  }
}
