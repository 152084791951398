import React from 'react';
import { connect } from 'react-redux';

import { track } from '@/actions/tracking';
import { white } from '@/theme/colors';

import Button from '../Button/Button';
import Dialog from '../Dialog';
import EmptyMessage from '../EmptyMessage';
import FAIcon from '../Icon/FAIcon';

class InternalNetworkSharePromoDialog extends React.Component {
  handleClick = () => {
    // @ts-expect-error TS(2339): Property 'track' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { track, clickEventTrack = 'promo.click.internal_network.share' } = this.props;
    track(clickEventTrack);
    window.HubSpotConversations?.widget?.open();
  };

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339): Property 'track' does not exist on type 'Readonly<... Remove this comment to see the full error message
      track,
      // @ts-expect-error TS(2339): Property 'showEventTrack' does not exist on type '... Remove this comment to see the full error message
      showEventTrack = 'promo.show.internal_network.share',
    } = this.props;
    if (open && showEventTrack) track(showEventTrack);
  }

  componentDidUpdate(oldProps: any) {
    const {
      // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339): Property 'track' does not exist on type 'Readonly<... Remove this comment to see the full error message
      track,
      // @ts-expect-error TS(2339): Property 'showEventTrack' does not exist on type '... Remove this comment to see the full error message
      showEventTrack = 'promo.show.internal_network.share',
    } = this.props;
    if (!oldProps.open && open && showEventTrack) track(showEventTrack);
  }

  render() {
    const { ...props } = this.props;

    return (
      <Dialog promo {...props}>
        <EmptyMessage
          border={false}
          style={{ padding: 20 }}
          titleStyle={{ color: white, fontSize: 20 }}
          bodyStyle={{ color: white, fontSize: 16 }}
          icon={<FAIcon iconSet="fal" icon="sitemap" size={30} color="rgba(255, 255, 255, .52)" />}
          title="Share your internal network"
          body={
            <React.Fragment>
              Share experts with partners and other organizations on OnFrontiers.
              <br />
              Contact our support team to know more.
            </React.Fragment>
          }
          action={
            <Button
              onClick={this.handleClick}
              fontColor={white}
              backgroundColor="rgba(255, 255, 255, .2)"
              style={{ backgroundColor: 'transparent' }}
            >
              More Info
            </Button>
          }
        />
      </Dialog>
    );
  }
}

export default connect(undefined, {
  track,
})(InternalNetworkSharePromoDialog);
