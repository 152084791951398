import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const GET_BILLING_GROUP = gengql(/* GraphQL */ `
  query getGroup($id: String!) {
    group(id: $id) {
      id
      billing_account {
        id
      }
    }
  }
`);

const CREATE_PORTAL_URL = gengql(/* GraphQL */ `
  mutation createPortalURL($accountId: String!, $path: String!) {
    createPortalURL(account_id: $accountId, path: $path)
  }
`);

const GET_PRICES = gengql(/* GraphQL */ `
  query getPrices($accountId: String) {
    prices(account_id: $accountId) {
      id
      credits {
        cents
        currency
      }
      money {
        cents
        currency
      }
    }
  }
`);

export function fetchBillingAccount(id: any): AppThunk<Promise<any>> {
  const billingAccountQuery = gengql(/* GraphQL */ `
    query getBillingAccount($id: String!) {
      billingAccount(id: $id) {
        id
        type
        entity_type
        seat_count
        state
        purchases {
          remaining {
            cents
            currency
          }
          expires_at
        }
        credit_balance {
          cents
          currency
        }
        transactions {
          id
          created_at
          description
          money {
            cents
            currency
          }
          credits {
            cents
            currency
          }
          tracking_code
          state
        }
      }
    }
  `);

  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(billingAccountQuery, { id });

    dispatch({
      type: ActionTypes.BILLING__FETCH_ACCOUNT,
      billingAccount: result.billingAccount,
    });
  };
}

export function createBillingAccountURL(groupId: string): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { group } = await graphql.query(GET_BILLING_GROUP, { id: groupId });
    const accountId = group.billing_account?.id;
    if (!accountId) {
      throw new Error('Group has no billing account');
    }
    const result = await graphql.mutate(CREATE_PORTAL_URL, {
      accountId,
      path: `/team/${group.id}/settings`,
    });
    return result.createPortalURL;
  };
}

export function fetchPrices(accountId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { prices } = await graphql.query(GET_PRICES, { accountId });

    dispatch({
      type: ActionTypes.BILLING__FETCH_PRICES,
      prices,
    });

    return prices;
  };
}
