import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  CancelablePromise,
  PaginatedSavedCollectionCollaboratorList,
  SavedCollectionCollaboratorsService,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<PaginatedSavedCollectionCollaboratorList>;

export const fetchData = createAsyncThunk(
  'collectionCollaborators/fetchData',
  async ({
    id,
    limit,
    offset,
    ordering,
  }: {
    id: string;
    limit?: number;
    offset?: number;
    ordering?: string;
  }) => {
    request?.cancel();
    request = SavedCollectionCollaboratorsService.savedCollectionCollectionCollaboratorList(
      id,
      limit,
      offset,
      ordering
    );
    return request;
  }
);

const collectionCollaboratorSlice = createSlice({
  name: 'collectionCollaborators',
  initialState: initialState.collectionCollaborator,
  reducers: {
    updateData: (state, action: PayloadAction<PaginatedSavedCollectionCollaboratorList>) => ({
      ...state,
      ...action.payload,
    }),
    resetData: () => initialState.collectionCollaborator,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const collectionCollaboratorSelector = (state: IState) => state.collectionCollaborator;
export const { updateData, resetData } = collectionCollaboratorSlice.actions;
export default collectionCollaboratorSlice.reducer;
