import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import PrivacyForm from '@/profile/components/forms/PrivacyForm';

import SaveForm from './DeprecatedSaveForm';

class Privacy extends PureComponent {
  static propTypes = {
    profileId: PropTypes.string,
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId } = this.props;
    return (
      <PrivacyForm
        component={SaveForm}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        saveMessage="Privacy updated."
        profileId={profileId}
      />
    );
  }
}

export default Privacy;
