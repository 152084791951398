import { PureComponent } from 'react';

import Button from '@/components/Button/Button';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import { teal500, white } from '@/theme/colors';

import s from './TranscriptPromo.module.scss';

const buttonStyle = {
  backgroundColor: white,
  style: {
    color: teal500,
  },
};

interface TranscriptPromoProps {
  onRequestTranscript: () => void;
}

class TranscriptPromo extends PureComponent<TranscriptPromoProps> {
  render() {
    return (
      <div className={s.root}>
        <div className={s.icon}>
          <MaterialIcon icon="speaker_notes" size={40} color={white} style={{ opacity: 0.5 }} />
        </div>

        <div className={s.content}>
          <div className={s.title}>Want a full transcript of this consultation?</div>
          <div className={s.text}>
            Get one for 0.01 credits per minute. Typical turnaround time is 24 hours.
          </div>
          <Button {...buttonStyle} onClick={this.props.onRequestTranscript}>
            Request Transcript
          </Button>
        </div>
      </div>
    );
  }
}

export default TranscriptPromo;
