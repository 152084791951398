/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedAssignmentHasKnowledgeList } from '../models/PaginatedAssignmentHasKnowledgeList';

export class AssignmentHasKnowledgeService {
  /**
   * List all Assignments
   * List all Assignments
   * @param knowledgeId
   * @param knowledgeType The type of knowledge to get the values for
   * @param profileId Filter the assignments by profile id
   * @param assignmentTypes Filter the assignments by a list of pipe-separated assignment type names
   * @param limit Number of results to return per page.
   * @param name Filter the assignments by name. This will match title, work_title name and organization name
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @returns PaginatedAssignmentHasKnowledgeList
   * @throws ApiError
   */
  public static assignmentsHasKnowledgeList(
    knowledgeId: string,
    knowledgeType: 'country' | 'knowledge_raw',
    profileId: string,
    assignmentTypes?: '' | 'Job' | 'Project' | 'Project|Proposal' | 'Proposal',
    limit?: number,
    name?: string,
    offset?: number,
    ordering:
      | ''
      | '-assignment_country'
      | '-dates'
      | '-has_knowledge'
      | '-title'
      | '-title_raw_term_name'
      | 'assignment_country'
      | 'dates'
      | 'has_knowledge'
      | 'title'
      | 'title_raw_term_name' = ''
  ): CancelablePromise<PaginatedAssignmentHasKnowledgeList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/assignments/has_knowledge/{knowledge_type}/{knowledge_id}/',
      path: {
        knowledge_id: knowledgeId,
        knowledge_type: knowledgeType,
      },
      query: {
        assignment_types: assignmentTypes,
        limit: limit,
        name: name,
        offset: offset,
        ordering: ordering,
        profile__id: profileId,
      },
    });
  }
}
