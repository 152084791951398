import { Button as MaterialButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

import Button from '../Button/Button';
import FeatureTile from '../FeatureTile/FeatureTile';
import Link from '../Link';
import MarketingSection from '../MarketingSection';
import s from './HowItWorks.module.scss';

function HowItWorks({ showSignup, ...other }: any) {
  return (
    <MarketingSection
      padding={60}
      preTitle="How it works"
      title="Experts you need, when you need them."
      {...other}
    >
      <Grid container spacing={4}>
        <FeatureTile icon="personexpand" title="Request an expert">
          Specify your questions and ideal expert on any topic
        </FeatureTile>
        <FeatureTile icon="clock" title="Get matches in hours">
          Choose from candidates we vet according to your brief
        </FeatureTile>
        <FeatureTile icon="phone" title="Connect confidentially">
          Dig into your topic and get your questions answered directly
        </FeatureTile>
        <FeatureTile icon="lightbulb" title="Continue engaging">
          Relationships with expert evolve according to your needs
        </FeatureTile>
      </Grid>

      {showSignup && (
        <div className={s.actions}>
          <Button href="/signup" size="large" style={{ marginRight: 20, marginBottom: 20 }}>
            Sign up for free
          </Button>

          <Link to="/platform">
            <MaterialButton style={{ color: 'red' }}>Learn more</MaterialButton>
          </Link>
        </div>
      )}
    </MarketingSection>
  );
}

export default HowItWorks;
