import { useLoaderData } from 'react-router-dom';

import { SignupType } from '@/auth';
import AuthPage from '@/auth/components/AuthPage';
import EmptyMessage from '@/components/EmptyMessage';
import { rewriteUrl } from '@/utils';

const ExpertAuthPage = (): JSX.Element | null => {
  const loaderProps = useLoaderData();
  if (!loaderProps) return null;

  // @ts-expect-error TS(2339) FIXME: Property 'expertRequest' does not exist on type 'u... Remove this comment to see the full error message
  const { expertRequest, signup, tags } = loaderProps;

  return (
    <AuthPage
      signupType={SignupType.Expert}
      nextUrl={rewriteUrl(expertRequest.public_html_url)}
      signup={signup}
      signupLink={`/expert_request/${expertRequest.slug}/refer/signup`}
      signinLink={`/expert_request/${expertRequest.slug}/refer/signin`}
      tags={tags}
    >
      <EmptyMessage
        border={false}
        style={{ padding: 15 }}
        title="Refer friends for paid calls on OnFrontiers."
        body="You’ll receive a $100 bonus to your account once they book a call."
      />
    </AuthPage>
  );
};

export default ExpertAuthPage;
