import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EditMembersForm from '@/components/EditProjectMembers/Form';
import FAIcon from '@/components/Icon/FAIcon';
import MediaQuery from '@/components/MediaQuery';
import { sortMembers } from '@/core/project';
import { black } from '@/theme/colors';
import { SCREEN_XS } from '@/theme/screens';

import s from './AddMembers.module.scss';

const iconStyle = {
  width: 16,
  height: 16,
  fontSize: 16,
  margin: 16 * 0.2,
  color: black,
};

function AddMembers({ viewer, project, expertRequestId }: any) {
  const navigate = useNavigate();
  const projectMembers = project.members || [];
  const membersToEdit = sortMembers(
    projectMembers.filter((m: any) => m.role !== 'expert' && m.state === 'active')
  );

  return (
    <div>
      <div className={s.header}>
        <div className={s.headerIcon}>
          <FAIcon icon="binoculars" style={iconStyle} />
        </div>
      </div>
      <div className={s.header}>We’re working on finding your Expert matches.</div>
      <div className={s.subHeader}>In the meantime, invite your team members to your project.</div>
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion: any) => (
          <EditMembersForm
            project={project}
            initialValues={{ members: membersToEdit }}
            onReset={() => navigate(`/expert_request/${expertRequestId}`)}
            onSubmit={() => navigate(`/expert_request/${expertRequestId}`)}
            usersLocked={[viewer.id]}
            buttonSize={isMobileVersion ? 'small' : 'medium'}
          />
        )}
      </MediaQuery>
    </div>
  );
}

const Component = connect((state) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  viewer: state.viewer,
}))(AddMembers);
export default Component;
