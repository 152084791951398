import Button from '@mui/material/Button';
import { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import Layout from '@/components/Layout';
import Link from '@/components/Link';
import { darkBlue } from '@/theme/colors';

import s from './NotFoundPage.module.scss';

class NotFoundPage extends PureComponent {
  render() {
    return (
      <Layout style={{ backgroundColor: darkBlue }} verticalCenter>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <div className={s.root}>
          <div className={s.container}>
            <h1 className={s.title}>
              We can find an expert in any country, but we can’t find this page.
            </h1>

            <div className={s.reasons}>
              <p className={s.text}>
                <b>Why am I here?</b>
              </p>
              <p className={s.text}>
                You need to <Link to="/login">login</Link> to view this page.
              </p>
              <p className={s.text}>This page moved somewhere else.</p>
              <p className={s.text}>This page never existed.</p>
              <p className={s.text}>You were looking for the 404 page.</p>
            </div>

            <Button href="/" classes={{ root: s.button }}>
              Return To Our Homepage →
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
