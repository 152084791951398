import { useQuery } from '@apollo/client';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { gengql } from '@/__generated__';
import { darkGray } from '@/theme/colors';

import CountryDropdown from '../CountryDropdown';

const phoneUtil = PhoneNumberUtil.getInstance();

function hasCallingCodeChanged(country1: string, country2: string) {
  if (country1 === country2) return false;

  const callingCode1 = phoneUtil.getCountryCodeForRegion(country1);
  const callingCode2 = phoneUtil.getCountryCodeForRegion(country2);

  return callingCode1 !== callingCode2;
}

function getCountryCodeFromNumber(value: any) {
  try {
    const regionCode = phoneUtil.getRegionCodeForCountryCode(parseInt(value));
    if (regionCode && regionCode !== 'ZZ') return regionCode;
    const phoneNumber = phoneUtil.parse(value);
    return phoneUtil.getRegionCodeForNumber(phoneNumber);
  } catch {
    return;
  }
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  callingCode: {
    display: 'inline-block',
    color: darkGray,
    marginLeft: 10,
  },
});

const GET_COUNTRIES = gengql(/* GraphQL */ `
  query getPhoneInputCountries {
    countries {
      id
      name
      country_iso2_code
    }
  }
`);

type PhoneInputProps = Omit<TextFieldProps, 'onChange'> & {
  input?: any;
  showExampleOnError?: boolean;
  containerStyle?: CSSProperties;
  onChange?: (value: string) => void;
};

const PhoneInput = ({
  input,
  showExampleOnError,
  containerStyle,
  helperText,
  ...props
}: PhoneInputProps): JSX.Element => {
  const s = useStyles();
  const inputRef = useRef();

  const defaultCountryCode = getCountryCodeFromNumber(props.value) || 'US';
  const defaultValue = `+${phoneUtil.getCountryCodeForRegion(defaultCountryCode)}`;

  const [countryCode, setCountryCode] = useState(defaultCountryCode);

  const onChange = input ? input.onChange : props.onChange;
  const value = input ? input.value : props.value;

  useEffect(() => {
    const newCountryCode = getCountryCodeFromNumber(value);
    if (newCountryCode && hasCallingCodeChanged(newCountryCode, countryCode)) {
      setCountryCode(newCountryCode);
    }
  }, [countryCode, value]);

  const { data } = useQuery(GET_COUNTRIES);
  const allCountries = useMemo(() => data?.countries || [], [data]);

  useEffect(() => {
    if (!countryInteracted.current) return;

    countryInteracted.current = false;
    const len = value ? value.length : 0;
    const { current } = inputRef;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const inputNode = current.getInputNode ? current.getInputNode() : current;
    inputNode.focus();
    inputNode.setSelectionRange(len, len);
  }, [countryCode, value]);

  const countryInteracted = useRef(false);
  const handleCountryCodeChange = useCallback(
    (countryCode: any) => {
      countryInteracted.current = true;

      const callingCode = phoneUtil.getCountryCodeForRegion(countryCode);

      try {
        const phoneNumber = phoneUtil.parse(value, countryCode);
        phoneNumber.setCountryCode(callingCode);
        onChange(phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL));
      } catch {
        onChange(`+${callingCode}`);
      }

      setCountryCode(countryCode);
    },
    [value, onChange]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;

      const formattedValue = value ? (value.startsWith('+') ? value : `+${value}`) : '';
      onChange(formattedValue);
    },
    [onChange]
  );

  const renderOption = useCallback(
    (countryCode: any) => {
      const country = allCountries.find((c: any) => c.country_iso2_code === countryCode);
      if (!country) return;

      const callingCode = phoneUtil.getCountryCodeForRegion(countryCode);
      if (!callingCode) return;

      return (
        <div>
          {country.name}
          <span className={s.callingCode}> +{callingCode}</span>
        </div>
      );
    },
    [allCountries, s.callingCode]
  );

  const errorWithExample = useCallback(
    (error: any) => {
      if (!showExampleOnError || !error) return error;

      const example = phoneUtil.getExampleNumber(countryCode) || phoneUtil.getExampleNumber('US');

      const exampleFormat = phoneUtil.format(example, PhoneNumberFormat.INTERNATIONAL);

      return `${error}. Example: ${exampleFormat}`;
    },
    [showExampleOnError, countryCode]
  );

  const options = useMemo(
    () => allCountries.map((c: any) => c.country_iso2_code).filter(Boolean),
    [allCountries]
  );

  return (
    <TextField
      {...props}
      value={value || defaultValue}
      slotProps={{
        htmlInput: {
          maxLength: 18,
        },
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <CountryDropdown
              value={countryCode}
              onChange={handleCountryCodeChange}
              options={options}
              renderOption={renderOption}
            />
          </InputAdornment>
        ),
      }}
      helperText={errorWithExample(helperText)}
      onChange={handleChange}
      inputRef={inputRef}
      style={containerStyle}
    />
  );
};

export default PhoneInput;
