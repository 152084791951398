import { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { updateProjectMember } from '@/actions/project';

import MemberRequest from './MemberRequest';

const connector = connect(undefined, {
  updateProjectMember,
});

interface MemberRequestsProps extends ConnectedProps<typeof connector> {
  projectId: string;
  memberRequests: any[];
}

const MemberRequests: FC<MemberRequestsProps> = ({
  projectId,
  memberRequests,
  updateProjectMember,
}) => {
  return memberRequests.map((m: any) => (
    <MemberRequest
      key={m.id}
      user={m.user}
      membersSet="this project"
      onApprove={() => updateProjectMember(projectId, { id: m.id, state: 'active' })}
      onDeny={() => updateProjectMember(projectId, { id: m.id, state: 'denied' })}
    />
  ));
};

export default connector(MemberRequests);
