import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import DateTimePicker from '@/components/DateTimePicker';
import { Checkbox, FileUpload, Select, TextField } from '@/components/FormAdapters/FormAdapters';
import KeywordInput from '@/components/KeywordInput/KeywordInput';
import Link from '@/components/Link';
import { interceptEnter, prettyName } from '@/utils';

const defaultMaxLength = Object.freeze({
  text: 255,
  textarea: 2048,
  file: 200,
  url: 200,
});

const useStyles = makeStyles(() => ({
  chips: {
    margin: '0px !important',
    overflow: 'hidden',
  },
}));

function ModelField({
  name,
  label,
  type,
  component,
  form,
  readOnly,
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  maxLength = defaultMaxLength[type],
  viewer,
  getURL,
  value,
  ...rest
}: any) {
  const s = useStyles();

  const field = useMemo(() => {
    const defaultProps = {
      component: TextField,
      name,
      label: label || prettyName(name),
      InputProps: {
        readOnly,
      },
      inputProps: {
        maxLength,
      },
      onKeyPress: interceptEnter,
      parse: (v: any) => v, // allow clear the input
      ...rest,
      required: false,
      mutators: form?.mutators,
    };

    if (component) {
      return <Field name={name} component={component} />;
    }

    switch (type) {
      case 'file':
        let pseudoName = `file_${name}`;
        if (name.includes('.')) {
          // FieldArray will add "."
          const names = name.split('.');
          const realField = names.pop();
          pseudoName = `${names.join('.')}.file_${realField}`;
        }

        return (
          <Field
            {...defaultProps}
            component={FileUpload}
            name={pseudoName}
            onUpload={(file: any) => {
              form.change(pseudoName, file.url);
              form.change(name, file.url);
            }}
          />
        );
      case 'number':
        return (
          <Field
            {...defaultProps}
            changeOnBlur={false}
            InputProps={{
              ...defaultProps.InputProps,
              type: 'number',
            }}
          />
        );
      case 'select':
        return <Field {...defaultProps} component={Select} type="text" />;
      case 'date':
        // no timezone to save the exact date user picked
        return (
          <Field
            {...defaultProps}
            component={DateTimePicker}
            label={prettyName(name)}
            timezone="UTC"
            clearable
            buttonClear={false}
            fullWidth
            dateOnly
          />
        );
      case 'chips':
        const { push, remove } = form.mutators;
        const { maxChips } = rest;
        const currentLength = form.getFieldState(name)?.value?.length ?? 0;
        return (
          <Field
            component={(props) => (
              // @ts-expect-error
              <KeywordInput
                {...props}
                maxLength={maxChips && currentLength < rest.maxChips ? maxLength : 0}
                onRequestAdd={(value: any) => push(name, value)}
                onRequestDelete={(_: any, index: any) => remove(name, index)}
                className={s.chips}
              />
            )}
            name={name}
            placeholder={defaultProps.label}
          />
        );
      case 'checkbox':
        return <Field {...defaultProps} component={Checkbox} type="checkbox" />;
      case 'textarea':
        return <Field {...defaultProps} changeOnBlur={false} multiline maxRows={6} />;
      case 'edit_link':
        return value ? <Link to={getURL(value)}>Edit</Link> : '';
      case 'text':
      default:
        return <Field {...defaultProps} type="text" changeOnBlur={false} />;
    }
  }, [type, value]);

  return field;
}

export default connect((state) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  viewer: state.viewer,
}))(ModelField);
