import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLoaderData } from 'react-router-dom';

import { hidePopup } from '@/actions/ui';
import { dismissConsultationReview } from '@/consultation/store';
import { RootState } from '@/store';
import { SCREEN_MD } from '@/theme/screens';

import Footer from '../Footer';
import MediaQuery from '../MediaQuery';
import Newsletter from '../Newsletter/Newsletter';
import PageLoadingBar from '../PageLoadingBar/PageLoadingBar';
import ReviewConsultation from '../ReviewConsultation';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import AccessHeader from './AccessHeader';
import s from './Layout.module.scss';
import Popup from './Popup';
import Search from './Search';

function Layout({
  showFooter = true,
  showReviewConsultation = true,
  showNewRequest = true,
  showNewsletter = false,
  viewer,
  verticalCenter,
  children,
  ui,
  footerStyle,
  contentClassName,
  hideSearch,
  unreviewedConsultations,
  dismissedConsultationReviews,
  dismissConsultationReview,
  style,
}: any) {
  const updateIntercomLauncher = () => {
    if (window.Intercom) {
      const width = window.document.body.clientWidth;
      window.Intercom('update', { hide_default_launcher: width <= SCREEN_MD });
    }
  };

  useEffect(() => {
    updateIntercomLauncher();
    window.addEventListener('resize', updateIntercomLauncher);
    return () => {
      window.removeEventListener('resize', updateIntercomLauncher);
    };
  }, []);

  const shouldShowNewRequest = showNewRequest && ui.userContext !== 'expert';

  const is404 = window.location.pathname === '/404';
  const unreviewed = unreviewedConsultations.edges.find(
    (e: any) => !dismissedConsultationReviews.includes(e.node.id)
  );
  const expert = unreviewed && unreviewed.node.expert;
  const requester = unreviewed && unreviewed.node.requester;
  const isViewerExpert = expert && expert.id === viewer.id;
  const userToReview = unreviewed && (isViewerExpert ? requester : expert);

  return (
    <PageLoadingBar>
      <MediaQuery maxWidth={SCREEN_MD}>
        {() => {
          return (
            <div className={s.root} style={style}>
              <AccessHeader
                SearchComponent={hideSearch ? undefined : Search}
                shouldShowNewRequest={shouldShowNewRequest}
              />
              <div
                className={cx(s.content, {
                  [s.verticalCenter]: verticalCenter,
                  [contentClassName]: !!contentClassName,
                })}
              >
                {children}
              </div>

              {showNewsletter && <Newsletter />}

              {showFooter && <Footer style={footerStyle} is404={is404} />}

              {ui.popup && <Popup open {...ui.popup} />}

              {showReviewConsultation && unreviewed && (
                <ReviewConsultation
                  open
                  consultationLink={!userToReview}
                  form="globalReviewConsultation"
                  consultation={unreviewed.node}
                  user={userToReview}
                  onCancel={() => dismissConsultationReview(unreviewed.node.id, false)}
                  isViewerExpert={isViewerExpert}
                />
              )}

              <SnackbarNotification />
            </div>
          );
        }}
      </MediaQuery>
    </PageLoadingBar>
  );
}

Layout.propTypes = {
  user: PropTypes.object,
  showNav: PropTypes.bool,
  showFooter: PropTypes.bool,
  showNewsletter: PropTypes.bool,
  showReviewConsultation: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  verticalCenter: PropTypes.bool,
  viewer: PropTypes.object,
  showNewRequest: PropTypes.bool,
};

// @ts-expect-error TS(2631): Cannot assign to 'Layout' because it is a namespac... Remove this comment to see the full error message
Layout = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    ui: state.ui,
    call: state.call,
    dismissedConsultationReviews: state.consultationReviews.dismissed,
    unreviewedConsultations: state.consultations.unreviewed,
  }),
  {
    hidePopup,
    dismissConsultationReview,
  }
)(Layout);

export default function LayoutWrapper(props: any) {
  const loaderData = useLoaderData();
  let data = {};
  if (loaderData) {
    const { ...rest } = loaderData as Record<string, unknown>;
    delete rest['key'];
    data = rest;
  }
  return <Layout {...props} {...data} />;
}
