import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  KnowledgeRawTermService,
  PaginatedAvailableKnowledgeList,
} from '@/openapi';

export const DEFAULT_KNOWLEDGE_LIMIT = 1000;

type FetchProjectKnowledge = {
  profileId: string;
  projectId: string;
  page?: number;
  limit?: number;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchProjectKnowledgeByProjectId = ({
  profileId,
  projectId,
  page = 1,
  limit = DEFAULT_KNOWLEDGE_LIMIT,
}: FetchProjectKnowledge): CancelablePromise<PaginatedAvailableKnowledgeList> => {
  const offset = (page - 1) * limit;
  return KnowledgeRawTermService.knowledgeRawTermList(
    undefined,
    limit,
    offset,
    profileId,
    projectId
  );
};

export const useProjectKnowledgeQuery = (
  { profileId, projectId, page, limit }: FetchProjectKnowledge,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['project-knowledge', { profileId, projectId, page, limit }],
    queryFn: () => fetchProjectKnowledgeByProjectId({ profileId, projectId, page, limit }),
    keepPreviousData: true,
    enabled: !!projectId,
    ...options,
  });
};
