import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';

import ExpertPicture from '@/components/ExpertPicture';
import FAIcon from '@/components/Icon/FAIcon';
import IconMenu from '@/components/IconMenu';
import InternalNetworksBadge from '@/components/InternalNetworksBadge';
import KeywordList from '@/components/KeywordList';
import Link from '@/components/Link';
import RegionList from '@/components/RegionList';
import SectorList from '@/components/SectorList';
import Tidbit from '@/components/Tidbit';
import { darkGreen } from '@/theme/colors';
import { highlight } from '@/utils';

import Candidates from './Candidates';
import Education from './Education';
import Experiences from './Experiences';
import NetworkExperts from './NetworkExperts';
import s from './ProfileSearchResult.module.scss';

function ProfileSearchResult({
  onSelect,
  result,
  selected,
  selectable,
  showSource,
  showNetworks,
  onProfileShare,
  showAdditionalInformation,
  ...other
}: any) {
  const handleCheck = (_: any, checked: any) => {
    if (onSelect) onSelect(result.profile, checked);
  };

  const { profile } = result;

  const className = cx(s.root, {});

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const isLead = !profile.user;
  const expertNetworks = profile.expert_internal_networks || [];
  const networks = expertNetworks.map((e: any) => e.network) || [];
  const showBadges = isLead || networks.length > 0;

  const sharedExpertNetwork =
    showSource && expertNetworks.find((e: any) => e.from_group && e.from_group.name);

  return (
    <div {...other} className={className} id={`profile-${profile.url_endpoint}`}>
      <div className={s.picture}>
        {selectable && !mobile && (
          <Checkbox checked={selected} onChange={handleCheck} style={{ marginRight: 5 }} />
        )}
        <ExpertPicture user={profile} size={mobile ? 55 : 80} openInNewTab />
      </div>

      <div className={s.info}>
        <div className={s.title}>
          <h3 className={s.name}>
            <Link to={profile.html_url} newTab>
              {result.name}
            </Link>
          </h3>

          {sharedExpertNetwork && (
            <Tooltip
              overlay={`${sharedExpertNetwork.from_group.name} shared this expert with you`}
              trigger={['hover', 'click']}
              placement="top"
            >
              <span className={s.groupSharedFrom}>
                <FAIcon
                  iconSet="fas"
                  icon="building"
                  color={darkGreen}
                  size={14}
                  style={{ marginRight: 5 }}
                />
                {sharedExpertNetwork.from_group.name}
              </span>
            </Tooltip>
          )}
        </div>

        {result.title && (
          <div
            className={s.headline}
            dangerouslySetInnerHTML={{ __html: highlight(result.title) }}
          />
        )}

        <Tidbit
          inline={false}
          showIcon={false}
          className={s.tidbit}
          city={result.city}
          country={result.country}
        />

        <Education education={result.education} />

        <Experiences experiences={result.experiences} />

        {showBadges && (
          <div className={s.badges}>
            {isLead && (
              <div className={s.leadBadge}>
                <FAIcon iconSet="fas" icon="user-lock" size={14} color={darkGreen} /> Unregistered
                Expert
              </div>
            )}

            {showNetworks && (
              <InternalNetworksBadge
                fontSize={16}
                className={s.internalNetworkBadge}
                networks={networks}
                iconColor={darkGreen}
              />
            )}
          </div>
        )}

        {result.summary_snippets && result.summary_snippets.length > 0 && (
          <p
            className={s.summary}
            dangerouslySetInnerHTML={{
              __html: highlight(`(…) ${result.summary_snippets.join(' (…) ')}…`),
            }}
          />
        )}

        {result.cv_snippets && result.cv_snippets.length > 0 && (
          <p className={s.summary}>
            <i>From CV file: </i>
            <span
              dangerouslySetInnerHTML={{
                __html: highlight(`(…) ${result.cv_snippets.join(' (…) ')}…`),
              }}
            />
          </p>
        )}

        {showAdditionalInformation &&
          result.additional_information_snippets &&
          result.additional_information_snippets.length > 0 && (
            <p className={s.summary}>
              <i>From Additional Information: </i>
              <span
                dangerouslySetInnerHTML={{
                  __html: highlight(`(…) ${result.additional_information_snippets.join(' (…) ')}…`),
                }}
              />
            </p>
          )}

        {((result.keywords && result.keywords.length > 0) ||
          (result.group_keywords && result.group_keywords.length > 0)) && (
          <div className={s.keywords}>
            <KeywordList
              keywords={result.keywords}
              highlightedKeywords={result.group_keywords}
              breakLine
            />
          </div>
        )}

        {result.sectors && result.sectors.length > 0 && (
          <div>
            <SectorList sectors={result.sectors} />
          </div>
        )}

        {result.regions && result.regions.length > 0 && (
          <div>
            <RegionList regions={result.regions} />
          </div>
        )}

        <Candidates expert={profile} candidates={result.candidates} />

        <NetworkExperts expert={profile} networkExperts={result.network_experts} />

        <div className={s.actions}>
          <IconMenu>
            <MenuItem onClick={onProfileShare}>Manage Access</MenuItem>
          </IconMenu>
        </div>
      </div>
    </div>
  );
}

export default ProfileSearchResult;
