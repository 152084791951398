import cx from 'classnames';
import React from 'react';
import { Field } from 'redux-form';

import AddButton from '@/profile/components/forms/buttons/AddButton';
import RemoveButton from '@/profile/components/forms/buttons/RemoveButton';

import { TextField } from '../FormAdapters/FormAdapters';
import s from './EditableList.module.scss';

function Input({
  showRemove,
  name,
  index,
  onAdd,
  onRemove,
  inputProps,
  inputComponent,
  autoFocus,
  className,
  removeClassName,
  field,
  value,
}: any) {
  const InputComponent = inputComponent;
  const FieldComponent = field || Field;

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAdd();
    }
  };

  return (
    <div className={cx(s.container, className)}>
      <div className={cx(s.removeAction, removeClassName)}>
        {showRemove && <RemoveButton className={s.removeButton} onClick={onRemove} />}
      </div>

      {InputComponent ? (
        <InputComponent name={name} index={index} onAdd={onAdd} value={value} {...inputProps} />
      ) : (
        <FieldComponent
          id={name}
          name={name}
          index={index}
          component={TextField}
          className={s.input}
          autoFocus={autoFocus}
          onKeyPress={handleKeyPress}
          {...inputProps}
        />
      )}
    </div>
  );
}

function EditableList({
  fields,
  inputProps,
  minLength = 0,
  label,
  addButtonLabel,
  multi,
  inputComponent,
  style,
  className,
  itemClassName,
  removeClassName,
  disabled,
  showRemoveForField = () => true,
}: any) {
  // patch for react-final-form
  if (fields.get === undefined) {
    fields.get = (index: any) => fields.value[index];
  }
  if (fields.getAll === undefined) {
    fields.getAll = () => fields.value;
  }

  const handleAdd = () => {
    // fields.getAll() doesn't update properly
    const values = Array.from({ length: fields.length }, (v, i) => fields.get(i));
    const hasEmptyValue = values.some((x) => (multi ? !Object.keys(x).length : !x));
    if (!hasEmptyValue) {
      fields.push(multi ? {} : '');
      // https://github.com/callemall/material-ui/issues/5793
      setTimeout(() => {
        // @ts-expect-error TS(2774): This condition will always return true since this ... Remove this comment to see the full error message
        if (Event.prototype.initEvent) {
          const evt = window.document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        } else {
          window.dispatchEvent(new Event('resize'));
        }
      }, 0);
    }
  };

  const showRemove = fields.length > minLength;

  const inputPropsFunction = typeof inputProps === 'function' ? inputProps : () => inputProps;

  return (
    <>
      <div style={style} className={className}>
        {}
        {label && <label className={s.label}>{label}</label>}

        {fields.map((name: any, index: any) => (
          <Input
            key={name}
            inputComponent={inputComponent}
            name={name}
            index={index}
            inputProps={inputPropsFunction(fields.get(index), index, fields.getAll())}
            onAdd={handleAdd}
            onRemove={() => fields.remove(index)}
            autoFocus={index === fields.length - 1}
            showRemove={!disabled && showRemove && showRemoveForField(fields.get(index))}
            className={itemClassName}
            removeClassName={removeClassName}
            value={fields.get(index)}
          />
        ))}
      </div>
      {!disabled && (
        <div className={s.addAction}>
          <AddButton label={addButtonLabel} onClick={handleAdd} />
        </div>
      )}
    </>
  );
}

export default EditableList;
