import Chip, { ChipProps } from '@mui/material/Chip';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import moment from 'moment-timezone';
import { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { suggestProfiles } from '@/actions/suggestion';
import { RootState } from '@/store';
import { teal500 } from '@/theme/colors';
import { isEmailValid } from '@/utils';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link/Link';
import Picture from '../Picture';
import SelectWithSuggestions from '../SelectWithSuggestions/SelectWithSuggestions';
import s from './SelectProfile.module.scss';

const styles = {
  picture: {
    marginRight: 10,
  },
};

interface ProfileChipProps extends ChipProps {
  profile: any;
  showExpertState?: boolean;
  showURLEndpoint?: boolean;
  disabled?: boolean;
}

class ProfileChip extends PureComponent<ProfileChipProps> {
  render() {
    const { profile, showExpertState, showURLEndpoint, disabled, ...other } = this.props;

    const expertState = profile.user ? profile.user.expert_state || 'Not applied' : 'Unregistered';

    let label = profile.name;
    label = showURLEndpoint ? `${label} (${profile.url_endpoint})` : label;
    label = showExpertState ? `${label} (${expertState})` : label;
    return (
      <Chip
        {...other}
        avatar={<Picture user={profile} link={false} size={24} />}
        label={label}
        disabled={disabled}
      />
    );
  }
}

interface EmailChipProps extends ChipProps {
  email: string;
}

class EmailChip extends PureComponent<EmailChipProps> {
  render() {
    const { email, disabled, ...other } = this.props;
    return (
      <Chip
        {...other}
        avatar={<Picture user={null} link={false} size={24} />}
        label={`${email} (New)`}
        disabled={disabled}
      />
    );
  }
}

interface ProfileMenuItemProps extends MenuItemProps {
  profile: any;
  showExpertState?: boolean;
  showCreationTimestamp?: boolean;
  showURLEndpoint?: boolean;
}

class ProfileMenuItem extends PureComponent<ProfileMenuItemProps> {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const { profile, showCreationTimestamp, showExpertState, showURLEndpoint, ...other } =
      this.props;

    const expertState = profile.user ? profile.user.expert_state || 'Not applied' : 'Unregistered';

    return (
      <MenuItem {...other}>
        <span className={s.root}>
          <Picture style={styles.picture} size={40} user={profile} link={false} />
          <span>{profile.name}</span>
          {showURLEndpoint && <small>&nbsp;({profile.url_endpoint})</small>}
          {showExpertState && <small className={s.expertState}>&nbsp;({expertState})</small>}
          {showCreationTimestamp && (
            <small className={s.date}>
              &nbsp;({moment(profile.created_at).format('YYYY-MM-DD LT')})
            </small>
          )}
          &nbsp;&nbsp;
          <Link style={{ zIndex: 999 }} force target="_blank" to={profile.html_url}>
            <FAIcon size={14} iconSet="far" color={teal500} icon="external-link-square" />
          </Link>
        </span>
      </MenuItem>
    );
  }
}

interface EmailMenuItemProps extends MenuItemProps {
  email: string;
}

class EmailMenuItem extends PureComponent<EmailMenuItemProps> {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const { email, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span className={s.root}>
          <Picture style={styles.picture} size={40} link={false} />
          <span>{email}</span>&nbsp;
        </span>
      </MenuItem>
    );
  }
}

const connector = connect(
  (state: RootState) => {
    const { loading: loadingSuggestions, cache: profileSuggestions } = state.suggestions.profiles;

    return {
      profileSuggestions,
      loadingSuggestions,
    };
  },
  {
    suggestProfiles,
  }
);

class SelectProfile extends PureComponent<ConnectedProps<typeof connector>> {
  renderOption = (props: any, { type, value }: any) => {
    if (type === 'email') {
      return <EmailMenuItem {...props} email={value.email} />;
    }

    // @ts-expect-error TS(2339): Property 'showCreationTimestamp' does not exist on... Remove this comment to see the full error message
    const { showCreationTimestamp, showExpertState, showURLEndpoint } = this.props;

    // profile
    return (
      <ProfileMenuItem
        {...props}
        showExpertState={showExpertState}
        showCreationTimestamp={showCreationTimestamp}
        showURLEndpoint={showURLEndpoint}
        profile={value}
      />
    );
  };

  renderTags = (value: any, getTagProps: any) => {
    // @ts-expect-error TS(2339): Property 'showExpertState' does not exist on type ... Remove this comment to see the full error message
    const { showExpertState, showURLEndpoint, disabled } = this.props;

    return value.map(({ type, value }: any, index: any) => {
      if (type === 'email') {
        return (
          <EmailChip
            key={value.email}
            email={value.email}
            {...getTagProps({ index })}
            disabled={disabled}
          />
        );
      }
      return (
        <ProfileChip
          key={value.id}
          profile={value}
          showURLEndpoint={showURLEndpoint}
          showExpertState={showExpertState}
          {...getTagProps({ index })}
          disabled={disabled}
        />
      );
    });
  };

  formatOption = ({ type, value }: any) => {
    if (type === 'email') {
      // email
      return {
        type,
        label: value.email,
        value,
      };
    }

    // @ts-expect-error TS(2339): Property 'showCreationTimestamp' does not exist on... Remove this comment to see the full error message
    const { showCreationTimestamp, showExpertState } = this.props;
    const timestamp = showCreationTimestamp
      ? ` (${moment(value.created_at).format('YYYY-MM-DD LT')})`
      : '';
    const expertState = showExpertState
      ? ` (${value.user ? value.user.expert_state || 'Not applied' : 'Unregistered'})`
      : '';
    const label = `${value.name}${expertState}${timestamp}`;

    // profile
    return {
      type,
      label,
      value,
    };
  };

  isValidEntry = (value: any) => {
    // @ts-expect-error TS(2339): Property 'allowEmail' does not exist on type 'Read... Remove this comment to see the full error message
    const { allowEmail = false, suggestionsFilter = () => true } = this.props;

    if (!suggestionsFilter(value)) return false;

    const type = value.type || 'email';
    const rawValue = value.value || value;
    if (
      type === 'email' && // not expecting emails or invalid email
      (!allowEmail || !isEmailValid(rawValue.email))
    )
      return false;

    return true;
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'allowEmail' does not exist on type 'Read... Remove this comment to see the full error message
      allowEmail = false,
      // @ts-expect-error TS(2339): Property 'allowProfile' does not exist on type 'Re... Remove this comment to see the full error message
      allowProfile = true,
      suggestProfiles,
      // @ts-expect-error TS(2339): Property 'suggestBufferSize' does not exist on typ... Remove this comment to see the full error message
      suggestBufferSize,
      // @ts-expect-error TS(2339): Property 'profileType' does not exist on type 'Rea... Remove this comment to see the full error message
      profileType = undefined,
      // @ts-expect-error TS(2339): Property 'suggestionsFilter' does not exist on typ... Remove this comment to see the full error message
      suggestionsFilter = () => true,
      profileSuggestions,
      loadingSuggestions,
      // @ts-expect-error TS(2339): Property 'errorText' does not exist on type 'Reado... Remove this comment to see the full error message
      errorText = undefined,
      // @ts-expect-error TS(2339): Property 'multiple' does not exist on type 'Readon... Remove this comment to see the full error message
      multiple,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'underlineShow' does not exist on type 'R... Remove this comment to see the full error message
      underlineShow = true,
      ...rest
    } = this.props;

    return (
      <SelectWithSuggestions
        {...rest}
        allowRaw={allowEmail}
        allowSuggestion={allowProfile}
        suggest={suggestProfiles}
        suggestBufferSize={suggestBufferSize}
        suggestScope="profiles"
        suggestArgs={[profileType]}
        suggestionsFilter={suggestionsFilter}
        suggestions={profileSuggestions}
        loadingSuggestions={loadingSuggestions}
        errorText={errorText}
        multiple={multiple}
        disabled={disabled}
        underlineShow={underlineShow}
        renderTags={this.renderTags}
        renderOption={this.renderOption}
        formatOption={this.formatOption}
        isValidEntry={this.isValidEntry}
        isValidRaw={isEmailValid}
        rawType="email"
        rawKey="email"
        suggestionType="profile"
      />
    );
  }
}

export default connector(SelectProfile);
