import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Dialog from '@/components/Dialog';
import { TextField } from '@/components/FormAdapters/FormAdapters';
import { darkGreen } from '@/theme/colors';

import s from './CancelConsultation.module.scss';

class CancelConsultation extends React.Component {
  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339) FIXME: Property 'showPolicy' does not exist on type 'Read... Remove this comment to see the full error message
      showPolicy,
      // @ts-expect-error TS(2339) FIXME: Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
      onCancel,
      // @ts-expect-error TS(2339) FIXME: Property 'onConfirm' does not exist on type 'Reado... Remove this comment to see the full error message
      onConfirm,
      // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
      title = 'Cancel Call',
      // @ts-expect-error TS(2339) FIXME: Property 'confirmLabel' does not exist on type 'Re... Remove this comment to see the full error message
      confirmLabel = 'Yes, Cancel',
      // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label = 'What is your reason for cancelling?',
    } = this.props;

    return (
      <Dialog
        open={open}
        title={title}
        confirmLabel={confirmLabel}
        confirmButtonProps={{ color: 'red' }}
        onClose={onCancel}
        onCancel={onCancel}
        onConfirm={handleSubmit(onConfirm)}
      >
        {showPolicy && (
          <p className={s.message}>
            Please review our&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: darkGreen }}
              href="https://try.onfrontiers.com/knowledge/cancellations-no-shows"
            >
              cancellation policy
            </a>
            .
          </p>
        )}

        <Field component={TextField} multiline fullWidth name="cancel_reason" label={label} />
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'cancelConsultation',
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof CancelConsultation' is no... Remove this comment to see the full error message
})(CancelConsultation);
