import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

import { borderColor, darkBrown, primary, red500, white } from '@/theme/colors';

const colorTypes = {
  success: primary,
  error: red500,
};

const useStyles = makeStyles({
  root: {
    padding: 20,
    marginBottom: 30,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    backgroundColor: white,
  },
  alert: {
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    fontWeight: 'bold',
    fontSize: 20,
    color: white,
  },
  notification: {
    border: `1px solid ${borderColor}`,
    color: darkBrown,
    fontSize: 16,
  },
  message: {
    padding: 0,
    width: '100%',
  },
  icon: {
    padding: 0,
  },
});

interface AlertProps extends MuiAlertProps {
  children: ReactNode;
  type?: 'success' | 'error';
  preset?: string;
  messageClassName?: string;
  style?: CSSProperties;
  onRequestClose?: () => void;
  center?: boolean;
  backgroundColor?: string;
}

const Alert: FC<AlertProps> = ({
  children,
  type,
  preset = 'alert',
  messageClassName,
  style,
  onRequestClose,
  center,
  backgroundColor,
  ...other
}) => {
  const s = useStyles();

  return (
    <MuiAlert
      icon={false}
      {...other}
      style={{
        textAlign: center ? 'center' : undefined,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        backgroundColor: backgroundColor || colorTypes[type],
        ...style,
      }}
      classes={{
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        root: cx(s.root, s[preset]),
        message: cx(s.message, messageClassName),
        icon: s.icon,
      }}
      action={
        onRequestClose && (
          <IconButton aria-label="close" color="inherit" size="small" onClick={onRequestClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
      data-sentry-unmask
    >
      {children}
    </MuiAlert>
  );
};

export default Alert;
