import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

/**
 * @param {number} userId
 * @returns {Promise<{ payouts_enabled: boolean }>} payoutAccount
 */
export function payoutAccountByUserID(userId: any): AppThunk<Promise<any>> {
  const getPayoutAccountQuery = gengql(/* GraphQL */ `
    query getPayoutAccount($userId: String!) {
      payoutAccountByUserID(id: $userId) {
        id
        payouts_enabled
      }
    }
  `);
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(getPayoutAccountQuery, { userId });
    return result.payoutAccountByUserID;
  };
}

/**
 * @param {number} userId
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutLoginURLByUser(userId: any): AppThunk<Promise<any>> {
  const createPayoutLoginURLQuery = gengql(/* GraphQL */ `
    mutation createPayoutLoginURL($userId: String!) {
      createPayoutLoginURL(user_id: $userId)
    }
  `);

  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(createPayoutLoginURLQuery, { userId });
    return result.createPayoutLoginURL;
  };
}

/**
 * @param {number} userId
 * @param {string} type
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutAccountURLByUserAndType(
  userId: any,
  type: any
): AppThunk<Promise<any>> {
  const createPayoutAccountURLQuery = gengql(/* GraphQL */ `
    mutation createPayoutAccountURL($userId: String!, $type: PayoutAccountURLType!) {
      createPayoutAccountURL(user_id: $userId, type: $type)
    }
  `);

  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(createPayoutAccountURLQuery, {
      userId,
      type,
    });
    return result.createPayoutAccountURL;
  };
}
