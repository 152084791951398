import PropTypes from 'prop-types';
import React from 'react';

import WizardPage from '@/components/WizardPage';
import { safeHtml } from '@/utils';

import s from './ComplianceTrainingStep.module.scss';

class ComplianceTrainingStep extends React.Component {
  static propTypes = {
    html: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'html' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { html, onNext } = this.props;

    return (
      <WizardPage title="Expert Compliance Training" onNext={onNext}>
        <div
          className={s.root}
          // @ts-expect-error TS(2322) FIXME: Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          dangerouslySetInnerHTML={{ __html: safeHtml(html) }}
        />
      </WizardPage>
    );
  }
}

export default ComplianceTrainingStep;
