import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  PaginatedProfileEmailList,
  ProfileEmail,
  ProfileEmailService,
} from '@/openapi';

const DEFAULT_PROFILES_LIMIT = 10;

type FetchProfilesByEmail = {
  query: string;
  page?: number;
  limit?: number;
  signal?: AbortSignal;
};

export type ProfilesUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  keepPreviousData?: boolean;
  refetchOnMount?: boolean | 'always';
};

const fetchProfilesByEmail = ({
  query,
  page = 1,
  limit = DEFAULT_PROFILES_LIMIT,
  signal,
}: FetchProfilesByEmail): CancelablePromise<PaginatedProfileEmailList> => {
  const offset = (page - 1) * limit;
  const response = ProfileEmailService.profileEmailList(limit, offset, query);
  if (signal) {
    signal.addEventListener('abort', () => response.cancel());
  }
  return response;
};

export const useProfilesByEmailQuery = (
  { query, page, limit }: FetchProfilesByEmail,
  options?: ProfilesUseQueryOptions
) => {
  return useQuery({
    queryKey: ['profiles-by-email', { query, page, limit }],
    queryFn: query
      ? async ({ signal }) =>
          fetchProfilesByEmail({
            query,
            page,
            limit,
            signal,
          })
      : () => ({
          count: 0,
          next: null,
          previous: null,
          results: [] as ProfileEmail[],
        }),
    keepPreviousData: true,
    ...options,
  });
};
