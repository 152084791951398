import makeStyles from '@mui/styles/makeStyles';
import Tooltip from 'rc-tooltip';
import { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { updateGroupAboutPage } from '@/actions/group';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link/Link';
import { presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import { darkGreen } from '@/theme/colors';
import { safeUrl } from '@/utils';

import Logo from './Logo';

const useStyles = makeStyles(() => ({
  textField: {
    display: 'block',
    maxWidth: 350,
  },
}));

function AboutPage({ aboutPage, group, updateGroupAboutPage, notify, isViewerAdmin }: any) {
  const s = useStyles();

  const { graphql } = useApp();

  const initialValues = useMemo(() => {
    return {
      enabled: aboutPage.enabled,
      public: aboutPage.public,
      search_engine_index: aboutPage.search_engine_index,
      logo_link_url: aboutPage.logo_link_url,
      externalBodyLogoUrl: aboutPage.body_logo_url,
    };
  }, [aboutPage]);

  const validate = useCallback(({ logo_link_url: logoLinkUrl }: { logo_link_url: string }) => {
    const errors = {};
    if (logoLinkUrl && !safeUrl(logoLinkUrl)) {
      // @ts-expect-error TS(2339) FIXME: Property 'logo_link_url' does not exist on type '{... Remove this comment to see the full error message
      errors.logo_link_url = 'Please provide a valid web page URL';
    }
    return errors;
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      await updateGroupAboutPage({ group_id: group.id, ...values });

      notify('Team settings updated.', 'success');
    } catch {
      notify('Error when updating team settings.', 'error');
    }
  };

  const helpIcon = useMemo(
    () => (
      <FAIcon
        iconSet="far"
        icon="question-circle"
        size={14}
        color={darkGreen}
        style={{ marginLeft: 8 }}
      />
    ),
    []
  );

  const labelEnabled = useMemo(
    () => (
      <>
        Enabled
        {aboutPage.html_url && (
          <Link newTab to={aboutPage.html_url}>
            <FAIcon
              iconSet="far"
              icon="external-link"
              size={14}
              color={darkGreen}
              style={{ marginLeft: 8 }}
            />
          </Link>
        )}
      </>
    ),
    [aboutPage.html_url]
  );

  const labelPublic = useMemo(
    () => (
      <>
        Public
        <Tooltip
          overlay={
            <div style={{ maxWidth: 300 }}>
              When not public, only team members and experts in your network are able to view this
              page.
            </div>
          }
          trigger={['hover', 'click']}
          placement="right"
        >
          {helpIcon}
        </Tooltip>
      </>
    ),
    [helpIcon]
  );

  const labelSearchIndex = useMemo(
    () => (
      <>
        Allow indexing by search engines
        <Tooltip
          overlay={
            <div style={{ maxWidth: 300 }}>
              When enabled, the page will appear on search results. The &quot;Public&quot; setting
              must be enabled.
            </div>
          }
          trigger={['hover', 'click']}
          placement="right"
        >
          {helpIcon}
        </Tooltip>
      </>
    ),
    [helpIcon]
  );

  return (
    <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
      {({ form, handleSubmit, values }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Field
              type="checkbox"
              component={Checkbox}
              label={labelEnabled}
              name="enabled"
              FormControlProps={{ margin: 'dense' }}
            />

            <Field
              type="checkbox"
              component={Checkbox}
              label={labelPublic}
              name="public"
              FormControlProps={{ margin: 'dense' }}
              onChange={(_: any, checked: any) => {
                // @ts-expect-error TS(2345) FIXME: Argument of type '"public"' is not assignable to p... Remove this comment to see the full error message
                form.change('public', checked);
                if (!checked) {
                  // @ts-expect-error TS(2345) FIXME: Argument of type '"search_engine_index"' is not as... Remove this comment to see the full error message
                  form.change('search_engine_index', false);
                }
              }}
            />

            <Field
              type="checkbox"
              component={Checkbox}
              // @ts-expect-error TS(2339) FIXME: Property 'public' does not exist on type '{ logo_l... Remove this comment to see the full error message
              disabled={!values.public}
              label={labelSearchIndex}
              name="search_engine_index"
              FormControlProps={{ margin: 'dense' }}
            />

            <Logo
              label="Logo"
              // @ts-expect-error TS(2339) FIXME: Property 'externalBodyLogoUrl' does not exist on t... Remove this comment to see the full error message
              src={values.externalBodyLogoUrl}
              onChange={async (file: any) => {
                const bodyLogoUrl = await presignAttachmentURL(graphql.client, file.url);
                // @ts-expect-error TS(2345) FIXME: Argument of type '"body_logo_url"' is not assignab... Remove this comment to see the full error message
                form.change('body_logo_url', file.url);
                // @ts-expect-error TS(2345) FIXME: Argument of type '"externalBodyLogoUrl"' is not as... Remove this comment to see the full error message
                form.change('externalBodyLogoUrl', bodyLogoUrl);
              }}
              alternateSvgUpload={isViewerAdmin}
            />

            <Field
              component={TextField}
              name="logo_link_url"
              label="Logo Link URL"
              placeholder="https://yourorganization.com"
              classes={{ root: s.textField }}
              parse={(v) => v}
            />

            <Button type="submit" style={{ marginTop: 20 }} size="medium">
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'AboutPage' because it is a funct... Remove this comment to see the full error message
AboutPage = connect(
  // @ts-expect-error TS(2339) FIXME: Property 'groupId' does not exist on type '{}'.
  (state: RootState, { groupId }) => {
    const group = state.groups.default.edges.find((e: any) => e.node.id === groupId).node;

    return {
      group,
      aboutPage: group.about_page || {},
    };
  },
  {
    updateGroupAboutPage,
    notify,
  }
)(AboutPage);

export default AboutPage;
