import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { dismissAppNotification, readAppNotifications } from '@/actions/appNotification';

import Alert from '../Alert';
import Button from '../Button';
import Picture from '../Picture';
import s from './ExpertAcceptedInvitationNotification.module.scss';

const ExpertAcceptedInvitationNotification = (props: any) => {
  const navigate = useNavigate();
  const { profile } = props.notification.payload;
  if (!profile) return null;

  useEffect(() => {
    const { notification, readAppNotifications } = props;
    if (!notification.read_at) {
      readAppNotifications(notification.id);
    }
  }, []);

  const handleCloseClick = () => {
    const { dismissAppNotification, notification } = props;
    dismissAppNotification(notification.id);
  };

  const handleRequestExpert = async () => {
    const { dismissAppNotification, notification } = props;
    await dismissAppNotification(notification.id);
    navigate(`/profile/${notification.payload.profile.url_endpoint}/request`);
  };

  return (
    <Alert preset="notification" onRequestClose={handleCloseClick}>
      <div className={s.notificationContents}>
        <div className={s.notificationPhoto}>
          <Picture user={profile} size={80} />
        </div>
        <div>
          <div className={s.notificationTitle}>
            <span className={s.expertName}>{profile.name}</span> has joined your expert network!
            You&rsquo;re now able to request calls without paying our platform booking fee.
          </div>
          <Button onClick={handleRequestExpert} size="medium">
            Request Expert
          </Button>
        </div>
      </div>
    </Alert>
  );
};

const ConnectedComponent = connect(undefined, {
  readAppNotifications,
  dismissAppNotification,
})(ExpertAcceptedInvitationNotification);

// @ts-expect-error TS(2339): Property 'filterNotifications' does not exist on t... Remove this comment to see the full error message
ConnectedComponent.filterNotifications = (state: RootState, notifications: any) => {
  // notification must have accessible expert to be accepted
  return notifications.filter((n: any) => n.payload.profile);
};

export default ConnectedComponent;
