import { logout } from '@/auth';

export default function LogoutPage() {
  return null;
}

export async function logoutPageLoader() {
  logout();

  // KT: note that we can't simply perform a router redirect here because we need the app to be
  // reloaded with a new viewer.
  window.location.assign('/login');
  return null;
}
