import IconButton from '@mui/material/IconButton';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { memo, useState } from 'react';
import { FieldRenderProps, Field as FinalField } from 'react-final-form';

import { PasswordHelpInputAdornment } from '@/auth/components/PasswordHelp';
import { TextField, areEqual } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import { darkGray } from '@/theme/colors';

interface ShowPasswordProps {
  id: string;
  showPassword?: boolean;
  toggleShowPassword: () => void;
}

const ShowPassword = ({ showPassword, toggleShowPassword, id }: ShowPasswordProps) => (
  <IconButton id={id} onClick={toggleShowPassword}>
    <FAIcon iconSet="fal" icon={showPassword ? 'eye-slash' : 'eye'} color={darkGray} size={20} />
  </IconButton>
);

const PasswordHelpingAndShowing = ({ showPassword, toggleShowPassword, id }: ShowPasswordProps) => (
  <PasswordHelpInputAdornment id={id}>
    <ShowPassword showPassword={showPassword} toggleShowPassword={toggleShowPassword} id={id} />
  </PasswordHelpInputAdornment>
);

export type PasswordTextFieldProps = MuiTextFieldProps & {
  endAdornmentId: string;
  current?: boolean;
};

export const PasswordTextField = ({
  endAdornmentId,
  current = true,
  ...input
}: PasswordTextFieldProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <MuiTextField
      type={showPassword ? 'text' : 'password'}
      inputProps={{
        autocomplete: current ? 'current-password' : 'new-password',
      }}
      {...input}
      InputProps={{
        style: {
          paddingRight: 0,
        },

        endAdornment: (
          <>
            <ShowPassword
              id={endAdornmentId}
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
            />
          </>
        ),
      }}
    />
  );
};

interface PasswordTextProps {
  id: string;
  variant: 'standard' | 'outlined' | 'filled';
  changeOnBlur: boolean;
  label: string;
  showHelp: boolean;
  current?: boolean;
}

export const PasswordText = memo(function PasswordText({
  input,
  id,
  variant,
  changeOnBlur,
  label,
  showHelp,
  current = true,
  ...rest
}: PasswordTextProps & FieldRenderProps<string>) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <FinalField
      id={id}
      variant={variant}
      component={TextField}
      changeOnBlur={changeOnBlur}
      name={input.name}
      label={label}
      inputType={showPassword ? 'text' : 'password'}
      inputProps={{
        autocomplete: current ? 'current-password' : 'new-password',
      }}
      {...rest}
      InputProps={{
        endAdornment: (
          <>
            {showHelp ? (
              <PasswordHelpingAndShowing
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                id={id}
              />
            ) : (
              <ShowPassword
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                id={id}
              />
            )}
          </>
        ),
      }}
    />
  );
}, areEqual);
