import React from 'react';
import { Field } from 'react-final-form';

import { Checkbox } from '@/components/FormAdapters';

type IncludeInvitationEmailFieldProps = {
  isAdmin: boolean;
  checked: boolean;
};

export const IncludeInvitationEmailField: React.FC<IncludeInvitationEmailFieldProps> = ({
  isAdmin,
  checked,
}) => {
  if (!isAdmin) return null;

  return (
    <Field
      type="checkbox"
      component={Checkbox}
      name="send_invitation_email"
      label="Include invitation email"
      checked={checked}
    />
  );
};
IncludeInvitationEmailField.displayName = 'IncludeInvitationEmailField';
