import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';

import { borderColor, white } from '@/theme/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px dashed ${borderColor}`,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    minHeight: 317,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      minHeight: 285,
    },
  },
  content: {
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      padding: 15,
    },
  },
  floating: {
    position: 'absolute',
    top: -7,
    left: 5,
    fontSize: 12,
    background: white,
    display: 'inline',
    padding: '0 5px',
  },
}));

export default function ImageOutline({ label, children, onClick }: any) {
  const s = useStyles();

  return (
    <div className={s.root} onClick={onClick}>
      {label && <InputLabel className={s.floating}>{label}</InputLabel>}
      <div className={s.content}>{children}</div>
    </div>
  );
}
