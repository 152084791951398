import cx from 'classnames';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { TextField } from '@/components/FormAdapters';
import YearPicker from '@/components/YearPicker';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';

import s from './Education.module.scss';
import AddButton from './buttons/AddButton';
import RemoveButton from './buttons/RemoveButton';

function Col({ className, children, ...other }: any) {
  return (
    <div className={cx(s.col, { [className]: !!className })} {...other}>
      <div className={s.colContainer}>{children}</div>
    </div>
  );
}

function Education({ field, onRemove, showRemove, showAdd, onAdd }: any) {
  return (
    <>
      <div className={s.root}>
        <div className={s.removeAction}>
          {showRemove && <RemoveButton backgroundColor={red500} onClick={onRemove} />}
        </div>

        <div className={s.content}>
          <div className={s.row}>
            <Col>
              <Field component={TextField} fullWidth name={`${field}.degree`} label="Degree" />
            </Col>

            <Col>
              <Field
                component={TextField}
                fullWidth
                name={`${field}.field_of_study`}
                label="Field of study"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field component={TextField} fullWidth name={`${field}.school`} label="School" />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <YearPicker current offset={0} name={`${field}.start_date`} label="Start year" />
            </Col>

            <Col>
              <YearPicker
                current={false}
                offset={8}
                name={`${field}.end_date`}
                label="End year (or expected)"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                name={`${field}.description`}
                component={TextField}
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                label="Describe what you learned here"
              />
            </Col>
          </div>
        </div>
      </div>

      <div className={s.addAction}>
        {showAdd && <AddButton label="Education" onClick={onAdd} />}
      </div>
    </>
  );
}

export default connect((state: RootState) => ({
  allCountries: state.countries,
}))(Education);
