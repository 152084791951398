import React, { useState } from 'react';
import { connect } from 'react-redux';

import { fetchConflictsCount } from '@/profile/store';
import { RootState } from '@/store';

import Picture from '../Picture';
import UserMenu from './UserMenu';

export default function PictureNav({ viewer, fetchConflictsCount, size, conflictsCount, id }: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(undefined);

  const handleMenuOpen = async (event: any) => {
    const target = event.currentTarget;
    if (viewer && viewer.admin) fetchConflictsCount();

    setMenuOpen(true);
    setMenuAnchorElement(target);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setMenuAnchorElement(null);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Picture size={size} user={viewer} onClick={handleMenuOpen} link={false} id={id} />

      <UserMenu
        // @ts-expect-error TS(2769): No overload matches this call.
        open={menuOpen}
        anchorEl={menuAnchorElement}
        onRequestClose={handleMenuClose}
        conflictsCount={conflictsCount}
      />
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'PictureNav' because it is a func... Remove this comment to see the full error message
PictureNav = connect(
  (state: RootState) => ({
    conflictsCount: state.profiles.counts.conflicts,
  }),
  {
    fetchConflictsCount,
  }
)(PictureNav);
