import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import theme from '@/theme';

const ns = 'date-picker';

export const datePickerClasses = {
  root: `${ns}__root`,
  error: `${ns}-error`,
  input: `${ns}-input`,
};

const { error, input } = datePickerClasses;

const styles = {
  datePicker: {},
  checkbox: {
    [`&.${formControlLabelClasses.root}`]: {
      margin: '5px 0 0 2px',
      color: theme.palette.grey[900],
    },
  },
  [`& .${error}`]: {
    color: theme.palette.error.main,
  },
  [`& .${input}`]: {
    maxWidth: '275px',
    [`& .${inputBaseClasses.input}`]: {
      color: theme.palette.grey[600],
    },
    [`&.${error}`]: {
      borderColor: theme.palette.danger.main,
      [`& .${inputBaseClasses.input}`]: {
        [`&::placeholder`]: {
          color: theme.palette.error.main,
          opacity: 1,
        },
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.danger.main,
      },
      [`& .Mui-focused`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.danger.main,
        },
      },
    },
  },
};

export default styles;
