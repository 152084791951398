/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Education } from '../models/Education';
import type { EducationCreate } from '../models/EducationCreate';
import type { EducationUpdate } from '../models/EducationUpdate';
import type { PaginatedEducationList } from '../models/PaginatedEducationList';
import type { PatchedEducationUpdate } from '../models/PatchedEducationUpdate';

export class EducationService {
  /**
   * List all educations
   * List all education
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId
   * @returns PaginatedEducationList
   * @throws ApiError
   */
  public static educationList(
    limit?: number,
    offset?: number,
    profileId?: string
  ): CancelablePromise<PaginatedEducationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/education/',
      query: {
        limit: limit,
        offset: offset,
        profile__id: profileId,
      },
    });
  }

  /**
   * Create a an education
   * Create a an education
   * @param requestBody
   * @returns EducationCreate
   * @throws ApiError
   */
  public static educationCreate(requestBody: EducationCreate): CancelablePromise<EducationCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/education/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve an education by UUID
   * Retrieve an education by UUID
   * @param id A UUID string identifying this education.
   * @returns Education
   * @throws ApiError
   */
  public static educationRetrieve(id: string): CancelablePromise<Education> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/education/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update an education by UUID
   * Completely update an education by UUID
   * @param id A UUID string identifying this education.
   * @param requestBody
   * @returns EducationUpdate
   * @throws ApiError
   */
  public static educationUpdate(
    id: string,
    requestBody: EducationUpdate
  ): CancelablePromise<EducationUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/education/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within an education by UUID
   * Update specific fields within an education by UUID
   * @param id A UUID string identifying this education.
   * @param requestBody
   * @returns EducationUpdate
   * @throws ApiError
   */
  public static educationPartialUpdate(
    id: string,
    requestBody?: PatchedEducationUpdate
  ): CancelablePromise<EducationUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/education/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete an education by UUID
   * Delete an education by UUID
   * @param id A UUID string identifying this education.
   * @returns void
   * @throws ApiError
   */
  public static educationDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/education/{id}/',
      path: {
        id: id,
      },
    });
  }
}
