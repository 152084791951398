import cx from 'classnames';
import { FC, Fragment, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button/Button';
import CircularProgress from '@/components/CircularProgress';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import LayoutPage from '@/components/Layout/LayoutPage';
import { csvImport, csvImportPreview } from '@/profile/store';
import { RootState } from '@/store';
import { orange500, red500, teal500, white } from '@/theme/colors';

import CsvContents from './CsvContents';
import ProfileFields from './ProfileFields';
import s from './ProfileUploader.module.scss';

interface ProfileUploaderProps {
  csvImport: any;
  csvPreviews: any;
}

const ProfileUploader: FC<ProfileUploaderProps> = ({ csvImport, csvPreviews }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [importing, setImporting] = useState(false);

  const handleConfirmUpload = useCallback(
    async (values: any) => {
      const {
        csv_filename: filename,
        csv_url: url,
        csv_mimetype: mimetype,
        csv_ignore_rows: ignoreRows,
        request_id: requestId,
        created_by: createdBy,
        network_id: networkId,
        not_available_marketplace: notAvailableMarketplace,
      } = values;

      setImporting(true);

      try {
        await csvImport({
          filename,
          url,
          mimetype,
          ignoreRows,
          requestId,
          createdBy,
          networkId,
          availableMarketplace: !notAvailableMarketplace,
        });
        setError(null);
        navigate('/profile-conflicts');
      } catch (e) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        setError(e);
      } finally {
        setImporting(false);
      }
    },
    [csvImport, navigate]
  );

  return (
    <LayoutPage showNav>
      <Form
        onSubmit={handleConfirmUpload}
        subscription={{
          submitting: true,
          values: true,
          initialValues: true,
        }}
        initialValues={{}}
      >
        {({ form, values, handleSubmit }) => {
          const {
            csv_filename: csvFilename,
            csv_url: csvUrl,
            csv_ignore_rows: csvIgnoreRows,
            request_id: requestId,
          } = values;

          const preview = csvUrl && csvPreviews[csvUrl];

          const profilesWithWarnings =
            preview && preview.profiles.filter((p: any) => p.warnings && p.warnings.length > 0);

          const warnings =
            profilesWithWarnings &&
            profilesWithWarnings.length > 0 &&
            profilesWithWarnings
              .map((p: any) => p.warnings.map((v: any) => `Row ${p.row}: ${v}`))
              .reduce((acc: any, v: any) => acc.concat(v));

          const profilesWithErrors =
            preview && preview.profiles.filter((p: any) => p.errors && p.errors.length > 0);

          const errors =
            profilesWithErrors &&
            profilesWithErrors.length > 0 &&
            profilesWithErrors
              .map((p: any) => p.errors.map((v: any) => `Row ${p.row}: ${v}`))
              .reduce((acc: any, v: any) => acc.concat(v));

          return (
            <Fragment>
              <div className={s.header}>
                <div className={s.headerText}>Profile Uploader</div>
                {!!preview && (
                  <div>
                    <Button
                      color="lightTan"
                      size="medium"
                      style={{ marginRight: 15 }}
                      onClick={() => form.reset()}
                    >
                      Go back
                    </Button>
                    <Button
                      backgroundColor={teal500}
                      fontColor={white}
                      onClick={handleSubmit}
                      disabled={!preview || !preview.valid || importing}
                      size="medium"
                    >
                      Confirm Upload
                    </Button>
                  </div>
                )}
              </div>
              <ProfileFields
                hasFile={!!csvUrl}
                setImporting={setImporting}
                disabled={!!preview || importing}
                form={form}
                values={values}
              />
              <div>
                {!!error && (
                  <div className={s.errorPanel}>
                    <div className={s.errorMessage}>
                      {error.toString ? error.toString() : JSON.stringify(error)}
                    </div>
                  </div>
                )}
                {importing && (
                  <div className={s.progress}>
                    <CircularProgress size={52} style={{ marginLeft: 10, marginBottom: 20 }} />
                    <div>Processing (this may take a few minutes)...</div>
                  </div>
                )}
                {!!csvFilename && !!preview && (
                  <Fragment>
                    <div className={s.csvFile}>
                      <div className={s.csvFileLabel}>
                        <MaterialIcon className={s.inlineIcon} icon="check" color={teal500} />
                        {csvFilename || csvUrl}
                        <div
                          className={cx(s.csvFileFormat, {
                            [s.csvFileFormatInvalid]: preview && !preview.valid,
                          })}
                        >
                          {preview && (preview.valid ? 'Formatting valid' : 'Formatting invalid')}
                        </div>
                      </div>
                    </div>
                    {preview.valid && (
                      <div>
                        <div className={s.profilesNew}>
                          <MaterialIcon
                            className={s.inlineIcon}
                            icon="person_outline"
                            color={teal500}
                          />
                          {preview.profiles.filter((p: any) => p.resolution === 'new').length} New
                          Profiles
                        </div>
                        <div className={s.profilesConflicting}>
                          <MaterialIcon className={s.inlineIcon} icon="clear" color={red500} />
                          {
                            preview.profiles.filter((p: any) => p.resolution.startsWith('conflict'))
                              .length
                          }{' '}
                          Conflicting Profiles
                        </div>
                        {warnings && warnings.length > 0 && (
                          <div className={s.warnings}>
                            <MaterialIcon
                              className={s.inlineIcon}
                              icon="warning"
                              color={orange500}
                            />
                            {warnings.length} Warning Profiles
                          </div>
                        )}
                        {errors && errors.length > 0 && (
                          <div className={s.errors}>
                            {errors.map((e: any) => (
                              <div key={e.row}>
                                <MaterialIcon
                                  className={s.inlineIcon}
                                  icon="error_outline"
                                  color={red500}
                                />{' '}
                                {e}
                              </div>
                            ))}
                          </div>
                        )}
                        <CsvContents
                          preview={preview}
                          ignoredRows={csvIgnoreRows}
                          globalRequestId={requestId}
                          form={form}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          );
        }}
      </Form>
    </LayoutPage>
  );
};

export default connect(
  (state: RootState) => ({
    csvPreviews: state.profiles.csvPreviews,
  }),
  {
    csvImport,
    csvImportPreview,
  }
)(ProfileUploader);
