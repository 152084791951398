import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import { fetchCountries } from '@/actions/country';
import { PhoneInput } from '@/components/FormAdapters';
import { isPhoneValid } from '@/utils';

function Phone(props: any) {
  const validate = (value: any) => {
    const { required } = props;
    if (!value) {
      return required ? 'Phone required' : undefined;
    }
    if (!isPhoneValid(value)) {
      return 'Phone must be valid';
    }
  };

  return (
    <Field
      id="communicationPhone"
      component={PhoneInput}
      validate={validate}
      type="tel"
      name="phone"
      showExampleOnError
      parse={(v) => v}
      {...props}
    />
  );
}

// @ts-expect-error TS(2631): Cannot assign to 'Phone' because it is a namespace... Remove this comment to see the full error message
Phone = connect((state) => ({
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  allCountries: state.countries,
}))(Phone);

Phone.fetch = fetchCountries;

export default Phone;
