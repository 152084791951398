import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';
import { DocumentNodeResult } from '@/utils/gql';

import ActionTypes from './ActionTypes';

const { DOMAIN__UPDATE, GROUP__UPDATE } = ActionTypes;

export const GET_DOMAIN = gengql(/* GraphQL */ `
  query actionFetchDomain($subdomain: String!) {
    domain(subdomain: $subdomain) {
      id
      subdomain
      root_subdomain_redirect
      logo_url
      signup_enabled
      email_domain
      expert_signup_about
      expert_signup_about_mobile
      network_join_title
      member_signup_about
      member_signup_about_mobile
      group {
        id
        name
        about
        enforce_2fa
        about_page {
          html_url
        }
      }
      agreements(active_only: true) {
        id
        policy_code
        policy_label
        policy_url
      }
      signup_prompt_hourly_rate
      signup_prompt_marketplace
      signup_prompt_profile_publicity
    }
  }
`);

export type GroupDomain = NonNullable<DocumentNodeResult<typeof GET_DOMAIN>['domain']>;

export function fetchDomain(subdomain: string): AppThunk<Promise<GroupDomain>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(GET_DOMAIN, { subdomain });
    const domain = result.domain!;
    dispatch({ type: DOMAIN__UPDATE, subdomain, domain });
    return domain;
  };
}

export function fetchSignupDomain(subdomain: string): AppThunk<Promise<GroupDomain | null>> {
  return async (dispatch) => {
    const domain = await dispatch(fetchDomain(subdomain));
    return domain?.signup_enabled ? domain : null;
  };
}

const UPDATE_GROUP_DOMAIN = gengql(/* GraphQL */ `
  mutation actionUpdateGroupDomain(
    $group_id: String!
    $logo_url: String
    $signup_enabled: Boolean
    $subdomain: String
    $root_subdomain_redirect: String
    $member_signup_about: String
    $member_signup_about_mobile: String
    $expert_signup_about: String
    $expert_signup_about_mobile: String
    $network_join_title: String
    $signup_prompt_hourly_rate: Boolean
    $signup_prompt_marketplace: Boolean
    $signup_prompt_profile_publicity: Boolean
    $agreements: [GroupDomainSignupAgreementInput]
  ) {
    updateGroupDomain(
      group_id: $group_id
      logo_url: $logo_url
      signup_enabled: $signup_enabled
      subdomain: $subdomain
      root_subdomain_redirect: $root_subdomain_redirect
      member_signup_about: $member_signup_about
      member_signup_about_mobile: $member_signup_about_mobile
      expert_signup_about: $expert_signup_about
      expert_signup_about_mobile: $expert_signup_about_mobile
      network_join_title: $network_join_title
      signup_prompt_hourly_rate: $signup_prompt_hourly_rate
      signup_prompt_marketplace: $signup_prompt_marketplace
      signup_prompt_profile_publicity: $signup_prompt_profile_publicity
      agreements: $agreements
    ) {
      id
      subdomain
      root_subdomain_redirect
      logo_url
      signup_enabled
      expert_signup_about
      expert_signup_about_mobile
      network_join_title
      member_signup_about
      member_signup_about_mobile
      signup_prompt_hourly_rate
      signup_prompt_marketplace
      signup_prompt_profile_publicity
      agreements(active_only: false) {
        id
        active
        policy_code
        policy_label
        policy_url
      }
    }
  }
`);

export function updateGroupDomain(
  domain: any,
  groupId: string
): AppThunk<
  Promise<NonNullable<DocumentNodeResult<typeof UPDATE_GROUP_DOMAIN>['updateGroupDomain']>>
> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(UPDATE_GROUP_DOMAIN, domain);

    const updateGroupDomain = data!.updateGroupDomain!;

    dispatch({
      type: GROUP__UPDATE,
      group: { id: groupId, domain: updateGroupDomain },
    });
    return updateGroupDomain;
  };
}
