import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { Checkbox } from '@/components/FormAdapters';
import { updateProfile } from '@/profile/store';

class PrivacyForm extends PureComponent {
  static propTypes = {
    profileId: PropTypes.string,
    component: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    updateProfile: PropTypes.func,
    reset: PropTypes.func,
    onReset: PropTypes.func,
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });
    if (onSubmit) onSubmit(values);
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();
    if (onReset) onReset();
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container = Form,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      ...other
    } = this.props;

    return (
      <Container onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset} {...other}>
        <Field
          type="checkbox"
          component={Checkbox}
          name="hide_profile"
          label="Keep my profile hidden"
        />
      </Container>
    );
  }
}

// @ts-expect-error TS(2629): Cannot assign to 'PrivacyForm' because it is a cla... Remove this comment to see the full error message
PrivacyForm = reduxForm({
  form: 'expertPrivacy',
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'typeof PrivacyForm' is not assig... Remove this comment to see the full error message
})(PrivacyForm);

// @ts-expect-error TS(2629): Cannot assign to 'PrivacyForm' because it is a cla... Remove this comment to see the full error message
PrivacyForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        hide_profile: profile.hide_profile,
      },
    };
  },
  {
    updateProfile,
  }
  // @ts-expect-error TS(2345): Argument of type 'typeof PrivacyForm' is not assig... Remove this comment to see the full error message
)(PrivacyForm);

export default PrivacyForm;
