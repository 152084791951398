import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form/Form';
import { Checkbox } from '@/components/FormAdapters';
import { updateUser } from '@/store/user';

class BackgroundCheckForm extends PureComponent {
  handleSubmit = async (values: any) => {
    // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { userId, updateUser, onSubmit } = this.props;
    await updateUser({ id: userId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
    const { component: Container = Form, handleSubmit, ...other } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          type="checkbox"
          component={Checkbox}
          name="background_check"
          label="Background check has been completed"
        />
      </Container>
    );
  }
}
export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const user = state.users[ownProps.userId] || {};
    return {
      initialValues: {
        background_check: user.background_check,
      },
    };
  },
  {
    updateUser,
  }
)(
  reduxForm({
    form: 'expertBackgroundCheck',
    // @ts-expect-error TS(2345): Argument of type 'typeof BackgroundCheckForm' is n... Remove this comment to see the full error message
  })(BackgroundCheckForm)
);
