const ns = 'avatar';

export const avatarClasses = {
  clickable: `${ns}__clickable`,
  root: `${ns}__root`,
};

const { clickable } = avatarClasses;

const styles = {
  avatar: {
    [`&.${clickable}`]: {
      cursor: 'pointer',
      fontSize: '25px',
    },
  },
};

export default styles;
