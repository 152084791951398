import Skeleton from '@mui/material/Skeleton';

import { Drawer } from '@/componentsv2/Drawer';
import Tag from '@/componentsv2/Tag';
import { displaySegment } from '@/dashboardv2/components/KnowledgeTable';
import { KnowledgeEnumType } from '@/knowledge/queries/knowledge';
import { useSearchKnowledgeSimilarQuery } from '@/searchv2/queries/search';
import { knowledgeCapitalized } from '@/utils/capitalization';

import { IKnowledgeInfoProps } from './types';

const testId = 'of-knowledge-info';

const KnowledgeInfo = ({
  className,
  title,
  knowledgeType,
  knowledgeId,
  description,
  isLocation,
}: IKnowledgeInfoProps) => {
  const { data, isLoading } = useSearchKnowledgeSimilarQuery({
    knowledgeId,
    knowledgeType,
    limit: 8,
  });

  const relatedKnowledge = data?.filter((knowledge) => knowledge.id !== knowledgeId);

  return (
    <Drawer.Section
      className={className}
      data-testid={testId}
      title="About"
      childrenContainerClassName="p-16"
    >
      <p className="subtitle-2">{displaySegment(knowledgeType as KnowledgeEnumType)}</p>
      <p className="mt-5 hd-5">{knowledgeCapitalized(title)}</p>
      <p className="mt-10 body-2">{description}</p>
      {relatedKnowledge?.length && !isLocation ? (
        <>
          <p className="my-10 subtitle-2">Related Concept:</p>
          <div className="flex flex-wrap gap-5">
            {relatedKnowledge.map((knowledge) => (
              <Tag key={knowledge.id} size="sm">
                {knowledgeCapitalized(knowledge.name)}
              </Tag>
            ))}
          </div>
        </>
      ) : isLoading ? (
        <div>
          <Skeleton variant="text" width="100px" height={24} />
          <Skeleton variant="text" width="100%" height={24} />
          <Skeleton variant="text" width="100%" height={24} />
        </div>
      ) : null}
    </Drawer.Section>
  );
};

export { testId as KnowledgeInfoTestId };
export type { IKnowledgeInfoProps };
export default KnowledgeInfo;
