// @ts-nocheck
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

import { IDataGridTableProps } from './types';

const testId = 'of-data-grid-table';

const DataGridTable = ({ className, ...rest }: DataGridProps & IDataGridTableProps) => {
  return (
    <DataGrid
      className={className}
      autoHeight
      disableSelectionOnClick
      sx={{
        border: 'none',
        '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      }}
      {...rest}
    />
  );
};

export type { IDataGridTableProps };
export { testId as DataGridTableTestId };
export default DataGridTable;
