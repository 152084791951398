import makeStyles from '@mui/styles/makeStyles';

import ColumnSection from '@/components/ColumnSection/ColumnSection';
import EditIcon from '@/components/EditIcon/EditIcon';
import { formatExperiencePeriod } from '@/core/profile';
import { primary } from '@/theme/colors';

import SectionItem from './SectionItem';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  emptyText: {
    fontSize: 16,
    lineHeight: '24px',
    color: primary,
  },
}));

export default ({ experiences, editable, onEdit }: any) => {
  const s = getStyles();

  return (
    <div className={s.row}>
      <ColumnSection title="Experience">
        {experiences.length
          ? experiences.map((e: any) => (
              <SectionItem
                key={e.id}
                subTitle={`${e.organization}${
                  e.organization && e.location ? ' • ' : ''
                }${e.location}`}
                title={e.title}
                description={e.description}
                period={formatExperiencePeriod(e.start_date, e.end_date, e.current)}
              />
            ))
          : editable && (
              /* eslint-disable-next-line */
              <a onClick={onEdit}>
                <span className={s.emptyText}>Add experience and projects</span>
              </a>
            )}
      </ColumnSection>

      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};
