import { Breadcrumbs } from '@/componentsv2/Drawer/types';
import { profileSelector } from '@/profilev2/store/profileSlice';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';

const useProfileBreadcrumbs = (breadcrumbs: Breadcrumbs) => {
  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);

  if (isProfileLoading) {
    return [];
  }

  return [
    {
      title: profileData?.full_name ?? '',
      to: '../' + APP_ROUTES.profile(profileData?.id ?? ''),
    },
    ...breadcrumbs,
  ];
};

export default useProfileBreadcrumbs;
