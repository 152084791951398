import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    marginTop: 5,
    marginRight: 5,
  },
  label: {
    overflow: 'hidden',
    maxWidth: 200,
    textOverflow: 'ellipsis',
  },
});

export default function FilterChip(props: any) {
  const classes = useStyles();

  return <Chip size="small" classes={classes} {...props} />;
}
