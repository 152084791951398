import React, { useMemo } from 'react';

import Link from '../Link/Link';
import Picture from '../Picture/Picture';
import PictureList from '../PictureList/PictureList';

interface Member {
  id: string;
  user: string;
}

interface MemberListProps {
  members: Member[];
  membersDisplayLimit?: number;
  total?: number;
  htmlUrl?: string;
}

const More = ({ more, htmlUrl }: { more: number; htmlUrl?: string }) => {
  if (more <= 0) {
    return null;
  }

  return (
    <Link key="more" id="more" to={htmlUrl}>
      <Picture style={{ fontSize: 16 }} size={40}>
        +{more}
      </Picture>
    </Link>
  );
};

const MemberList: React.FC<MemberListProps> = (props) => {
  const { members, membersDisplayLimit = 0, total = 0, htmlUrl, ...other } = props;

  const more = useMemo(() => total - membersDisplayLimit, [total, membersDisplayLimit]);
  const membersLimit = useMemo(
    () => (more > 0 ? members.slice(0, membersDisplayLimit) : members),
    [members, membersDisplayLimit, more]
  );
  const moreComponent = useMemo(
    () => <More key="more" more={more} htmlUrl={htmlUrl} />,
    [more, htmlUrl]
  );

  return (
    <PictureList {...other}>
      {membersLimit
        .map((m: any) => (
          <Picture
            user={m.user}
            key={m.id}
            id={m.id}
            size={40}
            ring={m.role === 'manager' || m.role === 'associate'}
          />
        ))
        .concat(moreComponent ? [moreComponent] : [])}
    </PictureList>
  );
};

export default MemberList;
