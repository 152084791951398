import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { Box } from '@/componentsv2';
import TagGroup from '@/componentsv2/TagGroup';
import { useProfileId } from '@/hooks/store';
import { useKnowledgesByTypeQuery } from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { mq } from '@/theme/screens';
import { knowledgeCapitalized } from '@/utils/capitalization';

import ExpertTag from '../ExpertTag';
import { ProfileContainer } from '../ProfileContainer';

export const TITLE = 'Topics';
const PLACEHOLDER = `More experiences required to display ${TITLE}`;

const Expertise = () => {
  const profileId = useProfileId();
  const { data, isLoading, isFetched, isRefetching } = useKnowledgesByTypeQuery({
    profileId,
    knowledgeTypes: ['Area of Expertise'],
    page: 1,
    ordering: '-value',
  });
  const hasData = data?.results?.length;
  const { isLoading: accrualIsLoading, accrualIsStale } = useAppSelector(accrualSummarySelector);
  const isDesktop = useMediaQuery(mq.md);

  return (
    <Box>
      <ProfileContainer>
        <ProfileContainer.Header
          showUpdatingIndicator={isRefetching || (accrualIsLoading && accrualIsStale)}
          to={PROFILE_ROUTES().knowledgeType('expertise')}
        >
          {TITLE}
        </ProfileContainer.Header>
        <ProfileContainer.Body>
          <TagGroup
            isLoading={isLoading}
            showPlaceholder={!hasData && isFetched}
            placeholder={
              <Typography variant="body2" color="grey.500">
                {PLACEHOLDER}
              </Typography>
            }
            collapsible={!isDesktop}
          >
            {data?.results?.map((knowledge) => {
              return (
                <Link to={PROFILE_ROUTES().knowledge(knowledge.name ?? '')} key={knowledge.name}>
                  <ExpertTag value={knowledge.value} isClickable>
                    {knowledgeCapitalized(knowledge.name)}
                  </ExpertTag>
                </Link>
              );
            })}
          </TagGroup>
        </ProfileContainer.Body>
      </ProfileContainer>
    </Box>
  );
};

export default Expertise;
