/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedAvailableKnowledgeList } from '../models/PaginatedAvailableKnowledgeList';

export class KnowledgeRawTermService {
  /**
   * Get knowledge raw terms
   * List the knowledge raw terms
   * @param jobId A UUID string identifying the job whose knowledges you want to list
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId A UUID string identifying the profile whose knowledges you want to list
   * @param projectId A UUID string identifying the project whose knowledges you want to list
   * @returns PaginatedAvailableKnowledgeList
   * @throws ApiError
   */
  public static knowledgeRawTermList(
    jobId?: string,
    limit?: number,
    offset?: number,
    profileId?: string,
    projectId?: string
  ): CancelablePromise<PaginatedAvailableKnowledgeList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/knowledge_raw_term/',
      query: {
        job_id: jobId,
        limit: limit,
        offset: offset,
        profile_id: profileId,
        project_id: projectId,
      },
    });
  }
}
