import cx from 'classnames';
import React, { FC, memo } from 'react';

import Picture from '@/components/Picture';
import { borderColorHex, white } from '@/theme/colors';

import s from './Message.module.scss';

function getBorderStyle(color: any, position: any, align: any) {
  const borderStyle: React.CSSProperties = {};

  if (align === 'top') {
    borderStyle.borderTopColor = color;
  } else if (align === 'bottom') {
    borderStyle.borderBottomColor = color;
  }

  if (position === 'left') {
    borderStyle.borderRightColor = color;
  } else if (position === 'right') {
    borderStyle.borderLeftColor = color;
  } else if (position === 'top') {
    borderStyle.borderBottomColor = color;
  } else if (position === 'bottom') {
    borderStyle.borderTopColor = color;
  }

  return borderStyle;
}

interface MessageProps {
  children: React.ReactNode;
  className?: string;
  bubbleClassName?: string;
  /** The user object. If empty the user will be rendered as anonymous. */
  user?: any;
  pictureSize?: number;
  hideArrow?: boolean;
  icon?: React.ReactNode;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  position?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'center' | 'top' | 'bottom';
  [key: string]: any;
}

const Message: FC<MessageProps> = memo(
  ({
    className,
    style,
    position = 'left',
    align = 'top',
    children,
    borderColor = borderColorHex,
    backgroundColor = white,
    color,
    user,
    pictureSize,
    bubbleClassName,
    hideArrow,
    icon,
    ...other
  }) => {
    const bubbleStyle = { borderColor, backgroundColor, color };
    const rootClassNames = cx(s.root, className, s[`${align}Align`], s[`${position}Pos`]);
    const bubbleClassNames = cx(
      s.bubble,
      s[`${align}Align`],
      s[`${position}Pos`],
      bubbleClassName && { [bubbleClassName]: true }
    );
    const userClassNames = cx(s.userPicture, s[`${align}Align`], s[`${position}Pos`]);

    return (
      <div className={rootClassNames} style={style} {...other}>
        <Picture size={pictureSize} className={userClassNames} user={user}>
          {icon}
        </Picture>
        <div style={bubbleStyle} className={bubbleClassNames}>
          {!hideArrow && (
            <span style={getBorderStyle(borderColor, position, align)} className={s.before} />
          )}
          {children}
          {!hideArrow && (
            <span style={getBorderStyle(backgroundColor, position, align)} className={s.after} />
          )}
        </div>
      </div>
    );
  }
);
Message.displayName = 'Message';

export default Message;
