import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import AssignmentsManager, {
  AssignmentsFilter,
  defaultProps as defaultFilterProps,
} from '@/assignment/AssignmentsManager';
import { CancelablePromise, PaginatedAssignmentsList, Project } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

const history = createBrowserHistory();

let request: CancelablePromise<PaginatedAssignmentsList>;

export const fetchProjects = createAsyncThunk(
  'manageProject/fetchProjects',
  async (filters: AssignmentsFilter) => {
    request?.cancel();

    request = AssignmentsManager.fetchAssignmentsList({
      ...defaultFilterProps,
      limit: 100,
      ...filters,
      assignmentTypes: 'Project|Proposal',
    });
    return request;
  }
);

const manageProjectSlice = createSlice({
  name: 'manageProject',
  initialState: initialState.manageProject,
  reducers: {
    resetData: () => initialState.manageProject,
    showDrawer: (state) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
      };
    },
    closeDrawer: (state) => ({
      ...state,
      opened: false,
      closeAfterEditRole: false,
      selectedJobId: null,
    }),
    editProject: (state, action: PayloadAction<Project>) => {
      if (!action.payload?.can_edit) {
        throw Error('Project is not editable by user');
      }
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        selectedProject: action.payload,
        initialState: 'editProject',
      };
    },
    editRoles: (state, action: PayloadAction<Project>) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        selectedProject: action.payload,
        initialState: 'editRoles',
      };
    },
    editRolesByProjectId: (
      state,
      action: PayloadAction<{
        projectId: string;
        closeAfterEditRole?: boolean;
        skipAccrualUpdate?: boolean;
      }>
    ) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        selectedProjectId: action.payload.projectId,
        closeAfterEditRole: action.payload.closeAfterEditRole ?? false,
        skipAccrualUpdate: action.payload.skipAccrualUpdate ?? false,
        initialState: 'editRoles.fetchingProject',
      };
    },
    reviewProjects: (state) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        initialState: 'bulkView',
      };
    },
    selectNewProjectSource: (
      state,
      action: PayloadAction<
        | {
            closeAfterEditRole: boolean;
            selectedJobId: string | null;
          }
        | undefined
      >
    ) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
        closeAfterEditRole: action?.payload?.closeAfterEditRole,
        selectedJobId: action?.payload?.selectedJobId ?? null,
        initialState: 'selectNewProjectSource',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchProjects.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchProjects), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const manageProjectSelector = (state: IState) => state.manageProject;
export const {
  showDrawer,
  closeDrawer,
  editProject,
  editRoles,
  editRolesByProjectId,
  reviewProjects,
  resetData,
  selectNewProjectSource,
} = manageProjectSlice.actions;
export default manageProjectSlice.reducer;
