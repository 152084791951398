import Button from '@mui/material/Button';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { fetchAllGroups } from '@/actions/group';
import { fetchProjectNames } from '@/actions/project';
import { Select, TextField } from '@/components/FormAdapters/FormAdapters';
import MediaQuery from '@/components/MediaQuery';
import { SCREEN_XS } from '@/theme/screens';
import { normalizeSpace } from '@/utils';

class Project extends React.Component {
  componentDidUpdate(prevProps: any) {
    // @ts-expect-error TS(2339): Property 'formValues' does not exist on type 'Read... Remove this comment to see the full error message
    const { formValues, projects } = this.props;

    const projectId = formValues?.project_id;
    const previousProjectId = prevProps.formValues?.project_id;

    const projectName = formValues?.project_name;
    const previousProjectName = prevProps.formValues?.project_name;

    if (projectId && projectId !== previousProjectId) {
      const project = projects?.edges?.find((p: any) => p.node.id === projectId)?.node;

      // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.change('project_add_new', false);
      if (project && project.group) {
        // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.props.change('group_id', project.group.id);
      }
    } else if (!previousProjectName && projectName) {
      // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.change('project_add_new', true);
    }
  }

  handleAddNew = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer } = this.props;
    // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.change('project_add_new', true);
    // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.change('project_id', null);
    // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.change('group_id', null);
    if (!viewer.admin && viewer.groups && viewer.groups.length === 1) {
      // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.change('group_id', viewer.groups[0].id);
    }
  };

  requiredIfNew = (value: any, values: any) => {
    // @ts-expect-error TS(2339): Property 'projects' does not exist on type 'Readon... Remove this comment to see the full error message
    const { projects } = this.props;
    const projectEdges = (projects && projects.edges) || [];

    if (projectEdges.length === 0 || values.project_add_new) {
      if (!value) {
        return 'Required';
      }
    }
  };

  requiredIfNotNew = (value: any, values: any) => {
    // @ts-expect-error TS(2339): Property 'projects' does not exist on type 'Readon... Remove this comment to see the full error message
    const { projects } = this.props;
    const projectEdges = (projects && projects.edges) || [];

    if (!value && projectEdges.length > 0 && !values.project_add_new) {
      return 'Required';
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'projects' does not exist on type 'Readon... Remove this comment to see the full error message
    const { projects, groups, formValues, disabled, TextFieldProps } = this.props;
    // @ts-expect-error TS(2339): Property 'field' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const FieldComponent = this.props.field || Field;
    const projectEdges = (projects && projects.edges) || [];
    const groupEdges = (groups && groups.edges) || [];

    return (
      <div>
        {projectEdges.length > 0 && (
          <div>
            <FieldComponent
              fullWidth
              autocomplete={projectEdges.length > 10}
              rawValueOnChange
              style={{ maxWidth: 350 }}
              component={Select}
              validate={this.requiredIfNotNew}
              disabled={disabled}
              label="Select Project"
              placeholder="Select Project"
              TextFieldProps={TextFieldProps}
              name="project_id"
              options={[
                ...projectEdges.map((e: any) => {
                  const trackingCode = e.node.tracking_code ? ` (${e.node.tracking_code})` : '';
                  return {
                    label: `${e.node.name}${trackingCode}`,
                    value: e.node.id,
                  };
                }),
              ]}
            />
            <Button
              color="secondary"
              disabled={disabled}
              onClick={this.handleAddNew}
              style={{
                marginLeft: 3,
                marginRight: 3,
              }}
            >
              Add New Project
            </Button>
          </div>
        )}

        {(projectEdges.length === 0 || formValues.project_add_new) && (
          <div>
            <MediaQuery maxWidth={SCREEN_XS}>
              {(isMobileVersion: any) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobileVersion ? 'column' : 'row',
                    }}
                  >
                    <FieldComponent
                      autoFocus
                      fullWidth
                      component={TextField}
                      validate={this.requiredIfNew}
                      disabled={disabled}
                      name="project_name"
                      label="Type a new project name"
                      style={{ marginRight: 10, maxWidth: 360 }}
                      {...TextFieldProps}
                    />
                    <FieldComponent
                      fullWidth
                      component={TextField}
                      disabled={disabled}
                      inputProps={{ maxLength: 60 }}
                      name="tracking_code"
                      label="Tracking code"
                      style={{
                        marginRight: 10,
                        maxWidth: isMobileVersion ? 360 : 130,
                      }}
                      multiline={isMobileVersion}
                      onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
                      parse={(v: any) => normalizeSpace(v)}
                      {...TextFieldProps}
                    />
                  </div>
                );
              }}
            </MediaQuery>
            <div>
              {groupEdges.length > 1 && (
                <FieldComponent
                  component={Select}
                  validate={this.requiredIfNew}
                  disabled={disabled}
                  autocomplete={groupEdges.length > 10}
                  rawValueOnChange
                  name="group_id"
                  label="Team"
                  style={{ maxWidth: 360 }}
                  options={groupEdges.map((g: any) => ({
                    label: g.node.name,
                    value: g.node.id,
                  }))}
                  TextFieldProps={TextFieldProps}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error TS(2629): Cannot assign to 'Project' because it is a class.
Project = connect((state) => ({
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  viewer: state.viewer,
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  projects: state.projects.names,
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  groups: state.groups.all,
}))(Project);

// @ts-expect-error TS(2339): Property 'fetch' does not exist on type 'typeof Pr... Remove this comment to see the full error message
Project.fetch = [fetchProjectNames, fetchAllGroups];

export default Project;
