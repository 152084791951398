import { Box, Popover, PopoverProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import Button from '@/components/Button';
import { TimeRange } from '@/core/time';
import { teal500 } from '@/theme/colors';

import PlivoPopoverContent from './PlivoPopoverContent';
import TwilioPopoverContent from './TwilioPopoverContent';
import Participant from './participant';

const CarriersPopoverContent: Record<string, ReactNode> = {
  // @ts-ignore
  twilio: TwilioPopoverContent,
  // @ts-ignore
  plivo: PlivoPopoverContent,
  zoom: null,
};

interface TimelinePopoverProps extends PopoverProps {
  carrier: string;
  participant: Participant;
  range: TimeRange;
}

const TimelinePopover: FC<TimelinePopoverProps> = ({ carrier, participant, range, ...other }) => {
  const Content = CarriersPopoverContent[carrier];

  return (
    <Popover
      {...other}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        {/* @ts-ignore */}
        {Content && <Content participant={participant} range={range} />}
        <div style={{ textAlign: 'center' }}>
          {!participant.hasMetrics() && <p>Metrics are pending</p>}

          <Button href={participant.url()} style={{ width: 120, color: teal500 }}>
            View Details
          </Button>
        </div>
      </Box>
    </Popover>
  );
};

export default TimelinePopover;
