import { fetchGroups } from '@/actions/group';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';

import { LegacyRoute } from '../routesMiddleware';
import TeamList from './TeamList';

const route: LegacyRoute = {
  path: '/teams',

  async action({ store }) {
    const { viewer } = store.getState();
    await Promise.all([
      store.dispatch(
        fetchGroups({
          force: true,
          collection: 'default',
          memberOnly: !viewer.admin,
          memberLimit: 5,
          billingAccount: true,
          stats: true,
        })
      ),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    document.title = 'Teams';
    return (
      <LayoutPage showNav selected="teams">
        <ConsultationsStarting />
        <TeamList />
      </LayoutPage>
    );
  },
};

export default route;
