import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { X } from 'react-feather';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import { Input } from '@/componentsv2';
import Button from '@/componentsv2/Button';
import { ProfileEmail } from '@/openapi';
import { useProfilesByEmailQuery } from '@/profilev2/queries/profilesByEmail';
import { useAddSearchCollaborator } from '@/searchv2/mutations/searchCollaborators';
import { closeCollaboratorsDialog, searchSelector } from '@/searchv2/store/searchSlice';
import { useAppDispatch, useAppSelector } from '@/store';

import CollaboratorsList, { CollaboratorItem } from './CollaboratorsList';

const AddCollaborators = () => {
  const { savedSearchId } = useParams();
  const [inputValue, setInputValue] = useState('');
  const debouncedQuery = useDebounce(inputValue, 100);
  const [value, setValue] = useState<ProfileEmail | null>(null);
  const { mutate: addSearchCollaborator } = useAddSearchCollaborator();
  const { data: autocompleteData, isFetching: autocompleteIsFetching } = useProfilesByEmailQuery({
    query: debouncedQuery,
  });

  const debouncedIsFetching = useDebounce(autocompleteIsFetching, 2000);

  return (
    <>
      <h3 className="body-1">Add collaborators:</h3>
      <Autocomplete
        className="mt-8"
        options={autocompleteData?.results ?? []}
        getOptionLabel={(option: ProfileEmail) => option.email ?? ''}
        isOptionEqualToValue={(option: ProfileEmail, value: ProfileEmail) => option.id === value.id}
        size="small"
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        value={value}
        clearOnBlur
        onChange={(_, newValue) => {
          setValue(null);
          setInputValue('');
          if (newValue) {
            addSearchCollaborator({
              id: savedSearchId ?? '',
              profiles: [
                {
                  full_name: newValue.profile,
                  picture_url: newValue.picture_url,
                  profile_id: newValue.profile_id,
                },
              ],
            });
          }
        }}
        renderInput={(params) => (
          <Input
            name="todo"
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              placeholder: 'Search by name or email',
              className: 'bg-white',
              endAdornment:
                inputValue && debouncedIsFetching ? (
                  <CircularProgress size={20} />
                ) : (
                  params.InputProps?.endAdornment
                ),
            }}
          />
        )}
        filterOptions={(x) => x}
        renderOption={(props, option: ProfileEmail) => (
          <CollaboratorItem
            key={option.id}
            className="px-16"
            fullName={option.profile}
            pictureUrl={option.picture_url}
            email={option.email}
            muiProps={props}
          />
        )}
      />
      <p className="mt-8 body-2">
        Invite collaborators to this search and see any candidates added to your shortlist.{' '}
      </p>
    </>
  );
};

const CollaboratorsDialog = () => {
  const dispatch = useAppDispatch();
  const { collaboratorsDialogIsOpen } = useAppSelector(searchSelector);
  const handleClose = () => dispatch(closeCollaboratorsDialog());
  const { savedSearchId } = useParams();

  return (
    <Dialog
      open={collaboratorsDialogIsOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { maxHeight: 'min(95%, 500px)' },
      }}
    >
      {collaboratorsDialogIsOpen ? (
        <>
          <DialogTitle>
            <div className="flex items-center justify-between">
              <h2 className="hd-5">Share</h2>
              <Button
                variant="tertiary"
                size="large"
                startIcon={X}
                onClick={handleClose}
                srText="Close"
              />
            </div>
          </DialogTitle>
          <DialogContent className="max-w-[450px]">
            <AddCollaborators />
            <CollaboratorsList className="mt-24" savedSearchId={savedSearchId ?? ''} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default CollaboratorsDialog;
