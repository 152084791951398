import ActionTypes from '@/actions/ActionTypes';

const { ACTIVITY__MERGE } = ActionTypes;

export default function activitiesReducer(state = {}, action: any) {
  switch (action.type) {
    case ACTIVITY__MERGE:
      const {
        page: {
          objectType,
          objectId,
          pageInfo: { hasNextPage },
          edges,
        },
      } = action;
      const lastCursor = edges && (edges.length > 0 ? edges.slice(-1)[0].cursor : null);
      const key = activityKey(objectType, objectId);
      return {
        ...state,
        [key]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[key],
          data: mergeActivity(
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state[key] && state[key].data,
            (edges || []).map((e: any) => e.node)
          ),
          nextCursor: hasNextPage ? lastCursor : null,
        },
      };
    default:
      return state;
  }
}

function mergeActivity(state = [], actvts: any) {
  const newState = [];
  for (const existing of state) {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (actvts.some((a: any) => a.id === existing.id)) continue; // don't push updated
    newState.push(existing);
  }
  newState.push(...actvts);
  return newState;
}

export function activitiesFor(state: any, objectType: any, objectId: any) {
  const objectStateBranch = state[activityKey(objectType, objectId)];
  if (!objectStateBranch) return null;
  const data = objectStateBranch.data || [];
  return {
    data,
    nextCursor: objectStateBranch.nextCursor,
    loading: objectStateBranch.loading,
  };
}

function activityKey(oType: any, oId: any) {
  return `${oType}#${oId}`;
}
