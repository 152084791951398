import { Divider as DividerMaterial } from '@mui/material';
import React from 'react';

function Divider(props: any) {
  const { spacing = 20, margin, color, style, height = 1, ...other } = props;

  const defaultStyle: React.CSSProperties = {
    marginTop: spacing,
    marginBottom: spacing,
    marginLeft: margin,
    marginRight: margin,
    height,
    width: '100%',
  };

  if (color) {
    defaultStyle.borderColor = color;
    defaultStyle.backgroundColor = color;
  }

  return <DividerMaterial style={{ ...defaultStyle, ...style }} {...other} />;
}

export default Divider;
