import React from 'react';

import ConsultationRef from './ConsultationRef';
import RequestRef from './RequestRef';
import UserRef from './UserRef';

function ConsultationRequestedActivity({
  activity: {
    context: { expert, requester, consultation, group, expert_request: expertRequest },
  },
}: any) {
  return (
    <div>
      <UserRef user={requester} link /> requested a <ConsultationRef consultation={consultation} />{' '}
      with <UserRef user={expert} />{' '}
      <RequestRef expertRequest={expertRequest} group={group} optional />
    </div>
  );
}

export default ConsultationRequestedActivity;
