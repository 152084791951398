import { IProjectStore } from '@/assignment/project';
import { IUserStore } from '@/auth/user';
import { ICollection, ICollectionProfileList } from '@/collection/collection';
import { ICollectionInfoStore } from '@/collection/collectionInfoData';
import IMyCollectionStore from '@/collection/myCollection';
import { SelectOption } from '@/componentsv2/Select/types';
import { GooglePlaceResponse } from '@/core/cities';
import ICountries from '@/core/countries';
import { IAccrualSummary } from '@/knowledge/accrualSummary';
import IAddKnowledge from '@/knowledge/addKnowledge';
import { IOnboarding } from '@/onboardingv2/onboarding';
import {
  PaginatedLanguageList,
  PaginatedSavedCollectionCollaboratorList,
  ProfileOnboardingTask,
} from '@/openapi';
import { IDegree } from '@/profilev2/degree';
import IManageExperience from '@/profilev2/manageExperience';
import IManageExperiences from '@/profilev2/manageExperiences';
import IManageProject from '@/profilev2/manageProject';
import { IPaginatedJobList } from '@/profilev2/paginatedJobList';
import { IPaginatedLookUpList } from '@/profilev2/paginatedLookupList';
import { IPaginatedMajorList } from '@/profilev2/paginatedMajorList';
import { IProjectList } from '@/profilev2/paginatedProjectList';
import { IPaginatedProjectMemberList } from '@/profilev2/paginatedProjectMemberList';
import { IProfileStore } from '@/profilev2/profile';
import IProfileAwardsStore from '@/profilev2/profileAwards';
import IProfileCertificationsStore from '@/profilev2/profileCertifications';
import IProfilePublicationsStore from '@/profilev2/profilePublications';
import { IProfilesByEmailStore } from '@/profilev2/profilesByEmail';
import IRawTitle from '@/profilev2/titleRawTerm';
import { ISearch } from '@/searchv2/search';
import UIStore from '@/ui/ui';

import IState from './state';

const genericBaseEmptyState = {
  data: { results: undefined },
  isLoading: false,
  error: null,
  hasLoaded: false,
};

// TODO: get rid of this as each slice should declare its initial state
export default {
  profile: {
    data: null,
    isLoading: true,
    error: null,
    counts: {
      proposals: 0,
      projects: 0,
    },
  } as IProfileStore,
  profilesByEmail: {
    data: {
      count: 0,
      next: '',
      previous: '',
      results: [],
    },
    isLoading: false,
    error: null,
  } as IProfilesByEmailStore,
  assignmentToDelete: null,
  project: {
    data: null,
  } as IProjectStore,
  projectMembers: { data: null } as IPaginatedProjectMemberList,
  jobs: { data: null } as IPaginatedJobList,
  workHistory: { data: null } as IPaginatedJobList,
  majors: { data: null } as IPaginatedMajorList,
  harnessCountries: { data: null, isLoading: false, error: null } as ICountries,
  cities: {
    predictions: [],
    status: '',
    isLoading: false,
  } as GooglePlaceResponse,
  myCollection: {
    data: null,
    isLoading: false,
    error: null,
  } as IMyCollectionStore,
  collectionProfiles: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollectionProfileList,
  collections: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollection,
  collectionInfo: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollectionInfoStore,
  popularCollections: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollection,
  collectionCollaborator: {} as PaginatedSavedCollectionCollaboratorList,
  languages: {} as PaginatedLanguageList,
  lookups: {} as IPaginatedLookUpList,
  addedLookupValues: {} as Record<string, SelectOption[]>,
  user: {
    data: null,
    isLoading: false,
    error: null,
  } as IUserStore,
  degreeTypes: { data: null } as IDegree,
  harnessUi: {
    data: {
      dialog: null,
      dialogConfirmClose: false,
    },
    isLoading: false,
    error: null,
  } as UIStore,
  profileAwards: genericBaseEmptyState as IProfileAwardsStore,
  profileCertifications: genericBaseEmptyState as IProfileCertificationsStore,
  profilePublications: genericBaseEmptyState as IProfilePublicationsStore,
  projectList: {
    query: '',
    data: [],
    isLoading: false,
    error: null,
  } as IProjectList,
  titleRawTerm: genericBaseEmptyState as IRawTitle,
  onboarding: {
    tasks: [] as Array<ProfileOnboardingTask>,
    job: null,
  } as IOnboarding,
  addKnowledge: {
    opened: false,
    selectedKnowledge: null,
    lookupKnowledges: [],
    isLookupKnowledgeLoading: false,
    lookupExperiences: {},
    isLookupExperiencesLoading: true,
    error: null,
  } as IAddKnowledge,
  manageExperience: {
    opened: false,
    jobId: null,
    job: null,
    isJobLoading: false,
    error: null,
    skipAccrualUpdate: false,
  } as IManageExperience,
  manageExperiences: {
    opened: false,
    enableUpload: false,
    excludeJobs: false,
    activeEditSection: null,
    parsedExperiences: {
      profile: [],
      awards: [],
      certifications: [],
      educations: [],
      jobs: [],
      publications: [],
    },
  } as IManageExperiences,
  manageProject: {
    opened: false,
    closeAfterEditRole: false,
    selectedProject: null,
    selectedProjectId: null,
    initialState: null,
    skipAccrualUpdate: false,
    selectedJobId: null,
    data: {},
  } as IManageProject,
  accrualSummary: {
    accrualIsStale: false,
    data: [],
    isLoading: false,
    error: null,
    trackingContext: undefined,
  } as IAccrualSummary,
  harnessSearch: {
    savedSearchesDrawerIsOpen: false,
    shortlistDrawerIsOpen: false,
    createOrEditDialogIsOpen: false,
    collaboratorsDialogIsOpen: false,
    editId: undefined,
    initialShortlistOnSearchCreation: undefined,
  } as ISearch,
} as IState;
