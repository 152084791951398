import React from 'react';

import s from './ColumnSection.module.scss';

function ColumnSection({ title, children }: any) {
  return (
    <div className={s.root}>
      <div className={s.title}>{title}</div>
      <div className={s.content}>{children}</div>
    </div>
  );
}

export default ColumnSection;
