import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import EditDialog, { EditDialogProps } from '@/components/EditDialog';
import { RadioGroup, TextField } from '@/components/FormAdapters';
import { RootState } from '@/store';

const useStyles = makeStyles(() => ({
  isGoodMatchField: {
    marginTop: 20,
  },
}));

interface EditClientNoteProps extends EditDialogProps {
  initialValues: { id: string; client_note: { note: string; is_good_match: string } };
  onSubmit: (values: any) => Promise<any>;
  noteValue?: string;
  profile: any;
  showMatchOptions: boolean;
}

interface FormData {
  client_note: {
    note: string;
    is_good_match: string;
  };
}

const formName = `candidateClientNote`;
const connector = connect(
  (state: RootState) => {
    return {
      form: formName,
      noteValue: formValueSelector(formName)(state, 'client_note.note'),
    };
  },
  {
    notify,
  }
);

function EditClientNote({
  onSubmit,
  notify,
  handleSubmit: handleSubmitProp,
  reset,
  noteValue,
  profile,
  showMatchOptions,
  ...props
}: EditClientNoteProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FormData, EditClientNoteProps>) {
  const s = useStyles();

  const handleSubmit = (values: any) => {
    onSubmit(values)
      .then(() => {
        notify('Note to Research Manager updated.', 'success');
      })
      .catch((err: any) => {
        notify('Error while updating note to Research Manager.', 'error');
        throw err;
      });
  };

  return (
    <EditDialog
      {...props}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      fieldValue={noteValue}
    >
      <Field
        id="client_note.note"
        component={TextField}
        fullWidth
        multiline
        name="client_note.note"
        label="Note to Research Manager"
        maxRows={15}
      />
      {showMatchOptions && (
        <Field
          name="client_note.is_good_match"
          component={RadioGroup}
          label={`Is ${profile.first_name} a good match?`}
          className={s.isGoodMatchField}
          style={{ flexDirection: 'row' }}
          options={[
            { label: 'No', value: 'no' },
            { label: 'Maybe', value: 'maybe' },
            { label: 'Yes', value: 'yes' },
          ]}
        />
      )}
    </EditDialog>
  );
}

const ConnectedEditClientNote = connector(EditClientNote);

export default reduxForm<FormData, EditClientNoteProps>({
  enableReinitialize: true,
  form: formName,
})(ConnectedEditClientNote);
