import theme from '@/theme';

const ns = 'pill';

export const pillClasses = {
  error: `${ns}__error`,
  filled: `${ns}__filled`,
  info: `${ns}__info`,
  primary: `${ns}__primary`,
  outlined: `${ns}__outlined`,
  root: `${ns}__root`,
  rounded: `${ns}__rounded`,
  secondary: `${ns}__secondary`,
  success: `${ns}__success`,
  squared: `${ns}__squared`,
  warning: `${ns}__warning`,
  light: `${ns}__light`,
};

const { error, info, primary, outlined, rounded, secondary, success, warning, light } = pillClasses;

const styles = {
  pill: {
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 500,
    marginRight: '4px',
    [`&.${primary}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.primary.main,
        border: `solid 1px ${theme.palette.primary.main}`,
      },
    },
    [`&.${warning}`]: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.warning.main,
        border: `solid 1px ${theme.palette.warning.main}`,
      },
    },
    [`&.${secondary}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.secondary.main,
        border: `solid 1px ${theme.palette.secondary.main}`,
      },
    },
    [`&.${success}`]: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.success.main,
        border: `solid 1px ${theme.palette.success.main}`,
      },
    },
    [`&.${light}`]: {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
      color: theme.palette.grey[700],
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: 'rgba(0, 0, 0, 0.06)',
        border: `solid 1px rgba(0, 0, 0, 0.06)`,
      },
    },
    [`&.${info}`]: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.info.main,
        border: `solid 1px ${theme.palette.info.main}`,
      },
    },
    [`&.${error}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      [`&.${outlined}`]: {
        backgroundColor: 'unset !important',
        color: theme.palette.error.main,
        border: `solid 1px ${theme.palette.error.main}`,
      },
    },
    [`&.${rounded}`]: {
      borderRadius: '15px',
    },
    padding: '2px 8px 0',
    width: 'fit-content',
  },
};

export default styles;
