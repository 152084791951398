import { useState } from 'react';

import { useProfileId } from '@/hooks/store';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import { useJobAssignmentsQuery } from '@/profilev2/queries/jobAssignments';

import ExperienceListDrawer, { ExperienceListOrdering } from '../ExperienceListDrawer';

export const TITLE = 'Work Experiences';

const JobsDrawer = () => {
  const profileId = useProfileId();
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<ExperienceListOrdering>('-dates');

  const {
    data: experiences,
    isLoading,
    isRefetching,
  } = useJobAssignmentsQuery({ profileId, page, ordering: sortValue });

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title: TITLE,
    },
  ]);

  return (
    <ExperienceListDrawer
      title={TITLE}
      breadcrumbs={breadcrumbs}
      experiences={experiences}
      isLoading={isLoading}
      isRefetching={isRefetching}
      sortValue={sortValue}
      handleSortOrderChange={(newValue) => setSortValue(newValue)}
      page={page}
      handlePageChange={(newValue) => setPage(newValue)}
    />
  );
};

export default JobsDrawer;
