import Check from '@mui/icons-material/Check';
import MuiAutocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { TextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import { useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import Input from '@/componentsv2/Input';
import { CompleteKnowledge, SimpleKnowledgeRaw } from '@/openapi';
import { useSearchKnowledge } from '@/searchv2/queries/searchKnowledge';

import { IKnowledgeLookupProps } from './types';

const testId = 'of-knowledge-lookup';

const KnowledgeLookup = ({
  className,
  name = 'search-knowledge',
  onChange,
  TextFieldProps,
  currentKnowledges,
  ...rest
}: {
  className?: string;
  name?: string;
  onChange: (newValue: CompleteKnowledge | null) => void;
  TextFieldProps?: TextFieldProps;
  currentKnowledges: SimpleKnowledgeRaw[];
}) => {
  const [value, setValue] = useState(null);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 300);
  const { data, isFetching, isPreviousData } = useSearchKnowledge(
    {
      query: debouncedQuery,
    },
    { staleTime: 1000 * 60 * 5 }
  );

  return (
    <div className={className} data-testid={testId}>
      <MuiAutocomplete
        value={value}
        options={query ? (data ?? []) : []}
        getOptionLabel={(option) => option.name ?? ''}
        isOptionEqualToValue={(option, value) => option.knowledge_id === value.knowledge_id}
        size="small"
        inputValue={query}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'reset') {
            setQuery('');
            setValue(null);
            return;
          }
          setQuery(newInputValue);
        }}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        getOptionDisabled={(option) =>
          currentKnowledges.some((knowledge) => knowledge.id === option.knowledge_id)
        }
        renderInput={(params) => (
          <Input
            name={name}
            {...params}
            {...TextFieldProps}
            inputProps={{
              ...params.inputProps,
              placeholder: 'Search to Add Skills',
              ...(TextFieldProps?.inputProps ?? {}),
            }}
            InputProps={{
              ...params.InputProps,
              ...(TextFieldProps?.InputProps ?? {}),
              endAdornment: isFetching ? (
                <CircularProgress size={20} />
              ) : (
                params.InputProps?.endAdornment
              ),
            }}
          />
        )}
        filterOptions={(x) => x}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.knowledge_id}
            className={clsx(props.className, {
              'opacity-40': isPreviousData,
            })}
          >
            {props['aria-disabled'] ? <Check className="mr-2" /> : null}
            {option.name}
          </li>
        )}
        {...rest}
      />
    </div>
  );
};

export { testId as KnowledgeLookupTestId };
export type { IKnowledgeLookupProps };
export default KnowledgeLookup;
