import { KnowledgeTypeSegments } from '@/knowledge/components/KnowledgeListDrawer';

const APP_ROUTES = {
  admin: '/admin',
  callback: '/callback',
  collectionDetails: '/collections/:id',
  collections: '/collections',
  dashboard: '/dashboard',
  signup: '/signup?next=%2Fdashboard',
  login: '/login',
  logout: '/logout',
  my_collections: '/my_collections',
  onboarding: '/onboardingv2',
  profile: (id?: string) => `/profile${id ? `/${id}` : ''}`,
  profilev2: (id?: string) => `/profilev2${id ? `/${id}` : ''}`,
  search: '/search',
  searchv2: '/searchv2',
  savedSearch: (id: string, source: string) => `/searchv2/saved/${id}?source=${source}`,
  storybook: '/storybook',
};
export default APP_ROUTES;

const prependProfilePath = (path: string, profileId?: string) =>
  profileId ? [APP_ROUTES.profilev2(profileId), path].join('/') : path;

export const PROFILE_ROUTES = (profileId?: string) => ({
  colleague: (id: string) => prependProfilePath(`colleagues/${id}`, profileId),
  jobs: prependProfilePath('jobs', profileId),
  job: (id: string) => prependProfilePath(`jobs/${id}`, profileId),
  knowledgeType: (type: KnowledgeTypeSegments) =>
    prependProfilePath(`knowledge-types/${type}`, profileId),
  knowledge: (name: string, redirectedFrom?: string) => {
    const params = new URLSearchParams();
    params.append('q', name);
    if (redirectedFrom) {
      params.append('r', redirectedFrom);
    }
    return prependProfilePath(`knowledge?${params.toString()}`, profileId);
  },
  locations: prependProfilePath('locations', profileId),
  location: (name: string) => {
    const qs = new URLSearchParams();
    qs.append('q', name);
    return prependProfilePath(`location?${qs.toString()}`, profileId);
  },
  knowledgeOrLocation: (name: string, type = 'knowledge') =>
    type === 'knowledge'
      ? PROFILE_ROUTES(profileId).knowledge(name)
      : PROFILE_ROUTES(profileId).location(name),
  projects: prependProfilePath('contracts/projects', profileId),
  project: (id: string) => prependProfilePath(`contracts/projects/${id}`, profileId),
  proposals: prependProfilePath('contracts/proposals', profileId),
  proposal: (id: string) => prependProfilePath(`contracts/proposals/${id}`, profileId),
});

export const DASHBOARD_ROUTES = {
  jobs: 'jobs',
  job: (id: string) => `jobs/${id}`,
  knowledgeType: (type: KnowledgeTypeSegments) => `knowledge-types/${type}`,
  knowledge: (name: string) => {
    const qs = new URLSearchParams();
    qs.append('q', name);
    return `knowledge?${qs.toString()}`;
  },
  locations: 'locations',
  location: (name: string) => {
    const qs = new URLSearchParams();
    qs.append('q', name);
    return `location?${qs.toString()}`;
  },
  proposals: 'contracts/proposals',
  proposal: (id: string) => `contracts/proposals/${id}`,
};

export const SEARCH_ROUTES = (profileId: string) => ({
  knowledge: (name: string, redirectedFrom?: string) => {
    const qs = new URLSearchParams();
    qs.append('q', name);
    if (redirectedFrom) {
      qs.append('r', redirectedFrom);
    }
    return `profilev2/${profileId}/knowledge?${qs.toString()}`;
  },
  knowledgeOrLocation: (name: string, type = 'knowledge') =>
    type === 'knowledge'
      ? SEARCH_ROUTES(profileId).knowledge(name)
      : SEARCH_ROUTES(profileId).location(name),
  location: (name: string) => {
    const qs = new URLSearchParams();
    qs.append('q', name);
    return `profilev2/${profileId}/location?${qs.toString()}`;
  },
});
