import { FC } from 'react';

import Review from '@/components/ReviewConsultation/ReviewCard';
import { usePermissions } from '@/hooks/useAppContext';

interface FeedbackDetailsProps {
  isViewerExpert: boolean;
  isViewerRequester: boolean;
  clientReview?: any;
  expertReview?: any;
  requester: any;
  expert: any;
}

const FeedbackDetails: FC<FeedbackDetailsProps> = ({
  isViewerExpert,
  isViewerRequester,
  clientReview,
  expertReview,
  requester,
  expert,
}) => {
  const [canUpdateExpertReview, canUpdateClientReview] = usePermissions(
    [
      { service: 'consultation_review', action: 'update', resource: expertReview?.id },
      { service: 'consultation_review', action: 'update', resource: clientReview?.id },
    ],
    [expertReview?.id, clientReview?.id]
  );

  return (
    <div>
      {expertReview && (
        <Review
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          review={expertReview}
          title={
            isViewerRequester
              ? 'Your Feedback'
              : `Feedback from ${requester ? requester.first_name : 'Client'}`
          }
          targetName={`${expert ? expert.first_name : 'Expert'}`}
          reviewFromExpert={false}
          updateReviewEnabled={canUpdateExpertReview}
        />
      )}
      {clientReview && (
        <Review
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          review={clientReview}
          title={
            isViewerExpert
              ? 'Your Feedback'
              : `Feedback from ${expert ? expert.first_name : 'Expert'}`
          }
          targetName={`${requester ? requester.first_name : 'Client'}`}
          reviewFromExpert
          updateReviewEnabled={canUpdateClientReview}
        />
      )}
    </div>
  );
};

export default FeedbackDetails;
