import { PropsWithChildren } from 'react';

const ItemContainer = ({ children }: PropsWithChildren) => {
  return (
    <nav className="hidden xl:block" aria-label="Site Navigation">
      <ul className="flex items-center">{children}</ul>
    </nav>
  );
};

export default ItemContainer;
