import { AccountType } from '@/__generated__/graphql';

import { Viewer } from './viewer';

export function isAdvancedUser(user: Viewer) {
  return (
    user.admin ||
    (user.groups || []).some(
      (g) =>
        g?.account_type && [AccountType.Advanced, AccountType.Enterprise].includes(g.account_type)
    )
  );
}

export function isEnterpriseUser(user: Viewer) {
  return user.admin || (user.groups || []).some((g) => g?.account_type === AccountType.Enterprise);
}
