import ActionTypes from '@/actions/ActionTypes';
import { updateAt } from '@/utils/reducer';

const {
  EXPERT_SHOWCASE__UPDATE,
  EXPERT_SHOWCASE__REMOVE,
  EXPERT_SHOWCASE__BATCH_ADD,
  EXPERT_SHOWCASE_PROFILES__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__UPDATE,
  EXPERT_SHOWCASE_SECTION__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__REMOVE,
  EXPERT_SHOWCASE_SECTION__REMOVE,
} = ActionTypes;

const initialState = {
  all: [],
  full: {},
  subdomains: {},
  categories: {},
  sections: {},
};

function expertShowcaseReducer(state = {}, action: any) {
  switch (action.type) {
    case EXPERT_SHOWCASE__UPDATE:
      return {
        ...state,
        [action.path]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.path],
          ...action.page,
        },
      };
    case EXPERT_SHOWCASE_PROFILES__UPDATE:
      // @ts-expect-error TS(2339): Property 'sections' does not exist on type '{}'.
      const index = state.sections.findIndex((s: any) => s.id === action.sectionId);
      return {
        ...state,
        [action.path]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.path],
          // @ts-expect-error TS(2339): Property 'sections' does not exist on type '{}'.
          sections: updateAt(state.sections, index, {
            experts: action.experts,
          }),
        },
      };
    default:
      return state;
  }
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case EXPERT_SHOWCASE__UPDATE:
      const newState = {
        ...state,
        subdomains: {
          ...state.subdomains,
          [action.subdomain]: expertShowcaseReducer(
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state[action.subdomain],
            action
          ),
        },
      };

      if (action.page && action.page.id) {
        newState.full = {
          ...state.full,
          [action.page.id]: action.page,
        };

        // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
        newState.all = state.all.map((page) => {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          if (page.id === action.page.id) {
            return {
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              ...page,
              ...action.page,
            };
          }
          return page;
        });
      }

      return newState;
    case EXPERT_SHOWCASE__REMOVE:
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        all: state.all.filter((p) => p.id !== action.id),
      };
    case EXPERT_SHOWCASE__BATCH_ADD:
      return {
        ...state,
        all: action.pages,
      };
    case EXPERT_SHOWCASE_CATEGORY__UPDATE:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.category.id]: action.category,
        },
      };
    case EXPERT_SHOWCASE_SECTION__UPDATE:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.section.id]: action.section,
        },
      };
    case EXPERT_SHOWCASE_CATEGORY__REMOVE:
      const categories = { ...state.categories };
      // @ts-expect-error TS(2454): Variable 'newState' is used before being assigned.
      delete newState[action.id];
      return {
        ...state,
        categories,
      };
    case EXPERT_SHOWCASE_SECTION__REMOVE:
      const sections = { ...state.sections };
      // @ts-expect-error TS(2454): Variable 'newState' is used before being assigned.
      delete newState[action.id];
      return {
        ...state,
        sections,
      };
    default:
      return state;
  }
}
