import cx from 'classnames';
import React from 'react';

function FAIcon({
  icon,
  size,
  color,
  className,
  style,
  fixedWidth,
  iconSet = 'fa',
  ...other
}: any) {
  const iconStyle = { color };
  // @ts-expect-error TS(2339): Property 'fontSize' does not exist on type '{ colo... Remove this comment to see the full error message
  if (size) iconStyle.fontSize = size;

  const combinedStyle = { ...iconStyle, ...style };
  const classes = cx(iconSet, `fa-${icon}`, className, {
    'fa-fw': fixedWidth,
  });

  return <i className={classes} style={combinedStyle} {...other} />;
}

export default FAIcon;
