import React, { PureComponent } from 'react';

import LocationForm from '@/profile/components/forms/LocationForm';

import SaveForm from './DeprecatedSaveForm';

interface LocationProps {
  profileId: string;
}

class Location extends PureComponent<LocationProps> {
  render() {
    const { profileId } = this.props;

    return (
      <LocationForm
        component={SaveForm}
        profileId={profileId}
        style={{ width: 500 }}
        saveMessage="Location updated."
      />
    );
  }
}

export default Location;
