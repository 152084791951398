/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileProjectLeaderList } from '../models/PaginatedProfileProjectLeaderList';
import type { PatchedProjectCreateUpdate } from '../models/PatchedProjectCreateUpdate';
import type { Project } from '../models/Project';
import type { ProjectCreateUpdate } from '../models/ProjectCreateUpdate';
import type { ProjectQuickMatch } from '../models/ProjectQuickMatch';

export class ProjectService {
  /**
   * List all projects
   * List all projects
   * @param limit Number of results
   * @param offset Offsets of the results
   * @param query Quickmatch for projects.
   * @param type Get the projects of an specific type
   * @returns ProjectQuickMatch
   * @throws ApiError
   */
  public static projectList(
    limit?: number,
    offset?: number,
    query?: string,
    type?: 'project' | 'proposal'
  ): CancelablePromise<Array<ProjectQuickMatch>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/project/',
      query: {
        limit: limit,
        offset: offset,
        query: query,
        type: type,
      },
    });
  }

  /**
   * Create a project
   * Create a project
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectCreate(requestBody: ProjectCreateUpdate): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/project/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a project by UUID
   * Retrieve a project by UUID
   * @param id A UUID string identifying this project.
   * @returns Project
   * @throws ApiError
   */
  public static projectRetrieve(id: string): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/project/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a project by UUID
   * Completely update a project by UUID
   * @param id A UUID string identifying this project.
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectUpdate(
    id: string,
    requestBody: ProjectCreateUpdate
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/project/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a project by UUID
   * Update specific fields within a project by UUID
   * @param id A UUID string identifying this project.
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectPartialUpdate(
    id: string,
    requestBody?: PatchedProjectCreateUpdate
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/project/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a project by UUID
   * Delete a project by UUID
   * @param id A UUID string identifying this project.
   * @returns void
   * @throws ApiError
   */
  public static projectDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/project/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * List of the leaders that worked in a project with a given user
   * List of the leaders that worked in a project with a given user and their mutual experiences
   * @param id A UUID string identifying this project.
   * @param profileId Filter by profile_id
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedProfileProjectLeaderList
   * @throws ApiError
   */
  public static projectLeadersList(
    id: string,
    profileId: string,
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedProfileProjectLeaderList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/project/{id}/leaders/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }
}
