import Grid from '@mui/material/Grid';
import React from 'react';

import ModelArray from './ModelArray';
import ModelField from './ModelField';

export default function ModelFields({ fields, form }: any) {
  return fields.map((field: any) =>
    !field.fields ? (
      <Grid item md={field.columnWidth || 4} key={field.name}>
        <ModelField {...field} form={form} />
      </Grid>
    ) : (
      <ModelArray {...field} form={form} key={field.name} />
    )
  );
}
