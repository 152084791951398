import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, PaginatedProjectMemberList, ProjectMemberService } from '@/openapi';

export const DEFAULT_ROLES_LIMIT = 10;

type FetchRoles = {
  profileId: string;
  projectId?: string;
  page?: number;
  limit?: number;
};

export type RolesUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  keepPreviousData?: boolean;
  refetchOnMount?: boolean | 'always';
};

const fetchRoles = ({
  profileId,
  projectId,
  page = 1,
  limit = DEFAULT_ROLES_LIMIT,
}: FetchRoles): CancelablePromise<PaginatedProjectMemberList> => {
  const offset = (page - 1) * limit;
  return ProjectMemberService.projectMemberList(limit, offset, profileId, projectId);
};

export const useRolesForUserByProjectIdQuery = (
  { profileId, projectId, page, limit }: FetchRoles,
  options?: RolesUseQueryOptions
) => {
  return useQuery({
    queryKey: ['project-members', { profileId, projectId, page, limit }],
    queryFn: () => fetchRoles({ profileId, projectId, page, limit }),
    keepPreviousData: true,
    enabled: !!projectId,
    ...options,
  });
};
