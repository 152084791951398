import ActionTypes from '@/actions/ActionTypes';
import { getCache, setCache } from '@/utils';

const initialState = {
  answers: [],
  steps: {},
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.COMPLIANCE_TRAINING__ANSWER_QUESTION:
      return {
        ...state,
        answers: state.answers
          // @ts-expect-error TS(2339): Property 'questionId' does not exist on type 'neve... Remove this comment to see the full error message
          .filter((answer) => answer.questionId !== action.questionId)
          .concat({
            // @ts-expect-error TS(2769): No overload matches this call.
            questionId: action.questionId,
            answerId: action.answerId,
          }),
      };

    case ActionTypes.COMPLIANCE_TRAINING__COMPLETE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.userId]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state.steps[action.userId],
            [action.step]: new Date(),
          },
        },
      };

    default:
      return state;
  }
}

export default function (state = initialState, action: any) {
  if (action.type === ActionTypes.LOAD_FROM_LOCAL_STORAGE) {
    const cachedSteps = getCache('expertComplianceTraining.steps');
    if (cachedSteps) {
      return {
        ...state,
        steps: cachedSteps,
      };
    }
  }

  const newState = reducer(state, action);

  if (state.steps !== newState.steps) {
    setCache('expertComplianceTraining.steps', newState.steps);
  }

  return newState;
}
