import { gengql } from '@/__generated__';

export const SAVE_PROJECT_FIELDS = gengql(/* GraphQL */ `
  fragment SaveProjectFields on Project {
    id
    name
    tracking_code
    group {
      id
      name
      html_url
    }
    members {
      id
      role
      state
      email
      user {
        id
        name
        first_name
        last_name
        html_url
        picture_url
      }
    }
  }
`);

export const UPDATE_PROJECT = gengql(/* GraphQL */ `
  mutation actionUpdateProject(
    $id: String!
    $name: String
    $group_id: String
    $tracking_code: String
  ) {
    updateProject(id: $id, name: $name, group_id: $group_id, tracking_code: $tracking_code) {
      ...SaveProjectFields
    }
  }
`);

export const CREATE_PROJECT = gengql(/* GraphQL */ `
  mutation actionCreateProject($name: String, $group_id: String, $tracking_code: String) {
    createProject(name: $name, group_id: $group_id, tracking_code: $tracking_code) {
      ...SaveProjectFields
    }
  }
`);
