import ActionTypes from '@/actions/ActionTypes';

const { TRACKING_LOG__CREATE } = ActionTypes;

export default function (state = {}, action: any) {
  switch (action.type) {
    case TRACKING_LOG__CREATE:
      return {
        ...state,
        [`${action.tracking.code}:${action.tracking.object_id}`]: action.tracking,
      };
    default:
      return state;
  }
}
