import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SavedCollection, SavedCollectionService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

import { ICollectionInfoStore } from '../collectionInfoData';

let previousId: string;

export const fetchData = createAsyncThunk(
  'collectionInfo/retrieveData',
  async ({ id, force = false }: { id: string; force?: boolean }, { getState }) => {
    if (previousId !== id || force) {
      previousId = id;
      return await SavedCollectionService.savedCollectionRetrieve(id);
    }

    const state: IState = getState() as IState;
    return state.collectionInfo.data;
  }
);

const collectionInfoSlice = createSlice({
  name: 'collectionInfo',
  initialState: initialState.collectionInfo,
  reducers: {
    updateData: (state, action: PayloadAction<Partial<ICollectionInfoStore>>) => ({
      ...state,
      ...action.payload,
    }),
    resetData: () => initialState.collectionInfo,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload as SavedCollection,
      isLoading: false,
      error: null,
    }));
    builder.addCase(fetchData.rejected, (state) => {
      return {
        ...state,
        error: {
          message: 'Rejected',
          status: 404,
        },
      };
    });
  },
});

export const collectionInfoSelector = (state: IState) => state.collectionInfo;
export const { updateData, resetData } = collectionInfoSlice.actions;
export default collectionInfoSlice.reducer;
