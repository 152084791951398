import { Helmet } from 'react-helmet';

import RequestPasswordReset from '@/auth/components/RequestPasswordReset';
import SetPassword from '@/auth/components/SetPassword';
import LayoutPage from '@/components/Layout/LayoutPage';
import { LegacyRoute } from '@/routes/routesMiddleware';

const route: LegacyRoute = {
  path: '/password_reset',

  async action({ query }) {
    const token = query.get('password_token');

    return (
      <LayoutPage>
        <Helmet>
          <title>Reset Password | OnFrontiers</title>
        </Helmet>
        {token ? <SetPassword token={token} /> : <RequestPasswordReset />}
      </LayoutPage>
    );
  },
};

export default route;
