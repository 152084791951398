import StarRatingComponent from 'react-star-rating-component';

import { borderColor, darkGreen } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import s from './StarRating.module.scss';

function StarRating({ title, style, ...other }: any) {
  return (
    <div className={s.rating} style={style}>
      <div className={s.label}>{title}</div>
      <StarRatingComponent
        editing
        starColor={darkGreen}
        emptyStarColor={borderColor}
        starCount={5}
        renderStarIcon={(index: any, value: any) => (
          <FAIcon icon={index <= value ? 'star' : 'star-o'} size={24} style={{ marginRight: 3 }} />
        )}
        {...other}
      />
    </div>
  );
}

export default StarRating;
