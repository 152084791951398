import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CancelablePromise, Project, ProjectService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<Project>;

export const fetchData = createAsyncThunk('project/fetchData', async (id: string) => {
  request?.cancel();
  request = ProjectService.projectRetrieve(id);
  return request;
});

const projectSlice = createSlice({
  name: 'project',
  initialState: initialState.project,
  reducers: {
    updateData: (state, action: PayloadAction<Project>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.project,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const projectSelector = (state: IState) => state.project;
export const { updateData, resetData } = projectSlice.actions;
export default projectSlice.reducer;
