import cx from 'classnames';
import React from 'react';

import { safeHtml } from '@/utils';

import s from './Article.module.scss';

function Article({ className, html, ...other }: any) {
  return (
    <article
      className={cx(s.article, className)}
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      dangerouslySetInnerHTML={{ __html: safeHtml(html) }}
      {...other}
    />
  );
}

export default Article;
