import EventEmitter from 'events';

import { fibonacci } from '@/utils';

export const QUEUE_IDS = {
  CONSULTATION_EXPERT_CALL_STATUS: 'consultation:expert_call_status',
  CHAT_UNREAD_MESSAGES: 'chat:unread_messages',
} as const;

export type QueueId = (typeof QUEUE_IDS)[keyof typeof QUEUE_IDS];

export interface QueueKey {
  queue: QueueId;
  resource: string;
}

interface Event {
  service: string;
  queue: string;
  resource: string;
  payload: Record<string, any>;
}

interface SubscribeRequest {
  service: string;
  queue: string;
  resource: string;
  action: 'subscribe' | 'unsubscribe';
}

type Listener = (payload: Record<string, any>) => void;

function buildKey(service: string, queue: string, resource: string) {
  return `${service}:${queue}:${resource}`;
}

class ApiWebSocket {
  private apiToken?: string;
  private conn: Promise<WebSocket> | null;
  private emitter: EventEmitter;
  private reconnectAttempts: number;
  private url: string;

  constructor(url: string, apiToken?: string) {
    this.emitter = new EventEmitter();
    this.url = url;
    this.apiToken = apiToken;
    this.reconnectAttempts = 0;
    this.conn = null;
  }

  // -- EVENT EMITTER

  on(queueId: QueueId, resource: string, listener: Listener) {
    const [service, queue] = queueId.split(':');
    this.sendRequest({
      service,
      queue,
      resource,
      action: 'subscribe',
    });

    const key = buildKey(service, queue, resource);
    this.emitter.on(key, listener);
    return () => this.removeListener(queueId, resource, listener);
  }

  removeListener(queueId: QueueId, resource: string, listener: Listener) {
    const [service, queue] = queueId.split(':');
    this.sendRequest({
      service,
      queue,
      resource,
      action: 'unsubscribe',
    });

    const key = buildKey(service, queue, resource);
    this.emitter.removeListener(key, listener);
  }

  // -- HANDLE CONNECTION

  private async sendRequest(eventPayload: SubscribeRequest) {
    const ws = await this.loadConnection();
    ws.send(JSON.stringify(eventPayload));
  }

  private async loadConnection() {
    if (this.conn) return this.conn;

    this.conn = new Promise((resolve) => {
      const ws = new WebSocket(this.url, this.apiToken && ['X-Authorization', this.apiToken]);
      ws.onclose = (evt) => {
        console.debug(
          'WebSocket connection closed -',
          'code:',
          evt.code,
          'clean',
          evt.wasClean,
          'reason',
          evt.reason
        );
        this.conn = null;
        if (!evt.wasClean) {
          // if wasn't a clean close, try to reconnect
          const seconds = fibonacci(1, ++this.reconnectAttempts);
          console.debug('Attempting to reconnect in', seconds, 'seconds...');
          setTimeout(() => this.loadConnection(), seconds * 1000);
        }
      };

      ws.onmessage = (evt) => {
        const data = JSON.parse(evt.data) as Event;
        const key = buildKey(data.service, data.queue, data.resource);
        this.emitter.emit(key, data.payload);
      };

      ws.onopen = () => {
        this.reconnectAttempts = 0;
        resolve(ws);
      };
    });

    return this.conn;
  }
}

export default ApiWebSocket;
