import { AutocompleteValue } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import { Select } from '@/components/Select';

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  selected: {
    fontWeight: 500,
    backgroundColor: 'transparent !important',
  },
  small: {
    fontSize: 14,
  },
  placeholder: {
    opacity: 0.42,
    color: 'currentColor',
    fontWeight: 'normal',
  },
});

interface FilterSelectProps<
  Value,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> {
  placeholder?: string;
  disabled?: boolean;
  multiple?: Multiple;
  options: readonly any[];

  value?: ('' | Value) & AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>;
  onChange: any;
}

const FilterSelect = <
  Value,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  placeholder,
  disabled,
  multiple,
  options,
  value,
  onChange,
}: FilterSelectProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();

  return (
    <Select<Value, Multiple, DisableClearable, FreeSolo>
      value={value}
      multiple={multiple}
      options={options}
      onChange={onChange}
      disabled={disabled}
      disableUnderline
      displayEmpty
      displayCheckbox={multiple}
      classes={{ root: cx(classes.small, classes.bold) }}
      margin="none"
      menuItemClasses={{ root: classes.small, selected: classes.selected }}
      // Do not move menu list when selecting items
      //MenuProps={{ getContentAnchorEl: null }}
      renderValue={(selected: any) => {
        if (placeholder && selected.length === 0) {
          return <span className={classes.placeholder}>{placeholder}</span>;
        }
        return (multiple ? selected : [selected])
          .map((v: any) => options.find((o: any) => o.value === v)?.label)
          .join(', ');
      }}
    />
  );
};

export default FilterSelect;
