/// <reference types="@types/google.maps" />
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GooglePlaceResponse } from '@/core/cities';
import { FilterRequest } from '@/core/pagination';
import initialState from '@/store/initialState';
import IState from '@/store/state';

const service = window.google ? new google.maps.places.AutocompleteService() : null;

export const fetchData = createAsyncThunk(
  'cities/fetchData',
  async (filters: FilterRequest): Promise<GooglePlaceResponse> => {
    if (!filters.query || !service) {
      return Promise.resolve({
        predictions: [],
        status: '',
        isLoading: false,
      } as GooglePlaceResponse);
    }

    const results = await service.getPlacePredictions({
      input: filters.query,
      language: 'en',
      types: ['(cities)'],
    });

    return results as GooglePlaceResponse;
  }
);

const citiesSlice = createSlice({
  name: 'cities',
  initialState: initialState.cities,
  reducers: {
    updateData(state, action: PayloadAction<GooglePlaceResponse>) {
      return { ...state, ...action.payload };
    },
    resetData() {
      return initialState.cities;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }));
  },
});

export const citiesSelector = (state: IState) => state.cities;
export const { updateData, resetData } = citiesSlice.actions;
export default citiesSlice.reducer;
