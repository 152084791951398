import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Currency from '@/components/Currency/Currency';
import Dialog from '@/components/Dialog';
import DurationField from '@/components/DurationField';
import { isDurationEmpty } from '@/consultation';
import { Trigger, fetchPredictTransactions, updateConsultation } from '@/consultation/store';
import { money } from '@/core/money';
import { formatCreditsText } from '@/utils';

const useStyles = makeStyles(() => ({
  headerText: {
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10,
  },
  valueText: {
    marginBottom: 10,
  },
}));

function Transactions({ fetchPredictTransactions, consultation, values }: any) {
  const s = useStyles();

  const {
    bill_rate: billRate,
    credit_rate: creditRate,
    expected_duration: expectedDuration,
  } = values;
  const { expert_request: expertRequest, group, engagement_type: engagementType } = consultation;

  const [expertEarnings, setExpertEarnings] = useState(null);
  const [clientExpertFee, setClientExpertFee] = useState(null);
  const [bookingFee, setBookingFee] = useState(null);

  useEffect(() => {
    // Fetch new fees on expert negotiation
    if (expertRequest?.id || group) {
      (async function () {
        const predictTransactions = await fetchPredictTransactions(
          consultation.expert.id,
          expertRequest?.id,
          group?.id,
          engagementType,
          expectedDuration,
          billRate,
          creditRate
        );
        setExpertEarnings(money(predictTransactions.expert_earnings).cents || 0);
        setClientExpertFee(money(predictTransactions.client_expert_fee).cents || 0);
        setBookingFee(money(predictTransactions.booking_fee).cents || 0);
      })();
    }
  }, [consultation, values]);
  return (
    <>
      <div className={s.headerText}>Predicted Transactions:</div>
      <div className={s.valueText}>
        <p>Booking Fee: {formatCreditsText(bookingFee ?? 0)}</p>
        <p>Expert Time: {formatCreditsText(clientExpertFee ?? 0)}</p>
        <p>Expert Earnings: ${expertEarnings ? (expertEarnings / 100).toFixed(2) : '0.00'}</p>
      </div>
    </>
  );
}
// @ts-expect-error TS(2630) FIXME: Cannot assign to 'Transactions' because it is a fu... Remove this comment to see the full error message
Transactions = connect(undefined, {
  fetchPredictTransactions,
})(Transactions);

function RestartNegotiation({
  consultation,
  updateConsultation,
  notify,
  onClose,
  viewer,
  ...other
}: any) {
  const {
    bill_rate: billRate,
    credit_rate: creditRate,
    expected_duration: expectedDuration,
  } = consultation;

  const initialValues = useMemo(
    () => ({
      bill_rate: billRate,
      credit_rate: creditRate,
      expected_duration: expectedDuration,
    }),
    []
  );

  const handleSubmit = useCallback(async (values: any) => {
    try {
      const {
        bill_rate: billRate,
        credit_rate: creditRate,
        expected_duration: expectedDuration,
      } = values;
      await updateConsultation({
        id: consultation.id,
        state: 'negotiating_expert_time',
        bill_rate: billRate,
        credit_rate: creditRate,
        duration: expectedDuration,
        initial_expected_duration: expectedDuration,
        trigger: Trigger.consultationPage,
      });
      notify('The consultation negotiation has been restarted.', 'success');
    } catch (err) {
      notify('An error occurred.', 'error');
    }
    onClose();
  }, []);

  const validate = useCallback((values: any) => {
    const errors = {};

    ['bill_rate', 'credit_rate'].forEach((f) => {
      const value = values[f];
      if (value === '' || value === undefined) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[f] = 'Required';
      }

      if (value < 0) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[f] = 'Must be a positive number';
      }
    });

    if (isDurationEmpty(values.expected_duration)) {
      // @ts-expect-error TS(2339) FIXME: Property 'expected_duration' does not exist on typ... Remove this comment to see the full error message
      errors.expected_duration = 'Must set a positive duration';
    }

    return errors;
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true, values: true }}
    >
      {({ handleSubmit, submitting, values }) => {
        return (
          <Dialog
            {...other}
            title="Restart Negotiation"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Suggest to Expert"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={Currency}
                name="credit_rate"
                label="Consumer Rate"
                variant="outlined"
                position="end"
                adornment="credits/hr"
              />
              <Field
                component={Currency}
                name="bill_rate"
                label="Expert Rate"
                variant="outlined"
                position="end"
                adornment="USD/hr"
              />
              <DurationField
                id="expertRequestExpectedDuration"
                name="expected_duration"
                label="Expert Hours"
                minHours={1}
                required
                variant="outlined"
              />
              <Transactions consultation={consultation} values={values} />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'RestartNegotiation' because it i... Remove this comment to see the full error message
RestartNegotiation = connect(undefined, {
  updateConsultation,
  notify,
})(RestartNegotiation);

export default RestartNegotiation;
