import cx from 'classnames';
import moment from 'moment-timezone';
import React from 'react';

import { safeHtml } from '@/utils';

import s from './BlogPostPreview.module.scss';

export function postToProps(post: any, mediaSize = 'gazette-post-thumbnail') {
  let sourceUrl = post.featured_media && post.featured_media.source_url;
  const mediaDetails = post.featured_media && post.featured_media.media_details;
  const thumbnail = mediaDetails && mediaDetails.sizes && mediaDetails.sizes[mediaSize];
  if (thumbnail) sourceUrl = thumbnail.source_url;

  return {
    url: post.link,
    author: post.author.name,
    date: post.date,
    title: post.title.rendered,
    excerpt: post.excerpt_text,
    thumbnail_url: sourceUrl,
    slug: post.slug,
  };
}

function BlogPostPreview({ highlight, className, post, ...other }: any) {
  const mediaStyle = {
    backgroundImage: `url(${post.thumbnail_url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div
      className={cx(s.root, {
        [s.highlight]: highlight,
        [className]: !!className,
      })}
      {...other}
    >
      <a href={post.url} className={cx(s.mediaLink)} style={mediaStyle} />

      <div className={cx(s.post)}>
        <div className={s.author}>
          By <span className={s.authorName}>{post.author}</span> |{' '}
          {moment(post.date).format('D MMMM YYYY')}
        </div>

        <a
          href={post.url}
          className={s.title}
          // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          dangerouslySetInnerHTML={{ __html: safeHtml(post.title) }}
        />

        <a
          href={post.url}
          className={s.preview}
          // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          dangerouslySetInnerHTML={{ __html: safeHtml(post.excerpt) }}
        />
      </div>
    </div>
  );
}

export default BlogPostPreview;
