import cx from 'classnames';
import { memo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import AddButton from '@/profile/components/forms/buttons/AddButton';
import { black, borderColor } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import s from './DraggableList.module.scss';

function Input({
  showRemove,
  name,
  index,
  onAdd,
  onRemove,
  inputProps,
  inputComponent,
  className,
  dragClassName,
  removeClassName,
  value,
  disabled,
}: any) {
  const InputComponent = inputComponent;

  return (
    <Draggable draggableId={name} index={index}>
      {(provided: any, snapshot: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(s.container, className, snapshot.isDragging ? s.draggingListItem : '')}
        >
          {!disabled && (
            <div {...provided.dragHandleProps} className={cx(s.dragAction, dragClassName)}>
              <FAIcon icon="bars" className={s.dragButton} />
            </div>
          )}
          <div className={cx(s.removeAction, removeClassName)}>
            <FAIcon
              icon="trash"
              className={s.removeButton}
              onClick={() => showRemove && onRemove()}
              style={{
                color: showRemove ? black : borderColor,
                cursor: showRemove ? 'pointer' : 'not-allowed',
              }}
            />
          </div>

          <InputComponent name={name} index={index} onAdd={onAdd} value={value} {...inputProps} />
        </div>
      )}
    </Draggable>
  );
}

const DraggableList = memo(
  ({
    // @ts-expect-error TS(2339): Property 'fields' does not exist on type '{}'.
    fields,
    // @ts-expect-error TS(2339): Property 'inputProps' does not exist on type '{}'.
    inputProps,
    // @ts-expect-error TS(2339): Property 'inputComponent' does not exist on type '... Remove this comment to see the full error message
    inputComponent,
    // @ts-expect-error TS(2339): Property 'minLength' does not exist on type '{}'.
    minLength = 0,
    // @ts-expect-error TS(2339): Property 'addButtonLabel' does not exist on type '... Remove this comment to see the full error message
    addButtonLabel,
    // @ts-expect-error TS(2339): Property 'multi' does not exist on type '{}'.
    multi,
    // @ts-expect-error TS(2339): Property 'style' does not exist on type '{}'.
    style,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'itemClassName' does not exist on type '{... Remove this comment to see the full error message
    itemClassName,
    // @ts-expect-error TS(2339): Property 'removeClassName' does not exist on type ... Remove this comment to see the full error message
    removeClassName,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{}'.
    disabled,
    // @ts-expect-error TS(2339): Property 'showRemoveForField' does not exist on ty... Remove this comment to see the full error message
    showRemoveForField = () => true,
  }) => {
    const handleDragEnd = ({ destination, source }: any) => {
      if (!destination) return;
      fields.swap(source.index, destination.index);
    };

    const handleAdd = () => {
      const values = fields.value;
      const hasEmptyValue = values?.some((x: any) => (multi ? !Object.keys(x).length : !x));
      if (!hasEmptyValue) {
        fields.push(multi ? {} : '');
      }
    };

    const showRemove = fields.length > minLength;

    return (
      <>
        <div style={style} className={className}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((name: any, index: any) => (
                    <Input
                      key={name}
                      inputComponent={inputComponent}
                      name={name}
                      index={index}
                      inputProps={inputProps}
                      onAdd={handleAdd}
                      onRemove={() => fields.remove(index)}
                      showRemove={
                        !disabled && showRemove && showRemoveForField(fields.value?.[index])
                      }
                      disabled={disabled}
                      className={itemClassName}
                      removeClassName={removeClassName}
                      value={fields.value[index]}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {!disabled && (
          <div className={s.addAction}>
            <AddButton label={addButtonLabel} onClick={handleAdd} />
          </div>
        )}
      </>
    );
  }
);

export default DraggableList;
