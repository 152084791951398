import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import { X } from 'react-feather';

import { Breadcrumbs } from '@/componentsv2/Breadcrumbs';
import Button from '@/componentsv2/Button';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';

import { IDrawerHeaderProps } from '../types';

const DrawerHeader = ({
  breadcrumbs,
  className,
  onClose,
  slotActions,
  showUpdatingIndicator,
  updatingIndicatorText = 'Updating knowledge scores',
}: IDrawerHeaderProps) => {
  return (
    <header className={clsx(className, 'h-[68px] border-b border-gray-200')}>
      <div
        className={clsx('my-auto flex flex-1 items-start justify-between pl-20 pr-16', {
          'border-b border-gray-100 md:border-0': slotActions,
        })}
      >
        <div className="flex grow items-center justify-between self-stretch">
          <div>
            {breadcrumbs?.length ? (
              <Breadcrumbs>
                {breadcrumbs.map((link) => (
                  <Breadcrumbs.Item key={link.title} to={link.to}>
                    {link.title}
                  </Breadcrumbs.Item>
                ))}
              </Breadcrumbs>
            ) : null}
          </div>
          {showUpdatingIndicator ? (
            <div className="mr-16 mt-[6px] self-start">
              <UpdatingIndicator text={updatingIndicatorText} />
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center gap-10">
          {slotActions ? (
            <>
              <div className="hidden md:block">{slotActions}</div>
              <Divider
                className="hidden md:block"
                orientation="vertical"
                flexItem
                variant="middle"
              />
            </>
          ) : null}
          {onClose ? (
            <Button
              variant="tertiary"
              srText="close"
              onClick={(e: React.MouseEvent<HTMLElement>) => onClose(e, 'backdropClick')}
              startIcon={X}
              size="large"
            />
          ) : null}
        </div>
      </div>
      {slotActions ? (
        <div className="flex justify-end bg-gray-50 px-16 py-8 md:hidden">
          <div className="md:hidden">{slotActions}</div>
        </div>
      ) : null}
    </header>
  );
};

export default DrawerHeader;
