/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileEmailList } from '../models/PaginatedProfileEmailList';
import type { PatchedProfileEmailUpdate } from '../models/PatchedProfileEmailUpdate';
import type { ProfileEmail } from '../models/ProfileEmail';
import type { ProfileEmailCreate } from '../models/ProfileEmailCreate';
import type { ProfileEmailUpdate } from '../models/ProfileEmailUpdate';

export class ProfileEmailService {
  /**
   * List all profile emails
   * List all profile emails
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param query Filter the email list by email or name
   * @returns PaginatedProfileEmailList
   * @throws ApiError
   */
  public static profileEmailList(
    limit: number = 10,
    offset?: number,
    query?: string
  ): CancelablePromise<PaginatedProfileEmailList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_email/',
      query: {
        limit: limit,
        offset: offset,
        query: query,
      },
    });
  }

  /**
   * Create a profile email
   * Create a profile email
   * @param requestBody
   * @returns ProfileEmailCreate
   * @throws ApiError
   */
  public static profileEmailCreate(
    requestBody: ProfileEmailCreate
  ): CancelablePromise<ProfileEmailCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile_email/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a profile email by UUID
   * Retrieve a profile email by UUID
   * @param id A UUID string identifying this profile email.
   * @returns ProfileEmail
   * @throws ApiError
   */
  public static profileEmailRetrieve(id: string): CancelablePromise<ProfileEmail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_email/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a profile email by UUID
   * Completely update a profile email by UUID
   * @param id A UUID string identifying this profile email.
   * @param requestBody
   * @returns ProfileEmailUpdate
   * @throws ApiError
   */
  public static profileEmailUpdate(
    id: string,
    requestBody: ProfileEmailUpdate
  ): CancelablePromise<ProfileEmailUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile_email/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a profile email by UUID
   * Update specific fields within a profile email by UUID
   * @param id A UUID string identifying this profile email.
   * @param requestBody
   * @returns ProfileEmailUpdate
   * @throws ApiError
   */
  public static profileEmailPartialUpdate(
    id: string,
    requestBody?: PatchedProfileEmailUpdate
  ): CancelablePromise<ProfileEmailUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_email/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a profile email by UUID
   * Delete a profile email by UUID
   * @param id A UUID string identifying this profile email.
   * @returns void
   * @throws ApiError
   */
  public static profileEmailDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile_email/{id}/',
      path: {
        id: id,
      },
    });
  }
}
