import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  PaginatedSearchFilterResponseList,
  SearchFilterResponse,
  SearchService,
} from '@/openapi';

export const DEFAULT_SEARCH_LIMIT = 10;

export type FilterName =
  | 'client_completion'
  | 'contract_completion'
  | 'current_location_completion'
  | 'department_completion'
  | 'education_major_completion'
  | 'knowledge_expertise_completion'
  | 'knowledge_raw_completion'
  | 'knowledge_skills_completion'
  | 'location_completion'
  | 'sector_completion'
  | 'skill_completion'
  | 'title_completion'
  | 'language_completion';

type FetchFilters = {
  query?: string;
  filterName?: FilterName;
  appliedFilters?: string[];
  searchInputQuery: string;
  limit?: number;
  page?: number;
  signal?: AbortSignal;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchFilters = ({
  query,
  filterName,
  appliedFilters,
  searchInputQuery,
  limit = DEFAULT_SEARCH_LIMIT,
  page = 1,
  signal,
}: FetchFilters): CancelablePromise<PaginatedSearchFilterResponseList> => {
  const offset = (page - 1) * limit;
  const response = SearchService.searchFiltersCreate(
    query || '',
    filterName,
    limit,
    offset,
    searchInputQuery,
    {
      filter_ids: appliedFilters ?? [],
    }
  );
  if (signal) {
    signal.addEventListener('abort', () => response.cancel());
  }
  return response;
};

export const useFiltersQuery = (
  { query, filterName, searchInputQuery, appliedFilters, page, limit }: FetchFilters,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['filters', { query, filterName, appliedFilters, searchInputQuery, page, limit }],
    queryFn:
      query && filterName
        ? async ({ signal }) =>
            fetchFilters({
              query,
              filterName,
              appliedFilters,
              searchInputQuery,
              page,
              limit,
              signal,
            })
        : () => ({
            count: 0,
            next: null,
            previous: null,
            results: [] as SearchFilterResponse[],
          }),
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });
};
