import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FilterRequest } from '@/core/pagination';
import { LanguageService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const fetchData = createAsyncThunk(
  'languages/fetchData',
  async (filters: FilterRequest = {}) => {
    return await LanguageService.languageList(filters.limit, filters.offset);
  }
);

const languagesSlice = createSlice({
  name: 'language',
  initialState: initialState.languages,
  reducers: {
    resetData() {
      return initialState.languages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      error: null,
    }));
  },
});

export const languagesSelector = (state: IState) => state.languages;
export const { resetData } = languagesSlice.actions;
export default languagesSlice.reducer;
