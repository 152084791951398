/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfilePublicationList } from '../models/PaginatedProfilePublicationList';
import type { PatchedProfilePublicationUpdate } from '../models/PatchedProfilePublicationUpdate';
import type { ProfilePublication } from '../models/ProfilePublication';
import type { ProfilePublicationCreate } from '../models/ProfilePublicationCreate';
import type { ProfilePublicationUpdate } from '../models/ProfilePublicationUpdate';

export class ProfilePublicationService {
  /**
   * List all Profile Publications
   * List all Profile Publications
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId
   * @returns PaginatedProfilePublicationList
   * @throws ApiError
   */
  public static profilePublicationList(
    limit?: number,
    offset?: number,
    profileId?: string
  ): CancelablePromise<PaginatedProfilePublicationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_publication/',
      query: {
        limit: limit,
        offset: offset,
        profile__id: profileId,
      },
    });
  }

  /**
   * Create a Profile Publication
   * Create a Profile Publication
   * @param requestBody
   * @returns ProfilePublicationCreate
   * @throws ApiError
   */
  public static profilePublicationCreate(
    requestBody?: ProfilePublicationCreate
  ): CancelablePromise<ProfilePublicationCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile_publication/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Profile Publication by UUID
   * Retrieve a Profile Publication by UUID
   * @param id A UUID string identifying this profile publication.
   * @returns ProfilePublication
   * @throws ApiError
   */
  public static profilePublicationRetrieve(id: string): CancelablePromise<ProfilePublication> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_publication/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Profile Publication by UUID
   * Completely update a Profile Publication by UUID
   * @param id A UUID string identifying this profile publication.
   * @param requestBody
   * @returns ProfilePublicationUpdate
   * @throws ApiError
   */
  public static profilePublicationUpdate(
    id: string,
    requestBody?: ProfilePublicationUpdate
  ): CancelablePromise<ProfilePublicationUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile_publication/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Profile Publication by UUID
   * Update specific fields within a Profile Publication by UUID
   * @param id A UUID string identifying this profile publication.
   * @param requestBody
   * @returns ProfilePublicationUpdate
   * @throws ApiError
   */
  public static profilePublicationPartialUpdate(
    id: string,
    requestBody?: PatchedProfilePublicationUpdate
  ): CancelablePromise<ProfilePublicationUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_publication/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Profile Publication by UUID
   * Delete a Profile Publication by UUID
   * @param id A UUID string identifying this profile publication.
   * @returns void
   * @throws ApiError
   */
  public static profilePublicationDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile_publication/{id}/',
      path: {
        id: id,
      },
    });
  }
}
