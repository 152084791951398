import Chip, { ChipProps } from '@mui/material/Chip';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { suggestUsers } from '@/actions/suggestion';
import { RootState } from '@/store';
import { isEmailValid } from '@/utils';

import Picture from '../Picture';
import SelectWithSuggestions from '../SelectWithSuggestions/SelectWithSuggestions';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  picture: {
    marginRight: 10,
  },
};

interface UserChipProps extends ChipProps {
  user: any;
}

class UserChip extends PureComponent<UserChipProps> {
  render() {
    const { user, ...other } = this.props;
    return (
      <Chip {...other} avatar={<Picture user={user} link={false} size={24} />} label={user.name} />
    );
  }
}

interface UserMenuItemProps extends MenuItemProps {
  user: any;
}

class UserMenuItem extends PureComponent<UserMenuItemProps> {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const { user, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span style={styles.root}>
          <Picture style={styles.picture} size={40} user={user} link={false} />
          <span>{user.name}</span>
        </span>
      </MenuItem>
    );
  }
}

interface InviteMenuItemProps extends MenuItemProps {
  email: string;
}

class InviteMenuItem extends PureComponent<InviteMenuItemProps> {
  static muiName = 'MenuItem';

  render() {
    const { email, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span style={styles.root}>
          <Picture style={styles.picture} size={40} link={false} />
          <span>Invite &quot;{email}&quot;</span>
        </span>
      </MenuItem>
    );
  }
}

const connector = connect(
  (state: RootState) => {
    const { loading: loadingSuggestions, cache: userSuggestions } = state.suggestions.users;

    return {
      userSuggestions,
      loadingSuggestions,
    };
  },
  {
    suggestUsers,
  }
);

class SelectUser extends PureComponent<ConnectedProps<typeof connector>> {
  renderOption = (props: any, { type, value }: any) => {
    if (type === 'email') {
      return <InviteMenuItem {...props} key={value.email} email={value.email} />;
    }

    // user
    return <UserMenuItem {...props} key={value.id} user={value} />;
  };

  renderTags = (value: any, getTagProps: any) => {
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
    const { disabled } = this.props;

    const props = {
      style: {
        margin: '5px',
        float: 'left',
        pointerEvents: disabled ? 'none' : undefined,
      },
    };
    return value.map(({ type, value }: any, index: any) => {
      if (type === 'email') {
        return (
          <Chip
            key={value.email}
            label={value.email}
            {...getTagProps({ index })}
            {...props}
            disabled={disabled}
          />
        );
      }
      return (
        <UserChip
          key={value.id}
          user={value}
          {...getTagProps({ index })}
          {...props}
          disabled={disabled}
        />
      );
    });
  };

  formatOption = ({ type, value }: any) => {
    if (type === 'email') {
      // email
      return {
        type,
        label: value.email,
        value,
      };
    }

    // user
    return {
      type,
      label: value.name,
      value,
    };
  };

  isValidEntry = (value: any) => {
    // @ts-expect-error TS(2339): Property 'allowEmail' does not exist on type 'Read... Remove this comment to see the full error message
    const { allowEmail = false, userFilter = () => true } = this.props;

    if (!userFilter(value)) return false;

    const type = value.type || 'email';
    const rawValue = value.value || value;
    if (
      type === 'email' && // not expecting emails or invalid email
      (!allowEmail || !isEmailValid(rawValue.email))
    )
      return false;

    return true;
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'allowEmail' does not exist on type 'Read... Remove this comment to see the full error message
      allowEmail = false,
      suggestUsers,
      // @ts-expect-error TS(2339): Property 'suggestBufferSize' does not exist on typ... Remove this comment to see the full error message
      suggestBufferSize,
      // @ts-expect-error TS(2339): Property 'userType' does not exist on type 'Readon... Remove this comment to see the full error message
      userType,
      // @ts-expect-error TS(2339): Property 'userFilter' does not exist on type 'Read... Remove this comment to see the full error message
      userFilter = () => true,
      userSuggestions,
      loadingSuggestions,
      // @ts-expect-error TS(2339): Property 'errorText' does not exist on type 'Reado... Remove this comment to see the full error message
      errorText = undefined,
      // @ts-expect-error TS(2339): Property 'multiple' does not exist on type 'Readon... Remove this comment to see the full error message
      multiple,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'underlineShow' does not exist on type 'R... Remove this comment to see the full error message
      underlineShow = true,
      ...rest
    } = this.props;

    return (
      <SelectWithSuggestions
        label="Find or invite users"
        {...rest}
        allowRaw={allowEmail}
        allowSuggestion
        suggest={suggestUsers}
        suggestBufferSize={suggestBufferSize}
        suggestScope="users"
        suggestionsFilter={userFilter}
        suggestions={userSuggestions}
        loadingSuggestions={loadingSuggestions}
        errorText={errorText}
        multiple={multiple}
        disabled={disabled}
        underlineShow={underlineShow}
        renderTags={this.renderTags}
        renderOption={this.renderOption}
        formatOption={this.formatOption}
        isValidEntry={this.isValidEntry}
        isValidRaw={isEmailValid}
        rawType="email"
        rawKey="email"
        suggestionType="user"
      />
    );
  }
}

export default connector(SelectUser);
