import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { typeCollectionOrdering } from '@/collection/components/CollectionSelector';
import { CancelablePromise, PaginatedSavedCollectionList, SavedCollectionService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let response: CancelablePromise<PaginatedSavedCollectionList>;

export const fetchData = createAsyncThunk(
  'popularCollections/fetchData',
  async ({
    limit = 4,
    offset,
    ordering,
  }: {
    limit: number;
    offset?: number;
    ordering?: typeCollectionOrdering;
  }) => {
    response?.cancel();
    response = SavedCollectionService.savedCollectionList(limit, offset, ordering, 'PUBLIC');

    return response;
  }
);

const popularCollectionSlice = createSlice({
  name: 'popularCollections',
  initialState: initialState.popularCollections,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedSavedCollectionList>) {
      state.data = action.payload;
    },
    resetData() {
      return initialState.popularCollections;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload as PaginatedSavedCollectionList,
      isLoading: false,
      error: null,
    }));
  },
});

export const popularCollectionsSelector = (state: IState) => state.popularCollections;
export const { updateData, resetData } = popularCollectionSlice.actions;
export default popularCollectionSlice.reducer;
