import MuiTextField from '@mui/material/TextField';
import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { renderTemplate } from '@/actions/messageTemplate';
import { notify } from '@/actions/ui';
import { Viewer } from '@/core/viewer';
import { channelUrlToId } from '@/messaging/sendbird';
import { createChannel } from '@/messaging/store';
import CandidateChip from '@/profile/components/AddToExpertRequestButton/CandidateChip';
import { RootState } from '@/store';

import CircularProgress from '../CircularProgress';
import Dialog from '../Dialog';
import FieldContainer from '../FieldContainer';
import { TextField } from '../FormAdapters';
import Link from '../Link';
import MessageTemplateVariablesDialog from './MessageTemplateVariablesDialog';
import MessageTemplatesDialog from './MessageTemplatesDialog';
import s from './SendMessageDialog.module.scss';

interface MessagePreviewProps {
  loading: boolean;
  message?: string;
}

const MessagePreview: FC<MessagePreviewProps> = ({ loading, message }) => {
  return (
    <div className={cx({ [s.messageLoading]: loading })}>
      {loading && (
        <div className={s.messageProgress}>
          <CircularProgress />
        </div>
      )}

      <div className={s.messageFields}>
        <FieldContainer className={s.messageField} label="Message">
          <MuiTextField
            InputProps={{ disableUnderline: true }}
            multiline
            fullWidth
            rows={10}
            maxRows={10}
            value={message || ''}
          />
        </FieldContainer>
      </div>
    </div>
  );
};

const sendMessageDialogConnector = connect(undefined, {
  notify,
  renderTemplate,
});

interface SendMessageDialogProps {
  profiles: any[];
  open: boolean;
  onClose: () => void;
  expertRequestId: string;
  viewer: Viewer;
}

interface FormData {
  message: string;
}

const SendMessageDialogBare: React.FC<
  SendMessageDialogProps &
    ConnectedProps<typeof sendMessageDialogConnector> &
    FormRenderProps<FormData>
> = ({
  profiles,
  open,
  onClose,
  expertRequestId,
  viewer,

  // Redux
  notify,
  renderTemplate,

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,
}) => {
  const [renderingExampleMessage, setRenderingExampleMessage] = useState(false);
  const [exampleMessage, setExampleMessage] = useState(undefined);
  const [exampleProfile, setExampleProfile] = useState(null);
  const [chooseTemplateDialogOpen, setChooseTemplateDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [variablesDialogOpen, setVariablesDialogOpen] = useState(false);

  const handleProfileRemove = (profile: any) => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    if (exampleProfile && profile.id === exampleProfile.id) {
      const newExample = profiles.find((p: any) => p.id !== profile.id);
      setExampleProfile(newExample);
    }
  };

  const handleSelectTemplate = async (template: any) => {
    if (template) {
      form.change('message', template.body);
    }
    setChooseTemplateDialogOpen(false);
  };

  useEffect(() => {
    const profile = exampleProfile || profiles[0];
    const canRenderExampleMessage = previewDialogOpen && expertRequestId && values.message !== '';

    if (!canRenderExampleMessage) {
      return;
    }

    setExampleProfile(profile);
    setRenderingExampleMessage(true);
    renderTemplate(values.message, {
      senderId: viewer.id as string,
      requestId: expertRequestId,
      profileId: profile.id,
    })
      .then((result: any) => {
        setRenderingExampleMessage(false);
        setExampleMessage(result);
      })
      .catch(() => {
        notify('An error occurred when rendering the template.', 'error');
        setRenderingExampleMessage(false);
        setExampleMessage(undefined);
      });
  }, [
    expertRequestId,
    profiles,
    exampleProfile,
    values.message,
    previewDialogOpen,
    viewer.id,
    renderTemplate,
    notify,
  ]);

  const handleExampleClick = () => {
    setExampleProfile(profiles[0]);
    setPreviewDialogOpen(true);
  };

  const title =
    profiles.length === 1
      ? `Send Message to ${profiles[0].first_name}`
      : 'Send Message to Multiple Experts';

  return (
    <Dialog
      useForm
      user={profiles.length === 1 ? profiles[0] : undefined}
      open={open}
      confirmLabel="Send"
      title={title}
      onSubmit={handleSubmit}
      onReset={() => form.reset()}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={submitting}
      submitting={submitting}
    >
      {profiles.length > 1 && (
        <FieldContainer
          label="Experts to message"
          className={s.expertsToAdd}
          containerClassName={s.expertsToAddContainer}
        >
          {profiles.map((p: any) => (
            <CandidateChip key={p.id} profile={p} onRequestDelete={() => handleProfileRemove(p)} />
          ))}
        </FieldContainer>
      )}
      <div className={s.messageField}>
        <Link onClick={() => setChooseTemplateDialogOpen(true)}>Select From Template</Link>
      </div>
      <MessageTemplatesDialog
        // @ts-expect-error TS(2769): No overload matches this call.
        open={chooseTemplateDialogOpen}
        onClose={() => setChooseTemplateDialogOpen(false)}
        onSelectTemplate={handleSelectTemplate}
      />
      <div className={s.messageField}>
        <Link onClick={() => setVariablesDialogOpen(true)}>View Available Template Variables</Link>
      </div>
      <MessageTemplateVariablesDialog
        open={variablesDialogOpen}
        title="Available Template Variables"
        onClose={() => setVariablesDialogOpen(false)}
      />
      <FieldContainer className={s.messageField} label="Message">
        <Field
          component={TextField}
          name="message"
          InputProps={{ disableUnderline: true }}
          fullWidth
          multiline
          rows={10}
          maxRows={10}
        />
      </FieldContainer>
      {profiles.length > 0 && (
        <div className={s.messageField}>
          <Link onClick={handleExampleClick}>Show Message Example</Link>
        </div>
      )}
      <Dialog
        open={previewDialogOpen}
        title="Preview"
        onClose={() => setPreviewDialogOpen(false)}
        onCancel={() => setPreviewDialogOpen(false)}
      >
        <MessagePreview loading={renderingExampleMessage} message={exampleMessage} />
      </Dialog>
    </Dialog>
  );
};

const SendMessageDialog = sendMessageDialogConnector(SendMessageDialogBare);

const sendMessageFormConnector = connect(
  (state: RootState) => {
    return {
      viewer: state.viewer,
    };
  },
  {
    notify,
    renderTemplate,
    createChannel,
  }
);

interface SendMessageFormProps {
  open: boolean;
  onClose: () => void;
  onSend: () => void;
  expertRequestId: string;
  profiles: any[];
}

export const SendMessageForm: FC<
  SendMessageFormProps & ConnectedProps<typeof sendMessageFormConnector>
> = ({
  open,
  viewer,
  profiles = [],
  onClose,
  onSend,
  expertRequestId,
  notify,
  renderTemplate,
  createChannel,
}) => {
  const validate = useCallback(({ message }: FormData) => {
    const errors = {};
    if (!message) {
      // @ts-expect-error TS(2339): Property 'message' does not exist on type '{}'.
      errors.message = 'Need to specify a message';
    }
    return errors;
  }, []);

  const sendMessageToUser = useCallback(
    async (profile: any, messageTemplate: any) => {
      const message = await renderTemplate(messageTemplate, {
        senderId: viewer.id as string,
        requestId: expertRequestId,
        profileId: profile.id,
      });

      const channel = await createChannel([viewer.id, profile.user.id]);
      await channel.sendUserMessage({ message });
      return channelUrlToId(channel.url);
    },
    [renderTemplate, viewer.id, expertRequestId, createChannel]
  );

  const handleSubmit = useCallback(
    async ({ message }: FormData) => {
      try {
        if (profiles.length === 1) {
          const channelId = await sendMessageToUser(profiles[0], message);
          window.open(`/messaging/${channelId}`, '_blank');
        } else {
          for (let i = 0; i < profiles.length; i++) {
            await sendMessageToUser(profiles[i], message);
            // stagger message sending to prevent potential rate limiting
            // errors from the sendbird API
            await new Promise((r) => setTimeout(r, 300));
          }
          notify(`Message sent to ${profiles.length} experts.`);
        }
        onSend();
        onClose();
      } catch (err) {
        notify(`Message sending to one or more users failed.`, 'error');
        throw err;
      }
    },
    [profiles, onSend, onClose, sendMessageToUser, notify]
  );

  const initialValues = useMemo(
    () => ({
      message: '',
    }),
    []
  );

  return (
    <Form<FormData>
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={(props) => (
        <SendMessageDialog
          {...props}
          profiles={profiles}
          open={open}
          onClose={onClose}
          expertRequestId={expertRequestId}
          viewer={viewer}
        />
      )}
      open={open}
      viewer={viewer}
      profiles={profiles}
      onClose={onClose}
      expertRequestId={expertRequestId}
    />
  );
};

export default sendMessageFormConnector(SendMessageForm);
