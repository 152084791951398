import { useSuspenseQuery } from '@apollo/client';
import { BaseMessage, FileMessage, Sender } from '@sendbird/chat/message';
import cx from 'classnames';
import moment from 'moment';
import { FC, memo } from 'react';

import { gengql } from '@/__generated__';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import SvgIcon from '@/components/Icon/SvgIcon';
import Link from '@/components/Link/Link';
import LongText from '@/components/LongText/LongText';
import { Viewer } from '@/core/viewer';
import { darkBrown, darkGray, darkGreen, sand, teal500, white } from '@/theme/colors';
import { prettyBytes } from '@/utils';

import s from './ChatMessage.module.scss';
import Message from './Message';

interface ChatMessageProps {
  message: BaseMessage;
  isRead?: boolean;
  isAnonymous?: boolean;
  isOwnMessage?: boolean;
}

const GET_USER = gengql(/* GraphQL */ `
  query getChatMessageUser($id: String!) {
    user(id: $id) {
      id
      profile {
        id
        name
        first_name
        last_name
        picture_url
        html_url
      }
    }
  }
`);

const ChatMessage: FC<ChatMessageProps> = memo(
  ({ message, isRead = false, isAnonymous = false, isOwnMessage = false }) => {
    const isToday = moment(message.createdAt).startOf('day').isSame(moment().startOf('day'));
    const dateFormat = isToday ? 'h:mma' : 'D MMM • h:mma';
    const showReadReceipt = isOwnMessage && isRead;

    const sender = (message as any).sender as Sender | undefined;
    if (!sender) {
      throw new Error('Sender is required for now');
    }

    const { data } = useSuspenseQuery(GET_USER, {
      variables: {
        id: sender.userId,
      },
      skip: isAnonymous,
    });

    const user = isAnonymous ? null : data?.user;
    const profile = user?.profile;

    const messageProps = isOwnMessage
      ? {
          color: white,
          borderColor: darkGreen,
          backgroundColor: darkGreen,
          position: 'right' as const,
          icon: profile ? undefined : (
            <SvgIcon icon="incognito" width={28} height={28} fill={white} />
          ),
        }
      : {
          color: darkBrown,
          borderColor: sand,
          backgroundColor: sand,
          position: 'left' as const,
        };

    return (
      <div
        className={cx(s.message, isOwnMessage ? s.messageRight : s.messageLeft, {
          [s.messageRead]: showReadReceipt,
        })}
      >
        <Message
          id={`message${message.messageId}`}
          pictureSize={40}
          bubbleClassName={cx(s.messageBubble, {
            [s.ownMessageBubble]: isOwnMessage,
          })}
          align="top"
          user={profile as Viewer | undefined}
          {...messageProps}
        >
          <MessageBody isOwnMessage={isOwnMessage} message={message} />
        </Message>
        <div className={s.messageDate}>
          {moment(message.createdAt).format(dateFormat)}
          {showReadReceipt && (
            <MaterialIcon className={s.readReceipt} icon="done" color={teal500} />
          )}
        </div>
      </div>
    );
  }
);
ChatMessage.displayName = 'ChatMessage';

interface MessageBodyProps {
  message: BaseMessage;
  isOwnMessage?: boolean;
}

const MessageBody: FC<MessageBodyProps> = ({ isOwnMessage, message }) => {
  if (message.isFileMessage()) {
    const fileMessage = message as FileMessage;
    return <Attachment message={fileMessage} isOwnMessage={isOwnMessage} />;
  }

  if (message.customType === 'consultation_feedback') {
    return <ConsultationFeedback message={message} isOwnMessage={isOwnMessage} />;
  }

  return <LongText linkify text={message.message} />;
};

interface AttachmentProps {
  message: FileMessage;
  isOwnMessage?: boolean;
}

const Attachment: FC<AttachmentProps> = ({ message, isOwnMessage = false }) => (
  <div>
    <Link href={`${message.url}`}>
      <MaterialIcon
        color={isOwnMessage ? white : darkGray}
        className={s.fileDownloadIcon}
        icon="file_download"
      />
    </Link>
    <Link className={s.fileName} href={message.url}>
      {message.name || 'Attachment'}
    </Link>
    {message.size > 0 && (
      <span className={s.fileSize}>{prettyBytes(message.size).toUpperCase()}</span>
    )}
  </div>
);

interface ConsultationFeedback {
  consultation_id: string;
  onfrontiers_feedback: boolean;
}

function parseConsultationFeedbackData(data: string): ConsultationFeedback {
  return JSON.parse(data) as ConsultationFeedback;
}

interface ConsultationFeedbackProps {
  message: BaseMessage;
  isOwnMessage?: boolean;
}

const ConsultationFeedback: FC<ConsultationFeedbackProps> = ({ message, isOwnMessage = false }) => {
  const feedback = parseConsultationFeedbackData(message.data);

  const title = feedback?.onfrontiers_feedback
    ? 'Private feeback for OnFrontiers'
    : isOwnMessage
      ? `Private feedback given`
      : `Private feedback for you`;

  return (
    <div>
      <b>{title}: </b>
      <LongText linkify text={message.message} style={{ display: 'inline' }} />
      {feedback?.consultation_id && (
        <div>
          <br />
          <Link to={`/consultation/${feedback.consultation_id}`}>View Consultation</Link>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
