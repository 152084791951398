import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { notify } from '@/actions/ui';
import Dialog from '@/components/Dialog/Dialog';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link/Link';
import { white } from '@/theme/colors';
import { isEmpty } from '@/utils';

import ModelField from './ModelField';
import ModelFields from './ModelFields';

const useStyles = makeStyles(() => ({
  root: {
    padding: 30,
  },
  icon: {
    marginRight: 10,
  },
  link: {
    display: 'block',
    marginBottom: 20,
  },
  actions: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    margin: '20px -16px 0 -16px',
    padding: 16,
    background: white,
  },
}));

function ModelForm({
  model,
  id,
  title,
  initialModel,
  fields,
  children,
  returnPath,
  onDelete,
  onSubmit,
  notify,
  validate,
  readOnly,
  ...rest
}: any) {
  const s = useStyles();
  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);

  const prepareFields = useCallback((fields: any, values: any, obj = {}) => {
    return fields.reduce((obj: any, f: any) => {
      const value = values[f.name];

      // Prevent send proxy url when saving, only new values
      if (f.type === 'file') {
        obj[`file_${f.name}`] = value;
      } else if (f.fields && value) {
        obj[f.name] = value.map((v: any) => prepareFields(f.fields, v, { id: v.id }));
      } else {
        obj[f.name] = value;
      }

      return obj;
    }, obj);
  }, []);

  const initialValues = useMemo(() => {
    const obj = { id };
    return prepareFields(fields, initialModel, obj);
  }, [fields, id, initialModel, prepareFields]);

  const defaultValidate = useCallback(
    (values: any) => {
      const errors = {};

      fields.forEach((f: any) => {
        if (f.required && isEmpty(values[f.name])) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          errors[f.name] = `Required`;
        }
      });

      return errors;
    },
    [fields]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await onSubmit(values);
        notify('Saved successfully');
      } catch (err) {
        const cause =
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          err.rawError && err.rawError[0] && `: ${err.rawError[0].message}`;
        notify(`An error has occurred${cause || ''}`, 'error');
        if (!cause) throw err;
      }
    },
    [notify, onSubmit]
  );

  const handleDelete = useCallback(async () => {
    try {
      await onDelete(id);
      notify('Deleted successfully');
      navigate(`/admin/${returnPath || model.path}`);
    } catch (err) {
      const cause =
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        err.rawError && err.rawError[0] && `: ${err.rawError[0].message}`;
      notify(`An error has occurred${cause || ''}`, 'error');
      if (!cause) throw err;
    }
  }, [id, model.path, navigate, notify, onDelete, returnPath]);

  return (
    <div className={s.root}>
      {returnPath && (
        <Link className={s.link} to={`/admin/${returnPath || model.path}`}>
          <FAIcon className={s.icon} icon="arrow-left" />
          Go back
        </Link>
      )}
      {title && <h4>{title}</h4>}
      <Form
        subscription={{
          submitting: true,
          values: true,
          initialValues: true,
        }}
        mutators={{ ...arrayMutators }}
        validate={validate || defaultValidate}
        {...rest}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, form }) => {
          return (
            // @ts-expect-error TS(2339) FIXME: Property 'form' does not exist on type 'ClassNameM... Remove this comment to see the full error message
            <form className={s.form} onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {id && (
                  <>
                    <Grid item md={1}>
                      <ModelField type="text" name="id" readOnly />
                    </Grid>
                    <Grid item md={11} />
                  </>
                )}

                <ModelFields fields={fields} form={form} />
              </Grid>

              {!readOnly && (
                <div className={s.actions}>
                  <Button variant="contained" type="submit" disabled={!onSubmit}>
                    Save
                  </Button>
                  {id && (
                    <Button variant="contained" onClick={() => setDeleting(true)}>
                      Delete
                    </Button>
                  )}
                </div>
              )}
            </form>
          );
        }}
      </Form>

      <Dialog
        open={deleting}
        fullWidth
        maxWidth="sm"
        confirmLabel="Remove"
        onConfirm={handleDelete}
        cancelLabel="Cancel"
        onCancel={() => setDeleting(false)}
      >
        Are you sure? It will remove the {title}.
      </Dialog>
    </div>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ModelForm' because it is a funct... Remove this comment to see the full error message
ModelForm = connect(undefined, {
  notify,
})(ModelForm);

export default ModelForm;
