import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { FC, useCallback } from 'react';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { ExpertRequestType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { TextField } from '@/components/FormAdapters';
import MediaQuery from '@/components/MediaQuery';
import ShareableLink from '@/components/ShareableLink';
import { ExpertRequest, buildRefUrl } from '@/expertrequest';
import { isOpportunityType } from '@/expertrequest/store';
import { RootState } from '@/store';
import { SCREEN_MD } from '@/theme/screens';
import { isEmailValid, queryPart } from '@/utils';

import s from './ReferFriend.module.scss';

const INVITE_EXPERT_TO_EXPERT_REQUEST = gengql(/* GraphQL */ `
  mutation referFriendInviteExpert($expertRequestId: String!, $email: String!) {
    inviteExpertToExpertRequest(expert_request_id: $expertRequestId, email: $email)
  }
`);

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    notify,
  }
);

interface FormData {
  email: string;
}

interface ReferFriendProps {
  expertRequest: ExpertRequest;
  action?: 'refer';
  tags?: string[];
}

const ReferFriend: FC<ReferFriendProps & ConnectedProps<typeof connector>> = ({
  expertRequest,
  notify,
  viewer,
  action,
  tags,
}) => {
  const [inviteExpert] = useMutation(INVITE_EXPERT_TO_EXPERT_REQUEST);

  const validate = useCallback((values: FormData) => {
    const errors: { email?: string } = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmailValid(values.email)) {
      errors.email = 'Invalid email';
    }

    return errors;
  }, []);

  const handleInviteExpert = useCallback(
    async (values: FormData) => {
      const errors: { email?: string } = {};
      try {
        await inviteExpert({
          variables: {
            expertRequestId: expertRequest.slug,
            email: values.email,
          },
        });
        notify('Invite sent!', 'success');
      } catch {
        errors.email = 'An error occurred when adding the email address.';
      }
      return errors;
    },
    [expertRequest.slug, inviteExpert, notify]
  );

  const url = buildRefUrl(expertRequest, 'linkedin', viewer.id);

  const signupQuery = queryPart({ t: tags });
  const objectName = isOpportunityType(expertRequest.er_type as ExpertRequestType)
    ? 'opportunity'
    : 'project';
  const title = `Refer this ${objectName} to a friend`;

  return (
    <MediaQuery maxWidth={SCREEN_MD}>
      {(isMobileVersion: boolean) => (
        <div
          className={cx(s.action, {
            [s.sticky]: isMobileVersion && action === 'refer',
          })}
        >
          <div className={s.actionTitle}>{title}</div>

          <p className={s.actionSubTitle}>Receive a $100 bonus if they do a call</p>

          <div>
            {viewer.id && (
              <FinalForm
                onSubmit={handleInviteExpert}
                validate={validate}
                style={{ display: 'flex', 'align-items': 'flex-end' }}
                render={({
                  form,
                  handleSubmit,
                  submitErrors,
                  submitting,
                  dirtySinceLastSubmit,
                  errors,
                }) => (
                  <form onSubmit={handleSubmit} onReset={() => form.reset()}>
                    <FinalField
                      id="inviteExpertEmail"
                      name="email"
                      component={TextField}
                      changeOnBlur={false}
                      fullWidth={isMobileVersion}
                      helperText={!dirtySinceLastSubmit && (errors?.email || submitErrors?.email)}
                      style={{
                        width: 'calc(100% - 110px)',
                        marginRight: 10,
                        textAlign: 'left',
                      }}
                      placeholder="Enter email address"
                    />
                    <Button type="submit" size="medium" submitting={submitting} className="mt-8">
                      Send
                    </Button>
                  </form>
                )}
              />
            )}
            {viewer.id ? (
              <ShareableLink
                url={url}
                enableShorten={false}
                inputStyle={{ width: 'calc(100% - 111px)' }}
                buttonStyle={{ width: 100 }}
              />
            ) : (
              <Button
                href={`${expertRequest.public_html_url}/refer/signup${signupQuery}`}
                size="small"
                className="mt-8"
              >
                Log in to refer a friend
              </Button>
            )}
          </div>
        </div>
      )}
    </MediaQuery>
  );
};

export default connector(ReferFriend);
