/**
 * Copyright © 2016-present OnFrontiers. All rights reserved.
 */

/**
 * Flux/Redux action types
 * http://redux.js.org/docs/basics/Actions
 *
 * Please, stick to the following convention:
 *
 *     <AREA>__<ACTION>_<TOPIC>
 *
 * For example:
 *
 *     USER_PROFILE__UPLOAD_PICTURE
 *
 * Action verb examples:
 *
 *     ADD    - add to the collection
 *     REMOVE - remove from the collection
 *     SET    - set a new value, replace an existing one if any
 *     TOGGLE - change from true to false and vise versa
 */
export default {
  LOADING__SET_PROGRESS: 'LOADING__SET_PROGRESS',
  //
  // Project
  // ---------------------------------------------------------------------------
  PROJECT__LIST: 'PROJECT__LIST',
  PROJECT__LIST_LOADING: 'PROJECT__LIST_LOADING',
  PROJECT__LIST_LOADED: 'PROJECT__LIST_LOADED',
  PROJECT__LIST_INVALIDATE: 'PROJECT__LIST_INVALIDATE',
  PROJECT__UPDATE: 'PROJECT__UPDATE',
  PROJECT__ADD_MEMBER: 'PROJECT__ADD_MEMBER',
  PROJECT__UPDATE_MEMBER: 'PROJECT__UPDATE_MEMBER',
  PROJECT__REMOVE_MEMBER: 'PROJECT__REMOVE_MEMBER',
  PROJECT__DELETE: 'PROJECT__DELETE',

  //
  // Profile Address
  // ---------------------------------------------------------------------------
  ADDRESS__SET_ADDRESS: 'ADDRESS__SET_ADDRESS',
  ADDRESS__REMOVE_ADDRESS: 'ADDRESS__REMOVE_ADDRESS',

  //
  // Domains
  // ---------------------------------------------------------------------------
  DOMAIN__UPDATE: 'DOMAIN__UPDATE',

  //
  // Groups
  // ---------------------------------------------------------------------------
  GROUP__BATCH_ADD: 'GROUP__BATCH_ADD',
  GROUP__UPDATE: 'GROUP__UPDATE',
  GROUP__REMOVE_MEMBER: 'GROUP__REMOVE_MEMBER',
  GROUP__REMOVE_MEMBER_AWAITING: 'GROUP__REMOVE_MEMBER_AWAITING',
  GROUP__ADD_MEMBER: 'GROUP__ADD_MEMBER',
  GROUP__UPDATE_MEMBER: 'GROUP__UPDATE_MEMBER',
  GROUP__LIST_LOADING: 'GROUP__LIST_LOADING',
  GROUP__LIST_LOADED: 'GROUP__LIST_LOADED',

  //
  // Networks
  // ---------------------------------------------------------------------------
  NETWORK__UPDATE_EXPERT: 'NETWORK__UPDATE_EXPERT',

  //
  // Compliance Training
  // ---------------------------------------------------------------------------
  // ...
  COMPLIANCE_TRAINING__ANSWER_QUESTION: 'COMPLIANCE_TRAINING__ANSWER_QUESTION',
  COMPLIANCE_TRAINING__COMPLETE_STEP: 'COMPLIANCE_TRAINING__COMPLETE_STEP',

  //
  // Billing
  // ---------------------------------------------------------------------------
  BILLING__FETCH_ACCOUNT: 'BILLING__FETCH_ACCOUNT',
  BILLING__FETCH_PRICES: 'BILLING__FETCH_PRICES',

  //
  // Call
  // ---------------------------------------------------------------------------
  CALL__UPDATE: 'CALL__UPDATE',

  //
  // Message Templates
  // ---------------------------------------------------------------------------
  MESSAGE_TEMPLATES__UPDATE: 'MESSAGE_TEMPLATES__UPDATE',
  MESSAGE_TEMPLATES__LOADING: 'MESSAGE_TEMPLATES__LOADING',
  MESSAGE_TEMPLATES__LIST: 'MESSAGE_TEMPLATES__LIST',
  MESSAGE_TEMPLATES__LOADED: 'MESSAGE_TEMPLATES__LOADED',

  //
  // App Notification
  // ---------------------------------------------------------------------------
  APP_NOTIFICATION__LIST: 'APP_NOTIFICATION__LIST',
  APP_NOTIFICATION__REMOVE: 'APP_NOTIFICATION__REMOVE',

  //
  // Landing Page
  // ---------------------------------------------------------------------------
  LANDING_PAGE__UPDATE: 'LANDING_PAGE__UPDATE',
  LANDING_PAGE__REMOVE: 'LANDING_PAGE__REMOVE',
  LANDING_PAGE__BATCH_ADD: 'LANDING_PAGE__BATCH_ADD',

  //
  // Expert Showcase Page
  // ---------------------------------------------------------------------------
  EXPERT_SHOWCASE__UPDATE: 'EXPERT_SHOWCASE__UPDATE',
  EXPERT_SHOWCASE__REMOVE: 'EXPERT_SHOWCASE__REMOVE',
  EXPERT_SHOWCASE__BATCH_ADD: 'EXPERT_SHOWCASE__BATCH_ADD',
  EXPERT_SHOWCASE_PROFILES__UPDATE: 'EXPERT_SHOWCASE_PROFILES__UPDATE',
  EXPERT_SHOWCASE_CATEGORY__UPDATE: 'EXPERT_SHOWCASE_CATEGORY__UPDATE',
  EXPERT_SHOWCASE_SECTION__UPDATE: 'EXPERT_SHOWCASE_SECTION__UPDATE',
  EXPERT_SHOWCASE_CATEGORY__REMOVE: 'EXPERT_SHOWCASE_CATEGORY__REMOVE',
  EXPERT_SHOWCASE_SECTION__REMOVE: 'EXPERT_SHOWCASE_SECTION__REMOVE',

  //
  // Misc
  // ---------------------------------------------------------------------------
  COUNTRIES__FETCH_SUCCESS: 'COUNTRIES__FETCH_SUCCESS',
  COUNTRIES__FETCH_ERROR: 'COUNTRIES__FETCH_ERROR',
  SECTOR__BATCH_ADD: 'SECTOR__BATCH_ADD',
  SECTOR__REMOVE: 'SECTOR__REMOVE',
  LOAD_FROM_LOCAL_STORAGE: 'LOAD_FROM_LOCAL_STORAGE',

  UI__SHOW_MESSAGE: 'UI__SHOW_MESSAGE',
  UI__HIDE_MESSAGE: 'UI__HIDE_MESSAGE',
  UI__SHOW_POPUP: 'UI__SHOW_POPUP',
  UI__HIDE_POPUP: 'UI__HIDE_POPUP',
  UI__SET_USER_CONTEXT: 'UI__SET_USER_CONTEXT',
  UI__SAVE_USER_VIEW: 'UI__SAVE_USER_VIEW',

  URL__SHORTENED: 'URL__SHORTENED',

  LEGAL__FETCH: 'LEGAL__FETCH',

  PERMISSION__SET: 'PERMISSION__SET',

  ACTIVITY__MERGE: 'ACTIVITY__MERGE',

  SEARCH: 'SEARCH',
  SEARCH_SELECT_PROFILE: 'SEARCH_SELECT_PROFILE',
  SEARCH_CLEAR_PROFILE_SELECTION: 'SEARCH_CLEAR_PROFILE_SELECTION',

  SUGGEST: 'SUGGEST',
  SUGGEST_LOADING: 'SUGGEST_LOADING',

  TRACKING_LOG__CREATE: 'TRACKING_LOG__CREATE',

  SITE_SETTINGS__FETCH: 'SITE_SETTINGS__FETCH',
  SITE_SETTINGS__UPDATE: 'SITE_SETTINGS__UPDATE',

  SAVED_SEARCHES__FETCH: 'SAVED_SEARCHES__FETCH',
};
