import { redirect } from 'react-router-dom';

import NotFoundPage from '@/pages/NotFoundPage';

import { LegacyRoute } from '../routesMiddleware';
import Admin from './Admin';

const route: LegacyRoute = {
  path: '/admin/:model?/:id?',

  async action({ store, params }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return <NotFoundPage />;

    const { model, id } = params;

    if (!model) {
      return redirect('/admin/landing_pages');
    }

    document.title = 'Admin';
    return <Admin model={model} id={id} />;
  },
};

export default route;
