import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import AssignmentsManager, {
  AssignmentHasKnowledgeFilter,
  AssignmentTypes,
  DEFAULT_ASSIGNMENT_LIMIT,
  TypeToHasKnowledgeFilter,
} from '@/assignment/AssignmentsManager';
import { SelectKnowledge } from '@/knowledge/addKnowledge';
import {
  CancelablePromise,
  CompleteKnowledge,
  PaginatedAssignmentHasKnowledgeList,
  SearchService,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

const history = createBrowserHistory();

let knowledgeRequest: CancelablePromise<Array<CompleteKnowledge>>;
let experiencesRequest: CancelablePromise<PaginatedAssignmentHasKnowledgeList>;

export const fetchKnowledges = createAsyncThunk(
  'addKnowledge/fetchKnowledge',
  async (query: string) => {
    knowledgeRequest?.cancel();

    knowledgeRequest = SearchService.completeKnowledgeCreate({
      query,
      knowledge_types: ['knowledge_raw'],
      limit: 10,
    });

    return (await knowledgeRequest).map((k) => k as SelectKnowledge);
  }
);

export const fetchExperiences = createAsyncThunk(
  'addKnowledge/fetchExperiences',
  async (
    filters: AssignmentHasKnowledgeFilter & {
      assignmentTypes?: AssignmentTypes;
      name?: string;
    }
  ) => {
    experiencesRequest?.cancel();
    experiencesRequest = AssignmentsManager.fetchAssignmentsHasKnowledgeList({
      ...filters,
      ordering: '-dates',
      limit: DEFAULT_ASSIGNMENT_LIMIT,
      assignmentTypes: filters.assignmentTypes,
      knowledgeId: filters.knowledgeId,
      knowledgeType: TypeToHasKnowledgeFilter[filters.knowledgeType],
      name: filters.name,
      profileId: filters.profileId,
    });
    return experiencesRequest;
  }
);

const addKnowledgeSlice = createSlice({
  name: 'addKnowledge',
  initialState: initialState.addKnowledge,
  reducers: {
    resetData: () => initialState.addKnowledge,
    showDrawer: (state) => {
      history.pushState('', '');
      return {
        ...state,
        opened: true,
      };
    },
    closeDrawer: (state) => ({
      ...state,
      opened: false,
    }),
    selectKnowledge: (state, action: PayloadAction<SelectKnowledge | null>) => ({
      ...state,
      selectedKnowledge: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKnowledges.pending, (state) => ({
      ...state,
      isLookupKnowledgeLoading: true,
      error: null,
    }));
    builder.addCase(fetchKnowledges.fulfilled, (state, action) => ({
      ...state,
      lookupKnowledges: action.payload,
      isLookupKnowledgeLoading: false,
      error: null,
    }));
    builder.addCase(fetchExperiences.pending, (state) => ({
      ...state,
      isLookupExperiencesLoading: true,
      error: null,
    }));
    builder.addCase(fetchExperiences.fulfilled, (state, action) => ({
      ...state,
      lookupExperiences: action.payload,
      isLookupExperiencesLoading: false,
      error: null,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchKnowledges), (state) => ({
      ...state,
      isLookupKnowledgeLoading: false,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchExperiences), (state) => ({
      ...state,
      isLookupExperiencesLoading: false,
    }));
  },
});

export const addKnowledgeSelector = (state: IState) => state.addKnowledge;
export const { showDrawer, closeDrawer, selectKnowledge, resetData } = addKnowledgeSlice.actions;
export default addKnowledgeSlice.reducer;
