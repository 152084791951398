import { TextField } from '@mui/material';
import cx from 'classnames';

import CircularProgress from '@/components/CircularProgress';
import FieldContainer from '@/components/FieldContainer';

import s from './InvitationEmailPreview.module.scss';

function InvitationEmailPreview({ loading, invitationEmail }: any) {
  return (
    <div className={cx({ [s.invitationEmailLoading]: loading })}>
      {loading && (
        <div className={s.invitationEmailProgress}>
          <CircularProgress />
        </div>
      )}

      <div className={s.invitationEmailFields}>
        <FieldContainer className={s.emailField} label="Subject">
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            value={(invitationEmail && invitationEmail.subject) || ''}
          />
        </FieldContainer>
        <FieldContainer className={s.emailField} label="Body">
          <TextField
            InputProps={{ disableUnderline: true }}
            multiline
            fullWidth
            rows={10}
            maxRows={10}
            value={(invitationEmail && invitationEmail.body) || ''}
          />
        </FieldContainer>
      </div>
    </div>
  );
}

export default InvitationEmailPreview;
