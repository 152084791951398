import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { useRef, useState } from 'react';

import { DEFAULT_ASSIGNMENTS_LIMIT } from '@/assignment/queries/assignments';
import { useAssignmentsByKnowledgeQuery } from '@/assignment/queries/assignmentsByKnowledge';
import { Drawer, IDrawerHeaderProps } from '@/componentsv2/Drawer';
import Dropdown from '@/componentsv2/Dropdown';
import Meter from '@/componentsv2/Meter';
import Pagination from '@/componentsv2/Pagination';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';
import { useProfileId, useSelf } from '@/hooks/store';
import ExpandedKnowledgeDisclaimer from '@/knowledge/components/ExpandedKnowledgeDisclaimer';
import KnowledgeLevelLabel from '@/knowledge/components/KnowledgeLevelLabel';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { ProfileKnowledgeScore } from '@/openapi';
import ProfileDeleteKnowledge from '@/profilev2/components/dialogs/ProfileDeleteKnowledge';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { useAppSelector } from '@/store';
import { knowledgeCapitalized } from '@/utils/capitalization';
import { displayWithInDaysOrDate } from '@/utils/date';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

import {
  ContentSkeleton,
  ExperienceListOrdering,
  SORT_OPTIONS,
  getDetailPath,
} from '../ExperienceListDrawer';
import ExperienceRow from '../ExperienceRow';

const ExperiencesByKnowledgeBase = ({
  className,
  knowledge,
  knowledgeName,
  isLocation,
  isLoading,
  redirectedFrom,
}: IDrawerHeaderProps & {
  knowledge?: ProfileKnowledgeScore;
  knowledgeName: string;
  isLocation?: boolean;
  isLoading?: boolean;
  redirectedFrom?: string;
}) => {
  const profileId = useProfileId();
  const isSelf = useSelf();
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<ExperienceListOrdering>('-dates');
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const { accrualIsStale } = useAppSelector(accrualSummarySelector);
  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);

  const {
    data: experiences,
    isLoading: isExperiencesLoading,
    isRefetching: isExperienceRefetching,
    isPreviousData: isExperiencesPreviousData,
  } = useAssignmentsByKnowledgeQuery({
    profileId,
    knowledgeName,
    ordering: sortValue,
    page,
    type: isLocation ? 'location' : 'knowledge',
  });
  const showUpdatingIndicator = isExperienceRefetching || accrualIsStale;
  const limit = DEFAULT_ASSIGNMENTS_LIMIT;

  return (
    <Drawer.Section className={className}>
      <div className="border-b border-grey-400 p-20">
        <div className="flex flex-col gap-8">
          <div className="flex items-start justify-between">
            {isProfileLoading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              <span className="body-1">{profileData?.full_name}&rsquo;s Experience</span>
            )}
            <KnowledgeLevelLabel value={knowledgeScoreToPercent(+(knowledge?.value || 0))} />
          </div>
          {isLoading ? (
            <Skeleton variant="text" width={300} />
          ) : (
            <>
              <h2 className="hd-4">{knowledgeCapitalized(knowledgeName)}</h2>
              {redirectedFrom ? (
                <p className="text-grey-500 body-2">
                  Redirected from {knowledgeCapitalized(redirectedFrom)}
                </p>
              ) : null}
            </>
          )}
          <div className="flex">
            {isLoading || !knowledge ? (
              <Skeleton variant="text" width={500} />
            ) : (
              <Drawer.DetailsList
                details={{
                  'Total Experiences': experiences?.count,
                  [`${pluralize(
                    knowledge.display_time.period,
                    knowledge.display_time.value
                  )} of experience`]: knowledge.display_time.value,
                  'Date last used': displayWithInDaysOrDate(knowledge.latest_date, 7, 'This week'),
                }}
              />
            )}
          </div>
          <Meter
            value={knowledgeScoreToPercent(+(knowledge?.value || 0))}
            className="mt-5"
            animate
          />
        </div>
      </div>
      <div className="p-20">
        <div className="flex h-[30px] items-center justify-between">
          <div>
            {showUpdatingIndicator ? (
              <div className="mr-16 mt-[6px] self-start">
                <UpdatingIndicator text="Updating experiences" />
              </div>
            ) : null}
          </div>
          <Dropdown
            id="experience-knowledge-sort"
            options={SORT_OPTIONS}
            value={sortValue}
            onChange={(newValue) => {
              setSortValue(newValue as ExperienceListOrdering);
              setPage(1);
            }}
          />
        </div>
        <div className="mt-16" ref={scrollableListRef}>
          <div
            className={clsx('flex flex-col gap-10', {
              'animate-pulse opacity-50': isExperiencesPreviousData,
            })}
          >
            {isExperiencesLoading ? (
              <ContentSkeleton count={limit} />
            ) : experiences?.results?.length ? (
              <>
                {experiences.results.map((experience) => {
                  return (
                    <ExperienceRow
                      experience={experience}
                      indicateExpandedKnowledge={experience.is_expanded}
                      key={experience.assignment_id}
                      to={getDetailPath(experience)}
                    />
                  );
                })}
                {experiences?.results?.some((result) => result.is_expanded) ? (
                  <ExpandedKnowledgeDisclaimer
                    knowledge={knowledgeName}
                    className="mt-12"
                    additionalText={
                      isSelf ? (
                        <>
                          You can link <span className="font-medium">{knowledgeName}</span> directly
                          to them by clicking “Add Experiences” for a higher score.
                        </>
                      ) : null
                    }
                  />
                ) : null}
              </>
            ) : null}
          </div>
          {experiences?.count && experiences.count > limit ? (
            <div className="mt-auto">
              <Pagination
                disabled={isExperiencesLoading}
                page={page}
                count={Math.ceil(experiences.count / limit)}
                handlePageChange={(_, newPage) => setPage(newPage)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ProfileDeleteKnowledge knowledge={knowledge} />
    </Drawer.Section>
  );
};

export default ExperiencesByKnowledgeBase;
