import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  PaginatedShortlistCollaboratorList,
  SavedSearchService,
} from '@/openapi';

export const DEFAULT_COLLABORATORS_LIMIT = 10;

type FetchSearchCollaborators = {
  id: string;
  limit?: number;
  page?: number;
};

const fetchSearchCollaborators = ({
  id,
  limit = DEFAULT_COLLABORATORS_LIMIT,
  page = 1,
}: FetchSearchCollaborators): CancelablePromise<PaginatedShortlistCollaboratorList> => {
  const offset = (page - 1) * limit;
  return SavedSearchService.savedSearchCollaboratorsList(id, limit, offset);
};

export const useSearchCollaboratorsQuery = ({ id, limit, page }: FetchSearchCollaborators) => {
  return useQuery({
    queryKey: ['saved-search-collaborators', { id, limit, page }],
    queryFn: () => fetchSearchCollaborators({ id, limit, page }),
    keepPreviousData: true,
    enabled: !!id,
  });
};
