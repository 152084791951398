import makeStyles from '@mui/styles/makeStyles';

import LongText from '@/components/LongText';
import { darkBrown, darkGray } from '@/theme/colors';

const getStyles = makeStyles((theme) => ({
  sectionItem: {
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0,
    },
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  sectionItemTitle: {
    display: 'inline-block',
    margin: '0 15px 0 0',
    fontWeight: 'bold',
    fontSize: 20,
    color: darkBrown,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
  },
  sectionItemSubTitle: {
    fontWeight: 500,
    color: darkGray,
    marginBottom: 5,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
  },
  sectionItemDescription: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '24px',
  },
  sectionItemPeriod: {
    fontSize: 16,
    color: darkGray,
    display: 'inline-block',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      display: 'block',
      marginTop: 5,
    },
  },
}));

export default ({ subTitle, title, description, period, children }: any) => {
  const s = getStyles();

  return (
    <div className={s.sectionItem}>
      <div className={s.sectionItemSubTitle}>{subTitle}</div>
      {(title || period) && (
        <div>
          <h3 className={s.sectionItemTitle}>{title}</h3>
          <div className={s.sectionItemPeriod}>{period}</div>
        </div>
      )}
      {description && <LongText className={s.sectionItemDescription} text={description} />}
      {children}
    </div>
  );
};
