import { useFeatureValue } from '@growthbook/growthbook-react';

import { generateMicrosoftTeamsDeepLink } from '@/utils/msteams';

interface StartConversationButtonProps {
  email: string;
}

export const MicrosoftTeamsLogo = () => (
  <img
    alt="Microsoft teams logo"
    className="h-[23px] w-[23px]"
    src="/assets/microsoft-teams-icon.svg"
  />
);

export default function StartConversationButton({ email }: StartConversationButtonProps) {
  const domainsToStartConversation = useFeatureValue('start-conversation-microsoft-teams', []);

  const isAllowed = domainsToStartConversation.some((domain) =>
    email.toLowerCase().endsWith(domain)
  );

  if (!isAllowed) return null;

  return (
    <div className="inline-flex h-[30px] w-[30px] items-center justify-center rounded-sm bg-microsoft-teams">
      <a
        href={generateMicrosoftTeamsDeepLink(email)}
        target="_blank"
        rel="noreferrer"
        aria-label="Start conversation on Microsoft Teams"
      >
        <MicrosoftTeamsLogo />
      </a>
    </div>
  );
}
