import moment from 'moment-timezone';
import { MessageTemplate, MessageTemplateCollectionState } from 'reducers/messageTemplates';

import { gengql } from '@/__generated__';
import { GraphQLClient } from '@/core/api';
import { AppThunk, RootState } from '@/store';

import ActionTypes from './ActionTypes';

const {
  MESSAGE_TEMPLATES__UPDATE,
  MESSAGE_TEMPLATES__LOADING,
  MESSAGE_TEMPLATES__LIST,
  MESSAGE_TEMPLATES__LOADED,
} = ActionTypes;

export function saveTemplate(template: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { saveMessageTemplate: saved } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionSaveTemplate($id: String, $title: String!, $body: String!) {
          saveMessageTemplate(id: $id, title: $title, body: $body) {
            id
            title
            created_at
            body
          }
        }
      `),
      template
    );

    dispatch({ type: MESSAGE_TEMPLATES__UPDATE, template: saved });
  };
}

export function deleteTemplate(templateId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { deleteMessageTemplate: deleted } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDeleteTemplate($templateId: String!) {
          deleteMessageTemplate(id: $templateId) {
            id
          }
        }
      `),
      { templateId }
    );

    dispatch({
      type: MESSAGE_TEMPLATES__UPDATE,
      templateId: deleted!.id,
      template: null,
    });
  };
}

export function fetchTemplates(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const alreadyLoaded = cachedTemplates(getState());
    if (alreadyLoaded) return alreadyLoaded;

    // set loading
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 0.
    const renderArgs = stringifyRenderArgs();
    dispatch({ type: MESSAGE_TEMPLATES__LOADING, renderArgs });
    try {
      // fetch templates
      const { messageTemplates: templates } = await graphql.query(
        gengql(/* GraphQL */ `
          query actionFetchTemplates {
            messageTemplates {
              id
              title
              created_at
              body
            }
          }
        `)
      );
      dispatch({
        type: MESSAGE_TEMPLATES__LIST,
        renderArgs,
        templates,
      });
    } finally {
      // done loading
      dispatch({ type: MESSAGE_TEMPLATES__LOADED, renderArgs });
    }
  };
}

export function renderTemplateId(
  templateId: string,
  { senderId, requestId, expertId }: { senderId: string; requestId: string; expertId: string }
): AppThunk<Promise<MessageTemplate>> {
  return async (dispatch, _getState, { graphql }) => {
    if ((senderId || requestId || expertId) && (!senderId || !requestId || !expertId)) {
      throw new Error(
        'all sender, request and candidate ids must be ' + 'specified to render templates'
      );
    }

    const renderArgs = stringifyRenderArgs(senderId, requestId, expertId);

    // set loading
    dispatch({ type: MESSAGE_TEMPLATES__LOADING, renderArgs });
    try {
      // render templates
      const { messageTemplate: template } = await graphql.query(
        gengql(/* GraphQL */ `
          query actionFetchTemplate(
            $templateId: String!
            $includeRendered: Boolean!
            $renderingArgs: [RenderingArgsInput!]
          ) {
            messageTemplate(id: $templateId) {
              id
              title
              created_at
              body
              rendered(args: $renderingArgs) @include(if: $includeRendered)
            }
          }
        `),
        {
          templateId,
          includeRendered: !!senderId,
          renderingArgs: [
            { name: 'sender_id', value: senderId },
            { name: 'request_id', value: requestId },
            { name: 'expert_id', value: expertId },
          ],
        }
      );
      dispatch({
        type: MESSAGE_TEMPLATES__UPDATE,
        renderArgs,
        template,
      });
      return template!;
    } finally {
      dispatch({ type: MESSAGE_TEMPLATES__LOADED, renderArgs });
    }
  };
}

export async function renderTemplate2(
  graphql: GraphQLClient,
  body: string,
  { senderId, requestId, profileId }: { senderId: string; requestId: string; profileId: string }
): Promise<string> {
  if (!senderId || !requestId || !profileId) {
    throw new Error(
      'all sender, request and candidate ids must be ' + 'specified to render templates'
    );
  }

  const { renderTemplate: rendered } = await graphql.query(
    gengql(/* GraphQL */ `
      query actionRenderTemplate2($body: String!, $renderingArgs: [RenderingArgsInput!]) {
        renderTemplate(body: $body, args: $renderingArgs)
      }
    `),
    {
      body,
      renderingArgs: senderId
        ? [
            { name: 'sender_id', value: senderId },
            { name: 'request_id', value: requestId },
            { name: 'profile_id', value: profileId },
          ]
        : undefined,
    }
  );
  return rendered!;
}

// TODO: remove
export function renderTemplate(
  body: string,
  { senderId, requestId, profileId }: { senderId: string; requestId: string; profileId: string }
): AppThunk<Promise<string>> {
  return (_dispatch, _getState, { graphql }) => {
    return renderTemplate2(graphql, body, { senderId, requestId, profileId });
  };
}

export function cachedTemplates(
  state: RootState,
  { senderId, requestId, expertId }: any = {}
): MessageTemplateCollectionState | null {
  const renderArgs = stringifyRenderArgs(senderId, requestId, expertId);
  const { messageTemplates } = state;
  if (messageTemplates[renderArgs] && moment().isBefore(messageTemplates[renderArgs].expire)) {
    return messageTemplates[renderArgs];
  }
  return null;
}

function stringifyRenderArgs(senderId: string, requestId: string, expertId: string) {
  if (!senderId || !requestId || !expertId) return '';
  return `${senderId}:${requestId}:${expertId}`;
}
