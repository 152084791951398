import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store';

import ProfileCompleteness from './ProfileCompleteness';

function RedirectCompleteness({ userContext, profile }: any) {
  const navigate = useNavigate();
  const [completenessClosed, setCompletenessClosed] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const isExpert = useMemo(() => userContext === 'expert', [userContext]);

  const redirectDialog = useCallback(
    (dialog: any) => {
      navigate(`/profile/${profile.url_endpoint}?dialog=${dialog}`);
    },
    [navigate, profile.url_endpoint]
  );

  return (
    isExpert &&
    !mobile &&
    !completenessClosed && (
      <ProfileCompleteness
        profileId={profile.id}
        onRequestClose={() => setCompletenessClosed(true)}
        style={{ marginBottom: 20 }}
        nameAction={() => redirectDialog('name')}
        pictureAction={() => redirectDialog('picture')}
        introductionAction={() => redirectDialog('headline')}
        experiencesAction={() => redirectDialog('experiences')}
        keywordsAction={() => redirectDialog('keywords')}
        expertiseAction={() => redirectDialog('expertise')}
        questionsAction={() => redirectDialog('questions')}
        locationAction={() => redirectDialog('location')}
        communicationAction={() => redirectDialog('communication')}
        phoneAction={() => redirectDialog('phones')}
        educationsAction={() => redirectDialog('education')}
        linkedinAction={() => redirectDialog('linkedin')}
        resumeAction={() => redirectDialog('resume')}
      />
    )
  );
}

export default connect((state: RootState) => ({
  userContext: state.ui.userContext,
}))(RedirectCompleteness);
