import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';

import { darkBrown, sand, teal500, white } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import IconButton from '../IconButton';
import IconMenu from '../IconMenu';
import s from './Module.module.scss';

function Module({
  title,
  primary,
  secondary,
  contextActionsElement,
  contextActions = [],
  className,
  children,
  headerStyle,
  bodyStyle,
  ...other
}: any) {
  const backgroundColor = primary ? teal500 : secondary ? sand : darkBrown;
  const color = secondary ? darkBrown : white;

  if (contextActions.length && !title) {
    throw new Error('context actions defined but no title');
  }

  return (
    <div className={cx(s.root, { [className]: !!className })} {...other}>
      {title && (
        <div className={s.header} style={{ backgroundColor, color, ...headerStyle }}>
          <div className={s.headerText}>{title}</div>
          {contextActions.length > 0 && renderContextActions(contextActions)}
          {contextActionsElement}
        </div>
      )}
      <div className={s.body} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
}

function renderContextActions(actions: any) {
  return (
    <IconMenu
      iconElement={
        <IconButton>
          <FAIcon icon="cog" color={white} />
        </IconButton>
      }
    >
      {actions.map((a: any) => (
        <MenuItem key={a.text} onClick={a.onClick} disabled={a.disabled}>
          {a.text}
        </MenuItem>
      ))}
    </IconMenu>
  );
}

export default Module;
