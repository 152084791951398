import PropTypes from 'prop-types';
import React from 'react';

import Body from '../Body';
import LinearProgress from '../LinearProgress';

function Wizard({ showProgress = true, completed, children }: any) {
  return (
    <div>
      {showProgress && <LinearProgress variant="determinate" value={100 * completed} />}
      <Body style={{ paddingTop: 30, paddingBottom: 30 }}>{children}</Body>
    </div>
  );
}

Wizard.propTypes = {
  completed: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Wizard;
