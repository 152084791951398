import { connect } from 'react-redux';

import { hideMessage } from '@/actions/ui';
import { RootState } from '@/store';

import Snackbar from './Snackbar';

function SnackbarNotification({ ui, notification, hideMessage }: any) {
  return (
    <Snackbar
      // @ts-expect-error TS(2769): No overload matches this call.
      open={ui.notifications.length > 0}
      message={notification ? notification.message : ''}
      messageType={notification && notification.messageType}
      autoHideDuration={notification && notification.duration}
      onClose={() => hideMessage()}
    />
  );
}

export default connect(
  (state: RootState) => {
    const notification = state.ui.notifications[0] || state.ui.lastNotification;

    return {
      ui: state.ui,
      notification,
    };
  },
  {
    hideMessage,
  }
)(SnackbarNotification);
