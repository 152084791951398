import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

import { saveProject } from '@/actions/project';
import EditDialog from '@/components/EditDialog';
import MediaQuery from '@/components/MediaQuery';
import { saveExpertRequest } from '@/expertrequest/store';
import { useApp } from '@/hooks/useAppContext';
import { SCREEN_XS } from '@/theme/screens';

function EditExpertRequest(props: any) {
  const {
    formValues,
    onClose,
    onSubmit,
    reset,
    saveExpertRequest,
    saveProject,
    initialValues,
    canEditQueries,
    children,
    component,
    array,
    change,
    submitOnClick,
    ...rest
  } = props;

  const { store } = useApp();

  const handleSubmit = async () => {
    const {
      project_name: projectName,
      group_id: groupId,
      tracking_code: trackingCode,
      ...expertRequestValues
    } = formValues;

    let projectId = formValues.project_id;

    try {
      if (!projectId) {
        const createdProject = await saveProject({
          name: projectName || formValues.name,
          tracking_code: trackingCode,
          group_id: groupId,
        });
        projectId = createdProject.id;
      }

      await saveExpertRequest(
        {
          ...expertRequestValues,
          project_id: projectId,
        },
        {
          fetchProject: initialValues.project_id !== projectId,
          includeQueries: canEditQueries,
        }
      );

      reset();

      onClose('Expert Request updated.', false);

      if (onSubmit) {
        return onSubmit({ store });
      }
    } catch {
      onClose('An error occurred when updating the expert request.', true);
    }
  };

  return (
    <EditDialog
      {...rest}
      onClose={() => props.onClose(undefined, false)}
      onSubmit={props.handleSubmit(handleSubmit)}
      onReset={() => reset()}
      showSubmit={!submitOnClick}
    >
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion: any) =>
          component
            ? React.createElement(component, {
                formValues,
                array,
                change,
                isMobileVersion,
                onClick: submitOnClick ? props.handleSubmit(handleSubmit) : undefined,
              })
            : children
        }
      </MediaQuery>
    </EditDialog>
  );
}

EditExpertRequest.propTypes = {
  children: PropTypes.element,
  component: PropTypes.func,
  reset: PropTypes.func,
  array: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveExpertRequest: PropTypes.func,
  formValues: PropTypes.object,
  submitOnClick: PropTypes.bool,
  onClose: PropTypes.func,
  canEditQueries: PropTypes.bool,
};

export default connect(
  (state, ownProps) => {
    return {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      formValues: getFormValues('editExpertRequest')(state) || {},
      // @ts-expect-error TS(2339) FIXME: Property 'component' does not exist on type '{}'.
      validate: ownProps.component.validate,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      projects: state.projects.names,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      groups: state.groups.all,
    };
  },
  {
    saveExpertRequest,
    saveProject,
  }
)(
  reduxForm({
    form: 'editExpertRequest',
    enableReinitialize: true,
    touchOnChange: true,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof EditExpertRequest' is not... Remove this comment to see the full error message
  })(EditExpertRequest)
);
