export const isTrue = (value: boolean | string = 'false') => {
  if (value === true) {
    return true;
  }
  if (value === null || value === undefined || value === false) {
    return false;
  }
  switch (value.toLowerCase()) {
    case 'true':
    case 'yes':
    case 'y':
    case '1':
      return true;
    default:
      return false;
  }
};
