import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment-timezone';
import React, { ReactNode, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import config from '@/config';
import { OpenAPI } from '@/openapi';

OpenAPI.BASE = config.harnessApiUrl;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: config.growthBookClientKey,
  enableDevMode: true,
  subscribeToChanges: true,
  onFeatureUsage: (featureKey, result) => {
    if (!window.growthbookFeaturesOnUsage) {
      window.growthbookFeaturesOnUsage = {};
    }
    // load feature usage into a global variable to be used in regular js functions
    window.growthbookFeaturesOnUsage[featureKey] = result.value;
    console.log('feature', featureKey, 'has value', result.value);
  },
});

/*

  Examples of how to use GrowthBook:

  - Feature hooks: https://www.npmjs.com/package/@growthbook/growthbook-react#feature-hooks
  - Feature wrapper components: https://www.npmjs.com/package/@growthbook/growthbook-react#feature-wrapper-components

*/

interface AppProps {
  children?: ReactNode;
}

const App = ({ children }: AppProps) => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  // KT: Harness providers are commented out for now.

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {/* <LocationListener> */}
      <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey || ''}>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            {React.Children.only(children)}
            {/* <KnowledgeSummaryDialog /> */}
          </QueryClientProvider>
        </LocalizationProvider>
      </GoogleReCaptchaProvider>
      {/* </LocationListener> */}
    </GrowthBookProvider>
  );
};

export default App;
