import React, { FC, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InjectedFormProps, change, reduxForm } from 'redux-form';

import EditDialog, { EditDialogProps } from '@/components/EditDialog';
import EditIcon from '@/components/EditIcon';
import WizardFooter from '@/components/WizardPage/WizardFooter';
import Name from '@/expertrequest/components/ExpertRequestForm/Name';

import s from './ExpertRequestWizardPage.module.scss';

interface ExpertRequestWizardPageProps {
  expertRequestName?: string;
  title?: string;
  subTitle?: string;
  nextLabel?: string;
  nextUrl?: string;
  children?: React.ReactNode;
  onNext?: () => void;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  editName?: boolean;
}

const connector = connect(undefined, {
  reduxFormChange: change,
});

const ExpertRequestWizardPage: FC<
  ExpertRequestWizardPageProps & ConnectedProps<typeof connector>
> = ({
  expertRequestName,
  title,
  subTitle,
  nextLabel,
  nextUrl,
  children,
  onNext,
  style,
  titleStyle,
  subTitleStyle,
  editName = true,
  reduxFormChange,
}) => {
  const navigate = useNavigate();
  const [editNameOpen, setEditNameOpen] = useState(false);

  if (!onNext && nextUrl)
    onNext = () => {
      navigate(nextUrl);
    };

  return (
    <div style={style}>
      <header className={s.header}>
        {expertRequestName && (
          <div className={s.expertRequestName}>
            <span className={s.nameContainer}>{expertRequestName}</span>
            {editName && (
              <EditIcon
                onClick={() => setEditNameOpen(true)}
                style={{ marginLeft: 10, verticalAlign: 'middle' }}
              />
            )}
          </div>
        )}
        <h2 style={titleStyle} className={s.title}>
          {title}
        </h2>
        <h3 style={subTitleStyle} className={s.subTitle}>
          {subTitle}
        </h3>
      </header>

      {children}

      {onNext && <WizardFooter onNext={onNext} nextLabel={nextLabel} />}

      <EditExpertRequestName
        onSubmit={(name: any) => {
          reduxFormChange('newExpertRequest', 'name', name);
          reduxFormChange('newExpertRequest', 'name_edited', true);
        }}
        //value={expertRequestName}
        open={editNameOpen}
        onClose={() => setEditNameOpen(false)}
        initialValues={{ name: expertRequestName }}
      />
    </div>
  );
};

interface FormData {
  name: string;
}

type EditExpertRequestWizardPageProps = EditDialogProps;

const EditExpertRequestNameBare: FC<
  EditExpertRequestWizardPageProps & InjectedFormProps<FormData, EditExpertRequestWizardPageProps>
> = ({ handleSubmit, reset, onSubmit, onClose, ...other }) => {
  const myHandleSubmit = useCallback(
    (values: FormData) => {
      if (onSubmit) onSubmit(values.name);
      if (onClose) onClose(true);
    },
    [onClose, onSubmit]
  );

  return (
    <EditDialog
      {...other}
      onSubmit={handleSubmit(myHandleSubmit)}
      onReset={reset}
      title="Edit Expert Request Name"
    >
      <Name />
    </EditDialog>
  );
};

const validate = (values: FormData) => {
  const errors = {};

  if (!values.name) {
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
    errors.name = 'Required';
  }

  return errors;
};

const EditExpertRequestName = reduxForm<FormData, EditExpertRequestWizardPageProps>({
  form: 'editExpertRequestName',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(EditExpertRequestNameBare);

export default connector(ExpertRequestWizardPage);
