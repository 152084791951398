import React, { PureComponent } from 'react';

import EditEmails from '@/components/EditEmails/EditEmails';

import s from './Emails.module.scss';

class Emails extends PureComponent {
  render() {
    return (
      <div>
        <div className={s.subTitle}>Let us know how you would like to hear from us.</div>
        <EditEmails
          // @ts-expect-error TS(2322) FIXME: Type '{ userId: any; profileId: any; showSuccessMe... Remove this comment to see the full error message
          userId={this.props.userId}
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
          profileId={this.props.profileId}
          showSuccessMessage
        />
      </div>
    );
  }
}

export default Emails;
