import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import EditExternalConsultation from '@/components/EditExternalConsultation';
import EditIcon from '@/components/EditIcon';
import { fetchExpertRequests } from '@/expertrequest/store';

interface TitleProps {
  consultation: any;
  isViewerExpert: boolean;
  isViewerRequester: boolean;
  requesterName: string;
  expertName: string;
  isWrittenConsultation: boolean;
}

const connector = connect(undefined, {
  fetchExpertRequests,
});

const Title = ({
  consultation,
  isViewerExpert,
  isViewerRequester,
  fetchExpertRequests,
  requesterName,
  expertName,
  isWrittenConsultation,
}: TitleProps & ConnectedProps<typeof connector>) => {
  const { external } = consultation;
  const userName = (isViewerExpert ? requesterName : expertName) || 'unknown';
  const [editConsultation, setEditConsultation] = useState(false);

  const openEditConsultation = () => {
    fetchExpertRequests({ withGroupOnly: false }).then(() => setEditConsultation(true));
  };

  const closeEditConsultation = () => {
    setEditConsultation(false);
  };

  const handleEditSubmit = () => {
    setEditConsultation(false);
  };

  const target = isWrittenConsultation ? 'Consultation' : 'Call';

  return (
    <>
      <span>
        {external || isViewerRequester
          ? `${target} with ${userName}`
          : isViewerExpert
            ? `${userName} requested a ${target.toLowerCase()} with you`
            : `${requesterName} requested a ${target.toLowerCase()} with ${expertName}`}
      </span>
      {external && <EditIcon onClick={openEditConsultation} style={{ marginLeft: 10 }} />}

      {external && (
        <EditExternalConsultation
          open={editConsultation}
          consultation={consultation}
          initialValues={{
            ...consultation,
            expert_request_id: consultation.expert_request && consultation.expert_request.id,
            started_at: new Date(consultation.started_at),
            recording_url: consultation.original_recording_url,
          }}
          onSubmit={handleEditSubmit}
          onClose={closeEditConsultation}
        />
      )}
    </>
  );
};

export default connector(Title);
