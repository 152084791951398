import makeStyles from '@mui/styles/makeStyles';

import EditIcon from '@/components/EditIcon/EditIcon';
import FAIcon from '@/components/Icon/FAIcon';
import { darkGray } from '@/theme/colors';

const getStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  questionsTitle: {
    fontSize: 14,
    color: darkGray,
    marginBottom: 10,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      fontSize: 14,
    },
  },
  questionsQuestion: {
    marginTop: 10,
    fontSize: 20,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  questionsIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  questionsInvite: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 10,
  },
  edit: {
    marginBottom: 5,
  },
}));

export default ({ profile, editable, onEdit }: any) => {
  const s = getStyles();

  const hasQuestions = profile.questions && profile.questions.length > 0;

  if (!hasQuestions && !editable) return null;

  return (
    <div className={s.row}>
      <div>
        {hasQuestions && <div className={s.questionsTitle}>Questions I can answer:</div>}
        {profile.questions &&
          profile.questions.map((q: any) => (
            <div key={q} className={s.questionsQuestion}>
              {q}
            </div>
          ))}
        {!hasQuestions && editable && (
          /* eslint-disable-next-line */
          <a onClick={onEdit} className={s.edit}>
            <FAIcon icon="question" color={darkGray} size={28} className={s.questionsIcon} />
            <div className={s.questionsInvite} style={{ color: darkGray }}>
              Add some questions you can answer
            </div>
          </a>
        )}
      </div>
      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};
