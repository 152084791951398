import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import initialState from '@/store/initialState';
import IState from '@/store/state';

const assignmentToDeleteSlice = createSlice({
  name: 'assignmentToDelete',
  initialState: initialState.assignmentToDelete,
  reducers: {
    resetData: () => initialState.assignmentToDelete,
    updateData: (_, action: PayloadAction<{ id: string; isJob: boolean } | null>) => action.payload,
  },
});

export const assignmentToDeleteSelector = (state: IState) => state.assignmentToDelete;
export const { resetData, updateData } = assignmentToDeleteSlice.actions;
export default assignmentToDeleteSlice.reducer;
