import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { X } from 'react-feather';

import Box from '@/componentsv2/Box';
import Button from '@/componentsv2/Button';

import styles, { DialogClasses } from './styles';

const useStyles = makeStyles(styles);

export interface IDialogSkeletonProps extends DialogProps {
  className?: string;
  onClose?: () => void;
  open: boolean;
  dialogTitle?: string | React.ReactNode | JSX.Element;
  showCloseButton?: boolean;
  'data-test-id'?: string;
}
const DialogSkeleton: React.FC<IDialogSkeletonProps> = (props) => {
  const classes = useStyles();
  const classProps = clsx(props.className, classes.Dialog, DialogClasses.root);
  const testId = props['data-test-id'] || 'of-dialog';

  return (
    <MUIDialog
      className={classProps}
      data-testid={testId}
      open={props.open}
      onClose={props.onClose}
    >
      <Box className={DialogClasses.dialogHeader}>
        {props.dialogTitle ? (
          <DialogTitle>{props.dialogTitle}</DialogTitle>
        ) : (
          <Skeleton variant="text" width={210} height={30} />
        )}
        {props.showCloseButton && (
          <Button
            variant="tertiary"
            size="large"
            startIcon={X}
            srText="Close"
            onClick={props.onClose}
            className={DialogClasses.dialogIconSize}
          />
        )}
      </Box>
      <DialogContent>
        {props.children || (
          <>
            <Skeleton variant="text" width={430} height={20} />
            <Skeleton variant="text" width={330} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Box className={DialogClasses.buttonsContainer}>
              <Skeleton
                variant="rectangular"
                width={160}
                height={38}
                className={DialogClasses.dialogBtn}
              />
            </Box>
          </>
        )}
      </DialogContent>
    </MUIDialog>
  );
};

export default DialogSkeleton;
