import { Member } from '@sendbird/chat/groupChannel';

export function findOtherMember(members: Member[], viewerId: string): Member {
  const others = members.filter((m) => m.userId !== viewerId);
  if (others.length === 1) {
    return others[0];
  } else if (others.length > 1) {
    throw new Error('Multiple other members found');
  }

  throw new Error('No other member found');
}
