import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment-timezone';
import { PureComponent } from 'react';

import { darkBlue } from '@/theme/colors';

import Button from '../Button/Button';

interface InputProps {
  value?: moment.Moment | Date;
}

interface DateTimePickerProps {
  value?: Date;
  input?: InputProps;
  timezone?: string;
}

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

function getValue(props: DateTimePickerProps) {
  const { value, input, timezone } = props;
  if (value) {
    return value;
  }
  if (input && input.value) {
    const momentDate = moment(input.value);
    if (timezone) {
      return new Date(momentDate.tz(timezone).format('YYYY-MM-DDTHH:mm:ss'));
    }
    const viewerOffsetToLocal = moment().utcOffset() - momentDate.utcOffset();
    return momentDate.subtract(viewerOffsetToLocal, 'm').toDate();
  }
}

function getOnChange(props: any) {
  const { onChange, input } = props;
  return onChange || (input && input.onChange);
}

function getError(props: any) {
  const { errorText, meta } = props;
  return errorText || (meta && (meta.touched || meta.modified) && meta.error);
}

function getWarning(props: any) {
  const { warningText, meta, displayWarning } = props;
  return warningText || (displayWarning && meta && meta.data.warning);
}

class DateTimePicker extends PureComponent {
  constructor(props: any) {
    super(props);

    const value = getValue(props);
    this.state = {
      timestamp: value,
      haveDate: !!value,
      haveTime: !!value,
    };
  }

  UNSAFE_componentWillReceiveProps(props: any) {
    const value = getValue(props);
    this.setState({
      timestamp: value,
      haveDate: !!value,
      haveTime: !!value,
    });
  }

  handleOnChangeDate = (e: any) => {
    const date = e?.toDate();
    const onChange = getOnChange(this.props);
    // @ts-expect-error TS(2339): Property 'haveTime' does not exist on type 'Readon... Remove this comment to see the full error message
    const { haveTime } = this.state;
    // @ts-expect-error TS(2339): Property 'minDate' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { minDate, dateOnly } = this.props;

    if (!date) {
      this.handleClear();
      return;
    }

    // Workaround bug that does not validate min date when navigating to the next month
    if (minDate && moment(date).isBefore(minDate)) return;

    // The time selected by the user is on the system timezone, but we make it
    // on the timezone the user specified (e.g the user is on a shared computer
    // with a different system setting).
    // @ts-expect-error TS(2339): Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
    const timezone = this.props.timezone || moment.tz.guess();
    const updatedTimeOnViewerTimezone = moment
      .tz(date, timezone)
      .startOf('day')
      .hour(dateOnly ? 0 : date.getHours())
      .minute(dateOnly ? 0 : date.getMinutes());

    this.setState({
      haveDate: true,
      timestamp: updatedTimeOnViewerTimezone,
    });

    if ((haveTime || dateOnly) && onChange) {
      onChange(updatedTimeOnViewerTimezone);
    }
  };

  handleOnChangeTime = (e: any) => {
    const date = e?.toDate();
    const onChange = getOnChange(this.props);
    // @ts-expect-error TS(2339): Property 'timestamp' does not exist on type 'Reado... Remove this comment to see the full error message
    const { timestamp, haveDate } = this.state;

    // The time selected by the user is on the system timezone, but we make it
    // on the timezone the user specified (e.g the user is on a shared computer
    // with a different system setting).
    // @ts-expect-error TS(2339): Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
    const timezone = this.props.timezone || moment.tz.guess();
    const currentTimeOnViewerTimezone = moment.tz(timestamp || date, timezone);
    const updatedTimeOnViewerTimezone = moment
      .tz(timestamp || undefined, timezone)
      .year(currentTimeOnViewerTimezone.year())
      .month(currentTimeOnViewerTimezone.month())
      .date(currentTimeOnViewerTimezone.date())
      .hour(date.getHours())
      .minute(date.getMinutes())
      .second(0)
      .millisecond(0);

    this.setState({
      haveTime: true,
      timestamp: updatedTimeOnViewerTimezone,
    });

    if (haveDate && onChange) {
      onChange(updatedTimeOnViewerTimezone);
    }
  };

  handleClear = () => {
    const onChange = getOnChange(this.props);
    this.setState({
      haveTime: false,
      haveDate: false,
      timestamp: '',
    });
    if (onChange) onChange(null);
  };

  formatLabel = (e: any, invalid: any, pattern: any) => {
    const date = moment(e);
    return date.isValid() ? date.format(pattern) : (invalid ?? '');
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339): Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label,
      // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
      placeholder,
      // @ts-expect-error TS(2339): Property 'buttonClear' does not exist on type 'Rea... Remove this comment to see the full error message
      buttonClear = true,
      // @ts-expect-error TS(2339): Property 'fullWidth' does not exist on type 'Reado... Remove this comment to see the full error message
      fullWidth = false,
      // @ts-expect-error TS(2339): Property 'clearable' does not exist on type 'Reado... Remove this comment to see the full error message
      clearable,
      // @ts-expect-error TS(2339): Property 'minDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      minDate,
      // @ts-expect-error TS(2339): Property 'maxDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      maxDate,
      // @ts-expect-error TS(2339): Property 'dateOnly' does not exist on type 'Readon... Remove this comment to see the full error message
      dateOnly,
      // @ts-expect-error TS(2339): Property 'required' does not exist on type 'Readon... Remove this comment to see the full error message
      required,
      // @ts-expect-error TS(2339): Property 'labelDateFormat' does not exist on type ... Remove this comment to see the full error message
      labelDateFormat = 'MMM DD YYYY',
    } = this.props;
    // @ts-expect-error TS(2339): Property 'timestamp' does not exist on type 'Reado... Remove this comment to see the full error message
    const { timestamp, haveTime, haveDate } = this.state;

    const errorText = getError(this.props);
    const warningText = getWarning(this.props);

    const style = {
      display: 'flex',
      alignItems: 'center',
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
      ...this.props.style,
    };

    // Hack to display the time selected by the user,
    // not the actual time on the specified timezone.
    // @ts-expect-error TS(2339): Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
    const timezone = this.props.timezone || moment.tz.guess();
    let displayTimestamp;
    if (timestamp) {
      const timeOnViewerTimezone = moment.tz(timestamp, timezone);
      displayTimestamp = timeOnViewerTimezone.toDate();
    }

    const hasError = !!(errorText || warningText);

    const textFieldsProps = {
      fullWidth,
      error: hasError,
      placeholder,
      helperText: hasError ? errorText || warningText : '',
      required,
    };

    const slotProps = {
      textField: textFieldsProps,
      field: { clearable },
      inputAdornment: { position: 'start' },
    };

    return (
      <div style={style}>
        <DatePicker
          name={name}
          value={haveDate ? moment(timestamp) : null}
          label={label}
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          onChange={this.handleOnChangeDate}
          format={labelDateFormat}
          required={required}
          // @ts-expect-error TS(2322): Type '{ textField: { fullWidth: any; error: boolea... Remove this comment to see the full error message
          slotProps={slotProps}
        />
        {!dateOnly && (
          <TimePicker
            name={name}
            value={haveTime ? moment(displayTimestamp) : null}
            label={label}
            onChange={this.handleOnChangeTime}
            minutesStep={5}
            required={required}
            sx={{ marginLeft: 2 }}
            format="h:mm a"
            // @ts-expect-error TS(2322): Type '{ textField: { fullWidth: any; error: boolea... Remove this comment to see the full error message
            slotProps={slotProps}
          />
        )}
        {buttonClear && (
          <Button variant="text" size="tiny" fontColor={darkBlue} onClick={this.handleClear}>
            Clear
          </Button>
        )}
      </div>
    );
  }
}

export default DateTimePicker;
