import { Button as MaterialButton } from '@mui/material';
import cx from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import {
  cachedTemplates,
  deleteTemplate,
  fetchTemplates,
  saveTemplate,
} from '@/actions/messageTemplate';
import { notify } from '@/actions/ui';
import { RootState } from '@/store';

import Button from '../Button/Button';
import CircularProgress from '../CircularProgress';
import Dialog from '../Dialog';
import Form from '../Form';
import { TextField } from '../FormAdapters';
import LongText from '../LongText';
import s from './MessageTemplatesDialog.module.scss';

const templateExample = {
  title: 'example: “Follow Up',
  body: '[field: expert.full_name], thank you very much for signing up!',
};

class MessageTemplateEditDialogBare extends Component {
  handleCancel = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.reset();
    // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onClose();
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'saveTemplate' does not exist on type 'Re... Remove this comment to see the full error message
    this.props.saveTemplate(values);
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.reset();
    // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onClose();
  };

  render() {
    // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onClose, open, template } = this.props;
    // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
    const handleSubmit = this.props.handleSubmit(this.handleSubmit);
    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onClose}
        title={!template ? 'Save New Message' : 'Edit Saved Message'}
        //bodyStyle={{ overflowY: 'scroll' }}
      >
        <Form onSubmit={handleSubmit} onReset={this.handleCancel}>
          <Field
            id="editMessageTemplateTitle"
            component={TextField}
            name="title"
            label="Title"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            placeholder={templateExample.title}
          />
          <Field
            id="editMessageTemplateBody"
            component={TextField}
            name="body"
            label="Message"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            multiline
            placeholder={templateExample.body}
          />
          <div className={s.actions}>
            <MaterialButton onClick={this.handleCancel}>Cancel</MaterialButton>
            <Button color="secondary" onClick={handleSubmit} size="small">
              Save
            </Button>
          </div>
        </Form>
      </Dialog>
    );
  }
}

const editFormselector = formValueSelector('editMessageTemplate');

const MessageTemplateEditDialog = connect(
  // @ts-expect-error TS(2339): Property 'template' does not exist on type '{}'.
  (state: RootState, { template }) => ({
    initialValues: template || {},
    templateBody: editFormselector(state, 'body'), // trigger dialog resize
  }),
  {
    saveTemplate,
  }
)(
  reduxForm({
    form: 'editMessageTemplate',
    validate: (values) => {
      const errors = {};
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      if (!values.title || !values.title.trim()) {
        // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
        errors.title = 'Title required';
      }
      // @ts-expect-error TS(2339): Property 'body' does not exist on type '{}'.
      if (!values.body || !values.body.trim()) {
        // @ts-expect-error TS(2339): Property 'body' does not exist on type '{}'.
        errors.body = 'Message required';
      }
      return errors;
    },
    enableReinitialize: true,
    // @ts-expect-error TS(2345): Argument of type 'typeof MessageTemplateEditDialog... Remove this comment to see the full error message
  })(MessageTemplateEditDialogBare)
);

function Template({ template, onClick, onEdit, onDelete }: any) {
  onClick = onClick || null;
  const { body, title } = template || {
    title: 'Empty Message',
    body: 'Type your message',
  };
  return (
    <div
      className={cx(s.template, onClick && s.templateClickable)}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <div className={s.templateTitle}>{title}</div>
      <div>
        <LongText
          className={cx({ [s.templateEmptyBody]: !template }, s.templateBody)}
          text={body}
        />
      </div>
      {onEdit && onDelete && (
        <div>
          <Button className={s.templateAction} type="submit" size="small" onClick={onEdit}>
            Edit
          </Button>
          <Button className={s.templateAction} type="submit" size="small" onClick={onDelete}>
            Delete
          </Button>
        </div>
      )}
    </div>
  );
}

class MessageTemplatesDialog extends Component {
  state = {
    editingTemplateOpen: false,
    editingTemplate: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // @ts-expect-error TS(2339): Property 'senderId' does not exist on type 'Readon... Remove this comment to see the full error message
    const { senderId, requestId, expertId } = this.props;
    // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (!this.props.open && nextProps.open) {
      this.fetchTemplates({ senderId, requestId, expertId });
    }
  }

  fetchTemplates = async ({ senderId, requestId, expertId }: any) => {
    // @ts-expect-error TS(2339): Property 'fetchTemplates' does not exist on type '... Remove this comment to see the full error message
    const { fetchTemplates, notify } = this.props;
    try {
      await fetchTemplates({ senderId, requestId, expertId });
    } catch (e) {
      notify(`Error loading templates: ${e}`, 'error');
    }
  };

  handleEditTemplate = (template: any) => {
    this.setState({
      editingTemplate: template,
      editingTemplateOpen: true,
    });
  };

  handleDeleteTemplate = (template: any) => {
    // @ts-expect-error TS(2339): Property 'deleteTemplate' does not exist on type '... Remove this comment to see the full error message
    this.props.deleteTemplate(template.id);
  };

  handleClose = () => {
    this.setState({ listEditMode: false });
    // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onClose();
  };

  render() {
    // @ts-expect-error TS(2339): Property 'onSelectTemplate' does not exist on type... Remove this comment to see the full error message
    const { onSelectTemplate, open, templates } = this.props;

    // @ts-expect-error TS(2339): Property 'listEditMode' does not exist on type '{ ... Remove this comment to see the full error message
    const { editingTemplateOpen, editingTemplate, listEditMode } = this.state;

    return (
      <div>
        <Dialog
          maxWidth="md"
          open={open && !editingTemplateOpen}
          title="Choose Message"
          onClose={this.handleClose}
        >
          {!templates || templates.loading ? (
            <CircularProgress />
          ) : !templates.templates || !templates.templates.length ? (
            <div className={s.templatesList}>No templates</div>
          ) : (
            <div className={s.templatesList}>
              <Template onClick={!listEditMode && (() => onSelectTemplate())} />
              {templates.templates.map((template: any) => (
                <Template
                  key={template.id}
                  template={template}
                  onClick={!listEditMode && (() => onSelectTemplate(template))}
                  onEdit={listEditMode && (() => this.handleEditTemplate(template))}
                  onDelete={listEditMode && (() => this.handleDeleteTemplate(template))}
                />
              ))}
            </div>
          )}
          {templates && !templates.loading && (
            <div>
              {!listEditMode && (
                <Button
                  id="log-in-button"
                  type="submit"
                  size="small"
                  onClick={() => this.handleEditTemplate(null)}
                  fullWidth
                >
                  Save New Message
                </Button>
              )}
              {listEditMode ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    size="small"
                    onClick={() => this.setState({ listEditMode: false })}
                  >
                    Done
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <MaterialButton
                    color="secondary"
                    onClick={() => this.setState({ listEditMode: true })}
                  >
                    Edit Saved Messages
                  </MaterialButton>
                  <MaterialButton onClick={this.handleClose}>{'Close'} </MaterialButton>
                </div>
              )}
            </div>
          )}
        </Dialog>
        <MessageTemplateEditDialog
          // @ts-expect-error TS(2769): No overload matches this call.
          open={editingTemplateOpen}
          template={editingTemplate}
          onClose={() => this.setState({ editingTemplateOpen: false })}
        />
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    templates: cachedTemplates(state),
  }),
  {
    deleteTemplate,
    fetchTemplates,
    notify,
  }
)(MessageTemplatesDialog);
