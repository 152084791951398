import { useMutation } from '@apollo/client';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import cx from 'classnames';
import { memo, useState } from 'react';
import { connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { notify } from '@/actions/ui';
import { Money, money, rate } from '@/core/money';
import { RootState } from '@/store';

import Button from '../Button';
import Link from '../Link';
import s from './CreditPurchaseForm.module.scss';

const ENSURE_USER_BILLING_ACCOUNT = gengql(/* GraphQL */ `
  mutation creditPurchaseFormensureUserBillingAccount($userId: String) {
    ensureUserBillingAccount(user_id: $userId) {
      id
    }
  }
`);

const CREATE_CHECKOUT_URL = gengql(/* GraphQL */ `
  mutation creditPurchaseFormCreateCheckoutURL(
    $accountId: String!
    $priceId: String!
    $quantity: Int
    $path: String!
  ) {
    createCheckoutURL(account_id: $accountId, price_id: $priceId, quantity: $quantity, path: $path)
  }
`);

interface RawMoney {
  cents: number;
  currency: string;
}

interface RawPrice {
  id: string;
  credits: RawMoney;
  money: RawMoney;
}

interface Rate {
  baseCurrency: string;
  quote: Money;
  apply: (amount: Money) => Money;
}

interface Price {
  id: string;
  credits: Money;
  rate: Rate;
}

interface Account {
  id: string;
  credit_balance: Money;
  credit_price?: RawPrice;
}

type CreditPurchaseFormProps = {
  prices: Price[];
  account: Account;
  purchaseDonePath: string;
};

export const CreditPurchaseFormPure = memo(
  ({ prices, account, purchaseDonePath }: CreditPurchaseFormProps) => {
    const [{ priceId, customAmount }, selectOption] = useState<{
      priceId: string;
      customAmount?: Money;
    }>({
      priceId: prices[0].id,
      customAmount: undefined,
    });

    const [ensureBillingAccount] = useMutation(ENSURE_USER_BILLING_ACCOUNT);
    const [createCheckoutUrl] = useMutation(CREATE_CHECKOUT_URL);

    const balance = account?.credit_balance || money({ currency: 'OFC', cents: 0 });

    const unitPrice = prices[0];
    const price = prices.find((p) => p.id === priceId) || unitPrice;
    const amount = priceId === 'custom' ? customAmount : price?.credits;

    const nominalCost = amount && unitPrice.rate.apply(amount);
    const actualCost = amount && price.rate.apply(amount);
    const discount = actualCost && nominalCost && actualCost.subtract(nominalCost);
    const discountPercentage = discount && Math.round(discount.times(-1).div(nominalCost) * 100);

    const step = unitPrice.credits.cents / 100;

    const handleSelect = (event: any) => {
      selectOption({ priceId: event.target.value, customAmount });
    };

    const handlePurchaseClick = async () => {
      const quantity = amount && amount.div(price.credits);
      const checkoutPriceId = priceId === 'custom' ? unitPrice.id : priceId;

      let accountId = account.id;
      // First query: Ensure the user has a billing account
      if (!accountId) {
        const { data } = await ensureBillingAccount();
        accountId = data!.ensureUserBillingAccount!.id;
      }

      // Second query: Create the checkout URL using the accountId
      const { data: checkoutData } = await createCheckoutUrl({
        variables: {
          accountId,
          quantity,
          priceId: checkoutPriceId,
          path: purchaseDonePath,
        },
      });

      const url = checkoutData!.createCheckoutURL!;
      window.location.assign(url);
    };

    return (
      <div>
        <div className={s.balance}>
          Your current balance is&nbsp;
          <span
            className={cx({
              [s.balanceWarning]: balance.cents <= 0,
            })}
          >
            {money(balance).formatted()}&nbsp;
            {balance.cents === 100 ? 'credit' : 'credits'}
          </span>
          .
        </div>

        <div className={s.amount}>
          <RadioGroup value={priceId} onChange={handleSelect}>
            {prices.map((p) => (
              <FormControlLabel
                key={p.id}
                value={p.id}
                control={<Radio />}
                style={{ marginBottom: 10 }}
                label={`${p.credits.formatted()} ${
                  p.credits.cents === 100 ? 'Credit' : 'Credits'
                }: ${p.rate.quote.formatted({ omitEmptyCents: true })}/credit`}
              />
            ))}

            <div className="MuiFormControlLabel-root">
              <Radio
                key="custom"
                value="custom"
                // style={{ display: 'inline-block', width: 100 }}
              />
              <TextField
                id="customCredits"
                placeholder="Custom amount"
                value={customAmount === undefined ? '' : customAmount.cents / 100}
                margin="none"
                fullWidth={false}
                type="number"
                inputProps={{
                  min: 1,
                  max: 1000,
                  step,
                  style: { width: '200px' },
                }}
                onClick={() => {
                  selectOption({
                    priceId: 'custom',
                    customAmount,
                  });
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  const s = e.target.value || '';
                  if (s.length > 3 || s.includes('-')) return;
                  const value = Math.max(0, Math.min(1000, parseInt(s)));
                  const credits = Number.isNaN(value) ? undefined : money(value * 100, 'OFC');
                  selectOption({ priceId: 'custom', customAmount: credits });
                }}
              />
            </div>
          </RadioGroup>
        </div>

        <div className={s.order}>
          <div className={s.orderTitle}>Your Order</div>
          <hr />
          <div className={s.orderRow}>
            <div className={s.orderCell}>
              {amount ? amount.formatted() : '0'}&nbsp;
              {amount && amount.cents === 100 ? 'Credit' : 'Credits'}
            </div>
            <div className={s.orderShortCell}>
              {nominalCost ? nominalCost.formatted() : '$0.00'}
            </div>
          </div>
          <div
            className={s.orderRow}
            style={{
              visibility: discountPercentage ? 'visible' : 'hidden',
            }}
          >
            <div className={cx(s.orderCell, s.orderDiscount)}>{discountPercentage}% Discount</div>
            <div className={s.orderShortCell}>{discount && discount.formatted()}</div>
          </div>
          <hr />
          <div className={s.orderRow}>
            <div className={s.orderCell}>Total</div>
            <div className={s.orderShortCell}>{actualCost ? actualCost.formatted() : '$0.00'}</div>
          </div>
        </div>
        <div className={s.footer}>
          <div className={cx(s.footerComponent, s.footerCard)} />
          <Button
            className={s.footerComponent}
            disabled={!actualCost || !amount || amount.cents === 0 || actualCost.cents > 99999999}
            onClick={handlePurchaseClick}
            //primary
            type="submit"
            size="large"
            label="Purchase"
          />
        </div>
        <div className={s.questions}>
          For large purchases, please contact our{' '}
          <Link href="mailto:hello@onfrontiers.com">customer care team</Link>.
        </div>
        {/* For reference: there is PaymentUpdate component in git history */}
      </div>
    );
  }
);
CreditPurchaseFormPure.displayName = 'CreditPurchaseFormPure';

export default connect(
  (state: RootState, props: CreditPurchaseFormProps) => {
    if (!state.billing) throw new Error('billing not found in state');
    const { account } = props;
    const { prices: rawPrices } = state.billing;

    // check and prepare prices
    if (!rawPrices) throw new Error('billing prices not found in state');

    const prices = rawPrices
      .map((rawPrice: any) => {
        const quote =
          account && account.credit_price && money(rawPrice.money).cents
            ? account.credit_price.money
            : money((100 * rawPrice.money.cents) / rawPrice.credits.cents, rawPrice.money.currency);

        return {
          id: rawPrice.id,
          credits: money(rawPrice.credits),
          rate: rate({
            baseCurrency: 'OFC',
            quote: money(quote),
          }),
        };
      })
      .filter((p: any) => p.credits.cents && p.credits.currency === 'OFC')
      .filter((p: any) => p.rate.quote.cents)
      .sort((a: any, b: any) => a.credits.cents - b.credits.cents);

    if (!prices.length) throw new Error('at least one price must be supplied');

    const { viewer } = state;

    return { viewer, account, prices };
  },
  {
    notify,
  }
)(CreditPurchaseFormPure);
