import isEqual from 'lodash.isequal';
import React, { useMemo } from 'react';

import { debounceAsync } from '@/utils';

import { Autocomplete, AutocompleteProps } from '../Select';

const defaultGetOptionValue = (o: any) => ({
  type: o.type,
  value: o.value,
});
const defaultGetOptionLabel = (o: any) => o.label || '';
const defaultIsOptionEqualToValue = (o: any, v: any) =>
  isEqual(defaultGetOptionValue(o), defaultGetOptionValue(v));

export interface SelectWithSuggestionsProps<
  Value,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> extends Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, 'options'> {
  onChange?: (value: any) => void;
  allowRaw: boolean;
  allowSuggestion: boolean;
  suggestions: any;
  loadingSuggestions: boolean;
  renderTags: (value: any, getTagProps: any) => any;
  renderOption: (option: any, { selected }: any) => any;
  suggest: (scope: string, value: string, bufferSize: number, ...args: any[]) => any;
  suggestScope: string;
  suggestBufferSize: number;
  suggestArgs?: any[];
  suggestionsFilter: (suggestion: any) => boolean;
  errorText: string | undefined;
  underlineShow: boolean;
  formatOption: (o: any) => any;
  isValidEntry: (entry: any) => boolean;
  isValidRaw: (raw: string) => boolean;
  rawType: string;
  rawKey: string;
  suggestionType: string;
}

const SelectWithSuggestions = <
  Value,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  onChange,
  allowRaw,
  value: initialValue,
  allowSuggestion,
  suggestions,
  loadingSuggestions,
  multiple = false as Multiple,
  disabled,
  renderTags,
  renderOption,
  suggest,
  suggestScope,
  suggestBufferSize = 10,
  suggestArgs,
  suggestionsFilter = () => true,
  errorText = undefined,
  underlineShow = true,
  formatOption = (o: any) => o,
  isValidEntry = () => true,
  isValidRaw = () => true,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  rawType = 'raw',
  rawKey = 'text',
  suggestionType = 'suggestion',
  ...rest
}: SelectWithSuggestionsProps<Value, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  /*
   * SelectWithSuggestions is a hybrid select component that allows
   * raw text input as well as selection from a list of autopopulated
   * suggestions.
   *
   * To differentiate between raw input and a suggestion the resulting value
   * is wrapped with the following structure:
   * {
   *   type: 'type',
   *   value: {}
   * }
   *
   * Where type is either the "rawType" or "suggestionType" string provided by
   * these properties.
   *
   * Likewise the value object is either the suggestion item in full or a
   * single key object with the given "rawKey" property. This allows for inputs
   * that take the same form as the suggestion object, i.e. an email or id key
   * that can be used to uniquely identify.
   */

  const [inputValue, setInputValue] = React.useState('');
  const [suggestionText, setSuggestionText] = React.useState('');

  const currentValue = initialValue ? (multiple ? initialValue : [initialValue]) : [];

  // Populate redux state and suggestions prop
  const suggestAction = useMemo(
    () =>
      debounceAsync(async (value: any) => {
        await suggest(suggestScope, value, suggestBufferSize, ...(suggestArgs || []));
        setSuggestionText(value);
      }, 500),
    [suggest, suggestScope, suggestBufferSize, suggestArgs]
  );

  type Option = string | { type: string; value: object };

  const handleChange = async (value: Option | Option[]) => {
    // value array contains entries that are either the text entered on the
    // Autocomplete component or an object if selected from the dropdown

    if (!onChange) {
      return;
    }

    // No checks to perform on removal
    // @ts-expect-error complaining about not being an array
    if (multiple && value.length < currentValue.length) {
      return onChange(value);
    }

    let valid;
    if (multiple && Array.isArray(value)) {
      // Check newest entry in value array is valid
      valid = isValidEntry(value[value.length - 1]);
    } else {
      valid = isValidEntry(value);
    }

    if (!valid) {
      return;
    }

    await onChange(value);
    setInputValue('');
  };

  const handleInputChange = (_: any, value: any, reason: any) => {
    if (reason === 'input') {
      setInputValue(value);
      if (allowSuggestion) {
        suggestAction(value);
      }
    }
  };

  // don't wipe the chips on each suggestion change by merging with existing
  // values
  // @ts-expect-error complaining about not being an array
  const options = [...currentValue];
  const results = allowSuggestion
    ? [
        ...(suggestions[suggestionText] || []).map((v: any) => ({
          type: suggestionType,
          value: v,
        })),
      ].filter(suggestionsFilter)
    : [];

  // de-dupe
  for (let i = 0; i < results.length; ++i) {
    if (options.find((s) => isOptionEqualToValue(s, results[i]))) {
      continue;
    }
    options.push(results[i]);
  }

  // allow raw input
  const rawOption = { type: rawType, value: { [rawKey]: inputValue } };
  if (
    allowRaw &&
    isValidRaw(inputValue) &&
    suggestionsFilter(rawOption) &&
    // @ts-expect-error Argument of type
    !options.find((s) => isOptionEqualToValue(s, rawOption))
  )
    options.unshift(rawOption);

  return (
    <Autocomplete
      {...rest}
      rawValueOnChange
      fullWidth
      multiple={multiple}
      filterOptions={(x: any) => x}
      filterSelectedOptions
      options={options.map(formatOption)}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      // @ts-expect-error complaining about not being an array
      disabled={disabled || !!(!multiple && currentValue.length > 0)}
      renderOption={renderOption}
      renderTags={renderTags}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      // @ts-expect-error complaining because we turned it into an array
      value={currentValue}
      loading={loadingSuggestions}
      TextFieldProps={{
        InputProps: { disableUnderline: !underlineShow },
        inputProps: { maxLength: 254 },
        // @ts-expect-error complaining about not being an array
        disabled: !multiple && currentValue.length > 0,
        error: !!errorText,
        helperText: errorText || '',
      }}
    />
  );
};

export default SelectWithSuggestions;
