import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import type { ButtonHTMLAttributes, FC, PropsWithChildren, Ref } from 'react';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import type { XOR } from '@/utils/types';

type BaseProps = {
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit';
  startIcon?: FC<{ className?: string }>;
  startIconClassName?: string;
  endIcon?: FC<{ className?: string }>;
  endIconClassName?: string;
  srText?: string;
  loading?: boolean;
  loadingClassName?: string;
  'data-testid'?: string;
  omitPaddingClassnames?: boolean;
};

type ButtonElementProps = PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & BaseProps;

type LinkElementProps = LinkProps & BaseProps;

export type ButtonBaseProps = XOR<ButtonElementProps, LinkElementProps>;

export const testId = 'of-button';

const ButtonBase = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonBaseProps>(
  (
    {
      className,
      children,
      size = 'medium',
      type = 'button',
      startIcon: StartIcon,
      startIconClassName,
      endIcon: EndIcon,
      endIconClassName,
      srText,
      to,
      loading,
      loadingClassName,
      omitPaddingClassnames,
      ...props
    },
    ref
  ) => {
    const iconClassName = clsx({
      'w-16 h-16': size === 'small' || size === 'medium',
      'w-20 h-20': size === 'large',
    });

    const paddingClassNames = omitPaddingClassnames
      ? ''
      : clsx({
          'py-6 px-8': size === 'small' && children,
          'px-12 py-8': size === 'medium' && children,
          'p-12': size === 'large' && children,
          'p-6': size === 'small' && !children,
          'p-8': size === 'medium' && !children,
          'p-10': size === 'large' && !children,
        });

    const allClassNames = clsx(
      className,
      'rounded-sm whitespace-nowrap',
      'disabled:cursor-not-allowed',
      paddingClassNames,
      {
        'relative !text-transparent': !!loading,
        'text-14 font-bold leading-[20px]': size === 'small',
        'text-16 font-bold leading-normal': size === 'medium',
        'text-18 font-bold leading-normal': size === 'large',
        'flex items-center gap-8': !!StartIcon || !!EndIcon,
      }
    );

    const isButton = !to;

    const Content = () => (
      <>
        {StartIcon ? (
          <StartIcon className={clsx(iconClassName, startIconClassName)} aria-hidden="true" />
        ) : null}
        {children}
        {srText && !children ? <span className="sr-only">{srText}</span> : null}
        {EndIcon ? (
          <EndIcon
            className={clsx(iconClassName, endIconClassName, 'ml-auto')}
            aria-hidden="true"
          />
        ) : null}
        {loading ? (
          <span className={clsx(loadingClassName, 'absolute inset-0 grid place-content-center')}>
            <CircularProgress color="inherit" size={12} thickness={5} />
          </span>
        ) : null}
      </>
    );

    return isButton ? (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={allClassNames}
        type={type}
        data-testid={testId}
        {...(props as ButtonElementProps)}
      >
        <Content />
      </button>
    ) : (
      <Link
        ref={ref as Ref<HTMLAnchorElement>}
        className={clsx(allClassNames, 'inline-block')}
        to={to}
        data-testid={testId}
        {...(props as Omit<LinkElementProps, 'to'>)}
      >
        <Content />
      </Link>
    );
  }
);

ButtonBase.displayName = 'Button';

const PrimaryButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonBaseProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <ButtonBase
        ref={ref}
        className={clsx(
          className,
          'bg-brand-primary text-white',
          'hover:bg-brand-secondary',
          'disabled:bg-disabled-primary/50 disabled:text-disabled-secondary/50'
        )}
        loadingClassName="text-white"
        {...props}
      >
        {children}
      </ButtonBase>
    );
  }
);

PrimaryButton.displayName = 'PrimaryButton';

const SecondaryButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonBaseProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <ButtonBase
        ref={ref}
        className={clsx(
          className,
          'bg-gray-100 text-dark-secondary',
          '[&:not(:disabled):hover]:bg-gray-200',
          'disabled:text-gray-300'
        )}
        loadingClassName="text-dark-secondary"
        {...props}
      >
        {children}
      </ButtonBase>
    );
  }
);

SecondaryButton.displayName = 'SecondaryButton';

const DestructiveButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonBaseProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <ButtonBase
        ref={ref}
        className={clsx(
          className,
          'bg-destructive-primary text-white',
          '[&:not(:disabled):hover]:bg-destructive-secondary',
          'disabled:bg-red-200 disabled:text-destructive-primary'
        )}
        loadingClassName="text-white"
        {...props}
      >
        {children}
      </ButtonBase>
    );
  }
);

DestructiveButton.displayName = 'DestructiveButton';

const TertiaryButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonBaseProps & { invert?: boolean }
>(({ className, children, size = 'medium', invert, ...props }, ref) => {
  const paddingClassNames = clsx({
    'px-8': size === 'small' && children,
    'px-12': (size === 'medium' || size === 'large') && children,
    'p-6': size === 'small' && !children,
    'p-8': size === 'medium' && !children,
    'p-10': size === 'large' && !children,
  });
  const colorClassNames = invert
    ? clsx('text-white', '[&:not(:disabled):hover]:text-gray-200', 'disabled:text-gray-300')
    : clsx(
        'text-brand-tertiary',
        '[&:not(:disabled):hover]:text-teal-700',
        'disabled:text-disabled-primary'
      );
  return (
    <ButtonBase
      ref={ref}
      className={clsx(className, paddingClassNames, colorClassNames)}
      loadingClassName="text-brand-tertiary"
      omitPaddingClassnames
      size={size}
      {...props}
    >
      {children}
    </ButtonBase>
  );
});

TertiaryButton.displayName = 'TertiaryButton';

export type ConditionalButtonProps =
  | {
      variant?: 'tertiary';
      invert?: boolean;
    }
  | {
      variant?: 'primary' | 'secondary' | 'destructive';
      invert?: never;
    };

export type ButtonProps = ButtonBaseProps & ConditionalButtonProps;

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ variant = 'primary', ...props }, ref) => {
    switch (variant) {
      case 'primary':
        return <PrimaryButton ref={ref} {...props} />;
      case 'secondary':
        return <SecondaryButton ref={ref} {...props} />;
      case 'tertiary':
        return <TertiaryButton ref={ref} {...props} />;
      case 'destructive':
        return <DestructiveButton ref={ref} {...props} />;
    }
  }
);

Button.displayName = 'Button';

export default Button;
