import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { FC, memo } from 'react';

import { darkBlue, primary } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link';

function initials(user: any) {
  if (!user) return;

  const { first_name: firstName, last_name: lastName, name } = user;

  let initials = '';

  if (firstName) {
    initials += firstName[0];
  }

  if (lastName) {
    initials += lastName[0];
  }

  if (!initials && name) {
    initials = name[0];
  }

  return initials.toUpperCase();
}

const useStyles = makeStyles(() => ({
  root: {
    borderColor: primary,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
}));

interface PictureProps {
  src?: string;
  href?: string;
  /** The user object. If empty the user will be rendered as anonymous. */
  user?: any;
  size?: number;
  link?: boolean;
  ring?: boolean;
  openInNewTab?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
}

const Picture: FC<PictureProps> = memo(
  ({
    user,
    src,
    href,
    size = 50,
    link = true,
    ring = false,
    openInNewTab = false,
    children,
    className,
    style,
    ...other
  }) => {
    const { picture_url: pictureUrl, html_url: htmlUrl } = user || {};
    src = src || pictureUrl;
    href = href || htmlUrl;
    const shouldRenderLink = href && link;

    const styles = useStyles();
    const avatarClassName = cx(styles.root, {
      ...(className && { [className]: !shouldRenderLink }),
    });
    const customStyle = {
      fontSize: size / 2,
      width: size,
      height: size,
      borderWidth: ring ? size * 0.05 : 0,
      backgroundColor: user ? undefined : darkBlue,
    };

    let avatar = (
      <Avatar className={avatarClassName} src={src} style={{ ...customStyle, ...style }} {...other}>
        {children || initials(user) || (
          <FAIcon iconSet="fal" icon="user" fixedWidth style={{ margin: 0 }} />
        )}
      </Avatar>
    );

    if (shouldRenderLink) {
      avatar = (
        <Link className={className} to={href} newTab={openInNewTab}>
          {avatar}
        </Link>
      );
    }

    return avatar;
  }
);
Picture.displayName = 'Picture';

// Needed for some material UI logic
// @ts-ignore
Picture.muiName = 'Avatar';

export default Picture;
