import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import styles, { tooltipClasses } from './styles';

interface TooltipProps extends MuiTooltipProps {
  className?: string;
  variant?: 'dark';
  textAlign?: 'left' | 'center' | 'right';
}

interface IPopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

const Tooltip = styled(({ className, textAlign = 'center', variant, ...props }: TooltipProps) => {
  return (
    <MuiTooltip
      {...props}
      classes={{
        popper: clsx(className, tooltipClasses[`text-align-${textAlign}`], {
          [tooltipClasses.dark]: variant === 'dark',
        }),
      }}
      className={className}
      title={props.title}
      data-testid="of-tooltip"
      PopperProps={{ 'data-testid': 'of-tooltip-popover' } as IPopoverProps}
    >
      {props.children}
    </MuiTooltip>
  );
})(styles);

export default Tooltip;
