import { useQuery } from '@tanstack/react-query';

import AssignmentsManager, { AssignmentsOrdering } from '@/assignment/AssignmentsManager';
import { CancelablePromise, PaginatedAssignmentsList } from '@/openapi';

export const DEFAULT_ASSIGNMENTS_LIMIT = 5;

type FetchJobAssignments = {
  profileId: string;
  page?: number;
  limit?: number;
  ordering?: AssignmentsOrdering;
};

export type AssignmentsUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchJobAssignments = ({
  profileId,
  page = 1,
  limit = DEFAULT_ASSIGNMENTS_LIMIT,
  ordering,
}: FetchJobAssignments): CancelablePromise<PaginatedAssignmentsList> => {
  const offset = (page - 1) * limit;
  return AssignmentsManager.findByJobs({
    profileId,
    limit,
    offset,
    ordering,
  });
};

export const useJobAssignmentsQuery = (
  { profileId, page, limit, ordering }: FetchJobAssignments,
  options?: AssignmentsUseQueryOptions
) => {
  return useQuery({
    queryKey: ['assignments', { profileId, page, limit, ordering }],
    queryFn: () => fetchJobAssignments({ profileId, page, limit, ordering }),
    keepPreviousData: true,
    ...options,
  });
};
