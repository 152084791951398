import { CompleteKnowledgeType } from '@/knowledge/addKnowledge';
import {
  AssignmentHasKnowledgeService,
  AssignmentsService,
  PaginatedAssignmentHasKnowledgeList,
} from '@/openapi';
import { CancelablePromise } from '@/openapi/core/CancelablePromise';
import { PaginatedAssignmentsList } from '@/openapi/models/PaginatedAssignmentsList';

export type AssignmentsOrdering =
  | ''
  | '-assignment_country'
  | '-dates'
  | '-title'
  | '-title_raw_term_name'
  | 'assignment_country'
  | 'dates'
  | 'title'
  | 'title_raw_term_name';

interface BaseProps {
  profileId: string;
  colleagueId?: string;
  limit?: number;
  offset?: number;
  ordering?: AssignmentsOrdering;
}

export const DEFAULT_ASSIGNMENT_LIMIT = 10;

export const defaultProps: BaseProps = {
  profileId: '',
  limit: DEFAULT_ASSIGNMENT_LIMIT,
  offset: 0,
  ordering: '-dates',
};

export type AssignmentTypes = '' | 'Job' | 'Project' | 'Proposal' | 'Project|Proposal';

export type AssignmentsFilter = BaseProps;

export type AssignmentHasKnowledgeFilter = BaseProps & {
  knowledgeId: string;
  knowledgeType: CompleteKnowledgeType;
};

export type HasKnowledgeType = 'country' | 'knowledge_raw';

export const TypeToHasKnowledgeFilter = {
  country: 'country' as HasKnowledgeType,
  knowledge_raw: 'knowledge_raw' as HasKnowledgeType,
};

export default class AssignmentsManager {
  public static fetchAssignmentsList = (data: {
    assignmentTypes?: AssignmentTypes;
    colleagueId?: string;
    jobId?: string;
    limit?: number;
    name?: string;
    offset?: number;
    ordering?: AssignmentsOrdering;
    profileId?: string;
  }): CancelablePromise<PaginatedAssignmentsList> =>
    AssignmentsService.assignmentsList(
      data.assignmentTypes,
      data.colleagueId,
      data.jobId,
      data.limit,
      data.name,
      data.offset,
      data.ordering,
      data.profileId
    );

  public static fetchAssignmentsHasKnowledgeList = (data: {
    knowledgeId: string;
    knowledgeType: HasKnowledgeType;
    assignmentTypes?: AssignmentTypes;
    colleagueId?: string;
    countries?: string;
    limit?: number;
    name?: string;
    offset?: number;
    ordering?: AssignmentsOrdering;
    profileId: string;
  }): CancelablePromise<PaginatedAssignmentHasKnowledgeList> =>
    AssignmentHasKnowledgeService.assignmentsHasKnowledgeList(
      data.knowledgeId,
      data.knowledgeType,
      data.profileId,
      data.assignmentTypes,
      data.limit,
      data.name,
      data.offset,
      data.ordering
    );

  public static findByJobs(_props: BaseProps): CancelablePromise<PaginatedAssignmentsList> {
    const props: BaseProps = { ...defaultProps, ..._props };

    return this.fetchAssignmentsList({ ...props, assignmentTypes: 'Job' });
  }

  public static findByMutualExperiences(
    _props: BaseProps
  ): CancelablePromise<PaginatedAssignmentsList> {
    const props: BaseProps = { ...defaultProps, ..._props };

    return this.fetchAssignmentsList({
      ...props,
      colleagueId: props.colleagueId,
      assignmentTypes: 'Project|Proposal',
    });
  }

  public static findByProjects(_props: BaseProps): CancelablePromise<PaginatedAssignmentsList> {
    const props: BaseProps = { ...defaultProps, ..._props };

    return this.fetchAssignmentsList({
      ...props,
      assignmentTypes: 'Project',
    });
  }

  public static findByProposals(_props: BaseProps): CancelablePromise<PaginatedAssignmentsList> {
    const props: BaseProps = { ...defaultProps, ..._props };

    return this.fetchAssignmentsList({
      ...props,
      assignmentTypes: 'Proposal',
    });
  }

  public static findByJobId(_props: {
    jobId: string;
    assignmentTypes: AssignmentTypes;
    offset?: number;
    limit?: number;
  }): CancelablePromise<PaginatedAssignmentsList> {
    const props: BaseProps = { ...defaultProps, ..._props };
    return this.fetchAssignmentsList({
      ...props,
      assignmentTypes: _props.assignmentTypes,
      jobId: _props.jobId,
      offset: _props.offset,
      limit: _props.limit,
    });
  }
}
