import { Button as MaterialButton } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, SubmissionError, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import { requestPasswordReset } from '@/auth/store';
import Form from '@/components/Form';
import { TextField } from '@/components/FormAdapters';
import Link from '@/components/Link';
import WizardPage from '@/components/WizardPage';
import WizardFooter from '@/components/WizardPage/WizardFooter';

function validate(values: any) {
  const errors = {};

  if (!values.email) {
    // @ts-expect-error TS(2339): Property 'email' does not exist on type '{}'.
    errors.email = 'Required';
  }

  return errors;
}

const errDoesNotExist = 'user does not exist';

class RequestPasswordReset extends PureComponent {
  state = {
    done: false,
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'requestPasswordReset' does not exist on ... Remove this comment to see the full error message
    const { requestPasswordReset, notify } = this.props;
    return requestPasswordReset(values.email)
      .then(() => this.setState({ done: true }))
      .catch((err: any) => {
        if (err && err.rawError && err.rawError[0] && err.rawError[0].message === errDoesNotExist) {
          throw new SubmissionError({
            email: 'There is no user with this email address',
          });
        } else {
          notify('An error occurred when trying to reset password.', 'error');
        }
      });
  };

  render() {
    // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
    const { handleSubmit } = this.props;
    const { done } = this.state;

    if (done) {
      return (
        <div>
          <h3>Password reset email sent! You should receive it within a few minutes.</h3>
          <Link to="/login">
            <MaterialButton>← Return to Login</MaterialButton>
          </Link>
        </div>
      );
    }

    return (
      <WizardPage
        title="Reset your password"
        subTitle="We'll email you a link to create a new password."
      >
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            name="email"
            component={TextField}
            autoComplete="email"
            label="Email address"
            type="email"
            style={{ width: 300 }}
          />
          <WizardFooter nextLabel="Reset Password" />
        </Form>
      </WizardPage>
    );
  }
}

export default connect(undefined, {
  requestPasswordReset,
  notify,
})(
  reduxForm({
    form: 'requestPasswordReset',
    validate,
    // @ts-expect-error TS(2345): Argument of type 'typeof RequestPasswordReset' is ... Remove this comment to see the full error message
  })(RequestPasswordReset)
);
