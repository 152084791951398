import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { setLoadingProgress } from '@/actions/loading';
import { useApp } from '@/hooks/useAppContext';
import { darkBrown, darkGray } from '@/theme/colors';
import { rewriteUrl } from '@/utils';

const urlToPathname = (url: string) => {
  try {
    const urlObj = new URL(url, window.location.origin);
    return urlObj.pathname + urlObj.search;
  } catch (e: unknown) {
    console.warn(e, url);
  }
  return null;
};

type LinkProps = {
  // internal navigation
  to?: string;
  // external navigation
  href?: string;
  force?: any;
  newTab?: boolean;
  target?: string;
  children?: any;
  onClick?: any;
  menu?: boolean;
  disabled?: boolean;
  [key: string]: any;
};

const Link = ({
  to: initialTo,
  href,
  children,
  onClick,
  menu,
  disabled,
  newTab,
  ...other
}: LinkProps) => {
  const { store } = useApp();

  if (newTab && !href && !initialTo) {
    throw new Error('Link component with newTab requires "to" or "href" prop');
  }

  const handleNavigationClick = useCallback(
    (e: any) => {
      store.dispatch(setLoadingProgress(10));
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, store]
  );

  let to = initialTo || href;
  if (to) {
    to = rewriteUrl(to);
  }

  const linkStyle = menu ? { color: darkBrown } : { cursor: 'pointer' };
  const disabledStyle = { color: darkGray, cursor: 'not-allowed' };
  if (disabled) {
    return (
      <span style={disabledStyle} {...other}>
        {children}
      </span>
    );
  }

  if (href || newTab) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        className="hover:underline"
        style={linkStyle}
        onClick={onClick}
        {...other}
      >
        {children}
      </a>
    );
  } else if (to) {
    return (
      <RouterLink
        to={urlToPathname(to) || to}
        onClick={handleNavigationClick}
        style={linkStyle}
        {...other}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <span style={linkStyle} onClick={onClick} {...other}>
      {children}
    </span>
  );
};

export default Link;
