import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { borderColor, teal500 } from '@/theme/colors';

const getStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: borderColor,
    margin: '20px 0',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
  root: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

const getIconStyle = makeStyles(() => ({
  root: {
    fontSize: 36,
    color: borderColor,
    '&$active': {
      color: teal500,
    },
    '&$completed': {
      color: teal500,
      cursor: 'pointer',
    },
  },
  active: {},
  completed: {},
}));

const getConnectorStyle = makeStyles(() => ({
  root: {
    top: 19,
    padding: '0 6px',
  },
}));

const getLabelStyle = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 10,
    },
  },
  completed: {
    cursor: 'pointer',
  },
}));

function Steps({ current = 0, steps, onStepClick }: any) {
  const s = getStyles();
  const iconStyle = getIconStyle();
  const connectorStyle = getConnectorStyle();
  const labelStyle = getLabelStyle();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const onClick = useCallback(
    (step: any, index: any) => {
      if (index >= current) return;
      onStepClick(step);
    },
    [current, onStepClick]
  );

  const isLastStep = useMemo(() => current === steps.length - 1, [current, steps]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12} xs={12}>
        <Stepper
          activeStep={current}
          alternativeLabel
          className={s.root}
          connector={<StepConnector classes={connectorStyle} />}
        >
          {steps.map((step: any, index: any) => (
            <Step key={step.title} onClick={() => onClick(step, index)}>
              <StepLabel classes={labelStyle} StepIconProps={{ classes: iconStyle }}>
                {(!mobile || current === index) && step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {mobile && !isLastStep && <Divider className={s.divider} />}
      </Grid>
    </Grid>
  );
}

Steps.propTypes = {
  current: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Steps;
