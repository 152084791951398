import moment from 'moment-timezone';

import ActionTypes from '@/actions/ActionTypes';

const { DOMAIN__UPDATE } = ActionTypes;

export default function domainsReducer(state = { savedSearches: [] }, action: any) {
  switch (action.type) {
    case DOMAIN__UPDATE:
      const { subdomain, domain } = action;
      return {
        ...state,
        [subdomain]: {
          // domain data not likely to change often
          expiresAt: moment().add(20, 'minutes').toISOString(),
          node: domain, // null domain may be cached as well
        },
      };
    default:
      return state;
  }
}
