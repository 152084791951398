import SearchOutlined from '@mui/icons-material/SearchOutlined';
import clsx from 'clsx';
import { useState } from 'react';

import { Input } from '@/componentsv2';

export type SearchInputProps = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, inputValue: string) => void;
  initialInputValue: string;
};

const SearchInput = ({ handleSubmit, initialInputValue }: SearchInputProps) => {
  const [inputValue, setInputValue] = useState(initialInputValue);

  return (
    <form onSubmit={(e) => handleSubmit(e, inputValue)} className="p-12">
      <label htmlFor="query" className="mb-4 block subtitle-1">
        Keyword Search
      </label>
      <Input
        InputProps={{
          inputProps: {
            className: 'peer',
          },
          autoComplete: 'off',
          sx: {
            paddingRight: '4px',
          },
          endAdornment: (
            <button
              className={clsx(
                'my-4 grid place-content-center self-stretch rounded-sm px-[6px] text-grey-500',
                'focus:bg-primary-main focus:text-white',
                'peer-focus:bg-primary-main peer-focus:text-white'
              )}
            >
              <span className="sr-only">Search</span>
              <SearchOutlined color="inherit" sx={{ fontSize: 16 }} />
            </button>
          ),
        }}
        id="query"
        placeholder="Search by name or keyword"
        className="w-full"
        size="small"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </form>
  );
};

export default SearchInput;
