import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Box, Text } from '@/componentsv2';

import styles, { heroClasses } from './styles';
import { IHeroProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-hero';

const Hero = ({ className, title, tagline, action: Action }: IHeroProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.hero, heroClasses.root);

  return (
    <Box className={classProps} data-testid={testId}>
      <Container>
        <Text tag="h1" className={heroClasses.title}>
          {title}
        </Text>
        {tagline && (
          <Text tag="pRegular" className={heroClasses.tagline}>
            {tagline}
          </Text>
        )}
        {Action && <Action />}
      </Container>
    </Box>
  );
};

export type { IHeroProps };
export { heroClasses };
export { testId as HeroTestId };
export default Hero;
