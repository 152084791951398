/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Job } from '../models/Job';
import type { JobCreate } from '../models/JobCreate';
import type { JobUpdate } from '../models/JobUpdate';
import type { PaginatedJobList } from '../models/PaginatedJobList';
import type { PatchedJobUpdate } from '../models/PatchedJobUpdate';

export class JobService {
  /**
   * List all Jobs
   * List all Jobs
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param organizationId
   * @param profileId
   * @returns PaginatedJobList
   * @throws ApiError
   */
  public static jobList(
    limit?: number,
    offset?: number,
    ordering: '-dates' | 'dates' = '-dates',
    organizationId?: string,
    profileId?: string
  ): CancelablePromise<PaginatedJobList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/job/',
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
        organization__id: organizationId,
        profile__id: profileId,
      },
    });
  }

  /**
   * Create a Job
   * Create a Job
   * @param requestBody
   * @returns Job
   * @throws ApiError
   */
  public static jobCreate(requestBody?: JobCreate): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/job/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Job by UUID
   * Retrieve a Job by UUID
   * @param id A UUID string identifying this job.
   * @returns Job
   * @throws ApiError
   */
  public static jobRetrieve(id: string): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/job/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Job by UUID
   * Completely update a Job by UUID
   * @param id A UUID string identifying this job.
   * @param requestBody
   * @returns Job
   * @throws ApiError
   */
  public static jobUpdate(id: string, requestBody?: JobUpdate): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/job/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Job by UUID
   * Update specific fields within a Job by UUID
   * @param id A UUID string identifying this job.
   * @param requestBody
   * @returns Job
   * @throws ApiError
   */
  public static jobPartialUpdate(
    id: string,
    requestBody?: PatchedJobUpdate
  ): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/job/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Job by UUID
   * Delete a Job by UUID
   * @param id A UUID string identifying this job.
   * @returns void
   * @throws ApiError
   */
  public static jobDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/job/{id}/',
      path: {
        id: id,
      },
    });
  }
}
