import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, CompleteKnowledge, SearchService } from '@/openapi';

export const DEFAULT_SEARCH_LIMIT = 20;

type SearchKnowledge = {
  query: string;
  limit?: number;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const searchKnowledgeByName = ({
  query,
  limit = DEFAULT_SEARCH_LIMIT,
}: SearchKnowledge): CancelablePromise<Array<CompleteKnowledge>> => {
  return SearchService.completeKnowledgeCreate({
    query,
    limit,
    knowledge_types: ['knowledge_raw'],
  });
};

export const useSearchKnowledge = (
  { query, limit }: SearchKnowledge,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['search-knowledge', { query, limit }],
    queryFn: () => searchKnowledgeByName({ query, limit }),
    keepPreviousData: true,
    enabled: !!query,
    ...options,
  });
};
