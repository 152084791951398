import { typographyClasses } from '@mui/material/Typography';

const { h1, h2, h3, h4, button } = typographyClasses;

const ns = 'text';

export const textClasses = {
  h1,
  h2,
  h3,
  h4,
  button,
  pRegular: 'pRegular',
  pMedium: 'pMedium',
  pBold: 'pBold',
  inputLabel: 'inputLabel',
  inputPlaceholder: 'inputPlaceholder',
  inputHelpText: 'inputHelpText',
  root: `${ns}__root`,
};

const { pRegular, pMedium, pBold, inputLabel, inputPlaceholder, inputHelpText } = textClasses;

export const styles = {
  text: {
    [`&.${typographyClasses.root}`]: {
      [`&.${pRegular}, &.${inputPlaceholder}, &.${inputHelpText}`]: {
        fontWeight: 400,
      },
      [`&.${pMedium}, &.${inputLabel}`]: {
        fontWeight: 500,
      },
      [`&.${pBold}`]: {
        fontWeight: 700,
      },
      [`&.${h1}, &.${h2}, &.${h3}, &.${h4}, &.${button}`]: {
        fontWeight: 600,
      },
      [`&.${pRegular}, &.${pMedium}, &.${button}, &.${inputPlaceholder}, &.${inputLabel}, &.${inputHelpText}`]:
        {
          letterSpacing: '0em',
        },
      [`&.${h1}, &.${h2}`]: {
        letterSpacing: '-0.03em',
      },
      [`&.${h3}, &.${h4}`]: {
        letterSpacing: '-0.01em',
      },
      [`&.${h1}`]: {
        fontSize: '40px',
        lineHeight: '47px',
      },
      [`&.${h2}`]: {
        fontSize: '30px',
        lineHeight: '35px',
      },
      [`&.${h3}`]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      [`&.${h4}`]: {
        fontSize: '20px',
        lineHeight: '23px',
      },
      [`&.${pRegular}, &.${pMedium}, &.${pBold}`]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      [`&.${button}`]: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      [`&.${inputPlaceholder}`]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      [`&.${inputLabel}`]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
      [`&.${inputHelpText}`]: {
        fontSize: '12px',
        lineHeight: '20px',
      },
      '&.transform-lowercase': {
        textTransform: 'lowercase',
      },
      '&.transform-uppercase': {
        textTransform: 'uppercase',
      },
      '&.transform-capitalize': {
        textTransform: 'capitalize',
      },
      '&.transform-none': {
        textTransform: 'none',
      },
    },
  },
};
