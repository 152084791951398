import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Children,
  ReactElement,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
  useState,
} from 'react';
import { ChevronDown } from 'react-feather';

import Button from '@/componentsv2/Button';

import { IMenuButtonProps } from './types';

const testId = 'of-menu-button';

const Root = forwardRef<HTMLButtonElement, IMenuButtonProps>(
  ({ className, children, id, buttonProps = {} }, ref) => {
    const menuId = `menu-button-${id}`;
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorEl = useRef<HTMLDivElement>(null);
    const handleClose = () => setMenuOpen(false);

    const ChildrenWithAutoclose = () => (
      <>
        {Children.map(children, (child) => {
          return isValidElement(child)
            ? cloneElement(child as ReactElement, {
                onClick: () => {
                  child?.props.onClick();
                  handleClose();
                },
              })
            : null;
        })}
      </>
    );

    return (
      <div className={className} data-testid={testId} ref={anchorEl}>
        <Button
          ref={ref}
          aria-controls={menuId}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : 'false'}
          onClick={() => setMenuOpen(true)}
          srText="More actions"
          startIcon={ChevronDown}
          {...buttonProps}
        />
        <Menu
          id={menuId}
          anchorEl={anchorEl.current}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': menuId,
          }}
        >
          <ChildrenWithAutoclose />
        </Menu>
      </div>
    );
  }
);

Root.displayName = 'MenuButton';

export { testId as MenuButtonTestId };
export type { IMenuButtonProps };

const MenuButton = Object.assign(Root, { Item: MenuItem });
export default MenuButton;
