import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

const testId = 'of-experience-row-skeleton';

const ExperienceRowSkeleton = ({ className }: { className?: string }) => (
  <div
    className={clsx(className, 'rounded-md border border-light-primary p-12 shadow-5')}
    data-testid={testId}
  >
    <Skeleton sx={{ transform: 'none', mt: '2px' }} variant="text" width={200} height={16} />
    <Skeleton sx={{ transform: 'none', mt: '11px' }} height={12} width={250} />
  </div>
);

export default ExperienceRowSkeleton;
