import { AnyAction, UnknownAction } from 'redux';

type RuntimeState = Record<string, any>;

const initialState: RuntimeState = {};

export function setRuntimeVariable(name: string, value: any): UnknownAction {
  return {
    type: 'SET_RUNTIME_VARIABLE',
    payload: {
      name,
      value,
    },
  };
}

export default function runtime(state = initialState, action: AnyAction): RuntimeState {
  switch (action.type) {
    case 'SET_RUNTIME_VARIABLE':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
}
