import createDOMPurify, { Config } from 'dompurify';
import { PhoneNumberUtil } from 'google-libphonenumber';
import keycode from 'keycode';
import moment, { Moment } from 'moment-timezone';
import queryString from 'query-string';

import config from '@/config';
import Duration from '@/core/duration';
import { CollectionState } from '@/reducers/projects';
import { SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XS } from '@/theme/screens';

const DOMPurify = createDOMPurify(window);

// From backbone
let idCounter = 0;
export function uniqueId(prefix: string) {
  const id = `${++idCounter}`;
  return prefix ? prefix + id : id;
}

const takeRate = 0.2;
const moneyPerCredit = 200;
const roundRate = 25;
const roundCredit = 5;

export function formatCredits(amount: number) {
  return (amount / 100.0)
    .toFixed(2)
    .replace(/(\..*?)(0+)$/, '$1')
    .replace(/\.?$/, '');
}

export function formatCreditsText(amount: number) {
  return `${formatCredits(amount)} ${amount / 100 === 1 ? 'credit' : 'credits'}`;
}

export function capitalize(str: string) {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export function absoluteUrl(path: string) {
  return new URL(path, window.location.href).href;
}

// same rule used to create transactions
export function roundOffCredits(creditRate = 0) {
  return roundCredit * Math.ceil(creditRate / roundCredit);
}

/**
 * Computes the number of credits from a bill rate
 * @param {int} bill rate in cents
 */
export function rateToCredits(rate = 0) {
  const totalRate = rate / (1.0 - takeRate);
  const n = Math.trunc(totalRate / moneyPerCredit);
  let d = Math.floor(n / roundRate);

  if (n % roundRate > 0) {
    d++;
  }

  return d * roundRate;
}

export function formatBillRate(rate = 0) {
  return `$${(rate / 100).toFixed(2)} per hour`;
}

export function formatLocation(city?: string, country?: string) {
  return [city, country].filter((e) => e).join(', ');
}

export const dateFormat = 'dddd D MMM YYYY';

export function formatDateTime(date: Moment | Date, timezone?: string) {
  const momentTimezone = moment.tz(
    date,
    timezone && moment.tz.zone(timezone) ? timezone : moment.tz.guess()
  );
  return `${momentTimezone.format(dateFormat)} • ${momentTimezone.format('h:mm a')}`;
}

export function formatDate(date: Moment | Date, timezone: string = '') {
  const momentTimezone = moment.tz(date, moment.tz.zone(timezone) ? timezone : moment.tz.guess());
  return momentTimezone.format(dateFormat);
}

let _localStorage: Storage | null | undefined;
function localStorage() {
  if (typeof _localStorage !== 'undefined') {
    return _localStorage;
  }

  _localStorage = typeof window !== 'undefined' && window.localStorage ? window.localStorage : null;

  if (_localStorage) {
    try {
      _localStorage.setItem('localStorage', '1');
      _localStorage.removeItem('localStorage');
    } catch {
      console.warn('browser does not support local storage');
      _localStorage = undefined;
    }
  }

  if (!_localStorage) {
    _localStorage = null;
  }

  return _localStorage;
}

export function getCache(key: string): any | undefined {
  const storage = localStorage();
  if (storage && storage[key]) {
    return JSON.parse(storage[key]);
  }
}

export function setCache(key: string, obj: Record<string, any> | undefined) {
  const storage = localStorage();
  if (storage) {
    if (obj) {
      storage[key] = JSON.stringify(obj);
    } else {
      delete storage[key];
    }
  }
}

export function clearCache(key: string) {
  setCache(key, undefined);
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmailValid(email: string): boolean {
  return emailRegex.test(email);
}

export function isPhoneValid(phone: string): boolean {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone));
  } catch {
    return false;
  }
}

export const normalizePhone = (value = '') => value.replace(/[^\d-\s+()]/g, '');

// Replace multiple spaces with a single space
// also remove a single space at the beginning
export const normalizeSpace = (value = '') => value.replace(/^\s+|\s+(?=\s)/g, '');

// Replace new lines with an empty string
export const normalizeNewLines = (v = '') => v.replace(/\n/g, '');

// KT: From Java -- for deterministic ids
export function hashCode(s: string) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32bit integer
  }
  return hash;
}

export function urljoin(base: string, input: string) {
  try {
    new URL(input);
    return input;
    // eslint-disable-next-line no-empty
  } catch {}

  const url = new URL(base);
  const { pathname } = url;
  const path = input.startsWith('/') ? input : `${pathname}/${input}`;

  return url.origin + path;
}

export function debounce(f: Function, wait: number) {
  let timeout: string | number | NodeJS.Timeout | null | undefined;
  let toCall: (() => void) | null;
  return function (this: unknown, ...args: any[]) {
    toCall = () => {
      f.apply(this, args);
      toCall = null;
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      if (!toCall) return;
      toCall();
    }, wait);
  };
}

export function debounceAsync(func: Function, wait: number) {
  let timeout: string | number | NodeJS.Timeout | null | undefined;

  return (...args: any[]) =>
    new Promise((resolve, reject) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
    });
}

export function slugify(string: string) {
  return (string || '')
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function getScreenWidth(width: number) {
  if (!width) return SCREEN_LG;
  if (width >= SCREEN_LG) return SCREEN_LG;
  if (width >= SCREEN_MD) return SCREEN_MD;
  if (width >= SCREEN_SM) return SCREEN_SM;
  return SCREEN_XS;
}

export function shouldResetCollection(
  col: CollectionState = {
    loading: false,
    edges: [],
    pageInfo: { hasNextPage: true },
    resetAt: undefined,
  },
  pageSize: number,
  expiryInMinutes?: number
) {
  const shouldReset = col.edges.length < pageSize && col.pageInfo.hasNextPage;
  const expired = moment(col.resetAt)
    .add(expiryInMinutes || 5, 'minutes')
    .isBefore(moment());
  return shouldReset || expired;
}

const withinAppRoutes = [
  '/select_domain',
  '/validate_email',
  '/dashboard',
  '/profile',
  '/consultations',
  '/expert_requests',
  '/teams',
  '/team',
  '/settings',
  '/compliance_training',
  '/legal_ack',
  '/password_reset',
  '/messaging',
  '/change_password',
  '/admin',
  /^\/?.*\/login\/?.*/,
  /^\/?.*\/signup\/?.*/,
  /^\/search\??.*/,
  /^\/messaging\/.*/,
  /^\/profile\/.*/,
  /^\/unregistered_expert\/.*/,
  /^\/consultation\/.*/,
  /^\/project\/.*$/,
  /^\/expert_request\/.*$/,
  /^\/expert_requests\/.*/,
  /^\/profile\/.*$/,
  /^\/team\/.*/,
  /^\/compliance_training\/.*/,
  /^\/legal_ack\/.*/,
  /^\/settings\/.*/,
  /^\/network\/?.*/,
  /^\/admin\/.*/,
];

function matchRoute(pattern: string | RegExp, path: string) {
  if (typeof pattern === 'string') {
    return pattern === path;
  }

  return path.match(pattern);
}

export function isWithinAppRoute(path: string) {
  return withinAppRoutes.findIndex((pattern) => matchRoute(pattern, path)) >= 0;
}

const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function prettyBytes(num: number) {
  if (!Number.isFinite(num)) {
    throw new TypeError(`Expected a finite number, got ${typeof num}: ${num}`);
  }

  const neg = num < 0;

  let n = num;
  if (neg) {
    n = -n;
  }

  if (n < 1) {
    return `${(neg ? '-' : '') + n} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(n) / Math.log(1000)), UNITS.length - 1);
  const numStr = Number((n / 1000 ** exponent).toPrecision(3));
  const unit = UNITS[exponent];

  return `${(neg ? '-' : '') + numStr} ${unit}`;
}

export function pathAndQuery(location: URL) {
  return encodeURIComponent(`${location.pathname}${location.search}`);
}

export function getSameOriginPath(urlStr: string) {
  try {
    const url = new URL(urlStr);
    if (window.location.origin === url.origin) {
      return url.pathname;
    }
  } catch {
    return '';
  }
  return '';
}

export function rewriteUrl(url: string) {
  const toRewrite = config.rewriteUrl;
  if (toRewrite && url?.startsWith(toRewrite)) {
    return url.slice(toRewrite.length);
  }
  return url;
}

export function queryPart(opts: Record<string, any>) {
  const qstr = queryString.stringify(opts);
  return qstr ? `?${qstr}` : '';
}

export function safeHtml(str: string, opts: Config) {
  return DOMPurify.sanitize(unescape(str), opts);
}

// A utility function to safely escape JSON for embedding in a <script> tag
// Copied from https://github.com/mhart/react-server-example
export function safeStringify(obj: Record<string, any>) {
  if (!obj) return '';
  return JSON.stringify(obj)
    .replace(/<\/(script)/gi, '<\\/$1')
    .replace(/<!--/g, '<\\!--')
    .replace(/\u2028/g, '\\u2028') // Only necessary if interpreting as JS, which we do
    .replace(/\u2029/g, '\\u2029'); // Ditto
}

export function safeUrl(str: string) {
  try {
    const url = new URL(str);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return str;
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return '';
}

interface HighlightOptions {
  multiline?: boolean;
  [key: string]: any;
}

export function highlight(str: string, { multiline, ...opts }: HighlightOptions = {}) {
  return safeHtml(multiline ? str.split('\n').join('<br />') : str, {
    ALLOWED_TAGS: ['em', 'br'],
    ALLOWED_ATTR: [],
    ...opts,
  });
}

type KeyGetter = { (p: any): any; (arg0: any): any };
type Item = { [key: string]: any[] };

export function groupBy(list: Item[], keyGetter: KeyGetter) {
  const obj: Item = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = obj[key];
    if (collection) {
      collection.push(item);
    } else {
      obj[key] = [item];
    }
  });
  return obj;
}

export function unique(list: Item[], keyGetter: KeyGetter) {
  return Object.values(groupBy(list, keyGetter)).map((l) => l[0]);
}

export function sortBy(field: string) {
  return function (p1: { [key: string]: any }, p2: { [key: string]: any }) {
    const n1 = (p1[field] || '').trim().toLowerCase();
    const n2 = (p2[field] || '').trim().toLowerCase();
    if (n1 < n2) return -1;
    if (n1 > n2) return +1;
    return 0;
  };
}

export function fibonacci(start: number, count: number) {
  let lastButOne = 0;
  let last = 0;
  let current = start;

  for (let i = 1; i < count; i++) {
    lastButOne = last;
    last = current;
    current = last + lastButOne;
  }

  return current;
}

// based on https://stackoverflow.com/a/48218209
// but for makeStyles
export function concatDeep(...objects: { [key: string]: any }[]) {
  const isObject = (obj: object) => obj && typeof obj === 'object';

  return objects.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = acc[key];
      const oVal = obj[key];

      if (isObject(pVal) && isObject(oVal)) {
        acc[key] = concatDeep(pVal, oVal);
      } else {
        acc[key] = `${pVal} ${oVal}`;
      }
    });

    return acc;
  }, {});
}

export function formatDuration(duration: Duration, separator = ':') {
  if (!duration) return '';
  const hours = `${duration.hours()}`.padStart(2, '0');
  const minutes = `${duration.minutes() % 60}`.padStart(2, '0');
  return `${hours}${separator}${minutes}`;
}

export function isArrayNotEmpty<T>(a: T[]) {
  return a.length > 0;
}

export function prettyName(name: string | undefined) {
  if (!name) return '';
  return (name.split('.').pop() ?? '')
    .split('_')
    .reduce((text: string, t: string) => `${text} ${t.charAt(0).toUpperCase()}${t.slice(1)}`, '')
    .trim();
}

export function isBot(ua: UAParser.IResult) {
  return !!ua.browser.type;
}

export function isUserApplying(user: { expert_state: string }) {
  return !user.expert_state || user.expert_state === 'applying';
}

export function interceptEnter(e: any) {
  const target = e.target;
  if (keycode(e) !== 'enter' || target.type === 'textarea') return;
  e.preventDefault();
}

export function isEmpty(value: any) {
  // Always trim and zeros are valid
  return !String(value ?? '').trim();
}

export function normalise(value: number, max: number) {
  return value > max ? 100 : (value * 100) / max;
}

// Get the id property for a list of input values, useful for redux-form
// field parse property
export function parseId(values: { id: any }[]) {
  return values && values.map((value) => value.id).filter(Boolean);
}

// Common Field validation helpers
export function required(value: string) {
  return value ? undefined : 'Required';
}

// Error message helper
export function errorMessage(value: string) {
  return value.replace('GraphQL Error: ', '');
}

export type HostnameParser = ReturnType<typeof createHostnameParser>;

export interface ParsedHostname {
  subdomain?: string;
  customTLD: string | null;
}

export function createHostnameParser(baseDomain: string) {
  const parts = baseDomain.split('.');
  const baseTLD = parts.length > 1 ? parts[parts.length - 1] : '';

  const domainExtractor = baseTLD ? new RegExp(`^(.+)\\.${baseTLD}$`) : /^(.*)$/;
  const domainMatch = domainExtractor.exec(baseDomain);
  const [, domainWithoutTLD] = domainMatch || [];
  if (!domainMatch || !domainWithoutTLD) {
    throw new Error('Invalid domain config. ' + `BASE_DOMAIN:'${baseDomain}', BASE_TLD:${baseTLD}`);
  }
  const domainParser = new RegExp(`^((.+)\\.)?${domainWithoutTLD}(\\.(.+))?$`);

  return (hostname: string): ParsedHostname => {
    const match = domainParser.exec(hostname);
    if (!match) return { customTLD: null };
    const [, , subdomain, , tld = ''] = match;
    const customTLD = tld === baseTLD ? null : tld;
    return { subdomain, customTLD };
  };
}

export const parseHostname = createHostnameParser(config.baseDomain);
