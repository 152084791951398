import { MESSAGING__EVENT_UNREAD_COUNT } from '.';

interface MessagesState {
  unreadCount: number;
}

const initialState: MessagesState = {
  unreadCount: 0,
};

export default function (state = initialState, action: any): MessagesState {
  switch (action.type) {
    case MESSAGING__EVENT_UNREAD_COUNT:
      return { ...state, unreadCount: action.unreadCount };
    default:
      return state;
  }
}
