import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';

import FAIcon from '@/components/Icon/FAIcon';
import { darkGray } from '@/theme/colors';

const useStyles = makeStyles({
  passwordAdornment: {
    padding: '5px 20px',
  },
  passwordHelp: {
    lineHeight: 1.25,
    fontSize: 14,
  },
});

function PasswordHelp() {
  const s = useStyles();

  return (
    <div className={s.passwordHelp}>
      <p>Password must contain at least:</p>
      <ul>
        <li>8 characters</li>
        <li>1 uppercase letter</li>
        <li>1 lowercase letter</li>
        <li>1 digit</li>
        <li>1 special character</li>
      </ul>
    </div>
  );
}

export function PasswordHelpInputAdornment({ children, id }: any) {
  const s = useStyles();

  const [anchorPwdHelpEl, setAnchorPwdHelpEl] = React.useState(null);
  const handlePwdHelpClick = useCallback((e: any) => setAnchorPwdHelpEl(e.currentTarget), []);
  const handlePwdHelpClose = useCallback(() => setAnchorPwdHelpEl(null), []);
  const pwdHelpOpen = Boolean(anchorPwdHelpEl);

  return (
    <InputAdornment position="end">
      <Popover
        open={pwdHelpOpen}
        anchorEl={anchorPwdHelpEl}
        onClose={handlePwdHelpClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: s.passwordAdornment }}
      >
        <PasswordHelp />
      </Popover>
      <IconButton onClick={handlePwdHelpClick} id={`${id}HelpButtonId`}>
        <FAIcon iconSet="fal" icon="question-circle" color={darkGray} size={20} />
      </IconButton>
      {children}
    </InputAdornment>
  );
}
