import React from 'react';

import Dialog from '@/components/Dialog';
import EditDialog from '@/components/EditDialog/EditDialog';
import EditEmails from '@/components/EditEmails/EditEmails';
import EditPhones from '@/components/EditPhones/EditPhones';
import AddAdditionalInformationForm from '@/profile/components/forms/AddAdditionalInformationForm';
import AvailableMarketplaceForm from '@/profile/components/forms/AvailableMarketPlaceForm';
import BillRateForm from '@/profile/components/forms/BillRateForm';
import CommunicationForm from '@/profile/components/forms/CommunicationForm';
import EducationForm from '@/profile/components/forms/EducationForm';
import ExperiencesForm from '@/profile/components/forms/ExperiencesForm';
import ExpertiseForm from '@/profile/components/forms/ExpertiseForm';
import IntroductionForm from '@/profile/components/forms/IntroductionForm';
import KeywordsForm from '@/profile/components/forms/KeywordsForm';
import LinkedinForm from '@/profile/components/forms/LinkedinForm';
import LocationForm from '@/profile/components/forms/LocationForm';
import NameForm from '@/profile/components/forms/NameForm';
import QuestionsForm from '@/profile/components/forms/QuestionsForm';
import SecurityForm from '@/profile/components/forms/Security';

import AvailableSelfServiceForm from './AvailableSelfServiceForm';
import BackgroundCheckForm from './BackgroundCheckForm';

const Dialogs = ({ user, profile, editSections, closeDialog }: any) => {
  return (
    <div>
      <Dialog
        maxWidth="md"
        title="Edit Emails"
        confirmLabel="Done"
        open={!!editSections.emails}
        onCancel={closeDialog}
        onConfirm={closeDialog}
      >
        <EditEmails showSuccessMessage={false} userId={user && user.id} profileId={profile.id} />
      </Dialog>

      <Dialog
        maxWidth="md"
        title="Edit Phones"
        confirmLabel="Done"
        open={!!editSections.phones}
        onCancel={closeDialog}
        onConfirm={closeDialog}
      >
        <EditPhones profileId={profile.id} />
      </Dialog>

      <AvailableMarketplaceForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        title="Expert Marketplace Status"
        profileId={profile.id}
        open={!!editSections.available_marketplace}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <AvailableSelfServiceForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        title="Expert Available Self Service"
        userId={user && user.id}
        open={!!editSections.available_self_service}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <BackgroundCheckForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        title="Background Check Complete"
        userId={user && user.id}
        open={!!editSections.background_check}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <BillRateForm
        component={EditDialog}
        title="Edit Rate"
        profileId={profile.id}
        open={!!editSections.rate}
        onSubmit={closeDialog}
        onClose={closeDialog}
        form="expertAvailabilityFromProfile"
      />

      <CommunicationForm
        component={Dialog}
        useForm
        editPhone={!!user}
        title="Edit Communication"
        profileId={profile.id}
        open={!!editSections.communication}
        onClose={closeDialog}
      />

      <LocationForm
        component={EditDialog}
        title="Edit Location"
        profileId={profile.id}
        open={!!editSections.location}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <NameForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        maxWidth="sm"
        title="Edit Name"
        profileId={profile.id}
        open={!!editSections.name}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <IntroductionForm
        component={EditDialog}
        // @ts-expect-error TS(2769): No overload matches this call.
        title="Edit Headline and Short Bio"
        profileId={profile.id}
        open={!!editSections.headline}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <QuestionsForm
        component={EditDialog}
        // @ts-expect-error TS(2769): No overload matches this call.
        maxWidth="md"
        title="Edit Questions"
        profileId={profile.id}
        open={!!editSections.questions}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <ExpertiseForm
        component={EditDialog}
        // @ts-expect-error TS(2769): No overload matches this call.
        maxWidth="md"
        title="Edit Expertise"
        profileId={profile.id}
        open={!!editSections.expertise}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <KeywordsForm
        // @ts-expect-error
        component={EditDialog}
        maxWidth="md"
        title="Edit Keywords"
        userId={user && user.id}
        profileId={profile.id}
        open={!!editSections.keywords}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <LinkedinForm
        component={EditDialog}
        maxWidth="md"
        title="Edit LinkedIn"
        profileId={profile.id}
        open={!!editSections.linkedin}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <ExperiencesForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        maxWidth="lg"
        title="Edit Work Experience"
        profileId={profile.id}
        open={!!editSections.experiences}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <EducationForm
        // @ts-expect-error TS(2769): No overload matches this call.
        component={EditDialog}
        maxWidth="lg"
        title="Edit Education"
        profileId={profile.id}
        open={!!editSections.education}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <AddAdditionalInformationForm
        component={EditDialog}
        maxWidth="lg"
        title="Add Additional Information"
        profileId={profile.id}
        open={!!editSections.additional_information}
        onSubmit={closeDialog}
        onClose={closeDialog}
        disableSubmitOnEnter
      />

      <Dialog open={!!editSections.security} onClose={closeDialog}>
        <SecurityForm
          maxWidth="lg"
          title="Edit Security"
          userId={user && user.id}
          onDisableOtpAuth={closeDialog}
        />
      </Dialog>
    </div>
  );
};
Dialogs.displayName = 'Dialogs';

export default Dialogs;
