import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { LANDING_PAGE__UPDATE, LANDING_PAGE__REMOVE, LANDING_PAGE__BATCH_ADD } = ActionTypes;

export const LANDING_PAGE_FIELDS = gengql(/* GraphQL */ `
  fragment LandingPageFields on LandingPage {
    id
    subdomain
    path
    page_title
    title
    subtitle
    background_url
    header_logo_url
    header_logo_link_url
    body_logo_url
    meta_image_url
    description
    experts_engage_text
    google_tag_manager_id
    organization_about_logo_url
    organization_about_logo_link_url
    organization_about_text
    organization_about_title
    onfrontiers_about_text
    show_footer
    ctas {
      id
      label
      url
      order
    }
    engage_ctas {
      id
      label
      url
      order
    }
    engage_details {
      id
      icon
      description
      order
    }
    blog_posts {
      id
      url
      thumbnail_url
      author
      date
      title
      excerpt
    }
    value_propositions {
      id
      icon
      title
      description
    }
  }
`);

const UPDATE_LANDING_PAGE = gengql(/* GraphQL */ `
  mutation actionUpdateLandingPage(
    $id: String
    $subdomain: String
    $path: String
    $page_title: String
    $title: String
    $subtitle: String
    $background_url: String
    $header_logo_url: String
    $header_logo_link_url: String
    $body_logo_url: String
    $meta_image_url: String
    $description: String
    $experts_engage_text: String
    $google_tag_manager_id: String
    $organization_about_logo_url: String
    $organization_about_logo_link_url: String
    $organization_about_text: String
    $organization_about_title: String
    $onfrontiers_about_text: String
    $show_footer: Boolean
    $ctas: [LandingPageCtaInputType!]
    $engage_ctas: [LandingPageEngageCtaInputType!]
    $engage_details: [LandingPageEngageDetailInputType!]
    $value_propositions: [LandingPageValuePropositionInputType!]
    $blog_posts: [LandingPageBlogPostInputType!]
  ) {
    updateLandingPage(
      id: $id
      subdomain: $subdomain
      path: $path
      page_title: $page_title
      title: $title
      subtitle: $subtitle
      background_url: $background_url
      header_logo_url: $header_logo_url
      header_logo_link_url: $header_logo_link_url
      body_logo_url: $body_logo_url
      meta_image_url: $meta_image_url
      description: $description
      experts_engage_text: $experts_engage_text
      google_tag_manager_id: $google_tag_manager_id
      organization_about_logo_url: $organization_about_logo_url
      organization_about_logo_link_url: $organization_about_logo_link_url
      organization_about_text: $organization_about_text
      organization_about_title: $organization_about_title
      onfrontiers_about_text: $onfrontiers_about_text
      show_footer: $show_footer
      ctas: $ctas
      engage_ctas: $engage_ctas
      engage_details: $engage_details
      value_propositions: $value_propositions
      blog_posts: $blog_posts
    ) {
      ...LandingPageFields
    }
  }
`);

export function fetchLandingPage({ subdomain, path, id }: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-ignore
    const pages = getState().landing[subdomain];
    const cachedPage = pages && pages[path];
    if (cachedPage) return cachedPage;

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchLandingPage($subdomain: String, $path: String, $id: String) {
          landingPage(subdomain: $subdomain, path: $path, id: $id) {
            ...LandingPageFields
          }
        }
      `),
      { subdomain, path, id }
    );

    const page = result.landingPage;

    if (page) {
      dispatch({ type: LANDING_PAGE__UPDATE, subdomain, path, ...page, page });
    }

    return page;
  };
}

export function updateLandingPage(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(UPDATE_LANDING_PAGE, options);
    const page = result.updateLandingPage;

    if (page) {
      dispatch({ type: LANDING_PAGE__UPDATE, ...page, page });
    }

    return page;
  };
}

export function removeLandingPage(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { removeLandingPage } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveLandingPage($id: String!) {
          removeLandingPage(id: $id)
        }
      `),
      { id }
    );

    if (removeLandingPage) {
      dispatch({ type: LANDING_PAGE__REMOVE, id });
    }

    return removeLandingPage;
  };
}

export function fetchLandingPages(): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchLandingPages {
          landingPages {
            id
            subdomain
            path
            title
          }
        }
      `)
    );

    const pages = result.landingPages;

    if (pages) {
      dispatch({ type: LANDING_PAGE__BATCH_ADD, pages });
    }

    return pages;
  };
}
