import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FilterRequest } from '@/core/pagination';
import { CancelablePromise, CountryService, PaginatedCountryList } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<PaginatedCountryList>;

export const fetchData = createAsyncThunk('countries/fetchData', async (filters: FilterRequest) => {
  filters.cancelPrevious && request?.cancel();
  request = CountryService.countryList(filters.limit, filters.name, filters.offset);

  return request;
});

const countriesSlice = createSlice({
  name: 'countries',
  initialState: initialState.harnessCountries,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedCountryList>) {
      return { ...state, data: action.payload };
    },
    resetData() {
      return initialState.harnessCountries;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      error: null,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const countriesSelector = (state: IState) => state.harnessCountries;
export const { updateData, resetData } = countriesSlice.actions;
export default countriesSlice.reducer;
