import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { PaginatedShortlistProfileList, SavedSearchService, ShortlistProfile } from '@/openapi';

export const useAddToShortlist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, profiles }: { id: string; profiles: ShortlistProfile[] }) =>
      SavedSearchService.savedSearchShortlistCreate(id, {
        profile_ids: profiles.map((profile) => profile.profile_id),
      }),
    onMutate: async (updatedPayload) => {
      // optimstically update cache
      const prevData: PaginatedShortlistProfileList =
        queryClient.getQueryData(['shortlist', { id: updatedPayload.id }]) ??
        ({ count: 0 } as PaginatedShortlistProfileList);

      const newData = {
        ...prevData,
        count: (prevData.count ?? 0) + updatedPayload.profiles.length,
        results: [
          ...(updatedPayload.profiles as ShortlistProfile[]), // prepend new profiles
          ...(prevData.results ?? []),
        ],
      };

      queryClient.setQueryData(['shortlist', { id: updatedPayload.id }], newData);

      return { prevData };
    },
    onError: (err, updatedPayload, context) => {
      // rollback optimistic update
      queryClient.setQueryData(['shortlist', { id: updatedPayload.id }], context?.prevData);
      enqueueSnackbar('Failed to add to shortlist, please contact support', {
        variant: 'error',
      });
    },
  });
};

export const useRemoveFromShortlist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, profileId }: { id: string; profileId: string }) =>
      SavedSearchService.savedSearchShortlistDestroy(id, profileId),
    onMutate: async (updatedPayload) => {
      // optimstically update cache
      const prevData: PaginatedShortlistProfileList =
        queryClient.getQueryData(['shortlist', { id: updatedPayload.id }]) ??
        ({ count: 0 } as PaginatedShortlistProfileList);

      if (!prevData.results?.length) {
        return;
      }

      const newData = {
        ...prevData,
        count: (prevData.count ?? 1) - 1,
        results: prevData.results.filter(
          (prevProfile) => prevProfile.profile_id !== updatedPayload.profileId
        ),
      };

      queryClient.setQueryData(['shortlist', { id: updatedPayload.id }], newData);

      return { prevData };
    },
    onError: (err, updatedPayload, context) => {
      // rollback optimistic update
      if (context?.prevData) {
        queryClient.setQueryData(['shortlist', { id: updatedPayload.id }], context?.prevData);
      }
      enqueueSnackbar('Failed to add to shortlist, please contact support', {
        variant: 'error',
      });
    },
  });
};
