import { DocumentNode } from 'graphql';

import { gengql } from '@/__generated__';
import {
  ProfileType,
  SuggestProfilesQueryVariables,
  SuggestUsersQueryVariables,
} from '@/__generated__/graphql';
import { AppThunk } from '@/store';
import { DocumentNodeResult } from '@/utils/gql';

import ActionTypes from './ActionTypes';

const { SUGGEST, SUGGEST_LOADING } = ActionTypes;

const inFlight = {};

const SUGGEST_USERS = gengql(/* GraphQL */ `
  query suggestUsers($text: String!) {
    suggest(text: $text) {
      id
      first_name
      last_name
      name
      html_url
      picture_url
      city
      country
      emails {
        address
      }
    }
  }
`);

const SUGGEST_PROFILES = gengql(/* GraphQL */ `
  query suggestProfiles($text: String!, $profileType: ProfileType) {
    suggestProfiles(text: $text, type: $profileType) {
      id
      user {
        id
        expert_state
      }
      first_name
      last_name
      name
      html_url
      url_endpoint
      picture_url
      created_at
    }
  }
`);

function suggest(
  scope: string,
  query: DocumentNode,
  params: SuggestUsersQueryVariables | SuggestProfilesQueryVariables
): AppThunk<Promise<unknown>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    inFlight[scope] = inFlight[scope] || 0;

    const { text } = params;

    if (text.trim() === '') {
      return dispatch({
        type: SUGGEST,
        scope,
        text,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        loading: !!inFlight[scope],
        suggestions: null,
      });
    }

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (getState().suggestions[scope].cache[text]) {
      // cache HIT
      return dispatch({
        type: SUGGEST,
        scope,
        text,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        loading: !!inFlight[scope],
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions: getState().suggestions[scope].cache[text],
      });
    }

    dispatch({
      type: SUGGEST_LOADING,
      scope,
    });

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    inFlight[scope] += 1;

    const { data: result } = await graphql.client.query({ query: query, variables: params });
    const [suggestions] = Object.values(result);

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    inFlight[scope] -= 1;

    dispatch({
      type: SUGGEST,
      scope,
      text,
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      loading: !!inFlight[scope],
      suggestions,
    });

    return result;
  };
}

export function suggestUsers(
  scope: string,
  text: string,
  _maxResults: number
): AppThunk<Promise<DocumentNodeResult<typeof SUGGEST_USERS>>> {
  return suggest(scope, SUGGEST_USERS, { text }) as AppThunk<
    Promise<DocumentNodeResult<typeof SUGGEST_USERS>>
  >;
}

export function suggestProfiles(
  scope: string,
  text: string,
  _maxResults: number,
  profileType?: ProfileType
): AppThunk<Promise<DocumentNodeResult<typeof SUGGEST_PROFILES>>> {
  return suggest(scope, SUGGEST_PROFILES, {
    text,
    profileType,
  }) as AppThunk<Promise<DocumentNodeResult<typeof SUGGEST_PROFILES>>>;
}
