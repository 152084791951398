import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';

const getStyle = makeStyles((theme) => ({
  root: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
      alignItems: 'center',
    },
  },
  label: (props) => ({
    // @ts-expect-error TS(2339): Property 'selected' does not exist on type '{}'.
    ...(props.selected ? { fontWeight: 600 } : {}),
  }),
}));

export interface RadioGroupOption {
  id?: string;
  value: any;
  label: string;
}

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'onChange'> {
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  FormLabelProps?: FormLabelProps;
  options: RadioGroupOption[];
  label: string;
  helperText?: string;
  error?: boolean;
  onChange?: (value: string) => void;
}

const RadioGroup = ({
  FormControlProps,
  FormHelperTextProps,
  FormLabelProps,
  options,
  defaultValue,
  value,
  label,
  helperText,
  error = false,
  onChange,
  ...other
}: RadioGroupProps): JSX.Element => {
  return (
    <FormControl error={error} {...FormControlProps}>
      {label && <FormLabel {...FormLabelProps}>{label}</FormLabel>}
      <MuiRadioGroup
        defaultValue={defaultValue}
        aria-label={label}
        {...other}
        onChange={(_, value) => {
          if (onChange) onChange(value);
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            id={option.id}
            label={option.label}
            value={option.value}
            checked={value === option.value}
            classes={getStyle({ selected: value === option.value })}
            control={<Radio id={option.id} />}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioGroup;
