import React from 'react';
import { Field } from 'redux-form';

import { TextField } from '@/components/FormAdapters/FormAdapters';
import { required } from '@/utils';

const NameField = function () {
  return (
    <Field
      changeOnBlur={false}
      id="expertRequestName"
      component={TextField}
      validate={[required]}
      helperText={'Required'}
      name="name"
      fullWidth
      placeholder="Name this expert request"
    />
  );
};
NameField.displayName = 'NameField';

export default NameField;
