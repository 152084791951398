/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { DegreeType } from '../models/DegreeType';
import type { PaginatedDegreeTypeList } from '../models/PaginatedDegreeTypeList';
import type { PatchedDegreeType } from '../models/PatchedDegreeType';

export class DegreeTypeService {
  /**
   * List all degree types
   * List all degree types
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param query The query in which you are searching for.
   * @returns PaginatedDegreeTypeList
   * @throws ApiError
   */
  public static degreeTypeList(
    limit?: number,
    offset?: number,
    query?: string
  ): CancelablePromise<PaginatedDegreeTypeList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/degree_type/',
      query: {
        limit: limit,
        offset: offset,
        query: query,
      },
    });
  }

  /**
   * Create a degree type
   * Create a degree type
   * @param requestBody
   * @returns DegreeType
   * @throws ApiError
   */
  public static degreeTypeCreate(requestBody: DegreeType): CancelablePromise<DegreeType> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/degree_type/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a degree type by UUID
   * Retrieve a degree type by UUID
   * @param id A UUID string identifying this degree type.
   * @returns DegreeType
   * @throws ApiError
   */
  public static degreeTypeRetrieve(id: string): CancelablePromise<DegreeType> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/degree_type/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a degree type by UUID
   * Completely update a degree type by UUID
   * @param id A UUID string identifying this degree type.
   * @param requestBody
   * @returns DegreeType
   * @throws ApiError
   */
  public static degreeTypeUpdate(
    id: string,
    requestBody: DegreeType
  ): CancelablePromise<DegreeType> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/degree_type/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a degree type by UUID
   * Update specific fields within a degree type by UUID
   * @param id A UUID string identifying this degree type.
   * @param requestBody
   * @returns DegreeType
   * @throws ApiError
   */
  public static degreeTypePartialUpdate(
    id: string,
    requestBody?: PatchedDegreeType
  ): CancelablePromise<DegreeType> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/degree_type/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a degree type by UUID
   * Delete a degree type by UUID
   * @param id A UUID string identifying this degree type.
   * @returns void
   * @throws ApiError
   */
  public static degreeTypeDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/degree_type/{id}/',
      path: {
        id: id,
      },
    });
  }
}
