import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';

import {
  CancelablePromise,
  PaginatedProfileAwardList,
  ProfileAward,
  ProfileAwardCreate,
  ProfileAwardService,
  ProfileAwardUpdate,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<PaginatedProfileAwardList>;

export const fetchData = createAsyncThunk('profileAwards/fetchData', async (id: string) => {
  request?.cancel();
  request = ProfileAwardService.profileAwardList(1000, 0, id);
  return request;
});
export const createAward = createAsyncThunk(
  'profileAwards/createAward',
  async (payload: ProfileAwardCreate) => await ProfileAwardService.profileAwardCreate(payload)
);
export const updateAward = createAsyncThunk(
  'profileAwards/updateAward',
  async (payload: ProfileAwardUpdate) =>
    await ProfileAwardService.profileAwardUpdate(payload.id, payload)
);
export const deleteAward = createAsyncThunk('profileAwards/deleteAward', (id: string) =>
  ProfileAwardService.profileAwardDestroy(id).then(() => id)
);

export const resetData = createAsyncThunk('profileAwards/resetData', async (_, { dispatch }) => {
  request?.cancel();
  dispatch(profileAwardsSlice.actions.reset());
});

const profileAwardsSlice = createSlice({
  name: 'profileAwards',
  initialState: initialState.profileAwards,
  reducers: {
    reset: () => initialState.profileAwards,
    addAward: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        results: [...(state.data?.results ?? []), action.payload],
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(createAward.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        if (draft.data.results) {
          draft.data.results.push({
            ...(action.payload as ProfileAward),
            award: action.payload.award_by_name || action.payload.award || '',
            organization: action.payload.organization_by_name || action.payload.organization || '',
          });
        }
      });
    });
    builder.addCase(updateAward.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((a) => a.id === action.payload.id);

        if (draft.data.results && draft.data.results[index]) {
          draft.data.results[index] = {
            ...(action.payload as ProfileAward),
            award: action.payload.award_by_name || action.payload.award || '',
            organization: action.payload.organization_by_name || action.payload.organization || '',
          };
        }
      });
    });
    builder.addCase(deleteAward.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((a) => a.id === action.payload);

        if (draft.data.results) {
          draft.data.results.splice(index, 1);
        }
      });
    });
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const profileAwardsSelector = (state: IState) => state.profileAwards;
export const { addAward } = profileAwardsSlice.actions;
export default profileAwardsSlice.reducer;
