import { Action } from 'redux';
import { UAParser } from 'ua-parser-js';
import { CLIs, Crawlers, Emails, Fetchers } from 'ua-parser-js/extensions';

import ActionTypes from '@/actions/ActionTypes';
import { UserContext, UserContextOption } from '@/core/user';
import { add, removeAt } from '@/utils/reducer';

// @ts-ignore
const parser = new UAParser([CLIs, Crawlers, Emails, Fetchers]);

interface Notification {
  message: string;
  messageType: string;
  duration: number;
}

interface UIAction extends Action {
  message: string;
  messageType: string;
  duration: number;
  userContext: UserContext;
  userContextOptions: UserContextOption[];
  userId: string;
}

interface UIState {
  notifications: Notification[];
  lastNotification?: Notification;
  userContext: UserContext;
  userContextOptions: UserContextOption[];
  lastUserIdViewed: string | null;
  userAgentParsed: ReturnType<typeof parser.getResult>;
  popup: any | null;
}

const initialState: UIState = {
  notifications: [],
  lastNotification: undefined,
  userContext: 'client',
  userContextOptions: [],
  lastUserIdViewed: null,
  userAgentParsed: parser.getResult(),
  popup: null,
};

export default function ui(state = initialState, action: UIAction): UIState {
  switch (action.type) {
    case ActionTypes.UI__SHOW_MESSAGE:
      return {
        ...state,
        notifications: add(state.notifications, {
          message: action.message,
          messageType: action.messageType,
          duration: action.duration,
        }),
      };
    case ActionTypes.UI__HIDE_MESSAGE:
      return {
        ...state,
        notifications: removeAt(state.notifications, 0),
        lastNotification: state.notifications[0],
      };
    case ActionTypes.UI__SHOW_POPUP:
      return {
        ...state,
        popup: action,
      };
    case ActionTypes.UI__HIDE_POPUP:
      return {
        ...state,
        popup: null,
      };
    case ActionTypes.UI__SET_USER_CONTEXT:
      return {
        ...state,
        userContext: action.userContext,
        userContextOptions: action.userContextOptions,
      };
    case ActionTypes.UI__SAVE_USER_VIEW:
      return {
        ...state,
        lastUserIdViewed: action.userId,
      };
    default:
      return state;
  }
}
