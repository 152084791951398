import moment from 'moment-timezone';
import React from 'react';

import Field from './Field';

export default function Source({ source }: any) {
  const {
    agent_id: agentId,
    agent_profile_id: agentProfileId,
    source_id: sourceId,
    source_profile_id: sourceProfileId,
    created_at: createdAt,
    created_by: createdBy,
  } = source;

  return (
    <div>
      <div>Source</div>
      <Field icon="upload" note={createdAt && moment(createdAt).format('D MMM YYYY hh:mma')}>
        <div>
          {agentId && (
            <div>
              {agentId}
              {agentProfileId && <div style={{ display: 'inline-block' }}>:{agentProfileId}</div>}
            </div>
          )}
          {sourceId && (
            <div>
              {sourceId}
              {sourceProfileId && <div style={{ display: 'inline-block' }}>:{sourceProfileId}</div>}
            </div>
          )}
        </div>
      </Field>

      {createdBy && (
        <Field icon="share" link={createdBy.html_url}>
          {createdBy.name}
        </Field>
      )}
    </div>
  );
}
