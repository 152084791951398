import ActionTypes from '@/actions/ActionTypes';

export default function (state = {}, action: any) {
  switch (action.type) {
    case ActionTypes.LEGAL__FETCH:
      return { pages: action.pages };
    default:
      return state;
  }
}
