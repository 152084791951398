import Grid from '@mui/material/Grid';
import React from 'react';

import MaterialIcon from '@/components/Icon/MaterialIcon';
import Link from '@/components/Link';
import DetailsNew from '@/expertrequest/components/ExpertRequestForm/DetailsNew';
import SubmitContainer from '@/expertrequest/components/ExpertRequestForm/SubmitContainer';
import { darkGreen } from '@/theme/colors';

import s from './Details.module.scss';

class Details extends React.PureComponent {
  render = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { change, values, handleSubmit, isMobileVersion } = this.props;
    return (
      <React.Fragment>
        <Link to="/request_expert" className={s.back}>
          <MaterialIcon color={darkGreen} icon="arrow_back" />
          <div className={s.backText}>Create New Request</div>
        </Link>
        <Grid container spacing={4} justifyContent="center" mt={0}>
          <Grid item md={8} sm={12} className="!pt-0">
            <DetailsNew
              isViewerExpert={false}
              change={change}
              values={values}
              isMobileVersion={isMobileVersion}
              canEdit
              canEditQueries
              canAddAttachment
            />
          </Grid>
          <Grid item md={4} sm={12} className={s.submitWrapper}>
            <SubmitContainer isNewRequest handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

// @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'typeof De... Remove this comment to see the full error message
Details.title = 'More Details';
// @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'typeof De... Remove this comment to see the full error message
Details.fetch = DetailsNew.fetch.concat([SubmitContainer.fetch]);

export default Details;
