import stringify from 'json-stringify-deterministic';
import qs from 'query-string';

import {
  CurrentRestriction,
  ExpertHistory,
  ExpertState,
  MarketplacePreference,
  ProfileType,
} from '@/__generated__/graphql';

export interface ExperienceFilter {
  text: string;
  start_date?: Date;
  end_date?: Date;
}

export class Query {
  text?: string;
  sort?: string;
  ascending?: boolean;
  highlight?: boolean;
  mustHaveEmail?: boolean;
  profileType?: ProfileType;
  expertStates?: ExpertState[];
  expertHistory?: ExpertHistory[];
  languages?: string[];
  countries?: string[];
  industries?: string[];
  agentIds?: string[];
  networkIds?: string[];
  sharingGroupIds?: string[];
  organizationsCurrentRestriction?: CurrentRestriction;
  organizations?: ExperienceFilter[];
  locationsCurrentRestriction?: CurrentRestriction;
  locations?: ExperienceFilter[];
  jobTitlesCurrentRestriction?: CurrentRestriction;
  jobTitles?: ExperienceFilter[];
  consultationsWith?: string[];
  educationDegrees?: string[];
  educationFos?: string[];
  groupKeywordIds?: string[];
  keywords?: string[];
  marketplacePreference?: MarketplacePreference;

  constructor(options: Partial<Query> = {}) {
    Object.assign(this, {
      text: (options.text || '').trim(),
      sort: options.sort || '',
      ascending: options.sort ? options.ascending || false : undefined,
      highlight: options.highlight === undefined ? true : options.highlight,
      mustHaveEmail: options.mustHaveEmail === undefined ? true : options.mustHaveEmail,
      profileType: options.profileType || '',
      expertStates: options.expertStates || [],
      expertHistory: options.expertHistory || [],
      languages: options.languages || [],
      countries: options.countries || [],
      industries: options.industries || [],
      agentIds: options.agentIds || [],
      networkIds: options.networkIds || [],
      sharingGroupIds: options.sharingGroupIds || [],
      organizationsCurrentRestriction: options.organizationsCurrentRestriction || 'none',
      organizations: options.organizations || [],
      locationsCurrentRestriction: options.locationsCurrentRestriction || 'none',
      locations: options.locations || [],
      jobTitlesCurrentRestriction: options.jobTitlesCurrentRestriction || 'none',
      jobTitles: options.jobTitles || [],
      consultationsWith: options.consultationsWith || [],
      educationDegrees: options.educationDegrees || [],
      educationFos: options.educationFos || [],
      groupKeywordIds: options.groupKeywordIds || [],
      keywords: options.keywords || [],
      marketplacePreference: options.marketplacePreference || 'none',
    });
  }

  serialize(): Partial<Query> {
    return {
      text: this.text,
      sort: this.sort,
      ascending: this.ascending,
      highlight: this.highlight,
      mustHaveEmail: this.mustHaveEmail,
      profileType: this.profileType,
      expertStates: this.expertStates,
      expertHistory: this.expertHistory,
      languages: this.languages,
      countries: this.countries,
      industries: this.industries,
      agentIds: this.agentIds,
      networkIds: this.networkIds,
      sharingGroupIds: this.sharingGroupIds,
      organizationsCurrentRestriction: this.organizationsCurrentRestriction,
      organizations: this.organizations,
      locationsCurrentRestriction: this.locationsCurrentRestriction,
      locations: this.locations,
      jobTitlesCurrentRestriction: this.jobTitlesCurrentRestriction,
      jobTitles: this.jobTitles,
      consultationsWith: this.consultationsWith,
      educationDegrees: this.educationDegrees,
      educationFos: this.educationFos,
      groupKeywordIds: this.groupKeywordIds,
      keywords: this.keywords,
      marketplacePreference: this.marketplacePreference,
    };
  }

  hash(): string {
    return stringify(this);
  }
}

export function parseExperienceFilter(value?: string) {
  const values = (value || '').split(':');
  if (values.length === 0) return;

  const startYear = values[1] && values[1].length === 4 && Number(values[1]);
  const endYear = values[2] && values[2].length === 4 && Number(values[2]);

  return {
    text: values[0],
    start_date: Number.isInteger(startYear)
      ? new Date(startYear as number, 0, 1, 0, 0, 0)
      : undefined, // inclusive
    end_date: Number.isInteger(endYear)
      ? new Date((endYear as number) + 1, 0, 1, 0, 0, 0)
      : undefined, // exclusive
  };
}

export function toQueryString(query: Partial<Query>) {
  return qs.stringify(
    {
      q: query.text || undefined,
      sort: query.sort || undefined,
      asc: query.ascending,
      email: query.mustHaveEmail,
      profile_type: query.profileType || undefined,
      expert_states: query.expertStates,
      expert_history: query.expertHistory,
      countries: query.countries,
      languages: query.languages,
      industries: query.industries,
      agents: query.agentIds,
      networks: query.networkIds,
      sharing_groups: query.sharingGroupIds,
      organizations_restrict: ignoreNone(query.organizationsCurrentRestriction),
      locations_restrict: ignoreNone(query.locationsCurrentRestriction),
      job_titles_restrict: ignoreNone(query.jobTitlesCurrentRestriction),
      organizations: query.organizations && query.organizations.map(encodeDateBoundString),
      locations: query.locations && query.locations.map(encodeDateBoundString),
      job_titles: query.jobTitles && query.jobTitles.map(encodeDateBoundString),
      consultations_with: query.consultationsWith,
      education_degrees: query.educationDegrees,
      education_fos: query.educationFos,
      group_keyword_ids: query.groupKeywordIds,
      keywords: query.keywords,
      marketplace_preference: ignoreNone(query.marketplacePreference),
    },
    {
      arrayFormat: 'bracket',
    }
  );
}

function encodeDateBoundString(obj: any) {
  const startYear = obj.start_date ? obj.start_date.getFullYear() : '';
  const endYear = obj.end_date ? obj.end_date.getFullYear() - 1 : '';
  return dateBoundStringKey(obj.text, startYear, endYear);
}

function dateBoundStringKey(text: any, startYear: any, endYear: any) {
  if (!startYear && !endYear) return text;
  if (!endYear) return `${text}:${startYear}`;
  return `${text}:${startYear}:${endYear}`;
}

function ignoreNone(value: any) {
  return value === 'none' ? undefined : value;
}
