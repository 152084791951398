import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment-timezone';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from '@/components/Button/Button';
import CircularProgress from '@/components/CircularProgress';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconButton from '@/components/IconButton/IconButton';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import { deleteProfile, fetchConflicts } from '@/profile/store';
import { darkGray } from '@/theme/colors';

import s from './ProfileConflicts.module.scss';

class ProfileConflicts extends PureComponent {
  handleDiscardConflict = ({ conflicting }: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'deleteProfile' does not exist on type 'R... Remove this comment to see the full error message
    const { deleteProfile } = this.props;
    deleteProfile(conflicting);
  };

  handleLoadNextPage = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'pageCursor' does not exist on type 'Read... Remove this comment to see the full error message
    const { pageCursor, fetchConflicts } = this.props;
    fetchConflicts(pageCursor);
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'conflicts' does not exist on type 'Reado... Remove this comment to see the full error message
    const { conflicts, loading, pageCursor } = this.props;
    return (
      <LayoutPage showNav>
        <div className={s.header}>Profile Merge Conflicts</div>
        <Conflicts conflicts={conflicts} discardConflict={this.handleDiscardConflict} />
        {loading && (
          <div className={s.progress}>
            <CircularProgress size={50} />
          </div>
        )}
        {pageCursor && !loading && (
          <div className={s.loadMore}>
            <Button onClick={this.handleLoadNextPage}>Load More</Button>
          </div>
        )}
      </LayoutPage>
    );
  }
}

function Conflicts({ conflicts, discardConflict }: any) {
  const headerStyle = {
    paddingLeft: 0,
    paddingRight: 15,
    fontSize: 16,
    color: darkGray,
  };
  const rowStyle = { padding: '15px 15px 15px 0px' };

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Table selectable={false}>
      <TableHeader>
        <TableRow>
          <TableCell style={headerStyle}>Profile</TableCell>
          <TableCell style={headerStyle}>Conflicting Identifiers</TableCell>
          <TableCell style={headerStyle}>Existing</TableCell>
          <TableCell style={headerStyle}>Conflict</TableCell>
          <TableCell style={headerStyle}>Expert Request</TableCell>
          <TableCell style={{ ...headerStyle, paddingRight: 0, width: 50 }} />
        </TableRow>
      </TableHeader>
      <TableBody style={{ verticalAlign: 'top' }}>
        {conflicts.map((c: any) => {
          return (
            <TableRow key={`${c.conflicting.id}:${c.existing.id}`}>
              <TableCell style={rowStyle}>
                <Link className={s.profileLabel} to={c.existing.html_url}>
                  {c.existing.name}
                </Link>
              </TableCell>
              <TableCell style={rowStyle}>
                <div className={s.conflictingId}>
                  {(c.conflicting_ids || []).map((id: any) => (
                    <div key={id}>{id}</div>
                  ))}
                </div>
              </TableCell>
              <TableCell style={rowStyle}>
                <div className={s.nameLabel}>
                  {c.existing.user ? c.existing.name : c.existing.source}
                  <span className={s.dateLabel}>{formatDate(c.existing.created_at)}</span>
                </div>
              </TableCell>
              <TableCell style={rowStyle}>
                <div className={s.nameLabel}>
                  {c.conflicting.source}
                  <span className={s.dateLabel}>{formatDate(c.conflicting.created_at)}</span>
                </div>
              </TableCell>
              <TableCell style={rowStyle}>
                <div className={s.nameLabel}>
                  <a target="blank" href={`/expert_request/${c.conflicting.expert_request_id}`}>
                    {c.conflicting.expert_request_id}
                  </a>
                </div>
              </TableCell>
              <TableCell style={{ ...rowStyle, padding: '15px 0px', width: 50 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                  }}
                >
                  <Link to={`/merge/${c.existing.url_endpoint}/${c.conflicting.url_endpoint}`}>
                    <IconButton>
                      <FAIcon icon="code-fork" color={darkGray} size={20} />
                    </IconButton>
                  </Link>
                  <IconButton onClick={() => discardConflict(c)} style={{ marginLeft: 16 }}>
                    <MaterialIcon icon="delete" color={darkGray} />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  function formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD hh:mma');
  }
}

// @ts-expect-error TS(2629) FIXME: Cannot assign to 'ProfileConflicts' because it is ... Remove this comment to see the full error message
ProfileConflicts = connect(
  (state) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const stateConflicts = state.profiles.collections.conflicts;
    const { loading } = stateConflicts;
    const hasNextPage = stateConflicts.pageInfo && stateConflicts.pageInfo.hasNextPage;
    const pageCursor =
      hasNextPage && stateConflicts.edges.length
        ? stateConflicts.edges[stateConflicts.edges.length - 1].cursor
        : null;

    const conflictedProfiles = stateConflicts.edges.map((e: any) => e.node);
    const conflicts = conflictedProfiles.reduce((acc: any, conflicted: any) => {
      const conflicts = conflicted.conflicting.map((value: any) => ({
        conflicting_ids: value.conflicting_ids,

        existing: {
          ...value.profile,
          source: formatSource(value.profile),
        },

        conflicting: {
          ...conflicted,
          source: formatSource(conflicted),
        },
      }));
      return [...acc, ...conflicts];
    }, []);

    function formatSource(profile: any) {
      const source = profile.sources && profile.sources[0];
      if (!source) return;
      const {
        agent_id: agentId,
        agent_profile_id: agentProfileId,
        source_id: sourceId,
        source_profile_id: sourceProfileId,
      } = source;
      if (agentId && agentProfileId) return `${agentId}:${agentProfileId}`;
      if (sourceId && sourceProfileId) return `${sourceId}:${sourceProfileId}`;
      if (sourceId) return sourceId;
      if (agentId) return agentId;
    }

    // Example of resulting conflicts prop:
    // const conflicts = [{
    //   conflicting_ids: [
    //     'anne@onfrontiers.com',
    //     'linkedin.com/in/akroijer/',
    //     '12346634',
    //   ],
    //   existing: {
    //     id: '1639',
    //     type: 'user',
    //     name: 'Anne Kroijer',
    //     created_at: '2018-08-14T23:00:00.000Z',
    //   },
    //   conflicting: {
    //     id: '1638',
    //     type: 'lead',
    //     name: 'Anne Kroijer',
    //     created_at: '2018-08-14T08:00:00.000Z',
    //     source: 'linkedinhelper.csv',
    //   },
    // }, {
    //   conflicting_ids: [
    //     'anne@onfrontiers.com',
    //     'linkedin.com/in/akroijer/',
    //     '12346634',
    //   ],
    //   existing: {
    //     id: '1638',
    //     type: 'user',
    //     name: 'Anne Kroijer',
    //     created_at: '2018-08-14T08:00:00.000Z',
    //   },
    //   conflicting: {
    //     id: '1638',
    //     type: 'lead',
    //     name: 'Anne Kroijer',
    //     created_at: '2018-08-14T08:00:00.000Z',
    //     source: 'linkedinhelper.csv',
    //   },
    // }];

    return {
      loading,
      pageCursor,
      conflicts,
    };
  },
  {
    deleteProfile,
    fetchConflicts,
  }
)(ProfileConflicts);

export default ProfileConflicts;
