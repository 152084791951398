/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileOnboardingList } from '../models/PaginatedProfileOnboardingList';
import type { PatchedProfileOnboarding } from '../models/PatchedProfileOnboarding';
import type { ProfileOnboarding } from '../models/ProfileOnboarding';

export class ProfileOnboardingService {
  /**
   * List all Profile Onboading tasks
   * List all Profile Onboading tasks
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @returns PaginatedProfileOnboardingList
   * @throws ApiError
   */
  public static profileOnboardingList(
    limit?: number,
    offset?: number
  ): CancelablePromise<PaginatedProfileOnboardingList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_onboarding/',
      query: {
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * Create a Profile Onboading task
   * Create a Profile Onboading task
   * @param requestBody
   * @returns ProfileOnboarding
   * @throws ApiError
   */
  public static profileOnboardingCreate(
    requestBody: ProfileOnboarding
  ): CancelablePromise<ProfileOnboarding> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile_onboarding/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Profile Onboading task by UUID
   * Retrieve a Profile Onboading task by UUID
   * @param id A UUID string identifying this profile onboarding.
   * @returns ProfileOnboarding
   * @throws ApiError
   */
  public static profileOnboardingRetrieve(id: string): CancelablePromise<ProfileOnboarding> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_onboarding/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Profile Onboading task by UUID
   * Completely update a Profile Onboading task by UUID
   * @param id A UUID string identifying this profile onboarding.
   * @param requestBody
   * @returns ProfileOnboarding
   * @throws ApiError
   */
  public static profileOnboardingUpdate(
    id: string,
    requestBody: ProfileOnboarding
  ): CancelablePromise<ProfileOnboarding> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile_onboarding/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Profile Onboading task by UUID
   * Update specific fields within a Profile Onboading task by UUID
   * @param id A UUID string identifying this profile onboarding.
   * @param requestBody
   * @returns ProfileOnboarding
   * @throws ApiError
   */
  public static profileOnboardingPartialUpdate(
    id: string,
    requestBody?: PatchedProfileOnboarding
  ): CancelablePromise<ProfileOnboarding> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_onboarding/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Profile Onboading task by UUID
   * Delete a Profile Onboading task by UUID
   * @param id A UUID string identifying this profile onboarding.
   * @returns void
   * @throws ApiError
   */
  public static profileOnboardingDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile_onboarding/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Update the progress of an onboarding task
   * Update the progress of an onboarding task based given the profile id and the slug
   * @param slug Slug of the onboarding task
   * @param requestBody
   * @returns ProfileOnboarding
   * @throws ApiError
   */
  public static profileOnboardingUpdateProgressPartialUpdate(
    slug: string,
    requestBody?: PatchedProfileOnboarding
  ): CancelablePromise<ProfileOnboarding> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_onboarding/update_progress/{slug}/',
      path: {
        slug: slug,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
