import React from 'react';

import Filter from './Filter';
import FilterAdaptiveSelect from './FilterAdaptiveSelect';

export default function SelectFilter({ title, hideDivider, ...other }: any) {
  return (
    <Filter title={title} hideDivider={hideDivider}>
      <FilterAdaptiveSelect {...other} />
    </Filter>
  );
}
