import React from 'react';
import { reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form';

import Dialog from '@/components/Dialog/Dialog';

interface ConfirmCallProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText: string;
  title?: string;
  confirmLabel?: string;
}

class ConfirmCall extends React.Component<
  ConfirmCallProps & InjectedFormProps<{}, ConfirmCallProps>
> {
  render() {
    const {
      open,
      handleSubmit,
      onCancel,
      onConfirm,
      confirmText,
      title = 'Confirm Call',
      confirmLabel = 'Yes, Accept Call',
    } = this.props;

    return (
      <Dialog
        open={open}
        title={title}
        subTitle={confirmText}
        subTitleStyle={{ marginTop: '20px' }}
        confirmLabel={confirmLabel}
        onClose={onCancel}
        onCancel={onCancel}
        onConfirm={handleSubmit(onConfirm)}
      />
    );
  }
}

export default reduxForm<{}, ConfirmCallProps>({
  form: 'confirmCall',
})(ConfirmCall);
