import moment from 'moment-timezone';

import { gengql } from '@/__generated__';
import { ExpertRequestType } from '@/__generated__/graphql';
import { AppThunk } from '@/store';
import { shouldResetCollection } from '@/utils';

import { Candidate } from '..';
import {
  ADD_EXPERT_REQUEST_CANDIDATES,
  CREATE_EXPERT_REQUEST,
  GET_EXPERT_REQUEST,
  GET_EXPERT_REQUEST_CANDIDATES,
  REQUEST_ADD_EXPERT_REQUEST_CANDIDATE,
  UPDATE_EXPERT_REQUEST,
  UPDATE_EXPERT_REQUEST_CANDIDATE,
} from './queries';

export const EXPERT_REQUEST__UPDATE = 'EXPERT_REQUEST__UPDATE';
export const EXPERT_REQUEST__DELETE = 'EXPERT_REQUEST__DELETE';
export const EXPERT_REQUEST__ADD_CANDIDATE = 'EXPERT_REQUEST__ADD_CANDIDATE';
export const EXPERT_REQUEST__FETCHED_INIT_CANDIDATES = 'EXPERT_REQUEST__FETCHED_INIT_CANDIDATES';
export const EXPERT_REQUEST__FETCHED_MORE_CANDIDATES = 'EXPERT_REQUEST__FETCHED_MORE_CANDIDATES';
export const EXPERT_REQUEST__FETCHING_CANDIDATES = 'EXPERT_REQUEST__FETCHING_CANDIDATES';
export const EXPERT_REQUEST__REMOVE_CANDIDATE = 'EXPERT_REQUEST__REMOVE_CANDIDATE';
export const EXPERT_REQUEST__UPDATE_CANDIDATE = 'EXPERT_REQUEST__UPDATE_CANDIDATE';
export const EXPERT_REQUEST__LIST = 'EXPERT_REQUEST__LIST';
export const EXPERT_REQUEST__LIST_LOADING = 'EXPERT_REQUEST__LIST_LOADING';
export const EXPERT_REQUEST__LIST_LOADED = 'EXPERT_REQUEST__LIST_LOADED';

export const ER_TYPE_LABELS = Object.freeze({
  [ExpertRequestType.Consultation]: 'Consultation',
  [ExpertRequestType.NewHire]: 'New Hire',
  [ExpertRequestType.ConsultingProject]: 'Consulting Project',
  [ExpertRequestType.WrittenReview]: 'Written Review',
  [ExpertRequestType.ProBonoConsultation]: 'Pro Bono', // legacy
});

export const isWorkType = (type: ExpertRequestType): boolean =>
  [ExpertRequestType.NewHire, ExpertRequestType.ConsultingProject].includes(type);

export const isUnpaidType = (type: ExpertRequestType): boolean =>
  [ExpertRequestType.NewHire, ExpertRequestType.ConsultingProject].includes(type);

export const isAttachmentDeliverableType = (type: ExpertRequestType): boolean =>
  [ExpertRequestType.WrittenReview].includes(type);

export const isOpportunityType = (type: ExpertRequestType): boolean =>
  [ExpertRequestType.ConsultingProject, ExpertRequestType.NewHire].includes(type);

export const isCallType = (type: ExpertRequestType): boolean =>
  [
    ExpertRequestType.Consultation,
    ExpertRequestType.ConsultingProject,
    ExpertRequestType.NewHire,
  ].includes(type);

export const isFixedRate = (type: ExpertRequestType): boolean => {
  return [
    ExpertRequestType.ConsultingProject,
    ExpertRequestType.NewHire,
    ExpertRequestType.WrittenReview,
  ].includes(type);
};

export const isSetExpectedDurationType = (type: ExpertRequestType): boolean =>
  [ExpertRequestType.WrittenReview].includes(type);

export const candidateCategories = {
  matched: [
    'polishing',
    'vetting',
    'verified',
    'matched',
    'rejected_by_client',
    'rejected_by_research',
  ],
  suggested: [
    'suggested_by_platform',
    'suggested_by_research',
    'contacted',
    'interested',
    'rejected_suggestion',
  ],
};

export function saveExpertRequest(
  expertRequest: any,
  {
    fetchProject = false,
    includeQueries = true,
    includeAttachments = true,
    includeAdminFields = true,
  } = {}
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const isUpdate = expertRequest.id;
    const mutationName = isUpdate ? 'updateExpertRequest' : 'createExpertRequest';
    const mutation = isUpdate ? UPDATE_EXPERT_REQUEST : CREATE_EXPERT_REQUEST;

    const params = {
      id: expertRequest.id,
      name: expertRequest.name,
      includeProject: fetchProject,
      description: expertRequest.description?.trim(),
      disclosure: expertRequest.disclosure,
      focus_areas: expertRequest.focus_areas,
      project_id: expertRequest.project_id,
      phone: expertRequest.phone,
      companies_avoid: expertRequest.companies_avoid.filter((x: any) => x && x.trim()),
      companies_pursue: expertRequest.companies_pursue.filter((x: any) => x?.trim()),
      regions: expertRequest.regions.map((x: any) => x.id),
      sectors: expertRequest.sectors.map((x: any) => x.id),
      er_type: expertRequest.er_type,
      instructions_research: expertRequest.instructions_research?.trim(),
      group_about: expertRequest.group_about?.trim(),
      job_scope: expertRequest.job_scope?.trim(),
      expected_duration: expertRequest.expected_duration,
      opportunity_location: expertRequest.opportunity_location,
    };

    if (includeAdminFields) {
      // @ts-expect-error TS(2339): Property 'tags' does not exist on type '{ id: any;... Remove this comment to see the full error message
      params.tags = expertRequest.tags;
      // @ts-expect-error TS(2339): Property 'time_done_scoping_call' does not exist o... Remove this comment to see the full error message
      params.time_done_scoping_call = expertRequest.time_done_scoping_call
        ? expertRequest.time_done_scoping_call
        : '0001-01-01T00:00:00Z';
    }

    // they share same permission
    if (includeQueries) {
      // @ts-expect-error TS(2339): Property 'qualifications' does not exist on type '... Remove this comment to see the full error message
      params.qualifications = expertRequest.qualifications?.filter((q: any) => q?.query?.trim());
      // @ts-expect-error TS(2339): Property 'questions' does not exist on type '{ id:... Remove this comment to see the full error message
      params.questions = expertRequest.questions?.filter((q: any) => q?.query?.trim());
    }

    const { data } = await graphql.client.mutate({ mutation, variables: params });
    // @ts-expect-error
    const updatedExpertRequest = { ...expertRequest, ...data[mutationName] };

    if (includeAttachments) {
      const attachments = expertRequest.attachments || [];
      const newAttachments = await dispatch(
        updateAttachments(
          updatedExpertRequest.id,
          attachments
            .filter((a: any) => !('id' in a))
            .map((a: any) => {
              return {
                name: a.name,
                description: a.description,
                file_url: a.file_url,
                hide_from_experts: a.hide_from_experts,
              };
            })
        )
      );
      const existingAttachments = attachments.filter((a: any) => 'id' in a);
      updatedExpertRequest.attachments = [...existingAttachments, ...newAttachments];
    }

    dispatch({
      type: EXPERT_REQUEST__UPDATE,
      expertRequest: updatedExpertRequest,
    });

    if (isUpdate) {
      dispatch(fetchExpertRequestCandidates(updatedExpertRequest.id, 'suggested', true));
    }

    return updatedExpertRequest;
  };
}

function updateAttachments(expertRequestId: any, attachments: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const newAttachments = attachments
      .filter((a: any) => !('id' in a))
      .map((a: any) => {
        return {
          name: a.name,
          description: a.description,
          file_url: a.file_url,
          hide_from_experts: a.hide_from_experts,
        };
      });
    const results = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateAttachments(
          $expertRequestId: String!
          $attachments: [EngagementAttachmentInput]!
        ) {
          requestAddAttachments(expert_request_id: $expertRequestId, attachments: $attachments) {
            id
            created_at
            author {
              id
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
            hide_from_experts
          }
        }
      `),
      {
        expertRequestId,
        attachments: newAttachments,
      }
    );
    return results.requestAddAttachments;
  };
}

export function deleteExpertRequest(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDeleteExpertRequest($id: String!) {
          deleteExpertRequest(id: $id) {
            id
            project {
              id
            }
          }
        }
      `),
      { id }
    );

    if (data?.deleteExpertRequest) {
      const { project } = data.deleteExpertRequest;
      dispatch({
        type: EXPERT_REQUEST__DELETE,
        projectId: project?.id,
        id,
      });
    }

    return data;
  };
}

export function updateExpertRequestState(
  id: any,
  state: any,
  closeReason: any
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateExpertRequestState(
          $id: String!
          $state: ExpertRequestState!
          $close_reason: String
        ) {
          updateExpertRequestState(id: $id, state: $state, close_reason: $close_reason) {
            id
            state
            project {
              id
            }
            close_reason
          }
        }
      `),
      { id, state, close_reason: closeReason }
    );

    if (result.updateExpertRequestState) {
      const { id, state, project } = result.updateExpertRequestState;
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        projectId: project?.id,
        expertRequest: { id, state },
      });
    }
  };
}

export function fetchBaseExpertRequest(id: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchBaseExpertRequest($id: String!) {
          expertRequest(id: $id) {
            id
            name
            er_type
            description
            focus_areas
            qualifications {
              id
              query
              response_type
              required
            }
            questions {
              id
              query
              response_type
              required
            }
            attachments {
              id
              created_at
              author {
                id
                name
                html_url
              }
              expert_request_id
              consultation_id
              name
              description
              file_url
              hide_from_experts
            }
            companies_pursue
            companies_avoid
            disclosure
            regions {
              id
              name
            }
            sectors {
              id
              name
            }
            tags
            expected_duration
            project {
              id
            }
            phone
          }
        }
      `),
      { id }
    );

    return result.expertRequest;
  };
}

export function fetchExpertRequest(id: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    for (const ger of getState().expertRequests.default.edges || []) {
      if (ger.node.id !== id) continue;
      if (!ger.expiresAt || moment().isSameOrAfter(ger.expiresAt)) continue;
      return ger.node;
    }

    const result = await graphql.query(GET_EXPERT_REQUEST, { expertRequestId: id });

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
      return expertRequest;
    }
  };
}

export function fetchExpertRequestCandidates(
  id: string,
  type: 'matched' | 'suggested',
  reset?: boolean,
  cursor?: string,
  pageSize = 10
): AppThunk<Promise<any>> {
  const states = candidateCategories[type];
  return async (dispatch, _getState, { graphql }) => {
    dispatch({
      type: EXPERT_REQUEST__FETCHING_CANDIDATES,
      expertRequestId: id,
    });
    const result = await graphql.query(GET_EXPERT_REQUEST_CANDIDATES, {
      expertRequestId: id,
      states,
      first: pageSize,
      after: cursor,
    });
    const { expertRequestCandidates } = result;

    dispatch({
      type: reset
        ? EXPERT_REQUEST__FETCHED_INIT_CANDIDATES
        : EXPERT_REQUEST__FETCHED_MORE_CANDIDATES,
      expertRequestId: id,
      candidates: expertRequestCandidates,
      candidateType: type,
    });
  };
}

export function fetchPublicExpertRequest(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchPublicExpertRequest($id: String!) {
          expertRequest(id: $id) {
            id
            html_url
            created_at
            name
            description
            focus_areas
            qualifications {
              id
              query
              response_type
              required
            }
            questions {
              id
              query
              response_type
              required
            }
            state
            slug
            public_html_url
            regions {
              id
              name
            }
            sectors {
              id
              name
            }
            project {
              id
              group {
                id
                name
              }
            }
            public_group_name
            group_about
            er_type
            job_scope
            opportunity_location
            unpaid
            disclosure
          }
        }
      `),
      { id }
    );

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
      return expertRequest;
    }
  };
}

export function fetchExpertRequests({
  groupId,
  memberOnly,
  withGroupOnly = true,
  state,
  collection = state || 'all',
}: any = {}): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const expertRequests = getState().expertRequests[collection];
    if (!shouldResetCollection(expertRequests, 1000)) return expertRequests;

    const { viewer } = getState();

    const data = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchExpertRequests(
          $memberOnly: Boolean
          $state: ExpertRequestState
          $groupId: String
          $withGroupOnly: Boolean
        ) {
          expertRequests(
            first: 200
            member_only: $memberOnly
            with_group_only: $withGroupOnly
            state: $state
            group_id: $groupId
          ) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                html_url
                name
                state
                disclosure
                er_type
                project {
                  id
                  group {
                    id
                    name
                    account_type
                  }
                }
              }
            }
          }
        }
      `),
      {
        groupId,
        memberOnly: memberOnly || !viewer.admin,
        withGroupOnly,
        state,
      }
    );

    const page = data.expertRequests;

    dispatch({
      type: EXPERT_REQUEST__LIST,
      collection,
      reset: true,
      ...page,
    });

    return page;
  };
}

export function fetchExpertRequestMembers(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchExpertRequestMembers($id: String!) {
          expertRequest(id: $id) {
            id
            er_type
            name
            permissions
            disclosure
            project {
              id
              tracking_code
              group {
                id
                name
              }
              members {
                id
                role
                state
                user {
                  id
                  username
                  name
                }
              }
            }
          }
        }
      `),
      { id }
    );

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
    }
    return expertRequest;
  };
}

export function fetchProfileCandidates(id: string): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchProfileCandidates($id: String!) {
          profileCandidates(id: $id) {
            id
            request_id
          }
        }
      `),
      { id }
    );

    return result.profileCandidates;
  };
}

export function fetchCandidate(expertRequestId: string, profileId: string): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchCandidate($expertRequestId: String!, $profileId: String!) {
          profileCandidate(profile_id: $profileId, expert_request_id: $expertRequestId) {
            id
            credit_rate
            show_engagement_agreement
          }
        }
      `),
      { expertRequestId, profileId }
    );

    return result.profileCandidate;
  };
}

export function addExpertRequestCandidates({
  expertRequestId,
  dryRun = true,
  sendInvitationEmail,
  invitationEmailSubject,
  invitationEmailBody,
  profileIds,
  state,
}: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(ADD_EXPERT_REQUEST_CANDIDATES, {
      expertRequestId,
      dryRun,
      sendInvitationEmail,
      invitationEmailSubject,
      invitationEmailBody,
      profileIds,
      state,
    });

    const data = result.addExpertRequestCandidates;

    if (!dryRun && data && data.success) {
      dispatch({
        type: EXPERT_REQUEST__ADD_CANDIDATE,
        expertRequestId,
        candidates: data.results!.map((r) => r!.candidate),
      });
    }
  };
}

export function requestAddExpertRequestCandidate(candidate: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(REQUEST_ADD_EXPERT_REQUEST_CANDIDATE, candidate);

    return result.requestAddExpertRequestCandidate;
  };
}

export function updateExpertRequestCandidate(
  expertRequestId: string,
  candidate: Candidate
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: UPDATE_EXPERT_REQUEST_CANDIDATE,
      variables: candidate,
    });

    const updated = data!.updateExpertRequestCandidate;

    dispatch({
      type: EXPERT_REQUEST__UPDATE_CANDIDATE,
      expertRequestId,
      candidate: {
        ...candidate,
        ...updated,
      },
    });
  };
}

export function removeExpertRequestCandidate(
  expertRequestId: any,
  candidate: Candidate
): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        gengql(/* GraphQL */ `
          mutation actionRemoveExpertRequestCandidate($id: String!) {
            removeExpertRequestCandidate(id: $id) {
              id
              email
            }
          }
        `),
        { id: candidate.id }
      )
      .then(() =>
        dispatch({
          type: EXPERT_REQUEST__REMOVE_CANDIDATE,
          expertRequestId,
          candidate,
        })
      );
}
