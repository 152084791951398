/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BatchCreateUpdateResponse } from '../models/BatchCreateUpdateResponse';
import type { ExtractorResponse } from '../models/ExtractorResponse';

export class BatchCreateOrUpdateService {
  /**
   * Bulk create/update objects based on serializers' logic
   * Bulk create/update objects based on serializers' logic
   * @param requestBody
   * @returns BatchCreateUpdateResponse
   * @throws ApiError
   */
  public static batchCreateOrUpdateCreate(
    requestBody?: ExtractorResponse
  ): CancelablePromise<BatchCreateUpdateResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/batch_create_or_update/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
