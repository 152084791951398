/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Me } from '../models/Me';
import type { MeHistory } from '../models/MeHistory';
import type { MeHistoryResponse } from '../models/MeHistoryResponse';
import type { PatchedProfileUpdate } from '../models/PatchedProfileUpdate';

export class MeService {
  /**
   * Get user basic information for a specific profile.
   * Get user basic information for a specific profile
   * @returns Me
   * @throws ApiError
   */
  public static meRetrieve(): CancelablePromise<Me> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/me/',
    });
  }

  /**
   * Save multiple entries for profile history for the logged in user.
   * Save multiple entries for profile history for the logged in user
   * @param requestBody
   * @returns MeHistoryResponse
   * @throws ApiError
   */
  public static meHistoryCreate(
    requestBody: Array<MeHistory>
  ): CancelablePromise<MeHistoryResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/me/history/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Save user basic information for a specific profile.
   * Save user basic information for a specific profile
   * @param requestBody
   * @returns Me
   * @throws ApiError
   */
  public static meUpdatePartialUpdate(requestBody?: PatchedProfileUpdate): CancelablePromise<Me> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/me/update/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
