import cx from 'classnames';
import { MouseEvent, useCallback, useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import Button from '../Button/Button';
import CircularProgress from '../CircularProgress';
import s from './LongList.module.scss';

interface LongListProps {
  entityName?: string;
  entityNamePlural?: string;
  total?: number;
  title?: string;
  children?: React.ReactNode;
  loading?: boolean;
  onMore?: () => void;
  hasNextPage: boolean;
  containerClassName?: string;
  className?: string;
  action?: React.ReactNode;
  infiniteScroll?: boolean;
}

const LongList = ({
  entityName,
  entityNamePlural,
  total,
  title,
  children,
  loading,
  onMore,
  hasNextPage,
  containerClassName,
  className,
  action,
  infiniteScroll,
}: LongListProps) => {
  const renderedTitle = useMemo(() => {
    if (title) return title;
    if (loading || loading === undefined) return '';
    if (!children || (Array.isArray(children) && !children.length))
      return `No ${entityNamePlural} Found`;
    if (total === 1) return `1 ${entityName} Found`;
    if (!total) return `${entityNamePlural} Found`;
    return `${total.toLocaleString('en-US')} ${entityNamePlural} Found`;
  }, [children, entityName, entityNamePlural, loading, title, total]);

  const handleWaypointMore = useCallback(
    (_args: Waypoint.CallbackArgs) => {
      if (onMore) onMore();
    },
    [onMore]
  );

  const handleButtonMore = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (onMore) onMore();
    },
    [onMore]
  );

  return (
    <div className={cx(className && { [className]: true })}>
      <div className={s.header}>
        <h3 className={s.title}>{renderedTitle}</h3>
        {action}
      </div>

      <div className={cx(containerClassName && { [containerClassName]: true })}>{children}</div>

      {hasNextPage &&
        !loading &&
        (infiniteScroll ? (
          <Waypoint onEnter={handleWaypointMore} />
        ) : (
          <div className={s.loadMore}>
            <Button onClick={handleButtonMore}>Load More</Button>
          </div>
        ))}

      {loading && (
        <div className={s.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default LongList;
