import { useQuery } from '@tanstack/react-query';

import {
  CancelablePromise,
  KnowledgeRawTermService,
  PaginatedAvailableKnowledgeList,
} from '@/openapi';

export const DEFAULT_KNOWLEDGE_LIMIT = 1000;

type FetchExperienceKnowledge = {
  jobId: string;
  page?: number;
  limit?: number;
};

type UseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchExperienceKnowledgeByJobId = ({
  jobId,
  page = 1,
  limit = DEFAULT_KNOWLEDGE_LIMIT,
}: FetchExperienceKnowledge): CancelablePromise<PaginatedAvailableKnowledgeList> => {
  const offset = (page - 1) * limit;
  return KnowledgeRawTermService.knowledgeRawTermList(jobId, limit, offset);
};

export const useExperienceKnowledge = (
  { jobId, page, limit }: FetchExperienceKnowledge,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ['experience-knowledge', { jobId, page }],
    queryFn: () => fetchExperienceKnowledgeByJobId({ jobId, page, limit }),
    keepPreviousData: true,
    enabled: !!jobId,
    ...options,
  });
};
