import clsx from 'clsx';
import { Edit2, Trash2 } from 'react-feather';
import { Navigate, useSearchParams } from 'react-router-dom';

import Button from '@/componentsv2/Button';
import { Drawer } from '@/componentsv2/Drawer';
import { SEGMENTS_KNOWLEDGE_TYPE_DICT_REVERSE } from '@/dashboardv2/components/KnowledgeTable';
import { useSelf } from '@/hooks/store';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import { KnowledgeTypeToCompleteType } from '@/knowledge/addKnowledge';
import { KnowledgeEnumType, KnowledgeType } from '@/knowledge/queries/knowledge';
import { ProfileKnowledgeScore } from '@/openapi';
import ExperiencesByKnowledgeBase from '@/profilev2/components/ExperiencesByKnowledge/Base';
import {
  selectKnowledge as openKnowledgeDrawer,
  showDrawer as showAddKnowledgeDrawer,
} from '@/profilev2/store/addKnowledgeSlice';
import { useAppDispatch } from '@/store';
import { showDialog } from '@/ui/store/uiSlice';

import KnowledgeInfo from '../KnowledgeInfo';
import KnowledgeRankList from '../KnowledgeRankList';

const DetailsDrawerBase = ({
  breadcrumbs,
  knowledgeData,
  type,
  isFetched,
  isLoading,
  disableEditing,
}: {
  breadcrumbs: { title: string; to?: string }[];
  knowledgeData?: ProfileKnowledgeScore;
  type: 'location' | 'knowledge';
  isFetched: boolean;
  isLoading?: boolean;
  disableEditing?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isSelf = useSelf();
  const handleCloseDrawer = useCloseDrawer();
  const [searchParams] = useSearchParams();

  const parsedKnowledgeName = decodeURIComponent(searchParams.get('q') ?? '');
  const parsedRedirectedFromName = decodeURIComponent(searchParams.get('r') ?? '');

  if (!parsedKnowledgeName || (isFetched && !knowledgeData)) {
    return <Navigate to=".." replace />;
  }

  return (
    <>
      <Drawer.Header
        onClose={handleCloseDrawer}
        breadcrumbs={breadcrumbs}
        slotActions={
          isSelf && !disableEditing ? (
            <div className="flex gap-10">
              {type === 'knowledge' ? (
                <Button
                  variant="secondary"
                  size="small"
                  startIcon={Trash2}
                  onClick={() => {
                    dispatch(showDialog('DELETE_KNOWLEDGE'));
                  }}
                >
                  Delete
                </Button>
              ) : null}
              {knowledgeData?.type ? (
                <Button
                  size="small"
                  startIcon={Edit2}
                  onClick={() => {
                    dispatch(showAddKnowledgeDrawer());
                    dispatch(
                      openKnowledgeDrawer({
                        knowledge_id: knowledgeData.source.object_id,
                        type: KnowledgeTypeToCompleteType[knowledgeData.type as KnowledgeType],
                        name: knowledgeData.name ?? '',
                      })
                    );
                  }}
                >
                  Add Experiences
                </Button>
              ) : null}
            </div>
          ) : null
        }
      />
      <Drawer.Main paddingClassName="p-0">
        <div className="min-h-full lg:flex">
          <div className="grow p-20">
            <ExperiencesByKnowledgeBase
              knowledge={knowledgeData}
              knowledgeName={parsedKnowledgeName ?? ''}
              isLoading={isLoading}
              isLocation={type === 'location'}
              redirectedFrom={parsedRedirectedFromName}
            />
          </div>
          <aside
            className={clsx(
              'mx-20 mb-20 rounded-md border border-light-primary bg-white p-10 shadow-5',
              'lg:m-0 lg:w-[460px] lg:shrink-0 lg:rounded-0 lg:border-0 lg:border-l lg:border-grey-400 lg:shadow-none'
            )}
          >
            <h2 className="my-10 hd-6">More Info</h2>
            {knowledgeData ? (
              <KnowledgeInfo
                title={parsedKnowledgeName}
                knowledgeType={knowledgeData.knowledge_type || ''}
                knowledgeId={knowledgeData.source.object_id}
                description={knowledgeData.source.description}
                isLocation={type === 'location'}
                className="mb-16"
              />
            ) : null}
            <KnowledgeRankList
              knowledgeName={parsedKnowledgeName}
              knowledgeType={
                SEGMENTS_KNOWLEDGE_TYPE_DICT_REVERSE[
                  knowledgeData?.knowledge_type as KnowledgeEnumType
                ]
              }
              type={type}
            />
          </aside>
        </div>
      </Drawer.Main>
    </>
  );
};

export default DetailsDrawerBase;
