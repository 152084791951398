import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const getStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 15,
    paddingLeft: 15,
    maxWidth: 1170,
  },
}));

function Body({ className, children, ...other }: any) {
  const s = getStyles();
  return (
    <div className={clsx(s.root, className)} {...other}>
      {children}
    </div>
  );
}

export default Body;
