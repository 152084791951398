import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import SendbirdChat, { MetaData, SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';

import { gengql } from '@/__generated__';
import config from '@/config';
import { GraphQLClient } from '@/core/api';
import { Viewer } from '@/core/viewer';

interface AccessTokenData {
  accessToken: string;
  lastSeenAt: Date | null;
}

export function isAnonymousUser(metadata: MetaData, userId: string) {
  return metadata[`anonymous_${userId}`] === 'true';
}

export function channelUrlToId(channelUrl: string) {
  return channelUrl.replace('sendbird_group_channel_', '');
}

export function channelIdToUrl(channelId: string) {
  return `sendbird_group_channel_${channelId}`;
}

async function createSessionWithToken(
  userId: string,
  accessToken: string
): Promise<SendbirdChatWith<GroupChannelModule[]> | null> {
  if (!config.sendbirdAppId) {
    console.warn('Sendbird AppId is not set, messaging is disabled');
    return null;
  }

  const sb = SendbirdChat.init({
    appId: config.sendbirdAppId,
    modules: [new GroupChannelModule()],
  });

  await sb.connect(userId, accessToken);
  return sb;
}

const CREATE_USER_ACCESS_TOKEN = gengql(/* GraphQL */ `
  mutation createUserAccessToken {
    generateMessagingAccessToken {
      access_token
      last_seen_at
    }
  }
`);

function parseLastSeenAt(lastSeenAt: string | null | undefined) {
  if (typeof lastSeenAt !== 'string') return null;
  return new Date(parseInt(lastSeenAt));
}

export default class SendbirdHelper {
  private graphql: ApolloClient<NormalizedCacheObject>;
  private user: Viewer;
  private accessTokenPromise: Promise<AccessTokenData | null> | null;
  private sessionPromise: ReturnType<typeof createSessionWithToken> | null;

  constructor(user: Viewer, graphql: GraphQLClient) {
    this.user = user;
    this.graphql = graphql.client;
    this.accessTokenPromise = null;
    this.sessionPromise = null;
  }

  private getAccessTokenData() {
    let promise = this.accessTokenPromise;
    if (!promise) {
      promise = (async () => {
        const { data } = await this.graphql.mutate({ mutation: CREATE_USER_ACCESS_TOKEN });
        const token = data?.generateMessagingAccessToken;
        return {
          accessToken: token?.access_token as string,
          lastSeenAt: parseLastSeenAt(token?.last_seen_at),
        };
      })();
    }
    this.accessTokenPromise = promise;
    return promise;
  }

  getSession() {
    let promise = this.sessionPromise;
    if (!promise) {
      promise = (async () => {
        if (!this.user.id) return null;
        const tokenData = await this.getAccessTokenData();
        if (!tokenData) return null;
        return await createSessionWithToken(this.user.id, tokenData.accessToken);
      })();
    }
    this.sessionPromise = promise;
    return promise;
  }

  async isActiveUser(): Promise<boolean> {
    // Consider user active if has connected to Sendbird within 7 days
    const tokenData = await this.getAccessTokenData();
    if (!tokenData || !tokenData.lastSeenAt) return false;
    const threshold = 1000 * 60 * 60 * 24 * 7;
    return Date.now() - tokenData.lastSeenAt.getTime() < threshold;
  }
}
