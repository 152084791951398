import { TextField } from '@mui/material';
import { PureComponent } from 'react';

import { darkBrown } from '@/theme/colors';

import Dialog from '../Dialog';

export default class DeleteDialog extends PureComponent {
  state = {
    value: '',
  };

  render() {
    // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
    const { children, ...other } = this.props;
    const { value } = this.state;
    const disableSubmit = !value || value !== 'DELETE';
    return (
      <Dialog
        confirmLabel="Delete"
        cancelLabel="Cancel"
        disableSubmit={disableSubmit}
        confirmButtonProps={{ color: 'red' }}
        {...other}
        contentStyle={{ maxWidth: 450 }}
      >
        <p style={{ color: darkBrown, lineHeight: 1.5 }}>{children}</p>
        <p style={{ color: darkBrown }}>Type “DELETE” below to proceed.</p>
        <TextField
          value={this.state.value}
          onChange={({ target: { value } }) => this.setState({ value: value.trim().toUpperCase() })}
          style={{ width: 100 }}
        />
      </Dialog>
    );
  }
}
