import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { X } from 'react-feather';

import { userSelector } from '@/auth/store/userSlice';
import Box from '@/componentsv2/Box';
import Button from '@/componentsv2/Button';
import FormikField from '@/componentsv2/FormikField';
import FormikForm from '@/componentsv2/FormikForm';
import { segmentTracking } from '@/core/analytics';
import { SendEmailRequest, SendEmailService } from '@/openapi';
import { useAppSelector } from '@/store';

import styles, { sendEmailClasses } from './styles';
import { ISendEmailProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-send-email';

const SendEmail = ({
  className,
  recipientId,
  email,
  onClose = () => {},
  onSend = () => {},
  ...rest
}: ISendEmailProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userData } = useAppSelector(userSelector);
  const classProps = clsx(className, classes.sendEmail, sendEmailClasses.root);
  const isOpen = !!email;

  const formik = useFormik({
    initialValues: {
      email: email ?? '',
      message: '',
    },
    onSubmit: (values) => {
      SendEmailService.sendEmailCreate({
        to_email: values.email,
        body: values.message,
        subject: 'Reach out',
        sender_profile_id: userData?.id,
        recipient_profile_id: recipientId,
      } as SendEmailRequest)
        .then(() => {
          enqueueSnackbar('Email sent', { variant: 'success' });
          onSend();
          const segmentPayload = {
            event_type: 'Profile View',
            event_name: 'Sent Email',
            profile_ID: userData?.id,
          };
          segmentTracking('clicked-send-message-modal-button', segmentPayload);
        })
        .catch(() =>
          enqueueSnackbar('There was a problem while sending the email', {
            variant: 'error',
          })
        );
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const segmentPayload = {
      event_type: 'Profile View',
      event_name: 'Send Email',
      profile_ID: userData?.id,
    };
    segmentTracking('clicked-send-email', segmentPayload);
  }, [isOpen, userData?.id]);

  return (
    <Dialog
      className={classProps}
      onClose={onClose}
      fullWidth
      open={isOpen}
      maxWidth="md"
      data-testid={testId}
      {...rest}
    >
      <DialogTitle className={clsx(sendEmailClasses.header)}>
        <h2 className="hd-6">Send Email</h2>
        <Button variant="tertiary" size="large" startIcon={X} onClick={onClose} srText="Close" />
      </DialogTitle>
      <DialogContent className={sendEmailClasses.content} sx={{ minHeight: 150 }}>
        <FormikForm value={formik}>
          <Stack spacing={2}>
            <FormikField
              name="email"
              label="Email"
              placeholder="Type your email here"
              variant="filled"
              disabled
            />
            <FormikField
              label="Message"
              name="message"
              variant="filled"
              placeholder="Type your message here"
              multiline
              rows={3}
            />
          </Stack>
        </FormikForm>
      </DialogContent>
      <DialogActions className={sendEmailClasses.footer}>
        <Box className={sendEmailClasses.footerActions}>
          <Button
            className={sendEmailClasses.saveBtn}
            onClick={() => formik.handleSubmit()}
            disabled={false}
          >
            Send
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { sendEmailClasses, testId as SendEmailTestId };
export type { ISendEmailProps };
export default SendEmail;
