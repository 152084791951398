import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CancelablePromise,
  PaginatedProfileMutualExperienceList,
  Profile,
  ProfileService,
} from '@/openapi';

type FetchProfile = {
  id: string;
};

const fetchProfile = ({ id }: Omit<FetchProfile, 'profileId'>): CancelablePromise<Profile> => {
  return ProfileService.profileRetrieve(id);
};

export const useProfileQuery = ({ id }: FetchProfile) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['profile', { id }],
    queryFn: () => fetchProfile({ id }),
    enabled: !!id,
    placeholderData: () => {
      const cachedColleagues = queryClient.getQueriesData(['colleagues']) as [
        Array<unknown>,
        PaginatedProfileMutualExperienceList,
      ][];

      const cachedProjectLeaders = queryClient.getQueriesData(['project-leaders']) as [
        Array<unknown>,
        PaginatedProfileMutualExperienceList,
      ][];

      for (const [, data] of [...cachedColleagues, ...cachedProjectLeaders]) {
        const colleague = data?.results?.find((colleague) => colleague.id === id);
        if (colleague) {
          return colleague as Partial<Profile>;
        }
      }

      return undefined;
    },
  });
};
