import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import ExperienceFilter from '../ExperienceFilter';
import SelectFilter from '../SelectFilter';

interface Viewer {
  admin: boolean;
}

interface Group {
  id: string;
  name: string;
  internal_network: boolean;
}

interface Query {
  organizationsCurrentRestriction: string;
  organizations: string[];
  consultationsWith: string[];
  profileType: string;
  networkIds: string[];
}

interface AdvancedFiltersProps {
  groups: Group[];
  query: Query;
  handleFilterAdd: (field: string, value: string) => void;
  handleFilterRemove: (field: string, value: string) => void;
  handleFilterEvent: (field: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  viewer: Viewer;
}

function AdvancedFilters({
  groups,
  query,
  handleFilterAdd,
  handleFilterRemove,
  handleFilterEvent,
  viewer,
}: AdvancedFiltersProps) {
  const consultationHistoryOptions = useMemo(() => {
    const groupOptions = groups
      .filter((g) => viewer.admin || g.internal_network)
      .map((g) => ({
        value: g.id,
        label: `with ${g.name}`,
      }));

    return [{ value: 'me', label: 'with me' }, ...groupOptions];
  }, [groups, viewer]);

  const networkOptions = useMemo(() => {
    return groups.filter((g) => g.internal_network).map((g) => ({ label: g.name, value: g.id }));
  }, [groups]);

  return (
    <>
      <ExperienceFilter
        title="Organizations"
        placeholder="Enter organization"
        currentRestriction={query.organizationsCurrentRestriction}
        values={query.organizations}
        onCurrentRestrictionChange={(e) => handleFilterEvent('organizationsCurrentRestriction', e)}
        onAddValue={(v) => handleFilterAdd('organizations', v)}
        onRemoveValue={(v) => handleFilterRemove('organizations', v)}
      />

      <SelectFilter
        title="Consultation History"
        placeholder="Select teams or 'with me'"
        value={query.consultationsWith || []}
        options={consultationHistoryOptions}
        autocomplete={consultationHistoryOptions.length > 5}
        onAddValue={(v: string) => handleFilterAdd('consultationsWith', v)}
        onRemoveValue={(v: string) => handleFilterRemove('consultationsWith', v)}
        multiple
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleFilterEvent('consultationsWith', e)
        }
      />

      <SelectFilter
        title="Expert Registration"
        value={query.profileType}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterEvent('profileType', e)}
        options={[
          { value: '', label: 'Registered and Unregistered' },
          { value: 'user', label: 'Registered only' },
          { value: 'lead', label: 'Unregistered only' },
        ]}
      />

      <SelectFilter
        title="Network"
        placeholder="Search teams"
        value={query.networkIds}
        options={networkOptions}
        autocomplete
        onAddValue={(v: string) => handleFilterAdd('networkIds', v)}
        onRemoveValue={(v: string) => handleFilterRemove('networkIds', v)}
      />
    </>
  );
}

export default connect((state: { groups: { networks: any } }) => ({
  groups: ((state.groups.networks || {}).edges || []).map((g: { node: any }) => g.node),
}))(AdvancedFilters);
