import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import clsx from 'clsx';

interface RadioProps extends MuiRadioProps {
  fullWidth?: boolean;
  label?: string | number | React.ReactElement;
}

const Radio = (props: RadioProps) => {
  const radio = <MuiRadio data-testid="of-radio" {...props} />;

  if (props.label) {
    return (
      <FormControlLabel
        data-testid="of-radio-control"
        control={radio}
        label={props.label}
        componentsProps={{
          typography: {
            className: props.fullWidth ? 'grow' : '',
          },
        }}
        className={clsx({ 'block w-full': props.fullWidth })}
      ></FormControlLabel>
    );
  }
  return radio;
};

export default Radio;
