/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AccrualDiff } from '../models/AccrualDiff';

export class AccrualApiService {
  /**
   * Calculate and save accrual calculation for a specific profile.
   * Calculate and save accrual calculation for a specific profile.
   * @param profileId A UUID string identifying the profile to calculate and save accrual calculation
   * @param showDiff Show the difference between the old calculated scores vs the new ones
   * @returns AccrualDiff
   * @throws ApiError
   */
  public static accrualCalculateCreate(
    profileId: string,
    showDiff?: boolean
  ): CancelablePromise<Array<AccrualDiff>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accrual/calculate/',
      query: {
        profile_id: profileId,
        show_diff: showDiff,
      },
    });
  }
}
