import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Children, ReactNode, isValidElement, useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import Collapse from '@/componentsv2/Collapse';
import CollapseToggle from '@/componentsv2/CollapseToggle';
import { TagSize } from '@/componentsv2/Tag/types';
import { arrayFromRange } from '@/utils/array';

import styles, { tagGroupClasses, tagMargin } from './styles';
import { ITagGroupProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-tag-group';

export const tagHeights: Record<TagSize, number> = {
  sm: 28,
  md: 34,
};

export const Skeletons = ({ tagSize, number = 3 }: { tagSize: TagSize; number?: number }) => {
  const skeletonWidths = [100, 80, 180, 150];

  return (
    <>
      {arrayFromRange({ max: number }).map((i) => (
        <Skeleton
          key={i}
          component="li"
          variant="rectangular"
          width={skeletonWidths[i % 4]}
          height={tagHeights[tagSize]}
          sx={{ borderRadius: 1 }}
        />
      ))}
    </>
  );
};

const TagGroup = ({
  className,
  children,
  tagSize = 'md',
  isLoading,
  collapsible, // group will only show 1 row by default
  placeholder,
  showPlaceholder,
}: ITagGroupProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.tagGroup, tagGroupClasses.root);

  const [expanded, setExpanded] = useState(() => !collapsible);
  const [requiresExpansion, setRequiresExpansion] = useState(false);

  // Use extra element to track expanded height for determining
  // if expand button is necessary
  const { ref: heightRef, height = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    if (!collapsible || isLoading) {
      return;
    }

    const tallerThanOneRow = height > tagHeights[tagSize] + tagMargin;
    setRequiresExpansion(tallerThanOneRow);
  }, [collapsible, height, isLoading, tagSize]);

  const WrappedChildren = () => (
    <>
      {Children.map(children, (child: ReactNode) => {
        if (isValidElement(child)) {
          return <li>{child}</li>;
        }
      })}
    </>
  );

  return (
    <Box className={classProps}>
      {/* +2 for shadow */}
      <Collapse in={expanded} collapsedSize={tagHeights[tagSize] + 2}>
        <Box sx={{ position: 'relative' }}>
          <Box
            ref={heightRef}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
            }}
          />
          {showPlaceholder ? (
            placeholder
          ) : (
            <Stack
              className={tagGroupClasses.tags}
              component="ul"
              data-testid={testId}
              direction="row"
              flexWrap="wrap"
            >
              {isLoading ? <Skeletons tagSize={tagSize} /> : <WrappedChildren />}
            </Stack>
          )}
        </Box>
      </Collapse>
      {collapsible && requiresExpansion ? (
        <CollapseToggle
          className="mt-16"
          expanded={expanded}
          handleToggle={() => setExpanded(!expanded)}
        />
      ) : null}
    </Box>
  );
};

export { testId as TagGroupTestId, tagGroupClasses };
export type { ITagGroupProps };
export default TagGroup;
