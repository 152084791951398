/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { SendEmailRequest } from '../models/SendEmailRequest';
import type { SendEmailResponse } from '../models/SendEmailResponse';

export class SendEmailService {
  /**
   * Send Email
   * Send Email by contributor page
   * @param requestBody
   * @returns SendEmailResponse
   * @throws ApiError
   */
  public static sendEmailCreate(
    requestBody: SendEmailRequest
  ): CancelablePromise<SendEmailResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/send-email/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
