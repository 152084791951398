import { gengql } from '@/__generated__';

export const REQUEST_CONSULTATION = gengql(/* GraphQL */ `
  mutation actionRequestConsultation(
    $requester_id: String
    $expert_id: String!
    $profile_type: ProfileType
    $dates: [Datetime!]
    $duration: Duration
    $credits: MoneyInput
    $description: String
    $expert_request_id: String
    $group_id: String
    $disclosure: DisclosureOption
    $engagement_type: EngagementType!
    $tracking_code: String
  ) {
    requestConsultation(
      requester_id: $requester_id
      expert_id: $expert_id
      profile_type: $profile_type
      proposed_times: $dates
      expected_duration: $duration
      expected_credits: $credits
      description: $description
      expert_request_id: $expert_request_id
      group_id: $group_id
      disclosure: $disclosure
      engagement_type: $engagement_type
      tracking_code: $tracking_code
    ) {
      id
      html_url
    }
  }
`);

export const UPDATE_CONSULTATION = gengql(/* GraphQL */ `
  mutation actionUpdateConsultation(
    $id: String!
    $starts_at: Datetime
    $state: ConsultationState
    $expert_request_id: String
    $proposed_times: [Datetime!]
    $cancel_reason: String
    $duration: Duration
    $recording_url: String
    $expert_id: String
    $expert_name: String
    $requester_id: String
    $requester_name: String
    $started_at: Datetime
    $recording_duration: Duration
    $tracking_code: String
    $initial_expected_duration: Duration
    $bill_rate: Int
    $credit_rate: Int
    $trigger: ConsultationUpdateTrigger
  ) {
    updateConsultation(
      id: $id
      starts_at: $starts_at
      state: $state
      expert_request_id: $expert_request_id
      proposed_times: $proposed_times
      cancel_reason: $cancel_reason
      expected_duration: $duration
      recording_url: $recording_url
      expert_id: $expert_id
      expert_name: $expert_name
      requester_id: $requester_id
      requester_name: $requester_name
      started_at: $started_at
      recording_duration: $recording_duration
      tracking_code: $tracking_code
      initial_expected_duration: $initial_expected_duration
      bill_rate: $bill_rate
      credit_rate: $credit_rate

      trigger: $trigger
    ) {
      id
      starts_at
      started_at
      state
      archived
      expert_request {
        id
        er_type
        html_url
        name
        description
        focus_areas
        qualifications {
          id
          query
        }
        questions {
          id
          query
        }
        attachments {
          id
          created_at
          author {
            id
            name
            html_url
          }
          expert_request_id
          consultation_id
          name
          description
          file_url
        }
        companies_pursue
        companies_avoid
        disclosure
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        project {
          id
          name
          html_url
          tracking_code
          group {
            id
            name
          }
        }
        unpaid
      }
      proposed_times
      rejected_times
      cancel_reason
      expected_duration
      initial_expected_duration
      estimated_expert_payout
      requester {
        id
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
      }
      requester_timezone
      requester_name
      expert {
        id
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
        expert_state
        compliance_completed_at
        bill_rate
      }
      expert_timezone
      expert_name
      event {
        description
        summary
        location
        start
        end
      }
      original_recording_url
      recording_url
      recording_duration
      tracking_code
      permissions
      transcription_price {
        cents
        currency
      }
      attachments {
        id
        created_at
        author {
          id
          name
          html_url
        }
        expert_request_id
        consultation_id
        name
        description
        file_url
      }
      bill_rate
      credit_rate
      engagement_type
    }
  }
`);
