import InputAdornment from '@mui/material/InputAdornment';
import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

import { parseDuration } from '@/consultation';
import Duration from '@/core/duration';

import { TextField } from '../FormAdapters';

export default function ({ label, minHours = 0, maxHours, required, ...rest }: any) {
  const validate = useCallback(
    (value: any) => {
      if (!value && !required) {
        return;
      }

      const errorMessage = `Hours must be at least ${minHours}${
        maxHours ? ` and at most ${maxHours}` : ``
      }.`;

      const number = value && Number.parseInt(value.split('h').shift());

      if (!value || number < minHours || number > maxHours) {
        return errorMessage;
      }
    },
    [minHours, maxHours]
  );

  return (
    <Field
      component={TextField}
      label={label}
      inputType="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">Hour(s)</InputAdornment>,
      }}
      inputProps={{
        min: minHours,
        max: maxHours,
        style: { textAlign: 'left', fontSize: 16 },
      }}
      required={required}
      validate={validate}
      parse={(hours) => (hours && hours > 0 && Duration.parse(`${hours}h`).toString()) || null}
      format={(d) => (d && parseDuration(d).hours()) || null}
      {...rest}
    />
  );
}
