import DomainSelector from '@/auth/components/DomainSelector';
import LayoutPage from '@/components/Layout/LayoutPage';
import { LegacyRoute } from '@/routes/routesMiddleware';

const route: LegacyRoute = {
  path: '/select_domain',
  async action() {
    document.title = 'Select domain';
    return (
      <LayoutPage headerBorder={false}>
        <DomainSelector />
      </LayoutPage>
    );
  },
};

export default route;
