import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CancelablePromise, MajorService, PaginatedMajorList } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<PaginatedMajorList>;

export const fetchData = createAsyncThunk(
  'majors/fetchData',
  async ({ limit, offset, query }: { limit?: number; offset?: number; query?: string }) => {
    request?.cancel();
    request = MajorService.majorList(limit, offset, query);
    return request;
  }
);

const majorsSlice = createSlice({
  name: 'majors',
  initialState: initialState.majors,
  reducers: {
    updateData: (state, action: PayloadAction<PaginatedMajorList>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.majors,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
    }));
  },
});
export const majorsSelector = (state: IState) => state.majors;
export const { updateData, resetData } = majorsSlice.actions;
export default majorsSlice.reducer;
