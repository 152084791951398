/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedTitleRawTermList } from '../models/PaginatedTitleRawTermList';
import type { PatchedTitleRawTerm } from '../models/PatchedTitleRawTerm';
import type { TitleRawTerm } from '../models/TitleRawTerm';

export class TitleRawTermService {
  /**
   * List all Title Raw Terms
   * List all Title Raw Terms
   * @param hasWorkTitle Search only Title Raw Terms that has a taxonomy link to it.
   * @param isDiscoverable Search only Title Raw Terms that are discoverable
   * @param limit Number of results to return per page.
   * @param name Search the Title Raw Terms list by name
   * @param offset The initial index from which to return the results.
   * @param query The query in which you are searching for.
   * @returns PaginatedTitleRawTermList
   * @throws ApiError
   */
  public static titleRawTermList(
    hasWorkTitle?: boolean,
    isDiscoverable?: boolean,
    limit?: number,
    name?: string,
    offset?: number,
    query?: string
  ): CancelablePromise<PaginatedTitleRawTermList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/title_raw_term/',
      query: {
        has_work_title: hasWorkTitle,
        is_discoverable: isDiscoverable,
        limit: limit,
        name: name,
        offset: offset,
        query: query,
      },
    });
  }

  /**
   * Create a Title Raw Terms
   * Create a Title Raw Terms
   * @param requestBody
   * @returns TitleRawTerm
   * @throws ApiError
   */
  public static titleRawTermCreate(requestBody: TitleRawTerm): CancelablePromise<TitleRawTerm> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/title_raw_term/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Title Raw Term by UUID
   * Retrieve a Title Raw Term by UUID
   * @param id A UUID string identifying this title raw term.
   * @returns TitleRawTerm
   * @throws ApiError
   */
  public static titleRawTermRetrieve(id: string): CancelablePromise<TitleRawTerm> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/title_raw_term/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Title Raw Term by UUID
   * Completely update a Title Raw Term by UUID
   * @param id A UUID string identifying this title raw term.
   * @param requestBody
   * @returns TitleRawTerm
   * @throws ApiError
   */
  public static titleRawTermUpdate(
    id: string,
    requestBody: TitleRawTerm
  ): CancelablePromise<TitleRawTerm> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/title_raw_term/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Title Raw Term by UUID
   * Update specific fields within a Title Raw Term by UUID
   * @param id A UUID string identifying this title raw term.
   * @param requestBody
   * @returns TitleRawTerm
   * @throws ApiError
   */
  public static titleRawTermPartialUpdate(
    id: string,
    requestBody?: PatchedTitleRawTerm
  ): CancelablePromise<TitleRawTerm> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/title_raw_term/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Title Raw Term by UUID
   * Delete a Title Raw Term by UUID
   * @param id A UUID string identifying this title raw term.
   * @returns void
   * @throws ApiError
   */
  public static titleRawTermDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/title_raw_term/{id}/',
      path: {
        id: id,
      },
    });
  }
}
