import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { X } from 'react-feather';
import ReactJson from 'react-json-view';

import Button from '@/componentsv2/Button';

export interface IDebugingProps extends DialogProps {
  className?: string;
  onClose: () => void;
  open: boolean;
  dialogTitle?: string;
  id?: string;

  code: any;
}

const DebugDialog: React.FC<IDebugingProps> = ({
  className,
  open,
  dialogTitle,
  onClose,
  code,
}: IDebugingProps) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} className={className}>
      <>
        <DialogTitle className="flex items-center justify-between">
          {code?.full_name ? `${code?.full_name}'s Data` : dialogTitle}
          <Button variant="tertiary" size="large" srText="Close" startIcon={X} onClick={onClose} />
        </DialogTitle>
        <DialogContent className="min-h-[600px]">
          <ReactJson src={code} />
        </DialogContent>
      </>
    </Dialog>
  );
};

export default DebugDialog;
