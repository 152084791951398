import IMask from 'imask';
import moment from 'moment';

const momentFormat = 'MM/DD/YYYY';

export const dateMask = {
  mask: Date,
  pattern: momentFormat,
  lazy: false,
  min: new Date(1970, 0, 1),
  max: new Date(2030, 0, 1),
  format: function (date: Date) {
    return moment(date).format(momentFormat);
  },
  parse: function (str: string) {
    return moment(str, momentFormat);
  },
  placeholder: momentFormat,
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2030,
      placeholderChar: 'y',
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      placeholderChar: 'm',
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      placeholderChar: 'd',
    },
  },
};

const momentMonthFormat = 'MM/YYYY';
export const monthMask = {
  mask: Date,
  pattern: momentMonthFormat,
  lazy: false,
  min: new Date(1970, 0, 1),
  max: new Date(2030, 0, 1),
  format: function (date: Date) {
    return moment(date).format(momentMonthFormat);
  },
  parse: function (str: string) {
    return moment(str, momentMonthFormat);
  },
  placeholder: momentMonthFormat,
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2030,
      placeholderChar: 'y',
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      placeholderChar: 'm',
    },
  },
};
