/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Message } from '../models/Message';
import type { PaginatedSavedCollectionCollaboratorList } from '../models/PaginatedSavedCollectionCollaboratorList';
import type { PatchedSavedCollectionCollaborator } from '../models/PatchedSavedCollectionCollaborator';
import type { SavedCollectionCollaborator } from '../models/SavedCollectionCollaborator';
import type { SavedCollectionCollaboratorRequestCreate } from '../models/SavedCollectionCollaboratorRequestCreate';

export class SavedCollectionCollaboratorsService {
  /**
   * Get a collaborator for a saved collection by UUID and collaborator UUID
   * Get a collaborator for a saved collection by UUID and collaborator UUID. Only the collaborators of the saved collection can retrieve collaborators.
   * @param id A UUID string identifying this saved collection.
   * @param profileId The id of the profile
   * @returns SavedCollectionCollaborator
   * @throws ApiError
   */
  public static savedCollectionCollaboratorRetrieve(
    id: string,
    profileId: string
  ): CancelablePromise<SavedCollectionCollaborator> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/{id}/collaborator/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
    });
  }

  /**
   * Get the collaborators list for a saved collection
   * Get list of collaborator for a saved collection. Only collaborators can get the list of collaborators of private saved collections.
   * @param id A UUID string identifying this saved collection.
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param ordering Which field to use when ordering the results.
   * @returns PaginatedSavedCollectionCollaboratorList
   * @throws ApiError
   */
  public static savedCollectionCollectionCollaboratorList(
    id: string,
    limit?: number,
    offset?: number,
    ordering?: string
  ): CancelablePromise<PaginatedSavedCollectionCollaboratorList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/{id}/collection_collaborator/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
      },
    });
  }

  /**
   * Create a new collaborator for a saved collection
   * Create a new collaborator for a saved collection. Only collaborators of the saved collection can create collaborators.
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns Message
   * @throws ApiError
   */
  public static savedCollectionCreateCollectionCollaboratorCreate(
    id: string,
    requestBody: SavedCollectionCollaboratorRequestCreate
  ): CancelablePromise<Message> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_collection/{id}/create_collection_collaborator/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a collaborator for a saved collection by UUID and collaborator UUID
   * Delete a collaborator for a saved collection by UUID. Only collaborators of the saved collection can delete collaborators.
   * @param collaboratorId The id of the collaborator
   * @param id A UUID string identifying this saved collection.
   * @returns void
   * @throws ApiError
   */
  public static savedCollectionDeleteCollaboratorDestroy(
    collaboratorId: string,
    id: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_collection/{id}/delete_collaborator/{collaborator_id}/',
      path: {
        collaborator_id: collaboratorId,
        id: id,
      },
    });
  }

  /**
   * Update a collaborator for a saved collection by UUID and collaborator UUID
   * Update a collaborator for a saved collection by UUID. Only collaborators of the saved collection can update collaborators.
   * @param id A UUID string identifying this saved collection.
   * @param profileId The id of the profile
   * @param requestBody
   * @returns SavedCollectionCollaborator
   * @throws ApiError
   */
  public static savedCollectionUpdateCollectionCollaboratorPartialUpdate(
    id: string,
    profileId: string,
    requestBody?: PatchedSavedCollectionCollaborator
  ): CancelablePromise<SavedCollectionCollaborator> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/saved_collection/{id}/update_collection_collaborator/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
