const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];

export function sortMembers(members: any[]) {
  return [...members].sort((a: any, b: any) => {
    if (a.state !== b.state) {
      return a.state === 'denied' ? 1 : -1;
    }

    let diff = roles.indexOf(a.role) - roles.indexOf(b.role);
    if (diff === 0) {
      diff = members.indexOf(a) - members.indexOf(b);
    }
    return diff;
  });
}

export const ErrMemberAlreadyAdded = new Error('GraphQL Error: project member already added');
