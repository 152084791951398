import { darken, lighten } from '@mui/material/styles';
import cx from 'classnames';
import React, { Component } from 'react';

import { darkBrown } from '@/theme/colors';

import Logo from '../Logo';
import s from './MarketingHeader.module.scss';
import Nav from './MarketingNav';

interface MarketingHeaderProps {
  theme?: string;
  backgroundColor?: string;
  logo?: React.ReactNode;
  links?: any[];
}

class Header extends Component<MarketingHeaderProps> {
  state = {
    sticky: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleScroll);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScroll);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { sticky } = this.state;
    const shouldStick = window.pageYOffset > 200;
    if (sticky !== shouldStick) {
      this.setState({ sticky: shouldStick });
    }
  };

  render() {
    const {
      theme = 'dark',
      // @ts-expect-error TS(2339): Property 'selectedPath' does not exist on type 'Re... Remove this comment to see the full error message
      selectedPath,
      links = [],
      logo,
      // @ts-expect-error TS(2339): Property 'enableMobile' does not exist on type 'Re... Remove this comment to see the full error message
      enableMobile,
      // @ts-expect-error TS(2339): Property 'rootPath' does not exist on type 'Readon... Remove this comment to see the full error message
      rootPath,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
      style,
      backgroundColor = darkBrown,
      // @ts-expect-error TS(2339): Property 'enableStickyHeader' does not exist on ty... Remove this comment to see the full error message
      enableStickyHeader = true,
      // @ts-expect-error TS(2339): Property 'showLogo' does not exist on type 'Readon... Remove this comment to see the full error message
      showLogo = true,
    } = this.props;

    const { sticky } = this.state;

    const colorFn = theme === 'dark' ? lighten : darken;

    const HeaderNav = (
      <div className={cx(s.nav, { [s.navWithLogo]: showLogo })}>
        {showLogo && (
          <a href={rootPath || '/'} style={{ lineHeight: 0 }}>
            {logo || <Logo dark={theme === 'light'} />}
          </a>
        )}

        <Nav
          // @ts-expect-error TS(2769): No overload matches this call.
          theme={theme}
          links={links}
          selectedPath={selectedPath}
          enableMobile={enableMobile}
        />
      </div>
    );

    return (
      <div style={style}>
        {HeaderNav}

        {enableStickyHeader && (
          <div
            className={cx(s.sticky, { [s.stickyVisible]: sticky })}
            style={{
              backgroundColor,
              borderBottomColor: colorFn(backgroundColor, 0.1),
            }}
          >
            {HeaderNav}
          </div>
        )}
      </div>
    );
  }
}

export default Header;
