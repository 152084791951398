import { fetchCountries } from '@/actions/country';
import { fetchSectors } from '@/actions/sector';
import { track } from '@/actions/tracking';
import Layout from '@/components/Layout';
import MediaQuery from '@/components/MediaQuery';
import NotFoundPage from '@/pages/NotFoundPage';
import Profile from '@/profile/components/Profile';
import { fetchProfile } from '@/profile/store';
import { LegacyRoute } from '@/routes/routesMiddleware';
import { SCREEN_SM } from '@/theme/screens';

const route: LegacyRoute = {
  path: '/unregistered_expert/:url_endpoint',

  async action({ params, store, permission }) {
    const { url_endpoint: urlEndpoint } = params;

    const [profile] = await Promise.all(
      [
        fetchProfile(urlEndpoint!, {
          leadOnly: true,
          audit: true,
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
          groupKeywords: true,
          sources: true,
          internalNetworks: true,
        }),
        fetchSectors(),
        fetchCountries(),
      ]
        .filter(Boolean)
        .map(store.dispatch)
    );

    if (!profile) return <NotFoundPage />;

    const [updatePerm] = await permission.allowedBatch([
      // @ts-ignore
      { service: 'profile', action: 'update', resource: profile.id },
      {
        service: 'group_member',
        action: 'update_group_keywords',
        // @ts-ignore
        resource: profile.id,
      },
    ]);

    await store.dispatch(track('profile.unregistered.view', profile.id, undefined, true));

    document.title = `${profile.name} - Unregistered Expert`;
    return (
      <Layout showNav>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(mobile: boolean) => (
            <Profile
              // @ts-ignore
              profileId={profile.id}
              mobile={mobile}
              editable={updatePerm.allowed}
            />
          )}
        </MediaQuery>
      </Layout>
    );
  },
};

export default route;
