import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import manageProject from '@/assignment/store/manageProjectSlice';
import projectList from '@/assignment/store/projectListSlice';
import projectMembers from '@/assignment/store/projectMembersSlice';
import project from '@/assignment/store/projectSlice';
import auth from '@/auth/store/reducer';
import user from '@/auth/store/userSlice';
import viewer from '@/auth/store/viewerSlice';
import collectionCollaborator from '@/collection/store/collectionCollaboratorSlice';
import collectionInfo from '@/collection/store/collectionInfoSlice';
import collectionProfiles from '@/collection/store/collectionProfilesSlice';
import collections from '@/collection/store/collectionsSlice';
import myCollection from '@/collection/store/myCollectionSlice';
import popularCollections from '@/collection/store/popularCollectionsSlice';
import { reviewsReducer as consultationReviews } from '@/consultation/store/reducer';
import consultations from '@/consultation/store/reducer';
import onboarding from '@/dashboardv2/store/onboardingSlice';
import expertRequests from '@/expertrequest/store/reducer';
import accrualSummary from '@/knowledge/store/accrualSummarySlice';
import messaging from '@/messaging/store/reducer';
import profiles from '@/profile/store/reducer';
import addKnowledge from '@/profilev2/store/addKnowledgeSlice';
import addedLookupValues from '@/profilev2/store/addedLookupValuesSlice';
import assignmentToDelete from '@/profilev2/store/assignmentToDeleteSlice';
import cities from '@/profilev2/store/citiesSlice';
import harnessCountries from '@/profilev2/store/countriesSlice';
import degreeTypes from '@/profilev2/store/degreeTypesSlice';
import jobs from '@/profilev2/store/jobsSlice';
import languages from '@/profilev2/store/languagesSlice';
import lookups from '@/profilev2/store/lookupSlice';
import majors from '@/profilev2/store/majorsSlice';
import manageExperience from '@/profilev2/store/manageExperienceSlice';
import manageExperiences from '@/profilev2/store/manageExperiencesSlice';
import profileAwards from '@/profilev2/store/profileAwardsSlice';
import profileCertifications from '@/profilev2/store/profileCertificationsSlice';
import profilePublications from '@/profilev2/store/profilePublicationsSlice';
import profile from '@/profilev2/store/profileSlice';
import profilesByEmail from '@/profilev2/store/profilesEmailSlice';
import titleRawTerm from '@/profilev2/store/titleRawTermSlice';
import workHistory from '@/profilev2/store/workHistorySlice';
import activities from '@/reducers/activities';
import appNotifications from '@/reducers/appNotifications';
import billing from '@/reducers/billing';
import call from '@/reducers/call';
import countries from '@/reducers/countries';
import domains from '@/reducers/domains';
import expertComplianceTraining from '@/reducers/expertComplianceTraining';
import expertShowcase from '@/reducers/expertShowcase';
import groups from '@/reducers/groups';
import landing from '@/reducers/landing';
import legal from '@/reducers/legal';
import loading from '@/reducers/loading';
import messageTemplates from '@/reducers/messageTemplates';
import networks from '@/reducers/networks';
import projects from '@/reducers/projects';
import savedSearches from '@/reducers/savedSearches';
import sectors from '@/reducers/sectors';
import siteSettings from '@/reducers/siteSettings';
import suggestions from '@/reducers/suggestions';
import tracking from '@/reducers/tracking';
import ui from '@/reducers/ui';
import urls from '@/reducers/urls';
import search from '@/search/store/reducer';
import harnessSearch from '@/searchv2/store/searchSlice';
import runtime from '@/store/runtime';
import users from '@/store/user';
import harnessUi from '@/ui/store/uiSlice';

export {
  collectionInfoSelector,
  updateData as updateCollectionInfo,
} from '@/collection/store/collectionInfoSlice';
export {
  collectionProfilesSelector,
  updateData as updateCollectionProfiles,
} from '@/collection/store/collectionProfilesSlice';
export {
  myCollectionSelector as mycollectionSelector,
  updateData as updateDataMyCollection,
} from '@/collection/store/myCollectionSlice';
export {
  popularCollectionsSelector,
  updateData as updateDataPopularCollection,
} from '@/collection/store/popularCollectionsSlice';
export {
  countriesSelector,
  updateData as updateDataCountries,
} from '@/profilev2/store/countriesSlice';
export {
  profilesByEmailSelector,
  updateData as updateProfilesByName,
} from '@/profilev2/store/profilesEmailSlice';
export {
  titleRawTermsSelector,
  updateData as updateDataTitles,
} from '@/profilev2/store/titleRawTermSlice';

const rootReducer = {
  profile,
  profilesByEmail,
  project,
  projectMembers,
  cities,
  harnessCountries,
  titleRawTerm,
  user,
  languages,
  myCollection,
  collections,
  degreeTypes,
  popularCollections,
  collectionCollaborator,
  collectionProfiles,
  collectionInfo,
  lookups,
  addedLookupValues,
  jobs,
  onboarding,
  workHistory,
  harnessUi,
  profileAwards,
  profileCertifications,
  profilePublications,
  assignmentToDelete,
  projectList,
  majors,
  addKnowledge,
  manageExperiences,
  manageExperience,
  manageProject,
  accrualSummary,
  harnessSearch,
  // access
  viewer,
  loading,
  users,
  profiles,
  projects,
  expertRequests,
  runtime,
  expertComplianceTraining,
  form,
  countries,
  sectors,
  search,
  groups,
  networks,
  domains,
  suggestions,
  consultations,
  consultationReviews,
  billing,
  ui,
  legal,
  messaging,
  messageTemplates,
  appNotifications,
  call,
  activities,
  landing,
  expertShowcase,
  urls,
  tracking,
  siteSettings,
  auth,
  savedSearches,
};

export default combineReducers(rootReducer);
