import { buttonBaseClasses } from '@mui/material/ButtonBase';
import { paginationClasses } from '@mui/material/Pagination';

import { colors } from '@/theme/colors';

const styles = {
  Search: {
    [`&.${paginationClasses.root}`]: {
      marginTop: '20px',
      [`& .${paginationClasses.ul}`]: {
        justifyContent: 'center',
        gap: 10,
        [`& li`]: {
          [`& .${buttonBaseClasses.root}`]: {
            color: colors.teal[900],
            backgroundColor: 'transparent',
            border: 'none',
            fontWeight: '700',
            padding: '4px 8px',
            minWidth: 'auto',
            height: 'auto',
            lineHeight: '20px',
          },
          [`& .Mui-selected`]: {
            backgroundColor: colors.teal[900],
            color: 'white',
          },
          [`& svg`]: {
            width: 16,
            heigth: 16,
          },
        },
      },
    },
  },
};

export default styles;
