import { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useMediaQuery } from 'usehooks-ts';

import AddingToMyCollectionDialog from '@/collection/components/AddingToMyCollectionDialog';
import CollectionFormDialog from '@/collection/components/CollectionFormDialog';
import { myCollection, myCollectionSelector } from '@/collection/store/myCollectionSlice';
import MenuButton from '@/componentsv2/MenuButton';
import SendEmail from '@/componentsv2/SendEmail';
import { segmentTracking } from '@/core/analytics';
import { useAppSelector } from '@/store';
import { useAppDispatch } from '@/store';
import { mq } from '@/theme/screens';

const AddToCollection = ({
  profileId,
  name,
  email,
  handleClose,
}: {
  profileId: string;
  name: string;
  email: string;
  handleClose: () => void;
}) => {
  const { data } = useAppSelector(myCollectionSelector);
  const { count: collectionsCount, results: collectionsResults } = data || {};
  const [createCollection, setCreateCollection] = useState(false);

  return collectionsCount && !createCollection ? (
    <AddingToMyCollectionDialog
      open
      showCloseButton
      onClose={handleClose}
      dialogTitle="Add to collections"
      options={collectionsResults ?? []}
      profileId={profileId ?? ''}
      profileName={name ?? ''}
      onAdded={(collectionId) => {
        segmentTracking('add-profile-to-collection', {
          event_type: 'Search Result',
          event_name: 'Add to Collection',
          emailBeingAdded: email,
          profileIdBeingAdded: profileId ?? '',
          toCollection: collectionId,
        });
      }}
      email={email}
      createNewCollection={() => setCreateCollection(true)}
    />
  ) : (
    <CollectionFormDialog
      showCloseButton
      open={true}
      onClose={handleClose}
      maxWidth="md"
      profileId={profileId ?? ''}
      profileName={name}
    />
  );
};

const ResultActionMenu = ({
  profileId,
  email,
  handleShortlistToggle,
  isAddedToShortlist,
}: {
  profileId: string;
  email: string;
  isAddedToShortlist?: boolean;
  handleShortlistToggle: () => void;
}) => {
  const menuId = `result-menu-${profileId}`;
  const dispatch = useAppDispatch();
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showCollectionDialog, setShowCollectionsDialog] = useState(false);
  const showAllMenuItems = useMediaQuery(`${mq.smd}, ${mq.md} and ${mq.lgd}`);

  const handleSendEmail = () => {
    setShowEmailDialog(true);
  };

  const handleAddToCollection = () => {
    dispatch(
      myCollection({
        limit: 100,
        offset: 0,
        ordering: '-created_at',
        privacy: '',
        profileCanJoin: profileId,
      })
    );
    setShowCollectionsDialog(true);
  };

  return (
    <>
      <MenuButton
        id={menuId}
        buttonProps={{
          variant: 'tertiary',
          startIcon: MoreVertical,
        }}
      >
        {showAllMenuItems ? (
          <MenuButton.Item onClick={handleShortlistToggle}>
            {isAddedToShortlist ? 'Remove from shortlist' : 'Add to shortlist'}
          </MenuButton.Item>
        ) : null}
        <MenuButton.Item onClick={handleSendEmail}>Contact via email</MenuButton.Item>
        <MenuButton.Item onClick={handleAddToCollection}>Add to collection</MenuButton.Item>
      </MenuButton>

      {showCollectionDialog ? (
        <AddToCollection
          profileId={profileId}
          name="Test Name"
          email="test@email.com"
          handleClose={() => setShowCollectionsDialog(false)}
        />
      ) : null}
      {showEmailDialog ? (
        <SendEmail
          email={email}
          recipientId={profileId}
          onClose={() => {
            setShowEmailDialog(false);
          }}
          onSend={() => {
            setShowEmailDialog(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ResultActionMenu;
