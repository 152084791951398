import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form';

import { RootState } from '@/store';

import EditDialog from '../EditDialog';
import { SelectUser } from '../FormAdapters/FormAdapters';

interface FormData {
  users: any[];
}

interface AddUserDialogProps {
  handleSubmit: any;
  onSubmit?: (users: any) => void;
  onRequestAdd: (users: any) => void;
  onReset?: () => void;
  allowEmails?: boolean;
  userFilter?: (user: any) => boolean;
  users?: any;
  [key: string]: any;
}

class AddUserDialog extends PureComponent<
  AddUserDialogProps & InjectedFormProps<FormData, AddUserDialogProps>
> {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    userSource: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  };

  handleSubmit = (values: FormData) => {
    const { onRequestAdd, reset, onSubmit } = this.props;
    const { users } = values;
    onRequestAdd(users);
    reset();

    if (onSubmit) {
      onSubmit(users);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const { allowEmails, handleSubmit, array, users, userFilter, ...other } = this.props;

    return (
      <EditDialog
        //saveLabel="Add"
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        disableSubmit={users && users.length === 0}
        onReset={this.handleReset}
      >
        <Field
          component={SelectUser}
          multiple
          name="users"
          suggestionsFilter={userFilter}
          allowEmail={allowEmails}
        />
      </EditDialog>
    );
  }
}

const WrappedAddUserDialog = reduxForm<FormData, AddUserDialogProps>({
  form: 'addUser',
  initialValues: {
    users: [],
  },
})(AddUserDialog);

const selector = formValueSelector('addUser');
export default connect((state: RootState) => ({
  users: selector(state, 'users'),
}))(WrappedAddUserDialog);
