import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import ColumnSection from '@/components/ColumnSection/ColumnSection';
import EditIcon from '@/components/EditIcon/EditIcon';
import KeywordList from '@/components/KeywordList/KeywordList';

import SectionItem from './SectionItem';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
    marginBottom: -10,
  },
  list: {
    marginBottom: 5,
  },
}));

const SectionKeywords = ({ groupKeywords, keywords, listClassName }: any) => {
  return (
    <>
      {groupKeywords
        .filter((gk: any) => gk.keywords?.length)
        .map((gk: any) => (
          <SectionItem key={gk.name} subTitle={gk.name}>
            <KeywordList className={listClassName} highlightedKeywords={gk.keywords} icon={null} />
          </SectionItem>
        ))}
      {keywords.length > 0 && (
        <SectionItem subTitle="Other">
          <KeywordList className={listClassName} keywords={keywords} icon={null} />
        </SectionItem>
      )}
    </>
  );
};

const Keywords = ({ profile, editable, showSections, onEdit }: any) => {
  const s = getStyles();

  const { keywords, group_keywords } = profile;

  const groupKeywords = (group_keywords || [])
    .filter((gk: any) => gk.group)
    .map((gk: any) => ({
      name: gk.group.name,
      keywords: gk.keywords.map((k: any) => k.name),
    }));

  const allGroupKeywords = groupKeywords.reduce(
    (all: any, gk: any) => [...all, ...gk.keywords],
    []
  );

  return (
    <div className={s.row}>
      <ColumnSection title="Keywords">
        <div>
          {showSections ? (
            <SectionKeywords
              listClassName={s.list}
              groupKeywords={groupKeywords}
              keywords={keywords}
            />
          ) : (
            <KeywordList
              className={s.list}
              icon={null}
              keywords={keywords}
              highlightedKeywords={allGroupKeywords}
            />
          )}
        </div>
      </ColumnSection>

      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};
Keywords.displayName = 'Keywords';
export default Keywords;
