import arrayMutators from 'final-form-arrays';
import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { redirect, useNavigate } from 'react-router-dom';

import { setAddress } from '@/actions/address';
import { setLoadingProgress } from '@/actions/loading';
import { fetchProject, invalidateFetchedProjectsCache, saveProject } from '@/actions/project';
import { notify, popup } from '@/actions/ui';
import Body from '@/components/Body';
import Layout from '@/components/Layout';
import MediaQuery from '@/components/MediaQuery';
import TeamAccountPromo from '@/components/TeamAccountPromo/TeamAccountPromo';
import {
  fetchBaseExpertRequest,
  fetchExpertRequest,
  saveExpertRequest,
} from '@/expertrequest/store';
import NotFoundPage from '@/pages/NotFoundPage';
import { LegacyRoute } from '@/routes/routesMiddleware';
import { RootState } from '@/store';
import { SCREEN_SM } from '@/theme/screens';
import { clearCache, getCache, queryPart, setCache } from '@/utils';

import AddMembers from './AddMembers';
import Details from './Details';
import ExpertRequestSpy from './ExpertRequestSpy';
import SelectType from './SelectType';
import { copyExpertRequest, getDefaultValues } from './util';

const baseUrl = '/request_expert';
const cacheValueKey = 'newExpertRequest';

const steps = [
  {
    path: 'type',
    component: SelectType,
  },
  {
    path: 'details',
    component: Details,
    LayoutParams: {
      showNav: true,
    },
  },
];

const route: LegacyRoute = {
  path: `${baseUrl}/:stepPath?`,

  async action({ store, params, query }) {
    const { viewer } = store.getState();
    const projectId = query.get('project_id');

    if (!viewer.groups || viewer.groups.length === 0) {
      document.title = 'Upgrade your account';
      return (
        <Layout verticalCenter hideSearch showNewRequest={false}>
          <TeamAccountPromo />
        </Layout>
      );
    }

    const { stepPath } = params;

    if (!stepPath) {
      return redirect(`${baseUrl}/${steps[0].path}${queryPart(query)}`);
    }

    const step = steps.find((s) => s.path === stepPath);
    if (!step) return <NotFoundPage />;
    const { component } = step;

    // @ts-ignore
    const fetches = component.fetch
      ? // @ts-ignore
        Array.isArray(component.fetch)
        ? // @ts-ignore
          component.fetch
        : // @ts-ignore
          [component.fetch]
      : [];

    for (const fetch of fetches) {
      await store.dispatch(fetch({ viewer }));
    }

    // if initial project_id is set, fetch the project so it can be preselected
    if (projectId) await store.dispatch(fetchProject(projectId));

    // @ts-ignore
    document.title = component.title;
    return <ExpertRequestNewConnected query={query} step={step} />;
  },
};

const PRIVATE_DISCLOSURE_ERROR =
  "GraphQL Error: You don't have permission to perform this action. Permission group#private_disclosure";
const ADDRESS_TAKEN_ERROR = 'GraphQL Error: address already taken';

const ExpertRequestNew = (props: any) => {
  const { viewer, query, fetchBaseExpertRequest, popup, step, setLoadingProgress } = props;

  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(() => {
    const { project_id: initialProjectId } = query;

    return getDefaultValues(viewer, initialProjectId);
  });

  const { LayoutParams } = step;
  const stepNumber = steps.indexOf(step);

  const onNext = useCallback(() => {
    const stepNumber = steps.indexOf(step);
    const nextUrl =
      steps[stepNumber + 1] && `${baseUrl}/${steps[stepNumber + 1].path}${queryPart(query)}`;
    setLoadingProgress(10);
    navigate(nextUrl);
  }, [step, query, navigate, setLoadingProgress]);

  useEffect(() => {
    const { project_id: initialProjectId, copy_from: copyFrom } = query;
    if (copyFrom) {
      fetchBaseExpertRequest(copyFrom).then((copyData: any) => {
        if (copyData) {
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ formInitialized: true; copy_fr... Remove this comment to see the full error message
          setInitialValues({
            ...copyExpertRequest(viewer, copyData),
            formInitialized: true,
          });
        }
      });
    } else if (step.path === steps[0].path) {
      const defaultValues = getDefaultValues(viewer, initialProjectId);

      const cachedValue = getCache(cacheValueKey);
      const hasCachedValue = Boolean(cachedValue);

      const initDefaultValues = () => {
        const initialValues = defaultValues;
        if (defaultValues.project_id) {
          initialValues.project_id = defaultValues.project_id;
        }
        setInitialValues({ ...initialValues, formInitialized: true });
      };

      if (hasCachedValue) {
        popup({
          buttonAlignment: 'space-between',
          title: 'A previous Expert Request was found.',
          contents: 'Do you want to recover the previous expert request?',
          buttons: [
            {
              flat: true,
              label: 'No',
              callback: () => {
                clearCache(cacheValueKey);
                initDefaultValues();
              },
            },
            {
              label: 'Yes',
              callback: () => {
                cachedValue.currentVisit += 1;
                setInitialValues({ ...cachedValue, formInitialized: true });
                if (steps.indexOf(step) === 0) {
                  onNext();
                }
              },
            },
          ],
        });
      } else {
        initDefaultValues();
      }
    }
  }, [fetchBaseExpertRequest, onNext, popup, query, step, viewer]);

  const handleSubmit = async (values: any, form: any, callback: any) => {
    if (submitting) return;

    const {
      viewer,
      projects,
      saveProject,
      saveExpertRequest,
      invalidateFetchedProjectsCache,
      notify,
      setAddress,
    } = props;

    if (!viewer.phone && values.phone) {
      try {
        setAddress(viewer.profile.id, 'phone', values.phone, true);
      } catch (err) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (err.message.startsWith(ADDRESS_TAKEN_ERROR)) {
          callback({
            phone: 'Already in use, please use another',
          });
          return;
        }
        notify('Error saving phone number.', 'error');
      }
    }

    const {
      project_name: projectName,
      tracking_code: trackingCode,
      ...expertRequestValues
    } = values;

    if (!expertRequestValues.name) {
      notify('Expert request must have a name.', 'error');
      return;
    }

    const groupId = values.group_id;
    let projectId = values.project_id;
    const projectEdges = (projects && projects.edges) || [];
    const shouldCreateProject =
      !projectId || !projectEdges.find((e: any) => e.node.id === projectId);

    setSubmitting(true);

    try {
      if (shouldCreateProject) {
        const createdProject = await saveProject({
          name: projectName || values.name,
          tracking_code: trackingCode,
          group_id: groupId,
        });
        projectId = createdProject.id;
        form.change('project_id', projectId);
        setCache(cacheValueKey, { ...values, project_id: projectId });
      }

      const expertRequest = await saveExpertRequest(
        {
          ...expertRequestValues,
          project_id: projectId,
        },
        {
          fetchProject: true,
        }
      );

      await invalidateFetchedProjectsCache();

      clearCache(cacheValueKey);
      navigate(`/expert_request/${expertRequest.id}/add_members`);
    } catch (err) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (err.message.startsWith(PRIVATE_DISCLOSURE_ERROR)) {
        notify('User is not authorized to choose private disclosure (premium).', 'error');
        return;
      }

      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (err.rawError) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        const errors = Array.isArray(err.rawError)
          ? // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
            err.rawError
          : // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
            [err.rawError];
        errors.forEach((error: any) => {
          notify(error.message, 'error');
        });
        return;
      }

      notify('Error when creating expert request.', 'error');
      console.warn(err);
    } finally {
      setSubmitting(false);
    }
  };

  const renderStep = (isMobileVersion: any, { form, handleSubmit }: any) => {
    const { step } = props;
    const { component } = step;

    return React.createElement(component, {
      onNext: onNext,
      isMobileVersion,
      change: form.change,
      values: form.getState().values,
      handleSubmit,
    });
  };

  return (
    <Layout {...LayoutParams} hideSearch showNewRequest={false} selected="expert_requests">
      <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(isMobileVersion: any) => (
            <Form
              onSubmit={handleSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) =>
                step &&
                step.component &&
                step.component.validate &&
                step.component.validate(values, props)
              }
            >
              {(formRenderProps) => (
                <>
                  <ExpertRequestSpy
                    cacheKey={cacheValueKey}
                    formValuesInitialized={initialValues.formInitialized && stepNumber > 1}
                  />
                  {renderStep(isMobileVersion, formRenderProps)}
                </>
              )}
            </Form>
          )}
        </MediaQuery>
      </Body>
    </Layout>
  );
};

const ExpertRequestNewConnected = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    projects: state.projects.names || [],
    groups: state.groups.all || [],
  }),
  {
    saveProject,
    invalidateFetchedProjectsCache,
    saveExpertRequest,
    fetchBaseExpertRequest,
    notify,
    setLoadingProgress,
    setAddress,
    popup,
  }
)(ExpertRequestNew);

export const addMembers: LegacyRoute = {
  path: `/expert_request/:id/add_members`,

  async action({ store, params: { id } }) {
    const expertRequest = await store.dispatch(fetchExpertRequest(id));
    if (!expertRequest) return <NotFoundPage />;

    document.title = 'Invite Members';
    return (
      <Layout hideSearch>
        <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
          <AddMembers project={expertRequest.project} expertRequestId={expertRequest.id} />
        </Body>
      </Layout>
    );
  },
};

export default route;
