import cx from 'classnames';
import moment from 'moment-timezone';

import Button from '@/components/Button/Button';
import { formatDuration } from '@/consultation';
import { darkGreen } from '@/theme/colors';

import s from './TimeButton.module.scss';

function TimeButton({ date, timezone, expectedDuration, selected, style, ...props }: any) {
  const momentTimezone = moment.tz(date, moment.tz.zone(timezone) ? timezone : moment.tz.guess());
  const minutes = formatDuration(expectedDuration);

  return (
    <Button
      {...props}
      color="white"
      backgroundColor={selected ? darkGreen : undefined}
      classes={{
        root: cx(s.timeButton, { [s.timeButtonSelected]: selected }),
        label: s.timeButtonLabel,
      }}
      square
      style={{ marginBottom: 20, ...style }}
    >
      <div>
        <div className={s.timeButtonDate}>{momentTimezone.format('ddd D MMM YYYY')}</div>
        <div className={s.timeButtonTime}>{momentTimezone.format('h:mma')}</div>
        {minutes && <div className={s.timeButtonDuration}>{minutes}</div>}
      </div>
    </Button>
  );
}

export default TimeButton;
