import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    minWidth: 'auto',
    padding: '4px 8px',
    fontSize: 12,
  },
});

export default function (props: any) {
  const classes = useStyles();

  return (
    <Button classes={classes} variant="contained" color="secondary" disableElevation {...props}>
      Add
    </Button>
  );
}
