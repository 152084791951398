import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { gengql } from '@/__generated__';
import DisableOtpAuth from '@/auth/components/SecurityDisableOtpAuth';
import { EnableOtpAuth, FAQ_URL } from '@/auth/components/SecurityEnableOtpAuth';
import { createOtpAuthConfiguration } from '@/auth/store';
import Checkbox from '@/components/Checkbox';
import FaqLink from '@/components/FaqLink';
import SettingsSection from '@/components/SettingsSection';

const GET_OTP_CONFIGURATION = gengql(/* GraphQL */ `
  query securityGetOtpConfig {
    otpConfiguration {
      enabled
    }
  }
`);

type OTPConfiguration = {
  enabled: boolean;
};

const useStyles = makeStyles({
  section: {
    margin: '0 !important',
  },
  faq: {
    paddingTop: 20,
  },
});

function Security({ onDisableOtpAuth, user }: any) {
  const s = useStyles();

  const [enableOtpAuthOpen, setEnableOtpAuthOpen] = useState(false);
  const [disableOtpAuthOpen, setDisableOtpAuthOpen] = useState(false);

  const { data, refetch } = useQuery<{ otpConfiguration: OTPConfiguration }>(GET_OTP_CONFIGURATION);
  const otpAuthEnabled = data?.otpConfiguration?.enabled || false;

  const handleEnabled = useCallback(async () => {
    if (otpAuthEnabled) {
      setDisableOtpAuthOpen(true);
    } else {
      setEnableOtpAuthOpen(true);
    }
  }, [otpAuthEnabled]);

  const handleEnableOtpAuthClose = useCallback(() => {
    setEnableOtpAuthOpen(false);
  }, []);

  const handleDisableOtpAuthClose = useCallback(() => {
    setDisableOtpAuthOpen(false);
  }, []);

  return (
    <div>
      <SettingsSection className={s.section} box title="Two-factor verification">
        <Checkbox
          label="Enabled"
          checked={otpAuthEnabled}
          onClick={handleEnabled}
          FormControlProps={{ margin: 'none' }}
        />
      </SettingsSection>
      <FaqLink url={FAQ_URL} className={s.faq} />
      <DisableOtpAuth
        open={disableOtpAuthOpen}
        onClose={handleDisableOtpAuthClose}
        user={user}
        onDisable={onDisableOtpAuth}
        refetch={refetch}
      />
      <EnableOtpAuth
        open={enableOtpAuthOpen}
        onCancel={handleEnableOtpAuthClose}
        user={user}
        email={user.email.address}
        setOtp={() => {}}
        refetch={refetch}
      />
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'Security' because it is a functi... Remove this comment to see the full error message
Security = connect(
  (state, ownProps) => ({
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    user: state.users[ownProps.userId],
  }),
  {
    createOtpAuthConfiguration,
  }
)(Security);

export default Security;
