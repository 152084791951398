import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ReactElement, forwardRef } from 'react';

import Box from '@/componentsv2/Box';
import { FAIcon } from '@/componentsv2/Icon';
import { Icon } from '@/componentsv2/Icon/FAIcon/icons';
import Text, { TextProps as TextCmpProps } from '@/componentsv2/Text';

import styles, { chipClasses } from './styles';

interface ChipProps extends MuiChipProps {
  righticon?: string | ReactElement | Icon;
  lefticon?: string | ReactElement | Icon;
  leftIconLight?: boolean;
  rightIconLight?: boolean;
  className?: string;
  TextProps?: TextCmpProps;
}

const useStyles = makeStyles(styles);

const Chip = forwardRef<HTMLDivElement | null, ChipProps>(
  ({ lefticon, leftIconLight, righticon, rightIconLight, TextProps = {}, ...props }, ref) => {
    const classes = useStyles();
    const classProps = clsx(props.className, classes.chip);

    let rightIconElement = undefined;

    if (righticon) {
      if (typeof righticon === 'string') {
        rightIconElement = (
          <FAIcon
            size="lg"
            icon={righticon}
            className={chipClasses.rightIcon}
            light={rightIconLight}
          />
        );
      } else {
        rightIconElement = righticon;
      }
    }

    let leftIconElement = undefined;

    if (lefticon) {
      if (typeof lefticon === 'string') {
        leftIconElement = (
          <FAIcon
            size="lg"
            color={props.color}
            className={chipClasses.leftIcon}
            icon={lefticon}
            light={leftIconLight}
          />
        );
      } else {
        leftIconElement = lefticon;
      }
    }

    return (
      <MuiChip
        {...props}
        className={classProps}
        ref={ref}
        label={
          <div className={chipClasses.labelContainer}>
            {leftIconElement && <Box className={chipClasses.leftIcon}>{leftIconElement}</Box>}
            <Text
              className={chipClasses.labelText}
              color="secondary"
              component="span"
              tag="pRegular"
              {...TextProps}
            >
              {' '}
              {props.children || props.label}
            </Text>
            {rightIconElement && <Box className={chipClasses.rightIcon}>{rightIconElement}</Box>}
          </div>
        }
      />
    );
  }
);

Chip.displayName = 'Chip';

export { chipClasses };
export type { ChipProps };
export default Chip;
