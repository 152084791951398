import { Button as MaterialButton, MenuItem } from '@mui/material';
import { useState } from 'react';

import { EngagementType } from '@/__generated__/graphql';
import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconMenu from '@/components/IconMenu';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import SendMessageButton from '@/components/SendMessageButton';
import { getStateDescription } from '@/consultation';
import { ER_TYPE_LABELS } from '@/expertrequest/store';
import { usePermissions } from '@/hooks/useAppContext';
import { black } from '@/theme/colors';

import s from './Header.module.scss';
import Title from './Title';

const engagementTypeLabels = {
  [EngagementType.Consultation]: 'Call',
  [EngagementType.WrittenResponse]: 'Written Response',
  [EngagementType.Opportunity]: 'Opportunity Call',
};

interface ConsultationActionsProps {
  onDelete?: () => void;
}

const ConsultationActions = ({ onDelete }: ConsultationActionsProps) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const openDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  if (!onDelete) return null;

  return (
    <div className={s.actions}>
      <IconMenu iconSize={24} iconStyle={{ color: black, opacity: 1 }}>
        <MenuItem onClick={openDeleteConfirmation}>Delete</MenuItem>
      </IconMenu>
      <Dialog
        open={deleteConfirmation}
        title="Are you sure?"
        subTitle={
          'You will lose all details and audio from this consultation. ' +
          'Click delete to confirm.'
        }
        onCancel={() => setDeleteConfirmation(false)}
        onConfirm={handleDelete}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </div>
  );
};

interface HeaderProps {
  consultation: any;
  user: any;
  isViewerExpert: boolean;
  isViewerAdmin: boolean;
  isCompleted: boolean;
  isMobileVersion: boolean;
  callActions: any;
  onReviewCall: () => void;
  onDelete?: () => void;
  shouldReview: boolean;
}

const Header = ({
  consultation,
  user,
  isViewerExpert,
  isViewerAdmin,
  isCompleted,
  isMobileVersion,
  callActions,
  onReviewCall,
  onDelete,
  shouldReview,
}: HeaderProps) => {
  const { expert, expert_request: expertRequest } = consultation;
  const requesterId = consultation.requester && consultation.requester.id;

  const [canStartNonAnonymousChat, canStartAnonymousChat] = usePermissions(
    [
      { service: 'chat', action: 'start_non_anonymous_chat', resource: requesterId },
      { service: 'chat', action: 'start_anonymous_chat', resource: requesterId },
    ],
    [requesterId]
  );

  const stateDescription = getStateDescription(consultation);
  const showMessageButton = !!(
    // must be a valid, non admin, user
    (
      !isViewerAdmin &&
      user &&
      user.username &&
      requesterId &&
      (canStartNonAnonymousChat || canStartAnonymousChat)
    )
  );

  const engagementType = isViewerAdmin
    ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      engagementTypeLabels[consultation.engagement_type]
    : null;

  const erType =
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    isViewerAdmin && expertRequest ? ER_TYPE_LABELS[expertRequest.er_type] : null;

  return (
    <div className={s.header}>
      {user && (
        <div className={s.photo}>
          <Picture user={user} size={100} />
        </div>
      )}

      <div className={s.headerInfo}>
        <div className={s.status}>
          <FAIcon
            className={s.statusIcon}
            iconSet={stateDescription.iconSet}
            icon={stateDescription.icon}
            color={stateDescription.color}
          />
          <span style={{ color: stateDescription.color }} className={s.statusText}>
            {stateDescription.text}
          </span>
          <ConsultationActions onDelete={onDelete} />
        </div>

        <div className={s.name}>
          <Title
            consultation={consultation}
            isViewerExpert={isViewerExpert}
            isViewerRequester={!isViewerExpert}
            requesterName={consultation.requester?.name}
            expertName={consultation.expert?.name}
            isWrittenConsultation={consultation.engagement_type === EngagementType.WrittenResponse}
          />
        </div>

        {engagementType && <div className={s.engagementType}>{engagementType}</div>}

        {erType && <div className={s.erType}>{erType}</div>}

        {showMessageButton && (
          <SendMessageButton
            userId={user.id}
            style={{ marginTop: 5, marginLeft: isMobileVersion ? 0 : -10 }}
          />
        )}
      </div>

      {(isCompleted || (!isMobileVersion && !!callActions)) && (
        <div className={s.headerActions}>
          {!isMobileVersion && callActions}

          {shouldReview && (
            <Button fullWidth size="large" style={{ marginBottom: 10 }} onClick={onReviewCall}>
              Review
            </Button>
          )}
          {isCompleted && !isViewerExpert && expert && (
            <div>
              <Link
                to={`${expert.html_url}/request?expertRequestId=${
                  expertRequest ? expertRequest.id : ''
                }`}
              >
                <MaterialButton
                  startIcon={<MaterialIcon icon="cached" style={{ fontSize: 22 }} />}
                  style={{ fontSize: 16 }}
                >
                  Request Again
                </MaterialButton>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
