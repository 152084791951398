import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateNetworkExpert } from '@/actions/internalNetwork';
import Button from '@/components/Button';
import { Checkbox, Field, Form } from '@/components/FormAdapters';
import Link from '@/components/Link';
import { updateUser } from '@/store/user';
import { borderColor } from '@/theme/colors';

const useStyles = makeStyles({
  form: {
    textAlign: 'center',
  },
  checkbox: {
    paddingTop: 2,
  },
  checkboxLabel: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  logo: {
    maxWidth: 300,
    maxHeight: 100,
    width: 'auto',
    height: 'auto',
  },
  divider: {
    backgroundColor: borderColor,
    margin: '20px 0 40px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px auto',
    maxWidth: 800,
  },
  agreements: {
    maxWidth: 800,
    margin: '0 auto',
  },
  button: {
    minWidth: 150,
  },
});

function AcceptNetwork({ viewer, networkExpert, updateUser, updateNetworkExpert }: any) {
  const s = useStyles();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: any) => {
      const now = new Date();
      const agreements = Object.keys(values).map((name) => {
        return {
          policy: name,
          accepted: values[name],
          updated_at: now,
        };
      });

      await updateUser({ id: viewer.id, agreements });

      await updateNetworkExpert({
        id: networkExpert.id,
        connection_state: 'active',
      });

      navigate('/network');
    },
    [viewer, networkExpert]
  );

  const validate = useCallback((values: any) => {
    const errors = {};

    if (domain.agreements) {
      domain.agreements.forEach((a: any) => {
        if (!values[a.policy_code]) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          errors[a.policy_code] = 'Required';
        }
      });
    }

    return errors;
  }, []);

  const { domain } = networkExpert.network.group;

  const logo = domain.logo_url && <img alt="logo" src={domain.logo_url} className={s.logo} />;

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit }: any) => {
        return (
          <form onSubmit={handleSubmit} className={s.form}>
            {logo}

            {logo && <Divider className={s.divider} />}

            <div className={s.agreements}>
              {domain.agreements.map((a: any) => (
                <Field
                  key={a.policy_code}
                  type="checkbox"
                  component={Checkbox}
                  classes={{ root: s.checkbox }}
                  labelClasses={{ root: s.checkboxLabel }}
                  name={a.policy_code}
                  label={
                    <span>
                      I have read and agree with&nbsp;
                      <Link newTab to={a.policy_url}>
                        {a.policy_label}
                      </Link>
                      .
                    </span>
                  }
                />
              ))}
            </div>

            <div className={s.actions}>
              <Link to="/network">
                <Button classes={{ root: s.button }}>Cancel</Button>
              </Link>

              <Button type="submit" classes={{ root: s.button }}>
                Accept
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'AcceptNetwork' because it is a f... Remove this comment to see the full error message
AcceptNetwork = connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
  }),
  {
    updateUser,
    updateNetworkExpert,
  }
)(AcceptNetwork);

export default AcceptNetwork;
