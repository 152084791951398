import React, { Component } from 'react';

const modules: Record<
  string,
  { default: React.FC<React.SVGProps<SVGSVGElement>> }
  // KT: can't add ?react here or glob won't find
> = import.meta.glob('./svgs/*.svg', { eager: true });

const icons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {};
for (const path in modules) {
  // Extract the icon name from the file path
  const match = path.match(/\.\/svgs\/(.*)\.svg$/);
  if (!match) {
    throw new Error(`Invalid icon path: ${path}`);
  }

  const iconName = match[1];
  icons[iconName] = modules[path].default;
}

interface SvgIconProps {
  style?: React.CSSProperties;
  icon: keyof typeof icons;
  fill?: string;
  stroke?: string;
  [key: string]: any;
}

class SvgIcon extends Component<SvgIconProps> {
  handleMouseEnter: any;
  handleMouseLeave: any;
  render() {
    const { style, icon, fill, stroke, ...other } = this.props;

    const mergedStyles = Object.assign(
      {
        display: 'inline-block',
        fill,
        stroke,
        userSelect: 'none',
      },
      style
    );

    const Svg = icons[icon];

    return (
      <Svg
        {...other}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={mergedStyles}
      />
    );
  }
}

export default SvgIcon;
