import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Viewer, loggedOutViewer } from '@/core/viewer';

const initialState = loggedOutViewer;

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setViewer(state, action: PayloadAction<Viewer>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setViewer } = viewerSlice.actions;
export default viewerSlice.reducer;
