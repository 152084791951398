import { FormApi } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import { renderTemplate } from '@/actions/messageTemplate';
import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog';
import FieldContainer from '@/components/FieldContainer';
import { TextField } from '@/components/FormAdapters';
import Link from '@/components/Link';
import {
  MessageTemplateVariablesDialog,
  MessageTemplatesDialog,
} from '@/components/MessageTemplate';

import { Profile, Values } from '../AddToExpertRequestDialog';
import s from '../AddToExpertRequestDialog.module.scss';
import InvitationEmailPreview from '../InvitationEmailPreview';
import InvitationSummary from '../InvitationSummary';

type EmailTemplateFieldProps = {
  isAdmin: boolean;
  profiles: Profile[];
  values: Values;
  senderId: string | undefined;
  form: FormApi<Values, Partial<Values>>;
  profileInvitationExample: Profile | undefined;
  setProfileInvitationExample: (profile: Profile) => void;
  notify: (message: string, type?: string) => void;
  renderTemplate: (template: string, args: any) => Promise<string>;
};

const defaultSubject = '[field: expert.first_name], paid consultation on [field: request.name]';
const defaultBody = `Dear [field: expert.first_name],

This is [field: sender.first_name] from OnFrontiers, a New York-based online Expert platform.

I saw your profile and was wondering if you would be willing to assist our client on a project in your area of expertise.

The project is “[field: request.name]”.

Discussion would be around:
[field: request.questions]

You seem like a match for this. If relevant and interesting to you, could you check the details and indicate your interest and expertise through this link: [field: request.short_public_url]?

Feel free to check our Q&A to know more about being an expert at OnFrontiers: https://try.onfrontiers.com/knowledge/onfrontiers-expert-engagement-faq
Please let us know if you have any questions or concerns and we will be in touch soon.

All the best,
[field: sender.full_name]

[field: sender.private_url]
OnFrontiers.com - Find experts in any local market`;

const EmailTemplateFieldPure: React.FC<EmailTemplateFieldProps> = ({
  isAdmin,
  profiles,
  form,
  values,
  senderId,
  profileInvitationExample,
  setProfileInvitationExample,
  notify,
  renderTemplate,
}) => {
  const [invitationEmail, setInvitationEmail] = useState<
    { subject: string; body: string } | undefined
  >(undefined);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [variablesDialogOpen, setVariablesDialogOpen] = useState(false);
  const [chooseTemplateOpenDialog, setChooseTemplateOpenDialog] = useState(false);
  const [renderingInvitationEmail, setRenderingInvitationEmail] = useState(false);

  const handleInvitationExampleClick = useCallback(
    (profile: any) => {
      setProfileInvitationExample(profile);
      setPreviewDialogOpen(true);
    },
    [setProfileInvitationExample]
  );

  const handleSelectTemplate = useCallback(
    async (template: any) => {
      if (template) {
        form.change('invitation_email_body', template.body);
      }
      setChooseTemplateOpenDialog(false);
    },
    [form]
  );

  useEffect(() => {
    const profile = profileInvitationExample || profiles[0];
    const canRenderInvitationEmail =
      previewDialogOpen &&
      values.expert_request_id &&
      values.send_invitation_email &&
      (values?.invitation_email_subject || values?.invitation_email_body);

    if (!canRenderInvitationEmail) {
      return;
    }

    setProfileInvitationExample(profile);
    setRenderingInvitationEmail(true);
    const renderTemplateArgs = {
      senderId,
      requestId: values.expert_request_id,
      profileId: profile.id,
    };
    Promise.all([
      renderTemplate(values?.invitation_email_subject, renderTemplateArgs),
      renderTemplate(values?.invitation_email_body, renderTemplateArgs),
    ])
      .then((results) => {
        setRenderingInvitationEmail(false);
        setInvitationEmail({
          subject: results[0],
          body: results[1],
        });
      })
      .catch(() => {
        notify('An error occurred when rendering the template.', 'error');
        setRenderingInvitationEmail(false);
        setInvitationEmail(undefined);
      });
  }, [
    notify,
    previewDialogOpen,
    profileInvitationExample,
    profiles,
    renderTemplate,
    senderId,
    setProfileInvitationExample,
    values.expert_request_id,
    values?.invitation_email_body,
    values?.invitation_email_subject,
    values.send_invitation_email,
  ]);

  if (!isAdmin || !values.send_invitation_email || !values.expert_request_id) return null;

  return (
    <>
      <Button variant="inverted" size="small" onClick={() => setChooseTemplateOpenDialog(true)}>
        Select From Template
      </Button>
      <MessageTemplatesDialog
        // @ts-expect-error TS(2769): No overload matches this call.
        open={chooseTemplateOpenDialog}
        onClose={() => setChooseTemplateOpenDialog(false)}
        onSelectTemplate={handleSelectTemplate}
      />
      <div className={s.emailField}>
        <Link onClick={() => setVariablesDialogOpen(true)}>View Available Template Variables</Link>
      </div>
      <MessageTemplateVariablesDialog
        open={variablesDialogOpen}
        title="Available Template Variables"
        onClose={() => setVariablesDialogOpen(false)}
      />
      <FieldContainer className={s.emailField} label="Subject">
        <Field
          component={TextField}
          name="invitation_email_subject"
          initialValue={defaultSubject}
          InputProps={{ disableUnderline: true }}
          fullWidth
        />
      </FieldContainer>
      <FieldContainer className={s.emailField} label="Body">
        <Field
          component={TextField}
          name="invitation_email_body"
          initialValue={defaultBody}
          InputProps={{ disableUnderline: true }}
          fullWidth
          multiline
          rows={10}
          maxRows={10}
        />
      </FieldContainer>
      <InvitationSummary
        profiles={profiles}
        onInvitationExampleClick={handleInvitationExampleClick}
      />
      <Dialog
        open={previewDialogOpen}
        title="Preview"
        onClose={() => setPreviewDialogOpen(false)}
        onCancel={() => setPreviewDialogOpen(false)}
      >
        <InvitationEmailPreview
          loading={renderingInvitationEmail}
          invitationEmail={invitationEmail}
        />
      </Dialog>
    </>
  );
};
EmailTemplateFieldPure.displayName = 'EmailTemplateField';

export const EmailTemplateField = connect(undefined, {
  notify,
  renderTemplate,
})(EmailTemplateFieldPure);
