import { fetchPrices } from '@/actions/billing';
import { fetchGroup } from '@/actions/group';
import BillingSettings from '@/components/BillingSettings';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import CreditPurchaseForm from '@/components/CreditPurchaseForm';
import CreditSettings from '@/components/CreditSettings';
import FAIcon from '@/components/Icon/FAIcon';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import Settings from '@/components/Settings';
import { formatAccountType } from '@/core/billing';
import NotFoundPage from '@/pages/NotFoundPage';
import { darkGreen } from '@/theme/colors';

import { LegacyRoute } from '../routesMiddleware';
import AboutPage from './AboutPage';
import Branding from './Branding';
import Preferences from './Preferences';
import SavedSearches from './SavedSearches';
import Subdomain from './Subdomain';

const route: LegacyRoute = {
  path: '/team/:teamSlug/settings/:section?/:subsection?',

  async action({ store, params, permission }) {
    const path = !params.section
      ? null
      : !params.subsection
        ? params.section
        : `${params.section}/${params.subsection}`;
    const { viewer } = store.getState();

    const { teamSlug } = params;

    const groups: {
      name: string;
      billing_account?: { id: string; seat_count?: number };
      account_type?: string;
      id: string;
    }[] = await Promise.all(
      [
        fetchGroup(teamSlug, {
          transactions: true,
          aboutPage: true,
          profileKeywordsDefinition: true,
          internalNetwork: true,
          domain: true,
          savedSearches: true,
        }),
      ].map(store.dispatch)
    );

    const group = groups[0];
    if (!group) return <NotFoundPage />;

    await store.dispatch(fetchPrices(group.billing_account?.id));

    const [canViewSettings, canUpdateGroup, canUpdateHigherTierSettings, canUpdateAdminSettings] =
      await permission.allowedBatch([
        { service: 'group', action: 'view_settings', resource: group.id },
        { service: 'group', action: 'update', resource: group.id },
        {
          service: 'group',
          action: 'update_higher_tier_settings',
          resource: group.id,
        },
        {
          service: 'group',
          action: 'update_admin_settings',
          resource: group.id,
        },
      ]);

    if (!canViewSettings.allowed || (!canUpdateGroup.allowed && !group.billing_account)) {
      return <NotFoundPage />;
    }
    const sections = [];

    if (group.billing_account) {
      sections.push({
        id: 'credits',
        title: 'Credits',
        path: 'credits',
        component: CreditSettings,
        componentProps: {
          account: group.billing_account,
          purchasePath: `/team/${teamSlug}/settings/credits/purchase`,
          // memberNotOwner: !memberOwner,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          accountType: formatAccountType(group.account_type),
          seatCount: group.billing_account?.seat_count,
        },
      });

      sections.push({
        id: 'billing',
        title: 'Billing',
        path: 'billing',
        url: '/team/:teamSlug/settings/billing',
        component: BillingSettings,
        componentProps: {
          account: group.billing_account,
        },
      });
    }

    if (canUpdateGroup.allowed) {
      sections.push({
        id: 'credits/purchase',
        title: 'Purchase Credits',
        path: 'credits/purchase',
        component: CreditPurchaseForm,
        componentProps: {
          account: group.billing_account,
          purchaseDonePath: `/team/${teamSlug}/settings/credits`,
          groupId: group.id,
        },
      });
      sections.push({
        id: 'preferences',
        title: 'Preferences',
        path: 'preferences',
        component: Preferences,
        componentProps: { groupId: group.id },
      });
    }

    if (canUpdateHigherTierSettings.allowed) {
      sections.push({
        id: 'about_page',
        title: 'About Page',
        path: 'about_page',
        component: AboutPage,
        componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
      });
    }

    const adminSections = [];

    if (group.account_type === 'enterprise' && canUpdateAdminSettings.allowed) {
      adminSections.push({
        id: 'subdomain',
        title: 'Subdomain',
        path: 'subdomain',
        component: Subdomain,
        componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
      });

      adminSections.push({
        id: 'saved_searches',
        title: 'Saved Searches',
        path: 'saved_searches',
        component: SavedSearches,
        componentProps: { groupId: group.id },
      });

      adminSections.push({
        id: 'branding',
        title: 'Branding',
        path: 'branding',
        component: Branding,
        componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
      });
    }

    const section = path
      ? sections.find((s) => s.path === path) || adminSections.find((s) => s.path === path)
      : sections[0];
    if (!section) return <NotFoundPage />;

    document.title = `${group.name} Settings`;
    return (
      <LayoutPage showNav selected="team">
        <ConsultationsStarting />
        <Settings
          pathPrefix={`/team/${teamSlug}/settings`}
          subTitle={
            <Link
              to={`/team/${teamSlug}`}
              style={{
                display: 'inline-block',
                marginBottom: 25,
                fontWeight: 500,
              }}
            >
              <FAIcon iconSet="fal" color={darkGreen} icon="long-arrow-left" /> {group.name}
            </Link>
          }
          nav={[
            { title: 'Team Settings', items: sections },
            ...(adminSections.length > 0 ? [{ title: 'Admin Only', items: adminSections }] : []),
          ]}
          {...section}
        />
      </LayoutPage>
    );
  },
};

export default route;
