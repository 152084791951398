import { useQuery } from '@apollo/client';

import { gengql } from '@/__generated__';
import Button from '@/components/Button/Button';
import WizardDone from '@/components/WizardDone';
import { isCallType } from '@/consultation/store';

import s from './Done.module.scss';

const TEXT = `
Congratulations! You have successfully completed OnFrontiers' Compliance Training.

You may now accept Consultation and Opportunity Call requests and make full use of the Platform.

- OnFrontiers Compliance Team
`;

interface DoneProps {
  consultationId?: string;
  confirmTime?: string;
}

const GET_CONSULTATION = gengql(/* GraphQL */ `
  query getDoneConsultation($id: String!) {
    consultation(id: $id) {
      id
      engagement_type
    }
  }
`);

const Done = ({ consultationId, confirmTime }: DoneProps) => {
  const { data } = useQuery(GET_CONSULTATION, {
    variables: { id: consultationId! },
    skip: !consultationId,
  });

  const consultation = data?.consultation;
  const engagementType = consultation?.engagement_type;

  let acceptMessage = 'Go to Dashboard →';
  if (engagementType && isCallType(engagementType)) {
    acceptMessage = 'Accept Call →';
  } else if (consultation) {
    acceptMessage = 'Accept Consultation →';
  }

  return (
    <WizardDone title="Compliance Training Complete" text={TEXT}>
      <div className={s.actions}>
        <Button
          size="large"
          href={
            consultationId
              ? `/consultation/${consultationId}?confirm_time=${confirmTime}`
              : '/dashboard'
          }
        >
          {consultationId ? acceptMessage : 'Go to Dashboard →'}
        </Button>
      </div>
    </WizardDone>
  );
};

export default Done;
