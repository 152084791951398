import { FC } from 'react';
import { To, useNavigate } from 'react-router-dom';

import MaterialIcon from '../Icon/MaterialIcon';
import IconButton, { IconButtonProps } from '../IconButton/IconButton';

interface EditIconProps extends IconButtonProps {
  onClick?: () => void;
  backToLocation?: To;
}

const EditIcon: FC<EditIconProps> = ({ onClick, backToLocation, ...props }) => {
  const navigate = useNavigate();

  if (!onClick && backToLocation) onClick = () => navigate(backToLocation);

  return (
    <IconButton onClick={onClick} style={{ ...props.style, opacity: 0.3 }} {...props}>
      <MaterialIcon icon="mode_edit" />
    </IconButton>
  );
};

export default EditIcon;
