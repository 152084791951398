import config from '@/config';

export const RareSkill = () => (
  <li className="flex items-center gap-10 px-16 py-8">
    <div className="flex h-32 w-32 items-center justify-center overflow-hidden rounded-full bg-deep-purple-50">
      <img alt="Unicorn" src="/assets/unicorn.svg" />
    </div>
    <span className="body-1">
      <b>Rare Skill</b> - Few experts in {config.productName} have this knowledge.
    </span>
  </li>
);
