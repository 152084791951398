import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import EditableList from '@/components/EditableList';
import MediaQuery from '@/components/MediaQuery';
import { updateProfile } from '@/profile/store';
import { SCREEN_XS } from '@/theme/screens';

class QuestionsForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    component: PropTypes.func,
    reset: PropTypes.func,
    profileId: PropTypes.string,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    updateProfile: PropTypes.func,
  };

  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, onSubmit, updateProfile } = this.props;
    updateProfile({
      id: profileId,
      questions: values.questions.filter((x: any) => x),
    });
    if (onSubmit) onSubmit(values);
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();
    if (onReset) onReset();
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      ...other
    } = this.props;

    return (
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion: any) => {
          return (
            <Container
              {...other}
              onSubmit={handleSubmit(this.handleSubmit)}
              onReset={this.handleReset}
            >
              <FieldArray
                name="questions"
                component={EditableList}
                addButtonLabel="Question"
                inputProps={{
                  placeholder:
                    !isMobileVersion &&
                    'example: "What\'s the regulatory environment like for roads and infrastructure in Mexico?"',
                  fullWidth: true,
                }}
              />
            </Container>
          );
        }}
      </MediaQuery>
    );
  }
}

function validate(/* values */) {
  return {};
}

// @ts-expect-error TS(2629): Cannot assign to 'QuestionsForm' because it is a c... Remove this comment to see the full error message
QuestionsForm = reduxForm({
  form: 'expertQuestions',
  validate,
  enableReinitialize: true,
  // @ts-expect-error TS(2345): Argument of type 'typeof QuestionsForm' is not ass... Remove this comment to see the full error message
})(QuestionsForm);

// @ts-expect-error TS(2629): Cannot assign to 'QuestionsForm' because it is a c... Remove this comment to see the full error message
QuestionsForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const questions = profile.questions && profile.questions.length > 0 ? profile.questions : [''];

    return {
      profile,
      initialValues: {
        questions,
      },
    };
  },
  {
    updateProfile,
  }
  // @ts-expect-error TS(2345): Argument of type 'typeof QuestionsForm' is not ass... Remove this comment to see the full error message
)(QuestionsForm);

export default QuestionsForm;
