import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import React from 'react';

import Divider from '@/components/Divider';
import FAIcon from '@/components/Icon/FAIcon';
import IconButton from '@/components/IconButton';
import Picture from '@/components/Picture';
import { white } from '@/theme/colors';

import FilterChip from './Filters/FilterChip';
import s from './SelectedProfilesBar.module.scss';

interface SelectedProfilesProps {
  onRemove: (profile: any) => void;
}

class SelectedProfiles extends React.Component<SelectedProfilesProps> {
  state = {
    showSelected: false,
  };

  toggleSelected = () =>
    this.setState((prevState) => ({
      // @ts-expect-error TS(2339) FIXME: Property 'showSelected' does not exist on type 'Re... Remove this comment to see the full error message
      showSelected: !prevState.showSelected,
    }));

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
    const { profiles, onClear, onRemove, children } = this.props;
    const { showSelected } = this.state;
    if (!profiles || profiles.length === 0) return null;
    return (
      <div>
        <div className={s.selectedProfilesTop}>
          <div className={s.selectedProfilesText}>
            {profiles.length} {profiles.length === 1 ? 'expert' : 'experts'} selected
            <Button
              onClick={onClear}
              style={{
                textTransform: 'none',
                color: '#C2C2C2',
                marginRight: 5,
              }}
            >
              Clear
            </Button>
          </div>
          <div className={s.selectedProfilesAction}>
            {children}
            <IconButton onClick={this.toggleSelected}>
              <FAIcon icon={`chevron-${showSelected ? 'up' : 'down'}`} size={18} color={white} />
            </IconButton>
          </div>
        </div>
        {showSelected && (
          <div>
            <Divider color={white} style={{ opacity: 0.2 }} />
            <div className={s.selectedProfilesChips}>
              {profiles.map((p: any) => (
                <FilterChip
                  key={p.id}
                  onDelete={() => onRemove(p)}
                  avatar={<Picture link={false} size={24} user={p} />}
                  label={p.name}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface SelectedProfilesBarProps {
  children: React.ReactNode;
  onRemove: (profile: any) => void;
  onClear: () => void;
  profiles: any[];
}

class SelectedProfilesBar extends React.Component<SelectedProfilesBarProps> {
  render() {
    const { profiles, children, onClear, onRemove } = this.props;
    return (
      <Snackbar open={profiles.length > 0} className={s.selectedProfilesBar}>
        <SnackbarContent
          className={s.selectedProfilesContent}
          classes={{ message: s.selectedProfilesContentMessage }}
          message={
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            <SelectedProfiles profiles={profiles} onClear={onClear} onRemove={onRemove}>
              {children}
            </SelectedProfiles>
          }
        />
      </Snackbar>
    );
  }
}

export default SelectedProfilesBar;
