import clsx from 'clsx';
import { useEffect } from 'react';
import { Edit2, Trash2 } from 'react-feather';
import { Navigate, useParams } from 'react-router-dom';

import Button from '@/componentsv2/Button';
import { Drawer } from '@/componentsv2/Drawer';
import { segmentTracking } from '@/core/analytics';
import { useProfileId, useSelf } from '@/hooks/store';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import KnowledgeColumn from '@/knowledge/components/KnowledgeColumn';
import { TITLE as JOBS_DRAWER_TITLE } from '@/profilev2/components/JobsDrawer';
import { useExperienceKnowledge } from '@/profilev2/queries/experienceKnowledge';
import { useJobQuery } from '@/profilev2/queries/job';
import { updateData as setAssignmentToDelete } from '@/profilev2/store/assignmentToDeleteSlice';
import { editExperience } from '@/profilev2/store/manageExperienceSlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppDispatch } from '@/store';
import { showDialog } from '@/ui/store/uiSlice';
import { jobTitleCapitalized, organizationCapitalized } from '@/utils/capitalization';
import { safeFormatDate } from '@/utils/date';

import { ContractsSection } from './ContractsSection';
import Overview from './Overview';

const testId = 'of-experience-details-drawer';

const ExperienceDetailsDrawer = ({ disableEditing }: { disableEditing?: boolean }) => {
  const dispatch = useAppDispatch();
  const profileId = useProfileId();
  const isSelf = useSelf();
  const { jobId } = useParams();
  const handleCloseDrawer = useCloseDrawer();

  const {
    data: job,
    isLoading: isJobLoading,
    isFetched: isJobFetched,
  } = useJobQuery({
    jobId: jobId ?? '',
    profileId,
  });

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title: JOBS_DRAWER_TITLE,
      to: '../' + PROFILE_ROUTES().jobs,
    },
    {
      title: organizationCapitalized(job?.organization),
    },
  ]);

  const { data: knowledges, isLoading: isKnowledgeLoading } = useExperienceKnowledge(
    { jobId: job?.id ?? '' },
    { staleTime: Infinity, refetchOnMount: 'always' }
  );

  const projectsCount =
    job?.project_members?.filter((contract) => contract.project_type === 'Project').length ?? 0;
  const proposalsCount =
    job?.project_members?.filter((contract) => contract.project_type === 'Proposal').length ?? 0;

  useEffect(() => {
    if (jobId) {
      const segmentPayload = {
        event_type: 'Job',
        event_name: 'View Job',
        job_id: jobId,
      };
      segmentTracking('view-job', segmentPayload);
    }
  }, [jobId, dispatch]);

  if (!jobId || (isJobFetched && !job)) {
    return <Navigate to=".." />;
  }

  return (
    <>
      <Drawer.Header
        onClose={handleCloseDrawer}
        breadcrumbs={breadcrumbs}
        slotActions={
          isSelf && !disableEditing ? (
            <div className="flex gap-10">
              <Button
                variant="secondary"
                size="small"
                startIcon={Trash2}
                onClick={() => {
                  dispatch(
                    setAssignmentToDelete({
                      id: job?.id ?? '',
                      isJob: true,
                    })
                  );
                  dispatch(showDialog('DELETE_ASSIGNMENT'));
                }}
              >
                Remove
              </Button>
              <Button
                size="small"
                startIcon={Edit2}
                onClick={() => dispatch(editExperience({ job: job ?? null }))}
              >
                Edit
              </Button>
            </div>
          ) : null
        }
      />
      <Drawer.Main paddingClassName="p-0" data-testid={testId}>
        <main className="lg:flex lg:h-full">
          <div className="overflow-auto p-20 lg:grow">
            <Overview
              isLoading={isJobLoading}
              title={jobTitleCapitalized(job?.title_raw_term?.name)}
              organization={organizationCapitalized(job?.organization)}
              detailsMain={[
                job?.country?.name,
                `${safeFormatDate(job?.start_date ?? false)} -
              ${safeFormatDate(job?.end_date ?? false, 'Present')}`,
              ]}
              description={job?.description || ''}
            />
            {projectsCount > 0 ? (
              <ContractsSection
                title="Project Implementation Experiences"
                assignmentTypes="Project"
                initialCount={projectsCount}
              />
            ) : null}
            {proposalsCount > 0 ? (
              <ContractsSection
                title="New Business Activity Experiences"
                assignmentTypes="Proposal"
                initialCount={proposalsCount}
              />
            ) : null}
          </div>
          <aside
            className={clsx(
              'mx-20 mb-20 rounded-md bg-white shadow-5',
              'lg:m-0 lg:h-full lg:w-[460px] lg:shrink-0 lg:rounded-0 lg:border-l lg:border-grey-400 lg:shadow-none'
            )}
          >
            <KnowledgeColumn
              key={isKnowledgeLoading ? 1 : 0}
              knowledges={knowledges?.results || []}
              isLoading={isKnowledgeLoading}
            />
          </aside>
        </main>
      </Drawer.Main>
    </>
  );
};

export { testId as ExperienceDetailsDrawerTestId };
export default ExperienceDetailsDrawer;
