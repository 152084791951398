import { dialogClasses } from '@mui/material/Dialog';
import { formControlClasses } from '@mui/material/FormControl';
import { inputBaseClasses } from '@mui/material/InputBase';
import { selectClasses } from '@mui/material/Select';

import { chipClasses } from '@/componentsv2/Chip/styles';
import { faIconClasses } from '@/componentsv2/Icon/styles';
import theme from '@/theme';
import { getStyles } from '@/utils/styles.helpers';

const nt = 'addingCollections-dialog';

export const DialogClasses = {
  dialogBtn: `${nt}-Btn`,
  buttonsContainer: `${nt}-btns-container`,
  headerCloseButton: `${nt}-header-closebtn`,
  saveButton: `${nt}-save-button`,
  chip: `${nt}__chip`,
  chipContainer: `${nt}__chipContainer`,
  chipCloseContainer: `${nt}__chipCloseContainer`,
  selected: `${nt}x__selected`,
  iconSize: `${nt}__iconSize`,
  selectContainer: `${nt}__selectContainer`,
  cta: `${nt}__selectContainer-cta`,
  ctaAction: `${nt}__selectContainer-cta-action`,
  ...dialogClasses,
  ...selectClasses,
  ...formControlClasses,
};

const styles = {
  Dialog: {
    fontFamily: 'Inter',
    [`& .${DialogClasses.iconSize}`]: {
      [`&:hover`]: {
        background: 'transparent',
      },
      [`& svg`]: {
        fontSize: '23px',
      },
    },
    [`& .${DialogClasses.cta}`]: {
      fontSize: '12px',
      color: theme.palette.grey[600],
      fontFamily: 'Inter',
      paddingTop: '8px',
      [`& .${DialogClasses.ctaAction}`]: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    [`& .${DialogClasses.selectContainer}`]: {
      [`& .${inputBaseClasses.root}`]: {
        width: '100%',
        backgroundColor: 'transpatent',
        border: 'none',
        padding: '0',
      },
    },
    [`& .${DialogClasses.paper}`]: {
      width: '100%',
      maxWidth: '600px',
    },
    [`& .${formControlClasses.root}`]: {
      width: '100%',
    },
    [`& .${DialogClasses.chipContainer}`]: {
      marginTop: '2px',
      width: '100%',
    },
    [`& .${chipClasses.root}.${DialogClasses.chip}`]: {
      marginRight: '10px',
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[700],
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: '30px',
      [`& .${DialogClasses.chipCloseContainer}`]: {
        [`& .${faIconClasses.root}`]: {
          marginLeft: '0',
          fontSize: '12px',
          fontWeight: '100',
        },
      },
      [`& .${chipClasses.label}`]: {
        paddingLeft: '13px',
      },
      [`& .${chipClasses.labelText}`]: {
        fontSize: '12px',
        whiteSpace: 'normal',
        padding: 0,
        color: theme.palette.grey[700],
        fontFamily: 'Inter',
      },
    },
    [`& .${DialogClasses.buttonsContainer}`]: {
      ...getStyles('display-flex-row'),
      margin: '23px 0',
      flexDirection: 'row-reverse',
      fontFamily: 'Inter',
    },
    [`& .${DialogClasses.dialogBtn}`]: {
      borderRadius: '5px',
    },
    [`& .${DialogClasses.saveButton}`]: {
      width: '176px',
      height: '37px',
    },
  },
};

export default styles;
