import ActionTypes from '@/actions/ActionTypes';

export default function loadingReducer(state = 0, action: any) {
  switch (action.type) {
    case ActionTypes.LOADING__SET_PROGRESS:
      return action.value;
    default:
      return state;
  }
}
