import cx from 'classnames';
import React, { PureComponent } from 'react';

import s from './FieldContainer.module.scss';

type FieldContainerProps = {
  label: string;
  className?: string;
  containerClassName?: string;
  title?: React.ReactNode;
  warningText?: React.ReactNode;
  errorText?: React.ReactNode;
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
};

class FieldContainer extends PureComponent<FieldContainerProps> {
  render() {
    const {
      label,
      labelStyle,
      children,
      containerStyle,
      className,
      containerClassName,
      title,
      errorText,
      warningText,
      ...other
    } = this.props;

    const rootClassNames = cx(s.root, className);
    const containerClassNames = cx(s.container, containerClassName, {
      [s.error]: !!errorText,
      [s.warning]: !!warningText && !errorText,
    });

    return (
      <div {...other} className={rootClassNames}>
        <div
          title={typeof title === 'string' ? title : undefined}
          className={s.label}
          style={labelStyle}
        >
          {label}
        </div>
        <div className={containerClassNames} style={containerStyle}>
          {children}
        </div>
        {errorText && <div className={s.errorText}>{errorText}</div>}
        {warningText && <div className={s.warningText}>{warningText}</div>}
      </div>
    );
  }
}

export default FieldContainer;
