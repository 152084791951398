import { useState } from 'react';
import { Plus } from 'react-feather';

import { useAssignmentsByType } from '@/assignment/queries/assignments';
import { selectNewProjectSource } from '@/assignment/store/manageProjectSlice';
import Button from '@/componentsv2/Button';
import { useProfileId, useSelf } from '@/hooks/store';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import ExperienceListDrawer, {
  ExperienceListOrdering,
} from '@/profilev2/components/ExperienceListDrawer';
import { useAppDispatch } from '@/store';

type DrawerType = 'Project' | 'Proposal';

export const TITLE_DICT: Record<DrawerType, string> = {
  Project: 'Government Projects',
  Proposal: 'New Business Activities',
};

const ContractsDrawer = ({
  type,
  disableEditing,
}: {
  type: DrawerType;
  disableEditing?: boolean;
}) => {
  const profileId = useProfileId();
  const isSelf = useSelf();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<ExperienceListOrdering>('-dates');

  const {
    data: experiences,
    isLoading,
    isRefetching,
  } = useAssignmentsByType({
    profileId,
    assignmentTypes: type,
    ordering: sortValue,
    page,
  });

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title: TITLE_DICT[type],
    },
  ]);

  return (
    <ExperienceListDrawer
      title={TITLE_DICT[type]}
      breadcrumbs={breadcrumbs}
      experiences={experiences}
      isLoading={isLoading}
      isRefetching={isRefetching}
      sortValue={sortValue}
      handleSortOrderChange={(newValue) => setSortValue(newValue)}
      page={page}
      handlePageChange={(newValue) => setPage(newValue)}
      headerSlotActions={
        isSelf && !disableEditing ? (
          <Button
            size="small"
            onClick={() => {
              dispatch(selectNewProjectSource());
            }}
            className="ml-12"
            startIcon={Plus}
          >
            {type === 'Project' ? 'Add Projects' : 'Add New Business Activity'}
          </Button>
        ) : null
      }
    />
  );
};

export default ContractsDrawer;
