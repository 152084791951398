import LoginAs from '@/auth/components/LoginAs';
import { LegacyRoute } from '@/routes/routesMiddleware';

const route: LegacyRoute = {
  //subdomain: '*',
  path: '/loginas',

  async action({ query }) {
    const user = query.get('user');
    const next = query.get('next') || undefined;

    if (!user) {
      return null;
    }

    return <LoginAs username={user} next={next} />;
  },
};

export default route;
