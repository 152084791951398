import React from 'react';

export default function (popupFn: any, isViewerExpert: any, successCallback: any) {
  return popupFn({
    title: '12 Hour Notice',
    contents: isViewerExpert ? (
      <div>
        You are rescheduling very close to the proposed consultation time. We recommend giving the
        other party at least 12 hours advance notice and suggesting alternative times.
      </div>
    ) : (
      <div>
        You are requesting the expert very close to the consultation time. We recommend giving
        experts at least 12 hours advance notice and suggesting alternative times.
      </div>
    ),
    buttonAlignment: 'space-between',
    buttons: [
      {
        label: 'Cancel',
        callback: () => {},
        flat: true,
      },
      {
        label: 'Request Anyways',
        primary: true,
        callback: successCallback,
      },
    ],
  });
}
