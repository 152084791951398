const platform = () => ({
  text: 'Platform',
  to: '/platform',
});

const becomeAnExpert = () => ({
  text: 'Become an Expert',
  to: `/signup/expert`,
});

const chat = () => ({
  text: 'Live Chat',
  onClick: () => window.HubSpotConversations?.widget?.open(),
  mobileOnly: true,
});

const login = () => ({
  text: 'Log In / Join',
  to: '/login',
  force: true,
  border: true,
  id: 'homeLoginJoin',
});

export const marketingLinks = ({
  showPlatform = true,
  showBecomeAnExpert = true,
  showChat = true,
  showLogin = true,
  ...opts
}) =>
  [
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    showPlatform && platform(opts),
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    showBecomeAnExpert && becomeAnExpert(opts),
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    showChat && chat(opts),
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    showLogin && login(opts),
  ].filter(Boolean);
