import { gengql } from '@/__generated__';
import { CollectionType } from '@/reducers/appNotifications';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { APP_NOTIFICATION__LIST, APP_NOTIFICATION__REMOVE } = ActionTypes;

export function fetchAppNotifications({
  collection = 'default',
  forceReload = false,
}: {
  collection?: CollectionType;
  forceReload?: boolean;
} = {}): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const col = getState().appNotifications[collection];
    if (!forceReload && col) return col;

    const { appNotifications } = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchAppNotifications {
          appNotifications {
            id
            service
            code
            created_at
            read_at
            dismissed_at
            payload {
              ... on RawAppNotificationPayload {
                raw
              }
              ... on NetworkExpertAcceptedInvitationAppNotificationPayload {
                profile {
                  id
                  name
                  url_endpoint
                  html_url
                  picture_url
                }
              }
              ... on GroupMemberAwaitingApprovalAppNotificationPayload {
                member {
                  id
                  group {
                    id
                    name
                    html_url
                  }
                  user {
                    id
                    name
                    html_url
                    picture_url
                  }
                }
              }
            }
          }
        }
      `)
    );

    dispatch({
      type: APP_NOTIFICATION__LIST,
      collection,
      appNotifications,
    });

    return appNotifications;
  };
}

export function readAppNotifications(ids: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionReadAppNotifications($ids: [String]) {
          readAppNotifications(ids: $ids) {
            id
          }
        }
      `),
      { ids }
    );
  };
}

export function dismissAppNotification(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { dismissAppNotification } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDismissAppNotification($id: String!) {
          dismissAppNotification(id: $id) {
            id
          }
        }
      `),
      { id }
    );

    dispatch({
      type: APP_NOTIFICATION__REMOVE,
      notificationId: dismissAppNotification!.id,
    });
  };
}
