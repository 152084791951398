import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from 'rc-tooltip';
import { useCallback } from 'react';

import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconButton from '@/components/IconButton';
import { darkGray, orange500, red500 } from '@/theme/colors';

export default function CsvContents({ form, preview, ignoredRows, globalRequestId }: any) {
  const toggleRowIgnore = useCallback(
    (row: any) => {
      form.change(
        'csv_ignore_rows',
        ignoredRows.includes(row)
          ? ignoredRows.filter((r: any) => r !== row)
          : [...ignoredRows, row].sort()
      );
    },
    [ignoredRows]
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell style={{ textAlign: 'right', width: 30 }}>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>LinkedIn URL</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell style={{ width: 75 }}>Exp. Req.</TableCell>
          <TableCell style={{ width: 60 }} />
        </TableRow>
      </TableHeader>
      <TableBody>
        {preview.profiles.map((r: any) => {
          const requestId = r.expert_request_id || globalRequestId;
          return (
            <TableRow
              key={r.row}
              hover
              style={
                ignoredRows.includes(r.row) || r.resolution === 'discarded'
                  ? { textDecoration: 'line-through' }
                  : {}
              }
            >
              <TableCell style={{ textAlign: 'right', width: 30 }}>{r.row}</TableCell>
              <TableCell title={r.name}>{r.name}</TableCell>
              <TableCell title={r.email}>{r.email}</TableCell>
              <TableCell title={r.linkedin_url}>{r.linkedin_url}</TableCell>
              <TableCell title={r.phone}>{r.phone}</TableCell>
              <TableCell style={{ width: 50 }}>
                <a target="_blank" rel="noreferrer" href={`/expert_request/${requestId}`}>
                  {requestId}
                </a>
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'right',
                  width: 75,
                }}
              >
                <div style={{ display: 'inline-block' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      width: 75,
                    }}
                  >
                    {r.warnings && r.warnings.length > 0 && (
                      <Tooltip
                        overlay={
                          <div>
                            {r.warnings.map((w: any) => (
                              <div key={w}>{w}</div>
                            ))}
                          </div>
                        }
                        trigger={['hover', 'click']}
                      >
                        <MaterialIcon icon="warning" color={orange500} />
                      </Tooltip>
                    )}
                    {r.errors && r.errors.length > 0 && (
                      <Tooltip
                        overlay={
                          <div>
                            {r.errors.map((w: any) => (
                              <div key={w}>{w}</div>
                            ))}
                          </div>
                        }
                        trigger={['hover', 'click']}
                      >
                        <MaterialIcon icon="error_outline" color={red500} />
                      </Tooltip>
                    )}
                    {r.resolution !== 'discarded' && (
                      <IconButton onClick={() => toggleRowIgnore(r.row)}>
                        <MaterialIcon icon="delete" color={darkGray} />
                      </IconButton>
                    )}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
