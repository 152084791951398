import { Form, FormikContextType, FormikProvider } from 'formik';

const testId = 'of-form';

export interface IFormikFormProps {
  className?: string;

  value: FormikContextType<any>;
  children?: React.ReactNode;
  onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const FormikForm: React.FC<IFormikFormProps> = ({ children, value, ...rest }) => {
  return (
    <FormikProvider data-testid={testId} value={value}>
      <Form onSubmit={value.handleSubmit} onReset={value.handleReset} {...rest}>
        {children}
      </Form>
    </FormikProvider>
  );
};

export { testId as FormikFormTestId };
export default FormikForm;
