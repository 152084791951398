import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { dismissAppNotification, readAppNotifications } from '@/actions/appNotification';
import { RootState } from '@/store';
import { fetchUser } from '@/store/user';
import { darkGreen } from '@/theme/colors';

import Alert from '../Alert';
import Button from '../Button';
import FAIcon from '../Icon/FAIcon';
import Link from '../Link';

function PromoteOtpAuthNotification({
  notification,
  viewer,
  readAppNotifications,
  dismissAppNotification,
}: any) {
  const [isOtpAuthEnabled, setOtpAuthEnabled] = useState(viewer.otp_auth_enabled);

  useEffect(() => {
    readAppNotifications(notification.id);
  }, []);

  useEffect(() => {
    if (viewer.otp_auth_enabled) dismissAppNotification(notification.id);
    setOtpAuthEnabled(viewer.otp_auth_enabled);
  }, [viewer.otp_auth_enabled]);

  const handleCloseClick = useCallback(() => {
    dismissAppNotification(notification.id);
  }, []);

  return isOtpAuthEnabled === false ? (
    <Alert
      icon={<FAIcon color={darkGreen} icon="lock" />}
      preset="notification"
      onRequestClose={handleCloseClick}
    >
      <div>
        <b>Set up two-factor authentication</b> to protect your account. Once enabled, logging in
        will require both your password and a verification code we send to your mobile phone or
        authenticator app or email.
        <br />
        <br />
        <Link to="/settings/security">
          <Button>Enable 2FA</Button>
        </Link>
      </div>
    </Alert>
  ) : null;
}

PromoteOtpAuthNotification.prefetch = async (store: any) => {
  const { viewer } = store.getState();
  await store.dispatch(
    fetchUser(viewer.username, {
      force: true,
      otpAuthEnabled: true,
    })
  );
};
// @ts-expect-error TS(2631): Cannot assign to 'PromoteOtpAuthNotification' beca... Remove this comment to see the full error message
PromoteOtpAuthNotification = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    readAppNotifications,
    dismissAppNotification,
  }
)(PromoteOtpAuthNotification);

export default PromoteOtpAuthNotification;
