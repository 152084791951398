import { FC, memo, useCallback, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button/Button';
import { Checkbox, Select } from '@/components/FormAdapters/FormAdapters';
import { openFileDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import { csvImportPreview } from '@/profile/store';
import { RootState } from '@/store';

import s from './ProfileFields.module.scss';

const connector = connect(
  (state: RootState) => ({
    requests: state.expertRequests.open,
    groups: ((state.groups.networks || {}).edges || []).map((g: any) => g.node),
    admins: Object.values(
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      state.groups.internal.edges.reduce((acc: any, group: any) => {
        group.node.members.edges.forEach((m: any) => {
          if (!m.node.user) return;
          acc[m.node.user.id] = m.node.user;
        });
        return acc;
      }, {})
    ),
  }),
  {
    csvImportPreview,
  }
);

interface ProfileFieldsProps {
  hasFile: boolean;
  disabled: boolean;
  form: any;
  values: any;
  setImporting: any;
}

const ProfileFields: FC<ProfileFieldsProps & ConnectedProps<typeof connector>> = memo(
  ({
    hasFile,
    disabled,
    admins,
    requests,
    groups,
    csvImportPreview,
    form,
    values,
    setImporting,
  }) => {
    const { graphql } = useApp();

    const [error, setError] = useState<Error | null>(null);

    const { network_id: networkId, not_available_marketplace: notAvailableMarketplace } = values;

    const pickFile = useCallback(async () => {
      try {
        const files = await openFileDialog(graphql.client, {
          accept: ['text/csv', '.csv'],
          maxSize: 8 * 1024 * 1024, // 8 MB
          fromSources: ['local_file_system'],
        });
        const file = files[0];
        form.change('csv_ignore_rows', []);
        form.change('csv_filename', file.filename);
        form.change('csv_url', file.url);
        form.change('csv_mimetype', file.mimetype);

        setError(null);
        setImporting(true);

        await csvImportPreview({
          file,
          networkId,
          availableMarketplace: !notAvailableMarketplace,
        });
      } catch (error) {
        setError(error as Error);
      } finally {
        setImporting(false);
      }
    }, [csvImportPreview, form, graphql.client, networkId, notAvailableMarketplace, setImporting]);

    const networkOptions = useMemo(() => {
      return groups
        .map((g: any) => g.internal_network)
        .filter(Boolean)
        .map((n: any) => ({
          label: n.name,
          value: n.id,
        }));
    }, [groups]);

    const chooseFileButton = !disabled && (
      <Button color="lightTan" size="medium" onClick={pickFile}>
        {hasFile ? 'Change File' : 'Choose File'}
      </Button>
    );

    return (
      <div>
        <div className={s.csvFormRow}>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              disabled={disabled}
              label="Add to Expert Request"
              name="request_id"
              options={(requests || { edges: [] }).edges.map((r: any) => ({
                value: r.node.id,
                label: `${r.node.name} (${r.node.id})`,
              }))}
            />
          </div>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              label="Uploaded By"
              name="created_by"
              options={(admins || []).map((r: any) => ({
                value: r.id,
                label: `${r.name} (${r.id})`,
              }))}
            />
          </div>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              disabled={disabled}
              label="Add to network"
              name="network_id"
              options={networkOptions}
            />
            <Field
              component={Checkbox}
              type="checkbox"
              disabled={disabled}
              name="not_available_marketplace"
              label="Do not add to marketplace"
            />
          </div>
        </div>
        <div>
          {error ? (
            <div className={s.errorPanel}>
              <div className={s.errorMessage}>
                {error?.toString ? error.toString() : JSON.stringify(error)}
              </div>
              {chooseFileButton}
            </div>
          ) : (
            chooseFileButton
          )}
        </div>
      </div>
    );
  }
);
ProfileFields.displayName = 'ProfileFields';

export default connector(ProfileFields);
