import React from 'react';

import Link from '../Link';

function ConsultationRef({ consultation }: any) {
  if (!consultation) return <span>consultation</span>;
  return <Link to={consultation.html_url}>consultation</Link>;
}

export default ConsultationRef;
