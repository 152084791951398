import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const {
  EXPERT_SHOWCASE__UPDATE,
  EXPERT_SHOWCASE__REMOVE,
  EXPERT_SHOWCASE__BATCH_ADD,
  EXPERT_SHOWCASE_PROFILES__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__UPDATE,
  EXPERT_SHOWCASE_SECTION__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__REMOVE,
  EXPERT_SHOWCASE_SECTION__REMOVE,
} = ActionTypes;

export const EXPERT_SHOWCASE_FIELDS = gengql(/* GraphQL */ `
  fragment ExpertShowcaseFields on ExpertShowcase {
    id
    subdomain
    path
    page_title
    background_url
    header_logo_url
    header_logo_link_url
    meta_image_url
    title
    subtitle
    header_note
    ctas {
      id
      label
      url
      order
    }
    categories {
      id
      name
      order
      sections {
        id
        icon
        name
        slug
        order
        cta_label
        cta_url
      }
    }
    google_tag_manager_id
    show_footer
  }
`);

const UPDATE_EXPERT_SHOWCASE = gengql(/* GraphQL */ `
  mutation actionUpdateExpertShowcase(
    $id: String
    $subdomain: String
    $path: String
    $page_title: String
    $title: String
    $subtitle: String
    $header_note: String
    $background_url: String
    $header_logo_url: String
    $header_logo_link_url: String
    $meta_image_url: String
    $google_tag_manager_id: String
    $show_footer: Boolean
    $ctas: [ExpertShowcaseCtaInput!]
    $categories: [ExpertShowcaseCategoryInput!]
  ) {
    updateExpertShowcase(
      id: $id
      subdomain: $subdomain
      path: $path
      page_title: $page_title
      title: $title
      subtitle: $subtitle
      header_note: $header_note
      background_url: $background_url
      header_logo_url: $header_logo_url
      header_logo_link_url: $header_logo_link_url
      meta_image_url: $meta_image_url
      google_tag_manager_id: $google_tag_manager_id
      show_footer: $show_footer
      ctas: $ctas
      categories: $categories
    ) {
      ...ExpertShowcaseFields
    }
  }
`);

export function fetchExpertShowcase({ subdomain, path, id }: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-ignore
    const pages = getState().expertShowcase[subdomain];
    const cachedPage = pages && pages[path];
    if (cachedPage) return cachedPage;

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query getExpertShowcase($subdomain: String, $path: String, $id: String) {
          expertShowcase(subdomain: $subdomain, path: $path, id: $id) {
            ...ExpertShowcaseFields
          }
        }
      `),
      { subdomain, path, id }
    );

    const page = result.expertShowcase;

    if (page) {
      dispatch({
        type: EXPERT_SHOWCASE__UPDATE,
        subdomain,
        path,
        ...page,
        page,
      });
    }

    return page;
  };
}

export function updateExpertShowcase(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: UPDATE_EXPERT_SHOWCASE,
      variables: options,
    });

    const page = data!.updateExpertShowcase;

    if (page) {
      dispatch({ type: EXPERT_SHOWCASE__UPDATE, ...page, page });
    }

    return page;
  };
}

export function removeExpertShowcase(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { removeExpertShowcase } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveExpertShowcase($id: String!) {
          removeExpertShowcase(id: $id)
        }
      `),
      { id }
    );

    if (removeExpertShowcase) {
      dispatch({ type: EXPERT_SHOWCASE__REMOVE, id });
    }

    return removeExpertShowcase;
  };
}

export function fetchExpertShowcases(): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchExpertShowcases {
          expertShowcases {
            id
            subdomain
            path
            title
          }
        }
      `)
    );

    const pages = result.expertShowcases;

    if (pages) {
      dispatch({ type: EXPERT_SHOWCASE__BATCH_ADD, pages });
    }

    return pages;
  };
}

export function fetchExperts(subdomain: any, path: any, sectionId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchExperts($sectionId: String!) {
          expertShowcaseProfiles(section_id: $sectionId) {
            id
            highlight
            badge_text
            name
            picture
            city
            country
            experience
            title
            summary
            marketing_keywords
            url
            can_request_consultation
          }
        }
      `),
      { sectionId }
    );

    const experts = result.expertShowcaseProfiles;

    if (experts) {
      dispatch({
        type: EXPERT_SHOWCASE_PROFILES__UPDATE,
        subdomain,
        path,
        sectionId,
        experts,
      });
    }

    return experts;
  };
}

export function fetchExpertShowcaseCategory(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionfetchExpertShowcaseCategory($id: String!) {
          expertShowcaseCategory(id: $id) {
            id
            name
            order
            showcase_id
            sections {
              id
              icon
              name
              slug
              order
              cta_label
              cta_url
            }
          }
        }
      `),
      { id }
    );

    const category = result.expertShowcaseCategory;

    if (category) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__UPDATE, category });
    }

    return category;
  };
}

const UPDATE_EXPERT_SHOWCASE_CATEGORY = gengql(/* GraphQL */ `
  mutation actionUpdateExpertShowcaseCategory(
    $id: String!
    $name: String
    $order: Int
    $sections: [ExpertShowcaseSectionInput!]
  ) {
    updateExpertShowcaseCategory(id: $id, name: $name, order: $order, sections: $sections) {
      id
      name
      order
      showcase_id
      sections {
        id
        icon
        name
        slug
        order
        cta_label
        cta_url
      }
    }
  }
`);

export function updateExpertShowcaseCategory(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(UPDATE_EXPERT_SHOWCASE_CATEGORY, options);
    const category = result.updateExpertShowcaseCategory;

    if (category) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__UPDATE, category });
    }

    return category;
  };
}

export function removeExpertShowcaseCategory(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveExpertShowcaseCategory($id: String!) {
          removeExpertShowcaseCategory(id: $id)
        }
      `),
      { id }
    );

    const removeExpertShowcaseCategory = data?.removeExpertShowcaseCategory;
    if (removeExpertShowcaseCategory) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__REMOVE, id });
    }

    return removeExpertShowcaseCategory;
  };
}

export function fetchExpertShowcaseSection(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchExpertShowcaseSection($id: String!) {
          expertShowcaseSection(id: $id) {
            id
            icon
            name
            slug
            order
            category_id
            cta_label
            cta_url
            profiles_raw {
              id
              name
              picture
              city
              country
              experience
              title
              summary
              marketing_keywords
              profile_id
              profile_name
              badge_text
              highlight
              order
            }
          }
        }
      `),
      { id }
    );

    const section = result.expertShowcaseSection;

    if (section) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__UPDATE, section });
    }

    return section;
  };
}

const UPDATE_EXPERT_SHOWCASE_SECTION = gengql(/* GraphQL */ `
  mutation actionUpdateExpertShowcaseSection(
    $id: String!
    $icon: String
    $name: String
    $slug: String
    $order: Int
    $profiles: [ExpertShowcaseProfileInput!]
    $cta_label: String
    $cta_url: String
  ) {
    updateExpertShowcaseSection(
      id: $id
      icon: $icon
      name: $name
      slug: $slug
      order: $order
      profiles: $profiles
      cta_label: $cta_label
      cta_url: $cta_url
    ) {
      id
      icon
      name
      slug
      order
      category_id
      cta_label
      cta_url
      profiles_raw {
        id
        name
        picture
        city
        country
        experience
        title
        summary
        marketing_keywords
        profile_id
        profile_name
        badge_text
        highlight
        order
      }
    }
  }
`);

export function updateExpertShowcaseSection(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(UPDATE_EXPERT_SHOWCASE_SECTION, options);
    const section = result.updateExpertShowcaseSection;

    if (section) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__UPDATE, section });
    }

    return section;
  };
}

export function removeExpertShowcaseSection(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { removeExpertShowcaseSection } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionRemoveExpertShowcaseSection($id: String!) {
          removeExpertShowcaseSection(id: $id)
        }
      `),
      { id }
    );

    if (removeExpertShowcaseSection) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__REMOVE, id });
    }

    return removeExpertShowcaseSection;
  };
}
