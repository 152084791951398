export const displayFlex = {
  display: 'flex',
};
export const flexColumn = {
  ...displayFlex,
  flexDirection: 'column',
};
export const flexRow = {
  ...displayFlex,
  flexDirection: 'row',
};
export const justifySpaceBetween = {
  justifyContent: 'space-between',
};
export const justifySpaceAround = {
  justifyContent: 'space-around',
};
export const placeContentStretch = {
  placeContent: 'stretch',
  alignItems: 'stretch',
};
export const spaceEvenly = {
  placeContent: 'center space-evenly',
};
export const alignItemsStart = {
  alignItems: 'start',
};

export const flexFill = {
  flex: '1 1 100%',
};

const helperStyles = {
  'display-flex': displayFlex,
  'display-flex-row': flexRow,
  'display-flex-column': flexColumn,
  'flex-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'flex-column-reverse': {
    flexDirection: 'column-reverse',
  },
  /* main axis */
  'justify-content-between': justifySpaceBetween,
  'justify-content-around': justifySpaceAround,
  'justify-content-start': { justifyContent: 'flex-start' },
  'justify-content-end': { justifyContent: 'flex-end' },
  'justify-content-center': { justifyContent: 'center' },
  'justify-content-evenly': { justifyContent: 'space-evenly' },
  /* cross axis */
  'align-items-start': alignItemsStart,
  'align-items-end': { alignItems: 'flex-end' },
  'align-items-center': { alignItems: 'center' },
  'align-items-baseline': { alignItems: 'baseline' },
  'align-items-stretch': { alignItems: 'stretch' },

  'space-evenly': spaceEvenly,
  'place-content-stretch': placeContentStretch,

  'flex-fill': flexFill,

  'flex-wrap': {
    flexWrap: 'wrap',
  },
  'flex-no-wrap': {
    flexWrap: 'nowrap',
  },
  'flex-wrap-reverse': {
    flexWrap: 'wrap-reverse',
  },
  'flex-auto': {
    flex: '1 1 auto',
  },
  'flex-none': { flex: 'none' },
  flex: { flex: 1 },
  'flex-2': { flex: 2 },
  'flex-3': { flex: 3 },
  'flex-4': { flex: 4 },
  'flex-5': { flex: 5 },
  'flex-6': { flex: 6 },
  'flex-7': { flex: 7 },
  'flex-8': { flex: 8 },
  'flex-9': { flex: 9 },
  'flex-10': { flex: 10 },
  'flex-11': { flex: 11 },
  'flex-12': { flex: 12 },
};
/**
 * Merges objects
 */

export const Combine = (...args: any[]) => {
  let output = {};

  args.forEach((a) => {
    output = Object.assign(a, output);
  });
  return output;
};

type StyleKey = keyof typeof helperStyles;

export const getStyles = (...args: StyleKey[]) => {
  let output = {};
  args.forEach((k) => {
    output = Combine(output, helperStyles[k]);
  });
  return output;
};
