import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@/components/Button/Button';
import EmptyMessage from '@/components/EmptyMessage';
import { removeExperience, saveExperience } from '@/profile/store';

import Experience from './Experience';

const validate = (values: any) => {
  const errors = {};

  const experienceErrors: any = [];
  values.experiences.forEach((exp: any, i: any) => {
    if (emptyExperience(exp)) {
      // empty experience form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!exp.title) {
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      err.title = 'Required';
    }

    if (!exp.organization) {
      // @ts-expect-error TS(2339): Property 'organization' does not exist on type '{}... Remove this comment to see the full error message
      err.organization = 'Required';
    }

    if (exp.start_date && !exp.current && !exp.end_date) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'Required';
    }

    if (exp.start_date && !moment(exp.start_date).isValid()) {
      // @ts-expect-error TS(2339): Property 'start_date' does not exist on type '{}'.
      err.start_date = 'Start date must be a valid date';
    }

    if (exp.end_date && !moment(exp.end_date).isValid()) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End date must be a valid date';
    }

    if (exp.start_date && exp.end_date && moment(exp.end_date).isBefore(exp.start_date)) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End date must be after start date';
    }

    if (Object.keys(err).length > 0) {
      experienceErrors[i] = err;
    }
  });

  if (experienceErrors.length) {
    // @ts-expect-error TS(2339): Property 'experiences' does not exist on type '{}'... Remove this comment to see the full error message
    errors.experiences = experienceErrors;
  }

  return errors;
};

function emptyExperience(exp: any) {
  if (exp.title) return false;
  if (exp.location) return false;
  if (exp.organization) return false;
  if (exp.role) return false;
  if (exp.start_date) return false;
  if (exp.end_date) return false;
  if (exp.current) return false;
  if (exp.description) return false;

  return true;
}

function Experiences({ fields, onAdd, change }: any) {
  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field: any, index: any) => (
          <Experience
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            experience={fields.get(index)}
            field={field}
            key={field}
            onRemove={() => fields.remove(index)}
            change={change}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add your first work experience"
          action={<Button onClick={onAdd}>Add a Work Experience</Button>}
        />
      )}
    </div>
  );
}

class ExperiencesForm extends PureComponent {
  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { profile, onSubmit } = this.props;
    const experiences = values.experiences.filter((exp: any) => !emptyExperience(exp));

    const itemsToDelete = profile.experiences.filter(
      (current: any) => !experiences.find((edited: any) => edited.id === current.id)
    );

    itemsToDelete.forEach((w: any) =>
      // @ts-expect-error TS(2339): Property 'removeExperience' does not exist on type... Remove this comment to see the full error message
      this.props.removeExperience(profile.id, w.id)
    );

    // @ts-expect-error TS(2339): Property 'saveExperience' does not exist on type '... Remove this comment to see the full error message
    experiences.forEach((w: any) => this.props.saveExperience(profile.id, w));

    if (onSubmit) {
      onSubmit({ experiences });
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
      component: Container,
      // @ts-expect-error TS(2339): Property 'array' does not exist on type 'Readonly<... Remove this comment to see the full error message
      array,
      // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
      change,
      // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      ...other
    } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <FieldArray
          name="experiences"
          component={Experiences}
          onAdd={() => array.push('experiences', {})}
          change={change}
        />
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const initialExperiences =
      profile.experiences && profile.experiences.length > 0 ? profile.experiences : [{}];
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const form = state.form.expertExperiences;
    const initialValues = { experiences: initialExperiences };
    const formValues = form ? form.values : initialValues;

    return {
      profile,
      formValues,
      initialValues,
    };
  },
  {
    saveExperience,
    removeExperience,
  }
)(
  reduxForm({
    form: 'expertExperiences',
    validate,
    enableReinitialize: true,
    // @ts-expect-error TS(2345): Argument of type 'typeof ExperiencesForm' is not a... Remove this comment to see the full error message
  })(ExperiencesForm)
);
