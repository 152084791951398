import { UnknownAction } from 'redux';

import { getUserContextOptions } from '@/core/user';
import { AppThunk } from '@/store';
import { setCache } from '@/utils';

import ActionTypes from './ActionTypes';

export function notify(message = '', messageType = 'success', duration = 8000): UnknownAction {
  return {
    type: ActionTypes.UI__SHOW_MESSAGE,
    message,
    messageType,
    duration,
  };
}

export function hideMessage(): UnknownAction {
  return {
    type: ActionTypes.UI__HIDE_MESSAGE,
  };
}

export function popup({
  layout,
  title,
  contents,
  buttons,
  links,
  contentStyle,
  buttonAlignment,
}: any): UnknownAction {
  return {
    type: ActionTypes.UI__SHOW_POPUP,
    layout,
    title,
    contents,
    buttons,
    links,
    contentStyle,
    buttonAlignment,
  };
}

export function hidePopup(): UnknownAction {
  return {
    type: ActionTypes.UI__HIDE_POPUP,
  };
}

export function setUserContext(userContext: any): AppThunk {
  return (dispatch, getState) => {
    if (!userContext) throw new Error('user context cannot be empty');

    const { viewer } = getState();
    const groups = getState().groups.all.edges.map((e: any) => e.node);
    const userContextOptions = getUserContextOptions(viewer, userContext, groups);

    setCache('user_context', userContext);

    dispatch({
      type: ActionTypes.UI__SET_USER_CONTEXT,
      userContext,
      userContextOptions,
    });
  };
}

export function saveUserView(userId: string): UnknownAction {
  return {
    type: ActionTypes.UI__SAVE_USER_VIEW,
    userId,
  };
}
