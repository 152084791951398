import TextField from '@mui/material/TextField';
import React, { PureComponent } from 'react';

import FAIcon from '@/components/Icon/FAIcon';
import { darkGreen } from '@/theme/colors';

import s from './ExperienceFilter.module.scss';
import Filter from './Filter';
import FilterButton from './FilterButton';
import FilterChip from './FilterChip';
import FilterSelect from './FilterSelect';

interface ExperienceFilterProps {
  title: string;

  placeholder: string;

  currentRestriction: string;

  values: string[];

  onCurrentRestrictionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onAddValue: (value: string) => void;

  onRemoveValue: (value: string) => void;
}

class ExperienceFilter extends PureComponent<ExperienceFilterProps> {
  textField: any;
  state = {
    text: '',
    startDate: '',
    endDate: '',
  };

  handleDateChange = (field: any, v: any) => {
    const trimmed = v && v.trim();
    const shouldChange = !trimmed || (v.length <= 4 && Number.isInteger(Number(trimmed)));
    this.setState({
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      [field]: shouldChange ? trimmed : this.state[field],
    });
  };

  handleAdd = () => {
    const { text, startDate, endDate } = this.state;

    const startYear = startDate.length === 4 && Number(startDate);
    const endYear = endDate.length === 4 && Number(endDate);

    // @ts-expect-error TS(2339) FIXME: Property 'onAddValue' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.onAddValue({
      text,
      start_date: Number.isInteger(startYear)
        ? // @ts-expect-error TS(2345) FIXME: Argument of type 'number | false' is not assignabl... Remove this comment to see the full error message
          new Date(startYear, 0, 1, 0, 0, 0)
        : undefined, // inclusive
      end_date: Number.isInteger(endYear)
        ? // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'number | ... Remove this comment to see the full error message
          new Date(endYear + 1, 0, 1, 0, 0, 0)
        : undefined, // exclusive
    });

    this.setState({
      text: '',
      startDate: '',
      endDate: '',
    });

    if (this.textField) this.textField.focus();
  };

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && this.state.text) {
      this.handleAdd();
    }
  };

  render() {
    const {
      title,
      placeholder,
      currentRestriction,
      values,
      onRemoveValue,
      onCurrentRestrictionChange,
    } = this.props;
    const { text, startDate, endDate } = this.state;

    const yearInputStyle = {
      flex: '1 1 38px',
      width: 'auto',
      fontSize: 14,
      margin: '0 5px',
    };

    return (
      <Filter title={title}>
        <FilterSelect
          value={currentRestriction}
          onChange={onCurrentRestrictionChange}
          options={[
            { value: 'none', label: 'Current or Past' },
            { value: 'any_current', label: 'Current' },
            { value: 'all_past', label: 'Past, not current' },
          ]}
        />

        <TextField
          ref={(ref) => (this.textField = ref)}
          value={text}
          placeholder={placeholder}
          InputProps={{ style: { fontSize: 14 } }}
          onChange={(e) => this.setState({ text: e.target.value })}
          onKeyPress={this.handleKeyPress}
        />

        <div className={s.dateRange}>
          <FAIcon
            iconSet="far"
            icon="calendar"
            color={darkGreen}
            size={20}
            style={{ verticalAlign: 'middle', marginRight: 5 }}
          />
          <span className={s.dateRangeLabel}>From</span>&nbsp;
          <TextField
            placeholder="yyyy"
            style={yearInputStyle}
            InputProps={{ style: { fontSize: 14 } }}
            value={startDate}
            onChange={(e) => this.handleDateChange('startDate', e.target.value)}
            onKeyPress={this.handleKeyPress}
          />
          <span className={s.dateRangeLabel}>to</span>&nbsp;
          <TextField
            placeholder="yyyy"
            style={yearInputStyle}
            InputProps={{ style: { fontSize: 14 } }}
            value={endDate}
            onChange={(e) => this.handleDateChange('endDate', e.target.value)}
            onKeyPress={this.handleKeyPress}
          />
          <FilterButton style={{ marginLeft: 10 }} disabled={!text} onClick={this.handleAdd} />
        </div>

        <div className={s.filterChips}>
          {values.map((value: any) => {
            const { start_date: startDate, end_date: endDate } = value;
            let { text } = value;
            if (startDate || endDate) {
              text = `${value.text} ${
                startDate ? startDate.getFullYear() : '?'
              }-${endDate ? endDate.getFullYear() - 1 : '?'}`;
            }

            return <FilterChip key={text} onDelete={() => onRemoveValue(value)} label={text} />;
          })}
        </div>
      </Filter>
    );
  }
}

export default ExperienceFilter;
