import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { useProjectCollaboratorsQuery } from '@/assignment/queries/projectLeaders';
import ColleaguesGrid from '@/componentsv2/ColleaguesGrid';
import { Drawer } from '@/componentsv2/Drawer';
import { useProfileId } from '@/hooks/store';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { DEFAULT_COLLABORATORS_LIMIT } from '@/searchv2/queries/searchCollaborators';
import { mq } from '@/theme/screens';

const WorkedWith = ({ projectId }: { projectId?: string }) => {
  const profileId = useProfileId();
  const [page, setPage] = useState(1);
  const isDesktop = useMediaQuery(mq.md);
  const limit = isDesktop ? DEFAULT_COLLABORATORS_LIMIT : 6;

  const { data, isLoading, isPreviousData } = useProjectCollaboratorsQuery({
    profileId,
    projectId: projectId ?? '',
    page,
    limit,
  });

  const colleagues = data?.results?.map((colleague) => ({
    full_name: colleague.full_name,
    picture_url: colleague.picture_url,
    project_member_title: colleague.project_member_title,
    id: colleague.id,
    mutual_projects_count: colleague.mutual_experiences,
  }));

  return (
    <Drawer.Section title="Collaborators" className="mt-20" childrenContainerClassName="p-16">
      <ColleaguesGrid
        colleagues={{ ...data, results: colleagues }}
        isLoading={isLoading}
        isPreviousData={isPreviousData}
        page={page}
        limit={limit}
        handlePageChange={(newPage) => setPage(newPage)}
        useColleagueLink
        colleagueUrl={(id) => '../' + PROFILE_ROUTES().colleague(id)}
        trackingPayload={{
          triggered_by: 'collaborators',
        }}
      />
    </Drawer.Section>
  );
};

export default WorkedWith;
