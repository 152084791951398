/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Country } from '../models/Country';
import type { PaginatedCountryList } from '../models/PaginatedCountryList';
import type { PatchedCountry } from '../models/PatchedCountry';

export class CountryService {
  /**
   * List all countries
   * List all countries
   * @param limit Number of results to return per page.
   * @param name Filter the countries list by name
   * @param offset The initial index from which to return the results.
   * @returns PaginatedCountryList
   * @throws ApiError
   */
  public static countryList(
    limit?: number,
    name?: string,
    offset?: number
  ): CancelablePromise<PaginatedCountryList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/country/',
      query: {
        limit: limit,
        name: name,
        offset: offset,
      },
    });
  }

  /**
   * Create a country
   * Create a country
   * @param requestBody
   * @returns Country
   * @throws ApiError
   */
  public static countryCreate(requestBody?: Country): CancelablePromise<Country> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/country/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a country by UUID
   * Retrieve a country by UUID
   * @param id A UUID string identifying this country.
   * @returns Country
   * @throws ApiError
   */
  public static countryRetrieve(id: string): CancelablePromise<Country> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/country/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a country by UUID
   * Completely update a country by UUID
   * @param id A UUID string identifying this country.
   * @param requestBody
   * @returns Country
   * @throws ApiError
   */
  public static countryUpdate(id: string, requestBody?: Country): CancelablePromise<Country> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/country/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a country by UUID
   * Update specific fields within a country by UUID
   * @param id A UUID string identifying this country.
   * @param requestBody
   * @returns Country
   * @throws ApiError
   */
  public static countryPartialUpdate(
    id: string,
    requestBody?: PatchedCountry
  ): CancelablePromise<Country> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/country/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a country by UUID
   * Delete a country by UUID
   * @param id A UUID string identifying this country.
   * @returns void
   * @throws ApiError
   */
  public static countryDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/country/{id}/',
      path: {
        id: id,
      },
    });
  }
}
