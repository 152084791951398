import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';

import {
  CancelablePromise,
  PaginatedProfileCertificationList,
  ProfileCertification,
  ProfileCertificationCreate,
  ProfileCertificationService,
  ProfileCertificationUpdate,
} from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<PaginatedProfileCertificationList>;

export const fetchData = createAsyncThunk(
  'profileCertifications/fetchData',
  async (profileId: string) => {
    request?.cancel();
    request = ProfileCertificationService.profileCertificationList(1000, 0, profileId);
    return request;
  }
);
export const createCertification = createAsyncThunk(
  'profileCertifications/createCertification',
  async (payload: ProfileCertificationCreate) =>
    await ProfileCertificationService.profileCertificationCreate(payload)
);
export const updateCertification = createAsyncThunk(
  'profileCertifications/updateCertification',
  async (payload: ProfileCertificationUpdate) =>
    await ProfileCertificationService.profileCertificationUpdate(payload.id, payload)
);
export const deleteCertification = createAsyncThunk(
  'profileCertifications/deleteCertification',
  (id: string) => ProfileCertificationService.profileCertificationDestroy(id).then(() => id)
);

export const resetData = createAsyncThunk(
  'profileCertifications/resetData',
  async (_, { dispatch }) => {
    request?.cancel();
    dispatch(profileCertificationsSlice.actions.reset());
  }
);

const profileCertificationsSlice = createSlice({
  name: 'profileCertifications',
  initialState: initialState.profileCertifications,
  reducers: {
    reset: () => initialState.profileCertifications,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(createCertification.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;
        if (draft.data.results) {
          draft.data.results.push({
            ...(action.payload as ProfileCertification),
            certificate: action.payload.certificate_by_name ?? '',
            organization: action.payload.certificate_by_name || action.payload.organization || '',
          });
        }
      });
    });
    builder.addCase(updateCertification.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;
        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((a) => a.id === action.payload.id);
        if (draft.data.results) {
          draft.data.results[index] = {
            ...(action.payload as ProfileCertification),
            certificate: action.payload.certificate_by_name ?? '',
            organization: action.payload.certificate_by_name || action.payload.organization || '',
          };
        }
      });
    });
    builder.addCase(deleteCertification.fulfilled, (state, action) => {
      return produce(state, (draft) => {
        draft.isLoading = false;

        const newData = draft?.data?.results ?? [];
        const index = newData.findIndex((i) => i.id === action.payload);

        if (draft.data.results) {
          draft.data.results.splice(index, 1);
        }
      });
    });
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const profileCertificationsSelector = (state: IState) => state.profileCertifications;
export default profileCertificationsSlice.reducer;
