import Grid from '@mui/material/Grid';

import { darkBrown, darkGray, darkGreen } from '@/theme/colors';

import SvgIcon from '../Icon/SvgIcon';

export default function FeatureTile({
  icon,
  title,
  children,
  contentClassName,
  iconProps,
  contentStyle,
  titleStyle,
  textStyle,
  ...other
}: any) {
  return (
    <Grid xs={12} md={6} lg={3} item {...other}>
      <div className={contentClassName} style={contentStyle}>
        {icon && (
          <div style={{ marginBottom: 20 }}>
            <SvgIcon icon={icon} fill={darkGreen} {...iconProps} />
          </div>
        )}
        <h3
          style={{
            color: darkBrown,
            marginTop: 0,
            marginBottom: 15,
            ...titleStyle,
          }}
        >
          {title}
        </h3>
        <div style={{ color: darkGray, lineHeight: '22px', ...textStyle }}>{children}</div>
      </div>
    </Grid>
  );
}
