import { Helmet } from 'react-helmet';

import { fetchCountries } from '@/actions/country';
import { fetchAllGroups } from '@/actions/group';
import { fetchSectors } from '@/actions/sector';
import LayoutPage from '@/components/Layout/LayoutPage';
import { fetchExpertRequests } from '@/expertrequest/store';
import NotFoundPage from '@/pages/NotFoundPage';
import { fetchProfile } from '@/profile/store';
import { LegacyRoute } from '@/routes/routesMiddleware';

import ProfileMerge from './ProfileMerge';

const route: LegacyRoute = {
  path: '/merge/:destProfileUrlEndpoint/:srcProfileUrlEndpoint',

  async action({ store, params }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return <NotFoundPage />;

    const destEndpoint = params.destProfileUrlEndpoint!;
    const srcEndpoint = params.srcProfileUrlEndpoint!;

    const [destProfile, srcProfile] = await Promise.all([
      store.dispatch(
        fetchProfile(destEndpoint, {
          leadOnly: false,
          internalNetworks: true,
          expertRequestCandidates: true,
          experiences: true,
          addresses: true,
          expertise: true,
          education: true,
          sources: true,
          rawPictureUrl: true,
        })
      ),
      store.dispatch(
        fetchProfile(srcEndpoint, {
          leadOnly: false,
          internalNetworks: true,
          expertRequestCandidates: true,
          experiences: true,
          addresses: true,
          education: true,
          sources: true,
          rawPictureUrl: true,
        })
      ),
      store.dispatch(fetchCountries()),
      store.dispatch(fetchSectors()),
      store.dispatch(fetchExpertRequests({ withGroupOnly: false })),
      store.dispatch(
        fetchAllGroups({
          collection: 'networks',
          internalNetwork: true,
        })
      ),
    ]);

    if (!destProfile || !srcProfile) return <NotFoundPage />;
    return (
      <LayoutPage showNav>
        <Helmet>
          <title>Profile Merge</title>
        </Helmet>
        <ProfileMerge existing={destProfile} conflicting={srcProfile} />
      </LayoutPage>
    );
  },
};

export default route;
