import React from 'react';
import { connect } from 'react-redux';

import Button from '@/components/Button';
import EmptyMessage from '@/components/EmptyMessage';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import { teal500 } from '@/theme/colors';
import { isUserApplying } from '@/utils';

class EmailValidated extends React.Component {
  handleResendVerification: any;
  render() {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer } = this.props;
    const action =
      viewer.signup_type === 'expert' && isUserApplying(viewer) ? (
        <Link to="/signup/expert">
          <Button style={{ marginTop: 15 }} onClick={this.handleResendVerification}>
            Continue to Expert Application →
          </Button>
        </Link>
      ) : (
        <Link to="/dashboard">
          <Button size="large" style={{ marginTop: 15 }} onClick={this.handleResendVerification}>
            Go to Dashboard →
          </Button>
        </Link>
      );

    return (
      <LayoutPage hideSearch showReviewConsultation={false} showNewRequest={false}>
        <EmptyMessage
          border={false}
          iconName="check_circle"
          iconColor={teal500}
          title="Your email has been verified"
          action={action}
        />
      </LayoutPage>
    );
  }
}

export default connect((state) => ({
  // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'DefaultR... Remove this comment to see the full error message
  viewer: state.viewer,
}))(EmailValidated);
