export const MoreSeparator = (props: { count: number }) => {
  return (
    <li className="flex w-full items-center px-20 py-8">
      <div className="grow border-t border-grey-400" />
      <div className="shrink items-center justify-center px-10 text-grey-600 util-caption">
        ··· {props.count} More
      </div>
      <div className="grow border-t border-grey-400" />
    </li>
  );
};
