export const AUTH__UPDATE = 'AUTH__UPDATE';
export const BASIC_AUTH__UPDATE = 'BASIC_AUTH__UPDATE';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case AUTH__UPDATE:
      return {
        ...state,
        otpAuthUri: action.otpAuthUri,
      };
    case BASIC_AUTH__UPDATE:
      return {
        ...state,
        basicAuthentication: action.basicAuthentication,
      };
    default:
      return state;
  }
}
