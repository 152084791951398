/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedAssignmentsList } from '../models/PaginatedAssignmentsList';

export class AssignmentsService {
  /**
   * List all Assignments
   * List all Assignments
   * @param assignmentTypes Filter the assignments by a list of pipe-separated assignment type names
   * @param colleagueId Find the assignments that are common between the profile and a given colleague
   * @param jobId Get the project experiences associated to a Job
   * @param limit Number of results to return per page.
   * @param name Filter the assignments by name. This will match title, work_title name and organization name
   * @param offset The initial index from which to return the results.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param profileId Filter the assignments by profile id
   * @returns PaginatedAssignmentsList
   * @throws ApiError
   */
  public static assignmentsList(
    assignmentTypes?: string,
    colleagueId?: string,
    jobId?: string,
    limit?: number,
    name?: string,
    offset?: number,
    ordering:
      | ''
      | '-assignment_country'
      | '-dates'
      | '-title'
      | '-title_raw_term_name'
      | 'assignment_country'
      | 'dates'
      | 'title'
      | 'title_raw_term_name' = '',
    profileId?: string
  ): CancelablePromise<PaginatedAssignmentsList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/assignments/',
      query: {
        assignment_types: assignmentTypes,
        colleague_id: colleagueId,
        job_id: jobId,
        limit: limit,
        name: name,
        offset: offset,
        ordering: ordering,
        profile__id: profileId,
      },
    });
  }
}
