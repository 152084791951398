import { redirect } from 'react-router-dom';

import { gengql } from '@/__generated__';
import Layout from '@/components/Layout';
import { AppThunk } from '@/store';
import { darkBlue } from '@/theme/colors';

import { ActionContext, LegacyRoute } from '../routesMiddleware';
import ComplianceTrainingStep from './ComplianceTrainingStep';
import ComplianceWizard from './ComplianceWizard';
import Confirm from './Confirm';
import Done from './Done';
import Questions from './Questions';
import questions from './questions.json';

/**
 * @returns {Promise<void>}
 */
export function completeComplianceTraining(): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    return await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionCompleteComplianceTraining {
          completeComplianceTraining {
            id
            name
          }
        }
      `)
    );
  };
}

const title = 'Compliance Training';
const baseUrl = '/compliance_training';

const steps = [
  { path: 'welcome' },
  { path: 'introduction' },
  { path: 'overview' },
  { path: 'conflicts-of-interest-1' },
  { path: 'conflicts-of-interest-2' },
  {
    path: 'questions-set-1',
    component: (
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: { id: number; text: string; ans... Remove this comment to see the full error message
      <Questions questions={questions.slice(0, 3)} subTitle="CONFLICTS OF INTEREST (QUIZ)" />
    ),
  },
  { path: 'current-employer-1' },
  { path: 'current-employer-2' },
  {
    path: 'questions-set-2',
    component: (
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: { id: number; text: string; ans... Remove this comment to see the full error message
      <Questions questions={questions.slice(3, 5)} subTitle="CURRENT EMPLOYER (QUIZ)" />
    ),
  },
  { path: 'confidentiality' },
  {
    path: 'questions-set-3',
    component: (
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: { id: number; text: string; ans... Remove this comment to see the full error message
      <Questions questions={questions.slice(5, 9)} subTitle="CONFIDENTIAL INFORMATION (QUIZ)" />
    ),
  },
  { path: 'anti-corruption-1' },
  { path: 'anti-corruption-2' },
  {
    path: 'questions-set-4',
    component: (
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: { id: number; text: string; ans... Remove this comment to see the full error message
      <Questions questions={questions.slice(9, 11)} subTitle="ANTI-CORRUPTION (QUIZ)" />
    ),
  },
  { path: 'contractual-obligations' },
  { path: 'conditions-for-specific-professions' },
  { path: 'almost-done', component: <Confirm /> },
  { path: 'done' },
];

async function action({ store, params }: ActionContext) {
  const step = steps.find((s) => s.path === params.step);
  const { consultationId, confirmTime } = params;
  const confirmTimeSafe = confirmTime || '';
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ path: string; component?: unde... Remove this comment to see the full error message
  const stepIndex = steps.indexOf(step);
  const { viewer, expertComplianceTraining } = store.getState();

  if (viewer.compliance_completed_at) {
    return redirect(
      consultationId
        ? `/consultation/${consultationId}?confirm_time=${confirmTimeSafe}`
        : '/dashboard'
    );
  }

  const urlSuffix = consultationId ? `/consultation/${consultationId}/${confirmTimeSafe}` : '';

  if (!step) {
    return redirect(`${baseUrl}/welcome${urlSuffix}`);
  }

  // We should not redirect on the server as we don't have the user training state to check if
  // particular step has not been completed
  const notCompletedStep = steps.slice(0, stepIndex).find((s) => {
    const userSteps = expertComplianceTraining.steps[viewer.id];
    const stepNotCompleted = !userSteps || !userSteps[s.path];
    return stepNotCompleted;
  });

  if (notCompletedStep) {
    return redirect(`${baseUrl}/${notCompletedStep.path}${urlSuffix}`);
  }

  const hasNext = stepIndex < steps.length - 1;
  const nextPath = hasNext && steps[stepIndex + 1].path;
  const nextUrl = hasNext && `${baseUrl}/${nextPath}${urlSuffix}`;

  if (params.step === 'done') {
    await store.dispatch(completeComplianceTraining());
  }

  let component;
  if (step.path === 'done') {
    component = <Done consultationId={consultationId} confirmTime={confirmTime} />;
  } else if (step.component) {
    component = step.component;
  } else {
    const content = (await import(`./content/${params.step}.md`)).default;
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    component = <ComplianceTrainingStep html={content.html} />;
  }

  const backgroundStyle = step.path === 'done' ? { backgroundColor: darkBlue } : undefined;

  document.title = title;
  return (
    <Layout style={backgroundStyle} hideSearch>
      <ComplianceWizard
        completed={stepIndex / steps.length}
        nextUrl={nextUrl}
        path={step.path}
        user={viewer}
      >
        {component}
      </ComplianceWizard>
    </Layout>
  );
}

const paths = [
  `${baseUrl}`,
  `${baseUrl}/:step`,
  `${baseUrl}/consultation/:consultationId?/:confirmTime?`,
  `${baseUrl}/:step/consultation/:consultationId?/:confirmTime?`,
];

const routes: LegacyRoute[] = paths.map((path) => ({
  path,
  action,
}));

export default routes;
