import React, { PureComponent } from 'react';

import Button from '../Button/Button';
import CustomCheckbox from '../Checkbox';
import MaterialIcon from '../Icon/MaterialIcon';
import EditReviewDialog from './EditReviewDialog';
import s from './ReviewCard.module.scss';
import StarRating from './StarRating';

function Field({ title, value }: any) {
  return (
    <div className={s.field}>
      <div className={s.label}>{title}</div>
      <div className={s.value}>{value}</div>
    </div>
  );
}

class ReviewCard extends PureComponent {
  state = {
    dialogOpened: false,
  };

  handleOpen = () => this.setState({ dialogOpened: true });

  handleClose = () => this.setState({ dialogOpened: false });

  render() {
    // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { title, review, reviewFromExpert, targetName, updateReviewEnabled } = this.props;

    const {
      match_experience_rating: matchExperienceRating,
      uselfulness_rating: uselfulnessRating,
      communication_rating: communicationRating,
      connection_quality_rating: connectionQualityRating,
      expert_usefulness_rating: expertUsefulnessRating,
      time_savings_feedback: timeSavings,
      improved_bid_confidence_feedback: improvedBidConfidence,
      partner_identification_feedback: partnerIdentification,
      cost_savings_feedback: costSavings,
      new_opportunity_identification_feedback: newOpportunityIdentification,
      other_feedback: otherSelected,
      other_text_feedback: otherText,
      target_feedback: targetFeedback,
      onfrontiers_feedback: onfrontiersFeedback,
    } = review;

    return (
      <div className={s.root}>
        <div className={s.title}>{title}</div>

        {review.review ? (
          <p className={s.review}>“{review.review}”</p>
        ) : (
          updateReviewEnabled && (
            <React.Fragment>
              <Button
                color="secondary"
                startIcon={<MaterialIcon icon="post_add" />}
                size="small"
                onClick={this.handleOpen}
              >
                Add Review Content
              </Button>
              <EditReviewDialog
                open={this.state.dialogOpened}
                review={review}
                onClose={this.handleClose}
              />
            </React.Fragment>
          )
        )}
        {!reviewFromExpert && (
          <>
            <StarRating
              style={{ marginTop: 30 }}
              title="How useful was the information the expert provided for this opportunity?"
              editing={false}
              name="expert_usefulness_rating"
              value={expertUsefulnessRating}
            />
            <div style={{ marginTop: 30 }}>
              What Value did you get from this call? Check all that apply
            </div>
            <CustomCheckbox
              name="time_savings_feedback"
              label="Time Savings (Increase speed to next decision gate)"
              disabled={true}
              checked={timeSavings}
            />
            <CustomCheckbox
              name="improved_bid_confidence_feedback"
              label="Improved Bid Confidence (The information provided gave me an edge I needed to improve my Pwin)"
              disabled={true}
              checked={improvedBidConfidence}
            />
            <CustomCheckbox
              name="partner_identification_feedback"
              label="Partner Identification (Was able to identify Prime or Sub Prime Contractors I could Partern with)"
              disabled={true}
              checked={partnerIdentification}
            />
            <CustomCheckbox
              name="cost_savings_feedback"
              label="Cost Savings (This call saved me from having to engage a consultant or consulting firm on a larger engagement to get the same info)"
              disabled={true}
              checked={costSavings}
            />
            <CustomCheckbox
              name="new_opportunity_identification_feedback"
              label="New Opportunity Identification (This call helped me find additional opportunities to bid on)"
              disabled={true}
              checked={newOpportunityIdentification}
            />
            <CustomCheckbox
              name="other_selected_feedback"
              label="Other"
              disabled={true}
              checked={otherSelected}
            />
            {otherSelected && <Field title="Other" value={otherText} />}
          </>
        )}
        {reviewFromExpert ? (
          <StarRating
            title="Match for my Experience"
            editing={false}
            name="match_experience_rating"
            value={matchExperienceRating}
          />
        ) : (
          <StarRating
            title="Match for my Request"
            editing={false}
            name="uselfulness_rating"
            value={uselfulnessRating}
          />
        )}

        <StarRating
          title="Communication"
          editing={false}
          name="communication_rating"
          value={communicationRating}
        />

        <StarRating
          title="Connection Quality"
          editing={false}
          name="connection_quality_rating"
          value={connectionQualityRating}
        />

        {targetFeedback && (
          <Field title={`Private Feedback to ${targetName}`} value={targetFeedback} />
        )}

        {onfrontiersFeedback && (
          <Field title="Private Feedback to OnFrontiers" value={onfrontiersFeedback} />
        )}
      </div>
    );
  }
}

export default ReviewCard;
