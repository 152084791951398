import loadingSVG from './loading.svg';

const UpdatingIndicator = ({ text = 'Updating' }: { text?: string }) => {
  return (
    <div className="flex flex-shrink-0 items-center gap-4 text-12 text-grey-600">
      <div className="animate-spin">
        <img src={loadingSVG} alt="Loading" width={16} height={16} />
      </div>
      {text}
    </div>
  );
};

export default UpdatingIndicator;
