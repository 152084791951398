import clsx from 'clsx';
import React from 'react';

import { Box, Pagination } from '@/componentsv2';
import { useProfileId } from '@/hooks/store';
import { arrayFromRange } from '@/utils/array';

import ProfileCard from './ProfileCard';
import { IColleaguesProps } from './types';

const testId = 'of-colleagues-grid';

const PLACEHOLDER = 'More experiences required to display network';

const Skeletons = ({ limit }: { limit: number }) => {
  return (
    <>
      {arrayFromRange({ max: limit }).map((_, i) => (
        <ProfileCard key={i} />
      ))}
    </>
  );
};

const ColleaguesGrid: React.FC<IColleaguesProps> = ({
  className,
  colleagues,
  isLoading,
  isPreviousData,
  page,
  handlePageChange,
  paginationClassName,
  useColleagueLink,
  colleagueUrl,
  limit,
  trackingPayload,
}) => {
  const profileId = useProfileId();

  return (
    <>
      <Box
        data-testid={testId}
        component={colleagues?.results?.length ? 'ul' : 'div'}
        className={clsx(
          className,
          'grid grid-cols-2 gap-x-16 gap-y-24 transition-opacity duration-200 sm:grid-cols-3',
          {
            'opacity-50': isPreviousData,
          }
        )}
      >
        {colleagues?.results?.length ? (
          colleagues.results.map((colleague) =>
            colleague.id !== profileId ? (
              <ProfileCard
                key={colleague.id}
                profile={colleague}
                useColleagueLink={useColleagueLink}
                colleagueUrl={colleagueUrl}
                trackingPayload={trackingPayload}
              />
            ) : null
          )
        ) : isLoading ? (
          <Skeletons limit={limit} />
        ) : (
          <p className="col-span-3 text-grey-500 body-2">{PLACEHOLDER}</p>
        )}
      </Box>

      {colleagues?.count ? (
        <Pagination
          className={paginationClassName}
          page={page}
          count={Math.ceil(colleagues.count / limit)}
          handlePageChange={(_, page) => {
            handlePageChange(page);
          }}
        />
      ) : null}
    </>
  );
};

export { testId as ColleaguesTestId };
export type { IColleaguesProps };
export default ColleaguesGrid;
