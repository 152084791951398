import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { EngagementType } from '@/__generated__/graphql';
import { fetchAllGroups } from '@/actions/group';
import config from '@/config';
import { getEngagementType } from '@/consultation';
import {
  fetchPredictTransactions,
  isFixedRate as isConsultationFixedRate,
  validateExpertConsultationPreferences,
} from '@/consultation/store';
import { money } from '@/core/money';
import {
  fetchCandidate,
  fetchExpertRequestMembers,
  fetchExpertRequests,
  isFixedRate as isERFixedRate,
} from '@/expertrequest/store';
import ProfileTitle from '@/profile/components/ProfileTitle';
import { RootState } from '@/store';

import WrittenEngagementFields from '../RequestWrittenEngagement/Fields';
import Actions from './Actions';
import ConsultationFields from './Fields';
import LegalNotes from './LegalNotes';
import s from './RequestConsultation.module.scss';
import { ERR_OUT_OF_MARKETPLACE, MSG_OUT_OF_MARKETPLACE } from './constants';

const useStyles = makeStyles({
  paper: {
    padding: 24,
  },
  paperWidthSm: {
    maxWidth: 640,
  },
  paperFullScreen: {
    boxSizing: 'border-box',
  },
});

export interface FormData {
  dates: moment.Moment[];
  expert_request_id: string;
  group_id: string;
  requester_id: string;
  disclosure: string;
  engagement_type: EngagementType;
  booking_fee: number;
  duration: string;
  tracking_code: string;
  marketplace_error: string;
}

interface RequestConsultationDialogProps {
  expertId: string;
  profile: any;
  open: boolean;
  onClose: () => void;
  expertRequest: any;
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    expertRequests: state.expertRequests.open,
    groups: state.groups.all,
    popupOpen: !!state.ui.popup,
  }),
  {
    fetchExpertRequests,
    fetchCandidate,
    fetchAllGroups,
    fetchExpertRequestMembers,
    fetchPredictTransactions,
    validateExpertConsultationPreferences,
  }
);

const RequestConsultationDialog: FC<
  RequestConsultationDialogProps & ConnectedProps<typeof connector> & FormRenderProps<FormData>
> = ({
  expertId,
  profile,
  open,
  onClose,
  expertRequest: initialExpertRequest,

  // Redux State
  viewer,

  expertRequests,
  groups,
  popupOpen,

  // Redux Actions
  fetchExpertRequests,
  fetchCandidate,
  fetchAllGroups,
  fetchExpertRequestMembers,
  fetchPredictTransactions,
  validateExpertConsultationPreferences,

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,
  initialValues,
}) => {
  if (viewer.groups?.length === 1) {
    // if the viewer is associated with exactly 1 group, then simply
    // pre-select it for them (as it would be their only option),
    // and the "Add to team" dropdown will be hidden.
    values.group_id = viewer.groups[0].id;
  }

  // State
  const [expertRequest, setExpertRequest] = useState(initialExpertRequest);
  const [candidate, setCandidate] = useState<any>();
  const [isFixedRate, setIsFixedRate] = useState(false);
  const [expertCredits, setExpertCredits] = useState();
  const type = getEngagementType(expertRequest);

  // Form Values
  const {
    expert_request_id: expertRequestId,
    booking_fee: bookingFee,
    engagement_type: engagementType,
    duration,
  } = values;

  let groupId = values.group_id;
  if (viewer.groups && viewer.groups.length === 1) {
    // if the viewer is associated with exactly 1 group, then simply
    // pre-select it for them (as it would be their only option),
    // and the "Add to team" dropdown will be hidden.
    groupId = viewer.groups[0].id;
  }

  // Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const creditRate = useMemo(
    () => (candidate && candidate.credit_rate) || profile.credit_rate,
    [candidate, profile]
  );

  const enableOpportunityCall = useMemo(
    () => config.enableOpportunityCallAccountIds.includes(groupId) || !!viewer.admin,
    [groupId, viewer.admin]
  );

  // Initial fetch
  useEffect(() => {
    if (!open) return;
    // If there is no initial ER or that ER is a deprecated type, fetch
    // ERs to allow the user to attach the consultation to an existing ER.
    if (!initialExpertRequest) {
      fetchExpertRequests({ state: 'open' });
    }
    fetchAllGroups();
  }, [fetchAllGroups, fetchExpertRequests, initialExpertRequest, open]);

  // Store form in a ref to avoid dependency issues
  const formRef = useRef(form);

  // Update the ref whenever form changes
  useEffect(() => {
    formRef.current = form;
  }, [form]);

  // Fetch expert request
  useEffect(() => {
    if (!open) return;

    (async function () {
      let request = expertRequest;
      if (!initialExpertRequest) {
        request = expertRequestId ? await fetchExpertRequestMembers(expertRequestId) : null;
        setExpertRequest(request);
      }

      if (request) {
        formRef.current.change('group_id', request.project?.group?.id);
        formRef.current.change('disclosure', request.disclosure);
        formRef.current.change('tracking_code', request.project?.tracking_code);
        formRef.current.change('engagement_type', getEngagementType(request));
      } else {
        formRef.current.change('group_id', initialValues.group_id);
        formRef.current.change('tracking_code', initialValues.tracking_code);
      }
    })();

    // KT: looks like form is not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    expertRequestId,
    expertRequest,
    initialExpertRequest,
    fetchExpertRequestMembers,
    getEngagementType,
    initialValues.group_id,
    initialValues.tracking_code,
  ]);

  // Fetch candidate
  useEffect(() => {
    if (!open) return;

    (async function () {
      const candidate = expertRequestId ? await fetchCandidate(expertRequestId, profile.id) : null;

      setCandidate(candidate);
    })();
  }, [open, expertRequest, expertRequestId, fetchCandidate, profile.id]);

  useEffect(() => {
    if (!open) return;

    // Fetch booking fee
    if (expertRequestId || groupId) {
      (async function () {
        const predictTransactions = await fetchPredictTransactions(
          expertId,
          expertRequestId,
          groupId,
          engagementType,
          duration
        );
        form.change('booking_fee', money(predictTransactions.booking_fee).cents || 0);
        setExpertCredits(money(predictTransactions.client_expert_fee).cents || 0);
      })();
    }

    // Validate expert's preferences
    (async function () {
      if (!groupId) return;

      let warning;
      let error;
      try {
        await validateExpertConsultationPreferences({
          expertId,
          engagementType,
          expertRequestId,
          groupId,
        });
      } catch (err: any) {
        if (err.message !== ERR_OUT_OF_MARKETPLACE) {
          throw err;
        }
        if (viewer.admin) {
          warning = MSG_OUT_OF_MARKETPLACE;
        } else {
          error = MSG_OUT_OF_MARKETPLACE;
        }
      } finally {
        form.mutators.setFieldData('group_id', { warning });
        form.change('marketplace_error', error);
      }
    })();

    // KT: looks like form is not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    groupId,
    engagementType,
    expertRequestId,
    duration,
    fetchPredictTransactions,
    expertId,
    validateExpertConsultationPreferences,
    viewer.admin,
  ]);

  useEffect(() => {
    setIsFixedRate(
      isConsultationFixedRate(engagementType) || isERFixedRate(expertRequest?.er_type)
    );
  }, [open, expertRequest, engagementType]);

  const isWrittenEngagement = type === EngagementType.WrittenResponse;
  const Fields = isWrittenEngagement ? WrittenEngagementFields : ConsultationFields;
  return (
    <Dialog
      open={open && !popupOpen}
      scroll="body"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm,
        paperFullScreen: classes.paperFullScreen,
      }}
    >
      <ProfileTitle
        profile={profile}
        requestLabel={isWrittenEngagement ? 'Request Written Engagement' : 'Request Call'}
      />

      <div className={s.message} style={enableOpportunityCall ? {} : { marginBottom: 0 }}>
        If {profile.first_name} accepts,{' '}
        {isWrittenEngagement
          ? 'you can instantly start sharing documents.'
          : 'your call will be instantly confirmed.'}
      </div>

      <form onSubmit={handleSubmit}>
        <Fields
          viewer={viewer}
          profile={profile}
          expertRequests={expertRequests}
          groups={groups}
          groupId={groupId}
          enableOpportunityCall={enableOpportunityCall}
          expertRequest={expertRequest}
          isFixedRate={isFixedRate}
          creditRate={creditRate}
        />

        <LegalNotes
          showEngagementAgreement={candidate ? candidate.show_engagement_agreement : true}
          bookingFee={bookingFee}
        />

        <Actions
          bookingFee={bookingFee}
          expertCredits={expertCredits}
          submitting={submitting}
          onCancel={onClose}
        />
      </form>
    </Dialog>
  );
};

export default connector(RequestConsultationDialog);
