import { IconButton } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import { primary } from '@/theme/colors';

import CircularProgress from '../CircularProgress/CircularProgress';
import MaterialIcon from '../Icon/MaterialIcon';
import s from './AudioPlayer.module.scss';

type PlayerState = 'loading' | 'playing' | 'paused' | 'error';

interface AudioPlayerProps {
  recordingUrl: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ recordingUrl }) => {
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [playerState, setPlayerState] = useState<PlayerState>('loading');

  useEffect(() => {
    (async function drawAudio() {
      const WaveSurfer = (
        await import(
          /* webpackChunkName: "wavesurfer" */
          'wavesurfer.js'
        )
      ).default;

      wavesurfer.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: primary,
        progressColor: primary,
        // barWidth: 1,
        // barHeight: 1,
      });

      wavesurfer.current.on('ready', () => {
        setPlayerState('paused');
      });

      wavesurfer.current.on('error', () => {
        setPlayerState('error');
      });

      wavesurfer.current.load(recordingUrl);
    })();

    return () => {
      if (wavesurfer.current) wavesurfer.current.pause();
    };
  }, [recordingUrl]);

  const play = useCallback(() => {
    if (!wavesurfer.current) return;

    wavesurfer.current.play();
    setPlayerState('playing');
  }, []);

  const pause = useCallback(() => {
    if (!wavesurfer.current) return;

    wavesurfer.current.pause();
    setPlayerState('paused');
  }, []);

  return (
    <div>
      <h3>Recording</h3>

      {playerState !== 'error' && <div id="waveform" />}

      <PlayerControls playerState={playerState} onPlay={play} onPause={pause} />
    </div>
  );
};

interface PlayerControlsProps {
  playerState: PlayerState;
  onPlay: () => void;
  onPause: () => void;
}

const PlayerControls: FC<PlayerControlsProps> = ({ playerState, onPlay, onPause }) => {
  return (
    <div className={s.playerControls}>
      {playerState === 'error' && 'Recording could not be loaded.'}

      {playerState === 'loading' && <CircularProgress />}

      {playerState === 'playing' && (
        <IconButton style={{ width: 'auto', height: 'auto' }} onClick={onPause}>
          <MaterialIcon icon="pause_circle_outline" color={primary} size={44} />
        </IconButton>
      )}
      {playerState === 'paused' && (
        <IconButton style={{ width: 'auto', height: 'auto' }} onClick={onPlay}>
          <MaterialIcon icon="play_circle_outline" color={primary} size={44} />
        </IconButton>
      )}
    </div>
  );
};

export default AudioPlayer;
