import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FilterRequest } from '@/core/pagination';
import { CancelablePromise, PaginatedTitleRawTermList, TitleRawTermService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<PaginatedTitleRawTermList>;

export const fetchData = createAsyncThunk(
  'titleRawTerm/fetchData',
  async (filters: FilterRequest) => {
    filters.cancelPrevious && request?.cancel();
    request = TitleRawTermService.titleRawTermList(
      filters.hasTaxonomy,
      true,
      filters.limit,
      filters.name,
      filters.offset,
      filters.query
    );

    return request;
  }
);

const titleRawTermSlice = createSlice({
  name: 'titleRawTerm',
  initialState: initialState.titleRawTerm,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedTitleRawTermList>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    resetData() {
      return initialState.titleRawTerm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      error: null,
    }));
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const titleRawTermsSelector = (state: IState) => state.titleRawTerm;
export const { updateData, resetData } = titleRawTermSlice.actions;
export default titleRawTermSlice.reducer;
