import { avatarClasses } from '@mui/material/Avatar';

import theme from '@/theme';

const ns = 'step-card';

export const stepCardClasses = {
  root: `${ns}__root`,
  body: `${ns}__details`,
  container: `${ns}__container`,
};

const styles = {
  stepCard: {
    padding: '36px',
    backgroundColor: 'white',
    borderRadius: '11px',

    [`& .${stepCardClasses.container}`]: {
      display: 'grid',
      rowGap: '42px',
    },
    [`& .${avatarClasses.root}`]: {
      margin: 'auto',
      // Keeps a margin top if the icon is not set
      marginTop: '-26px',
      width: '200px',
      height: '200px',
      backgroundColor: theme.palette.grey[100],
    },
    [`& .${stepCardClasses.body}`]: {
      display: 'grid',
      rowGap: '20px',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
};

export default styles;
