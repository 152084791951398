import { INITIAL_COMPLETED_TASKS } from '@/dashboardv2/config/all-tasks';
import { TASKS, onboardingSelector } from '@/dashboardv2/store/onboardingSlice';
import { useAppSelector } from '@/store';

const useTasks = () => {
  const { tasks: allTasks } = useAppSelector(onboardingSelector);

  const supportedTaskSlugs = Object.values(TASKS) as string[];

  const tasks = allTasks.filter(({ slug }) => supportedTaskSlugs.includes(slug));

  const completedTasks = [
    ...tasks.filter((task) => Number(task.progress) === 100),
    ...INITIAL_COMPLETED_TASKS,
  ];

  const incompleteTasks = tasks.filter((task) => Number(task.progress) !== 100);

  return { completedTasks, incompleteTasks };
};

export default useTasks;
