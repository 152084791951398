import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

const getStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    marginLeft: 10,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
}));

function RateAdvice({ className, user: { available_self_service: availableSelfService } }: any) {
  const s = getStyles();

  return (
    <div className={cx(s.root, className)}>
      <p>Rate changes will apply only to future opportunities.</p>

      {!availableSelfService && (
        <p>
          If you participate in an expert pool, changing your rate may trigger a re-approval by the
          pool curator.
        </p>
      )}
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'RateAdvice' because it is a func... Remove this comment to see the full error message
RateAdvice = connect((state, ownProps) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const profile = state.profiles.fullProfiles[ownProps.profileId];
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const user = state.users[ownProps.userId] || {};
  return {
    profile,
    user,
  };
})(RateAdvice);

export default RateAdvice;
