import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import { Fragment } from 'react';

import DatePicker, { IDatePickerProps } from '@/componentsv2/DatePicker';
import { isRequiredError } from '@/utils/input-helpers';

interface FormikFieldProps extends Partial<IDatePickerProps> {
  name: string;
  containerClassName?: string;
  className?: string;

  container?: any;
}

export const FormikDatePicker = ({
  name,
  container: Container = Fragment,
  containerClassName,
  onChange,
  TextFieldProps,
  disableMaskedInput,
  // the props should be the union of the props of Component
  ...rest
}: FormikFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { value, touched, error } = meta;
  const { setValue, setTouched } = helpers;
  const containerProps: { className?: string } = {};

  if (Container !== Fragment) {
    containerProps['className'] = containerClassName;
  }

  return (
    <Container {...containerProps}>
      <DatePicker
        {...field}
        {...rest}
        onChange={(value) => {
          setValue(value);
          onChange?.(value);
        }}
        disableMaskedInput={disableMaskedInput}
        value={value}
        InputProps={{
          onBlur: () => setTouched(true),
          onFocus: () => setTouched(false),
        }}
        TextFieldProps={{
          ...TextFieldProps,
          error: touched && !!error,
          abbreviateRequired: true,
        }}
      />
      {touched && error && !isRequiredError(error) ? (
        <FormHelperText error={!!error}>{error}</FormHelperText>
      ) : null}
    </Container>
  );
};

export default FormikDatePicker;
