import React, { createContext, useContext, useEffect, useState } from 'react';

import { VoipCarrier } from '@/conference/voipCarrier';
import { GraphQLClient } from '@/core/api';
import ApiWebSocket from '@/core/apiWebSocket';
import { PermissionRequest, PermissionService } from '@/core/permissions';
import { Viewer } from '@/core/viewer';
import SendbirdHelper from '@/messaging/sendbird';
import { AppStore } from '@/store';

interface Context {
  viewer: Viewer;
  store: AppStore;
  graphql: GraphQLClient;
  location: Location;
  sendbird: SendbirdHelper;
  voipCarrier: VoipCarrier;
  permission: PermissionService;
  apiWebSocket: ApiWebSocket;
  capabilities: {
    screen: {
      width: number;
    };
  };
}

type AppContextData = Context;

interface AppProviderProps {
  children: React.ReactNode;
  context: Context;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC<AppProviderProps> = ({ children, context }) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

function makePermKey(service: string, action: string, resource?: string | null) {
  return `${service}#${action}#${resource ? resource : ''}`;
}

export function usePermissions(requests: PermissionRequest[], deps?: React.DependencyList) {
  const { permission } = useApp();
  const [cache, setCache] = useState<Partial<Record<string, boolean>>>({});

  useEffect(() => {
    (async function fetchPerms() {
      const perms = await permission.allowedBatch(requests);
      const permMap = Object.fromEntries(
        perms.map((perm) => [makePermKey(perm.service, perm.action, perm.resource), perm.allowed])
      );
      setCache((cache) => ({ ...cache, ...permMap }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), permission]);

  return requests.map((req) => cache[makePermKey(req.service, req.action, req.resource)]);
}

export function useApp(): AppContextData {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useApp must be user within an AppProvider');
  }

  return context;
}
