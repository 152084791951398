import React, { Fragment, useCallback } from 'react';

import FilterAutocomplete from './FilterAutocomplete';
import FilterChips from './FilterChips';
import FilterSelect from './FilterSelect';

export default function ({
  placeholder,
  options,
  value,
  autocomplete,
  onAddValue,
  onRemoveValue,
  disableUnderline,
  multiple,
  onChange,
}: any) {
  const handleAdd = useCallback((_: unknown, newValue: { value: any }) => {
    onAddValue(newValue.value);
  }, []);

  if (autocomplete) {
    const selectedOpts = options.filter((o: { value: any }) => value.includes(o.value));
    const availableOpts = options.filter((o: { value: any }) => !value.includes(o.value));

    return (
      <Fragment>
        <FilterAutocomplete
          onChange={handleAdd}
          placeholder={placeholder}
          options={availableOpts}
          value={value}
          disableUnderline={disableUnderline}
        />
        <FilterChips
          options={selectedOpts}
          onRemoveValue={(o: { value: any }) => onRemoveValue(o.value)}
        />
      </Fragment>
    );
  }

  return (
    <FilterSelect
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      value={value}
    />
  );
}
