import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, PaginatedProfileProjectLeaderList, ProjectService } from '@/openapi';

export const DEFAULT_COLLABORATORS_LIMIT = 9;

type FetchCollaborators = {
  profileId: string;
  projectId: string;
  page?: number;
  limit?: number;
};

export type CollaboratorsUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  keepPreviousData?: boolean;
  refetchOnMount?: boolean | 'always';
};

const fetchProjectCollaborators = ({
  profileId,
  projectId,
  page = 1,
  limit = DEFAULT_COLLABORATORS_LIMIT,
}: FetchCollaborators): CancelablePromise<PaginatedProfileProjectLeaderList> => {
  const offset = (page - 1) * limit;
  return ProjectService.projectLeadersList(projectId, profileId, limit, offset);
};

export const useProjectCollaboratorsQuery = (
  { profileId, projectId, page, limit }: FetchCollaborators,
  options?: CollaboratorsUseQueryOptions
) => {
  return useQuery({
    queryKey: ['project-leaders', { profileId, projectId, page, limit }],
    queryFn: () => fetchProjectCollaborators({ profileId, projectId, page, limit }),
    keepPreviousData: true,
    enabled: !!projectId,
    ...options,
  });
};
