import React, { PureComponent } from 'react';

import Breadcrumbs from '../Breadcrumbs';
import s from './Settings.module.scss';
import SettingsNav from './SettingsNav';

interface SettingsProps {
  nav: any;
  path: string;
  pathPrefix: string;
  title: string;
  component: React.ComponentType<any>;
  componentProps: any;
  breadcrumbs?: any[];
  subTitle?: React.ReactNode;
}

class Settings extends PureComponent<SettingsProps> {
  render() {
    const {
      nav,
      path,
      pathPrefix,
      title,
      component: Page,
      componentProps,
      breadcrumbs,
      subTitle,
    } = this.props;

    return (
      <div className={s.root}>
        {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
        <div className={s.left}>
          <SettingsNav nav={nav} pathPrefix={pathPrefix} selected={path} />
        </div>
        <div className={s.right}>
          {subTitle}
          <h2 className={s.title}>{title}</h2>
          <Page {...componentProps} />
        </div>
      </div>
    );
  }
}

export default Settings;
