import { Fab as FloatingActionButton } from '@mui/material';
import { Component } from 'react';
import { connect } from 'react-redux';

import Button from '@/components/Button/Button';
import CircularProgress from '@/components/CircularProgress';
import EmptyMessage from '@/components/EmptyMessage';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import ProjectPreview from '@/components/ProjectPreview';
import TeamAccountPromoButton from '@/components/TeamAccountPromo/TeamAccountPromoButton';
import { darkBlue } from '@/theme/colors';

import ExpertApplicationPromo from './ExpertApplicationPromo';
import s from './Projects.module.scss';

function ExpertRequestPromo() {
  const body = (
    <span>
      Have a difficult question and no time for research?
      <br />
      Tell us about it and start talking to a local Expert.
    </span>
  );

  return (
    <EmptyMessage
      icon={<MaterialIcon icon="person_add" color={darkBlue} size={60} />}
      title="You don't have any expert requests"
      body={body}
      action={
        <TeamAccountPromoButton
          component={Button}
          label="New Expert Request"
          size="large"
          primary
        />
      }
    />
  );
}

interface ProjectsProps {
  // other props
  projects: { edges: any[]; loading: boolean };
  isViewerApplying: boolean;
}

class Projects extends Component<ProjectsProps> {
  render() {
    const { projects, isViewerApplying } = this.props;

    const filteredPrjs = (projects.edges || [])
      // filter out closed exp reqs
      .map((p: any) => ({
        ...p,

        node: {
          ...p.node,
          expert_requests: (p.node.expert_requests || []).filter(
            (er: any) => er.state !== 'closed'
          ),
        },
      }))
      // filter out empty projects
      .filter((p: any) => (p.node.expert_requests || []).length > 0)
      .map((p: any) => p.node);

    return (
      <div>
        <div className={s.header}>
          <h3 className={s.title}>Expert Requests</h3>
          <TeamAccountPromoButton component={FloatingActionButton}>
            <MaterialIcon icon="add" />
          </TeamAccountPromoButton>
        </div>

        {filteredPrjs.map((p: any) => (
          <ProjectPreview key={p.id} project={p} showState={false} showContextActions />
        ))}

        {projects.loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}

        {!projects.loading &&
          filteredPrjs.length === 0 &&
          (isViewerApplying ? <ExpertApplicationPromo /> : <ExpertRequestPromo />)}
      </div>
    );
  }
}

export default connect((state) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  projects: state.projects.dashboard,
}))(Projects);
