// @ts-nocheck
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { GridSortModel } from '@mui/x-data-grid';
import clsx from 'clsx';
import { useState } from 'react';
import { Plus } from 'react-feather';
import { useDebounce } from 'usehooks-ts';

import Button from '@/componentsv2/Button';
import DataGridTable from '@/componentsv2/DataGridTable';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';
import { useProfileId } from '@/hooks/store';
import { KnowledgeTypeSegments } from '@/knowledge/components/KnowledgeListDrawer';
import {
  EXPERT_LEVEL_ENUM,
  ExpertLevelEnumType,
  KNOWLEDGE_TYPE_ENUM,
  KnowledgeEnumType,
  useAllKnowledgesQuery,
} from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { ProfileKnowledgeScore } from '@/openapi';
import ProfileDeleteKnowledge from '@/profilev2/components/dialogs/ProfileDeleteKnowledge';
import { showDrawer as showAddKnowledgeDrawer } from '@/profilev2/store/addKnowledgeSlice';
import { useAppDispatch, useAppSelector } from '@/store';
import { capitalizer } from '@/utils/texts';

import { knowledgeTableColumns } from './knowledgeTableColumns';
import { IKnowledgeTableProps } from './types';

const testId = 'of-knowledge-table';

const whiteSelectedBg = {
  MenuProps: {
    sx: {
      '&& .Mui-selected': {
        backgroundColor: 'white',
      },
    },
  },
};

type KnowledgesOrdering = '' | '-percentile' | '-value' | 'percentile' | 'value';

type SegmentType = 'Topics' | 'Activities' | 'Tools' | 'Complementary Skills';

const SEGMENTS_KNOWLEDGE_TYPE_DICT: Record<SegmentType, KnowledgeEnumType> = {
  Topics: KNOWLEDGE_TYPE_ENUM.TOPICS,
  Activities: KNOWLEDGE_TYPE_ENUM.ACTIVITIES,
  Tools: KNOWLEDGE_TYPE_ENUM.TOOLS,
  'Complementary Skills': KNOWLEDGE_TYPE_ENUM.GENERAL,
};

export const SEGMENTS_KNOWLEDGE_TYPE_DICT_REVERSE: {
  [key: string]: KnowledgeTypeSegments;
} = {
  [KNOWLEDGE_TYPE_ENUM.TOPICS]: 'expertise',
  [KNOWLEDGE_TYPE_ENUM.ACTIVITIES]: 'specialized-activities',
  [KNOWLEDGE_TYPE_ENUM.TOOLS]: 'tools',
  [KNOWLEDGE_TYPE_ENUM.GENERAL]: 'general',
  'Transversal Skill': 'general',
  'Intrinsic Ability': 'general',
};

const ALL_LEVELS = Object.values(EXPERT_LEVEL_ENUM);
const ALL_KNOWLEDGE_TYPES = Object.keys(SEGMENTS_KNOWLEDGE_TYPE_DICT).map(
  (key) => key as SegmentType
);

export const displaySegment = (type: KnowledgeEnumType) => {
  if (KNOWLEDGE_TYPE_ENUM.GENERAL.includes(type)) return 'Complementary Skills';
  const entry = Object.entries(SEGMENTS_KNOWLEDGE_TYPE_DICT).find(([, value]) => value === type);
  return entry?.shift() as SegmentType;
};

const PAGE_LIMIT = 10;

const getKnowledgesOrdering = (sortModel: GridSortModel) => {
  const sortOrder = sortModel[0]?.sort === 'desc' ? '-' : '';
  const field = sortModel[0]?.field;
  return `${sortOrder}${field}` as KnowledgesOrdering;
};

const KnowledgeTable = () => {
  const dispatch = useAppDispatch();
  const profileId = useProfileId();
  const [inputValue, setInputValue] = useState('');
  const [knowledge, setKnowledge] = useState({});
  const [rowId, setRowId] = useState<string | undefined>(undefined);
  const [levels, setLevels] = useState<ExpertLevelEnumType[]>(ALL_LEVELS);
  const [knowledgeTypes, setKnowledgeTypes] = useState<SegmentType[]>(ALL_KNOWLEDGE_TYPES);
  const [ordering, setOrdering] = useState<KnowledgesOrdering>('-value');
  const [page, setPage] = useState(0);
  const debouncedQuery = useDebounce<string>(inputValue, 300);

  const { isLoading: accrualIsLoading, accrualIsStale } = useAppSelector(accrualSummarySelector);
  const { data, isLoading, isRefetching, isPreviousData } = useAllKnowledgesQuery({
    profileId,
    ordering,
    page: page + 1,
    limit: PAGE_LIMIT,
    query: debouncedQuery,
    expertLevels: levels,
    knowledgeTypes: knowledgeTypes.map((type) => SEGMENTS_KNOWLEDGE_TYPE_DICT[type]),
  });

  const handleClear = () => {
    setInputValue('');
    setLevels(ALL_LEVELS);
    setKnowledgeTypes(ALL_KNOWLEDGE_TYPES);
  };

  return (
    <div
      className="min-h-[800px] rounded-md border border-light-primary bg-white shadow-5"
      data-testid={testId}
    >
      <div className="flex items-center justify-between border-b border-light-primary p-18">
        <div className="flex items-center">
          <h2 className="mr-18 hd-5">My Skills</h2>
          {isRefetching || (accrualIsLoading && accrualIsStale) ? (
            <UpdatingIndicator text="Updating knowledge table" />
          ) : null}
        </div>
        <Button
          variant="tertiary"
          onClick={() => {
            handleClear();
            dispatch(showAddKnowledgeDrawer());
          }}
          endIcon={Plus}
          size="small"
        >
          Add New
        </Button>
      </div>
      <div className="px-10 py-18">
        <div className="flex flex-wrap items-center gap-16">
          <TextField
            size="small"
            label="Search Skills"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ width: '350px' }}
          />
          <FormControl size="small">
            <InputLabel id="knowledgeType">Skills Type</InputLabel>
            <Select
              multiple
              displayEmpty
              variant="outlined"
              size="small"
              id="knowledgeType"
              inputProps={whiteSelectedBg}
              value={knowledgeTypes}
              onChange={(e) => {
                setKnowledgeTypes(e.target.value as SegmentType[]);
              }}
              input={<OutlinedInput label="Skills Type" />}
              renderValue={(selected) =>
                selected.length === ALL_KNOWLEDGE_TYPES.length ? 'All Types' : selected.join(', ')
              }
              sx={{ width: '250px' }}
            >
              {ALL_KNOWLEDGE_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={knowledgeTypes.includes(type)} />
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel id="levels">Levels</InputLabel>
            <Select
              multiple
              displayEmpty
              variant="outlined"
              size="small"
              id="levels"
              inputProps={whiteSelectedBg}
              value={levels}
              onChange={(e) => setLevels(e.target.value as ExpertLevelEnumType[])}
              renderValue={(selected) =>
                selected.length === ALL_LEVELS.length
                  ? 'All Levels'
                  : selected.map((str) => capitalizer(str)).join(', ')
              }
              input={<OutlinedInput label="Levels" />}
              sx={{ width: '250px' }}
            >
              {ALL_LEVELS.map((level) => (
                <MenuItem key={level} value={level} className="capitalize">
                  <Checkbox checked={levels.includes(level)} />
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="secondary" size="small" onClick={handleClear}>
            Clear Filters
          </Button>
        </div>
      </div>
      <div className="mx-10 mb-10 body-2">
        <b>{data?.count}</b> Total Knowledge & Skills{' '}
      </div>
      <DataGridTable
        className={clsx({
          'animate-pulse opacity-50': isPreviousData,
        })}
        sortingMode="server"
        paginationMode="server"
        getRowId={(row) => String(row.name)}
        onSortModelChange={(sortModel: GridSortModel) => {
          setOrdering(getKnowledgesOrdering(sortModel));
          setPage(0);
        }}
        onPageChange={(pageNum) => setPage(pageNum)}
        loading={isLoading}
        initialState={{
          pagination: { pageSize: PAGE_LIMIT },
        }}
        rowsPerPageOptions={[PAGE_LIMIT]}
        componentsProps={{
          row: {
            onMouseEnter: (event: React.MouseEvent<HTMLElement>) =>
              setRowId(event.currentTarget.dataset.id),
            onMouseLeave: () => setRowId(undefined),
          },
        }}
        page={page}
        components={{
          ColumnUnsortedIcon: () => <UnfoldMoreIcon />,
          NoRowsOverlay: () => (
            <div className="relative z-10 flex h-full w-full items-center justify-center">
              {inputValue ? (
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleClear();
                    dispatch(showAddKnowledgeDrawer());
                  }}
                >
                  Add More Skills
                </Button>
              ) : (
                'No Skills found'
              )}
            </div>
          ),
        }}
        columns={knowledgeTableColumns(dispatch, setKnowledge, rowId)}
        rowCount={data?.count || 0}
        rows={data?.results || []}
      />
      <ProfileDeleteKnowledge knowledge={knowledge as ProfileKnowledgeScore} skipNavigate />
    </div>
  );
};

export { testId as KnowledgeTableTestId };
export type { IKnowledgeTableProps };
export default KnowledgeTable;
