import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { produce } from 'immer';

import initialState from '@/store/initialState';
import IState from '@/store/state';
import { DIALOG_ENUMS } from '@/ui/ui';

const history = createBrowserHistory();

export const FULLSCREEN_DIALOGS: DIALOG_ENUMS[] = [
  'EDIT_PROFILE_SETTINGS',
  'EDIT_CAREER_ROADMAP',
  'EDIT_WORK_TRAVEL_AVAILABILITY',
  'EDIT_LANGUAGES',
];

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState.harnessUi,
  reducers: {
    showDialog: (state, action: PayloadAction<DIALOG_ENUMS | null>) => {
      if (action.payload && FULLSCREEN_DIALOGS.includes(action.payload)) {
        history.pushState('', '');
      }
      return produce(state, (draft) => {
        draft.data.dialog = action.payload;
      });
    },
    hideDialog: (state) =>
      produce(state, (draft) => {
        draft.data.dialog = null;
      }),
    toggleDialogConfirmClose: (state, action: PayloadAction<boolean>) =>
      produce(state, (draft) => {
        draft.data.dialogConfirmClose = action.payload;
      }),
  },
});

export const uiSelector = (state: IState) => state.harnessUi;
export const { showDialog, hideDialog, toggleDialogConfirmClose } = uiSlice.actions;
export default uiSlice.reducer;
