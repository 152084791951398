import {
  IconButton as IconButtonMaterial,
  IconButtonProps as IconButtonMaterialProps,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';

export interface IconButtonProps extends IconButtonMaterialProps {
  style?: React.CSSProperties;
  children?: ReactNode;
}

const IconButton: FC<IconButtonProps> = ({ style, children, ...other }) => {
  const buttonStyle = {
    width: 'auto',
    height: 'auto',
    padding: 0,
    ...style,
  };

  return (
    <IconButtonMaterial {...other} style={buttonStyle}>
      {children}
    </IconButtonMaterial>
  );
};

export default IconButton;
