import Alert from '@mui/material/Alert';
import SnackbarMaterial from '@mui/material/Snackbar';
import React, { PureComponent } from 'react';

class Snackbar extends PureComponent {
  render() {
    const {
      // @ts-expect-error TS(2339): Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
      messageType,
      // @ts-expect-error TS(2339): Property 'bodyStyle' does not exist on type 'Reado... Remove this comment to see the full error message
      bodyStyle,
      // @ts-expect-error TS(2339): Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
      message,
      // @ts-expect-error TS(2339): Property 'autoHideDuration' does not exist on type... Remove this comment to see the full error message
      autoHideDuration = 5000,
      ...other
    } = this.props;

    return (
      <SnackbarMaterial autoHideDuration={autoHideDuration} {...other}>
        <Alert style={{ minWidth: 288 }} variant="filled" icon={false} severity={messageType}>
          {message}
        </Alert>
      </SnackbarMaterial>
    );
  }
}

export default Snackbar;
