import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TASKS, completeTask } from '@/dashboardv2/store/onboardingSlice';
import {
  CancelablePromise,
  Job,
  JobCreate,
  JobService,
  JobUpdate,
  PaginatedJobList,
} from '@/openapi';
import { fetchKnowledge } from '@/profilev2/store/profileSlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

import { fetchData as fetchWorkHistory } from './workHistorySlice';

let request: CancelablePromise<Job>;

export const fetchData = createAsyncThunk(
  'jobs/fetchData',
  async ({ jobId }: { jobId: string }) => {
    request?.cancel();
    request = JobService.jobRetrieve(jobId);
    return request;
  }
);

export const saveData = createAsyncThunk(
  'jobs/saveData',
  async (
    {
      jobId,
      patchedJob,
    }: {
      jobId: string;
      patchedJob: Partial<JobUpdate>;
    },
    { dispatch }
  ) => {
    return JobService.jobPartialUpdate(jobId, patchedJob).then((data) => {
      dispatch(fetchKnowledge(data.profile_id));
      dispatch(fetchWorkHistory(data.profile_id));
      return data;
    });
  }
);

export const createData = createAsyncThunk(
  'jobs/createData',
  async (requestBody: JobCreate, { dispatch }) =>
    JobService.jobCreate(requestBody).then((data) => {
      dispatch(
        completeTask({
          slug: TASKS.ADD_PROFESSIONAL_HISTORY,
        })
      );
      dispatch(fetchKnowledge(data.profile_id));
      return data;
    })
);

export const resetData = createAsyncThunk('jobs/resetData', async (_, { dispatch }) => {
  request?.cancel();
  dispatch(jobSlice.actions.reset());
});

const jobSlice = createSlice({
  name: 'jobs',
  initialState: initialState.jobs,
  reducers: {
    updateData: (state, action: PayloadAction<PaginatedJobList>) => ({
      ...state,
      data: action.payload,
    }),
    reset: () => initialState.jobs,
    resetNewData: (state) => ({ ...state, new: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: { count: action.payload?.id ? 1 : 0, results: [action.payload] },
      isLoading: false,
    }));
    builder.addCase(createData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(createData.fulfilled, (state, action) => ({
      ...state,
      new: action.payload,
      isLoading: false,
    }));
    builder.addCase(saveData.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        data: { count: action.payload?.id ? 1 : 0, results: [action.payload] },
      };
    });
    builder.addMatcher(isRejectedNotAbortedAction(fetchData), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});
export const jobSelector = (state: IState) => state.jobs;
export const { updateData, resetNewData } = jobSlice.actions;
export default jobSlice.reducer;
