import React, { FC, memo, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setLoadingProgress } from '@/actions/loading';
import { RootState } from '@/store';
import { setRuntimeVariable } from '@/store/runtime';
import { teal500 } from '@/theme/colors';

interface PageLoadingBarProps {
  progress: number;
  children?: React.ReactNode;
}

const connector = connect(
  (state: RootState) => ({ progress: state.loading, userContext: state.ui.userContext }),
  {
    setLoadingProgress,
    setRuntimeVariable,
  }
);

const PageLoadingBar: FC<PageLoadingBarProps & ConnectedProps<typeof connector>> = memo(
  ({ progress, userContext, children, setLoadingProgress, setRuntimeVariable }) => {
    const location = useLocation();

    // TODO: better way to set this
    const initial = progress === 10;

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
      let timer: ReturnType<typeof setTimeout>;
      if (progress === 100) {
        // Set a timeout to hide the component after 1 second
        timer = setTimeout(() => setIsVisible(false), 1000);
      } else {
        setIsVisible(true);
      }

      // Clear the timeout if the component unmounts or if the value changes
      return () => clearTimeout(timer);
    }, [progress]); // This effect runs when the value prop changes

    useEffect(() => {
      setLoadingProgress(100);
      window.scrollTo(0, 0);
      // Update referrer on push navigation
      setRuntimeVariable('referrer', `${window.location.origin}${location.pathname}`);
    }, [location.key, location.pathname, userContext, setLoadingProgress, setRuntimeVariable]);

    const barStyle: React.CSSProperties = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: `${progress}%`,
      height: '4px',
      backgroundColor: teal500,
    };

    if (!initial) {
      barStyle.transition = 'width 0.5s ease-in-out';
    }

    return (
      <>
        {isVisible && <div style={barStyle} />}
        {children}
      </>
    );
  }
);
PageLoadingBar.displayName = 'PageLoadingBar';

export default connector(PageLoadingBar);
