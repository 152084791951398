import FieldContainer from '@/components/FieldContainer/FieldContainer';

import { Profile } from '../AddToExpertRequestDialog';
import s from '../AddToExpertRequestDialog.module.scss';
import CandidateChip from '../CandidateChip';

type ExpertsToAddFieldProps = {
  warningText: JSX.Element[] | undefined;
  errorText: JSX.Element[] | undefined;
  profiles: Profile[];
  resultsByProfile: Record<string, any>;
  handleProfileRemove: (profile: Profile) => void;
};

export const ExpertsToAddField: React.FC<ExpertsToAddFieldProps> = ({
  profiles,
  warningText,
  errorText,
  resultsByProfile,
  handleProfileRemove,
}) => {
  return (
    <FieldContainer
      label="Experts to add"
      className={s.expertsToAdd}
      containerClassName={s.expertsToAddContainer}
      warningText={warningText}
      errorText={errorText}
    >
      {profiles.map((p: any) => (
        <CandidateChip
          key={p.id}
          profile={p}
          result={resultsByProfile[p.id]}
          onRequestDelete={() => handleProfileRemove(p)}
        />
      ))}
    </FieldContainer>
  );
};
ExpertsToAddField.displayName = 'ExpertsToAddField';
