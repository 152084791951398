import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import EditDialog from '@/components/EditDialog';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';

class EditMatchNote extends React.Component {
  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onSubmit, notify } = this.props;
    onSubmit(values)
      .then(() => {
        notify('Research Manager note updated.', 'success');
      })
      .catch((err: any) => {
        notify('Error while updating Research Manager note.', 'error');
        throw err;
      });
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
    const { handleSubmit, reset, noteValue, profile, ...props } = this.props;
    return (
      <EditDialog
        {...props}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={() => reset()}
        // bogus attribute (force dialog position update on text change)
        //fieldValue={noteValue}
      >
        <Field
          id="match_note.note"
          component={TextField}
          fullWidth
          multiline
          name="match_note.note"
          label="Research Manager note"
          placeholder={
            'Example: Based on my vetting calls, I think ' +
            `${profile.first_name} is a good fit for your needs based on ` +
            'the following...'
          }
          maxRows={15}
        />
        <Field
          type="checkbox"
          component={Checkbox}
          name="match_note.recommended"
          label="Add my recommendation"
          style={{ margin: '15px 0' }}
        />
      </EditDialog>
    );
  }
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'initialValues' does not exist on type '{... Remove this comment to see the full error message
    const formName = `candidateMatchNote-${ownProps.initialValues.id}`;
    return {
      form: formName,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      noteValue: formValueSelector(formName)(state, 'match_note.note'),
    };
  },
  {
    notify,
  }
)(
  // @ts-expect-error TS(2629) FIXME: Cannot assign to 'EditMatchNote' because it is a c... Remove this comment to see the full error message
  reduxForm({ enableReinitialize: true })(EditMatchNote)
);
