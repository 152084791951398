import React from 'react';
import { Field } from 'react-final-form';

import { RadioGroup } from '@/components/FormAdapters';

type AddToStageFieldProps = {
  isAdmin: boolean;
};

export const AddToStageField: React.FC<AddToStageFieldProps> = ({ isAdmin }) => {
  if (!isAdmin) return null;

  return (
    <Field
      name="candidate_state"
      component={RadioGroup}
      label="Add to Stage"
      FormControlProps={{ style: { marginTop: 30 } }}
      style={{ flexDirection: 'row' }}
      FormCon
      options={[
        { label: 'Contacted', value: 'contacted' },
        { label: 'Vetting', value: 'vetting' },
      ]}
    />
  );
};
AddToStageField.displayName = 'AddToStageField';
