import clsx from 'clsx';
import { ChevronRight } from 'react-feather';

import Button from '@/componentsv2/Button';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';

import { IProfileContainerHeaderProps } from '../types';

const ProfileContainerHeader = ({
  className,
  children,
  headingComponent: Heading = 'h2',
  showUpdatingIndicator,
  action,
  to,
}: IProfileContainerHeaderProps) => {
  return (
    <div className={clsx(className, 'flex items-center gap-8 border-b border-light-primary p-16')}>
      <Heading className="hd-6">{children}</Heading>
      {showUpdatingIndicator ? <UpdatingIndicator text="Updating knowledge scores" /> : null}
      <div className="ml-auto">
        {action ? (
          action
        ) : to ? (
          <Button variant="tertiary" size="small" to={to} endIcon={ChevronRight}>
            Show All
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileContainerHeader;
