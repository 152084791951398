import { memo } from 'react';

import { policies } from '@/core/agreements';
import { formatCreditsText } from '@/utils';

import Link from '../Link';
import s from './LegalNotes.module.scss';

function LegalNotes({ showEngagementAgreement, bookingFee }: any) {
  const tosPol = policies['terms-of-service'];
  const privacyPol = policies.privacy;
  const customerChargePol = policies['customer-charging-policy'];
  const marketExpertPol = policies['marketplace-expert-agreement'];

  const engagementAgreement = showEngagementAgreement ? (
    <>
      &nbsp;and to the{' '}
      <Link newTab href={marketExpertPol.url}>
        {marketExpertPol.name}
      </Link>{' '}
      or alternative terms
    </>
  ) : null;

  return (
    <div className={s.root}>
      By clicking “Submit Request”, you agree that this consultation, including any personal data,
      will be recorded by OnFrontiers and shall be used and maintained pursuant to OnFrontiers’{' '}
      <Link newTab href={tosPol.url}>
        {tosPol.name}
      </Link>{' '}
      and{' '}
      <Link newTab href={privacyPol.url}>
        {privacyPol.name}
      </Link>
      , and also that this consultation is subject to the terms of our{' '}
      <Link newTab href={customerChargePol.url}>
        {customerChargePol.name}
      </Link>
      {engagementAgreement || ' and terms'} agreed to between your organization and the Expert.{' '}
      {bookingFee ? (
        <span>Estimated charges below reflect a {formatCreditsText(bookingFee)} booking fee.</span>
      ) : undefined}
    </div>
  );
}

export default memo(LegalNotes);
