import Body from './Body';
import Header from './Header';
import Root, { ProfileContainerTestId } from './Root';
import {
  IProfileContainerBodyProps,
  IProfileContainerHeaderProps,
  IProfileContainerProps,
} from './types';

export const ProfileContainer = Object.assign(Root, { Header, Body });
export type { IProfileContainerProps, IProfileContainerHeaderProps, IProfileContainerBodyProps };
export { ProfileContainerTestId };
