import { DEFAULT_KNOWLEDGES_LIMIT } from '@/knowledge/queries/knowledge';
import { arrayFromRange } from '@/utils/array';

import KnowledgeBar, { IKnowledgeBarProps } from '.';

const KnowledgeBarSkeletons = ({
  count = DEFAULT_KNOWLEDGES_LIMIT,
  loadAsLink,
}: {
  count?: number;
} & Pick<IKnowledgeBarProps, 'loadAsLink'>) => {
  return (
    <>
      {arrayFromRange({ max: count }).map((i) => (
        <KnowledgeBar key={i} label={''} value={0} isLoading loadAsLink={loadAsLink} />
      ))}
    </>
  );
};

export default KnowledgeBarSkeletons;
