import IconButton from '@mui/material/IconButton';
import { FC, useCallback } from 'react';
import { connect } from 'react-redux';

import { popup } from '@/actions/ui';
import { openFileDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

import Button from '../Button';
import FAIcon from '../Icon/FAIcon';

interface CvButtonProps {
  url?: string;
  label?: string;
  editable?: boolean;
  popup?: any;
  onUpload: any;
  onRemove?: any;
}

const CvButton: FC<CvButtonProps> = ({
  url,
  label = 'Attach CV',
  editable = true,
  popup,
  onRemove,
  onUpload,
}) => {
  const { graphql } = useApp();

  const upload = useCallback(async () => {
    const files = await openFileDialog(graphql.client, {
      accept: ['.doc', '.docx', '.pdf', '.rtf'],
      maxSize: 3 * 1024 * 1024, // 3 MB
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
    });
    onUpload(files[0]);
  }, [graphql.client, onUpload]);

  const remove = useCallback(() => {
    popup({
      buttonAlignment: 'space-between',
      title: 'Do you want to remove the attached CV from the profile?',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Remove',
          callback: onRemove,
        },
      ],
    });
  }, [onRemove, popup]);

  if (url) {
    return (
      <>
        <Button href={url} size="medium">
          Open CV
        </Button>
        {editable && (
          <IconButton onClick={remove} size="small" style={{ marginLeft: 10 }}>
            <FAIcon icon="trash" />
          </IconButton>
        )}
      </>
    );
  }

  if (!editable) return null;

  return (
    <Button size="medium" onClick={upload}>
      {label}
    </Button>
  );
};

export default connect(undefined, {
  popup,
})(CvButton);
