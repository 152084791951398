import cx from 'classnames';
import { ReactElement, memo } from 'react';

import s from './PictureList.module.scss';

interface PictureListProps {
  children: ReactElement[];
  className?: string;
}

const PictureList = memo(({ children, className }: PictureListProps) => {
  return (
    <div className={cx(s.root, className && { [className]: !!className })}>
      {children.map((p) => (
        <div key={p.key} className={s.item}>
          {p}
        </div>
      ))}
    </div>
  );
});
PictureList.displayName = 'PictureList';

export default PictureList;
