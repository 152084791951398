import React from 'react';
import { Field } from 'react-final-form';

import Checkbox from '@/components/Checkbox';

class Disclosure extends React.PureComponent {
  static getValue = (checked: any) => {
    if (checked) {
      return 'private';
    }
    return 'full';
  };

  getChecked = () => {
    // @ts-expect-error TS(2339): Property 'formValues' does not exist on type 'Read... Remove this comment to see the full error message
    const value = this.props.formValues.disclosure;
    if (value === 'private') {
      return true;
    }
    return false;
  };

  handleChange = (_: any, checked: any) => {
    // @ts-expect-error TS(2339): Property 'change' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.change('disclosure', Disclosure.getValue(checked));
  };

  render = () => {
    // @ts-expect-error TS(2339): Property 'formValues' does not exist on type 'Read... Remove this comment to see the full error message
    const { formValues, change, ...other } = this.props;
    return (
      <Field
        id="expertRequestDisclosure"
        component={Checkbox}
        name="disclosure"
        label="Keep My Company Hidden"
        onChange={this.handleChange}
        checked={this.getChecked()}
        {...other}
      />
    );
  };
}

export default Disclosure;
