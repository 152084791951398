import clsx from 'clsx';
import { PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Menu } from 'react-feather';

import Button from '@/componentsv2/Button';

import LogoImg from './LogoImg';
import MobileNavDrawer from './MobileNavDrawer';

const testId = 'of-nav-bar';

export const NavBarSkeleton = () => {
  return (
    <div
      className="flex items-center gap-8 border-b border-light-primary bg-white px-24 py-8"
      data-testid={testId}
    >
      <LogoImg />
    </div>
  );
};

export const Root = ({
  className,
  children,
  mobileNavSlot,
}: PropsWithChildren<{ className?: string; mobileNavSlot: ReactElement }>) => {
  const [mobileNavDrawerOpen, setMobileNavDrawerOpen] = useState(false);
  const closeMobileNavDrawer = useCallback(() => setMobileNavDrawerOpen(false), []);

  return (
    <div
      className={clsx(
        className,
        'flex h-[52px] min-h-[52px] items-center gap-8 border-b border-light-primary bg-white pl-[24px] pr-10 md:pr-14'
      )}
      data-testid={testId}
      data-sentry-unmask
    >
      <LogoImg />
      {children}
      <Button
        variant="tertiary"
        srText="Open navigation"
        startIcon={Menu}
        startIconClassName="h-10 w-10 text-dark-secondary"
        size="small"
        className="ml-auto rounded-[4px] border border-gray-100 p-[6px] xl:hidden"
        onClick={() => setMobileNavDrawerOpen(true)}
      />

      <MobileNavDrawer open={mobileNavDrawerOpen} handleClose={closeMobileNavDrawer}>
        {mobileNavSlot}
      </MobileNavDrawer>
    </div>
  );
};
export { testId as navBarTestId };
export default Root;
