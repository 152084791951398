import ActionTypes from '@/actions/ActionTypes';

const { APP_NOTIFICATION__LIST, APP_NOTIFICATION__REMOVE } = ActionTypes;

export type CollectionType = 'default';

type AppNotificationsState = {
  [key in CollectionType]: undefined | any[];
};

const initialState: AppNotificationsState = {
  default: [],
};

export default function appNotificationsReducer(
  state = initialState,
  action: any
): AppNotificationsState {
  switch (action.type) {
    case APP_NOTIFICATION__LIST:
      return {
        ...state,
        [action.collection]: action.appNotifications,
      };
    case APP_NOTIFICATION__REMOVE: {
      const newState = { ...state };
      Object.keys(state).forEach((col) => {
        // @ts-ignore
        newState[col] = collectionReducer(state[col], action);
      });
      return newState;
    }
    default:
      return state;
  }
}

function collectionReducer(state = [], action: any): any[] {
  switch (action.type) {
    case APP_NOTIFICATION__REMOVE:
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      return state.filter((n) => n.id !== action.notificationId);
    default:
      return state;
  }
}
