import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { ElementType, ReactNode } from 'react';

import { FAIcon } from '@/componentsv2/Icon';
import { Icon } from '@/componentsv2/Icon/FAIcon/icons';
import Text from '@/componentsv2/Text';

import { buttonClasses, styles } from './styles';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    danger: true;
    inherit: true;
    error: true;
    info: false;
    warning: true;
  }
}

const useStyles = makeStyles(styles);

type ButtonProps = MuiButtonProps & {
  lightIcon?: boolean;
  startIcon?: Icon | ReactNode | string;
  shadowed?: boolean;
  target?: string;
  component?: ElementType;
  to?: string;
  loading?: boolean;
  'data-testid'?: string;
};

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  lightIcon,
  shadowed,
  startIcon,
  loading = false,
  ...rest
}) => {
  const classes = useStyles();
  const classProps = clsx(
    classes.button,
    buttonClasses.root,
    rest.size,
    rest.variant,
    {
      withIcon: startIcon,
      shadowed,
    },
    className
  );
  const startIconElement =
    typeof startIcon === 'string' ? (
      <FAIcon size="lg" icon={startIcon as Icon} light={lightIcon} />
    ) : (
      startIcon
    );
  return (
    <MuiButton
      data-testid="of-button"
      className={classProps}
      startIcon={startIconElement}
      {...rest}
    >
      {!loading ? (
        <Text tag="button" className={buttonClasses.label}>
          {children}
        </Text>
      ) : (
        <CircularProgress size={12} thickness={5} />
      )}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
  size: 'large',
  shadowed: false,
};

export { buttonClasses };
export type { ButtonProps };
export default Button;
