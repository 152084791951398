import ActionTypes from '@/actions/ActionTypes';

export default function siteSettingsReducer(state = null, action: any) {
  switch (action.type) {
    case ActionTypes.SITE_SETTINGS__FETCH:
      return action.siteSettings;
    case ActionTypes.SITE_SETTINGS__UPDATE:
      return action.siteSettings;
    default:
      return state;
  }
}
