import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { TextField } from '@/components/FormAdapters';
import { updateProfile } from '@/profile/store';
import { isValidLinkedin } from '@/utils/reducer';

function validate(values = {}) {
  const errors = {};

  // @ts-expect-error TS(2339): Property 'linkedin_url' does not exist on type '{}... Remove this comment to see the full error message
  if (!isValidLinkedin(values.linkedin_url)) {
    // @ts-expect-error TS(2339): Property 'linkedin_url' does not exist on type '{}... Remove this comment to see the full error message
    errors.linkedin_url = 'Must be a valid https://linkedin.com URL';
  }

  return errors;
}

function LinkedinForm({
  component: Container,
  reset,
  onReset,
  profileId,
  updateProfile,
  onSubmit,
  handleSubmit: handleSubmitProp,
  ...other
}: any) {
  const handleSubmit = (values: any) => {
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container {...other} onSubmit={handleSubmitProp(handleSubmit)} onReset={handleReset}>
      <Field fullWidth component={TextField} name="linkedin_url" label="LinkedIn URL" />
    </Container>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'LinkedinForm' because it is a fu... Remove this comment to see the full error message
LinkedinForm = reduxForm({
  form: 'expertLinkedin',
  validate,
  enableReinitialize: true,
})(LinkedinForm);

// @ts-expect-error TS(2630): Cannot assign to 'LinkedinForm' because it is a fu... Remove this comment to see the full error message
LinkedinForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        linkedin_url: profile.linkedin_url,
      },
    };
  },
  {
    updateProfile,
  }
)(LinkedinForm);

export default LinkedinForm;
