import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Currency from '@/components/Currency/Currency';
import Dialog, { DialogProps } from '@/components/Dialog';
import { Checkbox } from '@/components/FormAdapters/FormAdapters';
import { updateExpertRequestCandidate } from '@/expertrequest/store';
import { darkGray } from '@/theme/colors';
import { formatCreditsText, rateToCredits } from '@/utils';

const useStyles = makeStyles(() => ({
  calculated: {
    fontSize: 14,
    marginTop: 0,
    color: darkGray,
  },
}));

interface EditRatesDialogProps extends DialogProps {
  candidate: any;
  expertRequest: any;
  updateExpertRequestCandidate: any;
  notify: any;
  onClose: () => void;
}

const connector = connect(undefined, {
  updateExpertRequestCandidate,
  notify,
});

const EditRatesDialog = ({
  candidate,
  expertRequest,
  updateExpertRequestCandidate,
  notify,
  onClose,
  ...other
}: EditRatesDialogProps & ConnectedProps<typeof connector>) => {
  const s = useStyles();

  const { bill_rate: billRate, credit_rate: creditRate } = candidate;
  const [expectedConsumerRate, setExpectedConsumerRate] = useState(rateToCredits(billRate));

  const initialValues = useMemo(
    () => ({
      bill_rate: billRate,
      credit_rate: creditRate,
      show_engagement_agreement: candidate.show_engagement_agreement,
    }),
    [billRate, candidate.show_engagement_agreement, creditRate]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const candidateData = {
          id: candidate.id,
          bill_rate: values.bill_rate,
          credit_rate: values.credit_rate,
          show_engagement_agreement: values.show_engagement_agreement,
        };
        await updateExpertRequestCandidate(expertRequest.id, candidateData);
      } catch (err) {
        console.log(err);
        notify('An error occurred when trying to update the candidate.', 'error');
      }
      onClose();
    },
    [candidate.id, expertRequest.id, notify, onClose, updateExpertRequestCandidate]
  );

  const validate = useCallback((values: any) => {
    const errors = {};

    ['bill_rate', 'credit_rate'].forEach((f) => {
      const value = values[f];
      if (value === '' || value === undefined) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[f] = 'Required';
      }

      if (value < 0) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[f] = 'Must be a positive number';
      }
    });

    return errors;
  }, []);

  const consumerHelperText = `Calculated Consumer Rate: ${formatCreditsText(
    expectedConsumerRate
  )}/hr`;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            title="Edit Rates"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Save"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={Currency}
                name="credit_rate"
                label="Consumer Rate"
                variant="outlined"
                position="end"
                adornment="credits/hr"
              />
              <p className={s.calculated}>{consumerHelperText}</p>

              <Field
                component={Currency}
                name="bill_rate"
                label="Expert Rate"
                variant="outlined"
                position="end"
                parse={(value) => {
                  setExpectedConsumerRate(rateToCredits(value));
                  return value;
                }}
                adornment="USD/hr"
              />
              <Field
                type="checkbox"
                component={Checkbox}
                name="show_engagement_agreement"
                label="Show engagement agreement on consultation request"
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
};

export default connector(EditRatesDialog);
