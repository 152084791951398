import moment from 'moment-timezone';

import {
  Exact,
  InputMaybe,
  LanguageInput,
  NetworkExpertConnectionState,
  Scalars,
} from '@/__generated__/graphql';
import { hasErrorCode } from '@/core/api';
import ERROR_CODES from '@/core/apiErrorCodes';
import { CollectionState } from '@/reducers/projects';
import { AppThunk } from '@/store';
import { shouldResetCollection } from '@/utils';
import { DocumentNodeResult } from '@/utils/gql';

import {
  ADD_EDUCATION,
  ADD_EXPERIENCE,
  AUTOFILL_PROFILE,
  CONFLICTS_COUNT,
  CSV_IMPORT,
  CSV_IMPORT_PREVIEW,
  DELETE_PROFILE,
  FETCH_CONFLICTS,
  FETCH_PROFILE,
  MERGE_PROFILES,
  REMOVE_EDUCATION,
  REMOVE_EXPERIENCE,
  UPDATE_EDUCATION,
  UPDATE_EXPERIENCE,
  UPDATE_PROFILE,
} from './queries';

export const PROFILE__LIST_LOADING = 'PROFILE__LIST_LOADING';
export const PROFILE__LIST = 'PROFILE__LIST';
export const PROFILE__LIST_RESET = 'PROFILE__LIST_RESET';
export const PROFILE__LIST_LOADED = 'PROFILE__LIST_LOADED';
export const PROFILE__COUNT_LOADING = 'PROFILE__COUNT_LOADING';
export const PROFILE__COUNT = 'PROFILE__COUNT';
export const PROFILE__DELETE = 'PROFILE__DELETE';
export const PROFILE__UPDATE = 'PROFILE__UPDATE';
export const PROFILE__SET_CSV_PREVIEW = 'PROFILE__SET_CSV_PREVIEW';
export const PROFILE__UPDATE_EXPERIENCE = 'PROFILE__UPDATE_EXPERIENCE';
export const PROFILE__REMOVE_EXPERIENCE = 'PROFILE__REMOVE_EXPERIENCE';
export const PROFILE__UPDATE_EDUCATION = 'PROFILE__UPDATE_EDUCATION';
export const PROFILE__REMOVE_EDUCATION = 'PROFILE__REMOVE_EDUCATION';

export function fetchProfile(
  id: string,
  {
    force = false,
    leadOnly = false,
    audit = false,
    experiences = false,
    addresses = false,
    expertise = false,
    groupKeywords = false,
    education = false,
    sources = false,
    internalNetworks = false,
    expertRequestCandidates = false,
    groupAboutPage = false,
    internalNetworkConsultations = false,
    connectionStates = [NetworkExpertConnectionState.Active],
    rawPictureUrl = false,
  } = {}
): AppThunk<Promise<DocumentNodeResult<typeof FETCH_PROFILE>['profile'] | null>> {
  return async (dispatch, _getState, { graphql }) => {
    if (!id) throw new Error('fetchProfile must be given an id');

    let profile;
    try {
      const { data } = await graphql.client.query({
        query: FETCH_PROFILE,
        variables: {
          id,
          audit,
          experiences,
          addresses,
          expertise,
          groupKeywords,
          education,
          sources,
          internalNetworks,
          expertRequestCandidates,
          groupAboutPage,
          internalNetworkConsultations,
          rawPictureUrl,
          connectionStates: internalNetworks ? connectionStates : undefined,
        },
        fetchPolicy: force ? 'network-only' : undefined,
      });
      profile = data.profile;
    } catch (e: unknown) {
      if (hasErrorCode(e, ERROR_CODES.PROFILE_NOT_FOUND)) {
        return null;
      }
      throw e;
    }

    if (leadOnly && profile.user) {
      return null;
    }

    dispatch({ type: PROFILE__UPDATE, profile });
    return profile;
  };
}

export function fetchConflicts(
  cursor?: string,
  pageSize = 20
): AppThunk<Promise<CollectionState | undefined>> {
  return async (dispatch, getState, { graphql }) => {
    const collection = 'conflicts';
    const reset = !cursor;
    const conflicts = getState().profiles.collections[collection] as CollectionState;
    if (reset && !shouldResetCollection(conflicts, pageSize)) return conflicts;

    dispatch({ type: PROFILE__LIST_LOADING, collection });
    try {
      const { data } = await graphql.client.query({
        query: FETCH_CONFLICTS,
        variables: { cursor, pageSize },
      });
      const page = data.profiles;
      dispatch({ type: PROFILE__LIST, collection, ...page, reset });
    } finally {
      dispatch({ type: PROFILE__LIST_LOADED, collection });
    }
  };
}

export function fetchConflictsCount(): AppThunk<Promise<number | null | undefined>> {
  return async (dispatch, getState, { graphql }) => {
    const count = 'conflicts';
    const cache = getState().profiles.counts[count];
    if (cache?.value && moment().isBefore(cache.expire)) {
      return cache.value;
    }

    dispatch({ type: PROFILE__COUNT_LOADING, count: 'conflicts' });
    try {
      const { data } = await graphql.client.query({ query: CONFLICTS_COUNT });
      const conflicts = data.profilesCount;
      dispatch({ type: PROFILE__COUNT, count, value: conflicts });
      return conflicts;
    } catch (e) {
      dispatch({ type: PROFILE__COUNT, count });
      throw e;
    }
  };
}

export function updateProfile(profile: {
  id: string;
  languages?: InputMaybe<LanguageInput>[];
  skype?: string;
  cv_url?: string;
  picture_url?: string;
  cover_url?: string;
  timezone?: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({ mutation: UPDATE_PROFILE, variables: profile });
    dispatch({ type: PROFILE__UPDATE, profile: data!.updateProfile });
  };
}

export function mergeProfiles(
  merge: Exact<{
    destination_id: string;
    source_id: string;
    first_name?: InputMaybe<string>;
    last_name?: InputMaybe<string>;
    linkedin_username?: InputMaybe<string>;
    // Add other optional fields as needed
  }>
): AppThunk<Promise<NonNullable<DocumentNodeResult<typeof MERGE_PROFILES>['mergeProfiles']>>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({ mutation: MERGE_PROFILES, variables: merge });
    return data!.mergeProfiles!;
  };
}

export function csvImportPreview(args: {
  file: { filename: string; url: string };
  networkId: string;
  availableMarketplace: boolean;
}): AppThunk<Promise<DocumentNodeResult<typeof CSV_IMPORT_PREVIEW>['profileCsvImportPreview']>> {
  const {
    file: { filename, url },
    networkId,
    availableMarketplace,
  } = args;
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.query({
      query: CSV_IMPORT_PREVIEW,
      variables: {
        url,
        networkId,
        availableMarketplace,
      },
    });
    const preview = data!.profileCsvImportPreview;

    dispatch({
      type: PROFILE__SET_CSV_PREVIEW,
      preview: { ...preview, filename, url, valid: !!preview },
    });
    return preview;
  };
}

export function csvImport({
  filename,
  url,
  ignoreRows,
  requestId,
  createdBy,
  networkId,
  availableMarketplace = true,
}: {
  filename: string;
  url: string;
  ignoreRows: number;
  requestId: string;
  createdBy: string;
  networkId: string;
  availableMarketplace: boolean;
}): AppThunk<Promise<void>> {
  return async (dispatch, _getState, { graphql }) => {
    await graphql.client.mutate({
      mutation: CSV_IMPORT,
      variables: {
        filename,
        url,
        ignoreRows,
        requestId,
        createdBy,
        availableMarketplace,
        networkId,
      },
    });

    dispatch({ type: PROFILE__LIST_RESET, collection: 'conflicts' });
  };
}

export function deleteProfile(profile: { id: string }): AppThunk<Promise<void>> {
  return async (dispatch, _, { graphql }) => {
    await graphql.client.mutate({ mutation: DELETE_PROFILE, variables: { id: profile.id } });
    dispatch({ type: PROFILE__DELETE, profile });
  };
}

export function autofillProfile(profile: {
  autofill: boolean;
  cvUrl: string;
}): AppThunk<Promise<void>> {
  const { autofill, cvUrl } = profile;
  return async (_dispatch, _getState, { graphql }) => {
    await graphql.client.mutate({ mutation: AUTOFILL_PROFILE, variables: { autofill, cvUrl } });
  };
}

export function saveExperience(
  profileId: string,
  exp: {
    id?: Scalars['ID'];
    title: string;
    company: string;
    start_date: Scalars['Date'];
    end_date?: Scalars['Date'];
    current?: boolean;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, _getState, { graphql }) => {
    const mutation = exp.id ? UPDATE_EXPERIENCE : ADD_EXPERIENCE;
    const mutationName = exp.id ? 'updateExperience' : 'addExperience';
    const { data } = await graphql.client.mutate({
      mutation,
      variables: {
        profile_id: profileId,
        ...exp,
        current: exp.current === true,
      },
    });
    // @ts-ignore addExperience/updateExperience
    const experience = data[mutationName];
    dispatch({
      type: PROFILE__UPDATE_EXPERIENCE,
      profileId,
      experience,
    });
  };
}

export function removeExperience(profileId: string, id: string): AppThunk<Promise<void>> {
  return async (dispatch, _getState, { graphql }) => {
    await graphql.client.mutate({ mutation: REMOVE_EXPERIENCE, variables: { id } });
    dispatch({
      type: PROFILE__REMOVE_EXPERIENCE,
      profileId,
      id,
    });
  };
}

export function saveEducation(
  profileId: string,
  edu: {
    id: string;
    degree: string;
    field_of_study: string;
    school: string;
    start_date: Date;
    end_date: Date;
    description: string;
    current: boolean;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, _getState, { graphql }) => {
    const mutation = edu.id ? UPDATE_EDUCATION : ADD_EDUCATION;
    const mutationName = edu.id ? 'updateEducation' : 'addEducation';
    const { data } = await graphql.client.mutate({
      mutation,
      variables: {
        profile_id: profileId,
        ...edu,
        current: edu.current === true,
      },
    });
    // @ts-ignore addEducation/updateEducation
    const education = data[mutationName];
    dispatch({
      type: PROFILE__UPDATE_EDUCATION,
      profileId,
      education,
    });
  };
}

export function removeEducation(profileId: string, id: string): AppThunk<Promise<void>> {
  return async (dispatch, _, { graphql }) => {
    await graphql.client.mutate({ mutation: REMOVE_EDUCATION, variables: { id } });
    dispatch({
      type: PROFILE__REMOVE_EDUCATION,
      profileId,
      id,
    });
  };
}

export function updatePicture(id: string, imageUrl: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    // optimistic
    dispatch({
      type: PROFILE__UPDATE,
      profile: {
        id,
        picture_url: imageUrl,
      },
    });

    dispatch(
      updateProfile({
        id,
        picture_url: imageUrl,
      })
    );
  };
}

export function updateCover(id: string, imageUrl: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    // optimistic
    dispatch({
      type: PROFILE__UPDATE,
      profile: {
        id,
        cover_url: imageUrl,
      },
    });

    dispatch(
      updateProfile({
        id,
        cover_url: imageUrl,
      })
    );
  };
}
