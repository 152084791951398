import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import Collapse from '@/componentsv2/Collapse';
import CollapseToggle from '@/componentsv2/CollapseToggle';
import { nl2br } from '@/utils/texts';

const COLLAPSED_HEIGHT = 110;

const Accomplishments = ({ content }: { content: string }) => {
  const [expand, setExpanded] = useState(false);
  const [requiresExpansion, setRequiresExpansion] = useState(false);
  const { ref: heightRef, height = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    setRequiresExpansion(height > COLLAPSED_HEIGHT);
  }, [height]);

  return (
    <>
      <Collapse in={expand} collapsedSize={Math.min(COLLAPSED_HEIGHT, height + 2)}>
        <div className="relative mt-4 body-2" ref={heightRef}>
          {nl2br(content)}
        </div>
      </Collapse>
      {requiresExpansion ? (
        <CollapseToggle
          handleToggle={() => setExpanded((prev) => !prev)}
          expanded={expand}
          className="mt-8"
        />
      ) : null}
    </>
  );
};

export default Accomplishments;
