import { capitalizer } from './texts';

const fullyCapitalizeSentence = (
  sentence: string | undefined | null,
  {
    fixAllUppercased = false,
    checkGrowthbook = false,
    sourceKey = '',
  }: {
    fixAllUppercased?: boolean;
    checkGrowthbook?: boolean;
    sourceKey?: string;
  } = {}
): string => {
  if (!sentence) {
    return '';
  }

  const isAllUppercased = sentence === sentence.toUpperCase();

  if (fixAllUppercased && isAllUppercased) {
    sentence = sentence.toLowerCase();
  }

  const isAllLowercased = sentence === sentence.toLowerCase();
  // only modify title if all letters are lowercased
  if (!isAllLowercased) {
    return sentence;
  }

  const growthbookFeaturesOnUsage = window.growthbookFeaturesOnUsage || {};
  if (
    checkGrowthbook &&
    (growthbookFeaturesOnUsage['disable-capitalization'] ||
      growthbookFeaturesOnUsage[`disable-capitalization-${sourceKey}`])
  ) {
    console.warn('Capitalization disabled by Growthbook', sentence, sourceKey);
    return sentence;
  }

  return sentence
    .split(' ')
    .map((s) => capitalizer(s))
    .join(' ');
};

type AnyString = string | undefined | null;

// dedicated functions to easily track the use of these
export const organizationCapitalized = (organization: AnyString) => {
  return fullyCapitalizeSentence(organization, {
    checkGrowthbook: true,
    sourceKey: 'organization',
  });
};
export const jobTitleCapitalized = (title: AnyString) => {
  return fullyCapitalizeSentence(title, {
    checkGrowthbook: true,
    fixAllUppercased: true,
    sourceKey: 'job-title',
  });
};
export const majorCapitalized = (major: AnyString) => {
  return fullyCapitalizeSentence(major, {
    checkGrowthbook: true,
    fixAllUppercased: true,
    sourceKey: 'education-major',
  });
};
export const knowledgeCapitalized = (knowledge: AnyString) => {
  return fullyCapitalizeSentence(knowledge, {
    checkGrowthbook: true,
    sourceKey: 'knowledge',
  });
};
export const certificationCapitalized = (certification: AnyString) => {
  return fullyCapitalizeSentence(certification, {
    checkGrowthbook: true,
    sourceKey: 'certification',
  });
};
