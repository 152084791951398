import React, { Component } from 'react';

import BlogPostPreview from '../BlogPostPreview/BlogPostPreview';
import s from './BlogPostPreviewList.module.scss';

interface BlogPostPreviewListProps {
  posts: {
    url: string;
    author: string;
    date: string;
    title: string;
    excerpt: string;
    thumbnail_url: string;
    slug: string;
  }[];
  title?: string;
  titleStyle?: React.CSSProperties;
  center?: boolean;
  children?: React.ReactNode;
}

class BlogPostPreviewList extends Component<BlogPostPreviewListProps> {
  render() {
    const { posts, children, title, titleStyle, center } = this.props;

    return (
      <div>
        {title && (
          <h2 className={s.title} style={titleStyle}>
            {title}
          </h2>
        )}

        <div style={{ justifyContent: center ? 'center' : 'flex-start' }} className={s.posts}>
          {posts.map((p: any) => (
            <BlogPostPreview key={p.url} className={s.post} post={p} />
          ))}
          {children}
        </div>
      </div>
    );
  }
}

export default BlogPostPreviewList;
