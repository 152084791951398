import Skeleton from '@mui/material/Skeleton';

import Tag from '@/componentsv2/Tag';
import { tagHeights } from '@/componentsv2/TagGroup';
import { getLevelByScore } from '@/utils/knowledgeScoreToPercent';

import KnowledgeLevelIcon from '../KnowledgeLevelIcon';
import { IKnowledgeLevelLabelProps } from './types';

const testId = 'of-knowledge-level-label';

const KnowledgeLevelLabel = ({
  className,
  value,
  isLoading,
  iconOnly,
}: IKnowledgeLevelLabelProps) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        className="rounded-sm"
        width={iconOnly ? 24 : 98}
        height={tagHeights.sm}
      />
    );
  }

  if (!value) {
    return null;
  }

  const level = getLevelByScore(value);
  return (
    <Tag
      data-testid={testId}
      className={className}
      size="sm"
      startIcon={
        <KnowledgeLevelIcon className="fill-text-900" level={level} suppressTooltip={!iconOnly} />
      }
      textClassName="capitalize subtitle-1"
    >
      {!iconOnly ? level : null}
    </Tag>
  );
};

export { testId as KnowledgeLevelLabelTestId };
export type { IKnowledgeLevelLabelProps };
export default KnowledgeLevelLabel;
