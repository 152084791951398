import Typography from '@mui/material/Typography';

import ShowMore from '@/componentsv2/ShowMore';
import { useProfileId } from '@/hooks/store';
import KnowledgeBar from '@/knowledge/components/KnowledgeBar';
import KnowledgeBarSkeletons from '@/knowledge/components/KnowledgeBar/Skeletons';
import { useKnowledgesByTypeQuery } from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { ProfileContainer } from '@/profilev2/components/ProfileContainer';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { knowledgeCapitalized } from '@/utils/capitalization';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

import { ISpecializedActivitiesProps } from './types';

const testId = 'of-specialized-activities';

export const TITLE = 'Core Skills';
const PLACEHOLDER = `More experiences required to display ${TITLE}`;

const SpecializedActivities = () => {
  const profileId = useProfileId();
  const { data, isLoading, isFetched, isRefetching } = useKnowledgesByTypeQuery({
    profileId,
    knowledgeTypes: ['Specialized Activity'],
    page: 1,
    ordering: '-value',
  });
  const hasData = data?.results?.length;
  const { isLoading: accrualIsLoading, accrualIsStale } = useAppSelector(accrualSummarySelector);

  return (
    <ProfileContainer data-testid={testId}>
      <ProfileContainer.Header
        showUpdatingIndicator={isRefetching || (accrualIsLoading && accrualIsStale)}
        to={PROFILE_ROUTES().knowledgeType('specialized-activities')}
      >
        {TITLE}
      </ProfileContainer.Header>
      <ProfileContainer.Body>
        {isLoading ? (
          <div className="space-y-16">
            <KnowledgeBarSkeletons count={5} loadAsLink />
          </div>
        ) : (
          <ShowMore className="space-y-16" limit={5} maxLimit={10}>
            {isFetched && !hasData ? (
              <Typography variant="body2" color="grey.500">
                {PLACEHOLDER}
              </Typography>
            ) : (
              data?.results?.map((knowledge) => {
                return (
                  <KnowledgeBar
                    key={knowledge.name as string}
                    label={knowledgeCapitalized(knowledge.name)}
                    value={knowledgeScoreToPercent(+(knowledge.value || 0))}
                    to={PROFILE_ROUTES().knowledge(knowledge.name ?? '')}
                  />
                );
              })
            )}
          </ShowMore>
        )}
      </ProfileContainer.Body>
    </ProfileContainer>
  );
};

export { testId as SpecializedActivitiesTestId };
export type { ISpecializedActivitiesProps };
export default SpecializedActivities;
