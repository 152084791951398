import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import stringToColor from '@/utils/stringToColor';

import styles, { avatarClasses } from './styles';

const useStyles = makeStyles(styles);

interface AvatarProps extends Omit<MuiAvatarProps, 'alt' | 'id'> {
  fullName: string;
  profileId?: string | number;
}

const getInitials = (fullName: string) => {
  if (fullName) {
    return fullName
      .split(' ')
      .map((word) => word.charAt(0).replace(/[()]/g, ''))
      .join('')
      .slice(0, 2)
      .toUpperCase();
  }
  return '';
};

const Avatar = ({ className, fullName, src, profileId, sx, ...props }: AvatarProps) => {
  const initials = useMemo(() => getInitials(fullName), [fullName]);
  const bgcolor = useMemo(() => stringToColor(fullName, 700), [fullName]);
  const navigate = useNavigate();
  const classes = useStyles();
  const classProps = clsx(className, classes.avatar, avatarClasses.root, {
    [avatarClasses.clickable]: Boolean(profileId),
  });

  return (
    <MuiAvatar
      alt={initials}
      className={classProps}
      data-testid="of-avatar"
      onClick={profileId ? () => navigate(`/profile/${profileId}`) : undefined}
      src={src}
      {...props}
      sx={{
        bgcolor,
        ...sx,
      }}
    >
      {initials}
    </MuiAvatar>
  );
};

export default Avatar;
