const ns = 'form-radio-button';

export const formRadioButtonClasses = {
  root: `${ns}__root`,
};

const styles = {
  formRadioButton: {},
};

export default styles;
