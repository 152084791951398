import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { typeCollectionOrdering } from '@/collection/components/CollectionSelector';
import { PaginatedSavedCollectionList, SavedCollectionService } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let oldLimit: number;
let oldOffset: number | undefined;
let oldOrdering: typeCollectionOrdering | undefined;

export const fetchData = createAsyncThunk(
  'collections/fetchData',
  async (
    {
      limit = 4,
      offset,
      ordering,
    }: {
      limit: number;
      offset?: number;
      ordering?: typeCollectionOrdering;
    },
    { getState }
  ) => {
    if (limit !== oldLimit || offset !== oldOffset || ordering !== oldOrdering) {
      oldLimit = limit;
      oldOffset = offset;
      oldOrdering = ordering;
      return await SavedCollectionService.savedCollectionList(limit, offset, ordering, 'PUBLIC');
    }

    const state: IState = getState() as IState;
    return state.collections.data;
  }
);

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: initialState.collections,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedSavedCollectionList>) {
      state.data = action.payload;
    },
    resetData() {
      return initialState.collections;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload as PaginatedSavedCollectionList,
      isLoading: false,
      error: null,
    }));
  },
});

export const collectionsSelector = (state: IState) => state.collections;
export const { updateData, resetData } = collectionsSlice.actions;
export default collectionsSlice.reducer;
