import cx from 'classnames';
import Tooltip from 'rc-tooltip';

import { darkBlue } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import s from './InternalNetworksBadge.module.scss';

function InternalNetworksBadge({
  className,
  networks,
  iconColor = darkBlue,
  fontSize = 20,
  tooltipPlacement = 'bottom',
}: any) {
  // sanitize input
  if (!networks) return null;

  // no network
  if (!networks.length) return null;

  const icon = <FAIcon size={fontSize} icon="badge-check" color={iconColor} />;

  // one network
  if (networks.length === 1)
    return (
      <div className={cx(className, s.root)} style={{ fontSize }}>
        {icon} {networks[0].name}
      </div>
    );

  // multiple networks
  const tooltip = (
    <div>
      {networks.map((network: any) => (
        <div key={network.id}>{network.name}</div>
      ))}
    </div>
  );
  return (
    <Tooltip overlay={tooltip} trigger={['hover', 'click']} placement={tooltipPlacement}>
      <div className={cx(className, s.root)} style={{ fontSize }}>
        {icon} {networks.length} Networks
      </div>
    </Tooltip>
  );
}

export default InternalNetworksBadge;
