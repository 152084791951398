import { gengql } from '@/__generated__';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const UPDATE_SITE_SETTINGS = gengql(/* GraphQL */ `
  mutation actionUpdateSiteSettings(
    $group_about_page_onfrontiers_text: String
    $default_conference_carrier: String
  ) {
    updateSiteSettings(
      group_about_page_onfrontiers_text: $group_about_page_onfrontiers_text
      default_conference_carrier: $default_conference_carrier
    ) {
      group_about_page_onfrontiers_text
      default_conference_carrier
    }
  }
`);

export function fetchSiteSettings(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const { siteSettings } = getState();
    if (siteSettings) return siteSettings;

    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionFetchSiteSettings {
          siteSettings {
            group_about_page_onfrontiers_text
            default_conference_carrier
          }
        }
      `)
    );

    if (result.siteSettings) {
      dispatch({
        type: ActionTypes.SITE_SETTINGS__FETCH,
        siteSettings: result.siteSettings,
      });
      return result.siteSettings;
    }
  };
}

export function updateSiteSettings(options: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(UPDATE_SITE_SETTINGS, options);

    const siteSettings = result && result.updateSiteSettings;

    if (siteSettings) {
      dispatch({
        type: ActionTypes.SITE_SETTINGS__UPDATE,
        siteSettings,
      });

      return siteSettings;
    }
  };
}
