import { formatCredits } from '@/utils';

export class Money {
  cents: any;
  currency: any;
  constructor(...args: any[]) {
    const [cents, currency] =
      typeof args[0] === 'object' ? [args[0].cents, args[0].currency] : args;
    Object.assign(this, { cents: parseInt(cents), currency });
  }

  formatted({ currencySymbol, omitEmptyCents }: any = {}) {
    if (!this.currency) return '';

    const sign = this.cents < 0 ? '-' : '';
    const parts = (Math.abs(this.cents) / 100).toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!currencySymbol && currencySymbol !== '') {
      currencySymbol = '$';
      if (this.currency === 'OFC') return formatCredits(this.cents);
    }
    if (omitEmptyCents && parts[1] === '00') {
      return `${sign}${currencySymbol}${parts[0]}`;
    }
    return `${sign}${currencySymbol}${parts.join('.')}`;
  }

  times(value: any) {
    if (typeof value === 'number') {
      return new Money(this.cents * value, this.currency);
    }
    throw new Error(`Unsupported type ${typeof value}.`);
  }

  div(value: any) {
    if (!(value instanceof Money)) throw new Error('unsupported value type');
    if (this.currency !== value.currency)
      throw new Error(`cannot divide ${this.currency} by ${value.currency}`);
    return this.cents / value.cents;
  }

  add(value: any) {
    if (!(value instanceof Money)) throw new Error('unsupported value');
    if (this.currency !== value.currency)
      throw new Error(`unable to add ${this.currency} to ${value.currency}`);
    return new Money(this.cents + value.cents, this.currency);
  }

  subtract(value: any) {
    if (!(value instanceof Money)) throw new Error('unsupported value');
    if (this.currency !== value.currency)
      throw new Error(`unable to subtract ${value.currency} from ${this.currency}`);
    return new Money(this.cents - value.cents, this.currency);
  }

  negate() {
    return new Money(-this.cents, this.currency);
  }

  equal(m: any) {
    if (this.currency !== m.currency)
      throw new Error('cannot eval equality for different currencies.');
    if (this.cents !== m.cents) return false;
    return true;
  }

  greaterThan(m: any) {
    if (this.currency !== m.currency)
      throw new Error('cannot eval greater-than for different currencies.');
    return this.cents > m.cents;
  }

  lessThan(m: any) {
    if (this.currency !== m.currency)
      throw new Error('cannot eval less-than for different currencies.');
    return this.cents < m.cents;
  }

  asRateFrom(currency: any) {
    return new Rate({
      amount: money({ cents: 100, currency }),
      becomes: this,
    });
  }

  toGQL() {
    return {
      cents: `${this.cents}`,
      currency: this.currency,
    };
  }
}

export function money(...args: any[]) {
  return new Money(...args);
}

export class Rate {
  baseCurrency: any;
  quote: any;
  constructor({ baseCurrency, quote }: any = {}) {
    Object.assign(this, { baseCurrency, quote });
  }

  apply(amount: any) {
    if (amount.currency !== this.baseCurrency)
      throw new Error(`this rate accepts only ${this.baseCurrency}, ` + `not ${amount.currency}.`);

    return new Money((amount.cents / 100) * this.quote.cents, this.quote.currency);
  }
}

export function rate({ baseCurrency, quote }: any = {}) {
  return new Rate({ baseCurrency, quote });
}
