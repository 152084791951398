import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BasicInformationForm from '@/profile/components/forms/BasicInformationForm';
import { autofillProfile, updateProfile } from '@/profile/store';
import { hasOnlyLetters, isValidLinkedin } from '@/utils/reducer';

const getStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 0,
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginTop: 15,
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 30,
  },
  loading: {
    marginTop: 100,
    textAlign: 'center',
  },
}));

function BasicInformation({
  nextUrl,
  profileId,
  viewer,
  profile,
  updateProfile,
  autofillProfile,
  ...other
}: any) {
  const s = getStyles();
  const navigate = useNavigate();
  const {
    first_name,
    last_name,
    picture_url,
    country,
    city,
    languages,
    linkedin_url,
    cv_url,
    title,
    summary,
  } = profile;
  const { can_autofill_profile: canAutofill } = viewer;
  const [sending, setSending] = useState(false);

  const initialValues = useMemo(
    () => ({
      first_name,
      last_name,
      picture: picture_url,
      country,
      city,
      languages: (languages || []).map((l: any) => l.code),
      linkedin_url,
      resume: cv_url,
      title,
      summary,
    }),
    [
      city,
      country,
      cv_url,
      first_name,
      languages,
      last_name,
      linkedin_url,
      picture_url,
      summary,
      title,
    ]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      setSending(true);
      const { autofill, cv_url, first_name, last_name, languages } = values;

      if (canAutofill || cv_url) {
        await autofillProfile({
          autofill: autofill === 'true',
          cvUrl: cv_url,
        });
      }

      const result = await updateProfile({
        id: profileId,
        ...values,
        languages: languages.map((l: any) => ({
          code: l,
          fluency: 'professional_working',
        })),
        first_name: first_name.trim(),
        last_name: last_name.trim(),
      }).catch((err: any) => {
        const errors: { [key: string]: string } = {};

        if (err && err.message) {
          if (err.message.includes('first name')) {
            errors.first_name = 'Invalid name';
          } else if (err.message.includes('last name')) {
            errors.last_name = 'Invalid name';
          }
        }

        return { errors };
      });

      if (result?.errors) {
        setSending(false);
        return result?.errors;
      }

      navigate(nextUrl);
    },
    [autofillProfile, canAutofill, navigate, nextUrl, profileId, updateProfile]
  );

  const validate = useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};

      const fields = ['first_name', 'last_name', 'country', 'title', 'summary'];

      const { languages, linkedin_url: linkedinUrl } = values;

      if (canAutofill || values.cv_url) {
        fields.push('autofill');
      }

      if (!languages || !languages.length) {
        errors.languages = 'Required';
      }

      if (linkedinUrl && !isValidLinkedin(linkedinUrl)) {
        errors.linkedin_url = 'Must be a valid https://linkedin.com URL';
      }

      if (!(values.first_name || '').trim()) {
        errors.first_name = 'Required';
      }

      if (values.first_name && !hasOnlyLetters(values.first_name)) {
        errors.first_name = 'Only letters allowed';
      }

      if (!(values.last_name || '').trim()) {
        errors.last_name = 'Required';
      }

      if (values.last_name && !hasOnlyLetters(values.last_name)) {
        errors.last_name = 'Only letters allowed';
      }

      fields.forEach((f) => {
        if (!values[f]) errors[f] = 'Required';
      });

      return errors;
    },
    [canAutofill]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <p className={s.title}>Enter your basic information</p>
        <p className={s.subtitle}>
          Start off by adding your basic profile information and hourly rate.
        </p>

        <Form
          component={BasicInformationForm}
          profileId={profileId}
          nextUrl={nextUrl}
          canAutofill={canAutofill}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          sending={sending}
          {...other}
        />
      </Grid>
    </Grid>
  );
}

BasicInformation.propTypes = {
  nextUrl: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
};

// @ts-expect-error TS(2631) FIXME: Cannot assign to 'BasicInformation' because it is ... Remove this comment to see the full error message
BasicInformation = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId] || {};
    return {
      profile,
    };
  },
  {
    updateProfile,
    autofillProfile,
  }
)(BasicInformation);

export default BasicInformation;
