import { diff } from 'deep-object-diff';
import { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { segmentTracking } from '@/core/analytics';
import { setCache } from '@/utils';

const ExpertRequestSpy = ({
  cacheKey,
  debounce = 2000,
  formValuesInitialized = false,
  values,
}: any) => {
  const [saveTimeout, setSaveTimeout] = useState(null);
  const [savedValues, setSavedValues] = useState();

  const clearSaveTimeout = () => {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    clearTimeout(saveTimeout);
    setSaveTimeout(null);
  };

  useEffect(() => {
    const newValues = { ...values };
    if (formValuesInitialized && values.formInitialized && cacheKey) {
      if (!savedValues) {
        setSavedValues(newValues);
        clearSaveTimeout();
      } else {
        if (saveTimeout) {
          clearSaveTimeout();
        }

        // @ts-expect-error TS(2339) FIXME: Property 'currentVisit' does not exist on type '{}... Remove this comment to see the full error message
        const { currentVisit, ...difference } = diff(savedValues, newValues);
        if (Object.keys(difference).length) {
          setSaveTimeout(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Timeout' is not assignable to pa... Remove this comment to see the full error message
            setTimeout(() => {
              setCache(cacheKey, newValues);
              segmentTracking('expert-request-client-save', {
                fields: difference,
                currentVisit: newValues.currentVisit,
              });
              setSavedValues(newValues);
              clearSaveTimeout();
            }, debounce)
          );
        }
      }
    }
  }, [formValuesInitialized, values]);

  return null;
};

export default (props: any) => (
  <FormSpy {...props} subscription={{ values: true }} component={ExpertRequestSpy} />
);
