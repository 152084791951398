import ActionTypes from '@/actions/ActionTypes';

export default function savedSearchesReducer(state = [], action: any) {
  switch (action.type) {
    case ActionTypes.SAVED_SEARCHES__FETCH:
      return action.savedSearches;
    default:
      return state;
  }
}
