import { avatarClasses } from '@mui/material/Avatar';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
import { chipClasses as MuiChipClasses } from '@mui/material/Chip';

import { faIconClasses } from '@/componentsv2/Icon';
import { pillClasses } from '@/componentsv2/Pill';
import { textClasses } from '@/componentsv2/Text';
import theme from '@/theme';

const ns = 'chip';

export const chipClasses = {
  labelText: `${ns}__label-text`,
  labelContainer: `${ns}__label-container`,
  leftIcon: `${ns}__left-icon`,
  low: `${ns}__low`,
  high: `${ns}__high`,
  medium: `${ns}__medium`,
  rightIcon: `${ns}__right-icon`,
  ...MuiChipClasses,
};

const { high, label, labelContainer, labelText, medium, low } = chipClasses;

const styles = {
  chip: {
    margin: '6px 0',
    [`&.${buttonBaseClasses.root}`]: {
      margin: '6px 0',
    },
    [`&.${MuiChipClasses.outlined} .${label} .${textClasses.root}.${labelText}`]: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${label}`]: {
      display: 'inline-flex',
      [`& .${labelContainer} .${labelText}`]: {
        color: theme.palette.grey[700],
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
    [`& .${chipClasses.rightIcon}`]: {
      margin: '1px 0 0 8px',
    },
    [`& .${avatarClasses.root}`]: {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
    [`& .${chipClasses.leftIcon}`]: {
      marginRight: '5px',
    },
    [`& .${chipClasses.labelContainer}`]: {
      padding: '10px 5px 8px 0',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Inter',
    },
  },
  expertiseChip: {
    marginRight: '5px',
    [`& .${pillClasses.root}`]: {
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '12px',
      padding: '1px 5px',
      [`&.${high}`]: {
        backgroundColor: '#9ADAE0',
        color: theme.palette.primary.main,
        [`&.${pillClasses.outlined}`]: {
          backgroundColor: 'unset !important',
          color: '#9ADAE0',
          border: `solid 1px #9ADAE0`,
        },
      },
      [`&.${medium}`]: {
        backgroundColor: '#20A5B2',
        color: theme.palette.primary.contrastText,
        [`&.${pillClasses.outlined}`]: {
          backgroundColor: 'unset !important',
          color: '#20A5B2',
          border: `solid 1px #20A5B2`,
        },
      },
      [`&.${low}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        [`&.${pillClasses.outlined}`]: {
          backgroundColor: 'unset !important',
          color: theme.palette.primary.main,
          border: `solid 1px ${theme.palette.primary.main}`,
        },
      },
    },
    [`&  .${chipClasses.label}`]: {
      fontSize: '14px',
      lineHeight: '16px',
      '& span': {
        color: theme.palette.secondary.main,
      },
      [`& .${faIconClasses.root}`]: {
        color: theme.palette.primary.main,
        paddingBottom: '10px',
      },
    },
  },
  skillChip: {
    marginRight: '8px',
    display: 'inline-block',
    [`& .${chipClasses.root}`]: {
      height: 26,
      [`& .${chipClasses.label}`]: {
        padding: 0,
        [`& .${chipClasses.labelContainer}`]: {
          padding: '6px 8px',
          [`& .${chipClasses.labelText}`]: {
            fontSize: 12,
            lineHeight: '14px',
            padding: 0,
            color: theme.palette.grey[700],
          },
        },
      },
    },
    '& .fa': {
      fontSize: '12px',
      color: 'rgba(0, 127, 139, 0.3)',
      marginLeft: '12px',
    },
  },
};

export default styles;
