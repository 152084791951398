import { connect } from 'react-redux';

import { hidePopup } from '@/actions/ui';
import { darkBrown } from '@/theme/colors';

import Button from '../Button';
import Dialog from '../Dialog';
import Link from '../Link/Link';
import s from './Popup.module.scss';

function Popup({
  open,
  layout,
  title,
  contents,
  buttons,
  links,
  hidePopup,
  contentStyle,
  buttonAlignment,
}: any) {
  const defaultStyle = {
    // default styles
    contents: { fontSize: 16, lineHeight: 1.5, color: darkBrown },
    buttons: {
      display: 'flex',
      justifyContent: buttonAlignment || 'flex-end',
      marginTop: 20,
    },
    links: { textAlign: 'right', marginTop: 16 },
  };

  const style = Object.assign(
    {},
    defaultStyle,
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    {
      // layout styles
      promo: {
        title: { textAlign: 'center', fontSize: 24 },
        contents: {
          ...defaultStyle.contents,
          textAlign: 'center',
          marginBottom: 10,
        },
        buttons: {
          ...defaultStyle.buttons,
          justifyContent: 'center',
          alignItems: 'center',
        },
        links: {
          ...defaultStyle.links,
          textAlign: 'center',
        },
      },
    }[layout]
  );

  return (
    <Dialog open={open} title={title} titleStyle={style.title} contentStyle={contentStyle}>
      <div style={style.contents}>{contents}</div>

      <div style={style.buttons}>
        {(
          buttons || [
            {
              label: 'OK',
              primary: true,
              callback: () => {},
              flat: false,
              size: 'medium',
            },
          ]
        ).map(({ label, callback, flat, close = true, ...opt }: any) =>
          flat ? (
            <Button
              key={label}
              variant="text"
              size="medium"
              {...opt}
              onClick={() => {
                if (close) hidePopup();
                if (callback) callback();
              }}
            >
              {label}
            </Button>
          ) : (
            <Button
              key={label}
              color="teal"
              style={{ fontSize: 16 }}
              size="medium"
              {...opt}
              onClick={() => {
                if (close) hidePopup();
                if (callback) callback();
              }}
            >
              {label}
            </Button>
          )
        )}
      </div>

      {links && links.length > 0 && (
        <div style={style.links}>
          {links.map(({ label, callback, href, ...opt }: any) => (
            <Link
              key={label}
              {...opt}
              href={href || undefined}
              className={s.link}
              onClick={
                callback
                  ? () => {
                      hidePopup();
                      callback();
                    }
                  : undefined
              }
            >
              {label}
            </Link>
          ))}
        </div>
      )}
    </Dialog>
  );
}

export default connect(undefined, { hidePopup })(Popup);
