import { redirect } from 'react-router-dom';

import { NetworkExpertConnectionState } from '@/__generated__/graphql';
import { fetchNetworkExpert, updateNetworkExpert } from '@/actions/internalNetwork';
import LayoutPage from '@/components/Layout/LayoutPage';
import NotFoundPage from '@/pages/NotFoundPage';
import { fetchProfile } from '@/profile/store';

import { LegacyRoute } from '../routesMiddleware';
import AcceptNetwork from './AcceptNetwork';
import ExpertNetwork from './ExpertNetwork';

const routes: LegacyRoute[] = [
  {
    path: '/network',
    async action({ store }) {
      const { viewer, ui } = store.getState();

      if (!['client', 'expert'].includes(ui.userContext)) {
        return redirect('/dashboard');
      }

      const profile = await store.dispatch(
        fetchProfile(viewer.username!, {
          force: true,
          internalNetworks: true,
          groupAboutPage: true,
          internalNetworkConsultations: true,
          connectionStates: [
            NetworkExpertConnectionState.Active,
            NetworkExpertConnectionState.AwaitingAcceptance,
          ],

          // for completeness
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
        })
      );

      document.title = 'My Clients';
      return (
        <LayoutPage showNav selected="my-network">
          <ExpertNetwork profileId={profile!.id} />
        </LayoutPage>
      );
    },
  },
  {
    path: '/network/:networkExpertId/:connectionState',
    async action({ store, params }) {
      const { networkExpertId, connectionState } = params;

      if (!['rejected', 'removed', 'active'].includes(connectionState || ''))
        return <NotFoundPage />;

      const networkExpert = await store.dispatch(fetchNetworkExpert(networkExpertId));

      const { domain } = networkExpert.network.group;

      const canAccept = !domain || !domain.agreements || domain.agreements.length === 0;

      if (connectionState !== 'active' || canAccept) {
        await store.dispatch(
          updateNetworkExpert({
            id: networkExpertId,
            connection_state: connectionState,
          })
        );
        return redirect('/network');
      }

      document.title = 'My Clients';
      return (
        <LayoutPage showNav selected="my-network">
          <AcceptNetwork networkExpert={networkExpert} />
        </LayoutPage>
      );
    },
  },
];

export default routes;
