/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProjectMemberList } from '../models/PaginatedProjectMemberList';
import type { PatchedProjectMemberUpdate } from '../models/PatchedProjectMemberUpdate';
import type { ProjectMember } from '../models/ProjectMember';
import type { ProjectMemberCreate } from '../models/ProjectMemberCreate';
import type { ProjectMemberUpdate } from '../models/ProjectMemberUpdate';

export class ProjectMemberService {
  /**
   * List all project members
   * List all project members
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId Filter the project member list by profile id
   * @param projectId Filter the project member list by project id
   * @returns PaginatedProjectMemberList
   * @throws ApiError
   */
  public static projectMemberList(
    limit?: number,
    offset?: number,
    profileId?: string,
    projectId?: string
  ): CancelablePromise<PaginatedProjectMemberList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/project_member/',
      query: {
        limit: limit,
        offset: offset,
        profile__id: profileId,
        project__id: projectId,
      },
    });
  }

  /**
   * Create a project member
   * Create a project member
   * @param requestBody
   * @returns ProjectMember
   * @throws ApiError
   */
  public static projectMemberCreate(
    requestBody: ProjectMemberCreate
  ): CancelablePromise<ProjectMember> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/project_member/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a project member by UUID
   * Retrieve a project member by UUID
   * @param id A UUID string identifying this project member.
   * @returns ProjectMember
   * @throws ApiError
   */
  public static projectMemberRetrieve(id: string): CancelablePromise<ProjectMember> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/project_member/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a project member by UUID
   * Completely update a project member by UUID
   * @param id A UUID string identifying this project member.
   * @param requestBody
   * @returns ProjectMemberUpdate
   * @throws ApiError
   */
  public static projectMemberUpdate(
    id: string,
    requestBody: ProjectMemberUpdate
  ): CancelablePromise<ProjectMemberUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/project_member/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a project member by UUID
   * Update specific fields within a project member by UUID
   * @param id A UUID string identifying this project member.
   * @param requestBody
   * @returns ProjectMemberUpdate
   * @throws ApiError
   */
  public static projectMemberPartialUpdate(
    id: string,
    requestBody?: PatchedProjectMemberUpdate
  ): CancelablePromise<ProjectMemberUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/project_member/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a project member by UUID
   * Delete a project member by UUID
   * @param id A UUID string identifying this project member.
   * @returns void
   * @throws ApiError
   */
  public static projectMemberDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/project_member/{id}/',
      path: {
        id: id,
      },
    });
  }
}
