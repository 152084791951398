import moment from 'moment-timezone';
import { UnknownAction } from 'redux';

import { gengql } from '@/__generated__';
import {
  GetUserActionCreditRateQuery,
  NetworkExpertConnectionState,
} from '@/__generated__/graphql';
import { PROFILE__UPDATE } from '@/profile/store';
import { removeAttribute } from '@/utils/reducer';

import { AppThunk } from '.';
import { GET_CREDIT_RATE, GET_USER, UPDATE_USER } from './queries';

const USER__UPDATE_INFORMATION = 'USER__UPDATE_INFORMATION';
const USER__DELETE = 'USER__DELETE';

export function updateUserInfo(user: object): UnknownAction {
  return {
    type: USER__UPDATE_INFORMATION,
    user,
  };
}

export function updateUser(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(UPDATE_USER, values);

    if (data) {
      dispatch(updateUserInfo(data.updateUser));
      return data.updateUser;
    }
  };
}

export function updateExpertState(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateExpertState($id: String!, $expert_state: ExpertState!) {
          updateExpertState(id: $id, expert_state: $expert_state)
        }
      `),
      values
    );

    if (data.updateExpertState) {
      dispatch({
        type: USER__UPDATE_INFORMATION,
        user: values,
      });
    }
  };
}

export function deleteUser(id: any): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        gengql(/* GraphQL */ `
          mutation actionDeleteUser($id: String!) {
            deleteUser(id: $id) {
              id
            }
          }
        `),
        { id }
      )
      .then(() =>
        dispatch({
          type: USER__DELETE,
          id,
        })
      );
}

export function fetchUser(
  id: any,
  {
    force = false,

    // user
    stats = false,
    canAutofillProfile = false,
    signupSubdomain = false,
    groups = false,
    profileKeywordsDefinition = false,
    recruiter = false,
    otpAuthEnabled = false,

    // profile
    audit = false,
    experiences = false,
    addresses = false,
    expertise = false,
    groupKeywords = false,
    education = false,
    sources = false,
    sharedInternalNetworks = false,
    internalNetworks = false,
    expertRequestCandidates = false,
    availableConsultation = false,
    connectionStates = [NetworkExpertConnectionState.Active],
  } = {}
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const queryKey = JSON.stringify({
      id,
      stats,
      canAutofillProfile,
      groups,
      recruiter,
      audit,
      experiences,
      addresses,
      education,
      expertise,
      internalNetworks,
      sources,
      sharedInternalNetworks,
      expertRequestCandidates,
    });
    // use cache when possible - avoid unnecessary round trips
    const queryStatus = (getState().users.$$query_meta$$ || {})[queryKey];
    if (!force && queryStatus && queryStatus.result && moment().isBefore(queryStatus.expiresAt)) {
      const user = queryStatus.result;
      const creditRate = await dispatch(fetchUserCreditRate(user.id));
      if (creditRate) {
        user.profile.credit_rate = creditRate.cents;
      }

      dispatch({
        type: USER__UPDATE_INFORMATION,
        queryKey,
        user,
      });

      dispatch({
        type: PROFILE__UPDATE,
        queryKey,
        profile: user.profile,
      });

      return user;
    }

    const params = {
      id: id || getState().viewer.username,
      otpAuthEnabled,
      stats,
      canAutofillProfile,
      signupSubdomain,
      groups,
      recruiter,
      audit,
      experiences,
      addresses,
      expertise,
      groupKeywords,
      education,
      sources,
      sharedInternalNetworks,
      expertRequestCandidates,
      availableConsultation,
      internalNetworks,
      profileKeywordsDefinition,
      connectionStates,
    };

    if (internalNetworks) {
      params.connectionStates = connectionStates;
    }

    const { data } = await graphql.client.query({
      query: GET_USER,
      variables: params,
      fetchPolicy: force ? 'network-only' : undefined,
    });
    const user = data.user;

    if (user) {
      const creditRate = await dispatch(fetchUserCreditRate(user.id));
      const profile = { ...user.profile, credit_rate: creditRate?.cents };

      dispatch({
        type: USER__UPDATE_INFORMATION,
        queryKey,
        user: { ...user, profile },
      });

      dispatch({
        type: PROFILE__UPDATE,
        queryKey,
        profile,
      });
    }

    return user;
  };
}

export function submitClientInterest(data: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionSubmitClientInterest(
          $name: String
          $title: String
          $organization: String
          $email: String
          $phone: String
          $use_case: String
          $questions: String
          $source: ClientSource!
        ) {
          submitClientInterest(
            name: $name
            title: $title
            organization: $organization
            email: $email
            phone: $phone
            use_case: $use_case
            questions: $questions
            source: $source
          )
        }
      `),
      data
    );
}

/**
 * Fetched the credit rate for a given user taking in context the current user's billing account
 * @param {number} userId
 * @returns {async function(dispatch, getState, {graphql: *}): Promise<{cents: number, currency: string}>}
 */

export function fetchUserCreditRate(
  userId: string
): AppThunk<Promise<NonNullable<GetUserActionCreditRateQuery['expert']>['creditRate']>> {
  return async (_dispatch, getState, { graphql }) => {
    const { userContext, userContextOptions } = getState().ui;

    const billingAccountId =
      userContextOptions?.find((o: any) => o.value === userContext)?.billingAccountId || '';

    const { data } = await graphql.client.query({
      query: GET_CREDIT_RATE,
      variables: { id: userId, billingAccountId },
    });

    return data.expert?.creditRate || null;
  };
}

const INITIAL_STATE = {
  $$query_meta$$: {},
};

function reduceUser(state = {}, action: any) {
  switch (action.type) {
    case USER__UPDATE_INFORMATION:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}

export default function (state = INITIAL_STATE, action: any) {
  const userId =
    action.type === USER__UPDATE_INFORMATION
      ? // @ts-expect-error TS(2339): Property 'currentId' does not exist on type '{ $$q... Remove this comment to see the full error message
        action.user.id || state.currentId
      : // @ts-expect-error TS(2339): Property 'currentId' does not exist on type '{ $$q... Remove this comment to see the full error message
        action.userId || state.currentId;

  if (!userId) return state;

  if (action.type === USER__DELETE) {
    return removeAttribute(state, action.id);
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const newUserState = reduceUser(state[userId], action);
  const newState = {
    ...state,
    [userId]: newUserState,
  };

  if (action.user && action.user.username) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newState[action.user.username] = newUserState;
  }

  if (action.type === USER__UPDATE_INFORMATION && action.queryKey) {
    newState.$$query_meta$$ = {
      ...newState.$$query_meta$$,
      [action.queryKey]: {
        expiresAt: moment().add(1, 'minute').toISOString(),
        result: newUserState,
      },
    };
  }

  return newState;
}
