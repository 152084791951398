import { Chip } from '@mui/material';
import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { track } from '@/actions/tracking';
import Message from '@/messaging/components/Message';
import { darkBrown, darkGreen, sand, white } from '@/theme/colors';

import s from './Transcript.module.scss';

function createSpeakerMap(speakers: any[]) {
  const speakerMap: Record<string, { first_name: string; last_name: string }> = {};

  for (const speaker of speakers) {
    if (speakerMap[speaker.id]) continue;

    const name = speaker?.name || String(Object.keys(speakerMap).length + 1);
    const nameParts = name.split(' ');
    speakerMap[speaker.id] = { first_name: nameParts[0], last_name: nameParts[1] };
  }

  return speakerMap;
}

interface TranscriptProps {
  transcription: any;
  expertId?: string;
}

const connector = connect(undefined, {
  track,
});

const Transcript = ({
  transcription,
  expertId,
}: TranscriptProps & ConnectedProps<typeof connector>) => {
  const speakers = transcription.speakers || [];
  const monologues = transcription.monologues || [];

  const leftSpeaker =
    speakers.find((x: any) => x.user && x.user.id === expertId) ||
    speakers.find((x: any) => !x.user) ||
    speakers[0] ||
    {};

  const speakerMap = createSpeakerMap(speakers);

  useEffect(() => {
    track('consultation.transcription.view', transcription.id, undefined, true);
  }, [transcription.id]);

  return (
    <div className={s.root}>
      {monologues.map((m: any) => {
        const elements = m.elements || [];
        const speaker = m.speaker || {};
        const { user } = speaker;

        const text = elements.map((e: any) => {
          if (e.type === 'tag')
            return (
              <Chip
                key={`${e.position}${e.value}`}
                style={{
                  display: 'inline',
                  verticalAlign: 'baseline',
                  marginLeft: 3,
                  marginRight: 3,
                }}
                label={e.value}
              />
            );
          return e.value;
        });

        const isLeft = leftSpeaker.id === speaker.id;

        return (
          <Message
            key={text}
            className={s.message}
            color={isLeft ? darkBrown : white}
            borderColor={isLeft ? sand : darkGreen}
            backgroundColor={isLeft ? sand : darkGreen}
            position={isLeft ? 'left' : 'right'}
            align="top"
            user={user || speakerMap[speaker.id]}
          >
            {text}
          </Message>
        );
      })}
    </div>
  );
};

export default connector(Transcript);
