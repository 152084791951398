import MuiPagination from '@mui/material/Pagination';
import MuiPaginationItem from '@mui/material/PaginationItem';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { darkGreen, white } from '@/theme/colors';

const useItemStyles = makeStyles({
  root: {
    color: darkGreen,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: lighten(darkGreen, 0.9),
    },
  },
  selected: {
    color: white,
    backgroundColor: `${darkGreen} !important`,
  },
  ellipsis: {
    '&:hover': {
      backgroundColor: white,
    },
  },
});

function Pagination({ ...other }) {
  const itemStyles = useItemStyles();

  return (
    <MuiPagination
      {...other}
      renderItem={(item) => <MuiPaginationItem classes={itemStyles} {...item} />}
    />
  );
}

export default Pagination;
