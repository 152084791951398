import { Button as MaterialButton } from '@mui/material';
import { FC, ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { addRegistrant, getJoinInfo, joinCall, leaveCall } from '@/actions/call';
import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import Link from '@/components/Link';
import { JoinInfo } from '@/conference';
import { isTooEarlyToJoinCall, shouldAllowJoinCall } from '@/consultation';
import { dialOutExpert } from '@/consultation/store';
import Duration from '@/core/duration';
import { RootState } from '@/store';

import s from './JoinCall.module.scss';

const getRequesterName = (c: any) => (c.requester ? c.requester.name : 'Confidential client');
const getExpertName = (c: any) => (c.expert ? c.expert.name : 'Expert');
function getUserName(viewer: any, consultation: any) {
  const { expert } = consultation;
  const isViewerExpert = expert && viewer.id === expert.id;
  return isViewerExpert ? getRequesterName(consultation) : getExpertName(consultation);
}

interface DialOutExpertButtonProps {
  phone: string;
  onDialOutExpert: () => void;
}

const DialOutExpertButton: FC<DialOutExpertButtonProps> = memo(({ phone, onDialOutExpert }) => {
  const [status, setStatus] = useState<string | undefined>(undefined);

  const handleDialOutExpert = useCallback(() => {
    setStatus('dialing');
    onDialOutExpert();
  }, [onDialOutExpert]);

  return (
    <div>
      <MaterialButton disabled={status === 'dialing'} onClick={handleDialOutExpert}>
        {' '}
        {status === 'dialing' ? `Dialing ${phone}...` : `Dial expert at ${phone}`}
      </MaterialButton>
    </div>
  );
});
DialOutExpertButton.displayName = 'DialOutExpertButton';

interface CallInstructionProps {
  title?: string;
  children: ReactNode;
}

const CallInstruction: FC<CallInstructionProps> = ({ title, children }) => {
  return (
    <div className={s.joinCall}>
      <div className={s.joinCallInstructionsTitle}>{title}</div>
      <div className={s.joinCallInstructionsText}>{children}</div>
    </div>
  );
};

function isIOS(ua: UAParser.IResult) {
  return ua.os.name === 'iOS';
}

function isSafari(ua: UAParser.IResult) {
  return ua.browser.name === 'Safari';
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    call: state.call,
  }),
  {
    addRegistrant,
    dialOutExpert,
    getJoinInfo,
    joinCall,
    leaveCall,
  }
);

interface JoinCallProps {
  consultation: any;
  joinInfo?: JoinInfo;
}

const JoinCall: FC<JoinCallProps & ConnectedProps<typeof connector>> = memo(
  ({
    consultation,
    joinInfo,
    getJoinInfo,
    viewer,
    dialOutExpert,
    call,
    joinCall,
    addRegistrant,
  }) => {
    const [joinURL, setJoinURL] = useState<string | null>(null);
    const [unsupportedErrorOpen, setUnsupportedErrorOpen] = useState(false);

    const ua = viewer.userAgentParsed;

    const {
      conference,
      expert,
      expert_identifier: expertIdentifier,
      requester_identifier: requesterIdentifier,
      starts_at: startsAt,
      expected_duration: expectedDuration,
    } = consultation;
    useEffect(() => {
      (async function setup() {
        if (conference?.carrier === 'zoom' && conference?.id && isIOS(ua) && isSafari(ua)) {
          const info = joinInfo || (await getJoinInfo(conference.id));
          setJoinURL(info.join_url);
        }
      })();
    }, [conference?.carrier, conference?.id, getJoinInfo, joinInfo, ua]);

    const handleJoinCall = async () => {
      if (!conference) {
        return;
      }

      const isViewerExpert = expert && viewer.id === expert.id;
      let identifier = isViewerExpert ? expertIdentifier : requesterIdentifier;
      let joinURL = null;

      if (conference?.carrier === 'zoom') {
        let registrant = conference.registrants.find((r: any) => r.email === viewer.email?.address);
        // User must be added as a registrant before joining the call, expert
        // and requester are added by default
        if (!registrant) {
          registrant = await addRegistrant(consultation, {
            conferenceId: conference.id,
            name: `${viewer.first_name} ${viewer.last_name}`,
            email: viewer.email?.address,
            phoneNumber: viewer.phone,
          });
        }

        // refresh join info if not available yet
        const info = joinInfo || (await getJoinInfo(conference.id));
        identifier = info.identifier;
        joinURL = info.join_url;
      }

      try {
        await joinCall(
          {
            carrier: conference.carrier,
            userName: getUserName(viewer, consultation),
            identifier,
            joinURL,
            startTime: startsAt,
            expectedDuration: Duration.parse(expectedDuration || '0s').seconds(),
            consultationId: consultation.id,
            mainPage: `/consultation/${consultation.id}`,
            awaitingExpert: false,
          },
          {
            receiveEvents: !isViewerExpert,
          }
        );
      } catch (e: any) {
        if (e.unsupportedBrowser) {
          setUnsupportedErrorOpen(true);
          return;
        }
        throw e;
      }
    };

    if (consultation.state !== 'confirmed') return null;

    const ios = isIOS(ua);
    const safari = isSafari(ua);
    const isZoomCall = conference?.carrier === 'zoom';
    const iosUnsupported = ios && !safari && !isZoomCall;

    return (
      <div>
        {call.connected && call.consultationId === consultation.id && (
          <div>
            <span className={s.ongoingCallOuter}>
              <div className={s.ongoingCallInner} />
            </span>
            <span className={s.ongoingCall}>Recording</span>
          </div>
        )}
        {call.connected && call.consultationId !== consultation.id && (
          <CallInstruction title="Another conference active.">
            Hang up current call before joining.
          </CallInstruction>
        )}
        {!call.connected && (
          <div className={s.joinCall}>
            {safari && ios && isZoomCall ? (
              <Link target="_blank" to={joinURL || ''}>
                <Button
                  startIcon={<MaterialIcon icon="phone" style={{ fontSize: 24 }} />}
                  size="large"
                  disabled={!shouldAllowJoinCall(consultation)}
                >
                  Join Call
                </Button>
              </Link>
            ) : (
              <Button
                startIcon={<MaterialIcon icon="phone" style={{ fontSize: 24 }} />}
                size="large"
                onClick={handleJoinCall}
                disabled={!shouldAllowJoinCall(consultation) || iosUnsupported || call.connecting}
              >
                {call.connecting ? 'Joining...' : 'Join Call'}
              </Button>
            )}
            {iosUnsupported ? (
              <CallInstruction>
                <div className={s.notSupportedText}>
                  This browser is not supported on iOS.
                  <br />
                  Please switch to Safari.
                </div>
              </CallInstruction>
            ) : isTooEarlyToJoinCall(consultation) ? (
              <CallInstruction title="This call is not scheduled to begin yet.">
                Your web conference link will be accessible 1 hour before your scheduled call start
                time.
              </CallInstruction>
            ) : (
              viewer.admin &&
              !isZoomCall &&
              expert && (
                <DialOutExpertButton
                  phone={expert.phone}
                  onDialOutExpert={() => dialOutExpert(consultation.id)}
                />
              )
            )}
            <Dialog
              warning
              open={unsupportedErrorOpen}
              title="Unsupported Browser"
              subTitle={'Please switch to another browser, ' + 'such as Firefox or Chrome.'}
              onConfirm={() => setUnsupportedErrorOpen(false)}
              confirmLabel="Ok"
              confirmButtonProps={{ color: 'red' }}
            />
          </div>
        )}
      </div>
    );
  }
);
JoinCall.displayName = 'JoinCall';

export default connector(JoinCall);
