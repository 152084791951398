import Autocomplete from '@mui/material/Autocomplete';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { X } from 'react-feather';

import Box from '@/componentsv2/Box';
import Button from '@/componentsv2/Button';
import Chip from '@/componentsv2/Chip';
import FormikForm from '@/componentsv2/FormikForm';
import { FAIcon } from '@/componentsv2/Icon';
import { SelectOption } from '@/componentsv2/Select/types';
import { SavedCollection, SavedCollectionService } from '@/openapi';

import styles, { DialogClasses } from './styles';

const useStyles = makeStyles(styles);

export interface IAddingToMyCollectionDialog extends DialogProps {
  className?: string;
  onClose?: () => void;
  open: boolean;
  dialogTitle?: string | React.ReactNode | JSX.Element;
  showCloseButton?: boolean;
  'data-test-id'?: string;
  options: SavedCollection[];
  profileId: string;
  profileName: string;
  email: string | null | undefined;
  createNewCollection?: () => void;
  onAdded?: (collectionId: string) => void;
}

const AddingToMyCollectionDialog: React.FC<IAddingToMyCollectionDialog> = (props) => {
  const classes = useStyles();
  const classProps = clsx(props.className, classes.Dialog, DialogClasses.root);
  const testId = props['data-test-id'] || 'of-dialog';
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      collections: [],
    },
    onSubmit: (values) => {
      values.collections.map((item: { id: string; value: string; label: string }) => {
        const collectionSelected = item;
        if (item && props.profileId) {
          SavedCollectionService.savedCollectionProfileCreate(item.id, {
            profile: [props.profileId],
          })
            .then(() => {
              enqueueSnackbar(
                `${props.profileName} was added to the ${collectionSelected.value} Collection`,
                { variant: 'success' }
              );
              props.onAdded?.(item.value);
              props.onClose?.();
            })
            .catch(() => {
              enqueueSnackbar(`Error creating collection`, {
                variant: 'error',
              });
            });
        }
      });
    },
  });

  const collectionsInputValue = formik.values.collections;
  const AutocompleteOptions = props.options.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const handleChange = (_event: unknown, newValue: unknown | null) => {
    formik.setFieldValue('collections', newValue);
  };

  const handleCta = () => {
    props.onClose && props.onClose();
    props.createNewCollection && props.createNewCollection();
  };

  const renderCollections = () => {
    if (!Array.isArray(formik?.values.collections) || formik?.values.collections?.length < 1) {
      return;
    }
    const array = formik?.values.collections as SelectOption[];
    const values = array.filter((v) => !!v);

    const cleanChip = (index: number) => {
      const newValue = array.filter((_, i) => i !== index);
      formik.setFieldValue('collections', newValue);
    };

    return (
      <Box className={DialogClasses.chipContainer}>
        {values.map((value, index) => (
          <Chip
            key={'chip-' + index}
            label={value.label}
            className={DialogClasses.chip}
            color="error"
            righticon={
              <Box className={DialogClasses.chipCloseContainer}>
                <FAIcon light icon="close" onClick={() => cleanChip(index)} />
              </Box>
            }
          />
        ))}
      </Box>
    );
  };

  return (
    <Dialog className={classProps} data-testid={testId} open={props.open} onClose={props.onClose}>
      <Box className="flex justify-between p-24 pb-0">
        {props.dialogTitle ? (
          <h2 className="hd-6">{props.dialogTitle}</h2>
        ) : (
          <Skeleton variant="text" width={210} height={30} />
        )}
        {props.showCloseButton && (
          <Button
            variant="tertiary"
            size="large"
            startIcon={X}
            srText="Close"
            onClick={props.onClose}
          />
        )}
      </Box>
      <DialogContent>
        {props.children || (
          <FormikForm value={formik} className="space-y-24">
            <p className="body-1">Add this user to a collection or create a new one.</p>
            <FormControl>
              <Autocomplete
                value={collectionsInputValue}
                disableClearable
                options={AutocompleteOptions}
                onChange={handleChange}
                multiple
                renderInput={(params) => (
                  <TextField {...params} label="Select or create new collection" variant="filled" />
                )}
                renderTags={() => null}
              />
              <p className="mt-12 body-2">
                Click{' '}
                <button
                  onClick={handleCta}
                  className="text-brand-primary underline underline-offset-2"
                >
                  here
                </button>{' '}
                to create a new collection.
              </p>
              {renderCollections()}
              <Box className="mt-24 flex justify-end">
                <Button
                  size="small"
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={!!formik?.values.collections || formik.isSubmitting}
                >
                  Add to collection
                </Button>
              </Box>
            </FormControl>
          </FormikForm>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddingToMyCollectionDialog;
