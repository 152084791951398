import React, { Fragment } from 'react';

import Divider from '@/components/Divider';

import s from './Filter.module.scss';

function Filter({ title, children, titleStyle, hideDivider, ...other }: any) {
  return (
    <Fragment>
      <div className={s.filter} {...other}>
        {title && (
          <div className={s.filterTitle} style={titleStyle}>
            {title}
          </div>
        )}
        {children}
      </div>

      {!hideDivider && <Divider spacing={0} />}
    </Fragment>
  );
}

export default Filter;
