import cx from 'classnames';

import Button from '@/components/Button/Button';
import FAIcon from '@/components/Icon/FAIcon';
import { darkBlue } from '@/theme/colors';

import s from './NoConsultationsInstructions.module.scss';

function NoConsultationsInstructions() {
  return (
    <div className={s.root}>
      <FAIcon icon="calendar-o" size={60} style={{ color: darkBlue }} />
      <h3 className={s.title}>No scheduled calls yet</h3>

      <p className={cx(s.text, s.spaced)}>
        We’ll notify you when we find client projects that match your areas of expertise.
      </p>
      <p className={s.text}>Learn answers to common OnFrontiers Expert questions such as:</p>
      <p className={s.text}>How will call logistics work? How will I get paid?</p>

      <Button href="https://try.onfrontiers.com/knowledge" size="large">
        Visit the OnFrontiers Expert FAQ
      </Button>
    </div>
  );
}

export default NoConsultationsInstructions;
