export const faIconClasses = {
  root: 'faicon-root',
};
export const styles = {
  icon: {
    '&.fa': {
      '&.fal': {
        fontWeight: 'normal',
      },
      '&.fa-xs': {
        fontSize: '.75em',
      },
      '&.fa-sm': {
        fontSize: '.875em',
      },
      '&.fa-lg': {
        fontSize: '1.33333em',
      },
    },
  },
  flagIcon: {
    '&.flag': {
      backgroundPosition: '0 -26px',
      width: '24px !important',
      height: '18px !important',
      display: 'inline-block',
      backgroundImage: 'url(/flags.png)',
    },
    '&.flag-ad': { backgroundPosition: '0 0' },
    '&.flag-ae': { backgroundPosition: '0 -20px' },
    '&.flag-af': { backgroundPosition: '0 -40px' },
    '&.flag-ag': { backgroundPosition: '0 -60px' },
    '&.flag-ai': { backgroundPosition: '0 -80px' },
    '&.flag-al': { backgroundPosition: '0 -100px' },
    '&.flag-am': { backgroundPosition: '0 -120px' },
    '&.flag-ao': { backgroundPosition: '0 -140px' },
    '&.flag-aq': { backgroundPosition: '0 -160px' },
    '&.flag-ar': { backgroundPosition: '0 -180px' },
    '&.flag-as': { backgroundPosition: '0 -200px' },
    '&.flag-at': { backgroundPosition: '0 -220px' },
    '&.flag-au': { backgroundPosition: '0 -240px' },
    '&.flag-aw': { backgroundPosition: '0 -260px' },
    '&.flag-ax': { backgroundPosition: '0 -280px' },
    '&.flag-az': { backgroundPosition: '0 -300px' },
    '&.flag-ba': { backgroundPosition: '0 -320px' },
    '&.flag-bb': { backgroundPosition: '0 -340px' },
    '&.flag-bd': { backgroundPosition: '0 -360px' },
    '&.flag-be': { backgroundPosition: '0 -380px' },
    '&.flag-bf': { backgroundPosition: '0 -400px' },
    '&.flag-bg': { backgroundPosition: '0 -420px' },
    '&.flag-bh': { backgroundPosition: '0 -440px' },
    '&.flag-bi': { backgroundPosition: '0 -460px' },
    '&.flag-bj': { backgroundPosition: '0 -480px' },
    '&.flag-bl': { backgroundPosition: '0 -500px' },
    '&.flag-bm': { backgroundPosition: '0 -520px' },
    '&.flag-bn': { backgroundPosition: '0 -540px' },
    '&.flag-bo': { backgroundPosition: '0 -560px' },
    '&.flag-bq': { backgroundPosition: '0 -580px' },
    '&.flag-br': { backgroundPosition: '0 -600px' },
    '&.flag-bs': { backgroundPosition: '0 -620px' },
    '&.flag-bt': { backgroundPosition: '0 -640px' },
    '&.flag-bv': { backgroundPosition: '0 -660px' },
    '&.flag-bw': { backgroundPosition: '0 -680px' },
    '&.flag-by': { backgroundPosition: '0 -700px' },
    '&.flag-bz': { backgroundPosition: '0 -720px' },
    '&.flag-ca': { backgroundPosition: '0 -740px' },
    '&.flag-cc': { backgroundPosition: '0 -760px' },
    '&.flag-cd': { backgroundPosition: '0 -780px' },
    '&.flag-cf': { backgroundPosition: '0 -800px' },
    '&.flag-cg': { backgroundPosition: '0 -820px' },
    '&.flag-ch': { backgroundPosition: '0 -840px' },
    '&.flag-ci': { backgroundPosition: '0 -860px' },
    '&.flag-ck': { backgroundPosition: '0 -880px' },
    '&.flag-cl': { backgroundPosition: '0 -900px' },
    '&.flag-cm': { backgroundPosition: '0 -920px' },
    '&.flag-cn': { backgroundPosition: '0 -940px' },
    '&.flag-co': { backgroundPosition: '0 -960px' },
    '&.flag-cr': { backgroundPosition: '0 -980px' },
    '&.flag-cu': { backgroundPosition: '0 -1000px' },
    '&.flag-cv': { backgroundPosition: '0 -1020px' },
    '&.flag-cw': { backgroundPosition: '0 -1040px' },
    '&.flag-cx': { backgroundPosition: '0 -1060px' },
    '&.flag-cy': { backgroundPosition: '0 -1080px' },
    '&.flag-cz': { backgroundPosition: '0 -1100px' },
    '&.flag-de': { backgroundPosition: '0 -1120px' },
    '&.flag-dj': { backgroundPosition: '0 -1140px' },
    '&.flag-dk': { backgroundPosition: '0 -1160px' },
    '&.flag-dm': { backgroundPosition: '0 -1180px' },
    '&.flag-do': { backgroundPosition: '0 -1200px' },
    '&.flag-dz': { backgroundPosition: '0 -1220px' },
    '&.flag-ec': { backgroundPosition: '0 -1240px' },
    '&.flag-ee': { backgroundPosition: '0 -1260px' },
    '&.flag-eg': { backgroundPosition: '0 -1280px' },
    '&.flag-eh': { backgroundPosition: '0 -1300px' },
    '&.flag-er': { backgroundPosition: '0 -1320px' },
    '&.ic-flag-ct': { backgroundPosition: '0 -1340px' },
    '&.flag-es': { backgroundPosition: '0 -1360px' },
    '&.flag-et': { backgroundPosition: '0 -1380px' },
    '&.flag-eu': { backgroundPosition: '0 -1400px' },
    '&.flag-fi': { backgroundPosition: '0 -1420px' },
    '&.flag-fj': { backgroundPosition: '0 -1440px' },
    '&.flag-fk': { backgroundPosition: '0 -1460px' },
    '&.flag-fm': { backgroundPosition: '0 -1480px' },
    '&.flag-fo': { backgroundPosition: '0 -1500px' },
    '&.flag-fr': { backgroundPosition: '0 -1520px' },
    '&.flag-ga': { backgroundPosition: '0 -1540px' },
    '&.ic-gflageng': { backgroundPosition: '0 -1560px' },
    '&.ic-gflagnir': { backgroundPosition: '0 -1580px' },
    '&.ic-gflagsct': { backgroundPosition: '0 -1600px' },
    '&.ic-gflagwls': { backgroundPosition: '0 -1620px' },
    '&.flag-gb': { backgroundPosition: '0 -1640px' },
    '&.flag-gd': { backgroundPosition: '0 -1660px' },
    '&.flag-ge': { backgroundPosition: '0 -1680px' },
    '&.flag-gf': { backgroundPosition: '0 -1700px' },
    '&.flag-gg': { backgroundPosition: '0 -1720px' },
    '&.flag-gh': { backgroundPosition: '0 -1740px' },
    '&.flag-gi': { backgroundPosition: '0 -1760px' },
    '&.flag-gl': { backgroundPosition: '0 -1780px' },
    '&.flag-gm': { backgroundPosition: '0 -1800px' },
    '&.flag-gn': { backgroundPosition: '0 -1820px' },
    '&.flag-gp': { backgroundPosition: '0 -1840px' },
    '&.flag-gq': { backgroundPosition: '0 -1860px' },
    '&.flag-gr': { backgroundPosition: '0 -1880px' },
    '&.flag-gs': { backgroundPosition: '0 -1900px' },
    '&.flag-gt': { backgroundPosition: '0 -1920px' },
    '&.flag-gu': { backgroundPosition: '0 -1940px' },
    '&.flag-gw': { backgroundPosition: '0 -1960px' },
    '&.flag-gy': { backgroundPosition: '0 -1980px' },
    '&.flag-hk': { backgroundPosition: '0 -2000px' },
    '&.flag-hm': { backgroundPosition: '0 -2020px' },
    '&.flag-hn': { backgroundPosition: '0 -2040px' },
    '&.flag-hr': { backgroundPosition: '0 -2060px' },
    '&.flag-ht': { backgroundPosition: '0 -2080px' },
    '&.flag-hu': { backgroundPosition: '0 -2100px' },
    '&.flag-id': { backgroundPosition: '0 -2120px' },
    '&.flag-ie': { backgroundPosition: '0 -2140px' },
    '&.flag-il': { backgroundPosition: '0 -2160px' },
    '&.flag-im': { backgroundPosition: '0 -2180px' },
    '&.flag-in': { backgroundPosition: '0 -2200px' },
    '&.flag-io': { backgroundPosition: '0 -2220px' },
    '&.flag-iq': { backgroundPosition: '0 -2240px' },
    '&.flag-ir': { backgroundPosition: '0 -2260px' },
    '&.flag-is': { backgroundPosition: '0 -2280px' },
    '&.flag-it': { backgroundPosition: '0 -2300px' },
    '&.flag-je': { backgroundPosition: '0 -2320px' },
    '&.flag-jm': { backgroundPosition: '0 -2340px' },
    '&.flag-jo': { backgroundPosition: '0 -2360px' },
    '&.flag-jp': { backgroundPosition: '0 -2380px' },
    '&.flag-ke': { backgroundPosition: '0 -2400px' },
    '&.flag-kg': { backgroundPosition: '0 -2420px' },
    '&.flag-kh': { backgroundPosition: '0 -2440px' },
    '&.flag-ki': { backgroundPosition: '0 -2460px' },
    '&.flag-km': { backgroundPosition: '0 -2480px' },
    '&.flag-kn': { backgroundPosition: '0 -2500px' },
    '&.flag-kp': { backgroundPosition: '0 -2520px' },
    '&.flag-kr': { backgroundPosition: '0 -2540px' },
    '&.flag-kw': { backgroundPosition: '0 -2560px' },
    '&.flag-ky': { backgroundPosition: '0 -2580px' },
    '&.flag-kz': { backgroundPosition: '0 -2600px' },
    '&.flag-la': { backgroundPosition: '0 -2620px' },
    '&.flag-lb': { backgroundPosition: '0 -2640px' },
    '&.flag-lc': { backgroundPosition: '0 -2660px' },
    '&.flag-li': { backgroundPosition: '0 -2680px' },
    '&.flag-lk': { backgroundPosition: '0 -2700px' },
    '&.flag-lr': { backgroundPosition: '0 -2720px' },
    '&.flag-ls': { backgroundPosition: '0 -2740px' },
    '&.flag-lt': { backgroundPosition: '0 -2760px' },
    '&.flag-lu': { backgroundPosition: '0 -2780px' },
    '&.flag-lv': { backgroundPosition: '0 -2800px' },
    '&.flag-ly': { backgroundPosition: '0 -2820px' },
    '&.flag-ma': { backgroundPosition: '0 -2840px' },
    '&.flag-mc': { backgroundPosition: '0 -2860px' },
    '&.flag-md': { backgroundPosition: '0 -2880px' },
    '&.flag-me': { backgroundPosition: '0 -2900px' },
    '&.flag-mf': { backgroundPosition: '0 -2920px' },
    '&.flag-mg': { backgroundPosition: '0 -2940px' },
    '&.flag-mh': { backgroundPosition: '0 -2960px' },
    '&.flag-mk': { backgroundPosition: '0 -2980px' },
    '&.flag-ml': { backgroundPosition: '0 -3000px' },
    '&.flag-mm': { backgroundPosition: '0 -3020px' },
    '&.flag-mn': { backgroundPosition: '0 -3040px' },
    '&.flag-mo': { backgroundPosition: '0 -3060px' },
    '&.flag-mp': { backgroundPosition: '0 -3080px' },
    '&.flag-mq': { backgroundPosition: '0 -3100px' },
    '&.flag-mr': { backgroundPosition: '0 -3120px' },
    '&.flag-ms': { backgroundPosition: '0 -3140px' },
    '&.flag-mt': { backgroundPosition: '0 -3160px' },
    '&.flag-mu': { backgroundPosition: '0 -3180px' },
    '&.flag-mv': { backgroundPosition: '0 -3200px' },
    '&.flag-mw': { backgroundPosition: '0 -3220px' },
    '&.flag-mx': { backgroundPosition: '0 -3240px' },
    '&.flag-my': { backgroundPosition: '0 -3260px' },
    '&.flag-mz': { backgroundPosition: '0 -3280px' },
    '&.flag-na': { backgroundPosition: '0 -3300px' },
    '&.flag-nc': { backgroundPosition: '0 -3320px' },
    '&.flag-ne': { backgroundPosition: '0 -3340px' },
    '&.flag-nf': { backgroundPosition: '0 -3360px' },
    '&.flag-ng': { backgroundPosition: '0 -3380px' },
    '&.flag-ni': { backgroundPosition: '0 -3400px' },
    '&.flag-nl': { backgroundPosition: '0 -3420px' },
    '&.flag-no': { backgroundPosition: '0 -3440px' },
    '&.flag-np': { backgroundPosition: '0 -3460px' },
    '&.flag-nr': { backgroundPosition: '0 -3480px' },
    '&.flag-nu': { backgroundPosition: '0 -3500px' },
    '&.flag-nz': { backgroundPosition: '0 -3520px' },
    '&.flag-om': { backgroundPosition: '0 -3540px' },
    '&.flag-pa': { backgroundPosition: '0 -3560px' },
    '&.flag-pe': { backgroundPosition: '0 -3580px' },
    '&.flag-pf': { backgroundPosition: '0 -3600px' },
    '&.flag-pg': { backgroundPosition: '0 -3620px' },
    '&.flag-ph': { backgroundPosition: '0 -3640px' },
    '&.flag-pk': { backgroundPosition: '0 -3660px' },
    '&.flag-pl': { backgroundPosition: '0 -3680px' },
    '&.flag-pm': { backgroundPosition: '0 -3700px' },
    '&.flag-pn': { backgroundPosition: '0 -3720px' },
    '&.flag-pr': { backgroundPosition: '0 -3740px' },
    '&.flag-ps': { backgroundPosition: '0 -3760px' },
    '&.flag-pt': { backgroundPosition: '0 -3780px' },
    '&.flag-pw': { backgroundPosition: '0 -3800px' },
    '&.flag-py': { backgroundPosition: '0 -3820px' },
    '&.flag-qa': { backgroundPosition: '0 -3840px' },
    '&.flag-re': { backgroundPosition: '0 -3860px' },
    '&.flag-ro': { backgroundPosition: '0 -3880px' },
    '&.flag-rs': { backgroundPosition: '0 -3900px' },
    '&.flag-ru': { backgroundPosition: '0 -3920px' },
    '&.flag-rw': { backgroundPosition: '0 -3940px' },
    '&.flag-sa': { backgroundPosition: '0 -3960px' },
    '&.flag-sb': { backgroundPosition: '0 -3980px' },
    '&.flag-sc': { backgroundPosition: '0 -4000px' },
    '&.flag-sd': { backgroundPosition: '0 -4020px' },
    '&.flag-se': { backgroundPosition: '0 -4040px' },
    '&.flag-sg': { backgroundPosition: '0 -4060px' },
    '&.flag-sh': { backgroundPosition: '0 -4080px' },
    '&.flag-si': { backgroundPosition: '0 -4100px' },
    '&.flag-sj': { backgroundPosition: '0 -4120px' },
    '&.flag-sk': { backgroundPosition: '0 -4140px' },
    '&.flag-sl': { backgroundPosition: '0 -4160px' },
    '&.flag-sm': { backgroundPosition: '0 -4180px' },
    '&.flag-sn': { backgroundPosition: '0 -4200px' },
    '&.flag-so': { backgroundPosition: '0 -4220px' },
    '&.flag-sr': { backgroundPosition: '0 -4240px' },
    '&.flag-ss': { backgroundPosition: '0 -4260px' },
    '&.flag-st': { backgroundPosition: '0 -4280px' },
    '&.flag-sv': { backgroundPosition: '0 -4300px' },
    '&.flag-sx': { backgroundPosition: '0 -4320px' },
    '&.flag-sy': { backgroundPosition: '0 -4340px' },
    '&.flag-sz': { backgroundPosition: '0 -4360px' },
    '&.flag-tc': { backgroundPosition: '0 -4380px' },
    '&.flag-td': { backgroundPosition: '0 -4400px' },
    '&.flag-tf': { backgroundPosition: '0 -4420px' },
    '&.flag-tg': { backgroundPosition: '0 -4440px' },
    '&.flag-th': { backgroundPosition: '0 -4460px' },
    '&.flag-tj': { backgroundPosition: '0 -4480px' },
    '&.flag-tk': { backgroundPosition: '0 -4500px' },
    '&.flag-tl': { backgroundPosition: '0 -4520px' },
    '&.flag-tm': { backgroundPosition: '0 -4540px' },
    '&.flag-tn': { backgroundPosition: '0 -4560px' },
    '&.flag-to': { backgroundPosition: '0 -4580px' },
    '&.flag-tr': { backgroundPosition: '0 -4600px' },
    '&.flag-tt': { backgroundPosition: '0 -4620px' },
    '&.flag-tv': { backgroundPosition: '0 -4640px' },
    '&.flag-tw': { backgroundPosition: '0 -4660px' },
    '&.flag-tz': { backgroundPosition: '0 -4680px' },
    '&.flag-ua': { backgroundPosition: '0 -4700px' },
    '&.flag-ug': { backgroundPosition: '0 -4720px' },
    '&.flag-um': { backgroundPosition: '0 -4740px' },
    '&.flag-un': { backgroundPosition: '0 -4760px' },
    '&.flag-us': { backgroundPosition: '0 -4780px' },
    '&.flag-uy': { backgroundPosition: '0 -4800px' },
    '&.flag-uz': { backgroundPosition: '0 -4820px' },
    '&.flag-va': { backgroundPosition: '0 -4840px' },
    '&.flag-vc': { backgroundPosition: '0 -4860px' },
    '&.flag-ve': { backgroundPosition: '0 -4880px' },
    '&.flag-vg': { backgroundPosition: '0 -4900px' },
    '&.flag-vi': { backgroundPosition: '0 -4920px' },
    '&.flag-vn': { backgroundPosition: '0 -4940px' },
    '&.flag-vu': { backgroundPosition: '0 -4960px' },
    '&.flag-wf': { backgroundPosition: '0 -4980px' },
    '&.flag-ws': { backgroundPosition: '0 -5000px' },
    '&.flag-ye': { backgroundPosition: '0 -5020px' },
    '&.flag-yt': { backgroundPosition: '0 -5040px' },
    '&.flag-za': { backgroundPosition: '0 -5060px' },
    '&.flag-zm': { backgroundPosition: '0 -5080px' },
    '&.flag-zw': { backgroundPosition: '0 -5100px' },
  },
};
