import arrayMutators from 'final-form-arrays';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { updateGroupBranding } from '@/actions/group';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { Checkbox } from '@/components/FormAdapters/FormAdapters';
import { presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

import Logo from './Logo';

function Branding({ groupId, group, updateGroupBranding, notify, isViewerAdmin }: any) {
  const { graphql } = useApp();

  const initialValues = useMemo(() => {
    return {
      branding_logo_external_url: group.branding_logo_url,
      branding_show_poweredbyof: group.branding_show_poweredbyof,
    };
  }, [group]);

  const handleSubmit = async (values: any) => {
    try {
      await updateGroupBranding(groupId, values);
      notify('Team branding updated.', 'success');
    } catch (err) {
      console.error(err);
      notify('Error when updating team branding.', 'error');
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ form, values, submitting, handleSubmit }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Logo
              label="Logo"
              src={values.branding_logo_external_url}
              onChange={async (file: any) => {
                const signedUrl = await presignAttachmentURL(graphql.client, file.url);
                form.change('branding_logo_external_url', signedUrl);
                form.change('branding_logo_url', file.url);
              }}
              dimensions={{ height: 20, width: 213 }}
              alternateSvgUpload={isViewerAdmin}
              noStretch
            />

            <Field
              type="checkbox"
              component={Checkbox}
              label="Show Powered by OnFrontiers"
              name="branding_show_poweredbyof"
              FormControlProps={{ margin: 'dense' }}
            />

            <Button type="submit" disabled={submitting} style={{ marginTop: 40 }} size="medium">
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'Branding' because it is a functi... Remove this comment to see the full error message
Branding = connect(
  // @ts-expect-error TS(2339) FIXME: Property 'groupId' does not exist on type '{}'.
  (state: RootState, { groupId }) => {
    const group = state.groups.default.edges.find((e: any) => e.node.id === groupId).node;
    return {
      group,
    };
  },
  {
    updateGroupBranding,
    notify,
  }
)(Branding);

export default Branding;
