import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, PaginatedShortlistProfileList, SavedSearchService } from '@/openapi';

export const DEFAULT_SHORTLIST_LIMIT = 10;

type FetchSavedSearchShortlist = {
  id: string;
  limit?: number;
  page?: number;
};

const fetchSavedSearchShortList = ({
  id,
  limit = DEFAULT_SHORTLIST_LIMIT,
  page = 1,
}: FetchSavedSearchShortlist): CancelablePromise<PaginatedShortlistProfileList> => {
  const offset = (page - 1) * limit;
  return SavedSearchService.savedSearchShortlistList(id, limit, offset);
};

// hardcoding as one page of 100 max for now;
// if this changes, cache invalidation will need to be updated
export const useSavedSearchShortlistQuery = ({ id }: FetchSavedSearchShortlist) => {
  return useQuery({
    queryKey: ['shortlist', { id }],
    queryFn: () => fetchSavedSearchShortList({ id, limit: 100 }),
    enabled: !!id,
  });
};
