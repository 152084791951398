import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import Tooltip from 'rc-tooltip';
import React, { useMemo } from 'react';

import { money } from '@/core/money';

type Transaction = {
  [x: string]: any;
  id: React.Key | null | undefined;
  created_at: moment.MomentInput;
  description:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
  tracking_code:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | null
    | undefined;
  state: any;
};

const useStyles = makeStyles(() => ({
  tooltip: {
    wordBreak: 'break-all',
    maxWidth: 250,
  },
}));

function TransactionList({ transactions = [], amountColumn }: any) {
  const s = useStyles();

  const showTrackingCode = useMemo(
    () =>
      transactions.reduce(
        (previous: Transaction, val: { tracking_code: any }) => previous || val.tracking_code,
        false
      ),
    [transactions]
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell style={{ textTransform: 'none', width: '80px' }}>Date</TableCell>
          <TableCell style={{ textTransform: 'none' }}>Description</TableCell>
          {showTrackingCode && (
            <TableCell style={{ textTransform: 'none', width: '60px' }}>Code</TableCell>
          )}
          <TableCell style={{ textTransform: 'none', width: '60px' }}>State</TableCell>
          <TableCell
            style={{
              textTransform: 'none',
              width: '70px',
              textAlign: 'right',
            }}
          >
            {amountColumn.header}
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((tx: Transaction) => (
          <TableRow key={tx.id}>
            <TableCell style={{ width: '80px' }}>
              {moment(tx.created_at).format('DD MMM YYYY')}
            </TableCell>
            <TableCell>{tx.description}</TableCell>
            {showTrackingCode && (
              <TableCell style={{ width: '60px' }}>
                <Tooltip
                  overlay={<div className={s.tooltip}>{tx.tracking_code}</div>}
                  trigger={['hover', 'click']}
                  placement="left"
                >
                  <div>{tx.tracking_code}</div>
                </Tooltip>
              </TableCell>
            )}
            <TableCell style={{ width: '70px' }}>{formatTransactionState(tx.state)}</TableCell>
            <TableCell style={{ textAlign: 'right', width: '70px' }}>
              {money(tx[amountColumn.field]).formatted()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TransactionList;

function formatTransactionState(status: any) {
  switch (status) {
    case 'on_hold':
      return 'On Hold';
    case 'completed':
      return 'Complete';
    case 'canceled':
      return 'Canceled';
    default:
      return status;
  }
}
