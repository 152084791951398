/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Message } from '../models/Message';
import type { PaginatedSavedCollectionList } from '../models/PaginatedSavedCollectionList';
import type { PaginatedSavedCollectionProfileList } from '../models/PaginatedSavedCollectionProfileList';
import type { PatchedSavedCollectionUpdate } from '../models/PatchedSavedCollectionUpdate';
import type { SavedCollection } from '../models/SavedCollection';
import type { SavedCollectionCreate } from '../models/SavedCollectionCreate';
import type { SavedCollectionProfileRequestCreate } from '../models/SavedCollectionProfileRequestCreate';
import type { SavedCollectionRequestCreate } from '../models/SavedCollectionRequestCreate';
import type { SavedCollectionUpdate } from '../models/SavedCollectionUpdate';

export class SavedCollectionService {
  /**
   * Get the saved collections the user has access to
   * By default, users have access to all public saved collections. If they are a collaborator on a private saved collection, they will also have access to that saved collection.
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param privacy Filtering by collection privacy.
   * @returns PaginatedSavedCollectionList
   * @throws ApiError
   */
  public static savedCollectionList(
    limit: number = 10,
    offset?: number,
    ordering:
      | ''
      | '-created_at'
      | '-name'
      | '-profile_count'
      | '-updated_at'
      | 'created_at'
      | 'name'
      | 'profile_count'
      | 'updated_at' = '',
    privacy: '' | 'PRIVATE' | 'PUBLIC' = ''
  ): CancelablePromise<PaginatedSavedCollectionList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/',
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
        privacy: privacy,
      },
    });
  }

  /**
   * Create a new saved collection
   * Create a new saved collection. The user will be the owner of the saved collection and will be an ACTIVE collaborator.                             It is possible to add other collaborators to the saved collection by sending their ids in the optional collaborators field.
   * @param requestBody
   * @returns SavedCollectionCreate
   * @throws ApiError
   */
  public static savedCollectionCreate(
    requestBody: SavedCollectionRequestCreate
  ): CancelablePromise<SavedCollectionCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_collection/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a saved collection by UUID
   * Retrieve a saved collection by UUID. Users can retrieve PUBLIC saved collections without being a collaborator
   * @param id A UUID string identifying this saved collection.
   * @returns SavedCollection
   * @throws ApiError
   */
  public static savedCollectionRetrieve(id: string): CancelablePromise<SavedCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a saved collection by UUID
   * Completely update a saved collection by UUID. Only collection collaborators can update the saved collection.
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns SavedCollectionUpdate
   * @throws ApiError
   */
  public static savedCollectionUpdate(
    id: string,
    requestBody: SavedCollectionUpdate
  ): CancelablePromise<SavedCollectionUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/saved_collection/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a saved collection by UUID
   * Update specific fields within a saved collection by UUID. Only collection collaborators can update the saved collection.
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns SavedCollectionUpdate
   * @throws ApiError
   */
  public static savedCollectionPartialUpdate(
    id: string,
    requestBody?: PatchedSavedCollectionUpdate
  ): CancelablePromise<SavedCollectionUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/saved_collection/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a saved collection by UUID
   * Delete a saved collection by UUID. Only collection collaborators can delete the saved collection.
   * @param id A UUID string identifying this saved collection.
   * @returns void
   * @throws ApiError
   */
  public static savedCollectionDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_collection/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Add a profile to a saved collection
   * Add a profile to a saved collection. Only collection collaborators can add a profile to the saved collection
   * @param id A UUID string identifying this saved collection.
   * @param requestBody
   * @returns Message
   * @throws ApiError
   */
  public static savedCollectionProfileCreate(
    id: string,
    requestBody: SavedCollectionProfileRequestCreate
  ): CancelablePromise<Message> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/saved_collection/{id}/profile/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a profile from a saved collection
   * Delete a profile from a saved collection.                 Only collection collaborators can delete a profile from the saved collection.
   * @param id A UUID string identifying this saved collection.
   * @param profileId The id of the profile
   * @returns void
   * @throws ApiError
   */
  public static savedCollectionProfileDeleteDestroy(
    id: string,
    profileId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/saved_collection/{id}/profile_delete/{profile_id}/',
      path: {
        id: id,
        profile_id: profileId,
      },
    });
  }

  /**
   * Get the list of profiles in a saved collection
   * Retrieving the list of profiles in a saved collection.                 Logged users can retrieve the list of profiles for public collections                Only collection collaborators can retrieve the list of profiles.                 The list of profiles can be sorted by creation date or by profile name.
   * @param id A UUID string identifying this saved collection.
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @returns PaginatedSavedCollectionProfileList
   * @throws ApiError
   */
  public static savedCollectionProfilesList(
    id: string,
    limit: number = 10,
    offset?: number,
    ordering: '' | '-created_at' | '-profile__full_name' | 'created_at' | 'profile__full_name' = ''
  ): CancelablePromise<PaginatedSavedCollectionProfileList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/{id}/profiles/',
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        ordering: ordering,
      },
    });
  }

  /**
   * Get the list of collections for the logged user
   * Retrieving the list of public and private collections where the                 logged user is a collaborator. The list of collections can be sorted by creation date,                 by name or by number of profiles and filtered by privacy
   * @param collaboratorCanJoin Given a profile id, filter the list of collections that the profile can be added to as a collaborator
   * @param collaboratorCannotJoin Given a profile id, filter the list of collections that the profile is already a collaborator of, so they cannot be added to the collection
   * @param limit The limit for the paginated response.
   * @param offset The number of results to skip from 0.
   * @param ordering The field to sort the results by, add a - in front of the field to sort in descending order.
   * @param privacy Filtering by collection privacy.
   * @param profileCanJoin Given a profile id, filter the list of collections that the profile can be added to
   * @param profileCannotJoin Given a profile id, filter the list of collections that the profile is already a member of, so they cannot be added to the collection
   * @returns PaginatedSavedCollectionList
   * @throws ApiError
   */
  public static savedCollectionMyCollectionsList(
    collaboratorCanJoin: string = '',
    collaboratorCannotJoin: string = '',
    limit: number = 10,
    offset?: number,
    ordering:
      | ''
      | '-created_at'
      | '-name'
      | '-profile_count'
      | '-updated_at'
      | 'created_at'
      | 'name'
      | 'profile_count'
      | 'updated_at' = '',
    privacy: '' | 'PRIVATE' | 'PUBLIC' = '',
    profileCanJoin: string = '',
    profileCannotJoin: string = ''
  ): CancelablePromise<PaginatedSavedCollectionList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/saved_collection/my_collections/',
      query: {
        collaborator_can_join: collaboratorCanJoin,
        collaborator_cannot_join: collaboratorCannotJoin,
        limit: limit,
        offset: offset,
        ordering: ordering,
        privacy: privacy,
        profile_can_join: profileCanJoin,
        profile_cannot_join: profileCannotJoin,
      },
    });
  }
}
