import React from 'react';

import Alert from './Alert';

function OptionalAlert({ message, ...other }: any) {
  if (!message) return null;
  return <Alert {...other}>{message}</Alert>;
}

export default OptionalAlert;
