import InputLabel from '@mui/material/InputLabel';
import withStyles from '@mui/styles/withStyles';

import { borderColor, white } from '@/theme/colors';

const styles = {
  root: {
    position: 'relative',
    marginTop: '8px',
  },
  contentWrapper: {
    position: 'relative',
  },
  content: {
    borderRadius: '4px',
    padding: '18.5px 14px',
    marginTop: '15px',
    borderColor,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  inputLabel: {
    position: 'absolute',
    left: 0,
    top: 0,
    background: white,
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  notchedOutline: {},
};

const LabelledOutline = ({ classes: s, style, id, label, children }: any) => (
  <div className={s.root} style={style}>
    <InputLabel htmlFor={id} variant="outlined" className={s.inputLabel} shrink>
      {label}
    </InputLabel>
    <div className={s.contentWrapper}>
      <div id={id} className={s.content}>
        {children}
      </div>
    </div>
  </div>
);
// @ts-expect-error TS(2345): Argument of type '{ root: { position: string; marg... Remove this comment to see the full error message
export default withStyles(styles)(LabelledOutline);
