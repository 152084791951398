import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { Checkbox } from '@/components/FormAdapters';
import { updateUser } from '@/store/user';

class AvailableSelfServiceForm extends PureComponent {
  handleSubmit = async (values: any) => {
    // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { userId, updateUser, onSubmit } = this.props;
    await updateUser({ id: userId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
    const { component: Container = Form, handleSubmit, ...other } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          type="checkbox"
          component={Checkbox}
          name="available_self_service"
          label="Available for self service consultations"
        />
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const user = state.users[ownProps.userId] || {};
    return {
      initialValues: {
        available_self_service: user.available_self_service,
      },
    };
  },
  {
    updateUser,
  }
)(
  reduxForm({
    form: 'expertAvailableSelfService',
    // @ts-expect-error TS(2345): Argument of type 'typeof AvailableSelfServiceForm'... Remove this comment to see the full error message
  })(AvailableSelfServiceForm)
);
