import { dialogTitleClasses } from '@mui/material/DialogTitle';

import theme from '@/theme';

const ns = 'send-email';

export const sendEmailClasses = {
  root: `${ns}__root`,
  header: `${ns}__header`,
  dialogTitle: `${ns}-dialog-title`,
  dialogTitleText: `${ns}-dialog-title-text`,
  content: `${ns}__content`,
  saveBtn: `${ns}__save-btn`,
  footer: `${ns}__footer`,
  footerActions: `${ns}__footer-actions`,
  footerText: `${ns}__footer-text`,
};

const { dialogTitleText, content, footer, header } = sendEmailClasses;

const styles = {
  sendEmail: {
    // Header
    [`& .${header}`]: {
      overflow: 'hidden',
      position: 'sticky',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      top: 0,

      [`&.${dialogTitleClasses.root}`]: {
        display: 'flex',
        padding: '18px 24px',
      },
      [`& .${dialogTitleText}`]: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 20,
      },
    },
    // Content
    [`& .${content}`]: {
      padding: '0px 24px',
      paddingTop: 0,
    },
    // Footer
    [`& .${footer}`]: {
      padding: '24px',
    },
  },
};

export default styles;
