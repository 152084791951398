import { tooltipClasses as muiTooltipClasses } from '@mui/material/Tooltip';

import theme from '@/theme';

const ns = 'tooltip';

export const tooltipClasses = {
  dark: `${ns}__dark`,
  'text-align-center': `${ns}__text-align-center`,
  'text-align-left': `${ns}__text-align-left`,
  'text-align-right': `${ns}__text-align-right`,
};

const {
  dark,
  'text-align-center': textAlignCenter,
  'text-align-left': textAlignLeft,
  'text-align-right': textAlignRight,
} = tooltipClasses;

const styles = () => ({
  [`&.${muiTooltipClasses.popper}`]: {
    [`& .${muiTooltipClasses.tooltip}`]: {
      minWidth: 'unset',
    },
  },
  [`&.${dark} .${muiTooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 400,
    padding: 13,
    [`& .${muiTooltipClasses.arrow}`]: {
      color: theme.palette.grey[900],
    },
  },
  [`&.${textAlignCenter} .${muiTooltipClasses.tooltip}`]: {
    textAlign: 'center',
  },
  [`&.${textAlignLeft} .${muiTooltipClasses.tooltip}`]: {
    textAlign: 'left',
  },
  [`&.${textAlignRight} .${muiTooltipClasses.tooltip}`]: {
    textAlign: 'right',
  },
  [`& .${muiTooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[700],
    border: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${muiTooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`.${muiTooltipClasses.tooltip}.${muiTooltipClasses.tooltipPlacementBottom}`]: {
    margin: '-5px 0',
  },
});

export default styles;
