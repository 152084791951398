import {
  CollaboratorStatusEnum,
  PrivacyEnum,
  SavedCollectionCollaboratorsService,
  SavedCollectionService,
} from '@/openapi';

export default class CollectionService {
  /**
   * Delete one collaborator from a specified collection
   *
   * @param collectionId
   * @param collaboratorId
   * @param wait if true (default: false), it will await the execution
   * @param after if wait is false, it will be excecuted using passing the deleted ID as argument .then
   * @returns Promise / return from delete / return from after
   */
  public static async deleteCollaborator(
    collectionId: string,
    collaboratorId: string,
    wait = false,
    after?: (id: string) => unknown
  ) {
    const request = SavedCollectionCollaboratorsService.savedCollectionDeleteCollaboratorDestroy(
      collaboratorId,
      collectionId
    );

    if (wait) {
      const response = await request;
      return after?.(collaboratorId) ?? response;
    }

    return request.then(() => after?.(collaboratorId));
  }

  /**
   * Delete many collaborators from a specified collection
   *
   * @param collectionId
   * @param collaboratorId
   * @param wait if true (default: false), it will await the execution
   * @param afterEach if wait is false, it will be excecuted using passing the deleted ID as argument .then
   */
  public static async deleteCollaborators(
    collectionId: string,
    collaboratorId: string[],
    wait = false,
    afterEach?: (id: string) => unknown
  ) {
    for (let c = 0; c < collaboratorId.length; c++) {
      const request = this.deleteCollaborator(collectionId, collaboratorId[c], wait, afterEach);
      wait && (await request);
    }
  }

  public static async deleteCollection(id: string) {
    return SavedCollectionService.savedCollectionDestroy(id);
  }

  public static async createCollection(
    name: string,
    description: string,
    privacy: PrivacyEnum,
    collaborators: string[]
  ) {
    return SavedCollectionService.savedCollectionCreate({
      name,
      description,
      privacy,
      collaborators,
    });
  }

  public static async updateCollection(
    collectionId: string,
    name?: string,
    description?: string,
    privacy?: PrivacyEnum,
    deleted?: boolean | undefined
  ) {
    return SavedCollectionService.savedCollectionPartialUpdate(collectionId, {
      name,
      description,
      privacy,
      deleted,
    });
  }

  public static async addCollaborator(collectionId: string, profile: string, active = true) {
    return SavedCollectionCollaboratorsService.savedCollectionCreateCollectionCollaboratorCreate(
      collectionId,
      {
        profile: [profile],
        collaborator_status: active
          ? CollaboratorStatusEnum.ACTIVE
          : CollaboratorStatusEnum.PENDING,
      }
    );
  }
}
