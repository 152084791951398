import IconButton from '@mui/material/IconButton';
import React, { useCallback, useState } from 'react';

import MaterialIcon from '../Icon/MaterialIcon';
import Menu from '../Menu';

function IconMenu({
  children,
  buttonStyle,
  iconElement,
  iconSize,
  placement = 'bottom-end',
  iconStyle,
  ...rest
}: any) {
  const [anchorEl, setAnchorEl] = useState();

  const handleOpen = useCallback((event: any) => setAnchorEl(event.currentTarget), []);

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <IconButton style={{ padding: 0, ...buttonStyle }} onClick={handleOpen}>
        {iconElement || (
          <MaterialIcon style={iconStyle} size={iconSize || 36} icon="more_horiz" color="silver" />
        )}
      </IconButton>
      <Menu {...rest} anchorEl={anchorEl} placement={placement} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
}

export default IconMenu;
