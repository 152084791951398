/// <reference types="@types/segment-analytics" />
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

export default function TrackPathname() {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  const fullPathname = useMemo(() => {
    let path = location.pathname;

    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    return path + location.search;
  }, [location.pathname, location.search]);

  const allParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(urlParams);

    return { ...params, ...queryParams };
  }, [params, location.search]);

  const basePath = useMemo(() => {
    const replacedPath = Object.entries(allParams).reduce(
      (path, [key, value]) =>
        path.replace(`/${value}`, `/:${key}`).replace(`${key}=${value}`, `${key}=:${key}`),
      fullPathname || ''
    );

    return replacedPath;
  }, [fullPathname, allParams]);

  useEffect(() => {
    const { analytics } = window;
    if (!analytics || !analytics.page) return;

    analytics.page(`Visited ${basePath}`, {
      path: fullPathname,
      url: window.location.href,
      title: document.title,
      ...allParams,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, fullPathname]);

  return <Outlet />;
}
