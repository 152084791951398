import { white } from '@/theme/colors';

import s from './Wallpaper.module.scss';

function Wallpaper({ color, tint, videoSrc, imgSrc, vignette, imgPosition }: any) {
  const style = {};
  if (imgSrc) {
    // @ts-expect-error TS(2339): Property 'backgroundImage' does not exist on type ... Remove this comment to see the full error message
    style.backgroundImage = `url('${imgSrc}')`;
    if (tint) {
      // @ts-expect-error TS(2339): Property 'backgroundImage' does not exist on type ... Remove this comment to see the full error message
      style.backgroundImage = `linear-gradient(${tint}, ${tint}), url('${imgSrc}')`;
      // @ts-expect-error TS(2339): Property 'backgroundBlendMode' does not exist on t... Remove this comment to see the full error message
      style.backgroundBlendMode = 'multiply, luminosity';
      // @ts-expect-error TS(2339): Property 'backgroundColor' does not exist on type ... Remove this comment to see the full error message
      style.backgroundColor = white;
    }
    // @ts-expect-error TS(2339): Property 'backgroundPosition' does not exist on ty... Remove this comment to see the full error message
    if (imgPosition) style.backgroundPosition = imgPosition;
  }

  return (
    <div>
      {color && <div className={s.color} style={{ backgroundColor: color }} />}
      {videoSrc && (
        <div className={s.video}>
          <video loop muted autoPlay className={s.videoPlayer}>
            <source src={`${videoSrc}.webm`} type="video/webm" />
            <source src={`${videoSrc}.mp4`} type="video/mp4" />
            <source src={`${videoSrc}.ogv`} type="video/ogg" />
          </video>
          {vignette && <div className={s.vignette} />}
        </div>
      )}
      {imgSrc && (
        <div className={s.image} style={style}>
          {vignette && <div className={s.vignette} />}
        </div>
      )}
    </div>
  );
}

export default Wallpaper;
