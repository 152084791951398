import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

import { Job, ProfileOnboardingService, ProfileOnboardingTask } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const TASKS = {
  CONFIRM_PERSONAL_INFORMATION: 'confirm-personal-information' as const,
  CONFIRM_CURRENT_CONTRACTS: 'confirm-current-contracts' as const,
  REVIEW_ALL_CONTRACTS: 'review-all-contracts' as const,
  ADD_PROFESSIONAL_HISTORY: 'add-professional-history' as const,
  REVIEW_ALL_PROFESSIONAL_HISTORY: 'review-all-professional-history' as const,
  ADD_AVAILABILITY: 'add-availability' as const,
  ADD_LANGUAGE: 'add-language' as const,
  // USER_MISSION_STATEMENT: 'user-mission-statement' as const,
  // SEARCH_AND_TRAVEL_PREFERENCES: 'search-and-travel-preferences' as const,
};

export const PROGRESS = {
  STARTED: 40,
  COMPLETED: 100,
};

type ValueOf<T> = T[keyof T];

export type TASK_SLUG = ValueOf<typeof TASKS>;

// no real use case YET for this
export const fetchData = createAsyncThunk(
  'onboarding/fetchData',
  async () => await ProfileOnboardingService.profileOnboardingList()
);

export const startTask = createAsyncThunk(
  'onboarding/startTask',
  async ({ slug }: { slug: TASK_SLUG }, { dispatch }) => {
    dispatch(saveProgress({ slug, progress: PROGRESS.STARTED }));
  }
);

export const completeTask = createAsyncThunk(
  'onboarding/completeTask',
  async ({ slug }: { slug: TASK_SLUG }, { dispatch }) => {
    dispatch(saveProgress({ slug, progress: PROGRESS.COMPLETED }));
  }
);

export const saveProgress = createAsyncThunk(
  'onboarding/saveProgress',
  async (
    {
      slug,
      progress,
      allowDowngrade,
    }: { slug: TASK_SLUG; progress: number; allowDowngrade?: boolean },
    { getState }
  ) => {
    const state = getState() as IState;

    const existingTask = state.onboarding.tasks.find((t) => t.slug === slug);
    if (
      !allowDowngrade &&
      existingTask?.progress &&
      Number.parseFloat(existingTask.progress) > progress
    ) {
      // payload to update progress is lower than actual progress, skip it
      return Promise.resolve(existingTask);
    }

    return await ProfileOnboardingService.profileOnboardingUpdateProgressPartialUpdate(slug, {
      progress,
      allow_downgrade: allowDowngrade,
    });
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState.onboarding,
  reducers: {
    updateTasks: (state, action: PayloadAction<Array<ProfileOnboardingTask>>) => ({
      ...state,
      tasks: action.payload,
    }),
    resetData: () => initialState.onboarding,
    setJob: (state, action: PayloadAction<Job>) => ({
      ...state,
      job: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(saveProgress.fulfilled, (state, action) =>
      produce(state, (draft) => {
        const tasks = draft?.tasks ?? [];

        if (!action.payload) return;

        const slug =
          'onboarding' in action.payload ? action.payload.onboarding : action.payload.slug;

        const index = tasks.findIndex((t) => t.slug === slug);

        if (draft?.tasks[index]) {
          draft.tasks[index] = {
            ...draft.tasks[index],
            progress: String(action.payload.progress),
          };
        }
      })
    );
  },
});

export const onboardingSelector = (state: IState) => state.onboarding;
export const { updateTasks, resetData, setJob } = onboardingSlice.actions;
export default onboardingSlice.reducer;
