import clsx from 'clsx';
import { useState } from 'react';
import { Trash2 } from 'react-feather';

import Avatar from '@/auth/components/Avatar';
import { Pagination } from '@/componentsv2';
import Button from '@/componentsv2/Button';
import { useProfileId } from '@/hooks/store';
import { useRemoveSearchCollaborator } from '@/searchv2/mutations/searchCollaborators';
import {
  DEFAULT_COLLABORATORS_LIMIT,
  useSearchCollaboratorsQuery,
} from '@/searchv2/queries/searchCollaborators';

export const CollaboratorItem = ({
  className,
  fullName,
  pictureUrl,
  email,
  muiProps = {},
  handleRemove,
  isRemoving,
}: {
  className?: string;
  fullName: string;
  pictureUrl?: string;
  email?: string;
  handleRemove?: () => void;
  isRemoving?: boolean;
  muiProps?: React.HTMLAttributes<HTMLLIElement>;
}) => {
  return (
    <li
      {...muiProps}
      className={clsx(className, muiProps.className, '-mt-1 flex gap-16 py-10', {
        'items-center': !email,
        'opacity-70': isRemoving,
      })}
    >
      <Avatar fullName={fullName} src={pictureUrl} sx={{ width: 32, height: 32 }} />
      <div>
        <p className="subtitle-1">{fullName}</p>
        {email ? <p className="text-grey-500 body-1">{email}</p> : null}
      </div>
      {handleRemove ? (
        <div className="ml-auto shrink-0">
          <Button
            variant="tertiary"
            srText="delete"
            onClick={handleRemove}
            disabled={isRemoving}
            startIcon={Trash2}
          />
        </div>
      ) : null}
    </li>
  );
};

const CollaboratorsList = ({
  className,
  savedSearchId,
  fallbackMessage,
  disableRemove,
}: {
  className?: string;
  savedSearchId: string;
  fallbackMessage?: string;
  disableRemove?: boolean;
}) => {
  const profileId = useProfileId();
  const [page, setPage] = useState(1);
  const {
    mutate: removeSearchCollaborator,
    status: removedStatus,
    variables: removedVariables,
  } = useRemoveSearchCollaborator();
  const { data, isLoading, isPreviousData, isFetched } = useSearchCollaboratorsQuery({
    id: savedSearchId ?? '',
    page,
  });

  // Filter out the current user from the list of collaborators
  const filteredCollaborators =
    data?.results?.filter((collaborator) => collaborator.profile_id !== profileId) ?? [];
  const filteredCount = data?.count ? data.count - 1 : 0;

  const showFallbackMessage = isFetched && filteredCount === 0 && fallbackMessage;

  return (
    <>
      <ul className={clsx(className, { 'opacity-50': isPreviousData })}>
        {filteredCollaborators.map((collaborator) => {
          const isRemoving =
            (removedStatus === 'loading' || removedStatus === 'success') &&
            removedVariables?.profileId === collaborator.profile_id;
          return (
            <CollaboratorItem
              isRemoving={isRemoving}
              key={collaborator.profile_id}
              fullName={collaborator.full_name}
              pictureUrl={collaborator.picture_url}
              handleRemove={
                !disableRemove
                  ? () => {
                      removeSearchCollaborator({
                        id: savedSearchId ?? '',
                        profileId: collaborator.profile_id,
                      });
                    }
                  : undefined
              }
            />
          );
        })}
      </ul>
      {filteredCount > DEFAULT_COLLABORATORS_LIMIT ? (
        <div className="mt-auto">
          <Pagination
            disabled={isLoading}
            page={page}
            count={Math.ceil(filteredCount / DEFAULT_COLLABORATORS_LIMIT)}
            handlePageChange={(_, page) => setPage(page)}
          />
        </div>
      ) : null}
      {showFallbackMessage ? (
        <p className="text-center italic text-grey-500 body-2">{fallbackMessage}</p>
      ) : null}
    </>
  );
};

export default CollaboratorsList;
