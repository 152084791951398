import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import EditDialog from '@/components/EditDialog';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';
import { black } from '@/theme/colors';

const useStyles = makeStyles(() => ({
  confirmPolishTitle: {
    color: black,
    fontSize: 20,
    marginBottom: 11,
    fontWeight: 'bold',
  },
}));

interface PolishRequestFormProps {
  onSubmit: (data: any) => void;
  onClose: (data: any) => void;
  open: boolean;
  [key: string]: any;
}

function PolishRequestForm({
  onSubmit,
  handleSubmit,
  reset,
  fieldValue,
  ...other
}: InjectedFormProps<object, PolishRequestFormProps> & PolishRequestFormProps) {
  const s = useStyles();
  const handleFormSubmit = (data: any) => {
    onSubmit(data);
  };

  return (
    <EditDialog
      // saveLabel="Send"
      {...other}
      onSubmit={handleSubmit(handleFormSubmit)}
      // onClose={() => setOpen(false)}
      onReset={reset}
      // bogus attribute (force dialog position update on text change)
      // fieldValue={fieldValue}
    >
      <div className={s.confirmPolishTitle}>Send this Expert profile out for improvement</div>
      <div>
        A data operator will receive a link to the Expert’s profile and this request so they can
        tailor it to this request.
      </div>
      <Field
        type="checkbox"
        component={Checkbox}
        name="priority"
        label="Priority"
        style={{ margin: '15px 0' }}
      />
      <Field
        id="note"
        component={TextField}
        fullWidth
        multiline
        name="note"
        placeholder="Add note"
        maxRows={15}
      />
    </EditDialog>
  );
}

const WrappedPolishRequestForm = reduxForm<object, PolishRequestFormProps>({
  form: 'candidatePolishRequest',
  enableReinitialize: true,
})(PolishRequestForm);

export default connect((state) => ({
  // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  fieldValue: formValueSelector('candidatePolishRequest')(state, 'note'),
}))(WrappedPolishRequestForm);
