import React, { PureComponent } from 'react';

import { darkGray, sand } from '@/theme/colors';

import Button from '../Button';
import FAIcon from '../Icon/FAIcon';

const styles = {
  style: {
    minWidth: 0,
    color: darkGray,
    height: 35,
    width: 35,
    minHeight: 'initial',
    padding: 0,
  },
  iconStyle: {
    marginLeft: 10,
    fontSize: 25,
  },
};

interface SocialButtonProps {
  startIcon?: React.ReactNode | string;
  [key: string]: any; // To allow other props to be passed
}

class SocialButton extends PureComponent<SocialButtonProps> {
  render() {
    const startIconElement =
      typeof this.props.startIcon === 'string' ? (
        <FAIcon style={styles.iconStyle} icon={this.props.startIcon} />
      ) : (
        this.props.startIcon
      );
    return (
      <Button
        backgroundColor={sand}
        color="primary"
        {...styles}
        {...this.props}
        startIcon={startIconElement}
      />
    );
  }
}

export default SocialButton;
