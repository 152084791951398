import { FC, useCallback, useState } from 'react';

import { TimeRange, formatSeconds } from '@/core/time';

import s from './Timeline.module.scss';
import TimelineCall from './TimelineCall';
import TimelineEvent from './TimelineEvent';
import TimelineNetworkEvent from './TimelineNetworkEvent';
import TimelinePopover from './TimelinePopover';
import Participant from './participant';

interface TimelineProps {
  carrier: string;
  range: TimeRange;
  participants: Participant[];
}

const Timeline: FC<TimelineProps> = ({ carrier, range, participants }) => {
  const [state, setState] = useState({
    cursorPosition: -1,
    cursorSecond: 0,
    callAnchorElement: null,
    selectedParticipant: undefined,
  });

  const hideCursor = useCallback(() => {
    setState((prev) => ({
      ...prev,
      cursorPosition: -1,
      cursorSecond: 0,
    }));
  }, []);

  const updateCursor = useCallback(
    (event: any) => {
      const conferenceDuration = range.duration().seconds();
      const timelineWidth = event.currentTarget.clientWidth;
      const cursorPosition = event.clientX - event.currentTarget.offsetLeft;
      const pxPerSecond = timelineWidth / conferenceDuration;
      const cursorSecond = cursorPosition / pxPerSecond;

      if (cursorPosition > timelineWidth) {
        return hideCursor();
      }

      setState((prev) => ({
        ...prev,
        cursorPosition,
        cursorSecond,
      }));
    },
    [hideCursor, range]
  );

  const handleParticipantPopover = useCallback((e: any, p: any) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      selectedParticipant: p,
      callAnchorElement: e.currentTarget,
    }));
  }, []);

  const handleCallClose = useCallback(() => {
    setState((prev) => ({
      ...prev,
      selectedParticipant: undefined,
      callAnchorElement: null,
    }));
  }, []);

  const { cursorPosition, cursorSecond, selectedParticipant, callAnchorElement } = state;

  const conferenceDuration = range.duration().seconds();

  return (
    <div onMouseMove={updateCursor} onMouseLeave={hideCursor}>
      <div className={s.lines}>
        <div className={s.conference} />

        {participants.map((p) => {
          const events = p.events();

          const timelineNetworkEvents = events
            ? events
                .filter(
                  (e: any) => e.level === 'WARNING' && e.group === 'network-quality-warning-raised'
                )
                .map((e: any) => (
                  <TimelineNetworkEvent
                    key={`network-${p.callId()}-${e.timestamp}-${e.group}-${e.name}`}
                    event={e}
                    participant={p}
                    range={range}
                    conferenceDuration={conferenceDuration}
                    onClick={(evt: any) => handleParticipantPopover(evt, p)}
                  />
                ))
            : [];

          const timelineEvents = events
            ? events
                .filter(
                  (e: any) =>
                    e.level === 'ERROR' ||
                    e.level === 'WARNING' ||
                    e.name === 'muted' ||
                    e.name === 'unmuted'
                )
                .map((e: any) => (
                  <TimelineEvent
                    key={`event-${p.callId()}-${e.timestamp}-${e.group}-${e.name}`}
                    event={e}
                    callId={p.callId()}
                    range={range}
                    conferenceDuration={conferenceDuration}
                  />
                ))
            : [];

          return [
            <TimelineCall
              key={p.id}
              participant={p}
              range={range}
              conferenceDuration={conferenceDuration}
              onClick={p.url() ? (evt: any) => handleParticipantPopover(evt, p) : undefined}
            />,
            ...timelineEvents,
            ...timelineNetworkEvents,
          ];
        })}

        {cursorPosition > -1 && (
          <div className={s.cursor} style={{ left: cursorPosition }}>
            {formatSeconds(cursorSecond || 0)}
          </div>
        )}
      </div>

      {selectedParticipant && (
        <TimelinePopover
          open={!!callAnchorElement}
          carrier={carrier}
          anchorEl={callAnchorElement}
          participant={selectedParticipant}
          range={range}
          onClose={handleCallClose}
        />
      )}
    </div>
  );
};

export default Timeline;
