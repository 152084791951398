import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import s from './FocusAreas.module.scss';

const allFocusAreas = [
  'Competitive Landscape',
  'General Understanding',
  'Industry Trends',
  'Investment Decision',
  'Market Sizing',
  'Market Entry',
  'Partnership Identification',
  'Talent Sourcing',
];

class SelectFocusAreas extends React.Component {
  toggle = (e: any, focusArea: any) => {
    // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { fields } = this.props;
    if (e.target.checked) {
      fields.push(focusArea);
    } else {
      const values = fields.value || [];
      const index = values.findIndex((v: any) => v === focusArea);
      fields.remove(index);
    }
  };

  renderCheckbox = (focusArea: any) => {
    // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { fields, disabled } = this.props;
    const values = fields.value || [];

    const checked = values.includes(focusArea);
    return (
      <FormControlLabel
        key={focusArea}
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(e) => this.toggle(e, focusArea)}
            value={focusArea}
          />
        }
        label={focusArea}
      />
    );
  };

  render() {
    return (
      <div className={s.focusAreas}>
        <div className={s.column}>{allFocusAreas.slice(0, 4).map(this.renderCheckbox)}</div>
        <div className={s.column}>{allFocusAreas.slice(4, 8).map(this.renderCheckbox)}</div>
      </div>
    );
  }
}

function FocusAreas({ disabled }: any) {
  return (
    <FieldArray
      // @ts-expect-error TS(2322): Type 'typeof SelectFocusAreas' is not assignable t... Remove this comment to see the full error message
      component={SelectFocusAreas}
      disabled={disabled}
      name="focus_areas"
    />
  );
}

export default FocusAreas;
