import { Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material';
import { PickerFileMetadata } from 'filestack-js';
import Tooltip from 'rc-tooltip';
import { ChangeEventHandler, FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import Button from '@/components/Button';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import SvgIcon from '@/components/Icon/SvgIcon';
import { openFileDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import { black, darkGreen } from '@/theme/colors';

import s from './MessageInput.module.scss';

function isMac() {
  return navigator && navigator.platform.indexOf('Mac') > -1;
}

interface MessageInputProps {
  isFocused?: boolean;
  disabled?: boolean;
  text?: string;
  isMobileVersion?: boolean;
  isAnonymous?: boolean;
  canSendAnonymously?: boolean;
  initialSendAnonymously?: boolean;
  onSend?: (text: string, anonymous: boolean) => void | Promise<void>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onAttachFile?: (file: PickerFileMetadata) => void | Promise<void>;
}

const MessageInput: FC<MessageInputProps> = memo(
  ({
    isFocused = false,
    disabled = false,
    isMobileVersion = false,
    text = '',
    isAnonymous = false,
    canSendAnonymously = true,
    initialSendAnonymously = false,
    onSend,
    onChange,
    onAttachFile,
  }) => {
    const { graphql } = useApp();

    const [sendAnonymously, setSendAnonymously] = useState(
      initialSendAnonymously && canSendAnonymously
    );

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      const current = inputRef.current;
      if (isFocused && current) {
        current.focus();
      }
    }, [isFocused]);

    const handleAttachFile = useCallback(async () => {
      if (!onAttachFile) return;
      const files = await openFileDialog(graphql.client, {
        fromSources: [
          'local_file_system',
          'url',
          'googledrive',
          'dropbox',
          'box',
          'onedrive',
          'onedriveforbusiness',
        ],
        accept: [
          'image/*',
          '.txt',
          '.rtf',
          '.pdf',
          '.csv',
          '.tsv',
          '.xls',
          '.xlsx',
          '.doc',
          '.docx',
          '.ppt',
          '.pptx',
        ],
      });
      onAttachFile(files[0]);
    }, [graphql.client, onAttachFile]);

    const handleSend = useCallback(() => {
      const cleanedText = text.trim();
      if (cleanedText && onSend) onSend(cleanedText, sendAnonymously);
    }, [onSend, sendAnonymously, text]);

    const handleKeyPress = useCallback(
      (event: any) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSend();
        }
      },
      [handleSend]
    );

    const renderAnonymousOption = () => {
      if (isAnonymous) {
        return (
          <div className={s.anonymousMode}>
            <SvgIcon
              icon="incognito"
              width={18}
              height={18}
              fill={black}
              style={{ marginRight: 3 }}
            />{' '}
            Anonymous mode
          </div>
        );
      }

      if (!canSendAnonymously) return;

      return (
        <div>
          <Tooltip
            overlay="A new confidential channel will be used"
            trigger={['hover']}
            overlayStyle={{ zIndex: 2000 }}
            placement="top"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendAnonymously}
                  onChange={() => setSendAnonymously(!sendAnonymously)}
                />
              }
              label="Send anonymously"
              style={{ minWidth: 215, marginBottom: 10 }}
            />
          </Tooltip>
        </div>
      );
    };

    const sendDisabled = disabled || !text.trim();
    const isDesktopVersion = !isMobileVersion;

    return (
      <div className={s.messageInput}>
        <div className={s.messageInputText}>
          <TextField
            id="writeYourMessage"
            fullWidth
            placeholder="Write your message"
            multiline
            minRows={1}
            maxRows={6}
            value={text}
            disabled={disabled}
            onChange={onChange}
            InputProps={{ disableUnderline: true }}
            onKeyDown={handleKeyPress}
            inputRef={inputRef}
          />
        </div>

        <div className={s.actions}>
          {onAttachFile && (
            <IconButton
              style={isDesktopVersion ? { padding: 0, width: 35, height: 35 } : {}}
              onClick={handleAttachFile}
            >
              <MaterialIcon icon="attach_file" color={darkGreen} />
            </IconButton>
          )}
          <div className={s.sendAction}>
            {renderAnonymousOption()}
            {isDesktopVersion && (
              <span className={s.sendHelpText}>
                {isMac() ? 'Press CMD+Enter' : 'Press CTRL+Enter'}
              </span>
            )}
            {isMobileVersion ? (
              <IconButton disabled={sendDisabled} onClick={handleSend}>
                <MaterialIcon icon="send" color={darkGreen} />
              </IconButton>
            ) : (
              <Button
                disabled={sendDisabled}
                size="small"
                label="Send"
                onClick={handleSend}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
MessageInput.displayName = 'MessageInput';

export default MessageInput;
