import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TextField } from '../FormAdapters/FormAdapters';

// Hide spinner
const useStyles = makeStyles(() => ({
  input: {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export default function Currency({
  input = {},
  meta,
  InputProps = {},
  adornment = '$',
  position = 'start',
  maxlength = 6,
  ...other
}: any) {
  const s = useStyles();

  const inputRef = React.createRef();
  const [value, setValue] = useState(input.value);
  const [formatted, setFormatted] = useState('');

  useEffect(() => {
    if (value === '' || value === undefined) {
      setFormatted('');
      return;
    }

    const formatted = value
      .toString()
      .padStart(3, '0')
      .replace(/(\d+)(\d\d$)/, '$1.$2');

    setFormatted(formatted);
  }, [value]);

  const inputAdornment = useMemo(
    () => <InputAdornment position={position}>{adornment}</InputAdornment>,
    [position, adornment]
  );

  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const handleChange = useCallback((e: any, value: any) => {
    // Value will be present when using redux, otherwise get event value
    value = value ?? e.target?.value;

    value = String(value).replace(/\D+/g, '').substring(0, maxlength);

    // Strip zeros
    value = Number(value);

    setValue(value);
    if (input.onChange) {
      input.onChange(value);
    }
  });

  const keepCursorAtTheEnd = () => {
    const { length } = formatted || '';
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    inputRef.current.setSelectionRange(length, length);
  };

  return (
    <TextField
      {...other}
      input={input}
      meta={meta}
      onChange={handleChange}
      onBlur={handleChange}
      onSelect={keepCursorAtTheEnd}
      value={formatted}
      changeOnBlur={false}
      inputRef={inputRef}
      InputProps={{
        ...InputProps,
        classes: { input: s.input },
        startAdornment: position === 'start' && inputAdornment,
        endAdornment: position === 'end' && inputAdornment,
      }}
    />
  );
}
