import MaterialButton from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PureComponent } from 'react';

import { postToProps } from '@/components/Blog/BlogPostPreview';
import BlogPostPreviewList from '@/components/Blog/BlogPostPreviewList';
import Body from '@/components/Body';
import Button from '@/components/Button/Button';
import CaseStudyList from '@/components/CaseStudyList';
import Customers from '@/components/Customers';
import Divider from '@/components/Divider';
import ExpertWidgetHorizontal from '@/components/ExpertWidgetHorizontal';
import Footer from '@/components/Footer';
import HowItWorks from '@/components/HowItWorks';
import Link from '@/components/Link';
import MarketingHero from '@/components/MarketingLayout/MarketingHero';
import MarketingSection from '@/components/MarketingSection';
import MediaQuery from '@/components/MediaQuery';
import { Select } from '@/components/Select/Select';
import SearchPromo from '@/search/components/SearchPromo';
import { white } from '@/theme/colors';
import { SCREEN_MD } from '@/theme/screens';

import s from './Browse.module.scss';

function ExpertSection({ name, experts }: any) {
  return (
    <div className={s.expertList}>
      {experts.map((e: any) => (
        <ExpertWidgetHorizontal key={e.id} profile={e.profile} style={{ marginBottom: 30 }} />
      ))}
      <SearchPromo
        title={`Want to browse more ${name.toLowerCase()} experts?`}
        label="Sign Up For Free"
        href="/signup/member"
      />
    </div>
  );
}

interface FeaturedExpertsProps {
  sections: { key: string; name: string; experts: any[] }[];
}

class FeaturedExperts extends PureComponent<FeaturedExpertsProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      selected: props.sections[0].key,
    };
  }

  handleNavChange = (_: any, selected: any) => this.setState({ selected });

  render() {
    const { sections } = this.props;
    // @ts-expect-error TS(2339) FIXME: Property 'selected' does not exist on type 'Readon... Remove this comment to see the full error message
    const { selected } = this.state;

    const section = sections.find((s: any) => s.key === selected);

    return (
      <MarketingSection style={{ padding: '10px 0 0' }}>
        {section && <Nav sections={sections} value={section.key} onChange={this.handleNavChange} />}
        <ExpertSection {...section} />
      </MarketingSection>
    );
  }
}

function Hero({ title, imgSrc, description }: any) {
  return (
    <MarketingHero vignette backgroundImage={imgSrc}>
      <div className={s.hero}>
        <h1 className={s.heroTitle}>Access world-class {title.toLowerCase()} experts</h1>

        <p className={s.heroMarketing}>
          {description} OnFrontiers allows you to schedule consultations with pre-vetted
          practitioners and consultants spanning hundreds of specializations around the world.
        </p>

        <div>
          <Button href="/signup/member" size="large" style={{ marginRight: 10, marginBottom: 15 }}>
            Sign up for free
          </Button>

          <Link to="/platform">
            <MaterialButton style={{ color: white }}>Learn More</MaterialButton>
          </Link>
        </div>
      </div>
    </MarketingHero>
  );
}

function Nav({ sections, value, onChange }: any) {
  return (
    <div className={s.nav}>
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobile: any) =>
          isMobile ? (
            <Select
              name="industry"
              label="Select industry"
              value={value}
              onChange={({ target: { value } }: any) => onChange(value)}
              options={sections.map((section: any) => ({
                label: section.name,
                value: section.key,
              }))}
            />
          ) : (
            <Tabs value={value} onChange={onChange} variant="fullWidth">
              {sections.map((section: any) => (
                <Tab key={section.key} value={section.key} label={section.name} />
              ))}
            </Tabs>
          )
        }
      </MediaQuery>
    </div>
  );
}

function Browse({ icon, title, description, imgSrc, sections, blogPosts, caseStudies }: any) {
  return (
    <div>
      <Hero title={title} icon={icon} description={description} imgSrc={imgSrc} />

      <FeaturedExperts sections={sections} />

      <MarketingSection padding={60}>
        <CaseStudyList title="Case Studies" cases={caseStudies} caseTitleStyle={{ fontSize: 20 }} />
      </MarketingSection>

      <MarketingSection style={{ padding: '20px 0 60px' }}>
        <BlogPostPreviewList
          center
          title={`Read blog posts from our ${title.toLowerCase()} thought leaders`}
          posts={blogPosts.map(postToProps)}
        />
      </MarketingSection>

      <Body>
        <Divider spacing={0} />
      </Body>

      <HowItWorks showSignup />

      <Customers />

      <Footer />
    </div>
  );
}

export default Browse;
