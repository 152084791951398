import { Chip } from '@mui/material';
import Tooltip from 'rc-tooltip';

import Picture from '@/components/Picture';
import { orange500, red500, white } from '@/theme/colors';

const errorMessages = Object.freeze({
  out_of_marketplace: 'Not in the OnFrontiers marketplace',
});

export default function ({ profile, onRequestDelete, ...props }: any) {
  const result = props.result || {};

  const backgroundColor = result.error_code ? red500 : result.warning_code ? orange500 : undefined;

  const labelColor = result.error_code || result.warning_code ? white : undefined;

  const chip = (
    <Chip
      // @ts-expect-error TS(2769): No overload matches this call.
      color={labelColor}
      onRequestDelete={onRequestDelete}
      style={{ marginRight: 5, marginTop: 5, backgroundColor }}
      label={profile.name}
      avatar={<Picture user={profile} link={false} size={32} />}
    />
  );

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const errorMessage = errorMessages[result.error_code || result.warning_code];

  if (errorMessage) {
    return (
      <Tooltip
        overlay={errorMessage}
        trigger={['hover', 'click']}
        overlayStyle={{ zIndex: 2000 }}
        placement="top"
      >
        {chip}
      </Tooltip>
    );
  }

  return chip;
}
