import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { segmentTracking } from '@/core/analytics';
import { FilterRequest } from '@/core/pagination';
import { DegreeTypeService, PaginatedDegreeTypeList } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';

export const fetchData = createAsyncThunk(
  'degreeTypes/fetchData',
  async ({ limit, offset, query }: FilterRequest) => {
    const response = await DegreeTypeService.degreeTypeList(limit, offset, query);

    const filterPayload = { query, filters: { limit, offset } };
    segmentTracking('searching-degree-types-filter', filterPayload);

    return response;
  }
);

const degreeTypesSlice = createSlice({
  name: 'degreeTypes',
  initialState: initialState.degreeTypes,
  reducers: {
    updateData(state, action: PayloadAction<PaginatedDegreeTypeList>) {
      return { ...state, data: action.payload };
    },
    resetData() {
      return initialState.degreeTypes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      error: null,
    }));
  },
});

export const degreeTypesSelector = (state: IState) => state.degreeTypes;
export const { updateData, resetData } = degreeTypesSlice.actions;
export default degreeTypesSlice.reducer;
