import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { FormEvent, useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addGroupMember } from '@/actions/group';
import { joinNetwork } from '@/actions/internalNetwork';
import Button from '@/components/Button';
import { Checkbox } from '@/components/FormAdapters';
import Link from '@/components/Link';
import { Viewer } from '@/core/viewer';
import { GroupDomain } from '@/group';
import { updateUser } from '@/store/user';
import { borderColor, darkGray, sand } from '@/theme/colors';

export const useStyles = makeStyles({
  checkbox: {
    paddingTop: 2,
  },
  checkboxLabel: {
    alignItems: 'flex-start',
  },
  button: {
    fontWeight: 600,
    padding: 22,
    marginTop: 30,
  },
  login: {
    color: darkGray,
    textAlign: 'center',
  },
  loginDivider: {
    backgroundColor: borderColor,
    margin: '40px 0 30px',
  },
  joinNetwork: {
    padding: 20,
    border: `1px solid ${borderColor}`,
    backgroundColor: sand,
    textAlign: 'center',
    lineHeight: 1.25,
  },
  agreements: {
    marginTop: 15,
    textAlign: 'left',
  },
});

interface JoinProps {
  viewer: Viewer;
  domain: GroupDomain;
  isExpertSignup: boolean;
}

const connector = connect(undefined, {
  updateUser,
  joinNetwork,
  addGroupMember,
});

const JoinNetwork = ({
  viewer,
  domain,
  isExpertSignup,
  updateUser,
  joinNetwork,
  addGroupMember,
}: JoinProps & ConnectedProps<typeof connector>) => {
  const navigate = useNavigate();
  const s = useStyles();

  const handleSubmit = useCallback(
    async (values: any) => {
      const now = new Date();
      const agreements = Object.keys(values).map((name) => {
        return {
          policy: name,
          accepted: values[name],
          updated_at: now,
        };
      });

      await updateUser({ id: viewer.id, agreements });

      if (isExpertSignup) {
        await joinNetwork(domain.subdomain);

        navigate('/network');
        return;
      }

      await addGroupMember(domain.group!.id, {
        role: 'member',
        userId: viewer.id,
      });

      navigate(`/team/${domain.subdomain}`);
    },
    [
      updateUser,
      viewer.id,
      isExpertSignup,
      addGroupMember,
      domain.group,
      domain.subdomain,
      navigate,
      joinNetwork,
    ]
  );

  const agreements = useMemo(() => domain.agreements || [], [domain.agreements]);

  const validate = useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};
      agreements.forEach((a) => {
        if (!values[a.policy_code]) {
          errors[a.policy_code] = 'Required';
        }
      });
      return errors;
    },
    [agreements]
  );

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit }) => {
        return (
          <>
            <form
              onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}
              className={s.joinNetwork}
            >
              You are already logged into your OnFrontiers account.
              {agreements.length > 0 && (
                <div className={s.agreements}>
                  <br />
                  {agreements.map((a) => (
                    <Field
                      key={a.policy_code}
                      type="checkbox"
                      component={Checkbox}
                      classes={{ root: s.checkbox }}
                      labelClasses={{ root: s.checkboxLabel }}
                      FormControlProps={{ margin: 'none' }}
                      name={a.policy_code}
                      label={
                        <span>
                          I have read and agree with&nbsp;
                          <Link newTab href={a.policy_url}>
                            {a.policy_label}
                          </Link>
                        </span>
                      }
                    />
                  ))}
                </div>
              )}
              <Button type="submit" variant="contained" fullWidth classes={{ root: s.button }}>
                Join
              </Button>
            </form>
            <Divider className={s.loginDivider} />
            <div className={s.login}>
              Not {viewer.first_name}?&nbsp;
              <Link
                to={`/logout?next=/${`${domain.subdomain ? domain.subdomain + '/' : ''}login/expert`}`}
              >
                Log out
              </Link>{' '}
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default connector(JoinNetwork);
