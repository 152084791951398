import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TrackingContent } from '@/knowledge/accrualSummary';
import { AccrualApiService, AccrualDiff, CancelablePromise } from '@/openapi';
import { fetchKnowledge } from '@/profilev2/store/profileSlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { isRejectedNotAbortedAction } from '@/utils/reducer';

let request: CancelablePromise<Array<AccrualDiff>>;

export const accrualUpdate = createAsyncThunk(
  'accrualSummary/accrualUpdate',
  async (
    {
      profileId,
      showDiff,
      onAccrualFinish,
      trackingContext,
    }: {
      profileId: string;
      showDiff?: boolean;
      onAccrualFinish?: () => void;
      trackingContext?: TrackingContent;
    },
    { dispatch, getState }
  ) => {
    const state: IState = getState() as IState;

    if (!state.accrualSummary.accrualIsStale || !profileId) {
      return Promise.resolve();
    }

    request = AccrualApiService.accrualCalculateCreate(profileId, showDiff);
    return request.then((data) => {
      dispatch(fetchKnowledge(profileId));
      onAccrualFinish?.();
      return { data, trackingContext };
    });
  }
);

const accrualSummarySlice = createSlice({
  name: 'accrualSummary',
  initialState: initialState.accrualSummary,
  reducers: {
    markAccrualStale: (state) => {
      return {
        ...state,
        accrualIsStale: true,
      };
    },
    markAccrualUpdated: (state) => {
      return {
        ...state,
        accrualIsStale: false,
      };
    },
    resetData: () => initialState.accrualSummary,
  },
  extraReducers: (builder) => {
    builder.addCase(accrualUpdate.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(accrualUpdate.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        accrualIsStale: false,
        trackingContext: action.payload?.trackingContext,
        data: Array.isArray(action.payload?.data) ? (action.payload?.data ?? []) : [],
      };
    });
    builder.addMatcher(isRejectedNotAbortedAction(accrualUpdate), (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const accrualSummarySelector = (state: IState) => state.accrualSummary;
export const { markAccrualStale, markAccrualUpdated, resetData } = accrualSummarySlice.actions;
export default accrualSummarySlice.reducer;
