import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { TextField } from '@/components/FormAdapters';
import SelectLocation from '@/components/SelectLocation';
import { updateProfile } from '@/profile/store';

function validate(values = {}) {
  const errors = {};

  // @ts-expect-error TS(2339): Property 'country' does not exist on type '{}'.
  if (!values.country) {
    // @ts-expect-error TS(2339): Property 'country' does not exist on type '{}'.
    errors.country = 'A valid Country is required';
  }

  return errors;
}

interface LocationFormProps {
  title?: string;
  open?: boolean;
  component?: React.ComponentType<any>;
  profileId: string;
  saveMessage?: string;
  style?: React.CSSProperties;
  onClose?: () => void;
}

class LocationForm extends PureComponent<
  LocationFormProps & InjectedFormProps<{}, LocationFormProps>
> {
  handleSubmit = (values: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    // @ts-expect-error TS(2339): Property 'reset' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container = Form,
      // @ts-expect-error TS(2339): Property 'allCountries' does not exist on type 'Re... Remove this comment to see the full error message
      allCountries,
      handleSubmit,
      // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile,
      ...other
    } = this.props;

    return (
      <Container {...other} onSubmit={handleSubmit(this.handleSubmit)} onReset={this.handleReset}>
        <Field
          id="locationCountry"
          component={SelectLocation}
          multiple={false}
          name="country"
          label="In which country are you located?"
          countries={allCountries}
          format={(country: any) => allCountries.find((c: any) => c.name === country)}
          parse={(country: any) => country && country.name}
        />
        <Field
          id="locationCity"
          fullWidth
          component={TextField}
          name="city"
          label="In which city are you located?"
          placeholder="Start typing the name of a city here"
        />
      </Container>
    );
  }
}

const WrappedLocationForm = reduxForm<{}, LocationFormProps>({
  form: 'expertLocation',
  enableReinitialize: true,
  validate,
})(LocationForm);

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        country: profile.country,
        city: profile.city,
      },
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      allCountries: state.countries,
    };
  },
  {
    updateProfile,
  }
)(WrappedLocationForm);
