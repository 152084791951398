import ActionTypes from '@/actions/ActionTypes';

const { URL__SHORTENED } = ActionTypes;

const initialState = {
  shortened: {},
};

export default function urlReducer(state = initialState, action: any) {
  switch (action.type) {
    case URL__SHORTENED:
      return {
        ...state,
        shortened: {
          ...state.shortened,
          [action.url]: action.shorten,
        },
      };

    default:
      return state;
  }
}
