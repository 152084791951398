export function formatAccountType(accountType: string): string {
  switch (accountType) {
    case 'basic':
      return 'Basic';
    case 'advanced':
      return 'Advanced';
    case 'enterprise':
      return 'Enterprise';
    default:
      return accountType;
  }
}
