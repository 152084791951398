import { Button as MaterialButton } from '@mui/material';
import { PureComponent } from 'react';

import CreditsButton from '@/components/CreditsButton';
import Dialog from '@/components/Dialog';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import { teal500 } from '@/theme/colors';

import s from './RequestTranscript.module.scss';

class RequestTranscript extends PureComponent {
  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
      onCancel,
      // @ts-expect-error TS(2339) FIXME: Property 'onConfirm' does not exist on type 'Reado... Remove this comment to see the full error message
      onConfirm,
      // @ts-expect-error TS(2339) FIXME: Property 'transcriptionPrice' does not exist on ty... Remove this comment to see the full error message
      transcriptionPrice,
      // @ts-expect-error TS(2339) FIXME: Property 'submittingTranscript' does not exist on ... Remove this comment to see the full error message
      submittingTranscript,
    } = this.props;

    return (
      <Dialog open={open} onClose={onCancel}>
        <div className={s.icons}>
          <MaterialIcon icon="speaker_notes" size={40} color={teal500} />
        </div>

        <h4 className={s.title}>Request Call Transcript</h4>

        <p className={s.text}>Typical turnaround time is 24 hours.</p>

        <p className={s.text}>
          Call duration, connection quality, and languages spoken factor into timing.
        </p>

        <div className={s.actions}>
          <MaterialButton onClick={onCancel}>Cancel</MaterialButton>

          <CreditsButton
            disabled={submittingTranscript}
            credits={transcriptionPrice && transcriptionPrice.cents}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            onClick={onConfirm}
          >
            {submittingTranscript ? 'Requesting...' : 'Request'}
          </CreditsButton>
        </div>
      </Dialog>
    );
  }
}

export default RequestTranscript;
