import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import Currency from '@/components/Currency/Currency';
import Form from '@/components/Form';
import { Checkbox } from '@/components/FormAdapters';
import SettingsSection from '@/components/SettingsSection';
import { updateProfile } from '@/profile/store';
import { darkGray } from '@/theme/colors';

import RateAdvice from './RateAdvice';

const getStyles = makeStyles(() => ({
  dollar: {
    color: darkGray,
    marginTop: 15,
  },
  hourlyRateBox: {
    padding: '0 0 10px !important',
    border: '0 !important',
    maxWidth: 120,
    display: 'flex',
  },
  hourlyRate: {
    marginTop: 35,
  },
  advice: {
    marginTop: 35,
  },
}));

function validate(values = {}) {
  const errors = {};

  // @ts-expect-error TS(2339): Property 'bill_rate' does not exist on type '{}'.
  const { bill_rate: billRate } = values;

  if (billRate === '' || billRate === undefined) {
    // @ts-expect-error TS(2339): Property 'bill_rate' does not exist on type '{}'.
    errors.bill_rate = 'Required';
  }

  return errors;
}

function BillRateForm({
  userId,
  profileId,
  updateProfile,
  notify,
  onSubmit,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  component: Container = Form,
  showMarketplace = false,
  ...rest
}: any) {
  const s = getStyles();

  const handleSubmit = async (values: any) => {
    try {
      await updateProfile({ id: profileId, ...values });
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const msg = err.rawError?.[0].message;
      if (msg) {
        notify(msg, 'error');
      } else {
        throw err;
      }
    }

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container {...rest} onSubmit={handleSubmitProp(handleSubmit)} onReset={handleReset}>
      <Grid container>
        <Grid item md={3} sm={12}>
          <SettingsSection
            box
            className={s.hourlyRate}
            boxClassName={s.hourlyRateBox}
            title="Hourly Rate"
          >
            <Field
              id="expertAvailabilityBillRate"
              component={Currency}
              name="bill_rate"
              variant="outlined"
            />
          </SettingsSection>
        </Grid>
        <Grid item md={9} sm={12} container alignItems="center">
          <RateAdvice className={s.advice} profileId={profileId} userId={userId} />
        </Grid>

        <Grid item md={12} sm={12}>
          <SettingsSection box title="Consultation Preferences">
            <Field
              type="checkbox"
              id="expertAvailabilityAvailableLongTerm"
              component={Checkbox}
              name="available_long_term"
              label="Available for long-term projects"
            />
          </SettingsSection>

          {showMarketplace && (
            <SettingsSection
              box
              title="OnFrontiers Marketplace"
              text="By opting in to join the OnFrontiers marketplace, our research managers will match you to participate in projects for our diverse set of global customers."
            >
              <Field
                type="checkbox"
                id="expertAvailabilityAvailableMarketplace"
                component={Checkbox}
                name="available_marketplace"
                label="Yes, add my profile to the OnFrontiers marketplace"
              />
            </SettingsSection>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'BillRateForm' because it is a fu... Remove this comment to see the full error message
BillRateForm = reduxForm({
  form: 'expertAvailability',
  enableReinitialize: true,
  validate,
})(BillRateForm);

// @ts-expect-error TS(2630): Cannot assign to 'BillRateForm' because it is a fu... Remove this comment to see the full error message
BillRateForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        bill_rate: profile.bill_rate,
        available_long_term: profile.available_long_term,
        available_marketplace: profile.available_marketplace,
      },
    };
  },
  {
    updateProfile,
    notify,
  }
)(BillRateForm);

export default BillRateForm;
