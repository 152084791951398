import React from 'react';
import { connect } from 'react-redux';

import { track } from '@/actions/tracking';
import { darkGreen } from '@/theme/colors';

import Button from '../Button';
import EmptyMessage from '../EmptyMessage';
import FAIcon from '../Icon/FAIcon';

class TeamAccountPromo extends React.Component {
  handleClick = () => {
    const {
      // @ts-expect-error TS(2339): Property 'onContactSales' does not exist on type '... Remove this comment to see the full error message
      onContactSales,
      // @ts-expect-error TS(2339): Property 'clickEventTrack' does not exist on type ... Remove this comment to see the full error message
      clickEventTrack = 'promo.click.team_account.expert_request',
      // @ts-expect-error TS(2339): Property 'track' does not exist on type 'Readonly<... Remove this comment to see the full error message
      track,
    } = this.props;

    if (clickEventTrack) track(clickEventTrack);

    window.HubSpotConversations?.widget?.open();

    if (onContactSales) onContactSales();
  };

  render() {
    // @ts-expect-error TS(2339): Property 'iconProps' does not exist on type 'Reado... Remove this comment to see the full error message
    const { iconProps, buttonProps } = this.props;

    return (
      <EmptyMessage
        border={false}
        icon={
          <FAIcon iconSet="fas" icon="users-class" size={40} color={darkGreen} {...iconProps} />
        }
        title="Link a team billing account to proceed"
        style={{ margin: '0 auto' }}
        body={
          <React.Fragment>
            We no longer support individual billing accounts.
            <br />
            Contact our sales team and we’ll get you set up.
          </React.Fragment>
        }
        action={
          <Button onClick={this.handleClick} {...buttonProps}>
            Contact Sales
          </Button>
        }
        {...this.props}
      />
    );
  }
}

export default connect(undefined, {
  track,
})(TeamAccountPromo);
