import { KeyboardEvent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { RootState } from '@/store';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  disableSubmitOnEnter?: boolean;
  children: React.ReactNode;
  csrfToken?: string;
}

const connector = connect((state: RootState) => ({
  csrfToken: state.runtime.csrfToken as string | undefined,
}));

const Form = ({
  disableSubmitOnEnter = false,
  csrfToken,
  children,
  method,
  ...other
}: FormProps & ConnectedProps<typeof connector>) => {
  const isPost = (method || '').toLowerCase() === 'post';
  const csrfField =
    csrfToken && isPost ? (
      <input type="hidden" name="authenticity_token" value={csrfToken} />
    ) : undefined;

  const onKeyPress = disableSubmitOnEnter
    ? (e: KeyboardEvent<HTMLFormElement>) => {
        if (e.target instanceof HTMLTextAreaElement) {
          return;
        }
        if ((e.charCode || e.keyCode || 0) === 13) {
          e.preventDefault();
        }
      }
    : undefined;

  return (
    <form acceptCharset="UTF-8" method={method} {...other} onKeyPress={onKeyPress}>
      {csrfField}
      {children}
    </form>
  );
};

export default connector(Form);
