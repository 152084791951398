import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, PaginatedAssignmentsList, ProfileService } from '@/openapi';

import { AssignmentsOrdering } from '../AssignmentsManager';

export const DEFAULT_ASSIGNMENTS_LIMIT = 5;

type FetchAssignments = {
  profileId: string;
  knowledgeName: string;
  ordering?: AssignmentsOrdering;
  page?: number;
  limit?: number;
  type?: 'knowledge' | 'location';
};

export type AssignmentsByKnowledgeUseQueryOptions = {
  enabled?: boolean;
  staleTime?: number;
  refetchOnMount?: boolean | 'always';
};

const fetchAssignmentsByKnowledge = ({
  profileId,
  knowledgeName,
  page = 1,
  limit = DEFAULT_ASSIGNMENTS_LIMIT,
  ordering = '-dates',
  type = 'knowledge',
}: FetchAssignments): CancelablePromise<PaginatedAssignmentsList> => {
  const offset = (page - 1) * limit;
  return ProfileService.profileExperiencesV2List(
    profileId,
    knowledgeName,
    limit,
    offset,
    ordering,
    type
  );
};

export const useAssignmentsByKnowledgeQuery = (
  { profileId, knowledgeName, page, limit, ordering, type }: FetchAssignments,
  options?: AssignmentsByKnowledgeUseQueryOptions
) => {
  return useQuery({
    queryKey: ['assignments', { profileId, knowledgeName, page, limit, ordering }],
    queryFn: () =>
      fetchAssignmentsByKnowledge({
        profileId,
        knowledgeName,
        page,
        limit,
        ordering,
        type,
      }),
    keepPreviousData: true,
    enabled: !!profileId && !!knowledgeName,
    ...options,
  });
};
