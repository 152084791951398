import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { useCallback, useRef, useState } from 'react';

import Box from '@/componentsv2/Box';
import { Drawer } from '@/componentsv2/Drawer';
import Dropdown from '@/componentsv2/Dropdown';
import Pagination from '@/componentsv2/Pagination';
import UpdatingIndicator from '@/componentsv2/UpdatingIndicator';
import { useProfileId, useSelf } from '@/hooks/store';
import useCloseDrawer from '@/hooks/useCloseDrawer';
import useProfileBreadcrumbs from '@/hooks/useProfileBreadcrumbs';
import KnowledgeBar from '@/knowledge/components/KnowledgeBar';
import KnowledgeBarSkeletons from '@/knowledge/components/KnowledgeBar/Skeletons';
import { KNOWLEDGE_SORT_OPTIONS } from '@/knowledge/components/KnowledgeListDrawer';
import {
  DEFAULT_KNOWLEDGES_LIMIT,
  KnowledgesOrdering,
  useLocationsQuery,
} from '@/knowledge/queries/knowledge';
import { accrualSummarySelector } from '@/knowledge/store/accrualSummarySlice';
import { TITLE } from '@/profilev2/components/Locations';
import { profileSelector } from '@/profilev2/store/profileSlice';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { useAppSelector } from '@/store';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

const testId = 'of-locations-list-drawer';

const LocationListDrawer = () => {
  const handleCloseDrawer = useCloseDrawer();
  const profileId = useProfileId();
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState<KnowledgesOrdering>('-value');
  const isSelf = useSelf();
  const { isLoading: isAccrualRefetching, accrualIsStale } = useAppSelector(accrualSummarySelector);

  const { data: profileData, isLoading: isProfileLoading } = useAppSelector(profileSelector);

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
    scrollableListRef.current?.scrollTo(0, 0);
  }, []);

  const handleSortOrderChange = useCallback(
    (newOrdering: string) => {
      setSortValue(newOrdering as KnowledgesOrdering);
      handlePageChange(1);
    },
    [handlePageChange]
  );

  const {
    data: locations,
    isLoading,
    isRefetching,
    isPreviousData,
  } = useLocationsQuery({
    profileId,
    ordering: sortValue,
    page,
  });

  const breadcrumbs = useProfileBreadcrumbs([
    {
      title: TITLE,
    },
  ]);

  const showUpdatingScores = isSelf && (isRefetching || (isAccrualRefetching && accrualIsStale));

  return (
    <>
      <Drawer.Header breadcrumbs={breadcrumbs} onClose={handleCloseDrawer} />
      <Drawer.Main ref={scrollableListRef} data-testid={testId}>
        <Drawer.Section>
          <div className="border-b border-grey-400 p-20">
            <div className="space-y-8">
              {isProfileLoading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <span className="body-1">{profileData?.full_name}&rsquo;s Skills</span>
              )}
              <h2 className="hd-4">{TITLE}</h2>
            </div>
          </div>
          <div className="p-16">
            <div className="flex justify-between">
              <div className="flex gap-12">
                <Drawer.DetailsList
                  details={{
                    [pluralize('Location', locations?.count)]: !isLoading
                      ? locations?.count
                      : undefined,
                  }}
                />
                {showUpdatingScores ? <UpdatingIndicator text="Updating knowledge scores" /> : null}
              </div>
              <Dropdown
                id="knowledge-list-drawer-sort"
                options={KNOWLEDGE_SORT_OPTIONS}
                value={sortValue}
                onChange={handleSortOrderChange}
              />
            </div>

            <ul
              className={clsx('mt-16 flex flex-col gap-10', {
                'opacity-50': isPreviousData,
              })}
            >
              {isLoading ? (
                <KnowledgeBarSkeletons />
              ) : (
                locations?.results?.map((location) => {
                  return (
                    <li key={location.name}>
                      <KnowledgeBar
                        label={location.name as string}
                        value={knowledgeScoreToPercent(+(location.value || 0))}
                        to={'../' + PROFILE_ROUTES().location(location.name ?? '')}
                      />
                    </li>
                  );
                })
              )}
            </ul>
            {locations?.count && locations.count > DEFAULT_KNOWLEDGES_LIMIT ? (
              <Box sx={{ mt: 'auto' }}>
                <Pagination
                  disabled={isLoading}
                  page={page}
                  count={Math.ceil(locations.count / DEFAULT_KNOWLEDGES_LIMIT)}
                  handlePageChange={(_, page) => handlePageChange(page)}
                />
              </Box>
            ) : null}
          </div>
        </Drawer.Section>
      </Drawer.Main>
    </>
  );
};

export { testId as LocationListDrawerTestId };
export default LocationListDrawer;
