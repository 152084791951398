import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Box, Text } from '@/componentsv2';

import styles, { stepCardClasses } from './styles';
import { IStepCardProps } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-step-card';

const StepCard = ({ className, icon: Icon, title = '', description = '' }: IStepCardProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.stepCard, stepCardClasses.root);

  return (
    <Box className={classProps} data-testid={testId}>
      <Box className={stepCardClasses.container}>
        {Icon && (
          <Avatar>
            <Icon color="primary" sx={{ fontSize: 56 }} />
          </Avatar>
        )}
        <Box className={stepCardClasses.body}>
          <Text tag="h3" color="grey.900">
            {title}
          </Text>
          <Text tag="pRegular" color="grey.700">
            {description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export type { IStepCardProps };
export { stepCardClasses };
export { testId as StepCardTestId };
export default StepCard;
