import { PaginationItem } from '@mui/material';
import PaginationMui from '@mui/material/Pagination';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ArrowLeft, ArrowRight } from 'react-feather';

import styles from './styles';
import { PaginationProps } from './types';

const useStyles = makeStyles(styles);
const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, count, handlePageChange, disabled, className } = props;
  const classes = useStyles();
  const mainClass = clsx(classes.Search);

  return (
    <PaginationMui
      aria-label="pagination navigation"
      className={`${mainClass} ${className}`}
      page={page}
      count={count}
      disabled={disabled}
      onChange={handlePageChange}
      variant="outlined"
      shape="rounded"
      color="standard"
      size="small"
      renderItem={(item) => (
        <PaginationItem components={{ previous: ArrowLeft, next: ArrowRight }} {...item} />
      )}
    />
  );
};

export type { PaginationProps } from './types';
export default Pagination;
