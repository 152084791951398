import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { GroupRole, InvitationCollectionType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import CircularProgress from '@/components/CircularProgress/CircularProgress';
import CountBox from '@/components/CountBox';
import Divider from '@/components/Divider';
import GroupMember from '@/components/GroupMember';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link';
import MemberRequest from '@/components/MemberRequests/Group';
import Pagination from '@/components/Pagination/Pagination';
import { APIError } from '@/core/api';
import { money } from '@/core/money';
import { Viewer } from '@/core/viewer';
import { borderColor, darkGray, primary } from '@/theme/colors';
import { debounce, formatCredits, normalizeSpace } from '@/utils';

import AddTeamMemberDialog from './AddTeamMemberDialog';

const FETCH_TEAM_MEMBERS = gengql(/* GraphQL */ `
  query getTeamMembers(
    $groupId: String!
    $limit: Int!
    $page: Int
    $name: String
    $states: [GroupMemberState]
  ) {
    groupMembers(group_id: $groupId, limit: $limit, page: $page, name: $name, states: $states) {
      edges {
        node {
          id
          role
          email
          user {
            id
            html_url
            picture_url
            name
            first_name
            last_name
            city
            country
          }
        }
      }
      pageInfo {
        total
        lastPage
      }
    }
  }
`);

const ADD_TEAM_MEMBER = gengql(/* GraphQL */ `
  mutation addTeamMember($groupId: String!, $userId: String!, $role: GroupRole!) {
    addGroupMember(group_id: $groupId, user_id: $userId, role: $role) {
      id
      email
      role
      user {
        id
        first_name
        last_name
        name
        html_url
        picture_url
        city
        country
      }
    }
  }
`);

const INVITE_USER = gengql(/* GraphQL */ `
  mutation inviteUser(
    $email: String
    $profileId: String
    $firstName: String
    $lastName: String
    $collectionType: InvitationCollectionType!
    $collectionId: String!
    $role: String!
    $teamNote: String
    $invitationMessage: String
  ) {
    inviteUser(
      email: $email
      profile_id: $profileId
      first_name: $firstName
      last_name: $lastName
      collection_type: $collectionType
      collection_id: $collectionId
      role: $role
      team_note: $teamNote
      invitation_message: $invitationMessage
    ) {
      id
      role
      email
    }
  }
`);

const UPDATE_TEAM_MEMBER = gengql(/* GraphQL */ `
  mutation updateTeamMember($id: String!, $role: GroupRole!) {
    updateGroupMember(id: $id, role: $role) {
      id
      email
      user {
        id
      }
    }
  }
`);

const REMOVE_TEAM_MEMBER = gengql(/* GraphQL */ `
  mutation removeTeamMember($id: String!) {
    removeGroupMember(id: $id) {
      id
      email
      user {
        id
      }
    }
  }
`);

const DEFAULT_FETCH_LIMIT = 10;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLink: {
    whiteSpace: 'nowrap',
    marginLeft: 15,
    fontSize: 14,
  },
  members: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  membersActions: {
    width: 200,
  },
  membersActionsTitle: {
    color: darkGray,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  membersList: {
    flex: 1,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    borderTop: `1px solid ${borderColor}`,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 25,
    '& span': {
      paddingTop: 15,
    },
  },
  counts: {
    display: 'flex',
    flexWrap: 'wrap',
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      '& > *': {
        width: 170,
        paddingBottom: 10,
      },
    },
  },
  search: {
    marginLeft: 'auto',
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      width: 190,
    },
  },
}));

type Invitation = {
  email: string;
  profileId: string;
  firstName: string;
  lastName: string;
  collectionType: InvitationCollectionType;
  collectionId: string;
  role: string;
  teamNote: string;
  invitationMessage: string;
};

function Team(this: any, { viewer, groupId, team, notify }: any) {
  const s = useStyles();

  const { members_awaiting: awaitingPage } = team;
  const { name, stats = {}, html_url: htmlUrl, billing_account: billingAccount } = team;

  const [addOpen, setAddOpen] = useState(false);
  const [addTeamMemberLoading, setAddTeamMemberLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [nameSearch, setNameSearch] = useState(null);

  const gqlClient = useApolloClient();
  const addTeamMember = async (groupId: string, { userId, role }: any) => {
    const response = await gqlClient.mutate({
      mutation: ADD_TEAM_MEMBER,
      variables: { groupId, userId, role },
    });
    return response;
  };
  const inviteUser = async (invitation: Invitation) => {
    const response = await gqlClient.mutate({
      mutation: INVITE_USER,
      variables: invitation,
    });
    return response;
  };
  const [updateTeamMember, { loading: updateTeamMemberLoading, error: updateTeamMemberError }] =
    useMutation(UPDATE_TEAM_MEMBER, { refetchQueries: ['getTeamMembers'] });
  const [removeTeamMember, { loading: removeTeamMemberLoading, error: removeTeamMemberError }] =
    useMutation(REMOVE_TEAM_MEMBER, { refetchQueries: ['getTeamMembers'] });
  const {
    data,
    loading: fetchTeamMembersLoading,
    refetch,
    error: fetchTeamMembersError,
  } = useQuery(FETCH_TEAM_MEMBERS, {
    notifyOnNetworkStatusChange: true,
    variables: { groupId, page: 1, name, limit: DEFAULT_FETCH_LIMIT },
  });

  const members = data?.groupMembers || { edges: [], pageInfo: {} };
  const totalMembers = members.pageInfo?.total || 0;

  useEffect(() => {
    [fetchTeamMembersError, updateTeamMemberError, removeTeamMemberError].forEach((e) => {
      if (e && e instanceof APIError) {
        e.rawError.map((error: any) => notify(error.message));
      }
    });
  }, [fetchTeamMembersError, updateTeamMemberError, removeTeamMemberError, notify]);

  const isLoading =
    fetchTeamMembersLoading ||
    addTeamMemberLoading ||
    updateTeamMemberLoading ||
    removeTeamMemberLoading;

  // if empty, decrease page
  if (!isLoading && page > 1 && !members.edges.length) {
    setPage(page - 1);
  }

  const fetchPageMembers = useCallback(
    ({ name = nameSearch } = {}) => {
      refetch({ page, name });
    },
    [refetch, page, nameSearch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((name: any) => {
      fetchPageMembers({ name });
    }, 500),
    [fetchPageMembers]
  );

  useEffect(() => {
    fetchPageMembers({ name: nameSearch });
  }, [fetchPageMembers, nameSearch]);

  useEffect(() => {
    if (nameSearch === null) return;
    debounceSearch(nameSearch);
  }, [nameSearch, debounceSearch]);

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAdd = () => {
    setAddOpen(true);
  };

  const removeTeamMemberAndFetch = (id: string) => {
    removeTeamMember({ variables: { id } });
  };

  const updateTeamMemberAndFetch = (id: string, role: GroupRole) => {
    updateTeamMember({ variables: { id, role } });
  };

  const awaitingMembers = useMemo(() => {
    if (!awaitingPage) return null;
    const awaiting = awaitingPage.edges.map((x: any) => x.node).filter((x: any) => x.user);
    if (!awaiting.length) return null;

    return <MemberRequest onApprove={fetchPageMembers} memberRequests={awaiting} />;
  }, [awaitingPage, fetchPageMembers]);

  const creditBalance = billingAccount
    ? money(billingAccount.credit_balance)
    : money({ cents: 0, currency: 'OFC' });

  const permissions = team.permissions || [];
  const viewerMember = members.edges.find(({ node: m }) => m.user && m.user.id === viewer.id)?.node;
  const viewerRole = viewerMember?.role || GroupRole.Owner;

  const memberIDs = members.edges.map(({ node: m }) => m.user && m.user.id).filter((x: any) => x);
  const addUserFilter = (suggestionCandidate: any) => !memberIDs.includes(suggestionCandidate.id);

  const perms = {
    add: permissions.some((x: any) => x === 'add_member'),
    update: {
      owner: permissions.some((x: any) => x === 'update_owner'),
      admin: permissions.some((x: any) => x === 'update_admin'),
      member: permissions.some((x: any) => x === 'update_member'),
    },
    remove: {
      owner: permissions.some((x: any) => x === 'remove_owner'),
      admin: permissions.some((x: any) => x === 'remove_admin'),
      member: permissions.some((x: any) => x === 'remove_member'),
    },
    allowedRoles: permissions
      .filter((x: any) => x.startsWith('update_'))
      .map((x: any) => x.replace(/^update_/, '')),
  };

  const totalPages = members.pageInfo?.lastPage ?? 0;

  return (
    // @ts-expect-error TS(2339) FIXME: Property 'root' does not exist on type 'ClassNameM... Remove this comment to see the full error message
    <div className={s.root}>
      <div className={s.header}>
        <Breadcrumbs
          crumbs={[
            {
              title: name,
              href: htmlUrl,
            },
          ]}
        />
        {team.internal_network && (
          <Link className={s.headerLink} to={`/search?networks[]=${team.internal_network.id}`}>
            <FAIcon icon="users-class" /> View Network
          </Link>
        )}
        <Link className={s.headerLink} to={`${htmlUrl}/settings`}>
          <FAIcon icon="cog" /> Settings &amp; Billing
        </Link>
      </div>
      {awaitingMembers}
      <div className={s.counts}>
        <CountBox count={totalMembers} label="Team Members" />
        <CountBox count={stats.expert_request_count} label="Expert Requests" />
        <CountBox count={stats.consultation_count} label="Consultations" />
        <CountBox
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          count={formatCredits(creditBalance.cents)}
          warn={creditBalance.cents <= 0}
          linkTo={billingAccount && `${htmlUrl}/settings/credits`}
          label="Credits"
        />
        <div className={s.search}>
          <TextField
            value={nameSearch ?? ''}
            margin="none"
            fullWidth={false}
            onChange={({ target: { value } }) => {
              refetch({});
              setPage(1);
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              setNameSearch(normalizeSpace(value));
            }}
            placeholder="Filter by name"
          />
        </div>
      </div>
      <Divider />
      <div className={s.members}>
        <div className={s.membersActions}>
          <div className={s.membersActionsTitle}>Team Members</div>
          <Button
            onClick={handleAdd}
            color="white"
            variant="text"
            fontColor={primary}
            disabled={!perms.add}
            size="small"
          >
            Add
          </Button>
          <AddTeamMemberDialog
            groupId={groupId}
            userFilter={addUserFilter}
            open={addOpen}
            onSubmit={() => {
              setAddTeamMemberLoading(true);
              handleAddClose();
            }}
            onClose={handleAddClose}
            onRequestDone={() => {
              setAddTeamMemberLoading(false);
              fetchPageMembers();
            }}
            addTeamMember={addTeamMember}
            inviteUser={inviteUser}
          />
        </div>
        <div className={s.membersList}>
          {isLoading ? (
            <div className={s.loading}>
              <CircularProgress size={52} />
              <span>Loading...</span>
            </div>
          ) : (
            members.edges.map(({ node: m }: { node: any }) => (
              <GroupMember
                key={m.id}
                // @ts-expect-error TS(2322) FIXME: Type '{ key: any; member: never; groupId: any; vie... Remove this comment to see the full error message
                member={m}
                groupId={groupId}
                viewerRole={viewerRole}
                removeGroupMember={
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  perms.remove[m.role] ? removeTeamMemberAndFetch : null
                }
                updateGroupMember={
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  perms.update[m.role] ? updateTeamMemberAndFetch : null
                }
                allowedRoles={perms.allowedRoles}
              />
            ))
          )}
          {totalPages > 1 && (
            <div className={s.pagination}>
              <Pagination
                page={page}
                count={totalPages}
                onChange={(_: any, value: any) => {
                  refetch({ page: value });
                  setPage(value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (
  state: { viewer: Viewer; groups: any },
  ownProps: { groupId: string }
) => ({
  viewer: state.viewer,
  groupId: ownProps.groupId,
  team: (state.groups.all.edges.find((g: any) => g.node.id === ownProps.groupId) || {}).node,
});

export default connect(mapStateToProps, {
  notify,
})(Team);
