import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import Divider from '@/components/Divider';
import { updateExpertState } from '@/store/user';
import { darkGray, red500, teal500 } from '@/theme/colors';

const useStyles = makeStyles(() => ({
  expertApplication: {
    marginBottom: 15,
  },
  buttonInfo: {
    color: darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
  },
  buttonsTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const BaseButton = ({ fullWidth, disabled, style, children, ...rest }: any = {}) => (
  <Button
    {...rest}
    color="white"
    style={{ ...style, width: fullWidth ? undefined : 110 }}
    fullWidth={fullWidth}
    disabled={disabled}
  >
    {children}
  </Button>
);

export default function ExpertApplicationPanel(
  this: any,
  { user, updateExpertState, notify }: any
) {
  const s = useStyles();

  const handleUpdateStateClick = (state: 'active' | 'denied', e: any) => {
    e.preventDefault();

    updateExpertState({ id: user.id, expert_state: state }).catch(() => {
      notify('An error occurred when updating the expert state.', 'error');
    });
  };

  const approveButton = (props: any) => (
    <BaseButton
      {...props}
      key="approve"
      startIcon="check"
      color="white"
      size="small"
      fontColor={teal500}
      onClick={handleUpdateStateClick.bind(this, 'active')}
    >
      Approve
    </BaseButton>
  );

  const denyButton = (props: any) => (
    <BaseButton
      {...props}
      key="deny"
      startIcon="close"
      color="white"
      fontColor={red500}
      size="small"
      onClick={handleUpdateStateClick.bind(this, 'denied')}
    >
      Deny
    </BaseButton>
  );

  const approveDeny = [
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    approveButton(),
    denyButton({ style: { marginLeft: 10 } }),
  ];
  const approveDenyDisabled = [
    approveButton({ disabled: true }),
    denyButton({ disabled: true, style: { marginLeft: 10 } }),
  ];
  const approveAlone = approveButton({ fullWidth: true });
  const denyAlone = denyButton({ fullWidth: true });

  const complianceAt = user.compliance_completed_at && moment(user.compliance_completed_at);

  const compliance = complianceAt
    ? `Compliance completed ${complianceAt.calendar()}`
    : 'This user has not completed compliance training yet.';

  let applicationState = '';
  if (
    ['applying', 'applied'].includes(user.expert_state) ||
    (!user.expert_state && user.signup_type === 'expert')
  ) {
    applicationState = user.phone ? 'pending' : 'phone_missing';
  } else {
    applicationState = user.expert_state;
  }

  const buttons = {
    phone_missing: approveDenyDisabled,
    pending: approveDeny,
    active: denyAlone,
    denied: approveAlone,
  }[applicationState];

  const title = {
    '': 'Expert Application',
    phone_missing: 'Expert Application',
    pending: 'Expert Application',
    active: 'Expert Application Approved',
    denied: 'Expert Application Denied',
    inactive: 'Expert Inactive',
  }[applicationState];

  return (
    <div className={s.expertApplication}>
      <div className={s.buttonsTitle}>{title}</div>
      <div>{buttons}</div>
      {applicationState === 'phone_missing' && (
        <div className={s.buttonInfo}>
          The user must have a phone number in order to be approved.
        </div>
      )}
      <div className={s.buttonInfo}>{compliance}</div>
      <Divider />
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'ExpertApplicationPanel' because ... Remove this comment to see the full error message
ExpertApplicationPanel = connect(
  (state, ownProps) => ({
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    user: state.users[ownProps.userId],
  }),
  {
    updateExpertState,
    notify,
  }
)(ExpertApplicationPanel);
