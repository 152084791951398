import React from 'react';
import { connect } from 'react-redux';

import { requestEmailValidation } from '@/actions/address';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import EmptyMessage from '@/components/EmptyMessage';
import LayoutPage from '@/components/Layout/LayoutPage';
import { teal500 } from '@/theme/colors';

class AwaitingEmailValidation extends React.Component {
  handleResendVerification = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer, requestEmailValidation, notify } = this.props;
    requestEmailValidation(viewer.profile.id, viewer.email.address, 'verify').then(() =>
      notify('Verification email sent.')
    );
  };

  render() {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer } = this.props;

    return (
      <LayoutPage hideSearch showReviewConsultation={false} showNewRequest={false}>
        <EmptyMessage
          border={false}
          iconName="mail"
          iconColor={teal500}
          title="Please check your inbox for a verification email"
          body={
            <div>
              <p>We need to verify ownership of your email address before continuing.</p>
              <p>{viewer.email.address}</p>
            </div>
          }
          action={<Button onClick={this.handleResendVerification}>Re-Send</Button>}
        />
      </LayoutPage>
    );
  }
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'DefaultR... Remove this comment to see the full error message
    viewer: state.viewer,
  }),
  {
    requestEmailValidation,
    notify,
  }
)(AwaitingEmailValidation);
