import cx from 'classnames';
import React, { PureComponent } from 'react';

import { darkBrown } from '@/theme/colors';

import Body from '../Body';
import Wallpaper from '../Wallpaper';
import MarketingHeader from './MarketingHeader';
import s from './MarketingHero.module.scss';

interface HeroProps {
  dark?: boolean;
  tint?: string;
  vignette?: boolean;
  backgroundImage?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  style?: React.CSSProperties;
  contentClassName?: string;
}

class Hero extends PureComponent<HeroProps> {
  render() {
    const {
      children,
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      backgroundImage,
      // @ts-expect-error TS(2339) FIXME: Property 'backgroundVideo' does not exist on type ... Remove this comment to see the full error message
      backgroundVideo,
      backgroundColor = darkBrown,
      // @ts-expect-error TS(2339) FIXME: Property 'backgroundPosition' does not exist on ty... Remove this comment to see the full error message
      backgroundPosition = 'center',
      // @ts-expect-error TS(2339) FIXME: Property 'theme' does not exist on type 'Readonly<... Remove this comment to see the full error message
      theme,
      vignette = false,
      contentClassName,
      // @ts-expect-error TS(2339) FIXME: Property 'contentStyle' does not exist on type 'Re... Remove this comment to see the full error message
      contentStyle,
      // @ts-expect-error TS(2339) FIXME: Property 'headerProps' does not exist on type 'Rea... Remove this comment to see the full error message
      headerProps,
      dark = true,
      tint,
      style,
      ...other
    } = this.props;
    const classNames = cx(s.root, {
      [className]: !!className,
      [s.dark]: !!dark,
    });
    return (
      <div className={classNames} style={style} {...other}>
        <Wallpaper
          color={backgroundColor}
          imgSrc={backgroundImage}
          videoSrc={backgroundVideo}
          vignette={vignette}
          imgPosition={backgroundPosition}
          tint={tint}
        />

        <MarketingHeader theme={theme} backgroundColor={backgroundColor} {...headerProps} />

        <Body className={cx(s.content, contentClassName)} style={contentStyle}>
          {children}
        </Body>
      </div>
    );
  }
}

export default Hero;
