export const arrayFromRange = ({
  min = 1,
  max,
  step = 1,
}: {
  min?: number;
  max: number;
  step?: number;
}) => {
  return Array.from(Array(Math.floor((max - min) / step) + 1).keys()).map((i) => min + i * step);
};
