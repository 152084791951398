import clsx from 'clsx';

import { IDrawerSummaryProps } from '../types';

const testId = 'of-drawer-summary';

const DrawerSummary = ({ className, children }: IDrawerSummaryProps) => {
  const classProps = clsx('bg-grey-100 border border-grey-300 py-[6px] px-16 mt-16', className);

  return (
    <div className={classProps} data-testid={testId}>
      {children}
    </div>
  );
};

export default DrawerSummary;
