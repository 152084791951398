import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  CancelablePromise,
  PaginatedProjectMemberList,
  ProjectMember,
  ProjectMemberCreate,
  ProjectMemberService,
  ProjectMemberUpdate,
} from '@/openapi';
import { fetchKnowledge } from '@/profilev2/store/profileSlice';
import initialState from '@/store/initialState';
import IState from '@/store/state';

let request: CancelablePromise<PaginatedProjectMemberList>;

export const fetchData = createAsyncThunk(
  'projectMembers/fetchData',
  async ({ profileId, projectId }: { profileId: string; projectId: string }) => {
    request?.cancel();
    request = ProjectMemberService.projectMemberList(undefined, undefined, profileId, projectId);
    return request;
  }
);

export const saveData = createAsyncThunk(
  'projectMembers/saveData',
  async (
    {
      projectMemberId,
      patchedProjectMember,
    }: {
      projectMemberId: string;
      patchedProjectMember: Partial<ProjectMemberUpdate>;
    },
    { dispatch }
  ) =>
    await ProjectMemberService.projectMemberPartialUpdate(
      projectMemberId,
      patchedProjectMember
    ).then((data) => {
      dispatch(fetchKnowledge(data.profile_id));
      return data;
    })
);

export const addMember = createAsyncThunk(
  'projectMembers/add',
  async (requestBody: ProjectMemberCreate, { dispatch }): Promise<ProjectMember> =>
    await ProjectMemberService.projectMemberCreate(requestBody).then((data) => {
      dispatch(fetchKnowledge(data.profile_id));
      return data;
    })
);

const projectMemberSlice = createSlice({
  name: 'projectMembers',
  initialState: initialState.projectMembers,
  reducers: {
    updateData: (state, action: PayloadAction<PaginatedProjectMemberList>) => ({
      ...state,
      data: action.payload,
    }),
    resetData: () => initialState.projectMembers,
    resetNewData: (state) => ({ ...state, new: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: state.hasLoaded ? false : true,
      isRefetching: state.hasLoaded ? true : false,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      isRefetching: false,
      hasLoaded: true,
    }));
    builder.addCase(fetchData.rejected, (state) => ({
      ...state,
    }));
    builder.addCase(addMember.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(addMember.fulfilled, (state, action) => ({
      ...state,
      new: action.payload,
      isLoading: false,
    }));
  },
});

export const projectMemberSelector = (state: IState) => state.projectMembers;
export const { updateData, resetData, resetNewData } = projectMemberSlice.actions;
export default projectMemberSlice.reducer;
