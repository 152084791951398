/* istanbul ignore file */

/* tslint:disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { PaginatedProfileCertificationList } from '../models/PaginatedProfileCertificationList';
import type { PatchedProfileCertificationUpdate } from '../models/PatchedProfileCertificationUpdate';
import type { ProfileCertification } from '../models/ProfileCertification';
import type { ProfileCertificationCreate } from '../models/ProfileCertificationCreate';
import type { ProfileCertificationUpdate } from '../models/ProfileCertificationUpdate';

export class ProfileCertificationService {
  /**
   * List all Profile Certifications
   * List all Profile Certifications
   * @param limit Number of results to return per page.
   * @param offset The initial index from which to return the results.
   * @param profileId
   * @returns PaginatedProfileCertificationList
   * @throws ApiError
   */
  public static profileCertificationList(
    limit?: number,
    offset?: number,
    profileId?: string
  ): CancelablePromise<PaginatedProfileCertificationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_certification/',
      query: {
        limit: limit,
        offset: offset,
        profile__id: profileId,
      },
    });
  }

  /**
   * Create a Profile Certification
   * Create a Profile Certification
   * @param requestBody
   * @returns ProfileCertificationCreate
   * @throws ApiError
   */
  public static profileCertificationCreate(
    requestBody?: ProfileCertificationCreate
  ): CancelablePromise<ProfileCertificationCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profile_certification/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a Profile Certification by UUID
   * Retrieve a Profile Certification by UUID
   * @param id A UUID string identifying this profile certification.
   * @returns ProfileCertification
   * @throws ApiError
   */
  public static profileCertificationRetrieve(id: string): CancelablePromise<ProfileCertification> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profile_certification/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * Completely update a Profile Certification by UUID
   * Completely update a Profile Certification by UUID
   * @param id A UUID string identifying this profile certification.
   * @param requestBody
   * @returns ProfileCertificationUpdate
   * @throws ApiError
   */
  public static profileCertificationUpdate(
    id: string,
    requestBody?: ProfileCertificationUpdate
  ): CancelablePromise<ProfileCertificationUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profile_certification/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update specific fields within a Profile Certification by UUID
   * Update specific fields within a Profile Certification by UUID
   * @param id A UUID string identifying this profile certification.
   * @param requestBody
   * @returns ProfileCertificationUpdate
   * @throws ApiError
   */
  public static profileCertificationPartialUpdate(
    id: string,
    requestBody?: PatchedProfileCertificationUpdate
  ): CancelablePromise<ProfileCertificationUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profile_certification/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete a Profile Certification by UUID
   * Delete a Profile Certification by UUID
   * @param id A UUID string identifying this profile certification.
   * @returns void
   * @throws ApiError
   */
  public static profileCertificationDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profile_certification/{id}/',
      path: {
        id: id,
      },
    });
  }
}
