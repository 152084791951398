const isNode = typeof window === 'undefined';

if (isNode) {
  // @ts-expect-error
  import.meta.env = process.env;
}

interface Config {
  appEnv: string;
  baseDomain: string;
  harnessApiUrl: string;
  apiUrl: string;
  apiProxy: boolean;
  rewriteUrl?: string;
  webUrl: string;
  cdnUrl?: string;
  mainSiteUrl: string;
  webSocketUrl: string;
  blogApiUrl: string;
  filestackEngagementAttachmentMimeTypes: string[];
  enableInviteParticipant: boolean;
  disableWrittenEngagement: boolean;
  disableChangeExpertRequestType: boolean;
  recaptchaSiteKey?: string;
  googleTagManagerId?: string;
  growthBookClientKey?: string;
  enableConsultingProjectAccountIds: string[];
  enableNewHireAccountIds: string[];
  enableOpportunityCallAccountIds: string[];
  stripePublishableKey: string;
  enableBillingPortal: boolean;
  enablePayoutDashboard: boolean;
  requireAgreements: boolean;
  filestackApiKey: string;
  intercomAppId: string;
  sendbirdAppId: string;
  sentryDsn: string;

  // Harness
  clientName: string;
  clientAssetsName: string;
  productName: string;
  logo: string;
  icon: string;
  accessIcon: string;
  onboardingVideo: number;
}

const webUrl = new URL(import.meta.env.VITE_WEB_URL || `http://localhost:5173`);
const baseDomain = webUrl.hostname;

const apiUrl = new URL(import.meta.env.VITE_API_URL || `http://${baseDomain}:15000`);

const config: Config = {
  appEnv: import.meta.env.VITE_ENV || 'development',
  baseDomain,
  harnessApiUrl: import.meta.env.VITE_HARNESS_PYTHON_API_URL,
  apiUrl: apiUrl.toString().replace(/\/$/, ''),
  apiProxy: import.meta.env.VITE_API_PROXY === '1',
  rewriteUrl: import.meta.env.VITE_REWRITE_URL,
  webUrl: webUrl.toString().replace(/\/$/, ''),
  cdnUrl: import.meta.env.VITE_CDN_URL,
  mainSiteUrl: `https://onfrontiers.com`,
  webSocketUrl:
    import.meta.env.VITE_WEBSOCKET_URL ||
    `${apiUrl.protocol === 'http:' ? 'ws' : 'wss'}://${apiUrl.host}/websocket`,
  blogApiUrl: import.meta.env.VITE_BLOG_API_URL || 'https://onfrontiers.com',
  filestackEngagementAttachmentMimeTypes: import.meta.env
    .VITE_FILESTACK_ENGAGEMENT_ATTACHMENT_MIME_TYPES
    ? import.meta.env.VITE_FILESTACK_ENGAGEMENT_ATTACHMENT_MIME_TYPES.split(',')
    : ['text/*', 'image/*', 'application/*'],
  // TODO: Remove temporary feature flag introduced in EKN-138
  // when feature is ready.
  enableInviteParticipant: import.meta.env.VITE_ENABLE_INVITE_PARTICIPANT === '1',
  disableWrittenEngagement: import.meta.env.VITE_DISABLE_WRITTEN_ENGAGEMENT === '1',
  disableChangeExpertRequestType: import.meta.env.VITE_DISABLE_CHANGE_EXPERT_REQUEST_TYPE === '1',
  recaptchaSiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  // See ACCESS-27, we are disabling these expert request types for all
  // accounts except for the ones listed below.
  googleTagManagerId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
  growthBookClientKey: import.meta.env.VITE_GROWTH_BOOK_CLIENT_KEY,
  enableConsultingProjectAccountIds: import.meta.env.VITE_ENABLE_CONSULTING_PROJECT_ACCOUNT_IDS
    ? import.meta.env.VITE_ENABLE_CONSULTING_PROJECT_ACCOUNT_IDS.split(',')
    : [],
  enableNewHireAccountIds: import.meta.env.VITE_ENABLE_NEW_HIRE_ACCOUNT_IDS
    ? import.meta.env.VITE_ENABLE_NEW_HIRE_ACCOUNT_IDS.split(',')
    : [],
  // See ACCESS-29, we are disabling these consultation types for all
  // accounts except for the ones listed below.
  enableOpportunityCallAccountIds: import.meta.env.VITE_ENABLE_OPPORTUNITY_CALL_ACCOUNT_IDS
    ? import.meta.env.VITE_ENABLE_OPPORTUNITY_CALL_ACCOUNT_IDS.split(',')
    : [],
  // stripe demo test publishable key
  stripePublishableKey:
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || 'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
  enableBillingPortal: (import.meta.env.VITE_ENABLE_BILLING_PORTAL || '1') === '1',
  enablePayoutDashboard: (import.meta.env.VITE_ENABLE_PAYOUT_DASHBOARD || '1') === '1',
  requireAgreements: import.meta.env.VITE_REQUIRE_AGREEMENTS === '1',
  filestackApiKey: import.meta.env.VITE_FILESTACK_API_KEY,
  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
  sendbirdAppId: import.meta.env.VITE_SENDBIRD_APP_ID,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,

  // Harness
  clientName: (import.meta.env.VITE_CLIENT_NAME || 'Access').replace(/\+/g, ' '),
  clientAssetsName: import.meta.env.VITE_CLIENT_ASSETS_NAME || 'access',
  productName: import.meta.env.VITE_PRODUCT_NAME || 'OnFrontiers',
  logo: `/assets/logo/${import.meta.env.VITE_CLIENT_ASSETS_NAME || 'access'}.png`,
  icon: `/assets/favicon/${import.meta.env.VITE_CLIENT_ASSETS_NAME || 'access'}.ico`,
  accessIcon: `/assets/favicon/access.ico`,
  onboardingVideo: import.meta.env.VITE_ONBOARDING_VIDEO
    ? +import.meta.env.VITE_ONBOARDING_VIDEO
    : 858772190,
};

// KT: fix for node scripts
const location = isNode ? { origin: '' } : window.location;
config.cdnUrl = config.cdnUrl || config.webUrl;
config.apiUrl = config.apiProxy ? `${location.origin}/api` : config.apiUrl;
config.webSocketUrl = config.apiProxy ? `${location.origin}/websocket` : config.webSocketUrl;

export default Object.freeze(config);
