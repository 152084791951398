import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { Drawer } from '@/componentsv2/Drawer';
import { segmentTracking } from '@/core/analytics';
import { useProfileId } from '@/hooks/store';
import SparklesIcon from '@/icons/sparkles.svg';
import { useKnowledgeRankQuery } from '@/knowledge/queries/knowledge';
import { PROFILE_ROUTES } from '@/routes/APP_ROUTES';
import { mq } from '@/theme/screens';
import { knowledgeScoreToPercent } from '@/utils/knowledgeScoreToPercent';

import KnowledgeLevelIcon from '../KnowledgeLevelIcon';
import KnowledgeLevelLabel from '../KnowledgeLevelLabel';
import { MoreSeparator } from './MoreSeparator';
import { RareSkill } from './RareSkill';
import { IKnowledgeRankListProps } from './types';

const testId = 'of-rank-list';

const KnowledgeRankList = (props: IKnowledgeRankListProps) => {
  const { knowledgeName, type } = props;
  const profileId = useProfileId();
  const isMobile = useMediaQuery(mq.smd);

  const { data: users, isLoading } = useKnowledgeRankQuery({
    profileId,
    knowledgeName,
    knowledgeType: type,
  });

  const userRank = users?.find((user) => user.profile_id === profileId)?.rank;
  const userTotalCount = users?.length ? users[users.length - 1].rank : 0;

  return (
    <Drawer.Section data-testid={testId}>
      <div className="flex items-center justify-between border-b border-light-primary px-16 py-12">
        <div className="flex items-center gap-5">
          <KnowledgeLevelIcon level="expert" />
          <h4 className="subtitle-1">Top Experts</h4>
        </div>
        {isLoading ? (
          <Skeleton variant="text" width={50} />
        ) : userRank && userTotalCount > 0 ? (
          <span className="text-12">
            <b className="text-14">{userRank}/</b>
            {userTotalCount}
          </span>
        ) : null}
      </div>
      {isLoading ? (
        <ul className="py-8">
          {Array.from(Array(5)).map((_, i) => (
            <li className="px-16 py-8" key={i}>
              <Skeleton variant="text" width={400} className="px-16" />
            </li>
          ))}
        </ul>
      ) : !users || userTotalCount === 0 ? (
        <p className="p-16 text-grey-600 body-2">No experts found.</p>
      ) : (
        <>
          <ul className="py-8">
            {users.map((user, index) => {
              const nextUserRank = index < users.length - 1 ? users[index + 1].rank : 0;
              const showSeparator = index !== users.length - 1 && user.rank !== nextUserRank - 1;
              const profileLastRanked = users[users.length - 1].profile_id === profileId;

              const showUser =
                userTotalCount <= 5 || index !== users.length - 1 || profileLastRanked;

              const userLink = PROFILE_ROUTES(user.profile_id).knowledgeOrLocation(
                knowledgeName,
                type
              );

              return (
                <Fragment key={user.profile_id + index}>
                  {showUser ? (
                    <li
                      className={clsx('flex items-center justify-between px-16 py-8', {
                        'border-l-2 border-brand-primary bg-green-100':
                          user.profile_id === profileId,
                      })}
                    >
                      <div className="flex items-center gap-8 body-2">
                        {user.rank}.
                        <Link
                          to={userLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={clsx('text-14 text-brand-primary', {
                            'pointer-events-none font-medium': user.profile_id === profileId,
                            'underline underline-offset-2': user.profile_id !== profileId,
                          })}
                          onClick={() => {
                            segmentTracking('pressed-click-view-profile', {
                              subject_profile_id: user.profile_id,
                              triggered_by: 'rank list',
                            });
                          }}
                        >
                          {user.profile}
                        </Link>
                      </div>
                      <KnowledgeLevelLabel
                        value={knowledgeScoreToPercent(+(user.value || 0))}
                        iconOnly={isMobile}
                      />
                    </li>
                  ) : null}
                  {showSeparator ? (
                    <MoreSeparator
                      count={nextUserRank - user.rank - (index === users.length - 2 ? 0 : 1)}
                    />
                  ) : null}
                </Fragment>
              );
            })}
            {userTotalCount <= 5 ? <RareSkill /> : null}
          </ul>
          {!userRank ? (
            <div className="flex gap-8 rounded-b-sm bg-deep-purple-50/60 px-8 py-4 text-deep-purple-800 body-2">
              <SparklesIcon className="w-12 fill-deep-purple-500 align-sub" />
              <span>Rankings based on AI-powered inferences are excluded from this list.</span>
            </div>
          ) : null}
        </>
      )}
    </Drawer.Section>
  );
};

export { testId as KnowledgeRankListTestId };
export type { IKnowledgeRankListProps };
export default KnowledgeRankList;
