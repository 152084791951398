import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Button, { ButtonBaseProps } from '@/componentsv2/Button';
import Dialog from '@/componentsv2/Dialog';

const testId = 'of-confirm-dialog';

export interface IConfirmDialogProps {
  className?: string;
  onConfirm: () => void;
  onCancel: () => void;
  dialogTitle?: string;
  open: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  message: string | React.ReactNode;
  closeOnConfirm?: boolean;
  confirmButtonProps?: ButtonBaseProps;
  cancelButtonProps?: ButtonBaseProps;
}

const ConfirmDialog = ({
  className,
  onConfirm,
  onCancel,
  dialogTitle,
  open,
  cancelButtonLabel,
  confirmButtonLabel,
  message,
  closeOnConfirm,
  confirmButtonProps,
  cancelButtonProps,
}: IConfirmDialogProps) => {
  const handleConfirm = () => {
    onConfirm();
    if (closeOnConfirm) {
      onCancel();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className={className}
      maxWidth="md"
      disablePortal={false}
    >
      <DialogContent sx={{ p: 3, textAlign: 'center' }}>
        {dialogTitle ? <h2 className="hd-4">{dialogTitle}</h2> : null}

        <p className="my-16 body-1">{message}</p>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="tertiary" onClick={onCancel} {...cancelButtonProps}>
            {cancelButtonLabel || 'Cancel'}
          </Button>
          <Button variant="destructive" onClick={handleConfirm} {...confirmButtonProps}>
            {confirmButtonLabel || 'Confirm'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export { testId as ConfirmDialogTestId };
export default ConfirmDialog;
