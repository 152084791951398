import qs from 'query-string';
import { ReactElement, ReactNode, createElement } from 'react';
import {
  LoaderFunction,
  LoaderFunctionArgs,
  Params,
  RouteObject,
  redirect,
  useLoaderData,
} from 'react-router-dom';

import { fetchUnreviewed } from '@/consultation/store';
import agreements from '@/core/agreements';
import { GraphQLClient } from '@/core/api';
import { PermissionService } from '@/core/permissions';
import { hasAcceptedEmail } from '@/core/user';
import SendbirdHelper from '@/messaging/sendbird';
import { AppStore } from '@/store';
import { parseHostname, pathAndQuery } from '@/utils';

export interface MiddlewareContext {
  store: AppStore;
  graphqlClient: GraphQLClient;
  sendbird: SendbirdHelper;
  permission: PermissionService;
}

export interface ActionContext extends MiddlewareContext {
  request: Request;
  params: Params;
  location: URL;
  path: string;
  subdomain: string | null | undefined;
  query: URLSearchParams;
}

export interface LegacyRoute {
  path: string;
  public?: boolean;
  title?: string;
  element?: ReactElement;
  children?: Array<LegacyRoute>;
  action?: (context: ActionContext) => Promise<any>;
}

export type RouteMiddleware = (context: MiddlewareContext) => LoaderFunction;

export function parseQueryBool(query: URLSearchParams, key: string): boolean {
  return query.get(key) === 'true';
}

export function parseQueryInt(query: URLSearchParams, key: string): number | undefined {
  const value = query.get(key);
  return value ? parseInt(value, 10) : undefined;
}

export function redirectIfEmailNotVerified(store: AppStore) {
  const { viewer } = store.getState();

  if (!hasAcceptedEmail(viewer)) {
    return redirect('/awaiting_email_validation');
  }
}

export function applyMiddlewares(
  context: MiddlewareContext,
  middlewares: RouteMiddleware[]
): LoaderFunction {
  const loaders = middlewares.map((middleware) => middleware(context));
  return async (args: LoaderFunctionArgs) => {
    for (const loader of loaders) {
      let resp = loader(args);
      if (resp instanceof Promise) {
        resp = await resp;
      }
      if (resp !== undefined) return resp;
    }
  };
}

export function redirectIfAgreementsNotAccepted(store: AppStore, location: URL) {
  const { viewer } = store.getState();

  if (viewer.id) {
    // @ts-ignore
    const agreement = agreements(viewer.agreements);
    const isExpert = viewer.signup_type === 'expert';

    if (!agreement.hasAccepted('terms-of-use', 'privacy')) {
      return redirect(`/legal_ack/privacy?next=${pathAndQuery(location)}`);
    }

    if (isExpert && !agreement.hasAccepted('expert-participation-agreement')) {
      return redirect(`/legal_ack/expert-participation-agreement?next=${pathAndQuery(location)}`);
    }
  }
}

export function loginRequiredMiddleware({ store }: MiddlewareContext): LoaderFunction {
  return async ({ request }) => {
    const { viewer } = store.getState();
    const location = new URL(request.url);

    if (!viewer.id) {
      return redirect(`/login?next=${pathAndQuery(location)}`);
    }

    if (viewer.password_expiry && viewer.password_expiry.expired) {
      return redirect(`/change_password?next=${pathAndQuery(location)}`);
    }

    await store.dispatch(fetchUnreviewed());
  };
}

export function loginRequiredLegacy(route: LegacyRoute): LegacyRoute {
  if (route.public) return route;

  return {
    ...route,
    async action(...args: [ActionContext]) {
      const loader = loginRequiredMiddleware(...args);
      const redirect = await loader(...args);

      return redirect || (route.action && route.action(...args));
    },
  };
}

export function agreementsRequiredMiddleware({ store }: MiddlewareContext): LoaderFunction {
  return async ({ request }) => {
    const location = new URL(request.url);
    return redirectIfAgreementsNotAccepted(store, location);
  };
}

export function agreementsRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return {
    ...route,

    async action(...args: [ActionContext]) {
      const [context] = args || [];
      const location = new URL(context.request.url);

      return (
        redirectIfAgreementsNotAccepted(context.store, location) ||
        (route.action && route.action(...args))
      );
    },
  };
}

export function verifiedEmailRequiredMiddleware({ store }: MiddlewareContext): LoaderFunction {
  return async () => {
    return redirectIfEmailNotVerified(store);
  };
}

export function verifiedEmailRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return {
    ...route,

    async action(...args: [ActionContext]) {
      const [context] = args || [];

      return redirectIfEmailNotVerified(context.store) || (route.action && route.action(...args));
    },
  };
}

export function superAdminRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return {
    ...route,

    async action(...args: [ActionContext]) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (!viewer.id) {
        return redirect('/');
      }

      const canViewAdminPanel = await context.permission.allowed(
        'profile',
        'super_admin',
        viewer.id
      );
      if (!canViewAdminPanel) {
        return redirect('/');
      }

      return route.action && route.action(...args);
    },
  };
}

export function agreementsWithEmailRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return agreementsRequiredLegacy(verifiedEmailRequiredLegacy(route));
}

export function allRequiredMiddleware(context: MiddlewareContext): LoaderFunction {
  return applyMiddlewares(context, [
    loginRequiredMiddleware,
    agreementsRequiredMiddleware,
    verifiedEmailRequiredMiddleware,
  ]);
}

export function allRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return loginRequiredLegacy(agreementsRequiredLegacy(verifiedEmailRequiredLegacy(route)));
}

/**
 * Maps query parameters to an object with key value pairs
 * @param {string} url
 * @returns {Object<string, string>}
 */

export function mapQueryParams(url: string): URLSearchParams {
  const parsedParams = qs.parse(new URL(url).search, { arrayFormat: 'bracket' });

  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(parsedParams)) {
    if (Array.isArray(value)) {
      for (const v of value) {
        searchParams.append(key, v);
      }
    } else if (value !== null) {
      searchParams.append(key, value);
    }
  }
  return searchParams;
}

export type ProtectionFunction = (route: LegacyRoute) => LegacyRoute;

const LegacyCompat = () => {
  return useLoaderData() as ReactNode;
};

export function legacyRouteAdapter(
  context: MiddlewareContext,
  route: LegacyRoute,
  protectionFn?: ProtectionFunction
): RouteObject {
  const protectedRoute = protectionFn ? protectionFn(route) : route;

  let loader: LoaderFunction | undefined;
  const action = protectedRoute.action;
  if (action) {
    loader = ({ params, request }) => {
      const url = new URL(request.url);
      const { subdomain } = parseHostname(url.hostname);
      return action({
        ...context,
        request,
        params,
        location: url,
        path: url.pathname,
        subdomain: subdomain === 'www' ? null : subdomain,
        query: mapQueryParams(request.url),
      });
    };
  }

  return {
    path: protectedRoute.path,
    element: protectedRoute.element || (loader ? createElement(LegacyCompat) : undefined),
    loader,
    children: (protectedRoute.children || []).map((child) =>
      legacyRouteAdapter(context, child, protectionFn)
    ),
  };
}
