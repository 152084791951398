import { red500, sand, white } from '@/theme/colors';

import Alert from '../Alert';
import Button from '../Button';
import Picture from '../Picture';
import s from './MemberRequest.module.scss';

function MemberRequest({ user, membersSet, onApprove, onDeny }: any) {
  return (
    <Alert preset="notification" backgroundColor={sand}>
      <div className={s.memberRequest}>
        <div>
          <Picture user={user} size={60} />
        </div>

        <div className={s.memberRequestContent}>
          <div className={s.memberRequestText}>
            {user.name} would like to join {membersSet}.
          </div>

          <Button size="small" onClick={onApprove}>
            Approve
          </Button>

          <Button
            size="small"
            backgroundColor={white}
            fontColor={red500}
            style={{
              marginLeft: 15,
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
            }}
            onClick={onDeny}
          >
            Deny
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default MemberRequest;
