import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { segmentTracking } from '@/core/analytics';
import { CancelablePromise, PaginatedSavedSearchList, SavedSearchService } from '@/openapi';

export const DEFAULT_SAVED_SEARCHES_LIMIT = 10;

export const useSavedSearchQuery = (id: string) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['saved-search', { id }],
    queryFn: () => SavedSearchService.savedSearchRetrieve(id),
    enabled: !!id,
    retry: false,
    placeholderData: () => {
      const cachedSavedSearches = queryClient.getQueriesData(['saved-searches']) as [
        Array<unknown>,
        PaginatedSavedSearchList,
      ][];

      for (const [, data] of cachedSavedSearches) {
        const savedSearch = data?.results?.find((cachedSavedSearch) => cachedSavedSearch.id === id);
        if (savedSearch) {
          return savedSearch;
        }
      }

      return undefined;
    },
  });

  React.useEffect(() => {
    const savedSearch = query.data;
    if (!savedSearch) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    segmentTracking('user-viewed-saved-search', {
      event_type: 'Saved Search',
      event_name: 'User view a saved search',
      savedsearch_id: savedSearch.id,
      savedsearch_name: savedSearch.name,
      savedsearch_hash: savedSearch.search_hash,
      triggered_by: searchParams.get('source') || '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data?.id]);

  return query;
};

type FetchSavedSearches = {
  limit?: number;
  page?: number;
  ordering?:
    | ''
    | '-created_at'
    | '-owner__first_name'
    | '-updated_at'
    | 'created_at'
    | 'owner__first_name'
    | 'updated_at';
};

const fetchSavedSearches = ({
  limit = DEFAULT_SAVED_SEARCHES_LIMIT,
  page = 1,
  ordering = '-created_at',
}: FetchSavedSearches): CancelablePromise<PaginatedSavedSearchList> => {
  const offset = (page - 1) * limit;
  return SavedSearchService.savedSearchList(limit, offset, ordering);
};

export const useSavedSearchesQuery = ({ limit, page, ordering }: FetchSavedSearches) => {
  return useQuery({
    queryKey: ['saved-searches', { limit, page }],
    queryFn: () => fetchSavedSearches({ limit, page, ordering }),
    keepPreviousData: true,
  });
};
