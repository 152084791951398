import moment from 'moment-timezone';

import ActionTypes from '@/actions/ActionTypes';
import { candidateCategories } from '@/expertrequest/store';
import { projectReducer } from '@/reducers/projects';
import { add, mergeAt, removeAt } from '@/utils/reducer';

import {
  EXPERT_REQUEST__ADD_CANDIDATE,
  EXPERT_REQUEST__DELETE,
  EXPERT_REQUEST__FETCHED_INIT_CANDIDATES,
  EXPERT_REQUEST__FETCHED_MORE_CANDIDATES,
  EXPERT_REQUEST__FETCHING_CANDIDATES,
  EXPERT_REQUEST__LIST,
  EXPERT_REQUEST__LIST_LOADED,
  EXPERT_REQUEST__LIST_LOADING,
  EXPERT_REQUEST__REMOVE_CANDIDATE,
  EXPERT_REQUEST__UPDATE,
  EXPERT_REQUEST__UPDATE_CANDIDATE,
} from '.';

const {
  PROJECT__ADD_MEMBER,
  PROJECT__UPDATE_MEMBER,
  PROJECT__REMOVE_MEMBER,
  UI__SET_USER_CONTEXT,
} = ActionTypes;

const initialCollectionState = {
  loading: false,
  loaded: false,
  edges: [],
  pageInfo: { hasNextPage: true },
  resetAt: undefined,
};

const initialState = {
  dashboard: initialCollectionState,
  default: initialCollectionState,
  open: initialCollectionState,
  all: initialCollectionState,
};

function candidatesReducer(state = [], action: any) {
  switch (action.type) {
    case EXPERT_REQUEST__UPDATE_CANDIDATE:
      return (() => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const index = state.findIndex((m) => m.id === action.candidate.id);
        return mergeAt(state, index, action.candidate);
      })();
    case EXPERT_REQUEST__REMOVE_CANDIDATE:
      return (() => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const index = state.findIndex((m) => m.id === action.candidate.id);
        return removeAt(state, index);
      })();
    case EXPERT_REQUEST__ADD_CANDIDATE:
      return (() => {
        const newCandidates =
          action.candidates?.filter(
            (c: any) =>
              !state.some(
                // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'never'.
                (s) => s.profile && c.profile && c.profile.id === s.profile.id
              )
          ) || [];
        return [...state, ...newCandidates];
      })();
    case EXPERT_REQUEST__FETCHED_INIT_CANDIDATES:
      return (() => {
        return action.candidates?.edges?.map((e: any) => e.node) || [];
      })();
    case EXPERT_REQUEST__FETCHED_MORE_CANDIDATES:
      return (() => {
        const newCandidates =
          action.candidates?.edges
            ?.filter(
              // @ts-expect-error TS(7031): Binding element 'cand' implicitly has an 'any' typ... Remove this comment to see the full error message
              ({ node: cand }) =>
                !state.some(
                  (s) =>
                    // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'never'.
                    s.profile &&
                    cand?.profile &&
                    // @ts-expect-error TS(2339): Property 'profile' does not exist on type 'never'.
                    cand?.profile.id === s.profile.id
                )
            )
            .map((e: any) => e.node) || [];
        return [...state, ...newCandidates];
      })();
    default:
      return state;
  }
}

function candidatesPageInfoReducer(state = {}, candidate: any, action: any) {
  // shallow copy
  // @ts-expect-error TS(2339): Property 'metaData' does not exist on type '{}'.
  let metaData = Object.assign({}, state.metaData);
  switch (action.type) {
    case EXPERT_REQUEST__ADD_CANDIDATE:
      // increment new state count
      if (metaData[candidate.state]) {
        metaData[candidate.state]++;
      } else {
        metaData = { ...metaData, [candidate.state]: 1 };
      }
      return { ...state, metaData };
    case EXPERT_REQUEST__REMOVE_CANDIDATE:
      // decrement state count
      if (metaData[candidate.state] > 0) {
        metaData[candidate.state]--;
      }
      return { ...state, metaData };
    case EXPERT_REQUEST__UPDATE_CANDIDATE:
      // decrement old state count
      if (metaData[candidate.state] > 0) {
        metaData[candidate.state]--;
      }
      // increment new state count
      if (metaData[action.candidate.state]) {
        metaData[action.candidate.state]++;
      } else {
        metaData = { ...metaData, [action.candidate.state]: 1 };
      }
      return { ...state, metaData };
    default:
      return state;
  }
}

interface ExpertRequestState {
  candidates?: any[];
  suggestedCandidates?: any[];
  matchedCandidates?: any[];
  suggestedCandidatesPageInfo?: any;
  matchedCandidatesPageInfo?: any;
  project?: any;
  [key: string]: any;
}

function expertRequestReducer(state: ExpertRequestState = {}, action: any) {
  let candidatesTypeKey;
  let newCandidateState;
  let newCandidatePageInfo;
  let newState: any;
  switch (action.type) {
    case EXPERT_REQUEST__ADD_CANDIDATE:
      newState = state;
      action.candidates?.forEach((c: any) => {
        newCandidateState = c.state;
        if (candidateCategories.suggested.includes(newCandidateState)) {
          newCandidatePageInfo = state.suggestedCandidatesPageInfo;
          candidatesTypeKey = 'suggestedCandidates';
        } else {
          newCandidatePageInfo = state.matchedCandidatesPageInfo;
          candidatesTypeKey = 'matchedCandidates';
        }
        newState = {
          ...newState,
          [candidatesTypeKey]: candidatesReducer(state[candidatesTypeKey], action),
          [`${candidatesTypeKey}PageInfo`]: candidatesPageInfoReducer(
            newCandidatePageInfo,
            c,
            action
          ),
        };
      });
      return newState;
    case EXPERT_REQUEST__REMOVE_CANDIDATE:
    case EXPERT_REQUEST__UPDATE_CANDIDATE: {
      newCandidateState = action.candidate.state;
      newCandidatePageInfo = candidateCategories.suggested.includes(newCandidateState)
        ? state.suggestedCandidatesPageInfo
        : state.matchedCandidatesPageInfo;
      let oldCandidate = state.suggestedCandidates?.find((c: any) => c.id === action.candidate.id);
      let oldPageInfo = state.suggestedCandidatesPageInfo;
      candidatesTypeKey = 'suggestedCandidates';
      if (!oldCandidate) {
        oldCandidate = state.matchedCandidates?.find((c: any) => c.id === action.candidate.id);
        oldPageInfo = state.matchedCandidatesPageInfo;
        candidatesTypeKey = 'matchedCandidates';
      }
      newState = {
        ...state,
        [candidatesTypeKey]: candidatesReducer(state[candidatesTypeKey], action),
        [`${candidatesTypeKey}PageInfo`]: candidatesPageInfoReducer(
          oldPageInfo,
          oldCandidate,
          action
        ),
      };
      if (newCandidatePageInfo !== oldPageInfo) {
        // handle the case where candidate moves from one tab to another
        newState = {
          ...newState,
          [candidatesTypeKey]: candidatesReducer(state[candidatesTypeKey], {
            ...action,
            type: EXPERT_REQUEST__REMOVE_CANDIDATE,
          }),
        };
        candidatesTypeKey =
          candidatesTypeKey === 'suggestedCandidates' ? 'matchedCandidates' : 'suggestedCandidates';
        newState = {
          ...newState,
          [candidatesTypeKey]: candidatesReducer(state[candidatesTypeKey], {
            ...action,
            candidates: [{ ...action.candidate, profile: oldCandidate.profile }],
            type: EXPERT_REQUEST__ADD_CANDIDATE,
          }),
          [`${candidatesTypeKey}PageInfo`]: candidatesPageInfoReducer(
            newCandidatePageInfo,
            action.candidate,
            {
              ...action,
              type: EXPERT_REQUEST__ADD_CANDIDATE,
            }
          ),
        };
      }
      return newState;
    }
    case EXPERT_REQUEST__FETCHING_CANDIDATES:
      return {
        ...state,
        loadingCandidates: true,
      };
    case EXPERT_REQUEST__FETCHED_INIT_CANDIDATES:
    case EXPERT_REQUEST__FETCHED_MORE_CANDIDATES: {
      const edges = action.candidates?.edges || [];
      const cursor = edges.length > 0 ? edges[edges.length - 1].cursor : undefined;
      const sharedState = {
        ...state,
        loadingCandidates: false,
        loadedCandidates: true,
      };
      const candidatesKey = `${action.candidateType}Candidates`;
      return {
        ...sharedState,
        [candidatesKey]: candidatesReducer(state[candidatesKey], action),
        [`${candidatesKey}PageInfo`]: {
          cursor,
          hasNextPage: action.candidates?.pageInfo?.hasNextPage,
          metaData: action.candidates?.pageInfo?.metaData,
        },
      };
    }
    default: {
      const newExpertRequest = action.expertRequest.project;
      const candidates =
        newExpertRequest &&
        newExpertRequest.candidates &&
        newExpertRequest.candidates.map((c: any) => {
          const existingCandidate =
            state.candidates && state.candidates?.find((e: any) => e.id === c.id);
          if (!existingCandidate) return c;
          return {
            ...existingCandidate,
            ...c,
            user:
              existingCandidate.user || c.user
                ? {
                    ...existingCandidate.user,
                    ...c.user,
                  }
                : null,
          };
        });

      return {
        ...state,
        ...action.expertRequest,
        project: {
          ...state.project,
          ...newExpertRequest,
          candidates,
        },
      };
    }
  }
}

function collectionReducer(state = initialCollectionState, action: any) {
  switch (action.type) {
    case EXPERT_REQUEST__DELETE: {
      const { edges: edgesToDelete } = state;
      return {
        ...state,
        edges: removeAt(
          edgesToDelete,
          // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
          edgesToDelete.findIndex((p) => p.node.id === action.id)
        ),
      };
    }
    case EXPERT_REQUEST__LIST: {
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { edges, pageInfo, resetAt: new Date() };
      }
      return { edges: [...state.edges, ...edges], pageInfo };
    }
    case EXPERT_REQUEST__ADD_CANDIDATE:
    case EXPERT_REQUEST__FETCHING_CANDIDATES:
    case EXPERT_REQUEST__FETCHED_INIT_CANDIDATES:
    case EXPERT_REQUEST__FETCHED_MORE_CANDIDATES:
    case EXPERT_REQUEST__REMOVE_CANDIDATE:
    case EXPERT_REQUEST__UPDATE_CANDIDATE:
      return (() => {
        const { expertRequestId } = action;
        const expertRequestIndex = state.edges.findIndex(
          // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
          (p) => p.node.id === expertRequestId
        );
        if (expertRequestIndex < 0) return state;
        const original = state.edges[expertRequestIndex];
        const updated = {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...original,
          // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
          node: expertRequestReducer(original.node, action),
        };
        return {
          ...state,
          edges: mergeAt(state.edges, expertRequestIndex, updated),
        };
      })();
    case EXPERT_REQUEST__UPDATE:
      return (() => {
        const item = action.expertRequest;
        // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
        const index = state.edges.findIndex((p) => p.node.id === item.id);
        const updated = index > -1 ? Object.assign({}, state.edges[index]) : {};
        // @ts-expect-error TS(2339): Property 'node' does not exist on type '{}'.
        updated.node = expertRequestReducer(updated.node || {}, action);
        // @ts-expect-error TS(2339): Property 'expiresAt' does not exist on type '{}'.
        updated.expiresAt = moment().add(1, 'minute').toISOString();
        return {
          ...state,
          edges: index > -1 ? mergeAt(state.edges, index, updated) : add(state.edges, updated),
        };
      })();
    case EXPERT_REQUEST__LIST_LOADING:
      return { ...state, loading: true, loaded: false };
    case EXPERT_REQUEST__LIST_LOADED:
      return { ...state, loading: false, loaded: true };

    case PROJECT__ADD_MEMBER:
    case PROJECT__UPDATE_MEMBER:
    case PROJECT__REMOVE_MEMBER: {
      const newEdges = state.edges.map((e) => {
        // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
        if (!e.node.project || e.node.project.id !== action.projectId) return e;
        return {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...e,
          node: {
            // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
            ...e.node,
            // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
            project: projectReducer(e.node.project, action),
          },
        };
      });
      return {
        ...state,
        edges: newEdges,
      };
    }

    default:
      return state;
  }
}

export default function expertRequestsReducer(state = initialState, action: any) {
  switch (action.type) {
    case EXPERT_REQUEST__ADD_CANDIDATE:
    case EXPERT_REQUEST__FETCHING_CANDIDATES:
    case EXPERT_REQUEST__FETCHED_INIT_CANDIDATES:
    case EXPERT_REQUEST__FETCHED_MORE_CANDIDATES:
    case EXPERT_REQUEST__REMOVE_CANDIDATE:
    case EXPERT_REQUEST__UPDATE_CANDIDATE:
    case EXPERT_REQUEST__DELETE:
    case EXPERT_REQUEST__UPDATE: {
      const cols = action.collection
        ? [action.collection]
        : ['dashboard', 'default', 'open', 'all'];
      return cols.reduce(
        (acc, col) => ({
          ...acc,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [col]: collectionReducer(state[col], action),
        }),
        state
      );
    }
    case PROJECT__ADD_MEMBER:
    case PROJECT__UPDATE_MEMBER:
    case PROJECT__REMOVE_MEMBER: {
      const newState = {};
      Object.keys(state).forEach((col) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[col] = collectionReducer(state[col], action);
      });
      return newState;
    }
    case EXPERT_REQUEST__LIST_LOADING:
    case EXPERT_REQUEST__LIST_LOADED:
    case EXPERT_REQUEST__LIST: {
      const col = action.collection;
      return {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [col]: collectionReducer(state[col], action),
      };
    }
    case UI__SET_USER_CONTEXT:
      return initialState;
    default:
      return state;
  }
}
