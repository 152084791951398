import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Dialog from '@/components/Dialog';
import { TextField } from '@/components/FormAdapters/FormAdapters';
import { Trigger, updateConsultation } from '@/consultation/store';
import { normalizeSpace } from '@/utils';

function EditTrackingCodeDialog({
  consultation,
  updateConsultation,
  notify,
  onClose,
  ...other
}: any) {
  const { tracking_code: trackingCode } = consultation;

  const initialValues = useMemo(
    () => ({
      tracking_code: trackingCode,
    }),
    [consultation]
  );

  const handleSubmit = useCallback(async (values: any) => {
    try {
      await updateConsultation({
        id: consultation.id,
        ...values,
        trigger: Trigger.consultationPage,
      });
      notify('Consultation updated.', 'success');
    } catch (err) {
      notify("An error occurred when trying to update consultation's tracking code.", 'error');
    }
    onClose();
  }, []);

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} subscription={{ submitting: true }}>
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            title="Edit Tracking Code"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Save"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={TextField}
                name="tracking_code"
                label="Tracking Code"
                inputProps={{ maxLength: 60 }}
                multiline
                onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
                parse={(v) => normalizeSpace(v)}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'EditTrackingCodeDialog' because ... Remove this comment to see the full error message
EditTrackingCodeDialog = connect(undefined, {
  updateConsultation,
  notify,
})(EditTrackingCodeDialog);

export default EditTrackingCodeDialog;
