import PropTypes from 'prop-types';
import React, { Component, ReactNode } from 'react';

import LongText from '../LongText';
import s from './WizardDone.module.scss';

interface WizardDoneProps {
  title: string;
  text: string;
  children?: ReactNode;
}

class WizardDone extends Component<WizardDoneProps> {
  render() {
    const { title, text, children } = this.props;

    return (
      <div>
        <h2 className={s.title}>{title}</h2>
        <LongText className={s.text} text={text} />
        {children}
      </div>
    );
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
WizardDone.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default WizardDone;
