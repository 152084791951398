import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

const useFindElementMinHeight = () => {
  const [containerMinHeight, setContainerMinHeight] = useState(0);
  const { ref: containerRef, height = 0 } = useResizeObserver<HTMLElement>({
    box: 'border-box',
    round: (num) => Math.ceil(num),
  });

  useEffect(() => {
    setContainerMinHeight(height);
  }, [height]);

  return { containerRef, containerMinHeight };
};

export default useFindElementMinHeight;
