import { UnknownAction } from 'redux';

import { gengql } from '@/__generated__';
import { CONSULTATION__ADD_REGISTRANT } from '@/consultation/store';
import { QUEUE_IDS } from '@/core/apiWebSocket';
import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

// Setting up a reference to remove listener from anywhere
let unsubscribeQueue: any;

export function addRegistrant(consultation: any, registrant: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { addRegistrant } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionAddRegistrant($conferenceId: String!, $name: String!, $email: String!) {
          addRegistrant(conference_id: $conferenceId, name: $name, email: $email, phone_number: "")
        }
      `),
      registrant
    );

    const addedRegistrant = {
      ...registrant,
      identifier: addRegistrant,
    };

    dispatch({
      type: CONSULTATION__ADD_REGISTRANT,
      consultation,
      registrant: addedRegistrant,
    });

    return addedRegistrant;
  };
}

export function getJoinInfo(conferenceId: string): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { joinInfo } = await graphql.query(
      gengql(/* GraphQL */ `
        query getJoinInfo($conferenceId: String!) {
          joinInfo(conference_id: $conferenceId) {
            join_url
            identifier
            meeting_id
            dial_in_numbers_url
            dial_in_number
            dial_in_region
            passcode
            pin
          }
        }
      `),
      { conferenceId }
    );

    return joinInfo;
  };
}

export interface CallOptions {
  carrier: string;
  userName: string;
  identifier: string;
  joinURL: string;
  startTime: string | undefined;
  expectedDuration: number;
  consultationId: string;
  mainPage: string;
  awaitingExpert: boolean;
}

export interface ReceiveEventOptions {
  receiveEvents?: boolean;
}

export function joinCall(
  call: CallOptions,
  { receiveEvents }: ReceiveEventOptions = {}
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { voipCarrier, apiWebSocket }) => {
    await voipCarrier.connect(call.carrier, call.identifier, call.joinURL);

    dispatch(updateCall(call));

    if (!receiveEvents) return;

    const handleCallEvents = (payload: Record<string, any>) => {
      if (payload.status === 'connected') {
        dispatch(updateCall({ awaitingExpert: false }));
        return;
      }

      if (payload.status === 'disconnected') {
        dispatch(updateCall({ awaitingExpert: true }));
      }
    };

    unsubscribeQueue = apiWebSocket.on(
      QUEUE_IDS.CONSULTATION_EXPERT_CALL_STATUS,
      call.consultationId,
      handleCallEvents
    );
  };
}

export function leaveCall(): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { voipCarrier }) => {
    dispatch(updateCall({ consultationId: null }));
    voipCarrier.disconnect();
    if (unsubscribeQueue) {
      unsubscribeQueue();
    }
  };
}

export function toggleCallMute(): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { voipCarrier }) => {
    voipCarrier.toggleMute();
  };
}

export function createToken(identifier: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { createConferenceToken: callToken } = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionCreateToken($clientName: String!, $identifier: String!) {
          createConferenceToken(client_name: $clientName, identifier: $identifier)
        }
      `),
      {
        clientName: `web-${import.meta.env.MODE}`,
        identifier,
      }
    );

    return callToken;
  };
}

export function updateCall(call: any): UnknownAction {
  return { type: ActionTypes.CALL__UPDATE, call };
}
