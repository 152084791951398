import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { openFileDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';

import FAIcon from '../Icon/FAIcon';

function FileUpload({ onUpload, InputProps = {}, FileDialogProps = {}, ...other }: any) {
  const { graphql } = useApp();

  const upload = async () => {
    const files = await openFileDialog(graphql.client, {
      accept: ['image/*'],
      maxSize: 4 * 1024 * 1024, // 4 MB
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
      ...FileDialogProps,
    });
    onUpload(files[0]);
  };

  return (
    <TextField
      {...other}
      onChange={({ target: { value } }) => onUpload({ url: value })}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={upload}>
              <FAIcon icon="upload" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default FileUpload;
