import MediaQueryComponent from 'react-responsive';

import { useApp } from '@/hooks/useAppContext';

function toMediaValues(caps: any) {
  if (!caps) {
    return;
  }

  const { screen } = caps;

  if (screen) {
    return {
      width: screen.width,
      height: screen.height,
    };
  }
}

const MediaQuery = (props: any) => {
  const { capabilities } = useApp();
  return <MediaQueryComponent {...props} device={toMediaValues(capabilities)} />;
};

export default MediaQuery;
