import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

export interface CheckboxProps extends MuiCheckboxProps {
  label?: string | number | React.ReactElement;
  formControlLabelClassName?: string;
  FormControlLabelProps?: Partial<FormControlLabelProps>;
}

const Checkbox = ({
  formControlLabelClassName,
  FormControlLabelProps,
  ...props
}: CheckboxProps) => {
  const check = <MuiCheckbox {...props} />;
  if (props.label) {
    return (
      <FormControlLabel
        className={formControlLabelClassName}
        control={check}
        label={props.label}
        {...FormControlLabelProps}
      />
    );
  }
  return check;
};

export default Checkbox;
