import MuiTypography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

import { styles, textClasses } from './styles';
import { TextProps } from './types';

// TO-DO temporary while we migrate to theme v2
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    pRegular: true;
    pMedium: true;
    pBold: true;
    inputPlaceholder: true;
    inputLabel: true;
    inputHelpText: true;
  }
}

const useStyles = makeStyles(styles);

/**
 * @deprecated Use `mui/Typography` instead
 */
const Text: React.FC<TextProps> = ({ className, transform, tag, ...rest }) => {
  const classes = useStyles();
  const classProps = clsx(
    tag,
    classes.text,
    {
      [`transform-${transform}`]: transform,
    },
    textClasses.root,
    className
  );
  return <MuiTypography className={classProps} data-testid="of-text" variant={tag} {...rest} />;
};

export * from './types';
export { textClasses } from './styles';
export default Text;
