import FormHelperText from '@mui/material/FormHelperText';
import { Field, FieldProps, getIn } from 'formik';
import debounce from 'lodash.debounce';
import React, { Fragment, ReactNode, useCallback } from 'react';

import Checkbox, { CheckboxProps } from '@/componentsv2/Checkbox';
import Input from '@/componentsv2/Input';
import { InputProps } from '@/componentsv2/Input/types';
import { isRequiredError } from '@/utils/input-helpers';

const testId = 'of-formik-field';

export type FormikFieldProps = Partial<InputProps> &
  Partial<CheckboxProps> & {
    name: string;

    renderedComponent?: any;

    container?: any;
    componentProps?: object;
    containerClassName?: string;
    type?: string;
    mask?: string | DateConstructor | NumberConstructor;
    multiple?: boolean;
    displayEmpty?: boolean;
    validate?: (value: number) => string;
    renderValue?: (value: string) => ReactNode;
  };

const FormikField: React.FC<FormikFieldProps> = ({
  name,
  // the props should be the union of the props of Component
  renderedComponent: C = Input,
  container: Container = Fragment,
  containerClassName,
  componentProps,
  type,
  validate,
  ...rest
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce((fn) => fn(), 500),
    []
  );

  return (
    <Field data-testid={testId} name={name} type={type} validate={validate}>
      {({ field, form, meta }: FieldProps) => {
        const error = getIn(form.touched, name) && getIn(form.errors, name);
        const containerProps: { className?: string } = {};
        if (Container !== Fragment) {
          containerProps['className'] = containerClassName;
        }

        return (
          <Container {...containerProps}>
            <C
              error={C.name === Checkbox.name ? undefined : !!error}
              {...field}
              {...rest}
              {...componentProps}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
                field.onBlur(e);
                if (rest.onBlur) {
                  rest.onBlur(e);
                }
                form.setFieldTouched(name, false);
                debounceFn(() => form.setFieldTouched(name, true));
              }}
            />
            {meta.touched && meta.error && !rest.helperText && !isRequiredError(meta.error) ? (
              <FormHelperText error={!!error}>{meta.error}</FormHelperText>
            ) : null}
          </Container>
        );
      }}
    </Field>
  );
};

export { testId as FormikFieldTestId };
export default FormikField;
