import { ThunkAction, UnknownAction, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { VoipCarrier } from '@/conference/voipCarrier';
import { GraphQLClient } from '@/core/api';
import ApiWebSocket from '@/core/apiWebSocket';
import SendbirdHelper from '@/messaging/sendbird';
import { SearchAction } from '@/search/store';

import rootReducer from './reducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return { type: action.type };
  },

  // only relevant states to be sent to Sentry
  // stateTransformer: (state: IState) => ({
  //   accrualSummary: state.accrualSummary,
  //   profile: state.profile,
  //   ui: state.harnessUi,
  //   user: state.user,
  // }),
});

// TODO: get rid of this
export interface ThunkExtraArg {
  graphql: GraphQLClient;
  history: History;
  sendbird: SendbirdHelper;
  voipCarrier: VoipCarrier;
  apiWebSocket: ApiWebSocket;
}

// @ts-ignore
const thunkExtraArg: ThunkExtraArg = {};

export function setThunkExtraArg(options: ThunkExtraArg) {
  Object.assign(thunkExtraArg, options);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunkExtraArg,
      },
    }),
  devTools: import.meta.env.MODE === 'development',
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  ThunkExtraArg,
  UnknownAction | SearchAction
>;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();

export default store;
