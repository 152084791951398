import { Divider, List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import { PureComponent } from 'react';

import { darkGreen } from '@/theme/colors';

import Link from '../Link';
import s from './SettingsNav.module.scss';

interface SectionProps {
  section: {
    title: string;
    items: { path: string; title: string; id: string; url?: string }[];
  };
  pathPrefix: string;
  selected: string;
}

class Section extends PureComponent<SectionProps> {
  render() {
    const { section, pathPrefix, selected } = this.props;

    return (
      <List style={{ padding: '8px 0' }}>
        <ListSubheader style={{ textTransform: 'uppercase', paddingRight: 16 }} disableSticky>
          {section.title}
          <Divider style={{ marginBottom: 10 }} />
        </ListSubheader>
        {section.items
          .filter((e: any) => e.path.indexOf('/') < 0)

          .map((e: any) => (
            <Link key={e.path} to={`${pathPrefix}/${e.path}`} id={e.id} force={!!e.url}>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={e.title}
                  style={
                    e.path === selected
                      ? {
                          fontWeight: 'bold',
                          color: darkGreen,
                        }
                      : undefined
                  }
                />
              </ListItem>
            </Link>
          ))}
      </List>
    );
  }
}

interface SettingsNavProps {
  nav: {
    title: string;
    items: { path: string; title: string; id: string; url?: string }[];
  }[];
  pathPrefix: string;
  selected: string;
}

class SettingsNav extends PureComponent<SettingsNavProps> {
  render() {
    const { nav, pathPrefix, selected } = this.props;

    return (
      <div className={s.root}>
        {nav.map((e: any) => (
          <Section selected={selected} pathPrefix={pathPrefix} key={e.title} section={e} />
        ))}
      </div>
    );
  }
}

export default SettingsNav;
