import clsx from 'clsx';
import { useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useResizeObserver from 'use-resize-observer';
import { useIntersectionObserver } from 'usehooks-ts';

import Button from '@/componentsv2/Button';

const HorizontalScroller = ({
  children,
  childrenSelectors = 'li',
  className,
}: {
  children: React.ReactNode;
  childrenSelectors?: string;
  className?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const startWatcherRef = useRef<HTMLDivElement>(null);
  const endWatcherRef = useRef<HTMLDivElement>(null);
  const startWatcher = useIntersectionObserver(startWatcherRef, {
    threshold: 1,
  });
  const endWatcher = useIntersectionObserver(endWatcherRef, { threshold: 1 });
  const { width: containerWidth = 0 } = useResizeObserver<HTMLDivElement>({
    ref: containerRef,
  });

  const buffer = 8;
  const containerLeft = containerRef.current?.getBoundingClientRect().left ?? 0;

  const scrollRight = () => {
    const elements = Array.from(containerRef.current?.querySelectorAll(childrenSelectors) ?? []);

    const target = elements.find((el) => {
      const { left, width } = el.getBoundingClientRect();
      return left + width - containerLeft > containerWidth;
    });

    if (!target) {
      return;
    }

    const scrollBy = target.getBoundingClientRect().left - containerLeft - buffer;

    containerRef.current?.scrollBy({ left: scrollBy, behavior: 'smooth' });
  };

  const scrollLeft = () => {
    const elements = Array.from(containerRef.current?.querySelectorAll(childrenSelectors) ?? []);

    const target = elements.reverse().find((el) => {
      const { left } = el.getBoundingClientRect();
      return left - containerLeft < 0;
    });

    if (!target) {
      containerRef.current?.scrollTo({ left: 0, behavior: 'smooth' });
      return;
    }

    const scrollBy =
      containerWidth - (target.getBoundingClientRect().right - containerLeft) - buffer;

    containerRef.current?.scrollBy({ left: scrollBy * -1, behavior: 'smooth' });
  };

  const showScrollButtons = !startWatcher?.isIntersecting || !endWatcher?.isIntersecting;

  return (
    <div className={clsx(className, 'flex w-full')}>
      <div className="flex grow overflow-auto scrollbar-hidden" ref={containerRef}>
        <div className="w-0" ref={startWatcherRef} />
        {children}
        <div className="relative -left-1 w-0" ref={endWatcherRef} />
      </div>
      {showScrollButtons ? (
        <div className="flex border-l border-grey-400">
          <Button
            variant="tertiary"
            size="large"
            className="!px-4"
            onClick={scrollLeft}
            disabled={startWatcher?.isIntersecting ?? false}
            srText="Scroll left"
            startIcon={ChevronLeft}
          />
          <Button
            className="!px-4"
            variant="tertiary"
            size="large"
            onClick={scrollRight}
            disabled={endWatcher?.isIntersecting ?? false}
            srText="Scroll right"
            startIcon={ChevronRight}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HorizontalScroller;
